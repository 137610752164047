import Papa, { UnparseConfig } from 'papaparse'
import { trimObjStringValues } from '../strings'

export function createCsvFile(allData: any[]) {
  if (!allData || !allData.length) {
    return ''
  }

  allData.forEach((object) => {
    trimObjStringValues(object)
  })

  const allColumnNames: Set<string> = new Set()

  allData.forEach((currentDataItem) => {
    const keysOfDataItem = Object.keys(currentDataItem)
    keysOfDataItem.forEach((currentKey) => allColumnNames.add(currentKey))
  })

  const csvOptions: UnparseConfig = {
    columns: Array.from(allColumnNames),
  }

  return Papa.unparse([...allData], csvOptions)
}

export function saveDataToFile(name: string, data: string) {
  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }))
  a.download = name + '.csv'

  // Append anchor to body.
  document.body.appendChild(a)
  a.click()

  // Remove anchor from body
  document.body.removeChild(a)
}
