import React, { useContext, useMemo } from 'react'
import { ResultbyDistributionbyLossType } from '../../backend/parameterisation'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { GridContainer } from '../css-grid/GridContainer'
import {
  givenDataCreateSetOfColumns,
  groupParameterisationByRow,
} from '../../pages/parameterisation/ParameterisationRows'
import { CssGridItem } from '../css-grid/CssGridRows'

export type ParameterizationRow = Record<string, ResultbyDistributionbyLossType>

export const NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW = 2

function TeamParameterisationTable(): JSX.Element {
  const { dataFilteredByDistributionType, allUniqueColumns } = useContext(TeamParameterisationDataContext)

  const listOfDataToBeDisplayed = useMemo(() => {
    return dataFilteredByDistributionType.filter((item) => item.display)
  }, [dataFilteredByDistributionType])

  const dataGroupedByRow = groupParameterisationByRow(listOfDataToBeDisplayed)
  const rowGenerator = givenDataCreateSetOfColumns(allUniqueColumns)

  return (
    <div className="ParameterisationTableContainer">
      <GridContainer>
        <CssGridItem
          columnNumber={1}
          rowNumber={1}
        >
          Stat
        </CssGridItem>
        {allUniqueColumns.map((columnName, columnIndex) => {
          return (
            <CssGridItem
              columnNumber={columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              rowNumber={1}
              key={`column-header-${columnName}`}
            >
              {columnName}
            </CssGridItem>
          )
        })}
        {Object.entries(dataGroupedByRow).flatMap(([rowIdentifier, allValuesForRow], rowIndex) => {
          return rowGenerator(rowIdentifier, rowIndex, allValuesForRow)
        })}
      </GridContainer>
    </div>
  )
}

export default TeamParameterisationTable
