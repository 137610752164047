import { Flex, Grid, Spinner, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

export function ExposuresLoading(): ReactElement {
  return (
    <Grid
      placeItems={'center'}
      height={'100%'}
    >
      <Flex
        flexDirection={'column'}
        placeItems={'center'}
        gap={'1rem'}
      >
        <Text>Loading Exposures...</Text>
        <Spinner size={'xl'} />
      </Flex>
    </Grid>
  )
}
