import { isNumber, PossiblyNegativeNumber } from '../../utils/numbers'

export const calculatePercentileError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!isNumber(value)) {
    return 'This must have a value'
  }

  if (value <= 0) {
    return 'This must be greater than 0'
  }

  if (value > 100) {
    return 'This must be less than 101'
  }

  return undefined
}
