import { useCallback, useEffect, useRef, useState } from 'react'

export function useStateCallback<StateType>(
  initialState: StateType,
): [StateType, (state: StateType, callback?: () => void) => void] {
  const [state, setState] = useState(initialState)
  const cbRef = useRef<(value: StateType) => void>((updatedValue: StateType) => undefined) // mutable ref to store current callback

  const setStateCallback = useCallback((state: StateType, cb?: () => void) => {
    if (cb) {
      cbRef.current = cb // store passed callback to ref
    }
    setState(state)
  }, [])

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef && cbRef.current) {
      cbRef.current(state)
      cbRef.current = () => undefined // reset callback after execution
    }
  }, [state])

  return [state, setStateCallback]
}
