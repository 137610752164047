import React, { MouseEventHandler, useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import './StackedOutwardScenariosList.scss'

import SectionHeader from '../section-header/SectionHeader'
import { StackedOutwardScenarioListContext } from '../../providers/outwards/StackedOutwardScenarioListProvider'
import StackedOutwardScenarioCard from '../stacked-outward-scenario-card/StackedOutwardScenarioCard'
import { StackedScenarioEditingContext } from '../stacked-scenarios-list/StackedScenarioEditingProvider'
import Button from '../../components/button/Button'
import { duplicateOutwardVersion } from '../../backend/outward-scenarios'
import { AllOutwardScenarioContext } from '../../providers/outwards/AllOutwardScenarioProvider'
import { OutwardScenarioChooserContext } from '../../pages/outward-scenario-chooser/OutwardScenarioChooser'
import { OutwardsPortfolioListingContext } from '../../providers/outwards/OutwardsPortfolioListingProvider'
import { AppQueryContext } from '../../providers/AppQueryProvider'

function StackedOutwardScenariosSidebarList() {
  const [currentProgramme] = useQueryParam('programme', StringParam)
  const [currentVersion] = useQueryParam('version', StringParam)

  const { canWriteToOutwards } = useContext(OutwardsPortfolioListingContext)
  const { availableVersions } = useContext(OutwardScenarioChooserContext)
  const { currentStackedOutwardScenarios, reloadStackedOutwardScenarios } = useContext(
    StackedOutwardScenarioListContext,
  )
  const {
    setCurrentlyChosenScenarioIds,
    currentlyCreatingStackedScenario,
    setCurrentlyCreatingStackedScenario,
    currentlyEditingStackedScenarioId,
    setCurrentlyEditingStackedScenarioId,
    setNameOfCurrentlyEditedStackedScenario,
    setDescriptionOfCurrentlyEditedStackedScenario,
    setCreateStackedScenarioModalIsOpen,
  } = useContext(StackedScenarioEditingContext)
  const { defaultOptionsForCreatingStackedScenario } = useContext(AllOutwardScenarioContext)
  const { yearQuery } = useContext(AppQueryContext)

  const [currentYear] = yearQuery

  const startCreatingStackedScenarios = () => {
    setCurrentlyChosenScenarioIds(defaultOptionsForCreatingStackedScenario)
    setCurrentlyCreatingStackedScenario(true)
    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
  }

  const cancelChoosingScenarios = () => {
    setCurrentlyChosenScenarioIds(undefined)
    setCurrentlyCreatingStackedScenario(false)
    setCurrentlyEditingStackedScenarioId(undefined)
  }

  const triggerCopyPreviousVersion: MouseEventHandler = async () => {
    if (!currentVersion || !currentYear) {
      return
    }

    const currentVersionIndex = availableVersions.indexOf(currentVersion!)
    const previousVersionIndex = currentVersionIndex - 1

    const previousVersion = availableVersions[previousVersionIndex]

    if (!previousVersion) {
      return
    }

    await duplicateOutwardVersion(currentYear, previousVersion, currentVersion)
    window.location.reload() //Hacky way of getting everything to update
  }

  const createStackedOutwardScenarioButton = () => {
    if (!canWriteToOutwards || currentlyCreatingStackedScenario || currentlyEditingStackedScenarioId) {
      return <></>
    }

    return (
      <Button
        title={`Create Organisational Scenario`}
        id="Stacked-Scenarios-Button-Create-Scenario"
        onClick={startCreatingStackedScenarios}
      />
    )
  }

  return (
    <div>
      {currentStackedOutwardScenarios.length ? (
        <SectionHeader
          className="StackedScenariosListTitle"
          title={'Organisational scenarios'}
        />
      ) : undefined}
      <div className="ButtonContainer">
        {canWriteToOutwards &&
          !currentProgramme &&
          currentVersion &&
          availableVersions.indexOf(currentVersion!) !== 0 && (
            <Button
              title={'Copy from Previous Version'}
              onClick={triggerCopyPreviousVersion}
            />
          )}
        {createStackedOutwardScenarioButton()}
        {currentlyCreatingStackedScenario && (
          <div>
            <Button
              title={`Save Organisational Scenario`}
              onClick={() => {
                setCreateStackedScenarioModalIsOpen(true)
              }}
            />
            <Button
              title="Cancel"
              onClick={cancelChoosingScenarios}
              secondary
            />
          </div>
        )}
      </div>
      {currentStackedOutwardScenarios.map((stackedOutwardScenario) => (
        <StackedOutwardScenarioCard
          key={stackedOutwardScenario.id}
          stackedScenario={stackedOutwardScenario}
          triggerStackedOutwardScenarioReload={reloadStackedOutwardScenarios}
          ableToEditUnderlyingScenarios
        />
      ))}
    </div>
  )
}

export default StackedOutwardScenariosSidebarList
