import { useContext, useMemo } from 'react'
import { useCategoryForm } from './hooks/useCategoryForm'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { FormProvider } from 'react-hook-form'
import { DynamicCategoryTable, UserSelectsColumns } from './DynamicCategoryTable'
import { MultiSelectDropdownMenu } from '../multi-select-dropdown-chakra/MultiSelectDropdownMenu'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { Cob2n3RegionFields } from './types'
import { Cob1ReferenceKey, cob2n3ReferenceData } from './data/reference'

export const Cob3Table = () => {
  const form = useCategoryForm<Cob2n3RegionFields>({
    defaultValues: {
      cob3s: [],
    },
  })
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)

  const { currentScenario } = useContext(ScenarioContext)
  assertNotNil(currentScenario)

  const cob3Options = useMemo(() => {
    const cobTier1Reference: Cob1ReferenceKey = (
      currentScenario.division === 'Insurance' ? currentScenario.team : currentScenario.market
    ) as Cob1ReferenceKey
    const options = cob2n3ReferenceData[cobTier1Reference]?.map((option) => ({
      label: option,
    }))
    if (!options) console.warn('No COB3 options found for team:', currentScenario.team)
    return options
  }, [currentScenario.team, currentScenario.division, currentScenario.market])

  return !cob3Options ? (
    <></>
  ) : (
    <FormProvider {...form}>
      <DynamicCategoryTable<Cob2n3RegionFields, UserSelectsColumns>
        categoryLabel={'COB - Tier\u00A03'}
        categoryName="cob3"
        dynamicFieldsName="cob3s"
        columnsOptions={cob3Options}
        ContentEditor={MultiSelectDropdownMenu}
      />
    </FormProvider>
  )
}
