import React, { SVGProps } from 'react'

const ScenariosIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#4E566B"
        d="M1.68666667,13.1 L2.58,13.4733333 L2.58,7.45333333 L0.96,11.36 C0.686666667,12.04 1.01333333,12.82 1.68666667,13.1 L1.68666667,13.1 Z M14.6866667,10.6333333 L11.38,2.65333333 C11.1733333,2.15333333 10.6866667,1.84666667 10.1733333,1.83333333 C10,1.83333333 9.82,1.86 9.64666667,1.93333333 L4.73333333,3.96666667 C4.23333333,4.17333333 3.92666667,4.65333333 3.91333333,5.16666667 C3.90666667,5.34666667 3.94,5.52666667 4.01333333,5.7 L7.32,13.68 C7.52666667,14.1866667 8.02,14.4933333 8.54,14.5 C8.71333333,14.5 8.88666667,14.4666667 9.05333333,14.4 L13.96,12.3666667 C14.64,12.0866667 14.9666667,11.3066667 14.6866667,10.6333333 L14.6866667,10.6333333 Z M8.55333333,13.1666667 L5.24666667,5.19333333 L10.1466667,3.16666667 L10.1533333,3.16666667 L13.4533333,11.1333333 L8.55333333,13.1666667 Z"
      />
      <circle
        cx="7.333"
        cy="6"
        r="1"
        fill="#4E566B"
      />
      <path
        fill="#4E566B"
        d="M3.92,13.1666667 C3.92,13.9 4.52,14.5 5.25333333,14.5 L6.22,14.5 L3.92,8.94 L3.92,13.1666667 Z"
      />
    </g>
  </svg>
)

export default ScenariosIcon
