import { useContext, useState } from 'react'

import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { calculateScenarioDisplayString, ScenarioStatusEntity } from '../../backend/scenarios'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { calculatePossibleStatusesToMoveTo } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { fetchUniqueValuesForColumnInScenario } from '../../backend/scenarios-data'
import { WF_TYPE_OF_DATA_COLUMN } from '../../backend/TypeOfData'
import { ActualsContext } from '../../providers/ActualDataChoiceProvider'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ScenarioStatusSelectorModal } from '../ScenarioStatusSelectorModal/ScenarioStatusSelectorModal'

interface ScenarioStatusSelectorProps {
  existingScenario: { id: string; status: ScenarioStatusEntity[] }
  onSelectFunction: (newState: ScenarioStatus) => void
  currentStatus?: ScenarioStatus
  boxed?: boolean
}

export function ScenarioStatusSelector(props: ScenarioStatusSelectorProps) {
  const { isAdmin } = useContext(IsAdminContext)
  const { actualsQuery } = useContext(ActualsContext)
  const { currentScenario } = useContext(ScenarioContext)

  const [openModal, setOpenModal] = useState(false)
  const [desiredStatus, setDesiredStatus] = useState<ScenarioStatus | undefined>(undefined)

  const [actualFromQuery] = actualsQuery

  const fetchScenarioDataAndCheckIfAnyIsUnadjusted: () => Promise<boolean> = async () => {
    if (!currentScenario) {
      console.error(`Missing current scenario`)
      throw new Error('Missing scenario')
    }

    if (!props.existingScenario.id) {
      return false
    }

    try {
      const scenarioData = await fetchUniqueValuesForColumnInScenario(
        props.existingScenario.id,
        WF_TYPE_OF_DATA_COLUMN,
        {
          actualsNameToUse: actualFromQuery,
          dataSource: currentScenario.data,
        },
      )

      if (!scenarioData) {
        console.error(`Scenario data was undefined, scenarioId: ${props.existingScenario.id}`)
        return false
      }

      return scenarioData.some((item) => item === 'RENEWAL_UNADJUSTED')
    } catch (err: unknown) {
      console.error(
        `An error occurred whilst trying to fetch and check if scenario data is unadjusted, scenarioId: ${props.existingScenario.id}`,
      )
      return false
    }
  }

  const onSelectFunction = async (desiredStatus: ScenarioStatus) => {
    if (desiredStatus === ScenarioStatus.COMPLETE || desiredStatus === ScenarioStatus.IN_REVIEW) {
      if (await fetchScenarioDataAndCheckIfAnyIsUnadjusted()) {
        window.alert('There is still unadjusted data left in this scenario')
        return
      }
    }

    setDesiredStatus(desiredStatus)

    if (desiredStatus === ScenarioStatus.COMPLETE) {
      setOpenModal(true)
      return
    }

    props.onSelectFunction(desiredStatus)
  }

  return (
    <>
      <ScenarioStatusSelectorModal
        isOpen={openModal}
        onComplete={() => {
          if (desiredStatus === undefined) {
            return
          }

          props.onSelectFunction(desiredStatus) // TODO this could also possibly be marked as async so that we could await it here (1 of 2 usages is actually async) - but i dont want to change the behavior more than necessary
          setOpenModal(false)
        }}
        onCancel={() => {
          setDesiredStatus(undefined)
          setOpenModal(false)
        }}
      />
      <MultiSelectDropdown
        title="Status"
        className="StatusDropdown"
        isDisabled={!isAdmin && props.existingScenario.status[0].status === 'COMPLETE'}
        onSelect={onSelectSingleValueFromMulti(onSelectFunction)}
        selected={
          props.currentStatus
            ? [
                {
                  label: calculateScenarioDisplayString(props.currentStatus),
                  value: props.currentStatus,
                },
              ]
            : []
        }
        placeholder="Select a status"
        options={calculatePossibleStatusesToMoveTo(isAdmin, props.existingScenario.status[0].status).map((item) => ({
          label: calculateScenarioDisplayString(item),
          value: item,
        }))}
        boxed={props.boxed}
      />
    </>
  )
}
