import { inceptionMonths } from './portfolioSplitsColumns'
import { CategoryTable } from './CategoryTable'
import { useCategoryForm } from './hooks/useCategoryForm'
import { FormProvider } from 'react-hook-form'
import { useContext } from 'react'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { InceptionMonthFields } from './types'
import { inceptionMonthFieldNames } from './data/inceptionMonth'

export const InceptionMonthsTable = () => {
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const initialInceptionMonthValues = ncpState.ncpData.categoryWeightings.inceptionMonth

  const form = useCategoryForm<InceptionMonthFields>({
    defaultValues: initialInceptionMonthValues,
  })

  return (
    <FormProvider {...form}>
      <CategoryTable<InceptionMonthFields>
        categoryLabel="Inception Month"
        categoryName="inceptionMonth"
        first
        columns={{
          labels: inceptionMonths,
          fields: inceptionMonthFieldNames,
        }}
        required
      />
    </FormProvider>
  )
}
