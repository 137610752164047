import React, { EffectCallback, useContext, useEffect, useMemo, useState } from 'react'

import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import ContentContainer from '../../components/content-container/ContentContainer'
import { StackedMarketAndConvexOverallContainerV2 } from '../../components/stacked-market-and-convex-overall-container-v2/StackedMarketAndConvexOverallContainerV2'
import Button from '../../components/button/Button'
import Spacer from '../../components/spacer/Spacer'
import { formatForDisplay, PossiblyNegativeNumber } from '../../utils/numbers'
import { MarketGraphDataV2 } from '../../backend/market-V2'
import { AggregatedMarketV2 } from '../../backend/aggregated-market-V2'
import { AggregatedMarketDataContextV2 } from '../../providers/AggregatedMarketDataProviderV2'
import { NotesDataContext } from '../notes/NotesDataProvider'
import {
  convertAggregatedMarketToTableData,
  HeaderGroupStacked,
  makeHeaderGroupList,
  PerRowDataSet,
} from './StackedMarketUtilsV2'
import {
  displayHeaderBasedOnScenarioLevel,
  StackedMarketBreakdownByLevelTableV2,
} from '../../components/stacked-market-table-v2/StackedMarketBreakdownByLevelTableV2'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import {
  calculateConvexGwp,
  calculateConvexMarketShare,
  calculateCoreClientsPercentage,
  sumTotalMarketGwp,
} from '../../backend/calculate-kpis'
import { StackedScenario } from '../../backend/stacked-scenarios'

type Props = {
  currentStackedScenario: StackedScenario
}

export function StackedScenarioMarketContentV2(props: Props): JSX.Element {
  const { aggregatedMarketData } = useContext(AggregatedMarketDataContextV2)
  const { fullDataForScenario, fullNewKpis, fullRenewalKpis } = useContext(FullScenarioDataContext)
  const { triggerSaveOfNotes, notesAreUpToDateWithRemote } = useContext(NotesDataContext)

  const { currentStackedScenario } = props

  const headerGroupList: HeaderGroupStacked[] = makeHeaderGroupList(currentStackedScenario)
  const year: string = currentStackedScenario?.year || new Date().getFullYear().toString()

  const subScenariosData: undefined | AggregatedMarketV2[] = useMemo(
    () => {
      return aggregatedMarketData ? aggregatedMarketData.composedOf : []
    },
    // eslint-disable-next-line
    [aggregatedMarketData],
  )

  const [totalMarketGwp, setTotalMarketGwp] = useState<number>(0)
  const [pmlScenario1In100, setPmlScenario1In100] = useState<PossiblyNegativeNumber>(0)
  const [confidenceInMarketSize, setConfidenceInMarketSize] = useState<PossiblyNegativeNumber>(0)
  const [accessOrCapability, setAccessOrCapability] = useState<PossiblyNegativeNumber>(0)
  const [inAppetite, setInAppetite] = useState<PossiblyNegativeNumber>(0)
  const [addressableMarketGwp, setAddressableMarketGwp] = useState<PossiblyNegativeNumber>(0)
  const [rateEnvironment, setRateEnvironment] = useState<string>('')
  const [convexGwp, setConvexGwp] = useState<PossiblyNegativeNumber>(0)
  const [convexMarketShare, setConvexMarketShare] = useState<PossiblyNegativeNumber>(0)
  const [peakConvexGwp, setPeakConvexGwp] = useState<PossiblyNegativeNumber>(0)
  const [peakConvexMarketShare, setPeakConvexMarketShare] = useState<PossiblyNegativeNumber>(0)
  const [convexEstimatedShareOfPml, setConvexEstimatedShareOfPml] = useState<PossiblyNegativeNumber>(0)
  const [coreClients, setCoreClients] = useState<PossiblyNegativeNumber>(0)
  const [previousYearGnlr, setPreviousYearGnlr] = useState<PossiblyNegativeNumber>(0)
  const [previousYearGglr, setPreviousYearGglr] = useState<PossiblyNegativeNumber>(0)
  const [convexAcquisitionRatio, setConvexAcquisitionRatio] = useState<PossiblyNegativeNumber>(0)
  const [marketGrarc, setMarketGrarc] = useState<PossiblyNegativeNumber>(0)
  const [marketNrarc, setMarketNrarc] = useState<PossiblyNegativeNumber>(0)
  const [inflation, setInflation] = useState<PossiblyNegativeNumber>(0)
  const [AbeGglr, setAbeGglr] = useState<PossiblyNegativeNumber>(0)
  const [uwConvexAcquisitionRatio, setUwConvexAcquisitionRatio] = useState<PossiblyNegativeNumber>(0)
  const [uwMarketGrarc, setUwMarketGrarc] = useState<PossiblyNegativeNumber>(0)
  const [uwInflation, setUwInflation] = useState<PossiblyNegativeNumber>(0)
  const [uwAbeGglr, setUwAbeGglr] = useState<PossiblyNegativeNumber>(0)
  const [graphData, setGraphData] = useState<MarketGraphDataV2[]>([
    {
      y: [],
      x: [],
      type: '',
      boxpoints: true,
      name: '',
    },
  ])

  const updateWithNewStackedScenarioMarketData: EffectCallback = (): void => {
    if (!aggregatedMarketData) {
      return
    }
    setTotalMarketGwp(aggregatedMarketData.totalMarketGwp)
    setPmlScenario1In100(Number(aggregatedMarketData.pmlScenario1In100))
    setConfidenceInMarketSize(Number(aggregatedMarketData.confidenceInMarketSize))
    setAccessOrCapability(Number(aggregatedMarketData.accessOrCapability))
    setInAppetite(Number(aggregatedMarketData.inAppetite))
    setAddressableMarketGwp(aggregatedMarketData.addressableMarketGwp)
    setRateEnvironment(aggregatedMarketData.rateEnvironment)
    setPeakConvexGwp(aggregatedMarketData.peakConvexGwp)
    setPeakConvexMarketShare(Number(aggregatedMarketData.peakConvexMarketShare))
    setConvexEstimatedShareOfPml(aggregatedMarketData.convexEstimatedShareOfPml)
    setPreviousYearGnlr(Number(aggregatedMarketData.previousYearGnlr))
    setPreviousYearGglr(Number(aggregatedMarketData.previousYearGglr))
    setConvexAcquisitionRatio(Number(aggregatedMarketData.convexAcquisitionRatio))
    setMarketGrarc(Number(aggregatedMarketData.marketGrarc))
    setMarketNrarc(Number(aggregatedMarketData.marketNrarc))
    setInflation(Number(aggregatedMarketData.inflation))
    setAbeGglr(Number(aggregatedMarketData.AbeGglr))
    setUwConvexAcquisitionRatio(Number(aggregatedMarketData.uwConvexAcquisitionRatio))
    setUwMarketGrarc(Number(aggregatedMarketData.uwMarketGrarc))
    setUwInflation(Number(aggregatedMarketData.uwInflation))
    setUwAbeGglr(Number(aggregatedMarketData.uwAbeGglr))
    setGraphData(aggregatedMarketData.graphData)

    const calculatedConvexGwp = calculateConvexGwp(fullRenewalKpis, fullNewKpis)
    setConvexGwp(calculatedConvexGwp.toNumber())

    const calculatedConvexMarketShare: number = calculateConvexMarketShare(calculatedConvexGwp, totalMarketGwp)
    setConvexMarketShare(calculatedConvexMarketShare)

    const calculatedCoreClients: number = calculateCoreClientsPercentage(fullDataForScenario)
    setCoreClients(calculatedCoreClients)
  }

  // eslint-disable-next-line
  useEffect(updateWithNewStackedScenarioMarketData, [aggregatedMarketData])

  const downloadOverallCsv = (): void => {
    const fileName = `${currentStackedScenario?.name} - ${year}`
    const data = {
      'Scenario Name': currentStackedScenario?.name,
      Year: year,
      'Total Market Gwp': totalMarketGwp,
      'PML Scenario 1 in 100': pmlScenario1In100,
      'Confidence in Market Size': confidenceInMarketSize,
      'Access or Capability': accessOrCapability,
      'In Appetite': inAppetite,
      'Addressable Market GWP': addressableMarketGwp,
      'Rate Environment': rateEnvironment,
      'Convex GWP': convexGwp,
      'Convex Market Share': convexMarketShare,
      'Peak Convex GWP': peakConvexGwp,
      'Peak Convex Market Share': peakConvexMarketShare,
      'Convex Estimated Share of PML': convexEstimatedShareOfPml,
      'Core Clients': coreClients,
      'Previous Year GNLR': previousYearGnlr,
      'Previous Year GGLR': previousYearGglr,
      'Convex Acquisition Ratio': convexAcquisitionRatio,
      'Market GRARC': marketGrarc,
      'Market NRARC': marketNrarc,
      Inflation: inflation,
      'ABE GGLR': AbeGglr,
      'Underwriter Convex Acquisition Ratio': uwConvexAcquisitionRatio,
      'Underwriter Market GRARC': uwMarketGrarc,
      'Underwriter Inflation': uwInflation,
      'Underwriter ABE GGLR': uwAbeGglr,
    }
    const allRawLines = createCsvFile([data])
    saveDataToFile(fileName, allRawLines)
  }

  const downloadSegmentsCsv = (): void => {
    if (!subScenariosData) {
      throw new Error(`Missing subScenariosData`)
    }
    const fileName = `${currentStackedScenario?.name} - ${year} - Segments`
    const segmentName = displayHeaderBasedOnScenarioLevel(currentStackedScenario?.scenarioLevel)
    const dataToSave = subScenariosData.map((aggregateMarket) => {
      return {
        [segmentName]: aggregateMarket.aggregateObjectLabel,
        'Total Market GWP': aggregateMarket.totalMarketGwp,
        'PML Scenario 1 In 100': aggregateMarket.pmlScenario1In100,
        'Confidence In Market Size': aggregateMarket.confidenceInMarketSize,
        'Access Or Capability': aggregateMarket.accessOrCapability,
        'In Appetite': aggregateMarket.inAppetite,
        'Addressable Market GWP': aggregateMarket.addressableMarketGwp,
        'Rate Environment': aggregateMarket.rateEnvironment,
        'Peak Convex GWP': aggregateMarket.peakConvexGwp,
        'Peak Convex Market Share': aggregateMarket.peakConvexMarketShare,
        'Convex Estimated Share Of PML': aggregateMarket.convexEstimatedShareOfPml,
        'Previous Year GNLR': aggregateMarket.previousYearGnlr,
        'Previous Year GGLR': aggregateMarket.previousYearGglr,
        'Previous Year Convex Acquisition Ratio': aggregateMarket.previousYearConvexAcquisitionRatio,
        'Convex Acquisition Ratio': aggregateMarket.convexAcquisitionRatio,
        [segmentName + ' Grarc']: aggregateMarket.marketGrarc,
        [segmentName + ' Nrarc']: aggregateMarket.marketNrarc,
        Inflation: aggregateMarket.inflation,
        'ABE GGLR': aggregateMarket.AbeGglr,
        'Underwriter Convex Acquisition Ratio': aggregateMarket.uwConvexAcquisitionRatio,
        ['Underwriter ' + segmentName + ' Grarc']: aggregateMarket.uwMarketGrarc,
        'Underwriter Inflation': aggregateMarket.uwInflation,
        'Underwriter ABE GGLR': aggregateMarket.uwAbeGglr,
      }
    })
    const allRawLines: string = createCsvFile(dataToSave)
    saveDataToFile(fileName, allRawLines)
  }

  const sumOfTotalMarketGwp: number | undefined = useMemo(() => {
    return sumTotalMarketGwp(subScenariosData)
  }, [subScenariosData])

  if (!subScenariosData) {
    return <></>
  }

  const marketShareCalculatedFromPage = Number(addressableMarketGwp) / Number(sumOfTotalMarketGwp)

  const tableData: PerRowDataSet[] = subScenariosData ? convertAggregatedMarketToTableData(subScenariosData) : []

  const coreClientsCalculatedFromPage: number = calculateCoreClientsPercentage(fullDataForScenario)

  return (
    <div>
      <PageHeaderContainer>
        <Spacer />
        <div className="SaveContainer">
          <div className="SaveContainerTitle">
            {notesAreUpToDateWithRemote ? 'Notes saved' : 'Unsaved notes changes'}
          </div>
          <div className="ButtonsContainer">
            <Button
              title="Save Notes"
              onClick={triggerSaveOfNotes}
            />
            <div className="CsvButton">
              <Button
                title="Overall CSV"
                onClick={downloadOverallCsv}
              />
            </div>
            <div className="CsvButton">
              <Button
                title="Segments CSV"
                onClick={downloadSegmentsCsv}
              />
            </div>
          </div>
        </div>
      </PageHeaderContainer>
      <ContentContainer>
        <StackedMarketAndConvexOverallContainerV2
          year={year}
          totalMarketGwp={totalMarketGwp}
          pmlScenario1In100={pmlScenario1In100}
          confidenceInMarketSize={confidenceInMarketSize}
          accessOrCapability={accessOrCapability}
          inAppetite={inAppetite}
          addressableMarketGwp={addressableMarketGwp}
          rateEnvironment={rateEnvironment}
          gwpCalculatedFromPage={sumOfTotalMarketGwp as PossiblyNegativeNumber}
          marketShareCalculatedFromPage={marketShareCalculatedFromPage}
          coreClientsCalculatedFromPage={coreClientsCalculatedFromPage}
          convexGwp={convexGwp}
          convexMarketShare={convexMarketShare}
          peakConvexGwp={peakConvexGwp}
          peakConvexMarketShare={peakConvexMarketShare}
          convexEstimatedShareOfPml={convexEstimatedShareOfPml}
          coreClients={coreClients}
          previousYearGnlr={previousYearGnlr}
          previousYearGglr={previousYearGglr}
          convexAcquisitionRatio={convexAcquisitionRatio}
          marketGrarc={marketGrarc}
          marketNrarc={marketNrarc}
          inflation={inflation}
          AbeGglr={AbeGglr}
          uwConvexAcquisitionRatio={uwConvexAcquisitionRatio}
          uwMarketGrarc={uwMarketGrarc}
          uwInflation={uwInflation}
          uwAbeGglr={uwAbeGglr}
          graphData={graphData}
        />
        <StackedMarketBreakdownByLevelTableV2
          columns={headerGroupList}
          data={tableData}
          scenarioLevel={currentStackedScenario?.scenarioLevel}
          sumOfTotalMarketGwp={formatForDisplay(sumOfTotalMarketGwp)}
        />
      </ContentContainer>
    </div>
  )
}
