import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import SegmentedControlSwitch from '../../components/segmented-control/SegmentedControl'
import { PATHS_TO_DISPLAY_ON } from './PathsToDisplay'

interface GraphDisplayToggleProps extends RouteComponentProps {}

const pathsToDisplayOn = PATHS_TO_DISPLAY_ON

enum ToggleOptions {
  HIDE = 'Data',
  SHOW = 'Graphs',
}

const ALL_OPTIONS = Object.entries(ToggleOptions).map(([key, value]) => ({ name: value, value: value }))

function GraphDisplayToggle(props: GraphDisplayToggleProps): JSX.Element {
  const [graphPanelIsOpen, setShowGraphPanel] = useQueryParam('graphPanel', BooleanParam)
  if (pathsToDisplayOn.includes(props.match.path)) {
    return (
      <div className="GraphDisplayToggle">
        <SegmentedControlSwitch
          options={ALL_OPTIONS}
          selectedOption={graphPanelIsOpen ? 1 : 0}
          onChange={(selectedItem) => setShowGraphPanel(Boolean(selectedItem === ToggleOptions.SHOW))}
        />
      </div>
    )
  }

  return <></>
}

export default withRouter(GraphDisplayToggle)
