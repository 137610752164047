import React from 'react'
import { formatInceptionYearForDisplay, sortInceptionMonth } from '../gwp-bar-chart/inception-month-graph'
import { GwpCumulativeLineChartDataProvider } from './GwpCumulativeLineChartDataProvider'
import { GwpAndAdjustedGwpCumulativeLineChart } from './GwpAndAdjustedGwpCumulativeLineChart'
import { GraphDataProvider } from '../GraphDataProvider'
import { GraphHeader } from '../../graph-header/GraphHeader'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'

export function GwpsCumulativeLine() {
  return (
    <>
      <GraphHeader title="Original vs Adjusted GWP" />
      <GraphDataProvider
        fieldNameToGroupBy={RENEWAL_INCEPTION_MONTH_COLUMN_NAME}
        formatDataFieldForDisplay={formatInceptionYearForDisplay}
        sortDataFunction={sortInceptionMonth}
      >
        <div className="Graph CumulativeLineChart">
          <GwpCumulativeLineChartDataProvider>
            <GwpAndAdjustedGwpCumulativeLineChart />
          </GwpCumulativeLineChartDataProvider>
        </div>
      </GraphDataProvider>
    </>
  )
}
