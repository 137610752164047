import { deleteAllAdjustments } from './adjustments'
import { getCurrentConfigSettings } from '../utils/config'
import { deleteAllPortfolioItems } from './new-portfolio-items'
import { ScenarioStatus } from '../utils/status/ScenarioStatus'
import { Level } from '../utils/urls'
import { getAccessToken } from './teams'

const calculateScenarioDisplayString = (value: ScenarioStatus | undefined) => {
  return (value || ScenarioStatus.IN_PROGRESS)
    .split('_')
    .map((currentPart) => currentPart.charAt(0).toUpperCase() + currentPart.slice(1).toLowerCase())
    .join(' ')
}

export type ScenarioStatusEntity = {
  id: string
  status: ScenarioStatus
  user: string | null
  signOffDate: string | null
  createdAt: Date
  updatedAt: Date
}

export interface Scenario {
  id: string
  name: string
  updatedAt: string
  data: string
  summary?: string | undefined | null
  division: string
  team: string
  market: string
  status: ScenarioStatusEntity[]
  canWriteScenario?: boolean
  year: string
  downloadLink?: string
  previousYearActualsDataName?: string | undefined | null
  currentYearActualsDataName?: string | undefined | null
  chainedScenarioId?: string | undefined | null
}

type PatchScenarioStatusRequest = {
  status: ScenarioStatus
  user: string | null
}

export interface PatchScenarioRequest {
  division?: string
  team?: string
  market?: string
  name?: string
  summary?: string
  scenarioStatus?: PatchScenarioStatusRequest
  currentYearActualsDataName?: string | undefined | null
  snowflakeDataSourceVersion?: string | undefined | null
}

export type DateString = `${number}-${number}-${number}`

export interface CreateScenarioRequest {
  name: string
  data: string
  summary?: string | undefined | null
  previousYearActualsDataName: string | undefined
  currentYearActualsDataName: string | undefined
  snowflakeDataSourceVersion?: DateString
  chainedScenarioId?: string | undefined | null
}

export interface DuplicatingScenarioOverrides {
  overrideName: string
  overrideActuals: string | undefined | null
  overrideDataName: string | undefined | null
  overrideChainedScenarioId: string | undefined | null
}

async function patchScenario(scenarioId: string, patchScenarioRequest: PatchScenarioRequest): Promise<void> {
  try {
    await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}`, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchScenarioRequest),
    })
  } catch (e: unknown) {
    console.log(e)
  }
}

async function fetchScenario(id: string): Promise<Scenario | undefined> {
  if (id === undefined) {
    return undefined
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}
async function fetchScenarioUploadLink(id: string): Promise<{ link: string } | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${id}/upload`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  return await response.json()
}

async function fetchAllScenarios(division: string, team: string, market: string, year: string): Promise<Scenario[]> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios?division=${encodeURIComponent(division)}&team=${encodeURIComponent(
      team,
    )}&market=${encodeURIComponent(market)}&year=${encodeURIComponent(year)}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

async function createScenario(level: Level, csr: CreateScenarioRequest) {
  // FIXME discrepancy with BE's CreateScenarioRequest in the controller
  const { year, division, team, market } = level
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ division, team, market, year, ...csr }),
  })
  return (await response.json()).id
}

async function duplicateScenario(scenarioId: string, overrideParameters: DuplicatingScenarioOverrides) {
  return await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/duplicate`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(overrideParameters),
  })
}

async function deleteScenario(scenarioId: string) {
  await deleteAllAdjustments(scenarioId)
  await deleteAllPortfolioItems(scenarioId)
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  return Promise.resolve()
}

export {
  fetchScenario,
  fetchAllScenarios,
  fetchScenarioUploadLink,
  createScenario,
  patchScenario,
  deleteScenario,
  duplicateScenario,
  calculateScenarioDisplayString,
}
