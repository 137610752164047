import {
  Adjustment,
  isActive,
  isClient,
  isNotPreview,
  previewPortfolioAdjustmentIsUntouched,
} from '../../../backend/adjustments'
import { useCallback, useContext } from 'react'
import { RenewalAdjustmentContext } from '../../../providers/RenewalAdjustmentsProvider'

export const useCountRenewalAdjustments = (): number | undefined => {
  const { adjustments: renewalAdjustments } = useContext(RenewalAdjustmentContext)

  const relevantRenewalAdjustments: (adjustment: Adjustment) => boolean = useCallback((adjustment) => {
    const isClientAdjustment = isClient(adjustment)
    const isClientAdjustmentNotInPreview = isClientAdjustment && isNotPreview(adjustment)
    return (
      isActive(adjustment) &&
      (isClientAdjustmentNotInPreview || (!isClientAdjustment && !previewPortfolioAdjustmentIsUntouched(adjustment)))
    )
  }, [])

  return renewalAdjustments?.filter(relevantRenewalAdjustments).length
}
