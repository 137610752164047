import React, { useContext } from 'react'
import { GraphContainerContext } from '../graph-container/GraphContainer'
import { IconButton } from '../icon-button/IconButton'
import ContractIcon from '../icons/ContractIcon'
import ExpandIcon from '../icons/ExpandIcon'
import { GraphStyleContext } from '../renewal-graph/GraphStyleProvider'
import './GraphHeader.scss'

interface GraphHeaderProps {
  title: string
  hideExpand?: boolean
}

function GraphHeader(props: GraphHeaderProps) {
  const { currentlyExpandedGraph, setCurrentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)

  return (
    <div className="GraphHeader">
      <h3 className="GraphTitle">{props.title}</h3>
      {!props.hideExpand && (
        <>
          {graphId && currentlyExpandedGraph && currentlyExpandedGraph === graphId ? (
            <IconButton
              icon={<ContractIcon />}
              onClick={() => setCurrentlyExpandedGraph(undefined)}
            />
          ) : (
            <IconButton
              icon={<ExpandIcon />}
              onClick={() => setCurrentlyExpandedGraph(graphId)}
            />
          )}
        </>
      )}
    </div>
  )
}

export { GraphHeader }
