export type loadingType = 'loading' | 'error' | 'loaded'

export type ExposuresGraphState<T> = {
  status: loadingType
  data: T
  emptyData: T
  message: string
}

export type ExposuresGraphAction<T> =
  | { type: 'setLoading' }
  | { type: 'setError'; payload: { error: string } }
  | { type: 'setLoaded'; payload: { data: T } }

export function createGraphInitialState<T>(emptyData: T): ExposuresGraphState<T> {
  return {
    status: 'loading',
    data: emptyData,
    emptyData,
    message: '',
  }
}

export function createGraphReducer<T>() {
  return (state: ExposuresGraphState<T>, action: ExposuresGraphAction<T>): ExposuresGraphState<T> => {
    switch (action.type) {
      case 'setLoading': {
        return { ...state, status: 'loading', data: state.emptyData, message: '' }
      }
      case 'setError': {
        return { ...state, status: 'error', data: state.emptyData, message: action.payload.error }
      }
      case 'setLoaded': {
        if (state.status === 'error') {
          return state
        }
        return { ...state, status: 'loaded', data: action.payload.data, message: '' }
      }
    }
  }
}
