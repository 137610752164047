import { fetchOutwardScenarioById } from '../../backend/outward-scenarios'
import { fetchOutwardScenarioMarketOutput, fetchOutwardScenarioPerilsOutput } from '../../backend/perils'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'

export async function fetchAssembleAndDownloadThePerilsDataForScenarios(
  outwardScenarioIds: Array<string>,
  fileNamePrefix: string,
) {
  const allMarketDataPromises = outwardScenarioIds.map(async (outwardScenarioId) => {
    const outwardScenario = await fetchOutwardScenarioById(outwardScenarioId)
    const marketOutput = await fetchOutwardScenarioPerilsOutput(outwardScenarioId)

    return {
      ID: outwardScenarioId,
      Name: outwardScenario?.name,
      'Treaty ID': outwardScenario?.treaty,
      Programme: outwardScenario?.programme,
      Version: outwardScenario?.version,
      ...marketOutput,
    }
  })

  const allMarketData = await Promise.all(allMarketDataPromises)

  const csvFile = createCsvFile(allMarketData)
  saveDataToFile(fileNamePrefix + '.outward-applicable-perils', csvFile)
}

export async function fetchAssembleAndDownloadTheMarketDataForScenarios(
  outwardScenarioIds: Array<string>,
  fileNamePrefix: string,
) {
  const allMarketDataPromises = outwardScenarioIds.map(async (outwardScenarioId) => {
    const outwardScenario = await fetchOutwardScenarioById(outwardScenarioId)
    const marketOutput = await fetchOutwardScenarioMarketOutput(outwardScenarioId)

    return {
      ID: outwardScenarioId,
      Name: outwardScenario?.name,
      'Treaty ID': outwardScenario?.treaty,
      Programme: outwardScenario?.programme,
      Version: outwardScenario?.version,
      ...marketOutput,
    }
  })

  const allMarketData = await Promise.all(allMarketDataPromises)

  const csvFile = createCsvFile(allMarketData)
  saveDataToFile(fileNamePrefix + '.outward-applicable-markets', csvFile)
}
