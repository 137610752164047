import React, { useContext } from 'react'
import './Notes.scss'
import NotesContent from './NotesContent'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { NotesDataProvider } from './NotesDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'

function Notes() {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  if (!currentScenario?.id && !currentStackedScenario?.id && !currentOutwardScenario?.id) {
    return (
      <div className="Notes">
        <SelectScenarioMessage />
      </div>
    )
  }

  return (
    <div className="Notes">
      <MarketV2Provider>
        <NotesDataProvider>
          <NotesContent />
        </NotesDataProvider>
      </MarketV2Provider>
    </div>
  )
}

export default Notes
