import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ADJUSTED_PREMIUM_VALUE, PREMIUM_COLUMN_NAME } from '../../../backend/calculate-kpis'
import { groupListOfObjsBy } from '../../../utils/lists'
import { ViolinGraphData } from './ViolinTypes'
import { numericValueIsDefined } from '../../../utils/numbers'
import { FieldToGroupPortfolioBy } from '../../../providers/PortfolioGroupDropdownAndProvider'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

interface NumericFieldChosenAndGroupdByOtherProviderProps {
  chosenNumericColumn: string
  chosenGroupingColumn?: string
  portfolioGroupingColumn?: FieldToGroupPortfolioBy
}

interface NumericFieldChosenAndGroupedByOtherFieldContainer {
  chosenNumericColumn: string
  chosenGroupingColumn?: string
  dataReadyForGraph: ViolinGraphData | {}
  verticalLineAt?: number
  tooltipOverride?: number
}

const NumericFieldChosenAndGroupedByOtherFieldContext =
  createContext<NumericFieldChosenAndGroupedByOtherFieldContainer>({
    chosenNumericColumn: PREMIUM_COLUMN_NAME,
    chosenGroupingColumn: 'A1 Segment',
    dataReadyForGraph: {},
  })

function groupIntoTopNAndOthers(
  formattedData: Record<string, number[]>,
  numberToKeep: number,
): Record<string, number[]> {
  if (Object.keys(formattedData).length <= numberToKeep) {
    return formattedData
  }

  const allEntriesWithTotal = Object.entries(formattedData).map(([columnName, allValuesForGroup]) => {
    const total = allValuesForGroup.reduce((first, second) => first + second, 0)

    return [columnName, total]
  })

  allEntriesWithTotal.sort((first, second) => (second[1] as number) - (first[1] as number))

  const topNColumnNames = allEntriesWithTotal.slice(0, numberToKeep).map(([columnName]) => columnName)
  const otherColumnNames = allEntriesWithTotal
    .slice(numberToKeep, allEntriesWithTotal.length)
    .map(([columnName]) => columnName)

  const allEntriesWithList = Object.entries(formattedData)

  const topNEntries = allEntriesWithList.filter(([columnName]) => topNColumnNames.includes(columnName))

  return {
    ...Object.fromEntries(topNEntries),
    Others: allEntriesWithList
      .filter(([columnName]) => otherColumnNames.includes(columnName))
      .flatMap((columnNameAndData) => columnNameAndData[1] as number[]),
  }
}

const NUMBER_TO_KEEP = 12

const DECIMAL_FIELDS = ['Effective Line', 'Actual Signing %']

function getColumnFromItem(data: any, chosenColumnName: string) {
  if (chosenColumnName === ADJUSTED_PREMIUM_VALUE) {
    const value = data[ADJUSTED_PREMIUM_VALUE]
    if (!numericValueIsDefined(value)) {
      return Number.parseInt(data[PREMIUM_COLUMN_NAME])
    }
  }

  if (DECIMAL_FIELDS.includes(chosenColumnName)) {
    return Number.parseFloat(data[chosenColumnName])
  }

  return Number.parseInt(data[chosenColumnName])
}

function NumericFieldChosenAndGroupdByOtherProvider(
  props: PropsWithChildren<NumericFieldChosenAndGroupdByOtherProviderProps>,
) {
  const [dataReadyForGraph, setGraphData] = useState<ViolinGraphData | {}>({})

  const { filteredDataForScenario } = useContext(FullScenarioDataContext)

  const recalculateData = () => {
    if (!filteredDataForScenario.length) {
      setGraphData({})
      return
    }

    const groupedData = props.chosenGroupingColumn
      ? groupListOfObjsBy(filteredDataForScenario, props.chosenGroupingColumn)
      : { All: filteredDataForScenario }

    const formattedData = Object.entries(groupedData).map(([columnName, rawDataPerColumn]) => {
      const groupedByColumn = groupListOfObjsBy(
        rawDataPerColumn,
        props.portfolioGroupingColumn || FieldToGroupPortfolioBy.CONVEX_LAYER,
      )

      const listOfIndiviaulsOnceGroupedByPortfolioGroup = Object.values(groupedByColumn).map((listOfData: any[]) =>
        listOfData.map((item) => getColumnFromItem(item, props.chosenNumericColumn)),
      )

      const dataTurnedIntoNumberValues = listOfIndiviaulsOnceGroupedByPortfolioGroup
        .map((listOfValues) => listOfValues.reduce((first: number, second: number) => first + second), 0)
        .filter((item) => item > 0)

      return [columnName, dataTurnedIntoNumberValues]
    })

    const dataObject = groupIntoTopNAndOthers(Object.fromEntries(formattedData), NUMBER_TO_KEEP)

    const graphData: ViolinGraphData = {
      labels: Object.keys(dataObject),
      datasets: [
        {
          label: props.chosenNumericColumn,
          backgroundColor: GRAPH_COLOURS[0],
          borderColor: GRAPH_COLOURS[1],
          borderWidth: 1,
          data: Object.values(dataObject),
        },
      ],
    }

    setGraphData(graphData)
  }

  useEffect(recalculateData, [
    filteredDataForScenario,
    props.chosenGroupingColumn,
    props.chosenNumericColumn,
    props.portfolioGroupingColumn,
  ])

  return (
    <NumericFieldChosenAndGroupedByOtherFieldContext.Provider
      value={{
        chosenGroupingColumn: props.chosenGroupingColumn,
        chosenNumericColumn: props.chosenNumericColumn,
        dataReadyForGraph,
      }}
    >
      {props.children}
    </NumericFieldChosenAndGroupedByOtherFieldContext.Provider>
  )
}

export { NumericFieldChosenAndGroupedByOtherFieldContext, NumericFieldChosenAndGroupdByOtherProvider }
