import React, { forwardRef, useContext } from 'react'
import ChartComponent, { LinearComponentProps } from 'react-chartjs-2'
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js'
import { NumericFieldChosenAndGroupedByOtherFieldContext } from './NumericFieldChooserAndGroupedByColumnProvider'
import { SHARED_CHART_JS_OPTION } from './ViolinChart'
import { GraphStyleContext } from '../GraphStyleProvider'
import { GraphContainerContext } from '../../graph-container/GraphContainer'

interface ViolinChartProps {}

const BoxAndWhiskers = forwardRef<any, Omit<LinearComponentProps, 'type'>>((props, ref) => (
  <ChartComponent
    {...props}
    type="boxplot"
    ref={ref}
    options={props.options || {}}
  />
))

function BoxAndWhiskersChart(props: ViolinChartProps) {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const isExpanded = currentlyExpandedGraph === graphId
  const { dataReadyForGraph } = useContext(NumericFieldChosenAndGroupedByOtherFieldContext)

  return (
    <BoxAndWhiskers
      data={dataReadyForGraph}
      options={SHARED_CHART_JS_OPTION(isExpanded)}
    />
  )
}

export default BoxAndWhiskersChart
