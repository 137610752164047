import React, { ReactElement, useContext } from 'react'

import './StackedScenariosList.scss'

import StackedScenarioCard from '../stacked-scenario-card/StackedScenarioCard'
import SectionHeader from '../section-header/SectionHeader'
import { StackedScenarioListContext } from './StackedScenarioListProvider'
import { StackedScenariosViewContext } from '../stacked-scenarios-view/StackedScenariosViewProvider'
import Button from '../button/Button'
import { StackedScenarioEditingContext } from './StackedScenarioEditingProvider'
import { AppQueryContext } from '../../providers/AppQueryProvider'

export function SidebarSelector(): ReactElement {
  const { currentStackedScenarios, reloadStackedScenarios } = useContext(StackedScenarioListContext)
  const { currentPortfolioLevel } = useContext(StackedScenariosViewContext)
  const {
    setCurrentlyChosenScenarioIds,
    currentlyCreatingStackedScenario,
    setCurrentlyCreatingStackedScenario,
    currentlyEditingStackedScenarioId,
    setCurrentlyEditingStackedScenarioId,
    setNameOfCurrentlyEditedStackedScenario,
    setDescriptionOfCurrentlyEditedStackedScenario,
    setCreateStackedScenarioModalIsOpen,
  } = useContext(StackedScenarioEditingContext)
  const { divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  const sortCurrentScenariosByUpdatedDate = currentStackedScenarios.sort(
    (a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt),
  )
  const startCreatingStackedScenarios = () => {
    setCurrentlyChosenScenarioIds({})
    setCurrentlyCreatingStackedScenario(true)
    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
  }

  const cancelChoosingScenarios = () => {
    setCurrentlyChosenScenarioIds(undefined)
    setCurrentlyCreatingStackedScenario(false)
    setCurrentlyEditingStackedScenarioId(undefined)
  }

  const createStackedScenarioButton = () => {
    if (currentlyCreatingStackedScenario || currentlyEditingStackedScenarioId) {
      return <></>
    }

    if (currentPortfolioLevel === 'Divisional') {
      const title = `Create ${heading(teamFromQuery, divisionFromQuery)} Scenario`
      return (
        <Button
          title={title}
          id="Stacked-Scenarios-Button-Create-Scenario"
          onClick={startCreatingStackedScenarios}
        />
      )
    }
  }
  if (currentPortfolioLevel === 'Organisational' || !currentStackedScenarios) {
    return <div />
  }
  return (
    <div>
      {currentStackedScenarios.length ? (
        <SectionHeader
          className="StackedScenariosListTitle"
          title={currentStackedScenarios[0].scenarioLevel.toLowerCase() + ' scenarios'}
        />
      ) : undefined}
      {createStackedScenarioButton()}
      {currentlyCreatingStackedScenario && (
        <div className="ButtonContainer">
          <Button
            title={`Save ${heading(teamFromQuery, divisionFromQuery)} Scenario`}
            id="Stacked-Scenarios-Button-Save-Scenario"
            onClick={() => {
              setCreateStackedScenarioModalIsOpen(true)
            }}
          />
          <Button
            className="CancelButton"
            title="Cancel"
            id="Stacked-Scenarios-Button-Cancel"
            onClick={cancelChoosingScenarios}
            secondary
          />
        </div>
      )}
      {sortCurrentScenariosByUpdatedDate.map((stackedScenario) => (
        <StackedScenarioCard
          key={stackedScenario.id}
          stackedScenario={stackedScenario}
          triggerStackedScenarioReload={reloadStackedScenarios}
          creatingIsSelected={false}
          creatingOnToggle={() => void 0}
          ableToEditUnderlyingScenarios
          selectable={false}
          comparingOnToggle={() => {}}
          isComparing={false}
          comparingIsSelected={false}
        />
      ))}
    </div>
  )
}

function heading(currentTeam: null | undefined | string, currentDivision: null | undefined | string): string {
  if (currentTeam) {
    return 'Team'
  }
  if (currentDivision) {
    return 'Division'
  }
  return 'Organisational'
}
