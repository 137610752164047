import React, { useContext, useMemo } from 'react'
import { css } from '../../utils/css'
import DraggableCard from '../draggable-card/DraggableCard'
import './OutwardRuleCard.scss'
import { deleteRule, fetchRuleById, Rule } from '../../backend/outward-rules'
import EditIcon from '../icons/EditIcon'
import DeleteIcon from '../icons/DeleteIcon'
import { FilterValues } from '../../backend/calculate-possible-filter-values'
import { FilterContext } from '../../providers/FilterProvider'
import { OutwardScenarioRuleContext } from '../../providers/outwards/OutwardScenarioRulesProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import LockedIcon from '../icons/LockedIcon'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { calculateKPIs, TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import { HeadingWithTitle } from '../heading-with-title/HeadingWithTitle'
import { suffixForValue } from '../../utils/numbers'
import { calculateFilteredDataApplyingRulesOneAfterAnother } from '../../providers/outwards/OutwardsDataProvider'
import PreviewIcon from '../icons/PreviewIcon'
import { saveOrUpdateListOfApplicableMarkets } from '../../backend/perils'
import { calculateListOfMarkets } from '../../providers/outwards/OutwardsApplicablePerilsProvider'
import { ManyDropdownFilterValues } from '../../providers/FilterProvider/model'

export const RULE_PREVIEW_ID = 'preview'
export const PERILS_ID = 'perils'

export const isNotPreview = (rule: Rule) => {
  return RULE_PREVIEW_ID !== rule.id
}

export type RuleCardProps = {
  index: number
  rule: Rule
  fullListOfRules: Rule[]
  onTriggerEditRule?: (ruleId: string) => void
  onDrop?: (dragIndex: number, hoverIndex: number) => void
  onHover?: (dragIndex: number, hoverIndex: number) => void
  hideCreateAndEdit?: boolean
}

export function OutwardRuleCard(props: RuleCardProps) {
  const { onChangeFilters } = useContext(FilterContext)
  const { triggerReloadSavedRules } = useContext(OutwardScenarioRuleContext)
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  const dataWhichMatchesRuleAndRulesAboveIt = useMemo(
    () =>
      calculateFilteredDataApplyingRulesOneAfterAnother(
        fullDataForScenario,
        props.fullListOfRules.filter((rule) => rule.orderNumber <= props.rule.orderNumber),
      ),
    [props.rule, fullDataForScenario, props.fullListOfRules],
  )
  const kpisForRule = useMemo(
    () => calculateKPIs(dataWhichMatchesRuleAndRulesAboveIt),
    [dataWhichMatchesRuleAndRulesAboveIt],
  )

  const canDrag = Boolean(props.onDrop && props.onHover)
  const canBeEdited = currentOutwardScenario?.canWrite && !props.hideCreateAndEdit

  const onUpdateFiltersWithValues = (savedRule: Rule | undefined) => {
    onChangeFilters(
      Object.entries(savedRule?.appliedFilters! as FilterValues).reduce(
        (dataSoFar: ManyDropdownFilterValues, [columnName, chosenValue], index) => ({
          ['filter' + (index + 1).toString()]: {
            columnName,
            chosenValue,
          },
          ...dataSoFar,
        }),
        {} as ManyDropdownFilterValues,
      ),
    )
  }

  const onPreviewFilters = async () => {
    const savedRule = await fetchRuleById(currentOutwardScenario!.id, props.rule.id)
    onUpdateFiltersWithValues(savedRule)
  }

  const onStartEditRule = async () => {
    const savedRule = await fetchRuleById(currentOutwardScenario!.id, props.rule.id)
    props.onTriggerEditRule && props.onTriggerEditRule(props.rule.id)
    onUpdateFiltersWithValues(savedRule)
  }

  const onDeleteRule = async () => {
    await deleteRule(currentOutwardScenario!.id, props.rule.id)
      .then(triggerReloadSavedRules)
      .then(() => {
        const rulesIgnoringDeletedOne = props.fullListOfRules.filter((item) => item.id !== props.rule.id)
        const filteredData = calculateFilteredDataApplyingRulesOneAfterAnother(
          fullDataForScenario,
          rulesIgnoringDeletedOne,
        )
        const applicableMarkets = calculateListOfMarkets(filteredData)
        saveOrUpdateListOfApplicableMarkets(currentOutwardScenario!.id, { applicableMarkets })
      })
  }

  return (
    <div
      className={css('OutwardRuleCard', canDrag ? 'CanGrab' : 'CantDrag')}
      key={props.rule.id}
    >
      <DraggableCard
        id={props.rule.id}
        index={props.index}
        onHover={props.onHover ? props.onHover : () => void 0}
        onDrop={props.onDrop ? props.onDrop : () => void 0}
      >
        <div className="CardHeader">
          <h4 className={css('OutwardRuleCardTitle', props.rule.isEnabled ? 'Checked' : 'Unchecked')}>
            {props.rule.name}
          </h4>
          {canBeEdited ? (
            <div className="CardOptions">
              <PreviewIcon
                className="PreviewButton"
                onClick={onPreviewFilters}
              />
              <EditIcon
                className="EditButton"
                onClick={onStartEditRule}
              />
              <DeleteIcon
                className="DeleteButton"
                onClick={onDeleteRule}
              />
            </div>
          ) : (
            <div className="CardOptions">
              <PreviewIcon
                className="PreviewButton"
                onClick={onPreviewFilters}
              />
              <LockedIcon className="LockedIcon" />
            </div>
          )}
        </div>
        <div className={css('OutwardRuleCardContent')}>
          <div className="Values">
            <HeadingWithTitle
              title="Total GWP"
              value={kpisForRule[TOTAL_ADJUSTED_GWP_COLUMN]}
              className="Adjusted"
              prefix="$"
              suffix={suffixForValue(kpisForRule[TOTAL_ADJUSTED_GWP_COLUMN])}
            />
          </div>
          {props.rule.description && (
            <div className="AdjustmentDescription">
              <p>{props.rule.description}ion"</p>
            </div>
          )}
        </div>
      </DraggableCard>
    </div>
  )
}

export default OutwardRuleCard
