import { getCurrentConfigSettings } from '../utils/config'
import { FilterValues } from './calculate-possible-filter-values'
import { getAccessToken } from './teams'

export interface Rule {
  id: string
  name: string
  description?: string
  orderNumber: number
  appliedFilters: FilterValues
  updatedDate: string
  isEnabled?: boolean
}

export interface CreateOutwardRuleRequest {
  id?: string
  name: string
  description?: string
  appliedFilters: FilterValues
  orderNumber?: number
}

export interface PatchRuleRequest {
  name?: string
  description?: string
  appliedFilters?: FilterValues
  orderNumber?: number
  isEnabled?: boolean
}

const isRelevantRule = (ruleId: string) => (rule: Rule) => {
  return rule.id === ruleId
}

const isIrrelevantRule = (ruleId: string) => (rule: Rule) => {
  return rule.id !== ruleId
}

function sortRulesByOldestFirst(inputRule: Rule[]): Rule[] {
  return [...inputRule].sort((first, second) => first.orderNumber - second.orderNumber)
}

async function fetchRules(scenarioId: string): Promise<Rule[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  return await response.json()
}

async function fetchRuleById(scenarioId: string, ruleId: string): Promise<Rule | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules/${ruleId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

async function createRule(scenarioId: string, createRuleRequest: CreateOutwardRuleRequest) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createRuleRequest),
  })

  return await response.json()
}

async function patchRule(scenarioId: string, ruleId: string, patchRuleRequest: PatchRuleRequest) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules/${ruleId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchRuleRequest),
    },
  )

  return await response.json()
}

async function deleteRule(scenarioId: string, ruleId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules/${ruleId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
}

async function deleteAllRules(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}/rules`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
}

export {
  fetchRuleById,
  fetchRules,
  createRule,
  patchRule,
  isRelevantRule,
  isIrrelevantRule,
  sortRulesByOldestFirst,
  deleteRule,
  deleteAllRules,
}
