import { useContext, useMemo, useState } from 'react'
import ContentContainer from '../../components/content-container/ContentContainer'
import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import TextArea from '../../components/text-area/TextArea'
import './Notes.scss'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import Button from '../../components/button/Button'
import { patchScenario } from '../../backend/scenarios'
import { isNoteSectionNames, NotesDataContext, NoteSectionNamesKeys } from './NotesDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { patchStackedScenario } from '../../backend/stacked-scenarios'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { patchOutwardScenario } from '../../backend/outward-scenarios'
import { validateDescription } from '../../components/create-scenario-card/CreateScenarioCard'
import Spacer from '../../components/spacer/Spacer'
import SignOffSummary from './SignOffSummary'

export const SIZE_OF_SECTIONS: Record<NoteSectionNamesKeys, number> = {
  GENERAL: 8,
  MARKET_GENERAL: 5,
  MARKET_CLIMATE_CHANGE: 2,
  MARKET_COMMENTARY: 3,
  PEAK_CONVEX_VISION: 3,
  UW_COMMENTARY_ON_ABE: 3,
  PARAM_GENERAL: 1,
}

function getDisplaySizeOfSection(sectionIdentifier: string): number {
  if (!isNoteSectionNames(sectionIdentifier)) {
    return 1
  }

  return SIZE_OF_SECTIONS[sectionIdentifier]
}

function getValueForSection(
  sectionIdentifier: string,
  currentSectionValues: Partial<Record<NoteSectionNamesKeys, string>>,
): string {
  if (!isNoteSectionNames(sectionIdentifier)) {
    return ''
  }

  const res = currentSectionValues[sectionIdentifier]
  if (!res) {
    return ''
  }

  return res
}

export function NoteSections(props: { onyIncludeSections?: string[] }) {
  const { updateSectionValueInState, currentSectionValues, segmentsToDisplay } = useContext(NotesDataContext)

  return (
    <>
      {segmentsToDisplay
        .filter((item) => (props.onyIncludeSections ? props.onyIncludeSections?.includes(item[1]) : true))
        .map(([sectionIdentifier, sectionDisplayName]) => (
          <TextArea
            key={'note-area-' + sectionIdentifier}
            title={sectionDisplayName}
            value={getValueForSection(sectionIdentifier, currentSectionValues)}
            onChange={(e) => {
              if (!isNoteSectionNames(sectionIdentifier)) {
                return
              }

              updateSectionValueInState(sectionIdentifier, e.target.value)
            }}
            rows={getDisplaySizeOfSection(sectionIdentifier)}
          />
        ))}
    </>
  )
}

function NotesContent() {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const idToUse = useMemo(
    () => currentScenario?.id || currentStackedScenario?.id || currentOutwardScenario?.id,
    [currentScenario, currentStackedScenario, currentOutwardScenario],
  )

  const [currentSummaryValue, setNewSummaryValue] = useState<string>(
    currentScenario?.summary || currentStackedScenario?.description || currentOutwardScenario?.description || '',
  )

  const { triggerSaveOfNotes, notesAreUpToDateWithRemote, setNotesAreUpToDateWithRemote } = useContext(NotesDataContext)

  const saveSummaryChanges = () => {
    if (currentScenario) {
      patchScenario(idToUse!, { summary: currentSummaryValue })
    }

    if (currentStackedScenario) {
      patchStackedScenario(idToUse!, { description: currentSummaryValue })
    }

    if (currentOutwardScenario) {
      patchOutwardScenario(idToUse!, { description: currentSummaryValue })
    }
  }

  const saveBothSummaryAndNotes = () => {
    if (!validateDescription(currentSummaryValue)) {
      triggerSaveOfNotes()
      saveSummaryChanges()
    }
  }

  return (
    <div
      className="Notes"
      id="Notes"
    >
      <PageHeaderContainer>
        <Spacer />
        <div className="SaveContainer">
          <div className="SaveContainerTitle">{notesAreUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}</div>
          <Button
            title={'Save'}
            onClick={saveBothSummaryAndNotes}
          />
        </div>
      </PageHeaderContainer>
      <ContentContainer>
        <SignOffSummary
          userEmail={currentScenario?.status[0].user}
          signOffDate={currentScenario?.status[0].signOffDate}
          status={currentScenario?.status[0].status}
        />
        <TextArea
          title="Summary"
          value={currentSummaryValue}
          onChange={(event) => {
            setNewSummaryValue(event.target.value)
            setNotesAreUpToDateWithRemote(false)
          }}
          rows={2}
          error={validateDescription(currentSummaryValue)}
        />
        <NoteSections />
      </ContentContainer>
    </div>
  )
}

export default NotesContent
