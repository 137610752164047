//TODO - This should be separated out per env (only have a test env at the mo - so laterbase...

import { getCurrentConfigSettings } from '../utils/config'

const AWS_REGION = process.env.AWS_REGION ?? 'eu-west-2'
export const AMPLIFY_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID
export const COGNITO_URL = process.env.REACT_APP_COGNITO_ENDPOINT
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID

const AWSAmplifyConfig = {
  Auth: {
    region: AWS_REGION,
    userPoolWebClientId: AMPLIFY_CLIENT_ID,
    userPoolId: COGNITO_USER_POOL_ID,
    mandatorySignIn: true,
    oauth: {
      domain: COGNITO_URL,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: getCurrentConfigSettings().ssoRedirectLocation,
      redirectSignOut: getCurrentConfigSettings().ssoRedirectLocation,
      responseType: 'code',
    },
  },
}

export default AWSAmplifyConfig
