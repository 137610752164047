import { InceptionMonthFields } from '../types'

export const defaultInceptionMonthValues: InceptionMonthFields = {
  '1': 0,
  '2': 0,
  '3': 0,
  '4': 0,
  '5': 0,
  '6': 0,
  '7': 0,
  '8': 0,
  '9': 0,
  '10': 0,
  '11': 0,
  '12': 0,
}
export const inceptionMonthFieldNames: (keyof InceptionMonthFields)[] = Object.getOwnPropertyNames(
  defaultInceptionMonthValues,
) as (keyof InceptionMonthFields)[]
