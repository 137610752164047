import { useCategoryForm } from './hooks/useCategoryForm'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { useContext } from 'react'
import { insuranceEntities, KnownDivision, reinsuranceEntities } from './portfolioSplitsColumns'
import { FormProvider } from 'react-hook-form'
import { CategoryTable } from './CategoryTable'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { EntityInsuranceFields, EntityReinsuranceFields } from './types'
import { entityInsuranceFieldNames, entityReinsuranceFieldNames } from './data/entity'

export const EntityTable = () => {
  const { divisionQuery } = useContext(AppQueryContext)

  const division: KnownDivision = divisionQuery[0] as KnownDivision
  const isReinsurance = division === 'Reinsurance'
  const isInsurance = division === 'Insurance'

  return isReinsurance ? <EntityReinsuranceTable /> : isInsurance ? <EntityInsuranceTable /> : <></>
}

const EntityReinsuranceTable = () => {
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const initialEntityReinsuranceValues = ncpState.ncpData.categoryWeightings.entity

  const form = useCategoryForm<EntityReinsuranceFields>({
    defaultValues: initialEntityReinsuranceValues,
  })

  return (
    <FormProvider {...form}>
      <CategoryTable<EntityReinsuranceFields>
        categoryLabel="Entity"
        categoryName="entity"
        columns={{
          labels: reinsuranceEntities,
          fields: entityReinsuranceFieldNames,
        }}
        required
      />
    </FormProvider>
  )
}

const EntityInsuranceTable = () => {
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const initialEntityInsuranceValues = ncpState.ncpData.categoryWeightings.entity

  const form = useCategoryForm<EntityInsuranceFields>({
    defaultValues: initialEntityInsuranceValues,
  })

  return (
    <FormProvider {...form}>
      <CategoryTable<EntityInsuranceFields>
        categoryLabel="Entity"
        categoryName="entity"
        columns={{
          labels: insuranceEntities,
          fields: entityInsuranceFieldNames,
        }}
        required
      />
    </FormProvider>
  )
}
