import React from 'react'
import { TableOptions, useTable } from 'react-table'
import './StackedMarketBreakdownByLevelTable.scss'
import SectionHeader from '../section-header/SectionHeader'
import { ScenarioLevel } from '../../backend/stacked-scenarios'

interface StackedMarketBreakdownByTableProps extends TableOptions<any> {
  scenarioLevel: ScenarioLevel | undefined
}

export function displayHeaderBasedOnScenarioLevel(scenarioLevel: ScenarioLevel | undefined) {
  if (!scenarioLevel) {
    return ''
  }

  if (scenarioLevel === ScenarioLevel.TEAM) {
    return 'Market'
  }

  if (scenarioLevel === ScenarioLevel.DIVISIONAL) {
    return 'Team'
  }

  if (scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
    return 'Division'
  }

  return ''
}

export function StackedMarketBreakdownByLevelTable({
  columns,
  data,
  scenarioLevel,
}: StackedMarketBreakdownByTableProps) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data } as any)

  return (
    <div className="StackedMarketBreakdownByLevelTable">
      <SectionHeader
        className="TableTitle"
        title={`Convex ${displayHeaderBasedOnScenarioLevel(scenarioLevel)} Relativities`}
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default StackedMarketBreakdownByLevelTable
