import { ReactElement, useContext } from 'react'
import { captureEvent } from '@sentry/browser'
import { stringify } from 'qs'

import './TopNavigationMenu.scss'

import {
  DEFAULT_LOGGED_IN_PATH,
  isOnOutwardScenarioPath,
  isOnScenarioPath,
  isOnStackedScenarioPath,
  isOnMarketEditor,
  OutwardScenarioPaths,
  ScenarioPaths,
  StackedScenarioPaths,
  MarketEditorPaths,
  WayfinderPathData,
} from '../../utils/paths/Paths'
import { getEnvironment } from '../../utils/urls'
import NavigationMenuItem from '../NavigationMenuItem/NavigationMenuItem'
import { Scenario } from '../../backend/scenarios'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ActualsContext } from '../../providers/ActualDataChoiceProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { aggregateProgress, ProgressContext } from '../../providers/ProgressProvider'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import LogoProd from '../../Logo-prod.png'
import LogoTest from '../../Logo-test.png'
import LogoUat from '../../Logo-uat.png'
import LogoDev from '../../Logo-dev.png'
import LogoIntegration from '../../Logo-integration.png'
import GraphDisplayToggle from '../../pages/renewal/GraphDisplayToggle'
import ProgressLine from '../progress-line/ProgressLine'
import { MarketV2Path } from '../../pages/marketV2/MarketV2'
import { useHistory, useLocation } from 'react-router-dom'

type WayFinderDataSectionType = 'Stacked Scenario' | 'Scenario' | 'Outward Scenario' | 'Market Editor' | undefined

type EnvLogoDictionary = {
  [key in ReturnType<typeof getEnvironment>]: string
}

type MenuPath = {
  pathsInMenu: WayfinderPathData[]
  wayFinderDataSection: WayFinderDataSectionType
}

const logoForEnvironment: EnvLogoDictionary = {
  test: LogoTest,
  preprod: LogoUat,
  dev: LogoDev,
  integration: LogoIntegration,
  prod: LogoProd,
}

const TopNavigationMenu = (): ReactElement => {
  const { progressIndicators } = useContext(ProgressContext)
  const history = useHistory()

  return (
    <div className="WfTopNavigationMenu">
      <div className="TopNavigationMenuTop">
        <img
          className="TopNavigationMenuLogo"
          src={getEnvironmentSpecificLogo()}
          onClick={() => history.push(DEFAULT_LOGGED_IN_PATH.path)}
          alt="Logo"
        />
        <div className="NavigationItems">
          <NavigationItems />
        </div>
        <div className="DataVisualisation">
          <GraphDisplayToggle />
        </div>
      </div>
      <div className="TopNavigationMenuBottom">
        <ProgressLine state={aggregateProgress(progressIndicators)} />
      </div>
    </div>
  )
}

const getEnvironmentSpecificLogo = (): string => logoForEnvironment[getEnvironment()]

const addOnNeededQueryParams = (
  path: string,
  scenario: Scenario | StackedScenario | undefined,
  actual: string | null | undefined,
  division: string | null | undefined,
  team: string | null | undefined,
  marketName: string | null | undefined,
  year: string | number | null | undefined,
) => {
  let queryOptions = {}

  if (scenario && path.includes('scenario-chooser')) {
    queryOptions = {
      ...queryOptions,
      division: scenario.division,
      team: scenario.team,
      year: scenario.year,
      portfoliolevel: 'Divisional',
    }
  }
  if (scenario && path.includes('scenarios')) {
    queryOptions = {
      ...queryOptions,
      division: scenario.division,
    }
  }

  if (actual) {
    queryOptions = { ...queryOptions, actuals: actual }
  }
  if (path.includes(MarketV2Path)) {
    queryOptions = { ...queryOptions, division, team, market: marketName, year }
  }
  return path + '?' + stringify(queryOptions)
}

const useMenuPath = (): MenuPath => {
  const location = useLocation()

  if (isOnStackedScenarioPath(location.pathname)) {
    return {
      pathsInMenu: StackedScenarioPaths,
      wayFinderDataSection: 'Stacked Scenario',
    }
  }
  if (isOnScenarioPath(location.pathname)) {
    return {
      pathsInMenu: ScenarioPaths,
      wayFinderDataSection: 'Scenario',
    }
  }
  if (isOnOutwardScenarioPath(location.pathname)) {
    return {
      pathsInMenu: OutwardScenarioPaths,
      wayFinderDataSection: 'Outward Scenario',
    }
  }
  if (isOnMarketEditor(location.pathname)) {
    return {
      pathsInMenu: MarketEditorPaths,
      wayFinderDataSection: 'Market Editor',
    }
  }

  return {
    pathsInMenu: [],
    wayFinderDataSection: undefined,
  }
}

const NavigationItems = () => {
  const { pathsInMenu, wayFinderDataSection } = useMenuPath()
  const history = useHistory()
  const location = useLocation()

  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { actualsQuery } = useContext(ActualsContext)
  const { yearQuery, divisionQuery, teamQuery, marketQuery } = useContext(AppQueryContext)

  const [actualFromQuery] = actualsQuery
  const [year] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery
  const [marketName] = marketQuery

  return (
    <>
      {pathsInMenu
        .filter((path) => !!path.displayName)
        .map((currentPath) => {
          const redirectTo = currentPath.path
            .replaceAll(':scenarioId', currentScenario?.id!)
            .replaceAll(':stackedScenarioId', currentStackedScenario?.id!)
            .replaceAll(':outwardScenarioId', currentOutwardScenario?.id!)

          const redirectToWithQueryParams = addOnNeededQueryParams(
            redirectTo,
            currentScenario,
            actualFromQuery,
            divisionFromQuery,
            teamFromQuery,
            marketName,
            year,
          )

          const navigationItemOnClick = () => {
            history.push(redirectToWithQueryParams)
            if (process.env.REACT_APP_ENV === 'prod') {
              captureEvent({
                message: `User navigation: ${wayFinderDataSection} - ${currentPath.displayName}`,
              })
            }
          }

          return (
            <div
              className="NavigationItem"
              onClick={navigationItemOnClick}
              key={currentPath.path}
            >
              <NavigationMenuItem
                icon={currentPath.icon!}
                title={currentPath.displayName!}
                navigateTo={redirectToWithQueryParams}
                isCurrentPage={location.pathname.includes(redirectTo)}
              />
            </div>
          )
        })}
    </>
  )
}

export default TopNavigationMenu
