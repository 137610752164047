import React, { ChangeEvent } from 'react'
import { Grid, AutoSizer, GridCellProps } from 'react-virtualized'

import './AutosizedGrid.scss'

import { css } from '../../utils/css'
import { SortIcon } from '../summary-adjustments-graph/table-of-kpis/SortIcon'

interface GridProps {
  data: any[]
  columns: string[]
  onUpdateFilter: (columnName: string) => (event: ChangeEvent<HTMLInputElement>) => void
  onUpdateSortOrder: (columName: string) => void
  heightToSubtractFromFull?: number
}

const NUMBER_OF_FIXED_HEADERS_AT_THE_TOP_OF_THE_PAGE = 1
const COLUMN_WIDTH = 150
const rowClassName = (index: number) => {
  if (index === 0) {
    return 'headerCell'
  }

  return index % 2 === 0 ? 'evenRow' : 'oddRow'
}
const NO_CONTENT = () => <div className={'noCells'}>No cells</div>

export default function AutosizedGrid(props: GridProps) {
  const renderBodyCell = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const currentColumnValue = props.columns[columnIndex]
    const rowClass = rowClassName(rowIndex)

    if (rowIndex === 0) {
      return (
        <div
          className={css(rowClass, 'cell', 'centeredCell', 'containsIcon')}
          key={key}
          style={style}
          onClick={() => props.onUpdateSortOrder(currentColumnValue)}
        >
          <b>{currentColumnValue}</b>
          <SortIcon />
        </div>
      )
    }

    const currentRowValue = props.data[rowIndex - NUMBER_OF_FIXED_HEADERS_AT_THE_TOP_OF_THE_PAGE]

    let currentValueForRowAndColumn = currentRowValue[currentColumnValue]
    if (typeof currentValueForRowAndColumn !== 'string' && typeof currentValueForRowAndColumn !== 'number') {
      if (currentValueForRowAndColumn) {
        currentValueForRowAndColumn = currentValueForRowAndColumn.toString()
      }
    }
    return (
      <div
        className={css(rowClass, 'cell', 'centeredCell')}
        key={key}
        style={style}
      >
        {currentValueForRowAndColumn}
      </div>
    )
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          cellRenderer={renderBodyCell}
          className={'BodyGrid'}
          columnWidth={COLUMN_WIDTH}
          columnCount={props.columns.length}
          height={height - (props.heightToSubtractFromFull || 0)}
          noContentRenderer={NO_CONTENT}
          rowHeight={60}
          rowCount={NUMBER_OF_FIXED_HEADERS_AT_THE_TOP_OF_THE_PAGE + props.data.length}
          width={width}
        />
      )}
    </AutoSizer>
  )
}
