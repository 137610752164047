import React, { useContext } from 'react'
import { LineCanvasProps, ResponsiveLineCanvas } from '@nivo/line'
import { GwpCumulativeLineChartContext } from './GwpCumulativeLineChartDataProvider'
import { GraphContainerContext } from '../../graph-container/GraphContainer'
import { formatForDisplay } from '../../../utils/numbers'
import { GraphStyleContext } from '../GraphStyleProvider'
import { GraphDataContext } from '../GraphDataProvider'
import { Theme } from '@nivo/core'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

interface CumulativeLineGraphGroupedByInceptionMonthProps {
  theme?: Theme
  tickRotation?: number
  legendOffset?: number
  forceExpanded?: string
}

export function GwpAndAdjustedGwpCumulativeLineChart({
  ...otherProps
}: CumulativeLineGraphGroupedByInceptionMonthProps) {
  const { graphData } = useContext(GwpCumulativeLineChartContext)
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const { formatDataFieldForDisplay } = useContext(GraphDataContext)

  const optionsIfGraphExpanded: Omit<LineCanvasProps, 'data'> =
    currentlyExpandedGraph === graphId
      ? {
          margin: { top: 10, right: 20, bottom: 60, left: 80 },
          axisLeft: {
            format: (item: any) => formatForDisplay(item),
            legend: 'Convex Premium',
            legendPosition: 'middle',
            legendOffset: -64,
          },
          axisBottom: {
            format: formatDataFieldForDisplay,
            tickRotation: otherProps.tickRotation || 0,
            legendPosition: 'middle',
            legendOffset: otherProps.legendOffset || 60,
          },
          legends: [
            {
              anchor: 'bottom',
              direction: 'row',
              itemWidth: 150,
              itemHeight: 20,
              translateY: 50,
            },
          ],
        }
      : {}
  return (
    <ResponsiveLineCanvas
      colors={GRAPH_COLOURS}
      data={graphData}
      {...optionsIfGraphExpanded}
      yFormat={(item: any) => formatForDisplay(item)}
    />
  )
}
