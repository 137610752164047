import { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Card } from '../card/Card'

import './OutwardScenarioCard.scss'

import { css } from '../../utils/css'
import ParagraphWithTitle from '../paragraph-with-title/ParagraphWithTitle'
import DeleteIcon from '../icons/DeleteIcon'
import EditIcon from '../icons/EditIcon'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { ScenarioStatusLine } from '../scenario-card/ScenarioStatusLine'
import { StackedScenarioListContext } from '../stacked-scenarios-list/StackedScenarioListProvider'
import { deleteOutwardScenario, OutwardScenario } from '../../backend/outward-scenarios'
import { fetchStackedScenarioById } from '../../backend/stacked-scenarios'
import { StackedScenarioEditingContext } from '../stacked-scenarios-list/StackedScenarioEditingProvider'
import { useIsMounted } from '../../utils/mounting'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import {
  fetchAssembleAndDownloadTheMarketDataForScenarios,
  fetchAssembleAndDownloadThePerilsDataForScenarios,
} from '../stacked-outward-scenario-card/OutwardOutputs'
import NotesIcon from '../icons/NotesIcon'
import MarketIcon from '../icons/MarketIcon'
import { scenarioShouldBeColoured } from '../scenario-card/ScenarioCard'

export type OutwardScenarioCardProps = RouteComponentProps & {
  outwardScenario: OutwardScenario
  triggerScenarioReload: () => void
  triggerEditScenario: (id: string) => void
  canWrite: boolean
  isComparing: boolean
  comparingIsSelected: boolean
  comparingOnToggle: () => void
  isCreatingStackedScenario: boolean
  creatingIsSelected: boolean
  creatingOnToggle: () => void
  uuid?: string
  stopClicking?: boolean
}

export const displayStatus: Record<ScenarioStatus, string> = {
  [ScenarioStatus.IN_PROGRESS]: 'In Progress',
  [ScenarioStatus.IN_REVIEW]: 'In Review',
  [ScenarioStatus.COMPLETE]: 'Complete',
  [ScenarioStatus.DELETED]: 'Missing sub-scenarios',
}

function OutwardScenarioCard({
  outwardScenario,
  history,
  triggerScenarioReload,
  triggerEditScenario,
  stopClicking,
  isCreatingStackedScenario,
  creatingIsSelected,
  creatingOnToggle,
}: OutwardScenarioCardProps) {
  //eslint-disable-next-line
  const { reloadStackedScenarios } = useContext(StackedScenarioListContext)
  const { currentlyChosenScenarioIds } = useContext(StackedScenarioEditingContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const isMounted = useIsMounted()
  const [currentInwardScenarioName, setCurrentInwardScenarioName] = useState('')

  const reloadInwardScenarioName = useCallback(async () => {
    const inwardScenario = await fetchStackedScenarioById(outwardScenario.inwardScenarioId)
    if (isMounted()) {
      setCurrentInwardScenarioName(inwardScenario?.name || '')
    }
  }, [outwardScenario.inwardScenarioId, isMounted])
  useEffect(() => {
    reloadInwardScenarioName()
  }, [reloadInwardScenarioName])

  const onDeleteScenario: MouseEventHandler = async (event) => {
    event.stopPropagation()
    await deleteOutwardScenario(outwardScenario.id)
    triggerScenarioReload()
    reloadStackedScenarios()
  }

  const onTriggerEditScenario: MouseEventHandler = (event) => {
    event.stopPropagation()
    triggerEditScenario(outwardScenario.id)
  }

  const cardOnClick = () => {
    if (stopClicking) {
      return undefined
    }
    if (isCreatingStackedScenario) {
      return creatingOnToggle
    }
    return () => history.push(`/outward-scenarios/${outwardScenario.id}/coverage`)
  }

  const onTriggerPerilsDownload: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('triggerOutwardPerilsDownload', ProgressState.LOADING)
    try {
      await fetchAssembleAndDownloadThePerilsDataForScenarios([outwardScenario.id], outwardScenario.name)

      updateIndividualProgressItem('triggerOutwardPerilsDownload', ProgressState.FINISHED)
    } catch (e) {
      console.error('Error while downloading market data', e)
      updateIndividualProgressItem('triggerOutwardPerilsDownload', ProgressState.ERROR)
    }
  }

  const onTriggerMarketDownload: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('triggerOutwardMarketDownload', ProgressState.LOADING)
    try {
      await fetchAssembleAndDownloadTheMarketDataForScenarios([outwardScenario.id], outwardScenario.name)
      updateIndividualProgressItem('triggerOutwardMarketDownload', ProgressState.FINISHED)
    } catch (e) {
      console.error('Error while downloading market data', e)
      updateIndividualProgressItem('triggerOutwardMarketDownload', ProgressState.ERROR)
    }
  }

  const hasBeenSelected =
    currentlyChosenScenarioIds && Object.values(currentlyChosenScenarioIds).includes(outwardScenario.id)
  const somethingIsSelected = currentlyChosenScenarioIds && currentlyChosenScenarioIds[outwardScenario.layer]
  const canDeleteCard = outwardScenario.canWrite && outwardScenario.status !== ScenarioStatus.COMPLETE

  return (
    <div
      className={css(
        'OutwardScenarioCard',
        !hasBeenSelected && somethingIsSelected ? 'ScenarioCardInactive' : '',
        stopClicking ? 'CantClick' : 'CanClick',
      )}
      onClick={cardOnClick()}
    >
      <Card className={scenarioShouldBeColoured(outwardScenario.name) ? 'ActivityOneColoured' : ''}>
        <div className="Content">
          <ScenarioStatusLine status={outwardScenario.status!} />
          <div className="CardHeader">
            <h4 className="ScenarioCardTitle">{outwardScenario.name}</h4>
            <div className="CardOptions">
              <div className="RightCardOptions">
                <div title="Download Applicable Perils">
                  <NotesIcon
                    className="NotesButton"
                    onClick={onTriggerPerilsDownload}
                  />
                </div>
                <div title="Download Applicable Markets">
                  <MarketIcon
                    className="DownloadButton"
                    onClick={onTriggerMarketDownload}
                  />
                </div>
                {canDeleteCard && !isCreatingStackedScenario && (
                  <div title="Edit">
                    <EditIcon
                      className="EditButton"
                      onClick={onTriggerEditScenario}
                    />
                  </div>
                )}
                {canDeleteCard && !isCreatingStackedScenario && (
                  <div title="Delete">
                    <DeleteIcon
                      className="DeleteButton"
                      onClick={onDeleteScenario}
                    />
                  </div>
                )}
              </div>
            </div>
            {isCreatingStackedScenario && (
              <div className="CardOptions">
                <input
                  type="checkbox"
                  checked={creatingIsSelected}
                  onChange={creatingOnToggle}
                />
              </div>
            )}
          </div>
          <div>
            <ParagraphWithTitle
              title="Inward Scenario Name"
              content={currentInwardScenarioName}
              className="InwardScenarioName"
            />
          </div>
          <div className="TopOptions">
            <ParagraphWithTitle
              title="Updated"
              content={new Date(outwardScenario.updatedAt).toLocaleDateString('en-GB')}
              className="Updated"
            />
          </div>
          <div className="BottomOptions">
            <ParagraphWithTitle
              title="Status"
              content={displayStatus[outwardScenario.status || ScenarioStatus.IN_PROGRESS]}
              className="Status"
            />
            {outwardScenario.description && (
              <ParagraphWithTitle
                title="Description"
                content={outwardScenario.description}
                className="Description"
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default withRouter(OutwardScenarioCard)
