import './ColumnAndValueFilter.scss'

import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { OptionsType } from '../../utils/lists'
import {
  calculateNumberTypeForColumn,
  FilterType,
  FilterValues,
  isNameListFilter,
  isNegatedNumericFilter,
  isNegatedStringFilter,
  isNumericFilter,
  NameListFilter,
  NegatedNumericFilter,
  NumericFilter,
} from '../../backend/calculate-possible-filter-values'
import MinMaxTextField from '../min-max-text-field/MinMaxTextField'
import { useStateCallback } from '../../utils/setStateWithBack'
import {
  formatInceptionYearForDisplay,
  INCEPTION_MONTH_COLUMN_NAME,
} from '../renewal-graph/gwp-bar-chart/inception-month-graph'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { option } from '../../utils/lists'
import SegmentedControlSwitch from '../segmented-control/SegmentedControl'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../backend/calculate-with-actuals'
import { ManyDropdownFilterValues } from '../../providers/FilterProvider/model'

export interface ColumnAndValueFilterProps {
  name: string
  partialOptions?: FilterValues
  options: FilterValues
  onSelected: (columnName: string, selectedValue: FilterType) => void
  current: ManyDropdownFilterValues
  hideMultiSelect?: boolean
  isDisabled?: boolean
  canBeNegated?: boolean
}

enum ToggleOptions {
  NOTNEGATED = '+',
  NEGATED = '-',
}

function toOptions(column: string, values?: FilterType): OptionsType[] {
  if (!values) {
    return []
  }
  if (isNegatedStringFilter(values)) {
    return values.valuesToBeIgnored.map(option())
  }
  if (!Array.isArray(values) || !values.length) {
    return []
  }
  if (column === INCEPTION_MONTH_COLUMN_NAME || column === RENEWAL_INCEPTION_MONTH_COLUMN_NAME) {
    return values.map(option(formatInceptionYearForDisplay))
  }
  return values.map(option(formatEnumForDisplay))
}

function ColumnAndValueFilter(props: ColumnAndValueFilterProps): JSX.Element {
  const [isNegated, setNegated] = useStateCallback<boolean>(false)

  const currentColumn = props.current[props.name]?.columnName
  const currentValue = props.current[props.name]?.chosenValue

  // `partialOptions` overrides `options`
  const filterOptions: FilterValues = isDrilldown(props) ? props.partialOptions : props.options

  const onSelectColumn = (selectedValues: OptionsType[]): void => {
    const empty = !selectedValues.length || !selectedValues[0] || !selectedValues[0].value || !filterOptions
    if (empty) {
      return props.onSelected('', [])
    }
    const selectedValue = selectedValues[0]
    props.onSelected(selectedValue.value, [])
  }

  const onSelectStringValue = (selectedValues: OptionsType[]): void => {
    if (!currentColumn) {
      throw new Error(`Missing currentColumn`)
    }
    if (!selectedValues.length) {
      setNegated(false)
    }
    const rawValues = selectedValues.map(({ value }) => value)
    if (selectedValues.length && isNegated) {
      props.onSelected(currentColumn, { valuesToBeIgnored: rawValues })
    } else {
      props.onSelected(currentColumn, rawValues)
    }
  }

  const onNewNumericValue = (filter: NumericFilter): void => {
    if (!currentColumn) {
      throw new Error(`Missing currentColumn`)
    }
    if (isNegated) {
      props.onSelected(currentColumn, {
        minimumValueOfRangeToIgnoreInclusive: filter.minimumValueInclusive,
        maximumValueOfRangeToIgnoreInclusive: filter.maximumValueInclusive,
      })
    } else {
      props.onSelected(currentColumn, { ...filter })
    }
  }

  const calculateMinimumError = (minimumValue: number): string => {
    if (minimumValue < 0) {
      return `This value can not be any lower than 0}`
    }
    return ''
  }

  const calculateMaximumError = (minimumValue: number, maximumValue: number): string => {
    if (maximumValue < minimumValue) {
      return `This value can not be lower than the current minimum of ${maximumValue}`
    }
    return ''
  }

  const toggleNegation = (selectedItem: string) => {
    const newStateIsNegated = selectedItem === ToggleOptions.NEGATED

    if (newStateIsNegated) {
      setNegated(true, () => void 0)
      if (!isNegatedStringFilter(currentValue) && Array.isArray(currentValue) && currentValue.length) {
        props.onSelected(currentColumn!, { valuesToBeIgnored: currentValue })
      }
      if (isNumericFilter(currentValue)) {
        props.onSelected(currentColumn!, {
          minimumValueOfRangeToIgnoreInclusive: currentValue.minimumValueInclusive,
          maximumValueOfRangeToIgnoreInclusive: currentValue.maximumValueInclusive,
        })
      }
    } else {
      setNegated(false, () => void 0)
      if (isNegatedStringFilter(currentValue)) {
        props.onSelected(currentColumn!, currentValue.valuesToBeIgnored)
      }
      if (isNegatedNumericFilter(currentValue)) {
        props.onSelected(currentColumn!, {
          minimumValueInclusive: currentValue.minimumValueOfRangeToIgnoreInclusive,
          maximumValueInclusive: currentValue.maximumValueOfRangeToIgnoreInclusive,
        })
      }
    }
  }

  const handleModifierKeyBeingPressed = (callback: () => void): void => {
    setNegated(true, callback)
    if (!isNegatedStringFilter(currentValue) && Array.isArray(currentValue) && currentValue.length) {
      props.onSelected(currentColumn!, { valuesToBeIgnored: currentValue })
    }
  }

  if (!filterOptions) {
    return (
      <MultiSelectDropdown
        onSelect={() => void 0}
        options={[]}
        selected={[]}
        isDisabled
      />
    )
  }

  const ALL_OPTIONS = Object.entries(ToggleOptions).map(([key, value]) => ({ name: value, value: value }))

  const primaryDropdown = (): JSX.Element => {
    if (!filterOptions) {
      throw new Error('missing options')
    }
    const selectedValues: OptionsType[] = currentColumn ? [{ label: currentColumn, value: currentColumn }] : []
    const options: OptionsType[] = Object.keys(filterOptions).map(option())
    const allOptions: OptionsType[] = [...options, ...selectedValues]

    return (
      <MultiSelectDropdown
        onSelect={onSelectColumn}
        selected={selectedValues}
        className="ColumnFilter"
        placeholder="Choose a field"
        options={allOptions}
        isDisabled={props.isDisabled}
      />
    )
  }

  const secondaryDropdown = (): JSX.Element => {
    if (!filterOptions) {
      throw new Error('missing options')
    }
    if (!currentColumn) {
      return (
        <MultiSelectDropdown
          onSelect={() => void 0}
          options={[]}
          selected={[]}
          placeholder="Select an option"
          className="ValueFilter"
          isDisabled={props.isDisabled}
        />
      )
    }
    const currentFull: string[] = (props.options[currentColumn] || []) as string[]
    const currentPart: string[] = props.partialOptions
      ? ((props.partialOptions?.[currentColumn] || []) as string[])
      : []
    const pending: FilterType = currentPart.length ? currentPart : currentFull
    if (!isNameListFilter(pending)) {
      return minmax(currentColumn)
    }
    const all: NameListFilter = pending
    const selectedValues = toOptions(currentColumn, currentValue)
    const options = [...selectedValues, ...all.map(option())]
    if (all.length >= 1) {
      return (
        <MultiSelectDropdown
          onSelect={onSelectStringValue}
          hasSelectAll
          hasMultipleSelection={!props.hideMultiSelect}
          placeholder="Select an option"
          selected={selectedValues}
          options={options}
          isDisabled={!currentColumn || props.isDisabled}
          className="ValueFilter"
          onModifierKeyWasBeingPressed={handleModifierKeyBeingPressed}
          negated={isNegatedStringFilter(currentValue)}
        />
      )
    }

    return minmax(currentColumn)
  }

  return (
    <div className="ColumnAndValueFilter">
      {props.canBeNegated && (
        <SegmentedControlSwitch
          options={ALL_OPTIONS}
          selectedOption={isNegated ? 1 : 0}
          onChange={toggleNegation}
          isDisabled={props.isDisabled}
          className="ColumnAndValueFilterSegmentedControl"
        />
      )}
      {primaryDropdown()}
      {secondaryDropdown()}
    </div>
  )

  function minmax(column: string): JSX.Element {
    const numeric = currentValue as NumericFilter | NegatedNumericFilter

    let minimumNumber = isNegated
      ? (numeric as NegatedNumericFilter)?.minimumValueOfRangeToIgnoreInclusive
      : (numeric as NumericFilter)?.minimumValueInclusive
    let maximumNumber = isNegated
      ? (numeric as NegatedNumericFilter)?.maximumValueOfRangeToIgnoreInclusive
      : (numeric as NumericFilter)?.maximumValueInclusive

    return (
      <MinMaxTextField
        numberType={calculateNumberTypeForColumn(column)}
        minValue={column ? minimumNumber : ('' as any)}
        maxValue={column ? maximumNumber : ('' as any)}
        minError={calculateMinimumError(minimumNumber)}
        maxError={calculateMaximumError(minimumNumber, maximumNumber)}
        onChange={onNewNumericValue}
        textCss={isNegated ? 'RedText' : undefined}
        isDisabled={!column || props.isDisabled}
      />
    )
  }
}

// Replace a property in an object type
type Overwrite<T1, T2> = { [P in Exclude<keyof T1, keyof T2>]: T1[P] } & T2

type Drilldown = Overwrite<ColumnAndValueFilterProps, { partialOptions: FilterValues }>

function isDrilldown(props: ColumnAndValueFilterProps): props is Drilldown {
  return Boolean(props.partialOptions)
}

export default ColumnAndValueFilter
