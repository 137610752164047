import React from 'react'
import Select, { OptionProps } from 'react-select'

import './MultiSelectDropdownSmall.scss'

import { css } from '../../utils/css'
import { OptionWithModifierSmall } from './SelectWithModifierSmall'
import { OptionsType } from '../../utils/lists'

export type MultiSelectDropdownSmallProps = {
  className?: string | string[]
  title?: string
  placeholder?: string
  options: OptionsType[]
  selected: OptionsType[]
  hasSelectAll?: boolean
  hasMultipleSelection?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
  testId?: string
  onSelect: (selected: OptionsType[]) => void
  onModifierKeyWasBeingPressed?: (callback: () => void) => void
  negated?: boolean
}

export function MultiSelectDropdown(props: MultiSelectDropdownSmallProps) {
  const onOptionChosen = (value: any) => {
    if (Array.isArray(value)) {
      props.onSelect(value)
    } else if (value) {
      props.onSelect([value as any])
    } else {
      props.onSelect([])
    }
  }

  return (
    <div
      className={css('MultiSelectDropdownSmall', props.className)}
      data-testid={props.testId}
    >
      {props.title && <h4 className="MultiSelectDropdownTitle">{props.title}</h4>}
      <Select
        data-testid={props.testId + '-select'}
        placeholder={props.placeholder}
        className={`react-select-container${props.negated ? ' negated' : ''}`}
        components={
          props.onModifierKeyWasBeingPressed
            ? {
                Option: (optionProps: OptionProps<any, any>) => (
                  <OptionWithModifierSmall
                    onModifierKeyWasBeingPressed={props.onModifierKeyWasBeingPressed!}
                    {...optionProps}
                  />
                ),
              }
            : undefined
        }
        classNamePrefix="react-select"
        options={props.options}
        onChange={onOptionChosen}
        value={props.selected}
        isMulti={props.hasMultipleSelection}
        closeMenuOnSelect={!props.hasMultipleSelection}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        isClearable={false}
      />
    </div>
  )
}

export default MultiSelectDropdown
