import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'
import {
  ADJUSTED_PREMIUM_VALUE,
  ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
  PREMIUM_COLUMN_NAME,
} from '../../../backend/calculate-kpis'
import { SummaryOfAdjustmentsByTypeContext, TreeMapNode } from './SummaryOfAdjustmentsByTypeProvider'
import { groupListOfObjsBy } from '../../../utils/lists'
import { WF_TYPE_OF_DATA_COLUMN, WayfinderDataType } from '../../../backend/TypeOfData'
import { sumListAsIntegersWithFallbackKeys } from '../../../utils/numbers'
import { isNotReplacedByActuals } from '../../../backend/calculate-with-actuals'

export type SupportedNumericFields =
  | typeof ADJUSTED_PREMIUM_VALUE
  | typeof ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION
  | typeof PREMIUM_COLUMN_NAME

interface SummaryTreeMapWithSelectorsContainer {
  graphData: TreeMapNode
}

const SummaryTreeMapWithSelectorsContext = createContext<SummaryTreeMapWithSelectorsContainer>({ graphData: {} })

function displayNameForOutputType(fieldName: string) {
  switch (fieldName) {
    case WayfinderDataType.RENEWAL_ADJUSTED:
      return 'Renewal - Portfolio'
    case WayfinderDataType.RENEWAL_UNADJUSTED:
      return 'Renewal - Unadjusted'
    case WayfinderDataType.NEW:
      return 'New - Portfolio'
    case WayfinderDataType.NEW_CLIENT:
      return 'New - Client'
    case WayfinderDataType.LEGACY_RENEWAL_CLIENT:
    case WayfinderDataType.RENEWAL_CLIENT:
      return 'Renewal - Client'
    case WayfinderDataType.ACTUALS:
      return 'Actuals'

    default:
      return fieldName
  }
}

function SummaryTreeMapWithSelectorsProvider(
  props: PropsWithChildren<{
    groupByField1ToUse: string
    groupByField2ToUse: string
    numericFieldToUse: SupportedNumericFields
  }>,
) {
  const [graphData, setGraphData] = useState({})
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)

  useEffect(() => {
    if (!filteredDataToUseInGraph) {
      return
    }

    const filteredDataWithoutOverridden = filteredDataToUseInGraph?.filter((item) => isNotReplacedByActuals(item))

    const firstGroupedData = groupListOfObjsBy(filteredDataWithoutOverridden, props.groupByField1ToUse)

    const graphData: TreeMapNode = {
      name: 'All',
      type: 'ALL',
      children: [],
    }

    Object.keys(firstGroupedData).forEach((element) => {
      // groupByField1ToUse grouping
      const firstGroup: TreeMapNode = {
        name: props.groupByField1ToUse === WF_TYPE_OF_DATA_COLUMN ? displayNameForOutputType(element) : element,
        children: [],
      }

      const subGroup = groupListOfObjsBy(firstGroupedData[element], props.groupByField2ToUse)

      Object.keys(subGroup).forEach((element) => {
        const sum = sumListAsIntegersWithFallbackKeys(subGroup[element], props.numericFieldToUse, [
          ADJUSTED_PREMIUM_VALUE,
          PREMIUM_COLUMN_NAME,
        ])

        const valueNode: TreeMapNode = {
          name: props.groupByField2ToUse === WF_TYPE_OF_DATA_COLUMN ? displayNameForOutputType(element) : element,
          value: sum.toNumber(),
        }
        firstGroup.children?.push(valueNode)
      })

      graphData.children?.push(firstGroup)
    })

    setGraphData(graphData)
  }, [filteredDataToUseInGraph, props.groupByField1ToUse, props.groupByField2ToUse, props.numericFieldToUse])

  return (
    <SummaryOfAdjustmentsByTypeContext.Provider
      value={{ graphData }}
      children={props.children}
    />
  )
}

export { SummaryTreeMapWithSelectorsProvider, SummaryTreeMapWithSelectorsContext }
