import React, { ReactElement, useContext, useMemo } from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'

import { GRAPH_COLOURS } from '../graphs/Graphs'
import { DEFAULT_GRAPH_THEME } from '../renewal-graph/graph-theme'
import { ExposuresDataContext } from '../../providers/ExposuresDataProvider'
import { GraphStyleContext } from '../renewal-graph/GraphStyleProvider'
import { GraphContainerContext } from '../graph-container/GraphContainer'
import { formatForDisplay } from '../../utils/numbers'
import { AverageAnnualLossTableRowData } from '../../backend/exposures'
import { DEFAULT_LOADING_STATE } from './AverageAnnualLossesTreeMap'

interface Props {
  currentlySelectedReturnPeriod: string
}

export const AverageAnnualLossesBarGraph = (props: Props): ReactElement => {
  const exposuresDataContext = useContext(ExposuresDataContext)
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)

  const optionsIfGraphExpanded: any =
    currentlyExpandedGraph === graphId
      ? {
          margin: { top: 10, right: 0, bottom: 75, left: 80 },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Losses ($)',
            legendPosition: 'middle',
            legendOffset: -70,
          },
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Peril',
            legendPosition: 'middle',
            legendOffset: 40,
          },
        }
      : {}

  const averageAnnualLossTableData = useMemo(() => {
    return exposuresDataContext.averageAnnualLossTableData?.rows ?? [DEFAULT_LOADING_STATE]
  }, [exposuresDataContext.averageAnnualLossTableData])

  const calculatedBarGraphData = useMemo(() => {
    const averageAnnualLossesBarGraphData = []
    if (averageAnnualLossTableData) {
      const fieldToSelect = props.currentlySelectedReturnPeriod
      const adjustedFieldToSelect = 'adjusted' + fieldToSelect[0].toUpperCase() + fieldToSelect.slice(1)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(averageAnnualLossTableData)) {
        const aalRowData = value as AverageAnnualLossTableRowData
        const itemAalValue = Number(parseFloat(value[fieldToSelect]).toFixed(0))
        const itemAdjustedAalValue = Number(parseFloat(value[adjustedFieldToSelect]).toFixed(0))

        if (itemAalValue !== 0 || itemAdjustedAalValue !== 0) {
          averageAnnualLossesBarGraphData.push({
            id: aalRowData.fieldThatWasSplitByValue,
            AAL: itemAalValue,
            adjustedAAL: itemAdjustedAalValue,
          })
        }
      }
    }
    return averageAnnualLossesBarGraphData
  }, [props.currentlySelectedReturnPeriod, averageAnnualLossTableData])

  return (
    <div className="Graph ExposuresAalBarGraph">
      <ResponsiveBarCanvas
        data={calculatedBarGraphData}
        enableLabel={true}
        keys={['AAL', 'adjustedAAL']}
        {...optionsIfGraphExpanded}
        groupMode="grouped"
        labelTextColor="#fff"
        label={(item) => '$' + formatForDisplay(item.value)}
        tooltipFormat={(item: any) => '$' + formatForDisplay(item)}
        colors={GRAPH_COLOURS}
        theme={DEFAULT_GRAPH_THEME}
      />
    </div>
  )
}
