import React, { forwardRef, useContext } from 'react'
import ChartComponent, { LinearComponentProps } from 'react-chartjs-2'
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js'
import 'chartjs-plugin-annotation/chartjs-plugin-annotation.min'
import { NumericFieldChosenAndGroupedByOtherFieldContext } from './NumericFieldChooserAndGroupedByColumnProvider'
import { GraphStyleContext } from '../GraphStyleProvider'
import { GraphContainerContext } from '../../graph-container/GraphContainer'
import { formatForDisplay, numericValueIsDefined } from '../../../utils/numbers'

interface ViolinChartProps {
  isHorizontal?: boolean
}

export const SHARED_CHART_JS_OPTION = (isExpanded: boolean, toolTipOverride?: number) => ({
  legend: {
    display: isExpanded,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem: any, data: any) => {
        return numericValueIsDefined(toolTipOverride) ? 'Median below percentile' : 'Median'
      },
      label: (tooltipItem: any, data: any) => {
        return numericValueIsDefined(toolTipOverride)
          ? formatForDisplay(toolTipOverride!)
          : formatForDisplay(tooltipItem.value)
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          callback: (item: string | number) => (typeof item === 'number' ? formatForDisplay(item) : item),
        },
        display: isExpanded, //this will remove all the x-axis grid lines
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (item: string | number) => (typeof item === 'number' ? formatForDisplay(item) : item),
        },
        display: isExpanded, //this will remove all the x-axis grid lines
      },
    ],
  },
  responsive: true,
  maintainAspectRatio: false,
})

const Violin = forwardRef<any, LinearComponentProps>((props, ref) => (
  <ChartComponent
    {...props}
    ref={ref}
    options={props.options || {}}
  />
))

function calculateAnnotation(inputValue?: number) {
  return {
    annotation: {
      annotations: [
        {
          id: 'maxLine',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: inputValue,
          borderColor: 'grey',
          borderWidth: 2,
          borderDash: [2, 2],
          label: {
            enabled: true,
            content: numericValueIsDefined(inputValue) ? formatForDisplay(inputValue!) : 0,
          },
        },
      ],
    },
  }
}

function ViolinChart(props: ViolinChartProps) {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const isExpanded = currentlyExpandedGraph === graphId
  const { dataReadyForGraph, verticalLineAt, tooltipOverride } = useContext(
    NumericFieldChosenAndGroupedByOtherFieldContext,
  )

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <Violin
        data={dataReadyForGraph}
        type={props.isHorizontal ? 'horizontalViolin' : 'violin'}
        options={{
          ...SHARED_CHART_JS_OPTION(isExpanded, tooltipOverride),
          ...(numericValueIsDefined(verticalLineAt) ? calculateAnnotation(verticalLineAt) : {}),
        }}
        redraw
      />
    </div>
  )
}

export default ViolinChart
