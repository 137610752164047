import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import ContentContainer from '../../components/content-container/ContentContainer'
import MarketConvexAndGraphOverallContainer, {
  calculateAddressableMarketError,
  calculateAdjustedGraphData,
  calculateDollarValueError,
  calculateErrorIfAnyOfTheSegmentsArentFilledIn,
  calculateMarketGwpInputError,
  calculatePercentileError,
  calculateRateChangeError,
} from '../../components/market-convex-and-graph-overall-container/MarketConvexAndGraphOverallContainer'
import Button from '../../components/button/Button'
import { headerGroup } from '../../components/market-splits-table/MarketSplitsTableUtils'
import { asZeroBasedPercentage, PossiblyNegativeNumber } from '../../utils/numbers'
import { createMarketV2, MarketGraphDataV2, MarketRowV2, MarketV2, patchMarketV2 } from '../../backend/market-V2'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { NotesDataContext } from '../notes/NotesDataProvider'
import { calculateErrorForMarketPercentage } from '../../components/market-table/EditableCell'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import Spacer from '../../components/spacer/Spacer'
import { MarketDataContextV2 } from '../../providers/MarketDataProviderV2'
import MarketSplitsBreakdownBySegmentTable from '../../components/market-splits-table/MarketSplitsBreakdownBySegmentTable'
import { MarketV2Context } from './MarketV2Provider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import {
  ACCESS_OR_CAPABILITY,
  ACTUARIAL_ABE_GGLR,
  ACTUARIAL_INFLATION,
  ADDRESSABLE_MARKET_GWP,
  calculateAddressableMarketGwp,
  calculateConvexGwp,
  calculateConvexMarketShare,
  calculateCoreClientsPercentage,
  CONFIDENCE_IN_MARKET_SIZE,
  CONVEX_ACQUISITION_RATIO,
  CONVEX_ESTIMATED_SHARE_OF_PML,
  GRAPH_DATA,
  IN_APPETITE,
  MARKET_GRARC,
  MARKET_NRARC,
  PEAK_CONVEX_GWP,
  PEAK_CONVEX_MARKET_SHARE,
  PML_SCENARIO_1_IN_100,
  PREVIOUS_YEAR_ACQUISITION_RATIO,
  PREVIOUS_YEAR_GGLR,
  PREVIOUS_YEAR_GNLR,
  RATE_ENVIRONMENT,
  SEGMENTS,
  TOTAL_MARKET_GWP,
  UW_ABE_GGLR,
  UW_CONVEX_ACQUISITION_RATIO,
  UW_INFLATION,
  UW_MARKET_GRARC,
} from '../../backend/calculate-kpis'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { toMarketRowV2 } from 'src/backend/market-data'
import { useCalculateMarketKpis } from '../../backend/hooks/useCalculateMarketKpis'

function MarketV2Content(): JSX.Element {
  const { marketData, defaultMarketData, reloadMarketDataFromRemote } = useContext(MarketDataContextV2)
  const { fullDataForScenario, fullNewKpis, fullRenewalKpis } = useContext(FullScenarioDataContext)
  const { isMarketEditorView } = useContext(MarketV2Context)
  const { yearQuery, marketQuery, divisionQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery
  const [currentMarketName] = marketQuery
  const [divisionFromQuery] = divisionQuery

  const [year, setYear] = useState<string | undefined>(yearFromQuery)
  const [marketId, setMarketId] = useState<undefined | string>(undefined)
  const [marketName, setMarketName] = useState<undefined | string>(currentMarketName ?? undefined)
  const [division, setDivision] = useState<undefined | string | null>(divisionFromQuery ?? undefined)

  const [totalMarketGwp, setTotalMarketGwp] = useState<PossiblyNegativeNumber>(0)
  const [pmlScenario1In100, setPmlScenario1In100] = useState<PossiblyNegativeNumber>(0)
  const [confidenceInMarketSize, setConfidenceInMarketSize] = useState<PossiblyNegativeNumber>(0)
  const [accessOrCapability, setAccessOrCapability] = useState<PossiblyNegativeNumber>(0)
  const [inAppetite, setInAppetite] = useState<PossiblyNegativeNumber>(0)

  const [manuallyEditedAddressableMarketGwp, setManuallyEditedAddressableMarketGwp] = useState<boolean>(false)
  const [addressableMarketGwp, setAddressableMarketGwp] = useState<PossiblyNegativeNumber>(
    calculateAddressableMarketGwp(totalMarketGwp, accessOrCapability, inAppetite),
  )

  const onManualOverrideAddressableMarketGwp: Dispatch<SetStateAction<PossiblyNegativeNumber>> = (value) => {
    setManuallyEditedAddressableMarketGwp(true)
    setAddressableMarketGwp(value)
  }

  useEffect(() => setYear(yearFromQuery), [yearFromQuery])
  useEffect(() => setDivision(divisionFromQuery), [divisionFromQuery])
  useEffect(() => setMarketName(currentMarketName ?? undefined), [currentMarketName])
  useEffect(() => {
    setManuallyEditedAddressableMarketGwp(false)
    const next: number = calculateAddressableMarketGwp(totalMarketGwp, accessOrCapability, inAppetite)
    setAddressableMarketGwp(next)
  }, [totalMarketGwp, accessOrCapability, inAppetite])

  const [rateEnvironment, setRateEnvironment] = useState<string | undefined>(undefined)
  const [convexGwp, setConvexGwp] = useState<PossiblyNegativeNumber>(0)
  const [convexMarketShare, setConvexMarketShare] = useState<PossiblyNegativeNumber>(0)
  const [peakConvexGwp, setPeakConvexGwp] = useState<PossiblyNegativeNumber>(0)
  const [peakConvexMarketShare, setPeakConvexMarketShare] = useState<PossiblyNegativeNumber>(0)
  const [convexEstimatedShareOfPml, setConvexEstimatedShareOfPml] = useState<PossiblyNegativeNumber>(0)
  const [coreClients, setCoreClients] = useState<PossiblyNegativeNumber>(0)
  const [previousYearGnlr, setPreviousYearGnlr] = useState<PossiblyNegativeNumber>(0)
  const [previousYearGglr, setPreviousYearGglr] = useState<PossiblyNegativeNumber>(0)
  const [previousYearConvexAcquisitionRatio, setPreviousYearConvexAcquisitionRatio] =
    useState<PossiblyNegativeNumber>(0)
  const [convexAcquisitionRatio, setConvexAcquisitionRatio] = useState<PossiblyNegativeNumber>(0)
  const [marketGrarc, setMarketGrarc] = useState<PossiblyNegativeNumber>(0)
  const [marketNrarc, setMarketNrarc] = useState<PossiblyNegativeNumber>(0)
  const [inflation, setInflation] = useState<PossiblyNegativeNumber>(0)
  const [AbeGglr, setAbeGglr] = useState<PossiblyNegativeNumber>(0)
  const [uwConvexAcquisitionRatio, setUwConvexAcquisitionRatio] = useState<PossiblyNegativeNumber>(0)
  const [uwMarketGrarc, setUwMarketGrarc] = useState<PossiblyNegativeNumber>(0)
  const [uwInflation, setUwInflation] = useState<PossiblyNegativeNumber>(0)
  const [uwAbeGglr, setUwAbeGglr] = useState<PossiblyNegativeNumber>(0)
  const [graphData, setGraphData] = useState<MarketGraphDataV2[]>([
    {
      y: [],
      x: [],
      type: '',
      boxpoints: true,
      name: '',
    },
  ])
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [shouldCreate, setShouldCreate] = useState<boolean>(true)
  const [isUpToDateWithRemote, setIsUptoDate] = useState<boolean>(true)
  const [editableFieldsData, setEditableFieldsData] = useState<Array<MarketRowV2>>([])

  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { triggerSaveOfNotes, notesAreUpToDateWithRemote } = useContext(NotesDataContext)

  const marketKpis = useCalculateMarketKpis()

  const updateWithNewMarketData = (): void => {
    setShouldCreate(!marketData || marketData.id === undefined || marketData.id === '')
    setMarketId(marketData?.id)
    setMarketName(currentMarketName ?? undefined)
    setYear(yearFromQuery)

    if (!marketKpis) {
      console.warn('MarketV2Content - updateWithNewMarketData - marketKpis does not exist, so we cannot set state')
      return
    }
    setTotalMarketGwp(marketKpis[TOTAL_MARKET_GWP])
    setPmlScenario1In100(marketKpis[PML_SCENARIO_1_IN_100])
    setConfidenceInMarketSize(marketKpis[CONFIDENCE_IN_MARKET_SIZE])
    setAccessOrCapability(marketKpis[ACCESS_OR_CAPABILITY])
    setInAppetite(marketKpis[IN_APPETITE])
    setAddressableMarketGwp(marketKpis[ADDRESSABLE_MARKET_GWP])
    setRateEnvironment(marketKpis[RATE_ENVIRONMENT])

    const calculatedConvexGwp = calculateConvexGwp(fullRenewalKpis, fullNewKpis)
    setConvexGwp(calculatedConvexGwp.toNumber())
    const calculatedConvexMarketShare = marketKpis[TOTAL_MARKET_GWP]
      ? calculateConvexMarketShare(calculatedConvexGwp, marketKpis[TOTAL_MARKET_GWP])
      : undefined
    setConvexMarketShare(calculatedConvexMarketShare)
    setPeakConvexGwp(marketKpis[PEAK_CONVEX_GWP])
    setPeakConvexMarketShare(marketKpis[PEAK_CONVEX_MARKET_SHARE])
    setConvexEstimatedShareOfPml(marketKpis[CONVEX_ESTIMATED_SHARE_OF_PML])
    const calculatedCoreClients = calculateCoreClientsPercentage(fullDataForScenario)
    setCoreClients(calculatedCoreClients)
    setPreviousYearGnlr(marketKpis[PREVIOUS_YEAR_GNLR])
    setPreviousYearConvexAcquisitionRatio(marketKpis[PREVIOUS_YEAR_ACQUISITION_RATIO])
    setInflation(marketKpis[ACTUARIAL_INFLATION])
    setMarketGrarc(marketKpis[MARKET_GRARC])
    setPreviousYearGglr(marketKpis[PREVIOUS_YEAR_GGLR])
    setConvexAcquisitionRatio(marketKpis[CONVEX_ACQUISITION_RATIO])
    setMarketNrarc(marketKpis[MARKET_NRARC])
    setAbeGglr(marketKpis[ACTUARIAL_ABE_GGLR])
    setUwConvexAcquisitionRatio(marketKpis[UW_CONVEX_ACQUISITION_RATIO])
    setUwMarketGrarc(marketKpis[UW_MARKET_GRARC])
    setUwInflation(marketKpis[UW_INFLATION])
    setUwAbeGglr(marketKpis[UW_ABE_GGLR])
    setGraphData(marketKpis[GRAPH_DATA])
    setEditableFieldsData(toMarketRowV2(marketKpis[SEGMENTS], fullDataForScenario))

    setIsUptoDate(true)
  }

  // eslint-disable-next-line
  useEffect(updateWithNewMarketData, [
    currentMarketName,
    yearFromQuery,
    marketData,
    defaultMarketData,
    fullDataForScenario,
    fullNewKpis,
    fullRenewalKpis,
  ])

  const updateMyData = useCallback(
    (rowIndex: number, columnId: string, newValue: string) => {
      setEditableFieldsData((previousValue) =>
        previousValue.map((row, index) => {
          if (index === rowIndex) {
            let marketGwp = editableFieldsData[rowIndex]['marketGwp']

            if (columnId === 'marketPercentage') {
              marketGwp = Number((Number(totalMarketGwp) * asZeroBasedPercentage(newValue || 0).toNumber()).toFixed(0))
            }

            return {
              ...editableFieldsData[rowIndex],
              [columnId]: newValue,
              marketGwp: marketGwp,
            }
          }
          return row
        }),
      )
    },
    [editableFieldsData, totalMarketGwp],
  )

  function calculateCreateOrUpdateRequest(): undefined | MarketV2 {
    if (!marketName) {
      console.log('Missing marketName')
      return
    }
    if (!year) {
      console.log('Missing year')
      return
    }
    return {
      id: marketId,
      marketName: marketName,
      year: year,
      totalMarketGwp: Number(totalMarketGwp),
      pmlScenario1In100: pmlScenario1In100?.toString() || '',
      confidenceInMarketSize: confidenceInMarketSize?.toString() || '',
      accessOrCapability: accessOrCapability?.toString() || '',
      inAppetite: inAppetite?.toString() || '',
      addressableMarketGwp: Number(addressableMarketGwp),
      rateEnvironment: rateEnvironment?.toString() || '',
      peakConvexGwp: Number(peakConvexGwp),
      peakConvexMarketShare: peakConvexMarketShare?.toString() || '',
      convexEstimatedShareOfPml: Number(convexEstimatedShareOfPml),
      previousYearGnlr: previousYearGnlr?.toString() || '',
      previousYearGglr: previousYearGglr?.toString() || '',
      previousYearConvexAcquisitionRatio: previousYearConvexAcquisitionRatio?.toString() || '',
      convexAcquisitionRatio: convexAcquisitionRatio?.toString() || '',
      marketGrarc: marketGrarc?.toString() || '',
      marketNrarc: marketNrarc?.toString() || '',
      inflation: inflation?.toString() || '',
      AbeGglr: AbeGglr?.toString() || '',
      uwConvexAcquisitionRatio: uwConvexAcquisitionRatio?.toString() || '',
      uwMarketGrarc: uwMarketGrarc?.toString() || '',
      uwInflation: uwInflation?.toString() || '',
      uwAbeGglr: uwAbeGglr?.toString() || '',
      marketSegments: segmentBreakdown as any,
      graphData: adjustedGraphData,
    }
  }

  async function validateThenCreateOrUpdateMarket() {
    setShouldValidate(true)

    if (
      calculateMarketGwpInputError(totalMarketGwp, segmentBreakdown as any) ||
      calculatePercentileError(pmlScenario1In100) ||
      calculatePercentileError(confidenceInMarketSize) ||
      calculatePercentileError(accessOrCapability) ||
      calculatePercentileError(inAppetite) ||
      calculateAddressableMarketError(addressableMarketGwp, totalMarketGwp) ||
      calculatePercentileError(inAppetite) ||
      !rateEnvironment ||
      calculateDollarValueError(peakConvexGwp) ||
      calculateDollarValueError(convexEstimatedShareOfPml) ||
      calculateRateChangeError(uwMarketGrarc) ||
      calculatePercentileError(uwInflation) ||
      calculateErrorIfAnyOfTheSegmentsArentFilledIn(segmentBreakdown as any) ||
      segmentBreakdown.find((segment) => calculateErrorForMarketPercentage(true, segment.marketPercentage))
    ) {
      return
    }

    const marketData: undefined | MarketV2 = calculateCreateOrUpdateRequest()
    if (!marketData) {
      console.log('Missing marketData')
      return
    }

    updateIndividualProgressItem('saveMarket', ProgressState.LOADING)
    if (shouldCreate) {
      await createMarketV2(division, marketName, year, marketData)
        .then(reloadMarketDataFromRemote)
        .finally(() => updateIndividualProgressItem('saveMarket', ProgressState.FINISHED))
    } else {
      await patchMarketV2(division, marketName, year, marketData)
        .then(reloadMarketDataFromRemote)
        .finally(() => updateIndividualProgressItem('saveMarket', ProgressState.FINISHED))
    }

    triggerSaveOfNotes()
  }

  const columns = useMemo(() => headerGroup(isMarketEditorView), [isMarketEditorView])

  const segmentBreakdown: Array<MarketRowV2> = useMemo(() => editableFieldsData, [editableFieldsData])

  const adjustedGraphData = useMemo(
    () => calculateAdjustedGraphData(defaultMarketData, previousYearConvexAcquisitionRatio, marketGrarc),
    [defaultMarketData, previousYearConvexAcquisitionRatio, marketGrarc],
  )

  const downloadOverallCsv = () => {
    const fileName = `${marketName} - ${year}`

    let data = {
      'Market Name': marketName,
      Year: year,
      'Total Market Gwp': totalMarketGwp,
      'PML Scenario 1 in 100': pmlScenario1In100,
      'Confidence in Market Size': confidenceInMarketSize,
      'Access or Capability': accessOrCapability,
      'In Appetite': inAppetite,
      'Addressable Market GWP': addressableMarketGwp,
      'Rate Environment': rateEnvironment,
      'Peak Convex GWP': peakConvexGwp,
      'Peak Convex Market Share': peakConvexMarketShare,
      'Convex Estimated Share of PML': convexEstimatedShareOfPml,
      'Previous Year GNLR': previousYearGnlr,
      'Previous Year GGLR': previousYearGglr,
      'Convex Acquisition Ratio': convexAcquisitionRatio,
      'Market GRARC': marketGrarc,
      'Market NRARC': marketNrarc,
      Inflation: inflation,
      'ABE GGLR': AbeGglr,
      'Underwriter Convex Acquisition Ratio': uwConvexAcquisitionRatio,
      'Underwriter Market GRARC': uwMarketGrarc,
      'Underwriter Inflation': uwInflation,
      'Underwriter ABE GGLR': uwAbeGglr,
    }

    if (!isMarketEditorView) {
      const additionalFields = {
        'Convex GWP': convexGwp,
        'Convex Market Share': convexMarketShare,
        'Core Clients': coreClients,
      }
      data = { ...data, ...additionalFields }
    }

    const allRawLines = createCsvFile([data])

    saveDataToFile(fileName, allRawLines)
  }

  const downloadSegmentsCsv = () => {
    const fileName = `${marketName} - ${year} - Segments`

    const data = segmentBreakdown.map((segment) => {
      let dataToReturn = {
        'Market Name': marketName,
        Year: year,
        Segment: segment.segmentName,
        'Market Percentage': segment.marketPercentage,
        'Market GWP': segment.marketGwp,
        Inflation: segment.inflation,
        'Rate Environment': segment.rateEnvironment,
      }

      if (!isMarketEditorView) {
        const additionalFields = {
          'Convex GWP': segment.convexGwp,
          'Market Share': segment.marketShare,
        }
        dataToReturn = { ...dataToReturn, ...additionalFields }
      }

      return dataToReturn
    })

    const allRawLines = createCsvFile(data)

    saveDataToFile(fileName, allRawLines)
  }

  if (!year) {
    return <div>Missing year</div>
  }
  if (!marketName) {
    return <div>Missing marketName</div>
  }

  return (
    <div>
      <PageHeaderContainer>
        <Spacer />
        <div className="SaveContainer">
          {isMarketEditorView && (
            <div className="SaveContainerTitle">
              {!shouldCreate && isUpToDateWithRemote && notesAreUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}
            </div>
          )}
          <div className="ButtonsContainer">
            {isMarketEditorView && (
              <Button
                title="Save"
                onClick={validateThenCreateOrUpdateMarket}
              />
            )}
            <div className="CsvButton">
              <Button
                title="Overall CSV"
                onClick={downloadOverallCsv}
              />
            </div>
            <div className="CsvButton">
              <Button
                title="Segments CSV"
                onClick={downloadSegmentsCsv}
              />
            </div>
          </div>
        </div>
      </PageHeaderContainer>
      <ContentContainer>
        <MarketConvexAndGraphOverallContainer
          year={year}
          marketName={marketName}
          totalMarketGwp={[totalMarketGwp, setTotalMarketGwp]}
          pmlScenario1In100={[pmlScenario1In100, setPmlScenario1In100]}
          confidenceInMarketSize={[confidenceInMarketSize, setConfidenceInMarketSize]}
          accessOrCapability={[accessOrCapability, setAccessOrCapability]}
          inAppetite={[inAppetite, setInAppetite]}
          manuallyEditedAddressableMarketGwp={manuallyEditedAddressableMarketGwp}
          addressableMarketGwp={[addressableMarketGwp, onManualOverrideAddressableMarketGwp]}
          rateEnvironment={[rateEnvironment, setRateEnvironment]}
          convexGwp={[convexGwp, setConvexGwp]}
          convexMarketShare={[convexMarketShare, setConvexMarketShare]}
          peakConvexGwp={[peakConvexGwp, setPeakConvexGwp]}
          peakConvexMarketShare={[peakConvexMarketShare, setPeakConvexMarketShare]}
          convexEstimatedShareOfPml={[convexEstimatedShareOfPml, setConvexEstimatedShareOfPml]}
          coreClients={[coreClients, setCoreClients]}
          previousYearGnlr={[previousYearGnlr, setPreviousYearGnlr]}
          previousYearGglr={[previousYearGglr, setPreviousYearGglr]}
          convexAcquisitionRatio={[convexAcquisitionRatio, setConvexAcquisitionRatio]}
          marketGrarc={[marketGrarc, setMarketGrarc]}
          marketNrarc={[marketNrarc, setMarketNrarc]}
          inflation={[inflation, setInflation]}
          AbeGglr={[AbeGglr, setAbeGglr]}
          uwConvexAcquisitionRatio={[uwConvexAcquisitionRatio, setUwConvexAcquisitionRatio]}
          uwMarketGrarc={[uwMarketGrarc, setUwMarketGrarc]}
          uwInflation={[uwInflation, setUwInflation]}
          uwAbeGglr={[uwAbeGglr, setUwAbeGglr]}
          graphData={graphData}
          perSegmentBreakdown={segmentBreakdown as any}
          shouldValidate={shouldValidate}
          setIsUpToDate={setIsUptoDate}
        />
        <MarketSplitsBreakdownBySegmentTable
          data={segmentBreakdown as any}
          columns={columns as any}
          updateMyData={updateMyData}
          setIsUpToDate={setIsUptoDate}
          shouldValidate={shouldValidate}
        />
      </ContentContainer>
    </div>
  )
}

export default MarketV2Content
