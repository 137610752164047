import React, { MouseEventHandler, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { Card } from '../card/Card'

import './ScenarioCard.scss'

import ParagraphWithTitle, { Title } from '../../components/paragraph-with-title/ParagraphWithTitle'
import { deleteScenario, Scenario } from '../../backend/scenarios'
import DeleteIcon from '../icons/DeleteIcon'
import EditIcon from '../icons/EditIcon'
import NotesIcon from '../icons/NotesIcon'
import ChainedIcon from '../icons/ChainedIcon'
import { css } from '../../utils/css'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { DuplicateIcon } from '../edit-icon/DuplicateIcon'
import { ScenarioStatusLine } from './ScenarioStatusLine'
import { StackedScenarioListContext } from '../stacked-scenarios-list/StackedScenarioListProvider'
import { StackedScenarioEditingContext } from '../stacked-scenarios-list/StackedScenarioEditingProvider'
import { DUPLICATING_SCENARIO_MODAL_QUERY_ID } from '../../pages/scenario-chooser/FinishDuplicatingScenarioModal'
import { fetchTagForEntity, TagResponse } from '../../backend/tags'
import Tag, {
  getBackgroundColourForTag,
  getColourForTag,
  getDisplayForTag,
  getTooltipForTag,
  TagContainer,
} from '../tag/Tag'
import { ProgressContext } from '../../providers/ProgressProvider'
import { MAX_LENGTH_OF_SHORT_STRINGS, trimStringIfTooLong } from '../../utils/strings'
import TagIcon from '../icons/TagIcon'
import { ScenarioChooserContext } from '../../pages/scenario-chooser/ScenarioChooser'
import { AddingTagModalContext } from '../adding-tag-modal/AddingTagModalContext'
import { sanitizeId } from '../../utils/ids'
import qs from 'qs'

export type Props = RouteComponentProps & {
  scenario: Scenario
  triggerScenarioReload: () => void
  triggerEditScenario: (id: string) => void
  canWrite: boolean
  isComparing: boolean
  comparingIsSelected: boolean
  comparingOnToggle: () => void
  isCreatingTeamScenario: boolean
  creatingIsSelected: boolean
  creatingOnToggle: () => void
  uuid?: string
  className?: string
  stopClicking?: boolean
  id?: string
  division?: string
}

export function scenarioShouldBeColoured(scenarioName: string): boolean {
  return scenarioName === 'Activity 1' || scenarioName === 'Current Estimate'
}

export const displayStatus: Record<ScenarioStatus, string> = {
  [ScenarioStatus.IN_PROGRESS]: 'In Progress',
  [ScenarioStatus.IN_REVIEW]: 'In Review',
  [ScenarioStatus.COMPLETE]: 'Complete',
  [ScenarioStatus.DELETED]: 'Missing',
}

function ScenarioCard(props: Props): React.ReactElement {
  const {
    scenario,
    history,
    triggerScenarioReload,
    triggerEditScenario,
    canWrite,
    isComparing,
    comparingIsSelected,
    comparingOnToggle,
    isCreatingTeamScenario,
    creatingIsSelected,
    creatingOnToggle,
    stopClicking,
    className,
  } = props

  const [, setCurrentlyDuplicatingScenarioId] = useQueryParam(DUPLICATING_SCENARIO_MODAL_QUERY_ID, StringParam)

  const { setEntityId, setMarket, setDivision } = useContext(AddingTagModalContext)
  const { reloadStackedScenarios } = useContext(StackedScenarioListContext)
  const { currentlyChosenScenarioIds } = useContext(StackedScenarioEditingContext)
  const { hideUntagged } = useContext(ScenarioChooserContext)
  const { progressIndicators } = useContext(ProgressContext)

  const [tags, setListOfTags] = useState<TagResponse[]>([])

  const isHidden = tags.length === 0 && hideUntagged

  const reloadTags = () => {
    fetchTagForEntity(scenario.id).then((listOfTags) => {
      setListOfTags(listOfTags)
    })
  }

  useEffect(reloadTags, [scenario.id, progressIndicators.createStackedScenario])

  const onDeleteScenario: MouseEventHandler = (event) => {
    event.stopPropagation()
    deleteScenario(scenario.id).then(triggerScenarioReload).then(reloadStackedScenarios)
  }
  const onChainedScenario: MouseEventHandler = () => {
    return <Link to={`/scenarios/${scenario.chainedScenarioId}`} />
  }

  const onOpenDuplicateModal: MouseEventHandler = (event) => {
    event.stopPropagation()
    setCurrentlyDuplicatingScenarioId(scenario.id)
  }

  const onTriggerEditScenario: MouseEventHandler = (event) => {
    event.stopPropagation()
    triggerEditScenario(scenario.id)
  }

  const onNotes: MouseEventHandler = (event) => {
    event.stopPropagation()
    history.push(`/scenarios/${scenario.id}/notes`)
  }

  const cardOnClick = () => {
    if (stopClicking) {
      return undefined
    }
    if (isComparing) {
      return comparingOnToggle
    }
    if (isCreatingTeamScenario) {
      return creatingOnToggle
    }

    const params = qs.stringify({
      division: scenario.division,
      team: scenario.team,
      year: scenario.year,
      market: scenario.market,
    })
    if (scenario.currentYearActualsDataName) {
      return () =>
        history.push(`/scenarios/${scenario.id}/summary?${params}&actuals=${scenario.currentYearActualsDataName}`)
    }

    return () => history.push(`/scenarios/${scenario.id}/summary?${params}`)
  }

  const onOpenTagModal: MouseEventHandler = async (event) => {
    event.stopPropagation()
    setEntityId(scenario.id)
    setMarket(scenario.market)
    setDivision(scenario.division)
  }

  const hasBeenSelected = currentlyChosenScenarioIds && Object.values(currentlyChosenScenarioIds).includes(scenario.id)
  const somethingIsSelected = currentlyChosenScenarioIds && currentlyChosenScenarioIds[scenario.market]
  const canDeleteCard = scenario.status[0].status !== ScenarioStatus.COMPLETE

  const previousYear = parseInt(scenario.year) - 1
  const currentYear = parseInt(scenario.year)
  return isHidden ? (
    <div />
  ) : (
    <div
      className={css(
        'ScenarioCard',
        className,
        !hasBeenSelected && somethingIsSelected ? 'ScenarioCardInactive' : '',
        stopClicking ? 'CantClick' : 'CanClick',
      )}
      onClick={cardOnClick()}
    >
      <Card className={scenarioShouldBeColoured(scenario.name) ? 'ActivityOneColoured' : ''}>
        <div className="Content">
          <ScenarioStatusLine status={scenario.status[0].status} />
          <div className="CardHeader">
            <h4 className="ScenarioCardTitle">{trimStringIfTooLong(scenario.name, MAX_LENGTH_OF_SHORT_STRINGS)}</h4>
            {canWrite && (
              <div className="CardOptions">
                <div className="LeftCardOptions">
                  {scenario.chainedScenarioId && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      title="Chained Scenario"
                    >
                      <Link to={`/scenarios/${scenario.chainedScenarioId}/summary`}>
                        <div title="Chained Scenario">
                          <ChainedIcon
                            className="ChainedButton"
                            onClick={onChainedScenario}
                          />
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="RightCardOptions">
                  {canWrite && (
                    <div title="Tags">
                      <TagIcon
                        className="TagButton"
                        id={`${sanitizeId(scenario.name)}-Tags`}
                        onClick={onOpenTagModal}
                      />
                    </div>
                  )}
                  {canDeleteCard && (
                    <div title="Edit">
                      <EditIcon
                        className="EditButton"
                        id={`${sanitizeId(scenario.name)}-Edit`}
                        onClick={onTriggerEditScenario}
                      />
                    </div>
                  )}
                  <div title="Notes">
                    <NotesIcon
                      className="NotesButton"
                      id={`${sanitizeId(scenario.name)}-Notes`}
                      onClick={onNotes}
                    />
                  </div>
                  <div title="Duplicate">
                    <DuplicateIcon
                      className="DuplicateButton"
                      id={`${sanitizeId(scenario.name)}-Duplicate`}
                      onClick={onOpenDuplicateModal}
                    />{' '}
                  </div>
                  {canDeleteCard && (
                    <div title="Delete">
                      <DeleteIcon
                        className="DeleteButton"
                        id={`${sanitizeId(scenario.name)}-Delete`}
                        onClick={onDeleteScenario}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {isComparing && (
              <div className="CardOptions">
                <input
                  type="checkbox"
                  id={`${sanitizeId(`${scenario.market}-${scenario.name}`)}`}
                  checked={comparingIsSelected}
                  onChange={comparingOnToggle}
                />
              </div>
            )}
            {isCreatingTeamScenario && (
              <div className="CardOptions">
                <input
                  type="checkbox"
                  id={`${sanitizeId(`${scenario.market}-${scenario.name}`)}`}
                  checked={creatingIsSelected}
                  onChange={creatingOnToggle}
                />
              </div>
            )}
          </div>
          <div className="CardContent">
            <div className="Options">
              <div className="LeftOptions">
                <ParagraphWithTitle
                  title="Status"
                  id={`${sanitizeId(scenario.name)}-Status`}
                  content={displayStatus[scenario.status[0].status || ScenarioStatus.IN_PROGRESS]}
                  className="Status"
                />
                <ParagraphWithTitle
                  title={scenario.chainedScenarioId ? `${parseInt(scenario.year) - 1} Source Scenario` : 'Source Data'}
                  id={`${sanitizeId(scenario.name)}-Source-Data`}
                  content={scenario.data}
                  className="Data"
                />
              </div>
              <div className="RightOptions">
                <ParagraphWithTitle
                  title="Updated"
                  id={`${sanitizeId(scenario.name)}-Updated`}
                  content={new Date(scenario.updatedAt).toLocaleDateString('en-GB')}
                  className="Updated"
                />
                {(scenario.previousYearActualsDataName || scenario.currentYearActualsDataName) && (
                  <ParagraphWithTitle
                    title={`${scenario.previousYearActualsDataName ? previousYear : currentYear} Source Actuals`}
                    id={`${sanitizeId(scenario.name)}-Source-Actuals`}
                    content={scenario.previousYearActualsDataName || scenario.currentYearActualsDataName}
                    className="PreviousActuals"
                  />
                )}
              </div>
              {!!tags.length && (
                <div className="Tags">
                  <Title title={'Tags'} />
                  <TagContainer>
                    {tags.map((tagObject) => (
                      <Tag
                        title={getDisplayForTag(tagObject.name)}
                        id={`${sanitizeId(scenario.name)}-${getDisplayForTag(tagObject.name)}`}
                        textColor={getColourForTag(tagObject.name)}
                        backgroundColor={getBackgroundColourForTag(tagObject.name)}
                        key={`${scenario.id}-${tagObject.name}`}
                        tooltip={getTooltipForTag(tagObject.name)}
                      />
                    ))}
                  </TagContainer>
                </div>
              )}
            </div>
            {scenario.summary && (
              <div className="ScenarioCardSummary">
                <p>{trimStringIfTooLong(scenario.summary)}</p>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}
export default withRouter(ScenarioCard)
