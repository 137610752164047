import { useContext } from 'react'
import KpiCard from '../kpi-card/KpiCard'
import {
  formatForAnimation,
  isZero,
  numericValueIsDefined,
  ONE_HUNDRED,
  suffixForValue,
  ZERO,
} from '../../utils/numbers'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import './Kpis.scss'
import {
  ADJUSTMENT_ID,
  TOTAL_ADJUSTED_GWP_COLUMN,
  TOTAL_GWP_COLUMN,
  TOTAL_GWP_LEFT_TO_ADJUST_COLUMN,
} from '../../backend/calculate-kpis'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import CountUp from 'react-countup'
import Big from 'big.js'
import Divider from '../divider/LineDivider'
import { AggregatedMarketDataContextV2 } from '../../providers/AggregatedMarketDataProviderV2'
import { usePrevious } from '../../utils/hooks/usePrevious'

function getNumberOfUniqueIdsInData(data: any[]): number {
  const uniqueIds = new Set()
  data.forEach((adjustment) => {
    uniqueIds.add(adjustment[ADJUSTMENT_ID])
  })
  if (uniqueIds.has(undefined)) uniqueIds.delete(undefined)
  return uniqueIds.size
}
export function FullStackedKpiDisplay() {
  const {
    filteredRenewalDataForScenario,
    filteredNewDataForScenario,
    filteredNewClientDataForScenario,
    filteredNewCustomPortfolioDataForScenario,
    fullRenewalKpis,
    fullNewKpis,
    grarcKpi,
    newGrarcKpi,
    renewalGrarcKpi,
    fullWeightedAverageKpis,
    weightedAverageRenewalKpis,
  } = useContext(FullScenarioDataContext)
  const { aggregatedMarketData } = useContext(AggregatedMarketDataContextV2)
  const totalOriginalGWPThatHasBeenAdjusted = fullRenewalKpis
    ? fullRenewalKpis[TOTAL_GWP_COLUMN].minus(fullRenewalKpis[TOTAL_GWP_LEFT_TO_ADJUST_COLUMN])
    : ZERO
  const adjustedPercentage =
    fullRenewalKpis && !isZero(fullRenewalKpis[TOTAL_GWP_COLUMN])
      ? totalOriginalGWPThatHasBeenAdjusted.div(fullRenewalKpis[TOTAL_GWP_COLUMN]).mul(ONE_HUNDRED)
      : ZERO
  const previousValue = usePrevious(adjustedPercentage)

  const uniqueRenewalAdjustments = new Big(getNumberOfUniqueIdsInData(filteredRenewalDataForScenario))
  const uniqueNewAdjustments = new Big(
    getNumberOfUniqueIdsInData(filteredNewDataForScenario) +
      getNumberOfUniqueIdsInData(filteredNewClientDataForScenario) +
      getNumberOfUniqueIdsInData(filteredNewCustomPortfolioDataForScenario),
  )

  const totalAdjustedGWP =
    fullRenewalKpis && fullNewKpis
      ? fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN].plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN])
      : ZERO

  let totalMarketShare
  if (aggregatedMarketData) {
    const totalMarketsGwp =
      aggregatedMarketData?.composedOf?.reduce(
        (current, next) => current.plus(next?.totalMarketGwp || 0),
        new Big(0),
      ) || ZERO
    if (!isZero(totalMarketsGwp)) {
      totalMarketShare = totalAdjustedGWP.mul(ONE_HUNDRED).div(totalMarketsGwp)
    }
  }

  return (
    <div className="Kpis">
      <CircularProgressbarWithChildren
        className="KpiProgressBar"
        value={adjustedPercentage.toNumber()}
        strokeWidth={5}
        styles={buildStyles({
          rotation: 0.5,
          strokeLinecap: 'round',
          pathTransitionDuration: 0.3,
        })}
      >
        <CountUp
          className="KpiProgressBarTitle"
          duration={0.75}
          start={numericValueIsDefined(previousValue) ? formatForAnimation(previousValue) : 0}
          end={formatForAnimation(adjustedPercentage)}
          suffix="%"
        />
        <p className="KpiProgressBarSubtitle">Adjusted</p>
      </CircularProgressbarWithChildren>
      <KpiCard
        className={'TopKpi'}
        value={fullRenewalKpis ? fullRenewalKpis[TOTAL_GWP_COLUMN] : ZERO}
        title="Original GWP"
        prefix="$"
        suffix={fullRenewalKpis ? suffixForValue(fullRenewalKpis[TOTAL_GWP_COLUMN]) : ''}
      />
      <Divider />
      <KpiCard
        value={totalAdjustedGWP}
        title="Adjusted GWP"
        prefix="$"
        suffix={
          fullRenewalKpis && fullNewKpis
            ? suffixForValue(fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN].plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN]))
            : ''
        }
      />
      <KpiCard
        value={totalMarketShare}
        decimals={1}
        title="Market Share"
        suffix="%"
      />
      <KpiCard
        value={grarcKpi}
        title="GRARC"
        suffix="%"
        decimals={1}
      />
      <KpiCard
        value={fullWeightedAverageKpis?.lossRatioKpi}
        decimals={1}
        title="Loss Ratio"
        suffix="%"
      />
      <KpiCard
        value={fullWeightedAverageKpis?.acqRatioKpi}
        decimals={1}
        title="Acq. Ratio"
        suffix="%"
      />
      <Divider />
      <KpiCard
        value={fullRenewalKpis ? fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN] : ZERO}
        title={'Renewal GWP'}
        prefix="$"
        suffix={fullRenewalKpis ? suffixForValue(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN]) : ''}
      />
      <KpiCard
        value={renewalGrarcKpi}
        title="GRARC"
        suffix="%"
        decimals={1}
      />
      <KpiCard
        value={weightedAverageRenewalKpis?.convexShareChangeKpi}
        title="Convex Share Change"
        decimals={1}
        suffix="%"
      />
      <KpiCard
        value={weightedAverageRenewalKpis?.renewalRetentionKpi}
        title="Renewal Retention"
        decimals={1}
        suffix="%"
      />
      <KpiCard
        value={uniqueRenewalAdjustments}
        title="# Renewal Adjustments"
        decimals={0}
      />
      <Divider />
      <KpiCard
        value={fullNewKpis ? fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN] : ZERO}
        title="New  GWP"
        prefix="$"
        suffix={fullNewKpis ? suffixForValue(fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN]) : ''}
      />
      <KpiCard
        value={uniqueNewAdjustments}
        title="# New Adjustments"
        decimals={0}
      />
      <KpiCard
        value={newGrarcKpi}
        suffix="%"
        title="New GRARC"
        decimals={1}
      />
    </div>
  )
}
