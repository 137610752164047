import { useCallback, useContext, useMemo } from 'react'
import './AdjustmentCard.scss'
import {
  Adjustment,
  deleteAdjustment,
  fetchAdjustment,
  patchAdjustment,
  sortAdjustmentsClientSpecificFirstThenByOldestFirst,
} from '../../backend/adjustments'
import { isZero, ONE_HUNDRED, suffixForValue, ZERO } from '../../utils/numbers'
import {
  RenewalAdjustmentContext,
  RenewalAdjustmentsContextContainer,
} from '../../providers/RenewalAdjustmentsProvider'
import ReactSwitch from 'react-switch'
import { css } from '../../utils/css'
import LockedIcon from '../icons/LockedIcon'
import DeleteIcon from '../icons/DeleteIcon'
import EditIcon from '../icons/EditIcon'
import { FilterContext } from '../../providers/FilterProvider'
import DraggableCard from '../draggable-card/DraggableCard'
import Big from 'big.js'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ACTUALS_ADJUSTMENT_ID, OVERRIDDEN_BY_ACTUALS } from '../../backend/calculate-with-actuals'
import { HeadingWithTitle } from '../heading-with-title/HeadingWithTitle'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { MAX_LENGTH_OF_SHORT_STRINGS, trimStringIfTooLong } from '../../utils/strings'
import PreviewIcon from '../icons/PreviewIcon'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import StopPreviewIcon from '../icons/StopPreviewIcon'
import {
  calculateCurrentAdjustmentIds,
  calculateItemsLowerThanThis,
  calculatePercentageOfOriginalGwpAdjustedBy,
  ProgressBar,
} from './ProgressBar'
import { sanitizeId } from 'src/utils/ids'
import { calculateDescription, calculateDisplayOrderNumber } from './utils'
import { ManyDropdownFilterValues } from '../../providers/FilterProvider/model'

export type AdjustmentCardProps = {
  index: number
  adjustedGwp: Big
  adjustmentId: string
  adjustmentName: string
  adjustedPercentage: string
  adjustmentDescription: string
  lowestOrderNumberIs0?: boolean
  orderNumber?: number
  gwpThatMatchesAdjFilter?: Big
  originalGwp?: Big
  riskAdjustedPremiumRateChange?: number
  grossRateChange?: number
  convexShareChange?: number
  retention?: number
  grarc?: number
  isAdjustmentEnabled?: boolean
  onTriggerEditAdjustment?: (adjustmentId: string) => void
  onDrop?: (dragIndex: number, hoverIndex: number) => void
  onHover?: (dragIndex: number, hoverIndex: number) => void
  hideCreateAndEdit?: boolean
}

export function AdjustmentCard(props: AdjustmentCardProps) {
  const {
    triggerReloadSavedAdjustments,
    adjustments,
    currentlyPreviewingAdjustmentId,
    setWhichAdjustmentIsBeingPreviewed,
  } = useContext<RenewalAdjustmentsContextContainer>(RenewalAdjustmentContext)
  const { fullRenewalKpis } = useContext(FullScenarioDataContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { onChangeFilters } = useContext(FilterContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const displayOrderNumber = useMemo(
    () => calculateDisplayOrderNumber(props.orderNumber, props.lowestOrderNumberIs0),
    [props.orderNumber, props.lowestOrderNumberIs0],
  )
  const onDeleteAdjustment: () => Promise<void> = useCallback(async () => {
    try {
      updateIndividualProgressItem('deletingAdjustment', ProgressState.LOADING)
      await deleteAdjustment(currentScenario!.id, props.adjustmentId)
      triggerReloadSavedAdjustments()
      updateIndividualProgressItem('deletingAdjustment', ProgressState.FINISHED)
    } catch (e) {
      updateIndividualProgressItem('deletingAdjustment', ProgressState.ERROR)
    }
  }, [updateIndividualProgressItem, triggerReloadSavedAdjustments, currentScenario!.id, props.adjustmentId])

  const toggleShowingAdjustment: () => Promise<void> = useCallback(async () => {
    try {
      updateIndividualProgressItem('togglingAdjustment', ProgressState.LOADING)
      await patchAdjustment(currentScenario!.id, props.adjustmentId, {
        name: props.adjustmentName,
        isEnabled: !props.isAdjustmentEnabled,
      })
      triggerReloadSavedAdjustments()
      updateIndividualProgressItem('togglingAdjustment', ProgressState.FINISHED)
    } catch (e) {
      updateIndividualProgressItem('togglingAdjustment', ProgressState.ERROR)
    }
  }, [
    updateIndividualProgressItem,
    triggerReloadSavedAdjustments,
    currentScenario!.id,
    props.adjustmentId,
    props.adjustmentName,
    props.isAdjustmentEnabled,
  ])

  const onUpdateFiltersWithValues: (savedAdjustment: Adjustment | undefined) => void = useCallback(
    (savedAdjustment) => {
      onChangeFilters(
        Object.entries(savedAdjustment?.appliedFilters!).reduce(
          // TODO this bad boy is lying (savedAdjustment?.appliedFilters!)
          (dataSoFar: ManyDropdownFilterValues, [columnName, chosenValue], index) => ({
            [`filter${index + 1}`]: { columnName, chosenValue },
            ...dataSoFar,
          }),
          {} as ManyDropdownFilterValues,
        ),
      )
    },
    [onChangeFilters],
  )

  const onPreviewFilters: () => Promise<void> = useCallback(async () => {
    const savedAdjustment = await fetchAdjustment(currentScenario!.id, props.adjustmentId)
    setWhichAdjustmentIsBeingPreviewed(props.adjustmentId)
    onUpdateFiltersWithValues(savedAdjustment)
  }, [setWhichAdjustmentIsBeingPreviewed, onUpdateFiltersWithValues, currentScenario!.id, props.adjustmentId])

  const onFinishPreview: () => void = useCallback(() => {
    setWhichAdjustmentIsBeingPreviewed('')
    onChangeFilters({})
  }, [setWhichAdjustmentIsBeingPreviewed, onChangeFilters])

  const onStartEditAdjustment: () => Promise<void> = useCallback(async () => {
    const savedAdjustment = await fetchAdjustment(currentScenario!.id, props.adjustmentId)
    props.onTriggerEditAdjustment?.(props.adjustmentId)
    onUpdateFiltersWithValues(savedAdjustment)
  }, [props.onTriggerEditAdjustment, onUpdateFiltersWithValues, currentScenario!.id, props.adjustmentId])

  const actualDescription = useMemo(
    () => calculateDescription(props.adjustmentDescription, props.adjustmentId),
    [props.adjustmentDescription, props.adjustmentId],
  )

  const idsOfCurrentAdjustment = useMemo(
    () => calculateCurrentAdjustmentIds(props.adjustmentId, adjustments || []),
    [props.adjustmentId, adjustments],
  )

  const percentageAdjustedByThisAdjustment = useMemo(
    () => calculatePercentageOfOriginalGwpAdjustedBy(fullRenewalKpis, idsOfCurrentAdjustment),
    [fullRenewalKpis, idsOfCurrentAdjustment],
  )

  const adjustmentsLowerThanThisInTheChain = useMemo(
    () =>
      calculateItemsLowerThanThis<Adjustment>(
        adjustments || [],
        props.adjustmentId,
        sortAdjustmentsClientSpecificFirstThenByOldestFirst,
      ),
    [adjustments, props.adjustmentId],
  )

  const percentageAdjustedByLowerAdjustments = useMemo(
    () =>
      calculatePercentageOfOriginalGwpAdjustedBy(fullRenewalKpis, [
        props.adjustmentId === 'Actuals' ? '' : OVERRIDDEN_BY_ACTUALS,
        ...adjustmentsLowerThanThisInTheChain.map((item) => item.id),
      ]),
    [fullRenewalKpis, props.adjustmentId, adjustmentsLowerThanThisInTheChain],
  )

  const leftoverPercentage = ONE_HUNDRED.minus(percentageAdjustedByThisAdjustment).minus(
    percentageAdjustedByLowerAdjustments,
  )

  const canDrag = Boolean(props.onDrop && props.onHover)
  const canBeEdited =
    !props.hideCreateAndEdit &&
    currentScenario &&
    currentScenario.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)

  const otherCardIsBeingPreviewed =
    currentlyPreviewingAdjustmentId && currentlyPreviewingAdjustmentId !== props.adjustmentId

  const isIneffective = isZero(props.adjustedGwp) && isZero(props.originalGwp)

  return (
    <div
      className={css(
        'AdjustmentCard',
        canDrag ? 'CanGrab' : 'CantDrag',
        otherCardIsBeingPreviewed ? 'CardInactive' : '',
      )}
      key={props.adjustmentId}
    >
      <DraggableCard
        index={props.index}
        id={props.adjustmentId}
        canDrag={canDrag}
        onHover={props.onHover ? props.onHover : () => void 0}
        onDrop={props.onDrop ? props.onDrop : () => void 0}
      >
        <ProgressBar
          percentageAdjustedByLowerAdjustments={percentageAdjustedByLowerAdjustments}
          percentageAdjustedByThisAdjustment={percentageAdjustedByThisAdjustment}
          leftoverPercentage={leftoverPercentage}
        />
        <div className="CardHeader">
          <h4
            className={css(
              'AdjustmentCardTitle',
              props.isAdjustmentEnabled ? 'Checked' : 'Unchecked',
              isIneffective ? 'Ineffective' : '',
            )}
            id={sanitizeId(props.adjustmentName + '-Name')}
            data-testid={sanitizeId(props.adjustmentName + '-Name')}
          >
            {displayOrderNumber === undefined ? '' : displayOrderNumber + '. '}{' '}
            {trimStringIfTooLong(props.adjustmentName, MAX_LENGTH_OF_SHORT_STRINGS)}
          </h4>
          {isIneffective && props.isAdjustmentEnabled && (
            <div
              className={css('IneffectiveWarning', 'p')}
              id={`${sanitizeId(props.adjustmentName)}-Ineffective-Warning`}
            >
              Adjustment ordering or actuals are rendering this adjustment ineffective
            </div>
          )}
          {canBeEdited && props.adjustmentId !== ACTUALS_ADJUSTMENT_ID && !props.adjustmentId?.includes('SUMMARY') ? (
            <div className="CardOptions">
              <div title="View">
                {!currentlyPreviewingAdjustmentId && (
                  <PreviewIcon
                    className="PreviewButton"
                    onClick={onPreviewFilters}
                    id={`${sanitizeId(props.adjustmentName)}-Preview`}
                  />
                )}
                {currentlyPreviewingAdjustmentId === props.adjustmentId && (
                  <StopPreviewIcon
                    className="StopPreviewButton"
                    onClick={onFinishPreview}
                    id={`${sanitizeId(props.adjustmentName)}-Stop-Preview`}
                  />
                )}
              </div>
              <div title="Edit">
                <EditIcon
                  className="EditButton"
                  onClick={onStartEditAdjustment}
                  id={`${sanitizeId(props.adjustmentName)}-Edit`}
                />
              </div>
              <div title="Delete">
                <DeleteIcon
                  className="DeleteButton"
                  onClick={onDeleteAdjustment}
                  id={`${sanitizeId(props.adjustmentName)}-Delete`}
                />
              </div>

              <div data-tip={props.isAdjustmentEnabled ? 'On' : 'Off'}>
                <ReactSwitch
                  className="ToggleAdjustment"
                  onChange={toggleShowingAdjustment}
                  checked={Boolean(props.isAdjustmentEnabled)}
                  onColor="#0BC893"
                  offColor="#4E566B"
                  handleDiameter={8}
                  height={12}
                  width={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  id={`${sanitizeId(props.adjustmentName)}-Toggle-Switch`}
                />
              </div>
            </div>
          ) : (
            <div className="CardOptions">
              {props.adjustmentId !== ACTUALS_ADJUSTMENT_ID && !props.adjustmentId?.includes('SUMMARY') && (
                <>
                  {!currentlyPreviewingAdjustmentId && (
                    <PreviewIcon
                      className="PreviewButton"
                      onClick={onPreviewFilters}
                      id={`${sanitizeId(props.adjustmentName)}-Preview`}
                    />
                  )}
                  {currentlyPreviewingAdjustmentId === props.adjustmentId && (
                    <StopPreviewIcon
                      className="StopPreviewButton"
                      onClick={onFinishPreview}
                      id={`${sanitizeId(props.adjustmentName)}-Stop-Preview`}
                    />
                  )}
                </>
              )}
              <LockedIcon className="LockedIcon" />
            </div>
          )}
        </div>
        <div
          className={css(
            'AdjustmentContentContainer',
            props.isAdjustmentEnabled ? 'Checked' : 'Unchecked',
            isIneffective ? 'Ineffective' : '',
          )}
        >
          <div className="AdjustmentNumbersContainer">
            <div className="AdjustmentNumbersLeftOptions">
              <HeadingWithTitle
                title="Filtered GWP"
                value={props.gwpThatMatchesAdjFilter}
                className="FilteredGWP"
                prefix="$"
                suffix={suffixForValue(props.gwpThatMatchesAdjFilter || ZERO)}
                id={`${sanitizeId(props.adjustmentName)}-Filtered-GWP`}
              />
              <HeadingWithTitle
                title="Adjustable GWP"
                value={props.originalGwp}
                className="AdjustableGWP"
                prefix="$"
                suffix={suffixForValue(props.originalGwp || ZERO)}
                id={`${sanitizeId(props.adjustmentName)}-Adjustable-GWP`}
              />
            </div>
            <div className="AdjustmentNumbersMiddleOptions">
              <HeadingWithTitle
                title="Risk Adj Premium Change"
                value={props.riskAdjustedPremiumRateChange}
                className="RiskAdjustedPremiumChange"
                suffix="%"
                id={`${sanitizeId(props.adjustmentName)}-Risk-Adjusted-Premium-Change`}
              />
              <HeadingWithTitle
                title="GG Premium Change"
                value={props.grossRateChange}
                className="GGPremiumChange"
                suffix="%"
                id={`${sanitizeId(props.adjustmentName)}-GG-Premium-Change`}
              />
              <HeadingWithTitle
                title="Convex Share Change"
                value={props.convexShareChange}
                className="ConvexShareChange"
                suffix="%"
                id={`${sanitizeId(props.adjustmentName)}-Convex-Share-Change`}
              />
              <HeadingWithTitle
                title="Renewal Retention"
                value={props.retention}
                className="RenewalRetention"
                suffix="%"
                id={`${sanitizeId(props.adjustmentName)}-Renewal-Retention`}
              />
            </div>
            <div className="AdjustmentNumbersRightOptions">
              <HeadingWithTitle
                title="GRARC"
                value={props.grarc}
                decimals={2}
                className="GRARC"
                suffix="%"
                id={`${sanitizeId(props.adjustmentName)}-GRARC`}
              />
              <HeadingWithTitle
                title="Adjusted GWP"
                value={props.adjustedGwp}
                className="Adjusted"
                prefix="$"
                suffix={suffixForValue(props.adjustedGwp)}
                id={`${sanitizeId(props.adjustmentName)}-Adjusted-GWP`}
              />
            </div>
          </div>
          <div className="AdjustmentSummaryContainer">
            {actualDescription && (
              <div className={css('AdjustmentSummary', props.isAdjustmentEnabled ? 'Checked' : 'Unchecked')}>
                <p>{actualDescription}</p>
              </div>
            )}
          </div>
        </div>
      </DraggableCard>
    </div>
  )
}

export default AdjustmentCard
