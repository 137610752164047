import { createContext, PropsWithChildren, useCallback, useState } from 'react'

const MultipleActualDataChoiceContext = createContext<MultipleActualDataChoiceContainer>({
  actualDataItemNames: {},
  setActualDataItemName: () => void 0,
})

export interface MultipleActualDataChoiceContainer {
  actualDataItemNames: Partial<Record<string, string>>
  setActualDataItemName: (keyName: string, newValue: string) => void
}

function MultipleActualDataChoiceProvider(props: PropsWithChildren<{}>) {
  const [actualDataItemNames, updateFullListOfStuff] = useState<Partial<Record<string, string>>>({})

  const setActualDataItemName = useCallback(
    (keyName: string, newValue: string) => {
      const updateListWithPrevState = (prevState: Partial<Record<string, string>>) => {
        const newItem = {
          ...prevState,
          [keyName]: newValue,
        }

        if (!newValue) {
          delete newItem[keyName]
        }
        return newItem
      }
      updateFullListOfStuff(updateListWithPrevState)
    },
    [updateFullListOfStuff],
  )

  return (
    <MultipleActualDataChoiceContext.Provider
      value={{
        actualDataItemNames,
        setActualDataItemName,
      }}
      children={props.children}
    />
  )
}

export { MultipleActualDataChoiceContext, MultipleActualDataChoiceProvider }
