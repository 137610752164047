import { Reducer } from 'react'
import { ExposuresReportStatus } from '../exposures/ReportStatus'

export type ReportMeta = {
  id: string
  name: string
  status: ExposuresReportStatus
  final: boolean
  outdated: boolean
  createdAt: string
  updatedAt: string
}

type ExposuresListState = {
  reports: ReportMeta[]
}

export type ExposuresListAction =
  | { type: 'setReports'; payload: { reports: ReportMeta[] } }
  | { type: 'setReportFinalStatus'; payload: { reportId: string; final: boolean } }

export const createInitialExposuresListState = (): ExposuresListState => {
  return {
    reports: [],
  }
}

export const reducer: Reducer<ExposuresListState, ExposuresListAction> = (state, action) => {
  switch (action.type) {
    case 'setReports': {
      return {
        ...state,
        reports: action.payload.reports,
      }
    }
    case 'setReportFinalStatus': {
      let updatedState = {
        ...state,
        reports: state.reports.map((report) => {
          if (report.id === action.payload.reportId) {
            return {
              ...report,
              final: action.payload.final,
            }
          }
          return report
        }),
      }
      return updatedState
    }
  }
}
