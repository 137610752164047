import React, { SVGProps } from 'react'

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <polygon
      fill="#4E566B"
      fillRule="evenodd"
      points="13 8.714 8.714 8.714 8.714 13 7.286 13 7.286 8.714 3 8.714 3 7.286 7.286 7.286 7.286 3 8.714 3 8.714 7.286 13 7.286"
    />
  </svg>
)

export default AddIcon
