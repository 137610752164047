import qs from 'qs'

import { getCurrentConfigSettings } from '../utils/config'
import { ScenarioStatus } from '../utils/status/ScenarioStatus'
import { getAccessToken } from './teams'

export interface StackedOutwardScenario {
  id: string
  name: string
  description: string
  version: string
  subScenarioIds: string[]
  updatedAt: string
  canWrite: boolean
  year: string
  status: ScenarioStatus
  subStatuses: Record<ScenarioStatus, number>
}

export interface PatchStackedOutwardScenarioRequest {
  name?: string
  description?: string
  subScenarioIds?: string[]
  version?: string
  year?: string
}

export interface CreateStackedOutwardScenarioRequest {
  name: string
  description: string
  subScenarioIds: string[]
  version: string
  year: string
}

export async function patchStackedOutwardScenario(
  scenarioId: string,
  patchScenarioRequest: PatchStackedOutwardScenarioRequest,
) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios/${scenarioId}`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(patchScenarioRequest),
  })

  return await response.json()
}

export async function fetchStackedOutwardScenarioById(id: string): Promise<StackedOutwardScenario | undefined> {
  if (id === undefined) {
    return undefined
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios/${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status === 200) {
    return await response.json()
  }

  return undefined
}

export async function fetchStackedScenariosByVersion(
  year?: string | null,
  version?: string | null,
): Promise<StackedOutwardScenario[]> {
  let queryContent: Record<string, string | null | undefined> = { year, version }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios?${qs.stringify(queryContent)}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}

export async function createStackedOutwardScenario(csr: CreateStackedOutwardScenarioRequest) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ ...csr }),
  })

  return (await response.json()).id
}

export async function deleteStackedOutwardScenario(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios/${scenarioId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  return Promise.resolve()
}
