import { Alert, AlertIcon, Box, Flex, Heading, Spacer, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

export function ExposureMissingTemplate(): ReactElement {
  return (
    <Box>
      <Flex
        px={'1rem'}
        pt={'1rem'}
        mb={'1rem'}
      >
        <Alert
          status="warning"
          borderRadius={'md'}
        >
          <AlertIcon />
          The target market you selected has no vendor modelled exposures.
        </Alert>
      </Flex>
      <Flex
        px={'3rem'}
        py={'1.5rem'}
        borderBottom={'1px solid rgba(0,0,0,0.1)'}
      >
        <Heading>Exposures</Heading>
        <Spacer />
      </Flex>
      <Flex
        px={'3rem'}
        pt={'1rem'}
        flexDirection={'column'}
        maxWidth={'75%'}
      >
        <Text
          pb={'1rem'}
          size={'sm'}
        >
          No vendor modelled losses available for the selected target market.
        </Text>
      </Flex>
    </Box>
  )
}
