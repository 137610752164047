import {
  Button,
  ButtonGroup,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Tag,
} from '@chakra-ui/react'
import { ReportMeta, ExposuresListAction } from './ExposuresListState'
import { ExposuresReportStatus, ReportStatus } from '../exposures/ReportStatus'
import { Dispatch, useContext, useMemo } from 'react'
import { updateReport } from '../../backend/exposures'
import { ScenarioContext } from 'src/providers/ScenarioProvider'
import { ScenarioStatus } from 'src/utils/status/ScenarioStatus'

type ExposureListTableProps = {
  reports: ReportMeta[]
  toReportPage: (reportId: string) => void
  exposuresListDispatch: Dispatch<ExposuresListAction>
}

export const ExposuresListTable = (props: ExposureListTableProps) => {
  const { reports, toReportPage, exposuresListDispatch } = props
  const { currentScenario } = useContext(ScenarioContext)

  const canTagAsFinal =
    currentScenario?.status[0].status === ScenarioStatus.IN_PROGRESS ||
    currentScenario?.status[0].status === ScenarioStatus.IN_REVIEW

  const noFinalReports = useMemo(() => {
    return !reports.some((report) => report.final)
  }, [reports])

  const tagReportAsFinal = async (id: string) => {
    try {
      await updateReport(id, { scenarioId: currentScenario?.id, final: true })
      exposuresListDispatch({ type: 'setReportFinalStatus', payload: { reportId: id, final: true } })
    } catch (err) {
      console.error('Failed to update report final status', err)
    }
  }

  const removeFinalReportTag = async (id: string) => {
    try {
      await updateReport(id, { scenarioId: currentScenario?.id, final: false })
      exposuresListDispatch({ type: 'setReportFinalStatus', payload: { reportId: id, final: false } })
    } catch (err) {
      console.error('Failed to update report final status', err)
    }
  }

  return (
    <TableContainer>
      <Table textAlign={'center'}>
        <Thead>
          <Tr>
            <Th>Last Updated</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {reports.map((report) => (
            <Tr
              key={`row-${report.id}`}
              _hover={{ backgroundColor: 'gray.50' }}
            >
              <Td fontSize={'sm'}>{formatDateTime(new Date(report.updatedAt))}</Td>
              <Td>
                <Heading
                  size={'sm'}
                  cursor={'pointer'}
                  onClick={() => toReportPage(report.id)}
                  textColor={'blue.500'}
                >
                  {report.name}
                </Heading>
                {report.outdated && <Text textColor="#A0AEC0">Outdated Adjustments</Text>}
              </Td>
              <Td>
                <ReportStatus reportStatus={report.status}></ReportStatus>
                {report.final && (
                  <Tag
                    size={'md'}
                    borderRadius="full"
                    variant="solid"
                    backgroundColor={'#C6F6D5'}
                    color={'black'}
                  >
                    Final
                  </Tag>
                )}
              </Td>
              <Td>
                {report.final && (
                  <Text
                    onClick={() => removeFinalReportTag(report.id)}
                    color={'blue.500'}
                    size={'sm'}
                    display={'inline'}
                    cursor={'pointer'}
                  >
                    Remove final tag
                  </Text>
                )}
                {noFinalReports && canTagAsFinal && (
                  <Text
                    onClick={() => tagReportAsFinal(report.id)}
                    color={'blue.500'}
                    size={'sm'}
                    display={'inline'}
                    cursor={'pointer'}
                  >
                    Tag as Final
                  </Text>
                )}
              </Td>
              <Td textAlign={'end'}>
                {report.status === ExposuresReportStatus.SCALED_ANALYSIS && (
                  <ButtonGroup>
                    <Button onClick={() => toReportPage(report.id)}>Edit</Button>
                  </ButtonGroup>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

function formatDateTime(date: Date): string {
  const minutes = date.getMinutes()
  return `${createDate(date)} ${date.getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`
}

function createDate(date: Date): string {
  const year = date.getFullYear()
  const rawMonth = date.getMonth() + 1
  const rawDay = date.getDate()

  let month: string = rawMonth.toString()
  if (rawMonth < 10) {
    month = `0${month}`
  }

  let day: string = rawDay.toString()
  if (rawDay < 10) {
    day = `0${day}`
  }

  return `${year}/${month}/${day}`
}
