import React, { useState } from 'react'
import { Card } from '../card/Card'
import './EditOutwardScenarioCard.scss'
import Button from '../button/Button'
import TextField from '../text-field/WFTextField'
import { onInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import TextArea from '../text-area/TextArea'
import { OutwardScenario, patchOutwardScenario } from '../../backend/outward-scenarios'
import { ScenarioStatusSelector } from '../scenario-status-selector/ScenarioStatusSelector'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'

export type EditOutwardScenarioCardProps = {
  outwardScenario: OutwardScenario
  triggerStopEditing: () => void
  triggerSaveChanges: () => void
}

function EditOutwardScenarioCard({
  outwardScenario,
  triggerStopEditing,
  triggerSaveChanges,
  ...otherProps
}: EditOutwardScenarioCardProps) {
  const [currentName, setName] = useState(outwardScenario.name)
  const [currentStatus, setStatus] = useState<ScenarioStatus>(outwardScenario.status || ScenarioStatus.IN_PROGRESS)
  const [currentSummary, setSummary] = useState(outwardScenario.description || '')
  const [shouldValidate, setShouldValidate] = useState(false)

  const onClickSave = () => {
    setShouldValidate(true)

    if (!isValidName(currentName)) {
      return
    }

    patchOutwardScenario(outwardScenario.id, {
      name: currentName,
      description: currentSummary,
      status: currentStatus,
    }).then(triggerSaveChanges)
  }

  return (
    <div className="EditOutwardScenarioCard">
      <Card>
        <TextField
          title="Name"
          placeholder="Name"
          value={currentName}
          onChange={onInputUpdateState(setName)}
          error={shouldValidate ? (isValidName(currentName) ? '' : 'Please enter a name for the outward scenario') : ''}
          className="Name"
        />
        <ScenarioStatusSelector
          onSelectFunction={setStatus}
          currentStatus={currentStatus}
          existingScenario={{
            ...outwardScenario,
            status: [
              {
                id: '',
                user: null,
                signOffDate: null,
                status: outwardScenario.status || ScenarioStatus.IN_PROGRESS,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            ],
          }}
          boxed={false}
        />
        <TextArea
          title="Summary"
          placeholder="Summary"
          value={currentSummary}
          onChange={onTextAreaUpdateState(setSummary)}
        />
        <div className="ButtonContainer">
          <Button
            title="Cancel"
            secondary
            onClick={triggerStopEditing}
          />
          <Button
            title="Save"
            onClick={onClickSave}
          />
        </div>
      </Card>
    </div>
  )
}

export default EditOutwardScenarioCard
