import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export interface NoteRequestOrResponse {
  content: string
  entityTypeReference: string
  noteIdentifier: string
}

export async function fetchNotes(
  parentEntityId: string,
  noteIdentifier: string,
): Promise<NoteRequestOrResponse | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/notes/${parentEntityId}/${noteIdentifier}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status === 404) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function updateNote(
  parentEntityId: string,
  noteIdentifier: string,
  createNoteRequest: NoteRequestOrResponse,
): Promise<NoteRequestOrResponse | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/notes/${parentEntityId}/${noteIdentifier}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createNoteRequest),
  })

  return await response.json()
}
