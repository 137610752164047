import React from 'react'
import { ParameterisationContent } from './ParameterisationContent'
import { ParameterisationDefaultMarketDataProvider } from '../../providers/parameterisation/ParameterisationDefaultMarketDataProvider'
import { ParameterisationDataProvider } from '../../providers/parameterisation/ParameterisationDataProvider'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'

export const Parameterisation = () => (
  <MarketV2Provider>
    <ParameterisationDefaultMarketDataProvider>
      <ParameterisationDataProvider>
        <ParameterisationContent />
      </ParameterisationDataProvider>
    </ParameterisationDefaultMarketDataProvider>
  </MarketV2Provider>
)
