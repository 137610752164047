import { ResponsiveLine } from '@nivo/line'
import { Box, Heading, Spinner } from '@chakra-ui/react'
import { useReducer } from 'react'
import { ExposuresGraphAlert, createGraphAlertInitialState, graphAlertReducer } from './ExposuresGraphAlert'
import { loadingType } from './ExposuresGraphState'
import {
  ExceedanceGraphData,
  ExceedanceProbabilityAALData,
  ExceedanceProbabilityAALGraph,
  ReturnPeriod,
} from '../../../backend/exposures-V2'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../StackedExposures'

type ExposuresLineGraphProps = {
  graphData: ExceedanceGraphData[]
  graphMarkers: ExceedanceProbabilityAALGraph[]
  peril: string
  returnPeriod: ReturnPeriod
  leftAxis: string
  bottomAxis: string
  loadingState: loadingType
}

export const ExposuresLineGraph = (props: ExposuresLineGraphProps) => {
  const { graphData, graphMarkers, leftAxis, bottomAxis, loadingState, peril, returnPeriod } = props
  const [graphAlertState, graphAlertDispatch] = useReducer(graphAlertReducer, createGraphAlertInitialState())
  const { way1154ExposuresAalGraphData } = useFlags<Flag>()

  if (loadingState === 'loading') {
    return (
      <Box>
        <Heading size={'sm'}>Loading</Heading>
        <Spinner />
      </Box>
    )
  }

  const onCloseAlert = () => {
    graphAlertDispatch({ type: 'closeAlert' })
  }

  if (loadingState === 'error') {
    return (
      <ExposuresGraphAlert
        alertStatus={graphAlertState.alertStatus}
        message={graphAlertState.message}
        isActive={graphAlertState.isActive}
        onClose={onCloseAlert}
      />
    )
  }
  const colors: string[] = []

  return (
    <ResponsiveLine
      data={graphData.map((g) => {
        const dataForPerilReturnPeriod = g.data[peril]?.[returnPeriod]
        colors.push(g.color)

        return {
          id: g.id,
          color: g.color,
          data: dataForPerilReturnPeriod || [],
        }
      })}
      margin={{ top: 40, right: 60, bottom: 50, left: 60 }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      colors={colors}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: bottomAxis,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        format: (a) => `${a / 1000000}m`,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: leftAxis,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      markers={
        way1154ExposuresAalGraphData
          ? graphMarkers.map((marker) => {
              return {
                axis: 'y',
                value: getAALMarkersForPeril(marker.data, peril),
                lineStyle: { stroke: marker.color, strokeWidth: 2 },
                textStyle: {
                  fill: marker.color,
                  fontSize: 12,
                },
              }
            })
          : []
      }
      enableGridX={false}
      pointSize={5}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        way1154ExposuresAalGraphData
          ? [
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: -30,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 115,
                itemHeight: 19,
                itemOpacity: 0.75,
                symbolSize: 15,
                symbolShape: 'square',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
              {
                anchor: 'top-left',
                data: graphMarkers.map((marker) => {
                  return { id: marker.id, label: marker.id, color: marker.color }
                }),
                direction: 'row',
                justify: false,
                translateX: -30,
                translateY: -20,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 115,
                itemHeight: 19,
                itemOpacity: 0.75,
                symbolSize: 15,
                symbolShape: 'square',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : [
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: -30,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 115,
                itemHeight: 19,
                itemOpacity: 0.75,
                symbolSize: 15,
                symbolShape: 'square',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  )
}

function getAALMarkersForPeril(markerData: ExceedanceProbabilityAALData[], peril: string): number {
  let aalLoss: number = 0
  markerData.forEach((marker) => {
    if (marker[peril]) {
      aalLoss = marker[peril].data
    }
  })

  return aalLoss
}
