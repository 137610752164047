import React, { createContext, useContext, useEffect, useState } from 'react'
import { WaterfallChartDataItem } from './WaterfallChart'
import { ProgressContext, ProgressState } from '../../../providers/ProgressProvider'
import { calculateKPIs, TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_GWP_COLUMN } from '../../../backend/calculate-kpis'
import { aggregateIntoTopNAndOtherCategory, groupListOfObjsBy } from '../../../utils/lists'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'

const WaterfallGwpAddedPerAdjustmentContext = createContext<WaterfallGwpAddedPerAdjustmentContainer>({
  waterfallChartData: [],
})

const { Consumer, Provider } = WaterfallGwpAddedPerAdjustmentContext

export type WaterfallGwpAddedPerAdjustmentProviderProps = React.PropsWithChildren<{
  columnToSplitBy: string
}>

export interface WaterfallGwpAddedPerAdjustmentContainer {
  waterfallChartData: WaterfallChartDataItem[]
}

export function convertIntoWaterfallFormat(columnToSplitBy: string, data: any[]): WaterfallChartDataItem[] {
  const groupedData = groupListOfObjsBy(data, columnToSplitBy)

  const valuesForAllGroups = Object.entries(groupedData).map(([currentValueIdentifier, listOfData]) => {
    const kpis = calculateKPIs(listOfData)
    const netDifference = kpis[TOTAL_ADJUSTED_GWP_COLUMN].minus(kpis[TOTAL_GWP_COLUMN])

    return {
      category: currentValueIdentifier,
      GWP: netDifference.toNumber(),
    }
  })

  return aggregateIntoTopNAndOtherCategory(valuesForAllGroups, 'GWP', 'category', 12)
}

const WaterfallGwpAddedPerAdjustmentProvider = (props: WaterfallGwpAddedPerAdjustmentProviderProps) => {
  const [waterfallChartData, setWaterfallChartData] = useState<WaterfallChartDataItem[]>([])
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  useEffect(() => {
    updateIndividualProgressItem('waterfallGwpCalculationsUpdate', ProgressState.LOADING)
    const dataReadyForGraph = convertIntoWaterfallFormat(props.columnToSplitBy, filteredDataToUseInGraph)
    setWaterfallChartData(dataReadyForGraph)
    updateIndividualProgressItem('waterfallGwpCalculationsUpdate', ProgressState.FINISHED)
  }, [updateIndividualProgressItem, filteredDataToUseInGraph, props.columnToSplitBy])

  return (
    <Provider
      value={{ waterfallChartData }}
      {...props}
    />
  )
}

export {
  Consumer as WaterfallGwpAddedPerAdjustmentConsumer,
  WaterfallGwpAddedPerAdjustmentProvider,
  WaterfallGwpAddedPerAdjustmentContext,
}
