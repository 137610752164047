import { Theme } from '@nivo/core'

export const DEFAULT_GRAPH_THEME: Theme = {
  text: {
    fill: '#272626',
    fontSize: 12,
    fontFamily: 'Helvetica, sans-serif',
  },
  labels: {
    text: {
      fill: '#272626',
      fontSize: 12,
      fontFamily: 'Univia Pro, sans-serif',
    },
  },
  tooltip: {
    basic: {
      fill: '#272626',
      fontSize: 14,
      fontFamily: 'Helvetica, sans-serif',
    },
    tableCell: {},
  },
  axis: {
    domain: {
      line: {
        stroke: '#272626',
        strokeWidth: 2,
      },
    },
    ticks: {
      text: {
        fill: '#272626',
        fontSize: 10,
      },
      line: {
        stroke: '#272626',
        strokeWidth: 2,
      },
    },
  },
  grid: {
    line: {
      stroke: '#E3E5E5',
      strokeWidth: 1,
    },
  },
}
