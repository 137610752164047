import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { fetchDefaultMarketV2, fetchMarketV2, MarketV2 } from '../backend/market-V2'
import { AppQueryContext } from './AppQueryProvider'

export interface MarketDataContainerV2 {
  marketData: undefined | MarketV2
  defaultMarketData: undefined | MarketV2
  reloadMarketDataFromRemote: () => void
}

export const defaultMarketDataContextV2 = {
  marketData: undefined,
  defaultMarketData: undefined,
  reloadMarketDataFromRemote: () => undefined,
}

export const MarketDataContextV2 = createContext<MarketDataContainerV2>(defaultMarketDataContextV2)

export function MarketDataProviderV2(props: PropsWithChildren) {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery, marketQuery, divisionQuery } = useContext(AppQueryContext)

  const [currentMarketData, setMarketData] = useState<undefined | MarketV2>(undefined)
  const [currentDefaultMarketData, setDefaultMarketData] = useState<undefined | MarketV2>(undefined)

  const [year] = yearQuery
  const [market] = marketQuery
  const [division] = divisionQuery

  const reloadMarketDataFromRemote = (): void => {
    // fetchMarketV2 method needs both year and market
    if (!year) {
      console.warn(
        'MarketDataProviderV2 - reloadMarketDataFromRemote() - year is undefined. Cannot reload data from remote',
      )
      return
    }

    if (!market) {
      console.warn(
        'MarketDataProviderV2 - reloadMarketDataFromRemote() - market is undefined. Cannot reload data from remote',
      )
      return
    }

    if (!division) {
      console.warn(
        'MarketDataProviderV2 - reloadMarketDataFromRemote() - division is undefined. Cannot reload data from remote',
      )
      return
    }

    updateIndividualProgressItem('fetchMarketV2', ProgressState.LOADING)
    try {
      fetchMarketV2(division, market, year)
        .then((maybeMarket: undefined | MarketV2) => {
          setMarketData(maybeMarket)
        })
        .finally(() => {
          updateIndividualProgressItem('fetchMarketV2', ProgressState.FINISHED)
        })
      updateIndividualProgressItem('fetchMarketV2', ProgressState.FINISHED)
    } catch (e) {
      console.error('error occured whilst updating the market data', e)
      setMarketData(undefined)
    }
  }

  const reloadDefaultMarketDataFromRemote = (): void => {
    if (!year || !market || !division) {
      return
    }
    try {
      updateIndividualProgressItem('fetchDefaultMarketDataV2', ProgressState.LOADING)
      fetchDefaultMarketV2(division, market, year)
        .then((maybeMarket: undefined | MarketV2) => {
          setDefaultMarketData(maybeMarket)
        })
        .finally(() => updateIndividualProgressItem('fetchDefaultMarketDataV2', ProgressState.FINISHED))
    } catch (e) {
      console.log(e)
      updateIndividualProgressItem('fetchDefaultMarketDataV2', ProgressState.ERROR)
      setDefaultMarketData(undefined)
    }
  }

  useEffect(reloadMarketDataFromRemote, [year, market, updateIndividualProgressItem])
  useEffect(reloadDefaultMarketDataFromRemote, [year, market, updateIndividualProgressItem])

  return (
    <MarketDataContextV2.Provider
      value={{
        marketData: currentMarketData,
        defaultMarketData: currentDefaultMarketData,
        reloadMarketDataFromRemote,
      }}
      children={props.children}
    />
  )
}
