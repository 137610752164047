import Big from 'big.js'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'
import { formatInceptionYearForDisplay } from '../gwp-bar-chart/inception-month-graph'
import { CulmlativeGwpItem } from './GwpCumulativeLineChart.types'

export function convertDataToDataPoint(
  calculatedData: Array<CulmlativeGwpItem>,
  fieldToGroupBy: keyof CulmlativeGwpItem,
): Array<{ x: string; y: Big | number | string }> {
  return calculatedData
    .map((dataItem) => ({
      x: formatInceptionYearForDisplay(dataItem[RENEWAL_INCEPTION_MONTH_COLUMN_NAME]),
      y: dataItem[fieldToGroupBy],
    }))
    .filter((item) => item.y !== 'Unknown')
    .filter((item) => item.x !== 'Unknown')
}

export function convertDataToGraphFormat(gwpDataPoint: Array<any>, adjustedGwpDataPoint: Array<any>): Array<any> {
  return [
    {
      id: 'Original GWP',
      data: gwpDataPoint,
    },
    {
      id: 'Adjusted GWP',
      data: adjustedGwpDataPoint,
    },
  ]
}
