import { createContext, PropsWithChildren, useContext } from 'react'
import { AppQueryContext, initialQuery, QueryState } from './AppQueryProvider'

export type ActualProps = {
  actualsQuery: QueryState<string>
}

export const ActualsContext = createContext<ActualProps>({
  actualsQuery: initialQuery(),
})

export function ActualsProvider(props: PropsWithChildren<{}>) {
  const { actualsQuery } = useContext(AppQueryContext)
  return (
    <ActualsContext.Provider
      value={{ actualsQuery }}
      children={props.children}
    />
  )
}
