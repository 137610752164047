import { ReactElement } from 'react'

import New from '../../pages/new/New'
import PortfolioDataView from '../../pages/portfolio-data-view/PortfolioDataView'
import Summary from '../../pages/summary/Summary'
import ScenariosIcon from '../../components/icons/ScenariosIcon'
import RenewalIcon from '../../components/icons/RenewalIcon'
import NewIcon from '../../components/icons/NewIcon'
import PortfolioIcon from '../../components/icons/PortfolioIcon'
import SummaryIcon from '../../components/icons/SummaryIcon'
import MarketIcon from '../../components/icons/MarketIcon'
import NotesIcon from '../../components/icons/NotesIcon'
import ScenarioComparison from '../../pages/scenario-comparison/ScenarioComparison'
import Notes from '../../pages/notes/Notes'
import { Parameterisation } from '../../pages/parameterisation/Parameterisation'
import { StackedScenarioParameterisation } from '../../pages/stacked-scenario-parameterisation/StackedScenarioParameterisation'
import { Scenarios } from '../../pages/scenario-chooser/Scenarios'
import StackedScenarioSummary from '../../pages/stacked-scenario-summary/StackedScenarioSummary'
import StackedScenarioPortfolio from '../../pages/stacked-scenario-portfolio/StackedScenarioPortfolio'
import StackedScenarioComparison from '../../pages/stacked-scenario-comparison/StackedScenarioComparison'
import OutwardScenarioCoverage from '../../pages/outward-scenario-coverage/OutwardScenarioCoverage'
import OutwardScenarioPortfolio from '../../pages/outward-scenario-portfolio/OutwardScenarioPortfolio'
import OutwardScenarios from '../../pages/outward-scenario-chooser/OutwardScenarios'
import { ExposuresIcon } from '../../pages/exposures/ExposuresIcon'
import { MarketV2, MarketV2Path } from '../../pages/marketV2/MarketV2'
import { StackedScenarioMarketV2 } from '../../pages/stacked-scenario-marketV2/StackedScenarioMarketV2'
import OutwardScenarioStackedSummary from '../../pages/outward-scenario-stacked-summary/OutwardScenarioStackedSummary'
import { Exposures } from '../../pages/exposures/Exposures'
import { Renewal } from '../../pages/renewal/Renewal'

export interface WayfinderPathData {
  path: string
  icon?: ReactElement
  rightColumnComponent: ReactElement
  displayName?: string
}

export function isOnScenarioPath(currentPath: string): boolean {
  return currentPath.includes('/scenarios/')
}

export function isOnStackedScenarioPath(currentPath: string): boolean {
  return currentPath.includes('/stacked-scenarios/')
}

export function isOnOutwardScenarioPath(currentPath: string): boolean {
  return currentPath.includes('/outward-scenarios/')
}

export function isOnScenarioChooser(currentPath: string) {
  return currentPath.includes('/scenario-chooser')
}

export function isOnMarketEditor(currentPath: string) {
  return currentPath.includes('/market-editor')
}

export function isOnScenarioV2(currentPath: string) {
  return currentPath.includes('/scenario-v2')
}

export let ScenarioPaths: WayfinderPathData[] = [
  {
    path: `/scenarios/:scenarioId/summary`,
    displayName: 'Summary',
    icon: <SummaryIcon />,
    rightColumnComponent: <Summary />,
  },
  {
    path: `/scenarios/:scenarioId/market`,
    displayName: 'Market',
    icon: <MarketIcon />,
    rightColumnComponent: <MarketV2 />,
  },
  {
    path: `/scenarios/:scenarioId/renewal`,
    displayName: 'Renewal',
    icon: <RenewalIcon />,
    rightColumnComponent: <Renewal />,
  },
  {
    path: `/scenarios/:scenarioId/new`,
    displayName: 'New',
    icon: <NewIcon />,
    rightColumnComponent: <New />,
  },
  {
    path: `/scenarios/:scenarioId/parameterisation`,
    displayName: 'Parameterisation',
    icon: <PortfolioIcon />,
    rightColumnComponent: <Parameterisation />,
  },
  {
    path: `/scenarios/:scenarioId/portfolio`,
    displayName: 'Portfolio',
    icon: <PortfolioIcon />,
    rightColumnComponent: <PortfolioDataView />,
  },
  {
    path: `/scenarios/:scenarioId/exposures`,
    displayName: 'Exposures',
    icon: <ExposuresIcon />,
    rightColumnComponent: <Exposures />,
  },
  {
    path: `/scenarios/:scenarioId/notes`,
    displayName: 'Notes',
    icon: <NotesIcon />,
    rightColumnComponent: <Notes />,
  },
]

export let StackedScenarioPaths: WayfinderPathData[] = [
  {
    path: `/stacked-scenarios/:stackedScenarioId/summary`,
    displayName: 'Summary',
    icon: <SummaryIcon />,
    rightColumnComponent: <StackedScenarioSummary />,
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/market`,
    displayName: 'Market',
    icon: <MarketIcon />,
    rightColumnComponent: <StackedScenarioMarketV2 />,
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/parameterisation`,
    displayName: 'Parameterisation',
    icon: <PortfolioIcon />,
    rightColumnComponent: <StackedScenarioParameterisation />,
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/portfolio`,
    displayName: 'Portfolio',
    icon: <PortfolioIcon />,
    rightColumnComponent: <StackedScenarioPortfolio />,
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/notes`,
    displayName: 'Notes',
    icon: <NotesIcon />,
    rightColumnComponent: <Notes />,
  },
]

export const OutwardScenarioPaths: WayfinderPathData[] = [
  {
    path: `/outward-scenarios/:outwardScenarioId/coverage`,
    displayName: 'Coverage',
    icon: <SummaryIcon />,
    rightColumnComponent: <OutwardScenarioCoverage />,
  },
  {
    path: `/outward-scenarios/:outwardScenarioId/portfolio`,
    displayName: 'Portfolio',
    icon: <PortfolioIcon />,
    rightColumnComponent: <OutwardScenarioPortfolio />,
  },
  {
    path: `/outward-scenarios/:outwardScenarioId/notes`,
    displayName: 'Notes',
    icon: <NotesIcon />,
    rightColumnComponent: <Notes />,
  },
]

export const OutwardStackedScenarioPaths: WayfinderPathData[] = [
  {
    path: `/outward-stacked-scenarios/:stackedScenarioId/summary`,
    displayName: 'Summary',
    icon: <SummaryIcon />,
    rightColumnComponent: <OutwardScenarioStackedSummary />,
  },
]

export const MarketEditorPaths: WayfinderPathData[] = [
  {
    path: `/${MarketV2Path}`,
    displayName: 'Market',
    icon: <MarketIcon />,
    rightColumnComponent: <MarketV2 />,
  },
]

export const WayfinderPaths: WayfinderPathData[] = [
  {
    path: `/scenario-chooser`,
    displayName: 'Scenarios',
    icon: <ScenariosIcon />,
    rightColumnComponent: <Scenarios />,
  },
  {
    path: `/outwardScenario-chooser`,
    displayName: 'OutwardScenarios',
    icon: <ScenariosIcon />,
    rightColumnComponent: <OutwardScenarios />,
  },
  {
    path: `/scenarios-comparison`,
    displayName: undefined,
    icon: undefined,
    rightColumnComponent: <ScenarioComparison />,
  },
  {
    path: `/stacked-scenarios-comparison`,
    displayName: undefined,
    icon: undefined,
    rightColumnComponent: <StackedScenarioComparison />,
  },
  ...ScenarioPaths,
  ...StackedScenarioPaths,
  ...OutwardScenarioPaths,
  ...OutwardStackedScenarioPaths,
  ...MarketEditorPaths,
]

export const DEFAULT_LOGGED_IN_PATH: WayfinderPathData = WayfinderPaths[0]
