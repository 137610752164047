import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect, useState } from 'react'

type Provider = ({ children }: { children: ReactNode }) => JSX.Element

export const LaunchDarklyProvider = ({ children }: { children: ReactNode }) => {
  const emptyComponent = () => <></>
  const [LDProvider, setLDProvider] = useState<Provider>(() => emptyComponent)

  useEffect(() => {
    ;(async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
      })
      setLDProvider(() => provider)
    })()
  }, [])

  return <LDProvider>{children}</LDProvider>
}
