import './Button.scss'

import { css } from '../../utils/css'
import { PropsWithChildren, forwardRef, ReactElement, ForwardedRef, ButtonHTMLAttributes } from 'react'

export type Props = {
  title: string
  secondary?: boolean
  icon?: ReactElement
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  isDisabled?: boolean
  onClick?: () => void
}

type Ref = ForwardedRef<HTMLButtonElement>

export const Button = forwardRef((props: PropsWithChildren<Props>, ref: Ref): ReactElement => {
  const { isDisabled, type, title, onClick } = props

  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={css('Button', props.secondary ? 'Secondary' : '')}
    >
      {props.icon && <div className="ButtonIcon">{props.icon}</div>}
      {title}
    </button>
  )
})
