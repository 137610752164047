import React, { SVGProps } from 'react'

const RenewalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#4E566B"
      fillRule="evenodd"
      d="M4,8.66666667 C4,7.56666667 4.44666667,6.56666667 5.17333333,5.84 L4.22666667,4.89333333 C3.26666667,5.86 2.66666667,7.19333333 2.66666667,8.66666667 C2.66666667,11.3866667 4.7,13.6266667 7.33333333,13.9533333 L7.33333333,12.6066667 C5.44666667,12.2866667 4,10.6466667 4,8.66666667 Z M13.3333333,8.66666667 C13.3333333,5.72 10.9466667,3.33333333 8,3.33333333 C7.96,3.33333333 7.92,3.34 7.88,3.34 L8.60666667,2.61333333 L7.66666667,1.66666667 L5.33333333,4 L7.66666667,6.33333333 L8.60666667,5.39333333 L7.88666667,4.67333333 C7.92666667,4.67333333 7.96666667,4.66666667 8,4.66666667 C10.2066667,4.66666667 12,6.46 12,8.66666667 C12,10.6466667 10.5533333,12.2866667 8.66666667,12.6066667 L8.66666667,13.9533333 C11.3,13.6266667 13.3333333,11.3866667 13.3333333,8.66666667 Z"
      transform="matrix(-1 0 0 1 16 0)"
    />
  </svg>
)

export default RenewalIcon
