import React, { SVGProps } from 'react'

const LockedIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,5.33333333 L11.3333333,5.33333333 L11.3333333,4 C11.3333333,2.16 9.84,0.666666667 8,0.666666667 C6.16,0.666666667 4.66666667,2.16 4.66666667,4 L4.66666667,5.33333333 L4,5.33333333 C3.26666667,5.33333333 2.66666667,5.93333333 2.66666667,6.66666667 L2.66666667,13.3333333 C2.66666667,14.0666667 3.26666667,14.6666667 4,14.6666667 L12,14.6666667 C12.7333333,14.6666667 13.3333333,14.0666667 13.3333333,13.3333333 L13.3333333,6.66666667 C13.3333333,5.93333333 12.7333333,5.33333333 12,5.33333333 Z M6,4 C6,2.89333333 6.89333333,2 8,2 C9.10666667,2 10,2.89333333 10,4 L10,5.33333333 L6,5.33333333 L6,4 Z M12,13.3333333 L4,13.3333333 L4,6.66666667 L12,6.66666667 L12,13.3333333 Z M8,11.3333333 C8.73333333,11.3333333 9.33333333,10.7333333 9.33333333,10 C9.33333333,9.26666667 8.73333333,8.66666667 8,8.66666667 C7.26666667,8.66666667 6.66666667,9.26666667 6.66666667,10 C6.66666667,10.7333333 7.26666667,11.3333333 8,11.3333333 Z"
          id="Shape"
          fill="#DCDEE2"
        />
      </g>
    </svg>
  </div>
)

export default LockedIcon
