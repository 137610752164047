import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export type PlacementType = {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
}

export type NewPlacementType = {
  name: string
}

export async function fetchAllPlacementTypes(): Promise<PlacementType[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/placement_types`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}
