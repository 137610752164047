import { Thead, Tr } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export const NcpTableHead = (props: PropsWithChildren) => {
  return (
    <Thead backgroundColor={'#F8F8F8'}>
      <Tr>{props.children}</Tr>
    </Thead>
  )
}
