import { useCallback, useContext } from 'react'
import { ProgressContext, ProgressState } from '../../../providers/ProgressProvider'
import { NcpAlertDispatchContext } from '../../../providers/NewCustomPortfolioAlertStateProvider'

import { transformErrorMessages } from '../utils/errorMessages'

export const useHandleBackendErrors = (setUniqueNameError: (message: string) => void): ((error: any) => void) => {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const ncpAlertDispatch = useContext(NcpAlertDispatchContext)

  return useCallback(
    (error: any) => {
      if (Array.isArray(error.message)) {
        updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
        ncpAlertDispatch?.({ type: 'setError', payload: transformErrorMessages(error.message) })
        throw Error
      }

      if (error.name && error.name === 'ValidationError') {
        updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
        const errMsgs = error.message.split(', ')
        const uniqueErrMsgsArray = Array.from(new Set<string>(errMsgs))
        ncpAlertDispatch?.({ type: 'setError', payload: transformErrorMessages(uniqueErrMsgsArray) })
        throw Error
      }

      if (error.name && error.name === 'UpdateError') {
        updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
        throw Error
      }

      if (error.message) {
        const message = error.message as string
        if (message.includes('Adjustment name already exists for scenario')) {
          setUniqueNameError('Unique name is required')
          updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
          throw Error
        }
        //TODO: Implement error message for user - WAY-1408
        if (message.includes('Unexpected type encountered')) {
          updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
          throw Error
        }
      }
    },
    [updateIndividualProgressItem, ncpAlertDispatch],
  )
}
