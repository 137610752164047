import React, { useMemo } from 'react'
import {
  Column,
  TableBodyProps,
  TableCellProps,
  TableInstance,
  TableOptions,
  TableProps,
  TableRowProps,
  useTable,
  UseTableOptions,
} from 'react-table'
import './StackedMarketBreakdownByLevelTableV2.scss'
import SectionHeader from '../section-header/SectionHeader'
import { ScenarioLevel } from '../../backend/stacked-scenarios'
import { displayLargeDollarValue } from '../../utils/numbers'

interface StackedMarketBreakdownByTableV2Props extends TableOptions<any> {
  scenarioLevel: undefined | ScenarioLevel
  sumOfTotalMarketGwp: undefined | string | number
}

export function displayHeaderBasedOnScenarioLevel(scenarioLevel: ScenarioLevel | undefined) {
  if (!scenarioLevel) {
    return ''
  }

  if (scenarioLevel === ScenarioLevel.TEAM) {
    return 'Market'
  }

  if (scenarioLevel === ScenarioLevel.DIVISIONAL) {
    return 'Team'
  }

  if (scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
    return 'Division'
  }

  throw new Error(`Unhandled scenarioLevel: ${scenarioLevel}`)
}

export function StackedMarketBreakdownByLevelTableV2(props: StackedMarketBreakdownByTableV2Props) {
  const { columns, data, scenarioLevel, sumOfTotalMarketGwp } = props

  const columnTemp2: Column<any>[] = useMemo(() => {
    return columns.map((x: any) => {
      const maybeCell = x.Cell ? { Cell: x.Cell('foo') } : {} // TODO: fix this foo
      return { ...x, ...maybeCell }
    })
  }, [columns])

  const dataTemp: readonly any[] = useMemo(() => data, [data])

  const useTableOptions: UseTableOptions<any> = { columns: columnTemp2, data: dataTemp }
  const tableInstance: TableInstance<any> = useTable(useTableOptions)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const tableProps: TableProps = getTableProps()
  const tableBodyProps: TableBodyProps = getTableBodyProps()

  return (
    <div className="StackedMarketBreakdownByLevelTableV2">
      <SectionHeader
        className="TableTitle"
        title={`Convex ${displayHeaderBasedOnScenarioLevel(scenarioLevel)} Relativities`}
      />
      <table {...tableProps}>
        <thead>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                })}
              </tr>
            )
          })}
        </thead>
        <tbody {...tableBodyProps}>
          {rows.map((row) => {
            // console.log('row...');
            // console.log(row);

            prepareRow(row)
            const tableRowProps: TableRowProps = row.getRowProps()
            return (
              <tr {...tableRowProps}>
                {row.cells.map((cell) => {
                  // console.log('cell:');
                  // console.log(cell);

                  // cell.Cell = cell.Cell(row['original']['aggregateObjectId'])
                  // const cell2 = cell('foobar');
                  const cellProps: TableCellProps = cell.getCellProps()
                  // console.log('cellProps::', cellProps); // { key, role }
                  const cellRender: React.ReactNode = cell.render('Cell')
                  return <td {...cellProps}>{cellRender}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>{displayLargeDollarValue(sumOfTotalMarketGwp)}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
