import React, { ReactElement, useContext, useMemo } from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'

import { GRAPH_COLOURS } from '../graphs/Graphs'
import { DEFAULT_GRAPH_THEME } from '../renewal-graph/graph-theme'
import { ExposuresDataContext } from '../../providers/ExposuresDataProvider'
import { GraphStyleContext } from '../renewal-graph/GraphStyleProvider'
import { GraphContainerContext } from '../graph-container/GraphContainer'
import { MarketShareValues } from '../../backend/exposures'

const MAIN_ZONES_TO_DISPLAY = ['NAWS', 'USEQ', 'EUEQ', 'EUTC', 'JPEQ', 'JPWS']

type Props = { hideNonMainZones?: boolean; filterByPeril?: string }

export const MarketShareBarGraph = (props: Props): ReactElement => {
  const exposuresDataContext = useContext(ExposuresDataContext)
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)

  const optionsIfGraphExpanded: any =
    currentlyExpandedGraph === graphId
      ? {
          margin: { top: 10, right: 0, bottom: 75, left: 80 },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '%',
            legendPosition: 'middle',
            legendOffset: -50,
          },
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Peril',
            legendPosition: 'middle',
            legendOffset: 40,
          },
        }
      : {}

  const marketShareValuesGroupedByZonename = exposuresDataContext.marketShareValuesGroupedByZoneName?.results

  const calculatedBarGraphData = useMemo(() => {
    const marketShareBarGraphData = []
    if (marketShareValuesGroupedByZonename) {
      for (const [zoneName, value] of Object.entries(marketShareValuesGroupedByZonename)) {
        if (props.filterByPeril && value.peril !== props.filterByPeril) {
          continue
        }
        if (props.hideNonMainZones && !MAIN_ZONES_TO_DISPLAY.includes(zoneName)) {
          continue
        }
        const marketShareValues = value as MarketShareValues
        const marketShare = parseFloat(marketShareValues.marketShare)
        const marketShareWithRetention = parseFloat(marketShareValues.marketShareWithRetention)

        if (marketShare !== 0 || marketShareWithRetention !== 0) {
          marketShareBarGraphData.push({
            id: zoneName,
            marketShare: marketShare,
            marketShareWithRetention: marketShareWithRetention,
          })
        }
      }
    }
    return marketShareBarGraphData
  }, [marketShareValuesGroupedByZonename, props.hideNonMainZones, props.filterByPeril])

  return (
    <div className="Graph ExposuresMarketShareBarGraph">
      <ResponsiveBarCanvas
        data={calculatedBarGraphData}
        enableLabel={true}
        keys={['marketShare', 'marketShareWithRetention']}
        {...optionsIfGraphExpanded}
        groupMode="grouped"
        labelTextColor="#fff"
        label={(item) => item.value + '%'}
        tooltipFormat={(item: any) => item + '%'}
        colors={GRAPH_COLOURS}
        theme={DEFAULT_GRAPH_THEME}
      />
    </div>
  )
}
