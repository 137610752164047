import React, { SVGProps } from 'react'

const TagIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g>
          <polygon points="0 0 16 0 16 16 0 16"></polygon>
          <path
            d="M14.2733333,7.72 L8.27333333,1.72 C8.03333333,1.48 7.7,1.33333333 7.33333333,1.33333333 L2.66666667,1.33333333 C1.93333333,1.33333333 1.33333333,1.93333333 1.33333333,2.66666667 L1.33333333,7.33333333 C1.33333333,7.7 1.48,8.03333333 1.72666667,8.28 L7.72666667,14.28 C7.96666667,14.52 8.3,14.6666667 8.66666667,14.6666667 C9.03333333,14.6666667 9.36666667,14.52 9.60666667,14.2733333 L14.2733333,9.60666667 C14.52,9.36666667 14.6666667,9.03333333 14.6666667,8.66666667 C14.6666667,8.3 14.5133333,7.96 14.2733333,7.72 Z M8.66666667,13.34 L2.66666667,7.33333333 L2.66666667,2.66666667 L7.33333333,2.66666667 L7.33333333,2.66 L13.3333333,8.66 L8.66666667,13.34 L8.66666667,13.34 Z"
            id="Shape"
            fill="#4E566B"
            fillRule="nonzero"
          ></path>
          <circle
            id="Oval"
            fill="#4E566B"
            fillRule="nonzero"
            cx="4.33333333"
            cy="4.33333333"
            r="1"
          ></circle>
        </g>
      </g>
    </svg>
  </div>
)

export default TagIcon
