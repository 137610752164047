import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React from 'react'

type ExposuresConfirmOvernightModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  targetMarketHasNoPendingReports: boolean
  handleReprojection: () => void
}

export const ExposuresConfirmOvernightModal = (props: ExposuresConfirmOvernightModalProps) => {
  const { isOpen, setIsOpen, targetMarketHasNoPendingReports, handleReprojection } = props

  const confirmButtonRef = React.useRef(null)

  const handleCloseAlertDialog = () => {
    setIsOpen(false)
  }

  const handleConfirmAlertDialog = () => {
    handleReprojection()
    setIsOpen(false)
  }

  if (targetMarketHasNoPendingReports) {
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={confirmButtonRef}
        onClose={handleCloseAlertDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Request Projected Results</AlertDialogHeader>
            <AlertDialogBody>
              After you request projected results this report will no longer be editable. Are you sure you want to
              proceed?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={handleCloseAlertDialog}>Cancel</Button>
              <Button
                ref={confirmButtonRef}
                onClick={handleConfirmAlertDialog}
                ml={3}
              >
                Request Projected Results
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
  }
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={confirmButtonRef}
      onClose={handleCloseAlertDialog}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Sorry, we can't process your request</AlertDialogHeader>
          <AlertDialogBody>
            You can only request projected results for one report per target market at a time.
            <br></br>
            <br></br>
            Please wait until analysis on the report you requested has been completed before requesting another one.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={handleCloseAlertDialog}
              title={''}
            >
              Okay
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
