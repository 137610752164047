import { getCurrentConfigSettings } from '../utils/config'
import { patchScenario } from './scenarios'
import { getAccessToken } from './teams'

export interface NewClient {
  id: string
  orderNumber: number
  isEnabled: boolean
  scenarioId: string
  name: string
  description: string
  inceptionMonth: string
  segment: string
  classOfBusiness: string
  placingBasis?: string
  entity: string
  convexGwp: number
  attachment: number
  grossRiskAdjustedRateChange: number
  lossInput: number
  acqCost: number
  convexShare: number
  convexWrittenShare: number
  convexSignedShare: number
  convexLimit: number
  probability: number
  currency?: Currency
  placementType?: PlacementType
}

export async function fetchNewClients(scenarioId: string): Promise<NewClient[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}

export async function deleteNewClientItem(scenarioId: string, adjustmentId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients/${adjustmentId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}
}

export async function deleteAllNewClientItems(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
}

export async function fetchNewClientItem(scenarioId: string, adjustmentId: string): Promise<NewClient> {
  // TODO: update the NewClient backend service with new type
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients/${adjustmentId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

export async function fetchNewClientItems(scenarioId: string): Promise<NewClient[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  return await response.json()
}

type PatchNewClientFull = {
  readonly orderNumber: number
  readonly isEnabled: boolean
  readonly name: string
  readonly description: string
  readonly inceptionMonth: string
  readonly segment: string
  readonly classOfBusiness: string
  readonly placingBasis?: string
  readonly entity: string
  readonly convexGwp: string
  readonly attachment: string
  readonly grossRiskAdjustedRateChange: number
  readonly lossInput: number
  readonly acqCost: number
  readonly convexShare: number
  readonly convexWrittenShare: number
  readonly convexLimit: string
  readonly probability: number
  readonly currency: Currency
  readonly placementType: PlacementType
}

export type Currency = {
  id: string
  name: string
  displayName: string
  symbol: string
  createdAt: Date
  updatedAt: Date
}

export type PlacementType = {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
}

export type PatchNewClient = Partial<PatchNewClientFull>

export async function patchNewClientItem(
  scenarioId: string,
  adjustmentId: string,
  patchAdjustmentRequest: PatchNewClient,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients/${adjustmentId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchAdjustmentRequest),
    },
  )

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return await response.json()
}

export async function refreshClientAdjustments(scenarioId: string, adjustmentId: string): Promise<NewClient> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients/${adjustmentId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify({}),
    },
  )

  return await response.json()
}
