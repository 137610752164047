import React from 'react'
import './LineDivider.scss'
export type DividerProps = React.PropsWithChildren<{}>

export function Divider(props: DividerProps) {
  return (
    <div
      className="LineDivider"
      {...props}
    >
      {props.children}
      <div className="Line"></div>
    </div>
  )
}

export default Divider
