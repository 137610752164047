import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { Card } from '../card/Card'

import './CreateNewPortfolioClientCard.scss'

import TextField from '../text-field/WFTextField'
import {
  onInputUpdateState,
  onNumberInputUpdateState,
  onTextAreaUpdateState,
  setValueIncludingSuffixShorthand,
} from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import Button from '../button/Button'
import { createNewPortfolioClient } from '../../backend/new-portfolio-items'
import {
  displayLargeDollarValue,
  isNumber,
  isNumberOrZero,
  numericValueIsDefined,
  PossiblyNegativeNumber,
} from '../../utils/numbers'
import { ErrorText } from '../text-area/ErrorText'
import { PREVIEW_ID } from '../../backend/adjustments'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import { PlacementType, fetchNewClientItem, patchNewClientItem } from '../../backend/new-clients'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { groupListOfObjsBy, toOption } from '../../utils/lists'
import TextArea from '../text-area/TextArea'
import { validateDescription } from '../create-scenario-card/CreateScenarioCard'
import { Currency, fetchAllCurrencies } from '../../backend/currency'
import { CurrencyField } from './CurrencyField'
import { fetchAllPlacementTypes } from '../../backend/placement-type'
import { PlacementTypeField } from './PlacementTypeField'
import {
  UNSAFE_calculateNewGwpError,
  calculateNewGwpInputWarning,
} from '../create-new-portfolio-item-card/validationMessages'
import { calculatePercentileError } from './validationMessages'
import { RawEntry } from 'src/backend/rawEntry'

export type CreateNewPortfolioClientCardProps = {
  scenarioId: string
  itemBeingEditedId: string
  defaultMarketDataSegmentNames: string[]
  requestCloseModal: () => void
}

const CreateNewPortfolioClientCard = (props: CreateNewPortfolioClientCardProps) => {
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  const { triggerReloadNewClients } = useContext(NewClientItemsContext)

  const [currentName, setName] = useState<string>('')
  const [currentDescription, setDescription] = useState<string>('')
  const [currentGwp, setGwp] = useState<PossiblyNegativeNumber>(0)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [errorUniqueName, setErrorUniqueName] = useState<string>('')
  const [currentInceptionMonth, setInceptionMonth] = useState('1')
  const [currentCob, setCob] = useState<string>('')
  const [currentEntity, setEntity] = useState('CIL')
  const [currentConvexShare, setConvexShare] = useState<PossiblyNegativeNumber>(0)
  const [currentConvexWrittenShare, setConvexWrittenShare] = useState<PossiblyNegativeNumber>(0)
  const [currentAttachment, setAttachment] = useState<PossiblyNegativeNumber>(0)
  const [currentGrarc, setGrarc] = useState<PossiblyNegativeNumber>(0)
  const [currentAcqCost, setAcqCost] = useState<PossiblyNegativeNumber>(0)
  const [currentLossInput, setLossInput] = useState<PossiblyNegativeNumber>(0)
  const [currentConvexLimit, setConvexLimit] = useState<PossiblyNegativeNumber>(0)
  const [currentProbability, setProbability] = useState<PossiblyNegativeNumber>(100)
  const [currentSegment, setSegment] = useState<string>(props.defaultMarketDataSegmentNames?.[0])
  const [possibleSegmentValues, setSegmentValues] = useState<string[]>([])
  const [possibleCobValues, setCobValues] = useState<string[]>([])
  const [possibleEntityValues, setEntityValues] = useState<string[]>([])

  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [currentCurrencyId, setCurrentCurrencyId] = useState<string>('placeholder')

  const [placementTypes, setPlacementTypes] = useState<PlacementType[]>([])
  const [currentPlacementTypeId, setCurrentPlacementTypeId] = useState<string>('placeholder')

  const isPreview = props.itemBeingEditedId === PREVIEW_ID
  const createOrEditWord = isPreview ? 'Create' : 'Edit'
  const addOrSaveWord = isPreview ? 'Add' : 'Save'

  const fetchCurrencies: () => Promise<void> = useCallback(async () => {
    try {
      const currencies = await fetchAllCurrencies()
      setCurrencies(currencies)
    } catch (err: unknown) {
      console.error(`Error occurred whilst trying to fetch currencies, ${err}`)
    }
  }, [])

  const fetchPlacementTypes: () => Promise<void> = useCallback(async () => {
    try {
      const placementTypes = await fetchAllPlacementTypes()
      setPlacementTypes(placementTypes)
    } catch (err: unknown) {
      console.error(`Error occurred whilst trying to fetch placement types, ${err}`)
    }
  }, [])

  useEffect(() => {
    fetchCurrencies()
    fetchPlacementTypes()
  }, [])

  const updateCob = (newValue: string) => setCob(newValue)

  useEffect(() => {
    const segmentValues = props.defaultMarketDataSegmentNames || []
    setSegmentValues(segmentValues)
    setCobValues(calculateFieldsDropdownValues(fullDataForScenario, 'COB - Tier 2', updateCob))
    const entityValues = ['CIL', 'CRL', 'CES', 'CUS', 'CGU']
    setEntityValues(entityValues)
  }, [fullDataForScenario, props.defaultMarketDataSegmentNames])

  useEffect(() => {
    ;(async () => {
      if (isPreview) {
        return
      }

      try {
        const existingNewClientItem = await fetchNewClientItem(props.scenarioId, props.itemBeingEditedId)

        setName(existingNewClientItem.name)
        setDescription(existingNewClientItem.description)
        setGwp(existingNewClientItem.convexGwp)
        setShouldValidate(false)
        setInceptionMonth(existingNewClientItem.inceptionMonth)
        setSegment(existingNewClientItem.segment)
        setCob(existingNewClientItem.classOfBusiness)
        setCurrentPlacementTypeId(
          existingNewClientItem.placementType ? existingNewClientItem.placementType.id : 'placeholder',
        )
        setEntity(existingNewClientItem.entity)
        setConvexShare(existingNewClientItem.convexShare)
        setConvexWrittenShare(existingNewClientItem.convexWrittenShare)
        setAttachment(existingNewClientItem.attachment)
        setGrarc(existingNewClientItem.grossRiskAdjustedRateChange)
        setAcqCost(existingNewClientItem.acqCost)
        setLossInput(existingNewClientItem.lossInput)
        setConvexLimit(existingNewClientItem.convexLimit)
        setProbability(existingNewClientItem.probability)
        setCurrentCurrencyId(existingNewClientItem.currency ? existingNewClientItem.currency.id : 'placeholder')
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.error(`Error occurred whilst trying to fetch new client item, ${err.message}`)
          return
        }

        console.error(`An unknown error occurred whilst trying to fetch new client item`)
      }
    })()
  }, [props.scenarioId, props.itemBeingEditedId, isPreview])

  const onClickCreateOrUpdateItem = async () => {
    setShouldValidate(true)

    // validate all the fields
    if (
      UNSAFE_calculateNewGwpError(currentGwp) ||
      !isValidName(currentName) ||
      !isValidName(currentSegment) ||
      !isValidName(currentCob) ||
      !isValidName(currentEntity) ||
      validateDescription(currentDescription) ||
      calculatePercentileError(currentProbability) ||
      calculatePercentileError(currentConvexShare) ||
      !numericValueIsDefined(currentProbability) ||
      !numericValueIsDefined(currentAttachment) ||
      !numericValueIsDefined(currentGrarc) ||
      !numericValueIsDefined(currentLossInput) ||
      !numericValueIsDefined(currentAcqCost)
    ) {
      console.error(`Failed validation of the new client`)
      return
    }

    const resetAfterSuccess = () => {
      setErrorUniqueName('')
      props.requestCloseModal()
      triggerReloadNewClients()
      setName('')
      setDescription('')
      setGwp(0)
      setShouldValidate(false)
      setInceptionMonth('1')
      setCob('')
      setCurrentPlacementTypeId('placeholder')
      setEntity('')
      setAttachment(0)
      setGrarc(0)
      setLossInput(0)
      setAcqCost(0)
      setConvexShare(0)
      setConvexWrittenShare(0)
      setConvexLimit(0)
      setProbability(100)
      setSegment(props.defaultMarketDataSegmentNames?.[0] || '')
      setCurrentCurrencyId('placeholder')
    }

    const currency = currencies.find((c) => c.id === currentCurrencyId)
    if (!currency) {
      console.error(`Failed to find currency with id ${currentCurrencyId}`)
      return
    }

    const placementType = placementTypes.find((c) => c.id === currentPlacementTypeId)
    if (!placementType) {
      console.error(`Failed to find placement type with id ${currentPlacementTypeId}`)
      return
    }

    if (isPreview) {
      const res = await createNewPortfolioClient(props.scenarioId, {
        name: currentName,
        description: currentDescription,
        inceptionMonth: parseInt(currentInceptionMonth),
        segment: currentSegment,
        classOfBusiness: currentCob,
        placementType,
        entity: currentEntity,
        convexGwp: isNumber(currentGwp) ? currentGwp.toString() : '0',
        attachment: isNumber(currentAttachment) ? currentAttachment.toString() : '0',
        grossRiskAdjustedRateChange: isNumberOrZero(currentGrarc),
        acqCost: isNumberOrZero(currentAcqCost),
        lossInput: isNumberOrZero(currentLossInput),
        convexShare: isNumberOrZero(currentConvexShare),
        convexWrittenShare: isNumberOrZero(currentConvexWrittenShare),
        convexLimit: isNumber(currentConvexLimit) ? currentConvexLimit.toString() : '0',
        probability: isNumberOrZero(currentProbability),
        currency,
      })
      checkBackendResponse(res, setErrorUniqueName)
      resetAfterSuccess()
    } else {
      const res = await patchNewClientItem(props.scenarioId, props.itemBeingEditedId, {
        name: currentName,
        description: currentDescription,
        inceptionMonth: currentInceptionMonth,
        segment: currentSegment,
        classOfBusiness: currentCob,
        placementType,
        entity: currentEntity,
        convexGwp: numericValueIsDefined(currentGwp) ? currentGwp?.toString() : '0',
        attachment: numericValueIsDefined(currentAttachment) ? currentAttachment?.toString() : '0',
        grossRiskAdjustedRateChange: isNumberOrZero(currentGrarc),
        acqCost: isNumberOrZero(currentAcqCost),
        lossInput: isNumberOrZero(currentLossInput),
        convexShare: isNumberOrZero(currentConvexShare),
        convexWrittenShare: isNumberOrZero(currentConvexWrittenShare),
        convexLimit: numericValueIsDefined(currentConvexLimit) ? currentConvexLimit?.toString() : '0',
        probability: isNumberOrZero(currentProbability),
        currency,
      })
      checkBackendResponse(res, setErrorUniqueName)
      resetAfterSuccess()
    }
  }

  return (
    <div className="CreateNewPortfolioClientCard">
      <Card>
        <h4 className="CreateNewPortfolioClientCardTitle">{createOrEditWord} client input</h4>
        <div className="MiddleOptions">
          <MultiSelectDropdown
            title="Inception Month"
            id="New-Client-Card-Dropdown-Inception-Month"
            onSelect={onSelectSingleValueFromMulti(setInceptionMonth)}
            options={possibleInceptionMonths.map((item) => ({
              value: item[0],
              label: item[1],
            }))}
            selected={[
              {
                label: possibleInceptionMonths[Number(currentInceptionMonth) - 1][1] as string,
                value: currentInceptionMonth,
              },
            ]}
          />
          <MultiSelectDropdown
            title="A1 Segment"
            id="New-Client-Card-Dropdown-A1-Segment"
            onSelect={onSelectSingleValueFromMulti(setSegment)}
            options={possibleSegmentValues.map(toOption)}
            selected={[{ label: currentSegment, value: currentSegment }]}
          />
          <MultiSelectDropdown
            title="Cob - Tier 2"
            id="New-Client-Card-Dropdown-Cob-Tier-2"
            onSelect={onSelectSingleValueFromMulti(setCob)}
            options={possibleCobValues.map(toOption)}
            selected={[toOption(currentCob)]}
          />
          <PlacementTypeField
            placementTypes={placementTypes}
            id="New-Client-Card-Dropdown-Placement-Type"
            currentPlacementTypeId={currentPlacementTypeId}
            setCurrentPlacementTypeId={setCurrentPlacementTypeId}
            error={shouldValidate ? validatePlacementType(currentPlacementTypeId) : ''}
          />
          <MultiSelectDropdown
            title="Entity"
            id="New-Client-Card-Dropdown-Entity"
            onSelect={onSelectSingleValueFromMulti(setEntity)}
            options={possibleEntityValues.map(toOption)}
            selected={[toOption(currentEntity)]}
          />
          <CurrencyField
            currencies={currencies}
            id="New-Client-Card-Dropdown-Original-Currency"
            currentCurrencyId={currentCurrencyId}
            setCurrentCurrencyId={setCurrentCurrencyId}
            error={shouldValidate ? validateCurrency(currentCurrencyId) : ''}
          />
          <TextField
            title="Convex GWP"
            id="New-Client-Card-TextField-Convex-GWP"
            className="GWP"
            placeholder="0"
            value={displayLargeDollarValue(currentGwp)}
            onChange={setValueIncludingSuffixShorthand(setGwp)}
            warning={calculateNewGwpInputWarning(currentGwp)}
            error={shouldValidate ? UNSAFE_calculateNewGwpError(currentGwp) : ''}
          />
          <TextField
            title="Convex Signed Share"
            id="New-Client-Card-TextField-Convex-Signed-Share"
            className="Percentile"
            type="number"
            value={currentConvexShare}
            onChange={onNumberInputUpdateState(setConvexShare)}
            percentage
            error={shouldValidate ? calculatePercentileError(currentConvexShare) : ''}
          />
          <TextField
            title="Convex Written Share"
            id="New-Client-Card-TextField-Convex-Written-Share"
            className="Percentile"
            type="number"
            value={currentConvexWrittenShare}
            onChange={onNumberInputUpdateState(setConvexWrittenShare)}
            percentage
          />
          <TextField
            title="Convex Limit"
            id="New-Client-Card-TextField-Convex-Limit"
            className="GWP"
            placeholder="0"
            value={displayLargeDollarValue(currentConvexLimit)}
            onChange={setValueIncludingSuffixShorthand(setConvexLimit)}
            warning={calculateNewGwpInputWarning(currentConvexLimit)}
            error={shouldValidate ? UNSAFE_calculateNewGwpError(currentConvexLimit) : ''}
          />
          <TextField
            title="Attachment"
            id="New-Client-Card-TextField-Attachment"
            className="Percentile"
            placeholder="0"
            value={displayLargeDollarValue(currentAttachment)}
            onChange={setValueIncludingSuffixShorthand(setAttachment)}
            error={shouldValidate ? (numericValueIsDefined(currentAttachment) ? '' : 'Please enter a value') : ''}
          />
          <TextField
            title="GRARC"
            id="New-Client-Card-TextField-GRARC"
            className="GRARC"
            type="number"
            value={currentGrarc}
            onChange={onNumberInputUpdateState(setGrarc)}
            error={shouldValidate ? calculatePercentileError(currentGrarc) : ''}
            percentage
          />
          <TextField
            title="Acq Cost"
            id="New-Client-Card-TextField-Acq-Cost"
            className="Percentile"
            type="number"
            value={currentAcqCost}
            onChange={onNumberInputUpdateState(setAcqCost)}
            error={shouldValidate ? calculatePercentileError(currentAcqCost) : ''}
            percentage
          />
          <TextField
            title="GG Loss Ratio"
            id="New-Client-Card-TextField-GG-Loss-Ratio"
            className="Percentile"
            type="number"
            value={currentLossInput}
            onChange={onNumberInputUpdateState(setLossInput)}
            error={shouldValidate ? calculatePercentileError(currentLossInput) : ''}
            percentage
          />
          <TextField
            title="Probability"
            id="New-Client-Card-TextField-Probability"
            className="Probability"
            type="number"
            value={currentProbability}
            onChange={onNumberInputUpdateState(setProbability)}
            error={shouldValidate ? calculatePercentileError(currentProbability) : ''}
            percentage
          />
        </div>
        <div className="BottomOptions">
          <TextField
            title="Name"
            id="New-Client-Card-TextField-Name"
            placeholder="Name"
            value={currentName}
            onChange={onInputUpdateState(setName)}
            error={shouldValidate ? (isValidName(currentName) ? '' : 'Please enter a name for the adjustment') : ''}
            className="Name"
          />
          <TextArea
            className="AdjustmentDescription"
            title="Description"
            id="New-Client-Card-TextField-Description"
            placeholder="Description"
            value={currentDescription}
            onChange={onTextAreaUpdateState(setDescription)}
            error={shouldValidate ? validateDescription(currentDescription) : ''}
          />
        </div>
        <div className="ButtonContainer">
          {!isPreview && (
            <Button
              title="Cancel"
              id="New-Client-Card-Button-Cancel"
              secondary
              onClick={props.requestCloseModal}
            />
          )}
          <Button
            title={addOrSaveWord}
            id="New-Client-Card-Button-Add-Or-Save"
            onClick={onClickCreateOrUpdateItem}
          />
        </div>
        {shouldValidate && errorUniqueName.length > 0 && <ErrorText error={errorUniqueName} />}
      </Card>
    </div>
  )
}

const calculateFieldsDropdownValues = (
  rawData: RawEntry[],
  field: string,
  updateValue: (value: string) => void,
): string[] => {
  const groupedDataCob = groupListOfObjsBy(rawData, field)
  let values = Object.keys(groupedDataCob).filter((item) => item !== 'undefined')

  if (!values || values.length <= 0) {
    values = ['Unknown']
  }

  updateValue(values[0])
  return values
}

const checkBackendResponse = (response: object, setErrorUniqueName: Dispatch<SetStateAction<string>>) => {
  if (JSON.stringify(response).toString().includes('Client name already exists for scenario')) {
    setErrorUniqueName('Unique name is required')
    throw Error
  }
}

const validateCurrency = (currencyId: string): string =>
  currencyId === 'placeholder' ? 'Select original currency' : ''

const validatePlacementType = (placementTypeId: string): string =>
  placementTypeId === 'placeholder' ? 'Select placement type' : ''

const possibleInceptionMonths = [
  ['1', 'January'],
  ['2', 'February'],
  ['3', 'March'],
  ['4', 'April'],
  ['5', 'May'],
  ['6', 'June'],
  ['7', 'July'],
  ['8', 'August'],
  ['9', 'September'],
  ['10', 'October'],
  ['11', 'November'],
  ['12', 'December'],
]

export default CreateNewPortfolioClientCard
