import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ScenarioContext } from '../ScenarioProvider'
import { DefaultParameterData, fetchDefaultParameters } from '../../backend/parameterisation'
import { ProgressContext, ProgressState } from '../ProgressProvider'

export const ParameterisationDefaultMarketDataContext =
  createContext<ParameterisationDefaultMarketDataContextContainer>({
    defaultMarketData: undefined,
    triggerLoadMarketDefaults: () => void 0,
  })

export interface ParameterisationDefaultMarketDataContextContainer {
  defaultMarketData: DefaultParameterData | undefined
  triggerLoadMarketDefaults: () => void
}

export const ParameterisationDefaultMarketDataProvider = (props: PropsWithChildren) => {
  const [defaultMarketData, setDefaultMarketData] = useState<DefaultParameterData | undefined>(undefined)

  const { currentScenario } = useContext(ScenarioContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const triggerLoadMarketDefaults = () => {
    if (!currentScenario) {
      return
    }
    updateIndividualProgressItem('fetchingParameterisationDefaults', ProgressState.LOADING)

    fetchDefaultParameters(currentScenario.id)
      .then(setDefaultMarketData)
      .then(() => updateIndividualProgressItem('fetchingParameterisationDefaults', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingParameterisationDefaults', ProgressState.ERROR))
  }

  useEffect(triggerLoadMarketDefaults, [currentScenario, updateIndividualProgressItem])

  return (
    <ParameterisationDefaultMarketDataContext.Provider
      value={{
        defaultMarketData,
        triggerLoadMarketDefaults,
      }}
    >
      {props.children}
    </ParameterisationDefaultMarketDataContext.Provider>
  )
}
