import React from 'react'
import './PageHeaderContainer.scss'
export type PageHeaderContainerProps = React.PropsWithChildren<{}>

export function PageHeaderContainer(props: PageHeaderContainerProps) {
  return (
    <div
      className="PageHeaderContainer"
      {...props}
    >
      {props.children}
    </div>
  )
}

export default PageHeaderContainer
