import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { FullScenarioDataContext } from '../FullScenarioData/FullScenarioDataProvider'
import { fetchApplicableMarkets, fetchApplicablePerils, MarketSearchRequest } from '../../backend/perils'
import { OutwardScenarioContext } from './OutwardScenarioProvider'

interface OutwardsApplicablePerilsContainer {
  allApplicablePerils: string[]
  applicableMarkets: Array<MarketSearchRequest>
  reloadApplicablePerils: () => void
  reloadApplicableMarkets: () => void
}

export const OutwardsApplicablePerilsContext = createContext<OutwardsApplicablePerilsContainer>({
  allApplicablePerils: [],
  applicableMarkets: [],
  reloadApplicablePerils: () => void 0,
  reloadApplicableMarkets: () => void 0,
})

export function calculateListOfMarkets(filteredDataForScenario: any[]) {
  const allMarketsAndTeamsWithDuplicates: Array<MarketSearchRequest> = filteredDataForScenario.map((item) => ({
    team: item['UW Team'],
    market: item['A1 Market'],
  }))

  const allMarketsAndTeamsAsListOfStringsWithDuplicates = allMarketsAndTeamsWithDuplicates.map((item) =>
    JSON.stringify(item),
  )

  const allMarketsAndTeamsAsListOfStringsWithoutDuplicates = Array.from(
    new Set(allMarketsAndTeamsAsListOfStringsWithDuplicates),
  )

  const deDupedMarketsAndTeams: Array<MarketSearchRequest> = allMarketsAndTeamsAsListOfStringsWithoutDuplicates.map(
    (item) => JSON.parse(item),
  )

  return deDupedMarketsAndTeams
}

export function OutwardsApplicablePerilsProvider(props: React.PropsWithChildren<{}>) {
  const { filteredDataForScenario } = useContext(FullScenarioDataContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  const [currentApplicablePerils, setApplicablePerils] = useState<string[]>([])

  const allMarketData: MarketSearchRequest[] = useMemo(() => {
    return calculateListOfMarkets(filteredDataForScenario)
  }, [filteredDataForScenario])

  // eslint-disable-next-line
  const [applicableMarkets, setApplicableMarkets] = useState<MarketSearchRequest[]>(allMarketData)

  // For fetchApplicablePerils, we will want to switch to use the applicable markets endpoint in future
  // applicable markets endpoint stores the list of applicableMarkets in db, so we won't need to send it from FE every time
  const reloadApplicablePerils = () => {
    if (currentOutwardScenario) {
      fetchApplicablePerils({ applicableMarkets: allMarketData }).then((response) =>
        setApplicablePerils(response?.allMatchingPerils || []),
      )
    }
  }

  useEffect(reloadApplicablePerils, [allMarketData, currentOutwardScenario])

  const reloadApplicableMarkets = () => {
    if (currentOutwardScenario) {
      fetchApplicableMarkets(currentOutwardScenario.id).then((response) =>
        setApplicableMarkets(response?.applicableMarkets),
      )
    }
  }

  useEffect(reloadApplicableMarkets, [currentOutwardScenario])

  return (
    <OutwardsApplicablePerilsContext.Provider
      value={{
        allApplicablePerils: currentApplicablePerils,
        applicableMarkets: allMarketData,
        reloadApplicablePerils,
        reloadApplicableMarkets,
      }}
      children={props.children}
    />
  )
}
