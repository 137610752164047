import React, { ReactElement } from 'react'
import './TopContainer.scss'

export type TopContainerProps = React.PropsWithChildren<{
  topMenu: ReactElement
  leftColumm: ReactElement
  rightColumn: ReactElement
}>

export function TopContainer(props: TopContainerProps) {
  return (
    <div className="TopContainer">
      {props.children}
      <div className="TopContainerTopMenu">{props.topMenu}</div>
      <div className="TopContainerLeftColumn">{props.leftColumm}</div>
      <div className="TopContainerRightColumn">{props.rightColumn}</div>
    </div>
  )
}

export default TopContainer
