import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { fetchScenario, patchScenario, PatchScenarioRequest, Scenario } from '../backend/scenarios'
import { validate as uuidValidate } from 'uuid'
import { useLocation } from 'react-router-dom'

export interface ScenarioContainer {
  currentScenario: Scenario | undefined
  updateScenario: (request: PatchScenarioRequest) => Promise<void>
}

export const ScenarioContext = createContext<ScenarioContainer>({
  currentScenario: undefined,
  updateScenario: () => Promise.resolve(),
})

export function FixedScenarioProvider(props: PropsWithChildren<{ scenarioId: string }>) {
  const [currentScenario, setScenario] = useState<Scenario>()

  const reloadScenario: () => Promise<void> = useCallback(async () => {
    if (props.scenarioId) {
      try {
        const scenario = await fetchScenario(props.scenarioId)
        setScenario(scenario)
      } catch (err) {
        console.error(err)
      }
    }
  }, [props.scenarioId])

  const updateScenario: (patchRequest: PatchScenarioRequest) => Promise<void> = useCallback(
    async (patchRequest) => {
      if (!currentScenario) {
        return
      }

      await patchScenario(currentScenario.id, patchRequest)
      await reloadScenario()
    },
    [currentScenario, reloadScenario],
  )

  useEffect(() => {
    reloadScenario()
  }, [reloadScenario])

  return (
    <ScenarioContext.Provider
      value={{
        currentScenario,
        updateScenario,
      }}
    >
      {props.children}
    </ScenarioContext.Provider>
  )
}

export function ScenarioProvider(props: PropsWithChildren) {
  const location = useLocation()

  if (!location.pathname.includes('/scenarios/')) {
    return <>{props.children}</>
  }

  const scenarioIdToUse = location.pathname.split('/').filter((item) => uuidValidate(item))[0]

  return <FixedScenarioProvider scenarioId={scenarioIdToUse}>{props.children}</FixedScenarioProvider>
}
