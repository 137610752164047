import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import Divider from '../divider/LineDivider'
import { Card } from '../card/Card'
import {
  onInputUpdateState,
  onNumberInputUpdateState,
  onTextAreaUpdateState,
  updateValueIncludingSuffixShorthand,
} from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import Button from '../button/Button'
import { PseudoPortfolioContext } from '../../providers/NewPseudoPortfolioItemsProvider'
import { fetchNewPortfolioItem, isIrrelevantNewItem, isRelevantNewItem } from '../../backend/new-portfolio-items'
import { fetchNewCustomPortfolioItem, NewCustomPortfolioItem } from '../../backend/new-custom-portfolio-items'
import './CreateNewPortfolioItemCard.scss'
import { displayLargeDollarValue, numericValueIsDefined, PossiblyNegativeNumber } from '../../utils/numbers'
import TextArea from '../text-area/TextArea'
import { ErrorText } from '../text-area/ErrorText'
import { NewAdjustment } from '../../backend/api-types/NewAdjustment.types'
import { PREVIEW_ID } from '../../backend/adjustments'
import { validateDescription } from '../create-scenario-card/CreateScenarioCard'
import TextField from '../text-field/WFTextField'
import { Flex, Heading, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { NewBusinessTypeWithCustomPortfolio } from '../../pages/new/New'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../../pages/exposures/StackedExposures'
import {
  calculateNewGrarcInputError,
  calculateNewGwpInputError,
  calculateNewGwpInputWarning,
} from './validationMessages'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { useOnClickCreateOrUpdateItem } from './hooks/useOnClickCreateOrUpdateItem'
import { NewCustomPortfolioSplits } from '../new-custom-portfolio-splits/NewCustomPortfolioSplits'
import { useIsCustomPortfolio } from './hooks/useIsCustomPortfolio'
import { FilterContext, transformIntoFlatStructure } from '../../providers/FilterProvider'
import { NewCustomPortfolioAdjustmentContext } from '../../providers/NewCustomPortfolioAdjustmentsProvider'

type DataProps = {
  currentGwpInput: PossiblyNegativeNumber
  newPortfolioType: NewBusinessTypeWithCustomPortfolio
  updateCurrentGwpInput: ((newGwp: PossiblyNegativeNumber) => void) | undefined
  updateNewPortfolioType: ((newType: NewBusinessTypeWithCustomPortfolio) => void) | undefined
}

type IsPreview = {
  isPreview: boolean
}

type RequestCloseModalProps = {
  requestCloseModal: () => void
}

export type CreateNewPortfolioItemCardProps = {
  scenarioId: string
  itemBeingEditedId: string
} & DataProps &
  RequestCloseModalProps

type MiddleOptionsProps = {
  shouldValidate: boolean
  currentGrarcInput: PossiblyNegativeNumber
  setGrarcInput: Dispatch<SetStateAction<PossiblyNegativeNumber>>
} & DataProps &
  IsPreview

type BottomOptionsProps = {
  shouldValidate: boolean
  currentName: string
  setName: Dispatch<SetStateAction<string>>
  currentDescription: string
  setDescription: Dispatch<SetStateAction<string>>
} & Pick<DataProps, 'currentGwpInput' | 'newPortfolioType'> &
  IsPreview

type ButtonsProps = {
  onClickCreateOrUpdateItem: () => Promise<void>
} & IsPreview &
  RequestCloseModalProps

const isNewAdjustment = (adjustment: NewAdjustment | NewCustomPortfolioItem): adjustment is NewAdjustment =>
  (adjustment as NewAdjustment).orderNumber !== undefined

export const CreateNewPortfolioItemCard = (props: CreateNewPortfolioItemCardProps) => {
  const [currentName, setName] = useState<string>('')
  const [currentGrarcInput, setGrarcInput] = useState<PossiblyNegativeNumber>('' as any)
  const [currentDescription, setDescription] = useState<string>('')
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [errorUniqueName, setErrorUniqueName] = useState<string>('')
  const { newDataInputs, changeNewDataInputs, errors } = useContext(PseudoPortfolioContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  const isPreview = useMemo(() => props.itemBeingEditedId === PREVIEW_ID, [props.itemBeingEditedId])

  const onClickCreateOrUpdateItem: () => Promise<void> = useOnClickCreateOrUpdateItem({
    currentGwpInput: props.currentGwpInput,
    newPortfolioType: props.newPortfolioType,
    currentGrarcInput,
    currentName,
    currentDescription,
    isPreview,
    itemBeingEditedId: props.itemBeingEditedId,
    scenarioId: props.scenarioId,
    setShouldValidate: (val) => setShouldValidate(val),
    setErrorUniqueName: (val) => setErrorUniqueName(val),
    requestCloseModal: props.requestCloseModal,
    setName: (name) => setName(name),
    updateCurrentGwpInput: props.updateCurrentGwpInput,
    setGrarcInput: (val) => setGrarcInput(val),
    setDescription: (val) => setDescription(val),
  })

  const actionTitle = isNcpPreview ? 'View' : isPreview ? 'Create' : 'Edit'

  const updateBasedOnNewScenario = async () => {
    if (isPreview) {
      return
    }

    const savedVersionOfAdjustment =
      props.newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO
        ? await fetchNewCustomPortfolioItem(props.scenarioId, props.itemBeingEditedId)
        : await fetchNewPortfolioItem(props.scenarioId, props.itemBeingEditedId)

    if (!savedVersionOfAdjustment) {
      return
    }

    setShouldValidate(false)
    setName(savedVersionOfAdjustment.name)
    setDescription(savedVersionOfAdjustment.description)

    const gwpInput = isNewAdjustment(savedVersionOfAdjustment)
      ? savedVersionOfAdjustment.gwpValue
      : savedVersionOfAdjustment.totalGwp

    const grarcInput = isNewAdjustment(savedVersionOfAdjustment)
      ? Number.parseFloat(savedVersionOfAdjustment.grossRiskAdjustedRateChange)
      : Number.parseFloat(savedVersionOfAdjustment.totalGrarcPercent.toString())

    props.updateCurrentGwpInput?.(gwpInput)

    setGrarcInput(grarcInput)
  }

  useEffect(() => {
    updateBasedOnNewScenario()
  }, [props.scenarioId, props.itemBeingEditedId, isPreview])

  useEffect(() => {
    if (!isPreview && props.updateNewPortfolioType) {
      props.updateNewPortfolioType(props.newPortfolioType)
    }
  }, [isPreview])

  const updateNewAdjustmentPreviewInput = () => {
    const existingOne = (newDataInputs ? newDataInputs : []).find(isRelevantNewItem(props.itemBeingEditedId)) // TODO reflect in naming that newDataInputs has to do with "default" new portfolio

    const previewInput: NewAdjustment = {
      scenarioId: props.scenarioId,
      gwpValue: numericValueIsDefined(props.currentGwpInput) ? (props.currentGwpInput as number) : 0,
      grossRiskAdjustedRateChange: numericValueIsDefined(currentGrarcInput) ? (currentGrarcInput as string) : '0',
      name: currentName,
      appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
      description: currentDescription,
      id: props.itemBeingEditedId,
      orderNumber: existingOne ? existingOne.orderNumber : Number.MAX_SAFE_INTEGER,
      isEnabled: true,
    }

    const existingIgnoringCurrent = (newDataInputs || []).filter(isIrrelevantNewItem(props.itemBeingEditedId))

    if (props.newPortfolioType !== NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO) {
      changeNewDataInputs([...existingIgnoringCurrent, previewInput]) // TODO reflect in naming this has to do with "default" new portfolios
    }
  }

  useEffect(updateNewAdjustmentPreviewInput, [props.currentGwpInput, dropdownFilterOptions])

  return (
    <div className="CreateNewPortfolioItemCard">
      <Card>
        <h4 className="CreateNewPortfolioItemCardTitle">{actionTitle} Adjustment</h4>
        <MiddleOptions
          {...props}
          shouldValidate={shouldValidate}
          currentGrarcInput={currentGrarcInput}
          setGrarcInput={setGrarcInput}
          isPreview={isPreview}
        />
        <BottomOptions
          {...props}
          shouldValidate={shouldValidate}
          currentName={currentName}
          setName={setName}
          currentDescription={currentDescription}
          setDescription={setDescription}
          isPreview={isPreview}
        />
        {errors.map((error) => (
          <ErrorText error={error.toString()} />
        ))}
        <Buttons
          isPreview={isPreview}
          requestCloseModal={props.requestCloseModal}
          onClickCreateOrUpdateItem={onClickCreateOrUpdateItem}
        />
        {shouldValidate && errorUniqueName.length > 0 && <ErrorText error={errorUniqueName} />}
      </Card>
    </div>
  )
}

const MiddleOptions = (props: MiddleOptionsProps) => {
  const { way1401NewCustomPortfolio } = useFlags<Flag>()
  const ncpState = useContext(NcpContext)
  const isCustomPortfolio = useIsCustomPortfolio(props.newPortfolioType)
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  const onGwpValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateValueIncludingSuffixShorthand(event.target.value, props.updateCurrentGwpInput)
  }

  return (
    <div className="MiddleOptions">
      <TextField
        title="GWP"
        required
        id="New-Portfolio-Item-Card-TextField-GWP"
        className={isCustomPortfolio && isNcpPreview ? 'PercentileDisabled' : 'GWP'}
        placeholder="0"
        value={displayLargeDollarValue(props.currentGwpInput)}
        onChange={onGwpValueChange}
        warning={calculateNewGwpInputWarning(props.currentGwpInput)}
        error={props.shouldValidate ? calculateNewGwpInputError(props.currentGwpInput) : ''}
        disabled={isNcpPreview}
      />
      <TextField
        title="GRARC"
        required={props.newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL}
        id="New-Portfolio-Item-Card-TextField-GRARC"
        className={isCustomPortfolio ? 'PercentileDisabled' : 'Percentile'}
        type="number"
        value={isCustomPortfolio ? ncpState!.ncpData.totalGrarcPercent : props.currentGrarcInput}
        disabled={isCustomPortfolio}
        onChange={onNumberInputUpdateState(props.setGrarcInput)}
        error={
          props.newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL && props.shouldValidate
            ? calculateNewGrarcInputError(props.currentGrarcInput)
            : ''
        }
        percentage
      />
      {way1401NewCustomPortfolio && props.isPreview && (
        <Flex
          flexDirection={'column'}
          pr={'3rem'}
          pt={'1rem'}
        >
          <Heading
            as={'h6'}
            size={'xs'}
            fontWeight={500}
          >
            New portfolio composition
          </Heading>
          <RadioGroup
            title="New portfolio composition"
            onChange={(value) => {
              props.updateNewPortfolioType?.(value as NewBusinessTypeWithCustomPortfolio)
            }}
            value={props.newPortfolioType}
            id="New-Portfolio-Item-Card-RadioGroup-Portfolio-Composition"
            className="RadioGroupComposition"
          >
            <Stack
              direction="column"
              pt={'0.5rem'}
            >
              <Radio
                id="New-Portfolio-Item-Card-Radio-Portfolio"
                value={NewBusinessTypeWithCustomPortfolio.VIRTUAL}
                className="RadioButton"
                size={'sm'}
                defaultChecked
              >
                Use existing renewable portfolio
              </Radio>
              <Radio
                id="New-Portfolio-Item-Card-Radio-Custom-Portfolio"
                size={'sm'}
                value={NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO}
              >
                View and customise portfolio
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      )}
    </div>
  )
}

const BottomOptions = (props: BottomOptionsProps) => {
  const isCustomPortfolio = useIsCustomPortfolio(props.newPortfolioType)
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  return (
    <div className="BottomOptions">
      {isCustomPortfolio && !props.isPreview && (
        <>
          <NewCustomPortfolioSplits currentGwpInput={props.currentGwpInput} />
          <Divider />
        </>
      )}
      <TextField
        title="Name"
        required
        id="New-Portfolio-Item-Card-TextField-Name"
        placeholder="Name"
        value={props.currentName}
        onChange={onInputUpdateState(props.setName)}
        error={
          props.shouldValidate ? (isValidName(props.currentName) ? '' : 'Please enter a name for the adjustment') : ''
        }
        className={isCustomPortfolio && isNcpPreview ? 'PercentileDisabled' : 'Name'}
        disabled={isNcpPreview}
      />
      <TextArea
        title="Description"
        id="New-Portfolio-Item-Card-TextField-Description"
        className={isCustomPortfolio && isNcpPreview ? 'PercentileDisabled' : 'Description'}
        placeholder="Description"
        value={props.currentDescription}
        onChange={onTextAreaUpdateState(props.setDescription)}
        error={props.shouldValidate ? validateDescription(props.currentDescription) : ''}
        disabled={isNcpPreview}
      />
    </div>
  )
}

const Buttons = (props: ButtonsProps) => {
  const addOrSaveWord = props.isPreview ? 'Add' : 'Save'
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  return (
    <div className="ButtonContainer">
      {!props.isPreview && (
        <Button
          title="Cancel"
          id="New-Portfolio-Item-Card-Button-Cancel"
          secondary
          onClick={props.requestCloseModal}
        />
      )}
      {!isNcpPreview && (
        <Button
          title={addOrSaveWord}
          id="New-Portfolio-Item-Card-Button-Add-Or-Save"
          onClick={props.onClickCreateOrUpdateItem}
        />
      )}
    </div>
  )
}
