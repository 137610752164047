import React, { ReactElement } from 'react'
import { ResultbyDistributionbyLossType } from '../../backend/parameterisation'
import { groupListOfObjsBy } from '../../utils/lists'
import { CssGridItem } from '../../components/css-grid/CssGridRows'
import Big from 'big.js'
import { ONE_HUNDRED, suffixForValue } from '../../utils/numbers'
import KpiCard from '../../components/kpi-card/KpiCard'
import { NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW } from '../../components/parameterisation-table/parameterisation-table'

export function groupParameterisationByRow(
  inputData: Array<ResultbyDistributionbyLossType>,
): Record<string, Array<ResultbyDistributionbyLossType>> {
  return groupListOfObjsBy(inputData, 'stat')
}

export interface ParameterisationRowGeneratlor {
  title: string
  additionalCssClassNames?: string
  customCssClassName?: string
  perParameterisationRowGenerator: (context: {
    currentRowStat: string
    allFieldsForRow: Array<ResultbyDistributionbyLossType>
  }) => ReactElement | string | number
}

export function getSetofColumns(input: Array<ResultbyDistributionbyLossType>): Array<string> {
  return Array.from(new Set(input.map((item) => item.distribution))).sort()
}

export function handleNanAsUndefined(input: string | number): undefined | Big {
  if (input === 'NaN') {
    return undefined
  }

  try {
    return new Big(input)
  } catch (e) {
    return undefined
  }
}

export function fetchFormatter(lossType: string | undefined) {
  if (lossType === 'Loss Ratio') {
    return (item: string | number) => (
      <KpiCard
        value={handleNanAsUndefined(item)?.mul(ONE_HUNDRED)}
        title={''}
        suffix={'%'}
        decimals={2}
      />
    )
  }

  if (lossType === 'Frequency') {
    return (item: string | number) => (
      <KpiCard
        value={handleNanAsUndefined(item)}
        title={''}
        decimals={2}
      />
    )
  }

  return (item: string | number) => (
    <KpiCard
      value={handleNanAsUndefined(item)}
      title={''}
      prefix={'$'}
      suffix={suffixForValue(item)}
      decimals={2}
    />
  )
}

export function givenDataCreateSetOfColumns(
  allUniqueColumns: Array<string>,
): (rowId: string, rowIndex: number, listOfDataValues: Array<ResultbyDistributionbyLossType>) => Array<JSX.Element> {
  return (rowIdentifier, rowNumber, allFieldsWithinRow) => {
    const allDataValues = allUniqueColumns.map((currentColumnName) => {
      const matchingDataFromRow = allFieldsWithinRow.find((item) => item.distribution === currentColumnName)

      if (!matchingDataFromRow) {
        return <p />
      }

      const formatter = fetchFormatter(matchingDataFromRow.lossType)

      return (
        <CssGridItem
          key={`${matchingDataFromRow.stat}-${matchingDataFromRow.lossType}-${matchingDataFromRow.distribution}-value`}
          columnNumber={
            allUniqueColumns.indexOf(currentColumnName) + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW
          }
          rowNumber={rowNumber + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
        >
          {formatter(matchingDataFromRow.value)}
        </CssGridItem>
      )
    })

    return [
      <CssGridItem
        key={`row-${rowIdentifier}-first-column`}
        columnNumber={1}
        rowNumber={rowNumber + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
      >
        {rowIdentifier}
      </CssGridItem>,
      ...allDataValues,
    ]
  }
}
