import { RawEntry } from '../backend/rawEntry'

export function getTop10TargetMarketValues(fullDataForScenario: RawEntry[], columnName: string): string[] {
  if (fullDataForScenario.length === 0) return []
  const countMap = new Map<string, number>()

  fullDataForScenario
    .filter((portfolioRow) => portfolioRow[columnName] !== undefined && portfolioRow[columnName] !== '')
    .map((portfolioRow) => portfolioRow[columnName])
    .forEach(
      (targetMarketValue) =>
        targetMarketValue !== undefined && countMap.set(targetMarketValue, (countMap.get(targetMarketValue) || 0) + 1),
    )

  const sortedEntries = Array.from(countMap.entries()).sort((a, b) => b[1] - a[1])
  const top10 = sortedEntries.slice(0, 10).map((entry) => entry[0])

  top10.length === 0 && console.warn(`No ${columnName} options found in renewal data`)

  return top10
}
