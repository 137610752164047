import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  calculateGrarcKpi,
  calculateKPIs,
  calculateWeightedAverageKpis,
  filterData,
  ScenarioKeyPerformanceIndicators,
} from '../backend/calculate-kpis'
import { FilterContext, transformIntoFlatStructure } from './FilterProvider'
import { FullScenarioDataContext, splitIntoRenewalAndNew } from './FullScenarioData/FullScenarioDataProvider'
import { StackedScenario } from '../backend/stacked-scenarios'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { StackedScenarioComparisonContext } from './StackedScenarioComparisonProvider'
import { FilterValues } from '../backend/calculate-possible-filter-values'
import { StackedScenarioContext } from './StackedScenarioProvider'
import { fetchFullStackedScenarioData } from '../backend/scenarios-data'
import { fetchUnderlyingData } from '../backend/underlyingData'
import { AggregatedMarketDataContextV2, getAFieldForEveryLeafNode } from './AggregatedMarketDataProviderV2'

type NewClientRenewal = {
  renewal: any[]
  new: any[]
  newClient: any[]
  newCustomPortfolio: any[]
}

export const StackedScenarioDataProvider = (props: PropsWithChildren<{ stackedScenario?: StackedScenario }>) => {
  const { currentStackedScenario } = useContext(StackedScenarioComparisonContext)
  const mainStackedScenarioContext = useContext(StackedScenarioContext)
  const stackedScenarioToUse =
    currentStackedScenario || props.stackedScenario || mainStackedScenarioContext.currentStackedScenario

  const { dropdownFilterOptions } = useContext(FilterContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { aggregatedMarketData } = useContext(AggregatedMarketDataContextV2)

  const [fullDataWithActuals, setDataSetsForEachSubScenario] = useState<any[]>([])

  const reloadAllStackedScenarioDataSets = useCallback(async () => {
    if (!stackedScenarioToUse) {
      return
    }
    updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.LOADING)

    try {
      const downloadLinkResponse = await fetchFullStackedScenarioData(stackedScenarioToUse.id)
      const allData = await fetchUnderlyingData(downloadLinkResponse.downloadLink)
      const dataSets = allData.flat()
      setDataSetsForEachSubScenario(dataSets)
      updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.FINISHED)
    } catch (error) {
      updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.ERROR)
    }
  }, [stackedScenarioToUse, updateIndividualProgressItem])

  useEffect(() => {
    reloadAllStackedScenarioDataSets()
  }, [reloadAllStackedScenarioDataSets])

  const [filtersToApply, setFiltersToApply] = useState<FilterValues>({})
  useEffect(() => setFiltersToApply(transformIntoFlatStructure(dropdownFilterOptions)), [dropdownFilterOptions])
  const [fullRenewalAndNew, setFullRenewalAndNew] = useState<NewClientRenewal>({
    renewal: [],
    new: [],
    newClient: [],
    newCustomPortfolio: [],
  })
  useEffect(() => setFullRenewalAndNew(splitIntoRenewalAndNew(fullDataWithActuals)), [fullDataWithActuals])
  const [fullRenewalKpis, setFullRenewalKpis] = useState<ScenarioKeyPerformanceIndicators | undefined>(
    calculateKPIs([]),
  )
  useEffect(() => setFullRenewalKpis(calculateKPIs(fullRenewalAndNew.renewal)), [fullRenewalAndNew])
  const [fullNewKpis, setFullNewKpis] = useState<ScenarioKeyPerformanceIndicators | undefined>(calculateKPIs([]))
  useEffect(
    () =>
      setFullNewKpis(
        calculateKPIs([
          ...fullRenewalAndNew.new,
          ...fullRenewalAndNew.newClient,
          ...fullRenewalAndNew.newCustomPortfolio,
        ]),
      ),
    [fullRenewalAndNew.new, fullRenewalAndNew.newClient, fullRenewalAndNew.newCustomPortfolio],
  )

  const [filteredDataForScenario, setFilteredDataForScenario] = useState<any[]>([])
  useEffect(
    () => setFilteredDataForScenario(filterData(fullDataWithActuals, filtersToApply)),
    [fullDataWithActuals, filtersToApply],
  )
  const [filteredRenewalAndNew, setFilteredRenewalAndNew] = useState<NewClientRenewal>({
    renewal: [],
    new: [],
    newClient: [],
    newCustomPortfolio: [],
  })
  useEffect(() => setFilteredRenewalAndNew(splitIntoRenewalAndNew(filteredDataForScenario)), [filteredDataForScenario])
  const [filteredRenewalKpis, setFilteredRenewalKpis] = useState<ScenarioKeyPerformanceIndicators>(calculateKPIs([]))
  useEffect(() => setFilteredRenewalKpis(calculateKPIs(filteredRenewalAndNew.renewal)), [filteredRenewalAndNew])
  const [filteredNewKpis, setFilteredNewKpis] = useState<ScenarioKeyPerformanceIndicators>(calculateKPIs([]))
  useEffect(
    () =>
      setFilteredNewKpis(
        calculateKPIs([
          ...filteredRenewalAndNew.new,
          ...filteredRenewalAndNew.newClient,
          ...filteredRenewalAndNew.newCustomPortfolio,
        ]),
      ),
    [filteredRenewalAndNew.new, filteredRenewalAndNew.newClient, filteredRenewalAndNew.newCustomPortfolio],
  )

  const fallbackAcqValue = useMemo(() => {
    if (stackedScenarioToUse && aggregatedMarketData) {
      return getAFieldForEveryLeafNode(
        aggregatedMarketData,
        stackedScenarioToUse.scenarioLevel,
        'convexAcquisitionRatio',
      )
    }
    return {}
  }, [stackedScenarioToUse, aggregatedMarketData])

  const fullWeightedAverageKpis = useMemo(
    () => calculateWeightedAverageKpis(fullDataWithActuals, fallbackAcqValue),
    [fullDataWithActuals, fallbackAcqValue],
  )

  const weightedAverageRenewalKpis = useMemo(
    () => calculateWeightedAverageKpis(fullRenewalAndNew.renewal, fallbackAcqValue),
    [fullRenewalAndNew, fallbackAcqValue],
  )

  const grarcKpi = useMemo(() => calculateGrarcKpi(fullDataWithActuals), [fullDataWithActuals])

  const newGrarcKpi = useMemo(
    () =>
      calculateGrarcKpi([
        ...fullRenewalAndNew.new,
        ...fullRenewalAndNew.newClient,
        ...filteredRenewalAndNew.newCustomPortfolio,
      ]),
    [fullRenewalAndNew.new, fullRenewalAndNew.newClient, fullRenewalAndNew.newCustomPortfolio],
  )

  const renewalGrarcKpi = useMemo(() => calculateGrarcKpi([...fullRenewalAndNew.renewal]), [fullRenewalAndNew.renewal])

  return (
    <FullScenarioDataContext.Provider
      children={props.children}
      value={{
        fullDataForScenario: fullDataWithActuals,
        fullRenewalKpis,
        fullNewKpis,
        fullWeightedAverageKpis,
        grarcKpi,
        newGrarcKpi,
        renewalGrarcKpi,
        weightedAverageRenewalKpis,
        filteredDataForScenario,
        filteredRenewalKpis,
        filteredNewKpis,
        filteredNewDataForScenario: filteredRenewalAndNew.new,
        filteredRenewalDataForScenario: filteredRenewalAndNew.renewal,
        filteredNewClientDataForScenario: filteredRenewalAndNew.newClient,
        filteredNewCustomPortfolioDataForScenario: filteredRenewalAndNew.newCustomPortfolio,
        filteredDataToUseInGraph: filteredDataForScenario,
        reloadData: reloadAllStackedScenarioDataSets,
        setPreviewNewClient: () => undefined,
        setPreviewRenewal: () => undefined,
        setPreviewNewVirtual: () => undefined,
      }}
    />
  )
}
