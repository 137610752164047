import { useContext, useMemo } from 'react'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import './Renewal.scss'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import SectionHeader from '../../components/section-header/SectionHeader'
import { Adjustments } from '../../components/adjustments/Adjustments'
import {
  RenewalAdjustmentContext,
  RenewalAdjustmentsContextContainer,
} from '../../providers/RenewalAdjustmentsProvider'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { PREVIEW_ID } from '../../backend/adjustments'
import { CreateAdjustmentCard } from '../../components/create-adjustment-card/CreateAdjustmentCard'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import SharedGraphs from './SharedGraphs'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { ClientSpecificContent } from '../client/Client'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { RenewalTypes } from './RenewalTypes.enum'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import { GraphStyleProvider } from '../../components/renewal-graph/GraphStyleProvider'
import { NewCustomPortfolioAdjustmentProvider } from '../../providers/NewCustomPortfolioAdjustmentsProvider'

const RenewalContent = () => {
  const { currentScenario } = useContext(ScenarioContext)
  const { businessQuery } = useContext(AppQueryContext)
  const { currentlyEditingAdjustmentId } = useContext<RenewalAdjustmentsContextContainer>(RenewalAdjustmentContext)

  useResetFiltersOnFirstRender()

  const showCreateAdjustmentCard = useMemo(
    () =>
      !!currentScenario?.canWriteScenario &&
      currentScenario.status[0].status === ScenarioStatus.IN_PROGRESS &&
      (!currentlyEditingAdjustmentId || currentlyEditingAdjustmentId === PREVIEW_ID),
    [currentScenario],
  )

  const [businessFromQuery, setBusiness] = businessQuery

  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  if (businessFromQuery === RenewalTypes.CLIENT) {
    return <ClientSpecificContent />
  }

  return (
    <div
      className="Renewal"
      id="Renewal"
    >
      <SplitContentContainerWithSideMenu
        leftColumm={
          <div>
            <FiltersContainer>
              <SectionHeader title="Adjustment Control" />
              <ScenarioFilterCard
                availableBusinessTypes={Object.values(RenewalTypes)}
                currentBusinessType={businessFromQuery}
                setBusinessType={setBusiness}
              />
              <div className="CreateAdjustment">
                {showCreateAdjustmentCard && (
                  <CreateAdjustmentCard
                    key={'create-preview'}
                    adjustmentBeingEditedId={PREVIEW_ID}
                    adjustmentType={'NORMAL'}
                  />
                )}
              </div>
            </FiltersContainer>
          </div>
        }
        rightColumn={!graphPanelIsOpen ? <Adjustments adjustmentType={'NORMAL'} /> : <SharedGraphs />}
        sideMenu={
          <KpiBar>
            <FullKpiDisplay />
          </KpiBar>
        }
      />
    </div>
  )
}

const RenewalWithFilters = () => (
  <MarketV2Provider>
    <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
      <GraphStyleProvider>
        <NewCustomPortfolioAdjustmentProvider>
          <RenewalContent />
        </NewCustomPortfolioAdjustmentProvider>
      </GraphStyleProvider>
    </RenewalGraphFilterDataProvider>
  </MarketV2Provider>
)

export { RenewalWithFilters as Renewal }
