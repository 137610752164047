import { Heading, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

type NoReportsMessageProps = {
  createNew: () => void
  canCreateNew: boolean
}

export function NoReportsMessage(props: NoReportsMessageProps): ReactElement {
  const { createNew, canCreateNew } = props

  return (
    <Heading
      pb={'1rem'}
      size={'sm'}
    >
      There are no reports created, please{' '}
      <Text
        onClick={canCreateNew ? createNew : () => {}}
        color={canCreateNew ? 'blue.500' : 'blue.200'}
        size={'sm'}
        display={'inline'}
        cursor={canCreateNew ? 'pointer' : 'not-allowed'}
      >
        Create New Report
      </Text>
    </Heading>
  )
}
