import React from 'react'
import Big from 'big.js'

import './StackedMarketAndConvexOverallContainerV2.scss'
import SectionHeader from '../section-header/SectionHeader'
import { PossiblyNegativeNumber, suffixForValue } from '../../utils/numbers'
import { KpiCardSmall, KpiCardSmallText } from '../kpi-card-small/KpiCardSmall'
import Graphs from '../graphs/Graphs'
import MarketBoxPlot from '../market-graph/MarketBoxPlot'
import { MarketGraphDataV2 } from '../../backend/market-V2'
import { NoteSections } from '../../pages/notes/NotesContent'
import { NoteSectionNames } from '../../pages/notes/NotesDataProvider'

export interface StackedMarketAndConvexOverallContainerV2Props {
  year: string
  totalMarketGwp: number
  pmlScenario1In100: PossiblyNegativeNumber
  confidenceInMarketSize: PossiblyNegativeNumber
  accessOrCapability: PossiblyNegativeNumber
  inAppetite: PossiblyNegativeNumber
  addressableMarketGwp: PossiblyNegativeNumber
  rateEnvironment: string
  gwpCalculatedFromPage: PossiblyNegativeNumber
  coreClientsCalculatedFromPage: PossiblyNegativeNumber
  marketShareCalculatedFromPage: PossiblyNegativeNumber
  convexGwp: PossiblyNegativeNumber
  convexMarketShare: PossiblyNegativeNumber
  peakConvexGwp: PossiblyNegativeNumber
  peakConvexMarketShare: PossiblyNegativeNumber
  convexEstimatedShareOfPml: PossiblyNegativeNumber
  coreClients: PossiblyNegativeNumber
  previousYearGnlr: PossiblyNegativeNumber
  previousYearGglr: PossiblyNegativeNumber
  convexAcquisitionRatio: PossiblyNegativeNumber
  marketGrarc: PossiblyNegativeNumber
  marketNrarc: PossiblyNegativeNumber
  inflation: PossiblyNegativeNumber
  AbeGglr: PossiblyNegativeNumber
  uwConvexAcquisitionRatio: PossiblyNegativeNumber
  uwMarketGrarc: PossiblyNegativeNumber
  uwInflation: PossiblyNegativeNumber
  uwAbeGglr: PossiblyNegativeNumber
  graphData: Array<MarketGraphDataV2>
}

export function StackedMarketAndConvexOverallContainerV2(props: StackedMarketAndConvexOverallContainerV2Props) {
  return (
    <div className="OverallMarketContainer OverallStackedMarketContainer">
      <div className="MarketHalfPageContainer">
        <div className="SectionContainer">
          <div className="SideBySideSectionContainer">
            <div className="SectionContainer5Of7">
              <SectionHeader title="Total Market" />
              <div className="CardsContainer">
                <KpiCardSmall
                  value={new Big(Number(props.totalMarketGwp) || 0)}
                  title="Total Market GWP ($bn)"
                  prefix="$"
                  suffix={suffixForValue(Number(props.totalMarketGwp) || 0)}
                  className="GwpCard"
                />
                <KpiCardSmall
                  value={new Big(props.pmlScenario1In100 || 0)}
                  title="PML Scenario (1 in 100)"
                  prefix="$"
                  className="GwpCard"
                />
                <KpiCardSmall
                  value={new Big(props.confidenceInMarketSize || 0)}
                  title="Confidence In Market Size"
                  suffix="%"
                  className="GwpCard"
                  decimals={1}
                />
                <KpiCardSmall
                  value={new Big(props.accessOrCapability || 0)}
                  title="Access / Capability"
                  suffix="%"
                  className="GwpCard"
                  decimals={1}
                />
                <KpiCardSmall
                  value={new Big(props.inAppetite || 0)}
                  title="In Appetite"
                  suffix="%"
                  className="GwpCard"
                  decimals={1}
                />
              </div>
            </div>
            <div className="SectionContainer2Of7">
              <SectionHeader title="Accessible Market" />
              <div className="CardsContainer">
                <KpiCardSmall
                  value={new Big(props.addressableMarketGwp || 0)}
                  title="Addressable Market GWP ($m)"
                  prefix="$"
                  className="GwpCard"
                />
                <KpiCardSmallText
                  value={props.rateEnvironment}
                  title="Rate Environment"
                />
              </div>
            </div>
          </div>
        </div>
        <NoteSections onyIncludeSections={[NoteSectionNames.MARKET_COMMENTARY]} />
        <Graphs>
          <MarketBoxPlot
            graphData={props.graphData}
            otherLossRatios={
              [
                // TODO - find out what to loss ratio data to display
                // {
                //   title: 'TBC',
                //   value: 999 as number,
                //   x: 'TBC',
                // },
              ]
            }
          />
        </Graphs>
      </div>
      <div className="MarketHalfPageContainer">
        <div className="SectionContainer">
          <SectionHeader title="Convex" />
          <div className="CardsContainer">
            {/* Manually calculated from the table so they data is consistent with the table */}
            <KpiCardSmall
              value={new Big(props.gwpCalculatedFromPage || 0)}
              title={'Convex ' + parseInt(props.year) + ' GWP ($m)'}
              prefix="$"
              suffix={suffixForValue(props.gwpCalculatedFromPage || 0)}
              className="GwpCard"
            />
            {/* Calculated manually to stay consistent with other data on page*/}
            <KpiCardSmall
              value={new Big(props.marketShareCalculatedFromPage || 0)}
              title={'Convex ' + parseInt(props.year) + ' Market Share'}
              className="GwpCard"
              suffix="%"
              decimals={1}
            />
            <KpiCardSmall
              value={new Big(props.peakConvexGwp || 0)}
              title="Peak Convex GWP ($m)"
              prefix="$"
              suffix={suffixForValue(props.peakConvexGwp || 0)}
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.peakConvexMarketShare || 0)}
              title="Peak Convex Market Share"
              className="GwpCard"
              suffix="%"
              decimals={1}
            />
            <KpiCardSmall
              value={new Big(props.convexEstimatedShareOfPml || 0)}
              title="Convex est share of PML downside ($m)"
              className="GwpCard"
              decimals={1}
            />

            {/* Calculated manually to stay consistent with other data on page*/}
            <KpiCardSmall
              value={new Big(props.coreClientsCalculatedFromPage || 0)}
              title={'Core Clients'}
              className="GwpCard"
              suffix="%"
              decimals={1}
            />
            {/* <KpiCardSmall
              value={new Big(props.coreClients || 0)}
              title={"Core Clients"}
              className="GwpCard"
              suffix="%"
              decimals={1}
            /> */}
          </div>
        </div>
        <NoteSections onyIncludeSections={[NoteSectionNames.PEAK_CONVEX_VISION]} />
        <div className="SectionContainer">
          <SectionHeader title="Actuarial Benchmark" />
          <div className="CardsContainer">
            <KpiCardSmall
              value={new Big(props.previousYearGnlr || 0)}
              title={parseInt(props.year) - 1 + ' GNLR'}
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.previousYearGglr || 0)}
              title={parseInt(props.year) - 1 + ' GGLR'}
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.convexAcquisitionRatio || 0)}
              title="Convex Acq Ratio"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.marketGrarc || 0)}
              title="Convex GRARC"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.marketNrarc || 0)}
              title="Market NRARC"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.inflation || 0)}
              title="Inflation"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.AbeGglr || 0)}
              title="ABE GGLR"
              suffix="%"
              className="GwpCard"
            />
          </div>
        </div>
        <NoteSections onyIncludeSections={[NoteSectionNames.UW_COMMENTARY_ON_ABE]} />
        <div className="SectionContainer">
          <SectionHeader title="Underwriter View" />
          <div className="CardsContainer">
            <KpiCardSmall
              value={new Big(props.uwConvexAcquisitionRatio || 0)}
              title="Convex Acq Ratio"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.uwMarketGrarc || 0)}
              title="Market GRARC"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.uwInflation || 0)}
              title="Inflation"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.uwAbeGglr || 0)}
              title="UW ABE GGLR"
              suffix="%"
              className="GwpCard"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
