import {
  NewCustomPortfolioItem,
  NCPSegments,
  CategoryWeightings,
  MonthType,
  PortfolioSplitTypes,
} from '../../backend/new-custom-portfolio-items'
import { KnownDivision } from '../portfolio-splits-table/portfolioSplitsColumns'
import { defaultPlacementTypeValues } from '../portfolio-splits-table/data/placementType'
import { defaultInceptionMonthValues } from '../portfolio-splits-table/data/inceptionMonth'
import { EntityInsuranceFields, EntityReinsuranceFields } from '../portfolio-splits-table/types'
import { defaultEntityInsuranceValues, defaultEntityReinsuranceValues } from '../portfolio-splits-table/data/entity'
import { calculateTotals } from '../segment-splits-table/segmentsSplitsTableCalculator'

type NewCustomPortfolioStateData = Omit<
  NewCustomPortfolioItem,
  'name' | 'description' | 'isEnabled' | 'categoryWeightings'
> & {
  categoryWeightings: Required<NewCustomPortfolioItem['categoryWeightings']>
}

export type NewCustomPortfolioState = {
  ncpData: NewCustomPortfolioStateData
  reset: boolean
}

export type TotalName = keyof Omit<NewCustomPortfolioStateData, 'segments' | 'categoryWeightings'>

export type UpdateSingleCategoryWeightingPayload = {
  categoryName: keyof CategoryWeightings
  key: keyof MonthType | keyof PortfolioSplitTypes
  data: number
}

type RemoveSingleKeyFromDynamicCategoryWeightingPayload = {
  categoryName: keyof CategoryWeightings
  key: keyof PortfolioSplitTypes
}

export type NewCustomPortfolioAction =
  | { type: 'initialiseSegments'; payload: { segmentNames: string[] } }
  | { type: 'updateSingleKeyForCategoryWeighting'; payload: UpdateSingleCategoryWeightingPayload }
  | { type: 'removeSingleKeyFromDynamicCategoryWeighting'; payload: RemoveSingleKeyFromDynamicCategoryWeightingPayload }
  | { type: 'updateSegmentData'; payload: { segmentSplitsData: NCPSegments[] } }
  | { type: 'updateTotals'; payload: { totalName: TotalName; totalValue: number } }
  | { type: 'reset'; payload: { reset: boolean } }
  | { type: 'resetNcpData' }

let initialNcpData: NewCustomPortfolioStateData = {
  segments: [],
  categoryWeightings: {
    inceptionMonth: defaultInceptionMonthValues,
    placementType: defaultPlacementTypeValues,
    entity: {},
    exposureRegion: {},
    cob3: {},
    interestType: {},
    broker: {},
  },
  totalGwp: 0,
  totalGwpPercent: 0,
  totalGrarcPercent: 0,
  totalAvgGwpPerLayer: 0,
  totalAvgConvexLimit: 0,
  totalAvgAttachmentPoint: 0,
  totalEulrPercent: 0,
  totalWrittenLinePercent: 0,
  totalAcqCostPercent: 0,
}

export function createNewCustomPortfolioInitialState(division: KnownDivision): NewCustomPortfolioState {
  const entity: EntityReinsuranceFields | EntityInsuranceFields | {} =
    division === 'Reinsurance'
      ? defaultEntityReinsuranceValues
      : division === 'Insurance'
      ? defaultEntityInsuranceValues
      : {}

  initialNcpData = {
    ...initialNcpData,
    categoryWeightings: {
      ...initialNcpData.categoryWeightings,
      entity,
    },
  }

  return {
    ncpData: initialNcpData,
    reset: false,
  }
}

const initialSegmentValuesWithoutName: Omit<NCPSegments, 'name'> = {
  gwp: 0,
  gwpPercent: 0,
  avgGwpPerLayer: 0,
  avgConvexLimit: 0,
  avgAttachmentPoint: 0,
  eulrPercent: 0,
  writtenLinePercent: 0,
  acqCostPercent: 0,
  grarcPercent: 0,
}

export function createNewCustomPortfolioReducer() {
  return (state: NewCustomPortfolioState, action: NewCustomPortfolioAction): NewCustomPortfolioState => {
    switch (action.type) {
      case 'initialiseSegments': {
        const segmentSplitsData: NCPSegments[] = action.payload.segmentNames.map((segmentName) => ({
          name: segmentName,
          ...initialSegmentValuesWithoutName,
        }))
        return { ...state, ncpData: { ...state.ncpData, segments: segmentSplitsData } }
      }
      case 'updateSingleKeyForCategoryWeighting': {
        return {
          ...state,
          ncpData: {
            ...state.ncpData,
            categoryWeightings: {
              ...state.ncpData.categoryWeightings,
              [action.payload.categoryName]: {
                ...state.ncpData.categoryWeightings[action.payload.categoryName],
                [action.payload.key]: action.payload.data,
              },
            },
          },
        }
      }
      case 'removeSingleKeyFromDynamicCategoryWeighting': {
        const newCategory: PortfolioSplitTypes = {
          ...state.ncpData.categoryWeightings[action.payload.categoryName],
        }
        delete newCategory[action.payload.key]

        return {
          ...state,
          ncpData: {
            ...state.ncpData,
            categoryWeightings: {
              ...state.ncpData.categoryWeightings,
              [action.payload.categoryName]: newCategory,
            },
          },
        }
      }
      case 'updateSegmentData': {
        return {
          ...state,
          ncpData: {
            ...state.ncpData,
            segments: action.payload.segmentSplitsData,
            totalGwpPercent: calculateTotals(action.payload.segmentSplitsData, 'gwpPercent'),
          },
        }
      }
      case 'updateTotals': {
        return { ...state, ncpData: { ...state.ncpData, [action.payload.totalName]: action.payload.totalValue } }
      }
      case 'reset': {
        return { ...state, reset: action.payload.reset }
      }
      case 'resetNcpData': {
        const resetNCPData: NewCustomPortfolioStateData = {
          ...initialNcpData,
          segments: state.ncpData.segments.map((segment) => ({
            name: segment.name,
            ...initialSegmentValuesWithoutName,
          })),
          categoryWeightings: {
            ...initialNcpData.categoryWeightings,
            interestType: {
              ...Object.fromEntries(
                Object.entries(state.ncpData.categoryWeightings.interestType).map(([key, _]) => [key, 0]),
              ),
            },
            broker: {
              ...Object.fromEntries(
                Object.entries(state.ncpData.categoryWeightings.broker).map(([key, _]) => [key, 0]),
              ),
            },
          },
        }
        return { ...state, ncpData: resetNCPData }
      }
    }
  }
}
