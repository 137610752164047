import {
  ADJUSTED_PREMIUM_VALUE,
  ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
  ADJUSTED_PREMIUM_VALUE_WITH_SAMPLED_RETENTION,
  ADJUSTMENT_NAME,
  PREMIUM_COLUMN_NAME,
} from './calculate-kpis'
import { NumberType, numericValueIsDefined } from '../utils/numbers'
import { ALL_MONTHs } from '../components/renewal-graph/gwp-bar-chart/inception-month-graph'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from './calculate-with-actuals'
import { WF_TYPE_OF_DATA_COLUMN } from './TypeOfData'
import { RawEntry } from './rawEntry'

export interface NegatedNumericFilter {
  minimumValueOfRangeToIgnoreInclusive: number
  maximumValueOfRangeToIgnoreInclusive: number
}

export interface NumericFilter {
  minimumValueInclusive: number
  maximumValueInclusive: number
}

export interface NegatedFilter {
  valuesToBeIgnored: string[]
}

type NameFilter = string

export type NameListFilter = NameFilter[]

export type FilterType = NameListFilter | NegatedFilter | NumericFilter | NegatedNumericFilter

export type FilterValues = { [key: string]: FilterType }

export function isNegatedStringFilter(value?: FilterType): value is NegatedFilter {
  if (!value) {
    return false
  }
  return !Array.isArray(value) && value.hasOwnProperty('valuesToBeIgnored')
}

const PERCENTAGE_COLUMNS = new Set(['Actual Signing %', 'Rate on Line', 'Effective Line'])

export function calculateNumberTypeForColumn(columnName: string): NumberType {
  if (PERCENTAGE_COLUMNS.has(columnName)) {
    return NumberType.PERCENTAGE
  }

  return NumberType.DOLLAR
}

export function isNameListFilter(value: FilterType): value is NameListFilter {
  return Array.isArray(value)
}

export function isNumericFilter(value?: FilterType): value is NumericFilter {
  if (!value) {
    return false
  }
  return (
    !Array.isArray(value) &&
    value.hasOwnProperty('maximumValueInclusive') &&
    value.hasOwnProperty('minimumValueInclusive') &&
    numericValueIsDefined((value as NumericFilter).minimumValueInclusive) &&
    numericValueIsDefined((value as NumericFilter).maximumValueInclusive)
  )
}

export function isNegatedNumericFilter(value?: FilterType): value is NegatedNumericFilter {
  if (!value) {
    return false
  }
  return (
    !Array.isArray(value) &&
    value.hasOwnProperty('maximumValueOfRangeToIgnoreInclusive') &&
    value.hasOwnProperty('minimumValueOfRangeToIgnoreInclusive') &&
    numericValueIsDefined((value as NegatedNumericFilter).minimumValueOfRangeToIgnoreInclusive) &&
    numericValueIsDefined((value as NegatedNumericFilter).maximumValueOfRangeToIgnoreInclusive)
  )
}

export const EXTRA_FILTERS_FOR_SUMMARY_PAGE = [WF_TYPE_OF_DATA_COLUMN, ADJUSTMENT_NAME]

export const INCLUDED_THINGS_TO_USE_AS_STRING_FILTERS = [
  'UW Division',
  'UW Sector',
  'UW Team',
  'A1 Market',
  'A1 Segment',
  'Client',
  'Client Parent Name',
  'Client Ranking Category',
  'Client Cluster High-Level',
  'Client Cluster Product',
  'Inception Year',
  'Inception Month',
  'Expiry Date',
  'Client Rank',
  'Renewal Inception Month',
  'Convex Requirement Name',
  'Previous Convex Submission Ref',
  'Convex Submission Ref',
  'Convex Layer Ref',
  'Renewal Intention',
  'Currency',
  'A1 Segmentation Combined',
  'Analysis Group',
  'Policy Status Simplified',
  'Eclipse Submission Ref',
  'Eclipse Layer Ref',
  'Office',
  'Underwriter',
  'Broker',
  'Placement Type',
  'Treaty Type',
  'Treaty Type Simplified',
  'Placing Basis Structure',
  'Exposure Region',
  'Predominant Property Exposure',
  'Operating Territory',
  'Major Class',
  'Minor Class',
  'COB - Tier 1',
  'COB - Tier 2',
  'COB - Tier 3',
  'Policy Period',
  'Client Business Structure',
  'Cat Perils',
  'Cedant US Licensing',
  'Market Group',
  'Interest Type',
  'Loss Impacted',
  'Entity',
  'Interest Type',
  'WF Output Type',
  'Temporal Link',
  'TMV',
]

export const ATTACHMENT_COLUMN_NAME = 'Attachment - 100%'
export const LIMIT_COLUMN_NAME = 'Limit - 100%'
export const CONVEX_SHARE_COLUMN_NAME = 'Effective Line'

export const INCLUDED_THINGS_TO_USE_AS_NUMERIC_FILTERS = [
  PREMIUM_COLUMN_NAME,
  ADJUSTED_PREMIUM_VALUE,
  ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
  ADJUSTED_PREMIUM_VALUE_WITH_SAMPLED_RETENTION,
  'Limit - 100%',
  'Attachment - 100%',
  'Convex Limit',
  'Written Line',
  'Signed Line',
  CONVEX_SHARE_COLUMN_NAME,
  'Estimated Signing %',
  'Actual Signing %',
  'Rate on Line',
  'Risk Adjusted Rate Change',
  'Acq Costs',
  'EULR',
  'WF Adjustment Convex Share Change',
  'WF Adjustment 100% Risk Premium Change',
  'WF Adjustment 100% GWP Change',
  'WF Gross Risk Adjusted Rate Change',
  'WF Adjusted Convex Share',
  'WF Sampled Scaling Factor',
]

export const GRAPH_THINGS_TO_USE_AS_NUMERIC_FILTERS = [
  PREMIUM_COLUMN_NAME,
  ADJUSTED_PREMIUM_VALUE,
  'WF Gross Risk Adjusted Rate Change',
  'WF Adjusted Convex Share',
  'WF Adjusted Convex Limit',
  'Limit - 100%',
  'Attachment - 100%',
  'Convex Limit',
  'Written Line',
  'Signed Line',
  CONVEX_SHARE_COLUMN_NAME,
  'Actual Signing %',
  'Rate on Line',
]

export function calculateIndividualStringFilterValues(
  allPossibleValues: string[],
  columnThatCanBeFilteredOn: string,
): string[] | undefined {
  if (columnThatCanBeFilteredOn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME) {
    return ALL_MONTHs
  }

  const deduplicatedValues = Array.from(new Set(allPossibleValues))

  const filteredValues = deduplicatedValues.filter((value) => value !== undefined).filter(isString)

  filteredValues.sort()

  if (filteredValues.length) {
    return filteredValues
  } else {
    return undefined
  }
}

function calculateIndividualNumericFilterValues(
  rawData: any[],
  columnThatCanBeFilteredOn: string,
): NumericFilter | undefined {
  const allPossibleValues = rawData.map((rawDataItem) => rawDataItem[columnThatCanBeFilteredOn] as number)

  const filteredValues = allPossibleValues.filter(numericValueIsDefined)

  const deduplicatedValues = Array.from(new Set(filteredValues))

  if (deduplicatedValues.length) {
    const maximumValueInclusive = Math.max.apply(null, deduplicatedValues)
    const minimumValueInclusive = Math.min.apply(null, deduplicatedValues)
    return {
      minimumValueInclusive,
      maximumValueInclusive,
    }
  } else {
    return undefined
  }
}

export function calculatePossibleStringFilterValuesWithExtras(
  rawData: RawEntry[],
  extraFilters: string[] = [],
  summaryTabData: boolean,
): { [x: string]: NameListFilter } {
  const combinedFilters = [...INCLUDED_THINGS_TO_USE_AS_STRING_FILTERS, ...extraFilters]

  let resultList: { [x: string]: NameListFilter } = {}

  rawData.forEach((currentDataItem) => {
    for (const currentColumnName in currentDataItem) {
      if (currentDataItem.hasOwnProperty(currentColumnName)) {
        const currentColumnValue = currentDataItem[currentColumnName]
        if (!resultList.hasOwnProperty(currentColumnName)) {
          resultList[currentColumnName] = []
        }
        resultList[currentColumnName].push(currentColumnValue)
      }
    }
  })

  const resultForAllFilters: { [x: string]: NameListFilter } = {}

  combinedFilters.forEach((columnThatCanBeFilteredOn) => {
    const values = calculateIndividualStringFilterValues(
      resultList[columnThatCanBeFilteredOn] || [],
      columnThatCanBeFilteredOn,
    )

    const summaryTabA1SegmentValues = summaryTabData && columnThatCanBeFilteredOn === 'A1 Segment' && values && values.length > 0
    const filterValues = values && values.length > 1
    
    if (summaryTabA1SegmentValues || filterValues) {
      resultForAllFilters[columnThatCanBeFilteredOn] = values
    }
  })
  return resultForAllFilters
}

export function combineFilters(
  stringFilters: { [x: string]: NameListFilter },
  numericFilters: { [x: string]: NumericFilter },
): FilterValues {
  return { ...stringFilters, ...numericFilters }
}

export function calculatePossibleNumericFilterValues(
  rawData: RawEntry[],
  thingsToUseAsFilters: string[] = INCLUDED_THINGS_TO_USE_AS_NUMERIC_FILTERS,
): { [x: string]: NumericFilter } {
  const numericFilters = thingsToUseAsFilters
    .map((columnThatCanBeFilteredOn) => {
      const numericFilterRange = calculateIndividualNumericFilterValues(rawData, columnThatCanBeFilteredOn)

      return numericFilterRange ? { [columnThatCanBeFilteredOn]: numericFilterRange } : {}
    })
    .reduce((fullDataSoFar, currentObjectToAdd) => ({ ...fullDataSoFar, ...currentObjectToAdd }), {})

  return { ...numericFilters }
}

export function calculatePossibleFilterValues(
  rawData: RawEntry[],
  extraFilters: string[] = [],
): { [x: string]: NumericFilter | NameListFilter } {
  const stringFilters = calculatePossibleStringFilterValuesWithExtras(rawData, extraFilters, false)
  const numericFilters = calculatePossibleNumericFilterValues(rawData)

  return { ...stringFilters, ...numericFilters }
}

function isString(value: any): value is string {
  return typeof value === 'string' || value instanceof String
}
