import React, { useContext, useEffect, useState } from 'react'
import { css } from '../../utils/css'
import SectionHeaderContainer from '../../components/section-header-container/SectionHeaderContainer'
import SectionHeader from '../../components/section-header/SectionHeader'
import { fetchAllOutwardScenarios, OutwardScenario } from '../../backend/outward-scenarios'
import Divider from '../../components/divider/LineDivider'
import OutwardScenarioCard from '../../components/outward-scenario-card/OutwardScenarioCard'
import { AddScenarioCard } from '../scenario-chooser/AddScenarioCard'
import CreateOutwardScenarioCard from '../../components/create-outward-scenario-card/CreateOutwardScenarioCard'
import EditOutwardScenarioCard from '../../components/edit-outward-scenario-card/EditOutwardScenarioCard'
import Spacer from '../../components/spacer/Spacer'
import { useIsMounted } from '../../utils/mounting'
import { OutwardsPortfolioListingContext } from '../../providers/outwards/OutwardsPortfolioListingProvider'

export interface OutwardScenarioListProps {
  programme: string
  treaty: string
  layer: string
  version: string
  year: string
  isCreatingStackedScenario: boolean
  currentlyChosenScenarioIds: string | undefined
  onCurrentCreatingSelectChanges: (currentLayer: string, currentScenarioId: string | undefined) => void
  className: string
}

const NEW_OUTWARD_SCENARIO_FAKE_ID = 'NEW'

function OutwardScenarioList({
  programme,
  treaty,
  layer,
  year,
  version,
  className,
  isCreatingStackedScenario,
  currentlyChosenScenarioIds,
  onCurrentCreatingSelectChanges,
}: OutwardScenarioListProps) {
  const [currentlyEditingScenarioId, setCurrentlyEditingScenarioId] = useState<string | undefined>(undefined)
  const [currentOutwardScenarios, setOutwardScenarios] = useState<OutwardScenario[]>([])
  const isMounted = useIsMounted()
  const { canWriteToOutwards } = useContext(OutwardsPortfolioListingContext)
  const reloadOutwardScenarios = () => {
    fetchAllOutwardScenarios(programme, treaty, layer, version, year).then((newListOfScenarios) => {
      if (!isMounted()) return null
      setOutwardScenarios(newListOfScenarios)
    })
  }
  useEffect(reloadOutwardScenarios, [programme, treaty, layer, version, year, isMounted])

  const toggleIsCreating = (scenarioIdIsBeingToggled: string) => () => {
    if (currentlyChosenScenarioIds === scenarioIdIsBeingToggled) {
      onCurrentCreatingSelectChanges(layer, undefined)
    } else {
      onCurrentCreatingSelectChanges(layer, scenarioIdIsBeingToggled)
    }
  }

  const triggerEditScenario = (id: string) => {
    setCurrentlyEditingScenarioId(id)
  }

  return (
    <div
      className={css('OutwardScenarioList', className)}
      key={layer}
    >
      <SectionHeaderContainer>
        <div className="StackedScenariosContainerLeft">
          {canWriteToOutwards && !currentlyEditingScenarioId && (
            <AddScenarioCard onClick={() => setCurrentlyEditingScenarioId(NEW_OUTWARD_SCENARIO_FAKE_ID)} />
          )}
          {layer && <SectionHeader title={layer} />}
        </div>
        <Spacer />
      </SectionHeaderContainer>
      <div className="ScenarioCards">
        {currentlyEditingScenarioId === NEW_OUTWARD_SCENARIO_FAKE_ID && (
          <CreateOutwardScenarioCard
            programme={programme}
            treaty={treaty}
            layer={layer}
            version={version}
            year={year}
            onCreate={reloadOutwardScenarios}
            onCancel={() => setCurrentlyEditingScenarioId(undefined)}
            outwardScenarios={currentOutwardScenarios}
          />
        )}
        {currentOutwardScenarios.map((outwardScenario) =>
          currentlyEditingScenarioId === outwardScenario.id ? (
            <EditOutwardScenarioCard
              key={`view-${outwardScenario.id}`}
              outwardScenario={outwardScenario}
              triggerStopEditing={() => setCurrentlyEditingScenarioId(undefined)}
              triggerSaveChanges={() => {
                setCurrentlyEditingScenarioId(undefined)
                reloadOutwardScenarios()
              }}
            />
          ) : (
            <OutwardScenarioCard
              key={`view-${outwardScenario.id}`}
              outwardScenario={outwardScenario}
              triggerScenarioReload={reloadOutwardScenarios}
              triggerEditScenario={triggerEditScenario}
              canWrite={true}
              isComparing={false}
              comparingIsSelected={false}
              comparingOnToggle={() => void 0}
              isCreatingStackedScenario={isCreatingStackedScenario}
              creatingIsSelected={currentlyChosenScenarioIds === outwardScenario.id}
              creatingOnToggle={toggleIsCreating(outwardScenario.id)}
              stopClicking={currentlyEditingScenarioId !== undefined}
            />
          ),
        )}
        <div className="EndPadding" />
      </div>
      <Divider />
    </div>
  )
}

export default OutwardScenarioList
