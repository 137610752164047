import React, { createContext, useContext, useState } from 'react'

import { GraphFilterDataContext } from './GraphFilterDataProvider'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { ADJUSTED_PREMIUM_VALUE } from '../../backend/calculate-kpis'
import DropdownContainer from '../dropdown-container/DropdownContainer'
import { toOption } from '../../utils/lists'

const ChooseMultipleColumnDropdownContext = createContext<GraphFilterData>({
  currentlyChosenStringColumn: 'A1 Segment',
  currentlyChosenNumericColumn: ADJUSTED_PREMIUM_VALUE,
})

const { Consumer, Provider } = ChooseMultipleColumnDropdownContext

export interface GraphFilterData {
  currentlyChosenStringColumn: string
  currentlyChosenNumericColumn: string
}

type ChooseMultipleColumnDropdownProviderProps = React.PropsWithChildren<{
  defaultStringColumn?: string
  defaultNumericColumn?: string
}>

const ChooseMultipleColumnDropdownProvider = (props: ChooseMultipleColumnDropdownProviderProps) => {
  const { allPossibleColumnValues, possibleNumericColumnValues } = useContext(GraphFilterDataContext)

  const [currentlyChosenStringColumn, setChosenStringColumn] = useState(props.defaultStringColumn || 'A1 Segment')
  const [currentlyChosenNumericColumn, setChosenNumericColumn] = useState(
    props.defaultNumericColumn || ADJUSTED_PREMIUM_VALUE,
  )

  return (
    <>
      <DropdownContainer>
        <MultiSelectDropdown
          onSelect={onSelectSingleValueFromMulti(setChosenStringColumn)}
          options={allPossibleColumnValues.map(toOption)}
          selected={[toOption(currentlyChosenStringColumn)]}
        />
        <MultiSelectDropdown
          onSelect={onSelectSingleValueFromMulti(setChosenNumericColumn)}
          options={possibleNumericColumnValues.map(toOption)}
          selected={[toOption(currentlyChosenNumericColumn)]}
        />
      </DropdownContainer>
      <Provider
        value={{ currentlyChosenStringColumn, currentlyChosenNumericColumn }}
        children={props.children}
      />
    </>
  )
}

export {
  Consumer as ChooseMultipleColumnDropdownConsumer,
  ChooseMultipleColumnDropdownProvider,
  ChooseMultipleColumnDropdownContext,
}
