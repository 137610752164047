import { useCategoryForm } from './hooks/useCategoryForm'
import { FormProvider } from 'react-hook-form'
import { useContext, useMemo } from 'react'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { getTop10TargetMarketValues } from '../../utils/targetMarketColumns'
import { AutoSelectAllColumns, DynamicCategoryTable } from './DynamicCategoryTable'
import { BrokerFields } from './types'

export const BrokerTable = () => {
  const { filteredRenewalDataForScenario } = useContext(FullScenarioDataContext)

  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)

  const form = useCategoryForm<BrokerFields>({
    defaultValues: { brokers: [] },
  })

  const top10Brokers = useMemo(
    () => getTop10TargetMarketValues(filteredRenewalDataForScenario, 'Broker'),
    [filteredRenewalDataForScenario],
  )
  const brokerOptions = useMemo(() => top10Brokers.map((x) => ({ label: x })), [top10Brokers])

  return top10Brokers.length === 0 ? (
    <></>
  ) : (
    <FormProvider {...form}>
      <DynamicCategoryTable<BrokerFields, AutoSelectAllColumns>
        categoryLabel="Broker"
        categoryName="broker"
        dynamicFieldsName="brokers"
        columnsOptions={brokerOptions}
      />
    </FormProvider>
  )
}
