import React from 'react'

import './DropdownWithTextfield.scss'

import { css } from '../../utils/css'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { OptionsType } from '../../utils/lists'
import TextField from '../text-field/WFTextField'

export interface DropdownWithTextfieldProps {
  onDropdownSelect: (selectedItems: OptionsType[]) => void
  onTextFieldChange: React.ChangeEventHandler<HTMLInputElement>
  className?: string | string[]
  options: OptionsType[]
  selected: OptionsType[]
  hideMultiSelect?: boolean
  isDisabled?: boolean
  dropdownPlaceholder?: string
  textFieldPlaceholder?: string
  textFieldType?: string
  textFieldValue?: string
  textFieldWarning?: string
  textFieldError?: string
  textFieldIsPercentage?: boolean
  step?: string
}

function DropdownWithTextfield(props: DropdownWithTextfieldProps): JSX.Element {
  return (
    <div className={css('DropdownWithTextfield', props.className)}>
      <MultiSelectDropdown
        onSelect={props.onDropdownSelect}
        selected={props.selected}
        placeholder={props.dropdownPlaceholder}
        options={props.options}
        isDisabled={props.isDisabled}
      />
      <TextField
        onChange={props.onTextFieldChange}
        placeholder={props.textFieldPlaceholder}
        type={props.textFieldType}
        value={props.textFieldValue}
        warning={props.textFieldWarning}
        error={props.textFieldError}
        percentage={props.textFieldIsPercentage}
        step={props.step}
      />
    </div>
  )
}

export default DropdownWithTextfield
