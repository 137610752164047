import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import Big from 'big.js'

import './MarketConvexAndGraphOverallContainer.scss'

import SectionHeader from '../section-header/SectionHeader'
import TextField from '../text-field/WFTextField'
import {
  displayLargeDollarValue,
  numericValueIsDefined,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  suffixForValue,
  sumListOfPossiblyUndefinedOrStringIntegers,
} from '../../utils/numbers'
import { onNumberInputUpdateState, setValueIncludingSuffixShorthand } from '../../utils/onChange'
import { RATE_ENVIRONMENT_OPTIONS } from '../market-splits-table/MarketSplitsTableUtils'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import MultiSelectDropdownSmall from '../multi-select-dropdown-small/MultiSelectDropdownSmall'
import { KpiCardSmall, KpiCardSmallText } from '../kpi-card-small/KpiCardSmall'
import { ErrorText } from '../text-area/ErrorText'
import { MarketGraphDataV2, MarketSegmentV2, MarketV2 } from '../../backend/market-V2'
import { MarketV2Context } from '../../pages/marketV2/MarketV2Provider'
import { NoteSections } from '../../pages/notes/NotesContent'
import { NoteSectionNames } from '../../pages/notes/NotesDataProvider'
import Graphs from '../graphs/Graphs'
import MarketBoxPlot from '../market-graph/MarketBoxPlot'
import { toOption } from '../../utils/lists'
import { calculateGGLR, requiresAdjustment } from '../../backend/calculate-kpis'

export const calculateErrorIfAnyOfTheSegmentsArentFilledIn = (
  segmentBreakdowns: Array<MarketSegmentV2>,
): string | undefined => {
  const segmentNameAndFieldName: Array<{ segmentName: string; fieldName: string }> = segmentBreakdowns.flatMap(
    (segmentBreakdowns) => {
      const { segmentName } = segmentBreakdowns
      return Object.entries(segmentBreakdowns)
        .filter(([currentFieldName, currentFieldValue]) => currentFieldValue === undefined)
        .filter(([currentFieldName]) => currentFieldName !== 'id')
        .filter(([currentFieldName]) => currentFieldName !== 'marketId')
        .filter(([currentFieldName]) => currentFieldName !== 'marketShare')
        .map(([currentFieldName]) => ({ segmentName, fieldName: currentFieldName }))
    },
  )

  if (!segmentNameAndFieldName.length) {
    return undefined
  }

  const commaSeparatedList = segmentNameAndFieldName
    .map(({ segmentName, fieldName }) => `${segmentName} - ${fieldName}`)
    .join(',')
  return `You need values in all the segments for the following fields: (${commaSeparatedList})`
}

export const calculateMarketGwpInputError = (
  value: PossiblyNegativeNumber,
  segmentBreakdown: Array<MarketSegmentV2>,
): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if ((value as number) <= 0) {
    return 'This must be greater than 0'
  }

  const totalOfTheSegments = sumListOfPossiblyUndefinedOrStringIntegers(segmentBreakdown.map((item) => item.marketGwp))

  if (totalOfTheSegments !== value) {
    return "The total doesn't match the sum of the segments"
  }

  return undefined
}

const calculateGrossLossRatioWarning = (value: PossiblyNegativeNumber): string | undefined => {
  if (numericValueIsDefined(value) && (value as number) > 200) {
    return 'Should this be above 200?'
  }
}

export const calculateAcquisitionWarning = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if (numericValueIsDefined(value) && (value as number) > 50) {
    return 'Should this be above 50?'
  }
}

export const calculateDollarValueError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if ((value as number) <= 0) {
    return 'This must be greater than 0'
  }

  return undefined
}

export const calculateAddressableMarketError = (
  addressableMarket: PossiblyNegativeNumber,
  totalMarket: PossiblyNegativeNumber,
): string | undefined => {
  if (!numericValueIsDefined(addressableMarket)) {
    return 'This must have a value'
  }

  if ((addressableMarket as number) <= 0) {
    return 'This must be greater than 0'
  }

  if ((addressableMarket as number) >= (totalMarket as number)) {
    return 'Addressable Market GWP must be less than Total Market GWP'
  }

  return undefined
}

export const calculatePercentileError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if ((value as number) <= 0) {
    return 'This must be greater than 0'
  }

  if ((value as number) > 100) {
    return 'This must be less than 101'
  }

  return undefined
}

export const calculateRateChangeError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if ((value as number) < -100) {
    return 'This must be greater than -100'
  }

  if ((value as number) > 100) {
    return 'This must be less than 101'
  }

  return undefined
}

export interface MarketAndConvexOverallContainerProps {
  year: string
  marketName: string

  totalMarketGwp: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  pmlScenario1In100: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  confidenceInMarketSize: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  accessOrCapability: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  inAppetite: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]

  manuallyEditedAddressableMarketGwp: boolean
  addressableMarketGwp: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]

  rateEnvironment: [string | undefined, Dispatch<SetStateAction<string | undefined>>]

  convexGwp: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  convexMarketShare: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  peakConvexGwp: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  peakConvexMarketShare: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  convexEstimatedShareOfPml: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  coreClients: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]

  previousYearGnlr: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  previousYearGglr: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  convexAcquisitionRatio: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  marketGrarc: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  marketNrarc: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  inflation: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  AbeGglr: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]

  uwConvexAcquisitionRatio: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  uwMarketGrarc: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  uwInflation: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]
  uwAbeGglr: [PossiblyNegativeNumber, Dispatch<SetStateAction<PossiblyNegativeNumber>>]

  graphData: Array<MarketGraphDataV2>

  perSegmentBreakdown: Array<MarketSegmentV2>

  shouldValidate: boolean
  setIsUpToDate: (isUpToDate: boolean) => void
}

export function calculateAdjustedGraphData(
  defaultMarketData: MarketV2 | undefined,
  previousAcq: PossiblyNegativeNumber,
  marketRateChange: PossiblyNegativeNumber,
): Array<MarketGraphDataV2> {
  return (
    defaultMarketData?.graphData?.map((currentGraphDataPoint) => {
      if (currentGraphDataPoint?.y.length === 5) {
        const shouldAdjustGglr = requiresAdjustment(defaultMarketData.year)
        const newItem = {
          ...currentGraphDataPoint,
          y: currentGraphDataPoint.y.map((currentGraphCoordinate) =>
            calculateGGLR(
              currentGraphCoordinate,
              previousAcq,
              shouldAdjustGglr ? defaultMarketData.inflation : undefined,
              shouldAdjustGglr ? marketRateChange : undefined,
            ).toFixed(2),
          ),
        }

        return newItem
      }

      return currentGraphDataPoint
    }) || []
  )
}

export function MarketConvexAndGraphOverallContainer(props: MarketAndConvexOverallContainerProps) {
  const { isMarketEditorView } = useContext(MarketV2Context)

  function setStateWithSettingNotUpToDate<T>(setState: Dispatch<SetStateAction<T>>): Dispatch<SetStateAction<T>> {
    return (item: SetStateAction<T>): void => {
      setState(item)
      props.setIsUpToDate(false)
    }
  }

  function renderAddressableMarketGwp(manuallyEdited: boolean): JSX.Element {
    const title: string = 'Addressable Market GWP ($m)'
    const titleHeading: undefined | JSX.Element = manuallyEdited ? (
      <p
        className="Title"
        style={{ outline: '1px solid grey' }}
        title={`This field has been edited`}
      >{`*${title}`}</p>
    ) : undefined
    return (
      <TextField
        className="AddressableMarketGWP"
        title={title}
        titleHeading={titleHeading}
        placeholder="0"
        type="number"
        value={props.addressableMarketGwp[0]}
        onChange={onNumberInputUpdateState(setStateWithSettingNotUpToDate(props.addressableMarketGwp[1]))}
        error={
          props.shouldValidate
            ? calculateAddressableMarketError(props.addressableMarketGwp[0], props.totalMarketGwp[0])
            : ''
        }
      />
    )
  }

  const calculatePeakConvexMarketShareAndPlaceItIntoState = () => {
    //
    const result = new Big(props.peakConvexGwp[0] || 0)
      .div(new Big(Number(props.totalMarketGwp[0]) || 1))
      .mul(ONE_HUNDRED)
      .toFixed(2)
    props.peakConvexMarketShare[1](Number(result))
  }

  useEffect(calculatePeakConvexMarketShareAndPlaceItIntoState, [
    props.peakConvexMarketShare,
    props.totalMarketGwp,
    props.peakConvexGwp,
  ])

  return (
    <div className="OverallMarketContainer">
      <div className="MarketHalfPageContainer">
        <div className="SectionContainer">
          <div className="SideBySideSectionContainer">
            <div className="SectionContainer5Of7">
              <SectionHeader title="Total Market" />
              <div className="CardsContainer">
                {isMarketEditorView ? (
                  <TextField
                    className="MarketGwp"
                    title="Total Market GWP ($bn)"
                    placeholder="$0"
                    value={displayLargeDollarValue(Number(props.totalMarketGwp[0]) || 0)}
                    onChange={setValueIncludingSuffixShorthand(setStateWithSettingNotUpToDate(props.totalMarketGwp[1]))}
                    error={
                      props.shouldValidate
                        ? calculateMarketGwpInputError(Number(props.totalMarketGwp[0]), props.perSegmentBreakdown)
                        : ''
                    }
                  />
                ) : (
                  <KpiCardSmall
                    value={new Big(props.totalMarketGwp[0] || 0)}
                    title="Total Market GWP ($bn)"
                    prefix="$"
                    suffix={suffixForValue(Number(props.totalMarketGwp[0]) || 0)}
                    className="GwpCard"
                  />
                )}
                {isMarketEditorView ? (
                  <TextField
                    className="PMLScenario"
                    title="PML Scenario (1 in 100)"
                    placeholder="$0"
                    value={displayLargeDollarValue(Number(props.pmlScenario1In100[0]) || 0)}
                    onChange={setValueIncludingSuffixShorthand(
                      setStateWithSettingNotUpToDate(props.pmlScenario1In100[1]),
                    )}
                    warning={calculateAcquisitionWarning(props.pmlScenario1In100[0])}
                    error={props.shouldValidate ? calculatePercentileError(props.pmlScenario1In100[0]) : ''}
                  />
                ) : (
                  <KpiCardSmall
                    value={new Big(props.pmlScenario1In100[0] || 0)}
                    title="PML Scenario (1 in 100)"
                    prefix="$"
                    suffix={suffixForValue(Number(props.pmlScenario1In100[0]) || 0)}
                    className="GwpCard"
                  />
                )}
                {isMarketEditorView ? (
                  <TextField
                    className="ConfidenceInMarketSize"
                    title="Confidence In Market Size"
                    placeholder="0"
                    type="number"
                    value={props.confidenceInMarketSize[0]}
                    onChange={onNumberInputUpdateState(setStateWithSettingNotUpToDate(props.confidenceInMarketSize[1]))}
                    error={props.shouldValidate ? calculatePercentileError(props.confidenceInMarketSize[0]) : ''}
                    percentage
                  />
                ) : (
                  <KpiCardSmall
                    value={new Big(props.confidenceInMarketSize[0] || 0)}
                    title="Confidence In Market Size"
                    suffix="%"
                    className="GwpCard"
                    decimals={1}
                  />
                )}
                {isMarketEditorView ? (
                  <TextField
                    className="AccessOrCapability"
                    title="Access / Capability"
                    placeholder="0"
                    type="number"
                    value={props.accessOrCapability[0]}
                    onChange={onNumberInputUpdateState(setStateWithSettingNotUpToDate(props.accessOrCapability[1]))}
                    error={props.shouldValidate ? calculatePercentileError(props.accessOrCapability[0]) : ''}
                    percentage
                  />
                ) : (
                  <KpiCardSmall
                    value={new Big(props.accessOrCapability[0] || 0)}
                    title="Access / Capability"
                    suffix="%"
                    className="GwpCard"
                    decimals={1}
                  />
                )}
                {isMarketEditorView ? (
                  <TextField
                    className="InAppetite"
                    title="In Appetite"
                    placeholder="0"
                    type="number"
                    value={props.inAppetite[0]}
                    onChange={onNumberInputUpdateState(setStateWithSettingNotUpToDate(props.inAppetite[1]))}
                    error={props.shouldValidate ? calculatePercentileError(props.inAppetite[0]) : ''}
                    percentage
                  />
                ) : (
                  <KpiCardSmall
                    value={new Big(props.inAppetite[0] || 0)}
                    title="In Appetite"
                    suffix="%"
                    className="GwpCard"
                    decimals={1}
                  />
                )}
              </div>
            </div>
            <div className="SectionContainer2Of7">
              <SectionHeader title="Accessible Market" />
              <div className="CardsContainer">
                {isMarketEditorView ? (
                  renderAddressableMarketGwp(props.manuallyEditedAddressableMarketGwp)
                ) : (
                  <KpiCardSmall
                    value={new Big(props.addressableMarketGwp[0] || 0)}
                    title="Addressable Market GWP ($m)"
                    className="GwpCard"
                  />
                )}
                {isMarketEditorView ? (
                  <div>
                    <MultiSelectDropdownSmall
                      title="Rate Environment"
                      onSelect={onSelectSingleValueFromMulti(setStateWithSettingNotUpToDate(props.rateEnvironment[1]))}
                      options={RATE_ENVIRONMENT_OPTIONS.map(toOption)}
                      selected={props.rateEnvironment[0] ? [toOption(props.rateEnvironment[0])] : []}
                    />
                    {props.shouldValidate && !props.rateEnvironment[0] && (
                      <ErrorText error={'You must select an option'} />
                    )}
                  </div>
                ) : (
                  <KpiCardSmallText
                    value={props.rateEnvironment[0] ?? ''}
                    title="Rate Environment"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isMarketEditorView && <NoteSections onyIncludeSections={[NoteSectionNames.MARKET_COMMENTARY]} />}
        <Graphs>
          <MarketBoxPlot
            graphData={props.graphData}
            otherLossRatios={[
              {
                title: 'UW GGLR',
                value: props.uwAbeGglr[0] as number,
                x: props.marketName,
              },
              {
                title: 'ABE GGLR',
                value: props.AbeGglr[0] as number,
                x: props.marketName,
              },
            ]}
          />
        </Graphs>
      </div>
      <div className="MarketHalfPageContainer">
        <div className="SectionContainer">
          <SectionHeader title="Convex" />
          <div className="CardsContainer">
            {!isMarketEditorView && (
              <KpiCardSmall
                value={new Big(props.convexGwp[0] || 0)}
                title={'Convex ' + parseInt(props.year) + ' GWP ($m)'}
                prefix="$"
                suffix={suffixForValue(props.convexGwp[0] || 0)}
                className="GwpCard"
              />
            )}
            {!isMarketEditorView && (
              <KpiCardSmall
                value={new Big(props.convexMarketShare[0] || 0)}
                title={'Convex ' + parseInt(props.year) + ' Market Share'}
                className="GwpCard"
                suffix="%"
                decimals={1}
              />
            )}
            {isMarketEditorView ? (
              <TextField
                className="PeakConvexGwp"
                title="Peak Convex GWP ($m)"
                placeholder="$0"
                value={displayLargeDollarValue(props.peakConvexGwp[0] || 0)}
                onChange={setValueIncludingSuffixShorthand(setStateWithSettingNotUpToDate(props.peakConvexGwp[1]))}
                error={props.shouldValidate ? calculateDollarValueError(props.peakConvexGwp[0]) : ''}
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.peakConvexGwp[0] || 0)}
                title="Peak Convex GWP ($m)"
                prefix="$"
                suffix={suffixForValue(props.peakConvexGwp[0] || 0)}
                className="GwpCard"
              />
            )}
            <KpiCardSmall
              value={new Big(props.peakConvexMarketShare[0] || 0)}
              title="Peak Convex Market Share"
              className="GwpCard PeakConvexMarketShare"
              suffix="%"
              decimals={1}
            />
            {isMarketEditorView ? (
              <TextField
                className="ConvexEstShareOfPMLDownside"
                title="Convex est share of PML downside ($m)"
                placeholder="0"
                value={displayLargeDollarValue(props.convexEstimatedShareOfPml[0])}
                onChange={setValueIncludingSuffixShorthand(
                  setStateWithSettingNotUpToDate(props.convexEstimatedShareOfPml[1]),
                )}
                error={props.shouldValidate ? calculateDollarValueError(props.convexEstimatedShareOfPml[0]) : ''}
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.convexEstimatedShareOfPml[0] || 0)}
                title="Convex est share of PML downside ($m)"
                className="GwpCard"
                decimals={1}
              />
            )}
            {!isMarketEditorView && (
              <KpiCardSmall
                value={new Big(props.coreClients[0] || 0)}
                title={'Core Clients'}
                className="GwpCard"
                suffix="%"
                decimals={1}
              />
            )}
          </div>
        </div>
        {isMarketEditorView && <NoteSections onyIncludeSections={[NoteSectionNames.PEAK_CONVEX_VISION]} />}
        <div className="SectionContainer">
          <SectionHeader title="Actuarial Benchmark" />
          <div className="CardsContainer">
            <KpiCardSmall
              value={new Big(props.previousYearGnlr[0] || 0)}
              title={parseInt(props.year) - 1 + ' GNLR'}
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.previousYearGglr[0] || 0)}
              title={parseInt(props.year) - 1 + ' GGLR'}
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.convexAcquisitionRatio[0] || 0)}
              title="Convex Acq Ratio"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.marketGrarc[0] || 0)}
              title="Convex GRARC"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.marketNrarc[0] || 0)}
              title="Market NRARC"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.inflation[0] || 0)}
              title="Inflation"
              suffix="%"
              className="GwpCard"
            />
            <KpiCardSmall
              value={new Big(props.AbeGglr[0] || 0)}
              title="ABE GGLR"
              suffix="%"
              className="GwpCard"
            />
          </div>
        </div>
        {isMarketEditorView && <NoteSections onyIncludeSections={[NoteSectionNames.UW_COMMENTARY_ON_ABE]} />}
        <div className="SectionContainer">
          <SectionHeader title="Underwriter View" />
          <div className="CardsContainer">
            {isMarketEditorView ? (
              <TextField
                className="ConvexAcqRatio"
                title="Convex Acq Ratio"
                value={props.uwConvexAcquisitionRatio[0] || 0}
                onChange={setValueIncludingSuffixShorthand(
                  setStateWithSettingNotUpToDate(props.uwConvexAcquisitionRatio[1]),
                )}
                warning={props.shouldValidate ? calculateAcquisitionWarning(props.uwConvexAcquisitionRatio[0]) : ''}
                percentage
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.uwConvexAcquisitionRatio[0] || 0)}
                title="Convex Acq Ratio"
                suffix="%"
                className="GwpCard"
              />
            )}
            {isMarketEditorView ? (
              <TextField
                className="MarketGRARC"
                title="Market GRARC"
                value={props.uwMarketGrarc[0] || 0}
                onChange={setValueIncludingSuffixShorthand(setStateWithSettingNotUpToDate(props.uwMarketGrarc[1]))}
                error={props.shouldValidate ? calculateRateChangeError(props.uwMarketGrarc[0]) : ''}
                percentage
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.uwMarketGrarc[0] || 0)}
                title="Market GRARC"
                suffix="%"
                className="GwpCard"
              />
            )}
            {isMarketEditorView ? (
              <TextField
                className="Inflation"
                title="Inflation"
                value={props.uwInflation[0] || 0}
                onChange={setValueIncludingSuffixShorthand(setStateWithSettingNotUpToDate(props.uwInflation[1]))}
                error={props.shouldValidate ? calculatePercentileError(props.uwInflation[0]) : ''}
                percentage
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.uwInflation[0] || 0)}
                title="Inflation"
                suffix="%"
                className="GwpCard"
              />
            )}
            {isMarketEditorView ? (
              <TextField
                className="UWAbeGGLR"
                title="UW ABE GGLR"
                value={props.uwAbeGglr[0] || 0}
                onChange={setValueIncludingSuffixShorthand(setStateWithSettingNotUpToDate(props.uwAbeGglr[1]))}
                warning={props.shouldValidate ? calculateGrossLossRatioWarning(props.uwAbeGglr[0]) : ''}
                percentage
              />
            ) : (
              <KpiCardSmall
                value={new Big(props.uwAbeGglr[0] || 0)}
                title="UW ABE GGLR"
                suffix="%"
                className="GwpCard"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketConvexAndGraphOverallContainer
