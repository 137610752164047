import './ProgressBar.scss'
import React from 'react'
import {
  ScenarioKeyPerformanceIndicators,
  TOTAL_GWP_COLUMN,
  TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
} from '../../backend/calculate-kpis'
import { isZero, ONE_HUNDRED, ZERO } from '../../utils/numbers'
import { ACTUALS_ADJUSTMENT_ID, OVERRIDDEN_BY_ACTUALS } from '../../backend/calculate-with-actuals'
import Big from 'big.js'

interface ItemsWithOrderNumberAndId {
  id: string
  orderNumber: number
  subtype?: string
}

function sortItemsByOldestFirst<InputType extends ItemsWithOrderNumberAndId>(
  inputAdjustments: InputType[],
): InputType[] {
  return [...inputAdjustments].sort((first, second) => first.orderNumber - second.orderNumber)
}

export function calculateItemsLowerThanThis<InputType extends ItemsWithOrderNumberAndId>(
  allAvailableItems: Array<InputType>,
  currentAdjustmentId: string,
  sortFunction: (array: Array<InputType>) => Array<InputType> = sortItemsByOldestFirst,
): Array<InputType> {
  const sortedAdjustments = sortFunction(allAvailableItems)

  const indexOfThisAdjustment = sortedAdjustments.findIndex((item) => item.id === currentAdjustmentId)

  if (indexOfThisAdjustment < 0) {
    return []
  }

  return sortedAdjustments.slice(0, indexOfThisAdjustment)
}

function isNewClientAdjustment(item: ItemsWithOrderNumberAndId) {
  return item.hasOwnProperty('segment')
}

export function sortItemsNewClientFirstThenThis(
  inputAdjustments: ItemsWithOrderNumberAndId[],
): ItemsWithOrderNumberAndId[] {
  const clientSpecificAdjustments = [...inputAdjustments].filter(isNewClientAdjustment)
  const otherAdjustments = [...inputAdjustments].filter((item) => !isNewClientAdjustment(item))

  return [...sortItemsByOldestFirst(clientSpecificAdjustments), ...sortItemsByOldestFirst(otherAdjustments)]
}

export function calculatePercentageOfOriginalGwpAdjustedBy(
  kpis: ScenarioKeyPerformanceIndicators | undefined,
  adjustmentIds: string[],
  kpiFieldToUse: keyof ScenarioKeyPerformanceIndicators = TOTAL_GWP_COLUMN,
  kpiPerAdjFieldtoUse: keyof ScenarioKeyPerformanceIndicators = TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
) {
  if (!kpis?.[kpiFieldToUse]) {
    return ZERO
  }

  const totalOriginalGwpAffectedByAdjustments = adjustmentIds.reduce(
    (currentTotal, nextAdjustmentId) =>
      currentTotal.plus((kpis?.[kpiPerAdjFieldtoUse] as Record<string, Big>)?.[nextAdjustmentId] || ZERO),
    ZERO,
  )

  if (isZero(totalOriginalGwpAffectedByAdjustments) || isZero(kpis?.[kpiFieldToUse] as Big)) {
    return ZERO
  }

  return totalOriginalGwpAffectedByAdjustments.div(kpis?.[kpiFieldToUse] as Big).mul(ONE_HUNDRED)
}

export function calculateCurrentAdjustmentIds(
  currentAdjustmentId: string,
  allAdjustments: ItemsWithOrderNumberAndId[],
): Array<string> {
  if (currentAdjustmentId === ACTUALS_ADJUSTMENT_ID) {
    return [OVERRIDDEN_BY_ACTUALS]
  }

  if (currentAdjustmentId === 'CLIENT_SPECIFIC_SUMMARY') {
    return allAdjustments.filter((item) => item.subtype === 'CLIENT_SPECIFIC').map((item) => item.id)
  }

  if (currentAdjustmentId === 'NEW_CLIENT_SUMMARY_ID') {
    return allAdjustments.filter((item) => item.hasOwnProperty('segment')).map((item) => item.id)
  }

  return [currentAdjustmentId]
}

export function ProgressBar(props: {
  percentageAdjustedByLowerAdjustments: Big
  percentageAdjustedByThisAdjustment: Big
  leftoverPercentage: Big
}) {
  return (
    <div className="ProgressBars">
      <div
        className="ProgressBarOne"
        style={{ width: `${props.percentageAdjustedByLowerAdjustments}%` }}
        data-tip={`${props.percentageAdjustedByLowerAdjustments}%`}
      />
      <div
        className="ProgressBarTwo"
        style={{ width: `${props.percentageAdjustedByThisAdjustment}%` }}
        data-tip={`${props.percentageAdjustedByLowerAdjustments}%`}
      />
      <div
        className="ProgressBarThree"
        style={{ width: `${props.leftoverPercentage}%` }}
        data-tip={`${props.leftoverPercentage}%`}
      />
    </div>
  )
}
