import React from 'react'
import './FiltersContainer.scss'
export type FiltersContainerProps = React.PropsWithChildren<{}>

export function FiltersContainer(props: FiltersContainerProps) {
  return (
    <div
      className="FiltersContainer"
      {...props}
    >
      {props.children}
    </div>
  )
}

export default FiltersContainer
