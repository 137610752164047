import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { fetchStackedScenariosBy, StackedScenario } from '../../backend/stacked-scenarios'
import { StackedScenariosViewContext } from '../stacked-scenarios-view/StackedScenariosViewProvider'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'

interface StackedScenarioContainer {
  currentStackedScenarios: StackedScenario[]
  reloadStackedScenarios: () => void
}

export const StackedScenarioListContext = createContext<StackedScenarioContainer>({
  currentStackedScenarios: [],
  reloadStackedScenarios: () => void 0,
})

type Props = React.PropsWithChildren<{}>

export function StackedScenarioListProvider(props: Props): ReactElement {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { currentYear, currentPortfolioLevel, currentDivision, currentTeam } = useContext(StackedScenariosViewContext)

  const [currentStackedScenarios, setStackedScenarios] = useState<StackedScenario[]>([])

  const reloadStackedScenarios = () => {
    const progressKey = `fetchStackedScenarioList-${currentYear}-${currentPortfolioLevel}-${currentDivision}-${currentTeam}`

    updateIndividualProgressItem(progressKey, ProgressState.LOADING)
    fetchStackedScenariosBy(currentYear, currentDivision, currentTeam)
      .then(
        (newListOfScenarios) => {
          const next: StackedScenario[] = newListOfScenarios?.length ? newListOfScenarios : []
          setStackedScenarios(next)
        },
        () => updateIndividualProgressItem(progressKey, ProgressState.ERROR),
      )
      .then(() => updateIndividualProgressItem(progressKey, ProgressState.FINISHED))
  }
  useEffect(reloadStackedScenarios, [
    currentYear,
    currentPortfolioLevel,
    currentDivision,
    currentTeam,
    updateIndividualProgressItem,
  ])

  return (
    <StackedScenarioListContext.Provider
      value={{
        currentStackedScenarios,
        reloadStackedScenarios,
      }}
      children={props.children}
    />
  )
}
