import { Heading } from '@chakra-ui/react'
import { ReactElement } from 'react'
import {
  SegmentSplitsTable,
  SegmentSplitsTableProps as NewCustomPortfolioSplitsProps,
} from '../segment-splits-table/SegmentSplitsTable'
import { NewCustomPortfolioAlert } from './NewCustomPortfolioAlert'
import { PortfolioSplitsTable } from '../portfolio-splits-table/PortfolioSplitsTable'

export function NewCustomPortfolioSplits(props: NewCustomPortfolioSplitsProps): ReactElement {
  return (
    <div>
      <NewCustomPortfolioAlert />
      {props.isPreview && (
        <>
          <Heading
            as="h6"
            size="sm"
            paddingBottom={'0.3rem'}
            paddingTop={'1rem'}
          >
            New Portfolio Composition
          </Heading>
          <p>Enter values below to customise the segment and portfolio splits for new business</p>
        </>
      )}
      <SegmentSplitsTable {...props} />
      <PortfolioSplitsTable />
    </div>
  )
}
