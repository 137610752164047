import React, { useContext } from 'react'
import { Card } from '../card/Card'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { Link } from 'react-router-dom'
import './ScenarioNavigationMenuItem.scss'
import { addOnNeededQueryParams } from '../navigation-menu/NavigationMenu'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import {
  AvailableScenarioChooserPages,
  PATHS_FOR_SCENARIO_PAGES,
} from '../../pages/scenario-chooser/OutwardOrInwardToggle'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { ActualsContext } from '../../providers/ActualDataChoiceProvider'

export type ScenarioNavigationMenuItemProps = React.PropsWithChildren<{}>

export function formatEnumForDisplay(value: string): string {
  const partsOfEnum = value.split('_')

  return partsOfEnum
    .map((currentPart) => {
      const allLowerCase = currentPart.toLowerCase()

      return allLowerCase.slice(0, 1).toUpperCase() + allLowerCase.slice(1)
    })
    .join(' ')
}

export function ScenarioNavigationMenuItem(props: ScenarioNavigationMenuItemProps): JSX.Element {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { actualsQuery } = useContext(ActualsContext)

  const [actualDataItemName] = actualsQuery

  if (!currentScenario && !currentStackedScenario && !currentOutwardScenario) {
    return <></>
  }
  const inwardsScenarioUrl = addOnNeededQueryParams(
    PATHS_FOR_SCENARIO_PAGES[AvailableScenarioChooserPages.INWARD],
    currentScenario || currentStackedScenario,
    actualDataItemName,
  )
  const outwardsScenarioUrl = PATHS_FOR_SCENARIO_PAGES[AvailableScenarioChooserPages.OUTWARD]

  return (
    <Link
      to={!!currentOutwardScenario ? outwardsScenarioUrl : inwardsScenarioUrl}
      className="ScenarioNavigationMenuItem"
      {...props}
    >
      {props.children}
      <h4 className="ScenarioNavigationMenuItemTitle">
        {currentStackedScenario ? `${formatEnumForDisplay(currentStackedScenario.scenarioLevel)} Scenario` : 'Scenario'}
      </h4>
      <Card>
        {currentScenario && (
          <p className="ScenarioNavigationMenuItemSubtitle">
            {currentScenario.year} / {currentScenario.division} / {currentScenario.team} / {currentScenario.market}
          </p>
        )}
        {currentOutwardScenario && (
          <p className="ScenarioNavigationMenuItemSubtitle">
            {currentOutwardScenario.year} / {currentOutwardScenario.programme} / {currentOutwardScenario.treaty} /{' '}
            {currentOutwardScenario.layer}
          </p>
        )}
        <h3 className="ScenarioNavigationMenuItemTitle">
          {currentScenario?.name || currentStackedScenario?.name || currentOutwardScenario?.name || 'Select Scenario'}
        </h3>
      </Card>
    </Link>
  )
}

export default ScenarioNavigationMenuItem
