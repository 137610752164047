import AwesomeDebouncePromise from 'awesome-debounce-promise'

import { getCurrentConfigSettings } from '../utils/config'
import { Adjustment } from './adjustments'
import { NewClient } from './new-clients'
import { NewAdjustment } from './api-types/NewAdjustment.types'
import { getAccessToken } from './teams'

export interface FullScenarioDataRequest {
  forceRegenRenewal?: boolean
  previewRenewal?: Adjustment | null
  forceRegenNewClient?: boolean
  previewNewClient?: NewClient | null
  forceRegenVirtual?: boolean
  previewNewVirtual?: NewAdjustment | null
  actualsNameToUse?: string | null
  dataSource: 'Snowflake' | string
}

async function fetchFullScenarioData(id: string, requestOptions: FullScenarioDataRequest): Promise<any[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${id}/data/full`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(requestOptions),
  })

  if (response.status >= 400) {
    return Promise.reject()
  }

  return await response.json()
}

async function fetchFullStacked(id: string, requestOptions: FullScenarioDataRequest): Promise<any[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios/${id}/data/full`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(requestOptions),
  })

  if (response.status >= 400) {
    return Promise.reject()
  }

  return await response.json()
}

async function fetchFullStackedScenarioData(stackedScenarioId: string): Promise<{ downloadLink: string }> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/stacked-scenarios/${stackedScenarioId}/data/full-via-s3-cache`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status >= 400) {
    return Promise.reject()
  }

  return await response.json()
}

async function fetchFullOutwardsStackedScenarioData(stackedScenarioId: string): Promise<{ downloadLink: string }> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/stacked-outward-scenarios/${stackedScenarioId}/data/full-via-s3-cache`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status >= 400) {
    return Promise.reject()
  }

  return await response.json()
}

async function fetchUniqueValuesForColumnInScenario(
  id: string,
  columnName: string,
  requestOptions: FullScenarioDataRequest,
): Promise<string[] | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${id}/data/valuesForColumn/${columnName}`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(requestOptions),
    },
  )

  if (response.status >= 400) {
    return Promise.reject()
  }

  const bodyObject: { allValuesForColumn: string[] } = await response.json()

  return bodyObject.allValuesForColumn
}

const debouncedFetch: (id: string, requestOptions: FullScenarioDataRequest) => Promise<any[]> = AwesomeDebouncePromise(
  fetchFullScenarioData,
  300,
  { key: (args) => args },
)

const debouncedStackedFetch: (id: string) => Promise<{ downloadLink: string }> = AwesomeDebouncePromise(
  fetchFullStackedScenarioData,
  300,
  {
    key: (args) => args,
  },
)

const debouncedOutwardsStackedFetch: (id: string) => Promise<{ downloadLink: string }> = AwesomeDebouncePromise(
  fetchFullOutwardsStackedScenarioData,
  300,
  {
    key: (args) => args,
  },
)

export {
  debouncedFetch as fetchFullScenarioData,
  fetchFullStacked, //
  fetchUniqueValuesForColumnInScenario,
  debouncedStackedFetch as fetchFullStackedScenarioData,
  debouncedOutwardsStackedFetch as fetchFullOutwardsStackedScenarioData,
}
