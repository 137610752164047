import { FormProvider } from 'react-hook-form'
import { useCategoryForm } from './hooks/useCategoryForm'
import { DynamicCategoryTable, UserSelectsColumns } from './DynamicCategoryTable'
import { useContext, useMemo } from 'react'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { MultiSelectDropdownMenu } from '../multi-select-dropdown-chakra/MultiSelectDropdownMenu'
import { exposureRegionsReferenceData } from './data/reference'
import { ExposureRegionFields } from './types'

export const ExposureRegionTable = () => {
  const form = useCategoryForm<ExposureRegionFields>({
    defaultValues: {
      exposureRegions: [],
    },
  })
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)

  const allOptions = useMemo(
    () => exposureRegionsReferenceData.map((x) => ({ label: x })),
    [exposureRegionsReferenceData],
  )

  return (
    <FormProvider {...form}>
      <DynamicCategoryTable<ExposureRegionFields, UserSelectsColumns>
        categoryLabel="Exposure Region"
        categoryName="exposureRegion"
        dynamicFieldsName="exposureRegions"
        columnsOptions={allOptions}
        ContentEditor={MultiSelectDropdownMenu}
        required
      />
    </FormProvider>
  )
}
