import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { fetchNotes, updateNote } from '../../backend/notes'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { getOnlyResolvedPromises } from '../../utils/Promises'

export enum NoteSectionNames {
  GENERAL = 'General',
  MARKET_GENERAL = 'Market - General',
  MARKET_CLIMATE_CHANGE = 'Market - Climate Change',
  MARKET_COMMENTARY = 'Market Commentary',
  PEAK_CONVEX_VISION = 'Peak Convex Vision',
  UW_COMMENTARY_ON_ABE = 'UW Commentary on ABE',
  PARAM_GENERAL = 'Notes',
}

export type NoteSectionNamesKeys = keyof typeof NoteSectionNames

export function isNoteSectionNames(value: any): value is NoteSectionNamesKeys {
  return Object.keys(NoteSectionNames).includes(value)
}

export interface NotesDataContainer {
  currentSectionValues: Partial<Record<NoteSectionNamesKeys, string>>
  updateSectionValueInState: (sectionIdentifier: NoteSectionNamesKeys, newSectionValue: string) => void
  triggerReloadOfNotes: () => void
  triggerSaveOfNotes: () => void
  notesAreUpToDateWithRemote: boolean
  setNotesAreUpToDateWithRemote: (newValue: boolean) => void
  segmentsToDisplay: Array<[sectionIdentifier: string, value: string]>
}

export const NotesDataContext = createContext<NotesDataContainer>({
  currentSectionValues: {},
  updateSectionValueInState: () => void 0,
  setNotesAreUpToDateWithRemote: () => void 0,
  triggerSaveOfNotes: () => void 0,
  triggerReloadOfNotes: () => void 0,
  notesAreUpToDateWithRemote: true,
  segmentsToDisplay: [],
})

type NotesDataProviderProps = React.PropsWithChildren<{
  segmentsToDisplay?: Array<NoteSectionNames>
  idToUse?: string
}>

export function NotesDataProvider(props: NotesDataProviderProps) {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  const idToUse = useMemo(
    () => props.idToUse || currentScenario?.id || currentStackedScenario?.id || currentOutwardScenario?.id,
    [currentScenario, currentStackedScenario, currentOutwardScenario, props.idToUse],
  )

  const [currentSectionValues, setAllSectionValues] = useState<Partial<Record<NoteSectionNamesKeys, string>>>({})
  const [isUpToDateWithRemote, setIsUpToDateWithRemote] = useState(true)

  const segmentsToUse = props.segmentsToDisplay
    ? Object.entries(NoteSectionNames).filter(([, value]) => props.segmentsToDisplay?.includes(value))
    : Object.entries(NoteSectionNames)

  const updateSectionValueInState = (sectionIdentifier: NoteSectionNamesKeys, newSectionValue: string) => {
    setAllSectionValues(() => {
      return {
        ...currentSectionValues,
        [sectionIdentifier]: newSectionValue,
      }
    })

    setIsUpToDateWithRemote(false)
  }

  const saveAllSections = () => {
    const allSavePromises = Object.entries(currentSectionValues).map(
      ([currentSectionIdentifier, currentSectionValue]) => {
        if (!idToUse) {
          return undefined
        }

        if (!currentSectionValue) {
          return undefined
        }

        return updateNote(idToUse, currentSectionIdentifier, {
          content: currentSectionValue,
          noteIdentifier: currentSectionIdentifier,
          entityTypeReference: currentScenario
            ? 'Scenario'
            : currentStackedScenario
            ? 'StackedScenario'
            : currentOutwardScenario
            ? 'OutwardsScenario'
            : 'Unknown',
        })
      },
    )

    Promise.all(allSavePromises).then(() => setIsUpToDateWithRemote(true))
  }

  const reloadNotesFromRemote = async () => {
    if (!idToUse) {
      return
    }

    const allFetchPromises = segmentsToUse.map(([sectionIdentifier]) => {
      return fetchNotes(idToUse, sectionIdentifier)
    })

    const res = await getOnlyResolvedPromises(allFetchPromises)
    const sectionUpdates: Partial<Record<NoteSectionNamesKeys, string>> = {}
    res.forEach((note) => {
      if (!note) {
        console.log('Note is undefined')
        return
      }

      if (!isNoteSectionNames(note.noteIdentifier)) {
        console.log('Unknown note section type: ' + note.noteIdentifier)
        return
      }

      sectionUpdates[note.noteIdentifier] = note.content
    })

    setAllSectionValues(sectionUpdates)
    setIsUpToDateWithRemote(true)
  }

  useEffect(() => {
    ;(async () => reloadNotesFromRemote())()
  }, [idToUse])

  return (
    <NotesDataContext.Provider
      value={{
        notesAreUpToDateWithRemote: isUpToDateWithRemote,
        setNotesAreUpToDateWithRemote: setIsUpToDateWithRemote,
        triggerReloadOfNotes: reloadNotesFromRemote,
        triggerSaveOfNotes: saveAllSections,
        currentSectionValues: currentSectionValues,
        updateSectionValueInState: updateSectionValueInState,
        segmentsToDisplay: segmentsToUse,
      }}
    >
      {props.children}
    </NotesDataContext.Provider>
  )
}
