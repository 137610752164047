import React, { createContext, useContext, useEffect, useState } from 'react'
import { groupListOfObjsBy } from '../../utils/lists'
import { calculateKPIsAsNumbers, TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_GWP_COLUMN } from '../../backend/calculate-kpis'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { sumListAsIntegers } from '../../utils/numbers'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'

const GraphDataContext = createContext<GraphData>({
  summarisedData: [],
  fieldNameToGroupBy: '',
  formatDataFieldForDisplay: () => '',
})

const { Consumer, Provider } = GraphDataContext

export interface GraphData {
  summarisedData: any[]
  fieldNameToGroupBy: string
  sortDataFunction?: (dataList: any[]) => any[]
  formatDataFieldForDisplay?: (value: string | number | Date) => string
  legendLabel?: string
}

type GraphDataProviderProps = React.PropsWithChildren<{
  fieldNameToGroupBy: string
  sortDataFunction?: (dataList: any[]) => any[]
  formatDataFieldForDisplay?: (value: string | number | Date) => string
  legendLabel?: string
  numberOfItemsToInclude?: number
  hideOthers?: boolean
  fieldsToOrderBy?: [string, string]
}>

export function aggregateIntoTopNAndOtherCategoryWithTwoValueFields(
  inputValues: Array<any>,
  fieldsToUse: [string, string],
  nameFieldOfObject: string,
  numberToKeep: number,
): Array<any> {
  const valuesAsSortedArray = [...inputValues]
  valuesAsSortedArray.sort((first, second) => second[fieldsToUse[0]] - first[fieldsToUse[0]])

  if (inputValues.length <= numberToKeep) {
    return valuesAsSortedArray
  }

  const topNValues = valuesAsSortedArray.slice(0, numberToKeep)
  const otherValues = valuesAsSortedArray.slice(numberToKeep, valuesAsSortedArray.length)

  const firstTotal = sumListAsIntegers(otherValues, fieldsToUse[0])
  const secondTotal = sumListAsIntegers(otherValues, fieldsToUse[1])

  return [
    ...topNValues,
    {
      [nameFieldOfObject]: 'Others',
      [fieldsToUse[0]]: firstTotal.toNumber(),
      [fieldsToUse[1]]: secondTotal.toNumber(),
    },
  ]
}

export const convertGroupedDataToGraphFormat = (
  groupedData: { [key: string]: any },
  fieldNameToGroupBy: string,
  numberOToInclude: number = 12,
  fieldsToOrderBy: [string, string] = [TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_GWP_COLUMN],
) => {
  const allDataPoints = Object.entries(groupedData).map(([fieldValueThatWasGroupedBy, listOfData]) => {
    return {
      allOtherData: listOfData,
      [fieldNameToGroupBy]: fieldValueThatWasGroupedBy,
      ...calculateKPIsAsNumbers(listOfData),
    }
  })

  return aggregateIntoTopNAndOtherCategoryWithTwoValueFields(
    allDataPoints,
    fieldsToOrderBy,
    fieldNameToGroupBy,
    numberOToInclude,
  )
}

const GraphDataProvider = ({
  fieldNameToGroupBy,
  sortDataFunction,
  formatDataFieldForDisplay,
  legendLabel,
  children,
  numberOfItemsToInclude,
  hideOthers,
  fieldsToOrderBy,
}: GraphDataProviderProps) => {
  const [summarisedData, setSummarisedData] = useState<any[]>([])
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const jsonVersionOfOrderedFields = JSON.stringify(fieldsToOrderBy)

  useEffect(() => {
    updateIndividualProgressItem('renewalGraphUpdate', ProgressState.LOADING)
    let dataReadyForGraph
    const groupedData = groupListOfObjsBy(filteredDataToUseInGraph, fieldNameToGroupBy)
    dataReadyForGraph = convertGroupedDataToGraphFormat(
      groupedData,
      fieldNameToGroupBy,
      numberOfItemsToInclude,
      fieldsToOrderBy,
    )
    let sortedList = sortDataFunction ? sortDataFunction(dataReadyForGraph) : dataReadyForGraph.sort()
    if (hideOthers) {
      sortedList = sortedList.filter((item) => item[fieldNameToGroupBy] !== 'Others')
    }
    setSummarisedData(sortedList)
    updateIndividualProgressItem('renewalGraphUpdate', ProgressState.FINISHED)
    //eslint-disable-next-line
  }, [
    updateIndividualProgressItem,
    fieldNameToGroupBy,
    numberOfItemsToInclude,
    hideOthers,
    filteredDataToUseInGraph,
    jsonVersionOfOrderedFields,
  ])

  return (
    <Provider
      value={{
        summarisedData,
        fieldNameToGroupBy,
        sortDataFunction,
        formatDataFieldForDisplay,
        legendLabel,
      }}
      children={children}
    />
  )
}

export { Consumer as GraphDataConsumer, GraphDataProvider, GraphDataContext }
