import React, { SVGProps } from 'react'

const StackedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(1.000000, 1.000000)">
        <polygon
          id="Path"
          points="0 0 14 0 14 14 0 14"
        ></polygon>
        <path
          d="M1.75,2.91666667 L0.583333333,2.91666667 L0.583333333,12.25 C0.583333333,12.8916667 1.10833333,13.4166667 1.75,13.4166667 L11.0833333,13.4166667 L11.0833333,12.25 L1.75,12.25 L1.75,2.91666667 Z M12.25,0.583333333 L4.08333333,0.583333333 C3.44166667,0.583333333 2.91666667,1.10833333 2.91666667,1.75 L2.91666667,9.91666667 C2.91666667,10.5583333 3.44166667,11.0833333 4.08333333,11.0833333 L12.25,11.0833333 C12.8916667,11.0833333 13.4166667,10.5583333 13.4166667,9.91666667 L13.4166667,1.75 C13.4166667,1.10833333 12.8916667,0.583333333 12.25,0.583333333 Z M12.25,9.91666667 L4.08333333,9.91666667 L4.08333333,1.75 L12.25,1.75 L12.25,9.91666667 Z"
          id="Shape"
          fill="#4E566B"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default StackedIcon
