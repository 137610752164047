import { RouteComponentProps, withRouter } from 'react-router'
import { ReactElement, useContext, useEffect, useReducer } from 'react'

import './Exposures.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { ExposuresOldContent } from './ExposuresOldContent'
import { createInitialPageState, reducer } from './ExposuresState'
import { ExposuresSwitch } from './ExposuresSwitch'
import { ExposuresList } from '../exposures-list/ExposuresList'
import { ExposureGrowth, isOutputType } from '../../backend/exposures'
import { fetchExposuresTemplateIdByScenarioId } from '../../backend/exposures-V2'
import { ExposureMissingTemplate } from './ExposuresMissingTemplate'
import { ExposuresLoading } from './ExposuresLoading'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { RawEntry } from '../../backend/rawEntry'
import { WF_TYPE_OF_DATA_COLUMN } from '../../backend/TypeOfData'
import { ExposuresError } from './components/ExposuresError'
import { NewExposuresReport } from './NewExposureReport'
import ExisitingExposuresReport from './ExisitingExposuresReport'

type Props = RouteComponentProps<{ scenarioId: undefined | string }>

function ExposuresView(props: Props): ReactElement {
  const { exposuresV2 } = useFlags()
  const [exposuresState, exposuresDispatch] = useReducer(reducer, createInitialPageState())
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  const { scenarioId } = props.match.params

  useEffect(() => {
    ;(async () => {
      try {
        if (!scenarioId) {
          const message = `Missing scenarioId`
          exposuresDispatch({ type: 'toErrorPage', payload: { message } })
          return
        }
        const res = await fetchExposuresTemplateIdByScenarioId(scenarioId)

        if (res.id === '' || res.defaultValues.length === 0) {
          exposuresDispatch({ type: 'toMissingTemplate' })
          return
        }

        const ylt_dvid = res.defaultValues[0].YLT_DVID
        const asofdate = res.defaultValues[0].ASOFDATE

        exposuresDispatch({ type: 'setYltAndAsofdate', payload: { ylt_dvid, asofdate } })
        exposuresDispatch({ type: 'setMarket', payload: { market: res.market } })

        exposuresDispatch({ type: 'toList' })
      } catch (err: unknown) {
        console.error(err)

        if (err instanceof Error) {
          exposuresDispatch({ type: 'toErrorPage', payload: { message: err.message } })
          return
        }

        exposuresDispatch({
          type: 'toErrorPage',
          payload: { message: `An unknown error occurred whilst trying to load exposures` },
        })
      }
    })()
  }, [])

  useEffect(() => {
    const [exposuresData, isValid] = extractDataForExposuresCalculation(fullDataForScenario)
    exposuresDispatch({ type: 'loadExposuresCalculationData', payload: { data: exposuresData, isValid } })
  }, [fullDataForScenario])

  if (!exposuresV2) {
    return <ExposuresOldContent />
  }

  if (!scenarioId) {
    throw new Error('Something has seriously gone wrong if we reach this point')
  }

  return (
    <div className="Exposures">
      <ExposuresSwitch
        stateMap={{
          err: <ExposuresError />,
          loading: <ExposuresLoading />,
          missingTemplate: <ExposureMissingTemplate />,
          list: (
            <ExposuresList
              scenarioId={scenarioId}
              changePage={exposuresDispatch}
              canCreateNew={exposuresState.canCreateNew}
            />
          ),
          newReport: (
            <NewExposuresReport
              exposuresDispatch={exposuresDispatch}
              exposuresState={exposuresState}
              scenarioId={scenarioId}
              fullDataForScenario={fullDataForScenario}
            />
          ),
          report: (
            <ExisitingExposuresReport
              exposuresDispatch={exposuresDispatch}
              exposuresState={exposuresState}
              scenarioId={scenarioId}
            />
          ),
        }}
        pageType={exposuresState.page}
      />
    </div>
  )
}

function extractDataForExposuresCalculation(fullDataForScenario: RawEntry[]): [ExposureGrowth, boolean] {
  const exposureGrowth: ExposureGrowth = {
    outputType: Array(fullDataForScenario.length),
    convexGWP: Array(fullDataForScenario.length),
    convexGWPWithBasicRetention: Array(fullDataForScenario.length),
    riskPremiumChange: Array(fullDataForScenario.length),
    grossRiskAdjustedRateChange: Array(fullDataForScenario.length),
    gwpChange: Array(fullDataForScenario.length),
  }

  let isValid = false
  let isEmpty = true
  for (let i = 0; i < fullDataForScenario.length; i++) {
    const outputType: string | undefined = fullDataForScenario[i][WF_TYPE_OF_DATA_COLUMN]
    if (outputType !== undefined && isOutputType(outputType)) {
      exposureGrowth.outputType[i] = outputType
    }

    let curVal: string | undefined = fullDataForScenario[i]['Convex GWP']
    if (curVal) {
      const v = parseFloat(curVal)
      if (v !== 0) {
        isEmpty = false
      }
      exposureGrowth.convexGWP[i] = v
      isValid = true
    }

    curVal = fullDataForScenario[i]['WF Adjusted Convex GWP with Basic Retention']
    if (curVal) {
      const v = parseFloat(curVal)
      if (v !== 0) {
        isEmpty = false
      }
      exposureGrowth.convexGWPWithBasicRetention[i] = v
      isValid = true
    }

    curVal = fullDataForScenario[i]['WF Adjustment 100% Risk Premium Change']
    if (curVal) {
      const v = parseFloat(curVal)
      if (v !== 0) {
        isEmpty = false
      }
      exposureGrowth.riskPremiumChange[i] = v
      isValid = true
    }

    curVal = fullDataForScenario[i]['WF Gross Risk Adjusted Rate Change']
    if (curVal) {
      const v = parseFloat(curVal)
      if (v !== 0) {
        isEmpty = false
      }
      exposureGrowth.grossRiskAdjustedRateChange[i] = v
      isValid = true
    }

    curVal = fullDataForScenario[i]['WF Adjustment 100% GWP Change']
    if (curVal) {
      const v = parseFloat(curVal)
      if (v !== 0) {
        isEmpty = false
      }
      exposureGrowth.gwpChange[i] = v
      isValid = true
    }
  }

  if (isEmpty) {
    isValid = false
  }

  return [exposureGrowth, isValid]
}

export const Exposures = (): ReactElement => {
  const Wrapper = withRouter(ExposuresView)
  return <Wrapper />
}
