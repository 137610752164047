import { useContext } from 'react'
import { withRouter } from 'react-router'
import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import './Portfolio.scss'
import PortfolioDataTable from '../../components/portfolio-data-table/PortfolioDataTable'
import Button from '../../components/button/Button'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import { filterOutColumnsWeDontWant } from '../../utils/outputs/outputFilter'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { OutwardScenarioRuleProvider } from '../../providers/outwards/OutwardScenarioRulesProvider'
import { OutwardsDataProvider } from '../../providers/outwards/OutwardsDataProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { FixedStackedScenarioProvider } from '../../providers/StackedScenarioProvider'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import Spacer from '../../components/spacer/Spacer'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import SplitContentContainer from '../../components/split-content-container/SplitContentContainer'

function OutwardScenarioPortfolio() {
  const { fullDataForScenario, filteredDataForScenario } = useContext(FullScenarioDataContext)

  useResetFiltersOnFirstRender()

  if (!filteredDataForScenario) {
    return (
      <div className="PortfolioDataView">
        <PageHeaderContainer></PageHeaderContainer>
        <SelectScenarioMessage />
      </div>
    )
  }

  const downloadCsv = () => {
    const allRawLines = createCsvFile(filterOutColumnsWeDontWant(fullDataForScenario))
    saveDataToFile('Full data', allRawLines)
  }

  return (
    <div className="OutwardDataView">
      <PageHeaderContainer>
        <Spacer />
        <Button
          title={'Get CSV'}
          onClick={downloadCsv}
        />
      </PageHeaderContainer>
      <SplitContentContainer
        leftcolumn={
          <FiltersContainer>
            <ScenarioFilterCard />
          </FiltersContainer>
        }
        rightcolumn={
          <div className="PortfolioDataTable">
            <PortfolioDataTable />
          </div>
        }
      />
    </div>
  )
}

const OutwardPortfolioDataViewWithProgress = () => {
  const outwardScenarioContext = useContext(OutwardScenarioContext)

  return (
    <FixedStackedScenarioProvider stackedScenarioId={outwardScenarioContext?.inwardScenarioIdToUse || ''}>
      <StackedScenarioDataProvider>
        <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
          <OutwardScenarioRuleProvider>
            <OutwardsDataProvider>
              <OutwardScenarioPortfolio />
            </OutwardsDataProvider>
          </OutwardScenarioRuleProvider>
        </RenewalGraphFilterDataProvider>
      </StackedScenarioDataProvider>
    </FixedStackedScenarioProvider>
  )
}

export default withRouter(OutwardPortfolioDataViewWithProgress)
