import { useContext, useEffect } from 'react'
import { FilterContext } from './FilterProvider'

export const useResetFiltersOnFirstRender = () => {
  const { onChangeFilters } = useContext(FilterContext)

  useEffect(() => {
    onChangeFilters({})
  }, [])
}
