import qs from 'qs'

import { getCurrentConfigSettings } from '../utils/config'
import { ScenarioStatus } from '../utils/status/ScenarioStatus'
import { Scenario } from './scenarios'
import { getAccessToken } from './teams'

export interface StackedScenario {
  id: string
  name: string
  description: string
  division: string
  team: string
  scenarioLevel: ScenarioLevel
  subScenarioIds: string[]
  updatedAt: string
  year: string
  status: ScenarioStatus
  subStatuses: Record<ScenarioStatus, number>
  latestSignOffDate: string
}

export interface PatchScenarioRequest {
  name?: string
  description?: string
  division?: string | null
  team?: string | null
  scenarioLevel?: ScenarioLevel
  subScenarioIds?: string[]
}

export interface CreateStackedScenarioRequest {
  name: string
  description: string
  division?: string | null
  team?: string | null
  scenarioLevel: ScenarioLevel
  subScenarioIds: string[]
  year: string
}

export enum ScenarioLevel {
  ORGANISATIONAL = 'ORGANISATIONAL',
  DIVISIONAL = 'DIVISIONAL',
  TEAM = 'TEAM',
}

export async function patchStackedScenario(scenarioId: string, patchScenarioRequest: PatchScenarioRequest) {
  try {
    const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios/${scenarioId}`, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchScenarioRequest),
    })

    return await response.json()
  } catch (err: unknown) {
    console.error(err)
  }
}

export async function fetchStackedScenarioById(id: string): Promise<StackedScenario | undefined> {
  if (id === undefined) {
    return undefined
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios/${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status === 200) {
    return await response.json()
  }

  return undefined
}

export async function fetchLeavesForStackedScenario(id: string): Promise<Array<Scenario | null | undefined>> {
  if (id === undefined) {
    return []
  }
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios/search/leaves/${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}

export async function fetchStackedScenariosBy(
  year?: string | null,
  division?: string | null,
  team?: string | null,
): Promise<StackedScenario[]> {
  let queryContent: Record<string, string | null | undefined> = { year }

  let contextPath = '/stacked-scenarios/search/organisation'

  if (division) {
    queryContent = { ...queryContent, division }
    contextPath = '/stacked-scenarios/search/division'
  }

  if (division && team) {
    queryContent = { ...queryContent, division, team }
    contextPath = '/stacked-scenarios/search/division-team'
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}${contextPath}?${qs.stringify(queryContent)}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}

export async function createStackedScenario(csr: CreateStackedScenarioRequest) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ ...csr }),
  })

  return (await response.json()).id
}

export async function deleteStackedScenario(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/stacked-scenarios/${scenarioId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  return Promise.resolve()
}
