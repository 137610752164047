import { useCallback, useContext, useEffect, useMemo } from 'react'
import { RouterProps, withRouter } from 'react-router'
import qs from 'qs'
import { StringParam, useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'

import './ScenarioComparison.scss'

import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import PageHeaderText from '../../components/page-header-text/PageHeaderText'
import ContentContainer from '../../components/content-container/ContentContainer'
import { FixedScenarioProvider, ScenarioContext } from '../../providers/ScenarioProvider'
import { RenewalAdjustmentContext } from '../../providers/RenewalAdjustmentsProvider'
import { PseudoPortfolioContext } from '../../providers/NewPseudoPortfolioItemsProvider'
import { ScenarioComparisonRows } from '../../components/scenario-comparison/ScenarioComparisonRows'
import { CssGridItem } from '../../components/css-grid/CssGridRows'
import { ScenarioComparisonHeadersColumn } from '../../components/scenario-comparison/ScenarioComparisonHeadersColumn'
import {
  FullScenarioDataContext,
  FullScenarioDataProvider,
} from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { MarketDataContextV2 } from '../../providers/MarketDataProviderV2'
import Button from '../../components/button/Button'
import { GridContainer } from '../../components/css-grid/GridContainer'
import {
  ScenarioComparisonDataSwallower,
  ScenarioComparisonFullDataAvailableProvider,
} from '../../providers/ScenarioComparisonFullDataAvailableProvider'
import { FilterProvider } from '../../providers/FilterProvider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { GraphStyleProvider } from '../../components/renewal-graph/GraphStyleProvider'
import Graphs from '../../components/graphs/Graphs'
import { GraphContainer } from '../../components/graph-container/GraphContainer'
import { GraphHeader } from '../../components/graph-header/GraphHeader'
import { ComparisonGwpCumulativeLineChartDataProvider } from '../../components/renewal-graph/gwp-cumulative-line-chart/GwpCumulativeLineChartDataProvider'
import { GwpAndAdjustedGwpCumulativeLineChart } from '../../components/renewal-graph/gwp-cumulative-line-chart/GwpAndAdjustedGwpCumulativeLineChart'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { NewBusinessTypeWithCustomPortfolio } from '../new/New'

function getScenarioIds(searchString: string) {
  const queryParamWithoutLeadingQMark = searchString.slice(1)
  const parsedQueryParam = qs.parse(queryParamWithoutLeadingQMark)['scenarioIds']
  const scenarioIds: Array<string> =
    typeof parsedQueryParam === 'string' ? [parsedQueryParam] : (parsedQueryParam as string[])

  return scenarioIds
}

function ScenarioComparisonColumnContent(props: { scenarioId: string; columnNumber: number }) {
  const scenarioContainer = useContext(ScenarioContext)
  const fullScenarioContext = useContext(FullScenarioDataContext)
  const renewalAdjustmentContext = useContext(RenewalAdjustmentContext)
  const pseudoPortfolioContext = useContext(PseudoPortfolioContext)
  const marketContext = useContext(MarketDataContextV2)
  const { businessQuery } = useContext(AppQueryContext)
  const [currentBusinessType, setBusinessType] = businessQuery
  const setDefaultView = useCallback(() => {
    setBusinessType(NewBusinessTypeWithCustomPortfolio.VIRTUAL)
  }, [setBusinessType])
  useEffect(() => {
    if (!currentBusinessType) {
      setDefaultView()
    }
  }, [setDefaultView, currentBusinessType])

  return (
    <>
      {ScenarioComparisonRows.map((currentRow, currentRowIndex) => (
        <CssGridItem
          columnNumber={props.columnNumber}
          rowNumber={currentRowIndex + 1}
          key={`${props.scenarioId}-${currentRow.title}-value`}
          classNameOverride={currentRow.customCssClassName}
          additionalCssClassNames={currentRow.additionalCssClassNames}
        >
          {currentRow.perScenarioContentGenerator({
            scenarioContainer,
            fullScenarioContext,
            renewalAdjustmentContext,
            pseudoPortfolioContext,
            marketContext,
            currentBusinessType,
            setBusinessType,
          })}
        </CssGridItem>
      ))}
    </>
  )
}

interface ScenarioComparisonProps {
  scenarioId: string
  columnNumber: number
}

function ScenarioComparisonColumnWithProviders(props: ScenarioComparisonProps) {
  //FullScenarioDataProvider here is overriding ScenarioComparisonFullDataAvailableProvider
  return (
    <FixedScenarioProvider scenarioId={props.scenarioId}>
      <FullScenarioDataProvider>
        <ScenarioComparisonDataSwallower />
        <ScenarioComparisonColumnContent {...props} />
      </FullScenarioDataProvider>
    </FixedScenarioProvider>
  )
}

function ScenarioComparison(props: RouterProps) {
  const scenarioIds = useMemo<Array<string>>(
    () => getScenarioIds(props.history.location.search),
    [props.history.location.search],
  )

  const [actualDataItemName] = useQueryParam('actuals', StringParam)
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  const { yearQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  const backToHomePage = useMemo(
    () => () => {
      const queryParams = {
        division: divisionFromQuery,
        team: teamFromQuery,
        year: yearFromQuery,
        actuals: actualDataItemName,
        portfoliolevel: 'Divisional',
      }

      const fullRoute = `/scenario-chooser?${qs.stringify(queryParams)}`
      props.history.push(fullRoute)
    },
    [divisionFromQuery, teamFromQuery, yearFromQuery, actualDataItemName, props.history],
  )

  return (
    <div className="ScenarioComparison">
      <FilterProvider>
        <ScenarioComparisonFullDataAvailableProvider>
          <RenewalGraphFilterDataProvider onesToIgnore={[]}>
            <PageHeaderContainer>
              <PageHeaderText>Scenario Comparison</PageHeaderText>
              <Button
                title={'Back'}
                onClick={backToHomePage}
              />
            </PageHeaderContainer>
            <ContentContainer>
              {!graphPanelIsOpen && (
                <GridContainer>
                  <ScenarioComparisonHeadersColumn />
                  {scenarioIds.map((currentScenarioId, currentScenarioIndex) => (
                    <ScenarioComparisonColumnWithProviders
                      scenarioId={currentScenarioId}
                      key={currentScenarioId}
                      columnNumber={currentScenarioIndex + 2}
                    />
                  ))}
                </GridContainer>
              )}
              {graphPanelIsOpen && (
                <GraphStyleProvider>
                  <Graphs>
                    <GraphContainer
                      className="GraphRight"
                      graphId="OriginalVsAdjustedGwp"
                    >
                      <GraphHeader title="Original vs Adjusted GWP" />
                      <div className="Graph CumulativeLineChart">
                        <ComparisonGwpCumulativeLineChartDataProvider>
                          <GwpAndAdjustedGwpCumulativeLineChart />
                        </ComparisonGwpCumulativeLineChartDataProvider>
                      </div>
                    </GraphContainer>
                  </Graphs>
                </GraphStyleProvider>
              )}
            </ContentContainer>
          </RenewalGraphFilterDataProvider>
        </ScenarioComparisonFullDataAvailableProvider>
      </FilterProvider>
    </div>
  )
}

export default withRouter(ScenarioComparison)
