import { NcpError } from '../../new-custom-portfolio-splits/NewCustomPortfolioAlertState'
import { isNumber } from '../../../utils/numbers'

export const getErrorField = (errorField: string): string => {
  const errorFieldDictionary = {
    '\\bgwpPercent\\b': 'GWP %',
    '\\bgwp\\b': 'GWP $',
    '\\btotalGwpPercent\\b': 'Total GWP %',
    '\\beulrPercent\\b': 'EULR %',
    '\\bwrittenLinePercent\\b': 'Written Line %',
    '\\bacqCostPercent\\b': 'Acq. Cost %',
    '\\bgrarcPercent\\b': 'GRARC %',
    '\\bavgGwpPerLayer\\b': 'Average GWP per layer $',
    '\\bavgConvexLimit\\b': 'Average Convex Limit $',
    '\\bavgAttachmentPoint\\b': 'Average Attachment Point $',
    '\\btotalGwp\\b': 'Total GWP $',
    '\\btotalGrarcPercent\\b': 'Total GRARC %',
    '\\btotalAvgGwpPerLayer\\b': 'Total Average GWP per layer $',
    '\\btotalAvgConvexLimit\\b': 'Total Average Convex Limit $',
    '\\btotalAvgAttachmentPoint\\b': 'Total Average Attachment Point $',
    '\\btotalEulrPercent\\b': 'Total EULR %',
    '\\btotalWrittenLinePercent\\b': 'Total Written Line %',
    '\\btotalAcqCostPercent\\b': 'Total Acq. Cost %',
  }

  for (const key in errorFieldDictionary) {
    if (new RegExp(key).test(errorField)) {
      return errorFieldDictionary[key as keyof typeof errorFieldDictionary]
    }
  }
  return ''
}

export function transformErrorMessages(errors: string[]): NcpError {
  const fields: Set<string> = new Set<string>()
  const uniqueMessages: string[] = Array.from(
    new Set(
      errors.map((error) => {
        const cleanErrorMessage = removeObsoleteCategoryWeightingsSubstring(removeObsoleteSegmentsSubstring(error))
        let [errField, ...remainingErrWords] = cleanErrorMessage.split(' ')
        const maybeNumberConstraint = Number(remainingErrWords[remainingErrWords.length - 1])
        if (isNumber(maybeNumberConstraint)) {
          remainingErrWords[remainingErrWords.length - 1] = maybeNumberConstraint.toLocaleString('en-GB')
        }
        let errMsg = remainingErrWords.join(' ')
        if (errMsg.includes('must be a number conforming to the specified constraints')) {
          errMsg = errMsg.replace(
            'must be a number conforming to the specified constraints',
            'must contain 4 decimal places or less',
          )
        }
        if (errField === 'totalGwp') {
          return '' //WAY 1503 - do not display error message for totalGwp
        }
        fields.add(errField)
        return [getErrorField(errField), errMsg].join(' ').trim()
      }),
    ),
  )
  return { messages: uniqueMessages, fields: Array.from(fields) }
}

const removeObsoleteSegmentsSubstring = (text: string): string => text.replace(/segments\.\d+\./, '')
const removeObsoleteCategoryWeightingsSubstring = (text: string): string =>
  text.replace(/categoryWeightings(\.(\w+)\.)(.+)/, '$2 $3')
