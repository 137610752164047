import React from 'react'
import LoginWithJumpCloud from '../../components/login/LoginWithJumpCloud'
import Logo from '../../Logo-prod.png' // Tell webpack this JS file uses this image
import './Landing.scss'

const Landing: React.FC = (props: any) => (
  <div className="Landing">
    <img
      className="TopNavigationMenuLogo"
      src={Logo}
      alt="Logo"
    />
    <div className="LandingContent">
      <h1>Sign in</h1>
      <p>{'Wayfinder is a scenario-based strategy tool to enable Convex Activities 1 & 3.'}</p>
      <LoginWithJumpCloud changeLocation={(value) => (window.location.href = value)} />
    </div>
  </div>
)

export default Landing
