import React from 'react'
import './SectionHeaderContainer.scss'

export type SectionHeaderContainerProps = React.PropsWithChildren<{ alignRight?: boolean }>

export function SectionHeaderContainer(props: SectionHeaderContainerProps) {
  const { alignRight, ...rest } = props

  return (
    <div
      className="SectionHeaderContainer"
      style={alignRight ? { flexDirection: 'row-reverse' } : {}}
      {...rest}
    >
      {props.children}
    </div>
  )
}

export default SectionHeaderContainer
