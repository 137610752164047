import { Box, Grid, Heading, Select } from '@chakra-ui/react'
import { Dispatch, ReactElement } from 'react'
import {
  ExceedanceGraphData,
  ExceedanceProbabilityAALGraph,
  MarketShareMatrix,
  ReturnPeriod,
} from '../../backend/exposures-V2'
import { ExposuresBarGraph } from './components/ExposuresBarGraph'
import { ExposuresGraphAlert, ExposuresGraphAlertState } from './components/ExposuresGraphAlert'
import { ExposuresGraphState } from './components/ExposuresGraphState'
import { ExposuresLineGraph } from './components/ExposuresLineGraph'
import { Action, State } from './components/ExposuresReportState'

type ExposuresGraphsProps = {
  graphAlertState: ExposuresGraphAlertState
  returnPeriod: ReturnPeriod
  dispatch: Dispatch<Action>
  columns: string[]
  barGraphState: ExposuresGraphState<MarketShareMatrix>
  lineGraphState: ExposuresGraphState<ExceedanceGraphData[]>
  lineGraphMarkers: ExceedanceProbabilityAALGraph[]
  state: State
  overrideScaled: ExceedanceGraphData
}

export function ExposuresGraphs(props: ExposuresGraphsProps): ReactElement {
  const {
    graphAlertState,
    returnPeriod,
    dispatch,
    columns,
    barGraphState,
    lineGraphState,
    lineGraphMarkers,
    state,
    overrideScaled,
  } = props

  return (
    <>
      <Heading
        as="h2"
        size="md"
        margin={2}
      >
        Indicative Results
      </Heading>
      {graphAlertState.alertStatus === 'info' && (
        <Box>
          <ExposuresGraphAlert
            alertStatus={graphAlertState.alertStatus}
            message={graphAlertState.message}
            isActive={graphAlertState.isActive}
          />
        </Box>
      )}
      <Box>
        <Heading
          as="h4"
          size="sm"
          margin={2}
        >
          Select peril
        </Heading>
        <Select
          width={'20%'}
          margin={2}
          onChange={(e) => {
            const selectedPeril = e.target.value
            dispatch({
              type: 'setSelectedPeril',
              payload: {
                selectedPeril,
              },
            })
          }}
        >
          {columns.map((peril) => (
            <option
              key={peril}
              value={peril}
            >
              {peril}
            </option>
          ))}
        </Select>
      </Box>
      <Grid
        height={'100%'}
        width={'100%'}
        placeItems={'center'}
        paddingBottom={'40px'}
        templateRows={'3fr 10fr 3fr 10fr'}
      >
        {graphsErroring(barGraphState.status, lineGraphState.status) ? (
          <ExposuresGraphAlert
            alertStatus={graphAlertState.alertStatus}
            message={graphAlertState.message}
            isActive={graphAlertState.isActive}
          />
        ) : (
          <>
            <Heading
              as="h2"
              size="md"
            >
              Market Share Losses
            </Heading>
            <ExposuresBarGraph
              loadingState={barGraphState.status}
              graphData={barGraphState.data[state.selectedPeril]}
              leftAxis={'Market share %'}
              bottomAxis={'ILC band'}
            />
            <Heading
              as="h2"
              size="md"
            >
              Exceedance Probability - {returnPeriod}
            </Heading>
            <ExposuresLineGraph
              loadingState={lineGraphState.status}
              graphData={[...lineGraphState.data, overrideScaled]}
              graphMarkers={lineGraphMarkers}
              peril={state.selectedPeril}
              returnPeriod={returnPeriod}
              leftAxis={'Gross loss USD'}
              bottomAxis={'Return period'}
            />
          </>
        )}
      </Grid>
    </>
  )
}

function graphsErroring(barGraphStatus: string, lineGraphStatus: string): boolean {
  return barGraphStatus === 'error' && lineGraphStatus === 'error'
}
