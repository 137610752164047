import { PortfolioSplitsColumns } from './portfolioSplitsColumns'
import {
  NcpTable,
  NcpTableHead,
  NcpTableHeadCell,
  NcpTableReadOnlyInputWithTooltip,
} from '../new-custom-portfolio-table'
import { Box, Input, Tbody, Td, Tr } from '@chakra-ui/react'
import { sanitizeId } from '../../utils/ids'
import { FieldArrayWithId, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'
import { Asterisk } from '../asterisk/Asterisk'
import { ChangeEvent, memo, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { NcpContext, NcpDispatchContext } from '../../providers/NewCustomPortfolioStateProvider'
import { CategoryWeightings, MonthType, PortfolioSplitTypes } from '../../backend/new-custom-portfolio-items'
import { assertNotNil } from '../../utils/assertNotNil'
import { UpdateSingleCategoryWeightingPayload } from '../new-custom-portfolio-splits/NewCustomPortfolioState'
import { sumAllFormValues } from './utils/sumAllFormValues'
import { extractIndexFromDynamicFieldName } from './utils/dynamicFieldIndexExtractor'
import { NcpAlertContext } from '../../providers/NewCustomPortfolioAlertStateProvider'
import { NewCustomPortfolioAdjustmentContext } from '../../providers/NewCustomPortfolioAdjustmentsProvider'

export type StaticTable = 'STATIC'
export type DynamicTable = 'DYNAMIC'

export type StaticOrDynamic = StaticTable | DynamicTable

export type CategoryTableProps<TFieldValues extends FieldValues, TIsDynamic extends StaticOrDynamic> = {
  first?: true
  categoryLabel: string
  categoryName: keyof CategoryWeightings
  columns: {
    labels: PortfolioSplitsColumns
    fields: TIsDynamic extends StaticTable ? Path<TFieldValues>[] : FieldArrayWithId<TFieldValues>[]
  }
  required?: true
  formContentEditor?: ReactNode
} & (TIsDynamic extends DynamicTable ? { dynamicFieldsName: keyof TFieldValues } : {})

const firstStickyOffset = 0
const secondStickyOffset = 102

const NonMemoizedCategoryTable = <TFieldValues extends FieldValues, TIsDynamic extends StaticOrDynamic = StaticTable>(
  props: CategoryTableProps<TFieldValues, TIsDynamic>,
) => {
  const { labels, fields } = props.columns
  const isDynamic: boolean = 'dynamicFieldsName' in props
  const { categoryName } = props
  const { register, setValue, getValues } = useFormContext<TFieldValues>()
  const [total, setTotal] = useState<number>(() => sumAllFormValues(getValues()))
  const [previousNumberOfFields, setPreviousNumberOfFields] = useState<number>(fields.length)
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const ncpAlertState = useContext(NcpAlertContext)
  assertNotNil(ncpAlertState)
  const ncpDispatch = useContext(NcpDispatchContext)
  assertNotNil(ncpDispatch)
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  const isInvalid = useMemo(() => ncpAlertState.field.includes(categoryName), [categoryName, ncpAlertState.field])

  const updateNcpState: (payload: UpdateSingleCategoryWeightingPayload) => void = useCallback(
    (payload) =>
      ncpDispatch({
        type: 'updateSingleKeyForCategoryWeighting',
        payload,
      }),
    [ncpDispatch],
  )

  const getUniqueComponentKey: (field: Path<TFieldValues> | FieldArrayWithId<TFieldValues>, index: number) => string =
    useCallback((field, index) => (typeof field === 'string' ? `${index}` : field.id), [])

  const getFieldName: (
    field: Path<TFieldValues> | FieldArrayWithId<TFieldValues>,
    index: number,
  ) => Path<TFieldValues> = useCallback(
    (field, index) => (typeof field === 'string' ? field : getDynamicFieldName(index)) as Path<TFieldValues>,
    [],
  )

  const getDynamicFieldName: (index: number) => Path<TFieldValues> = useCallback((index) => {
    return `${'dynamicFieldsName' in props && String(props.dynamicFieldsName)}.${index}.value` as Path<TFieldValues>
  }, [])

  const updateTotal = () => setTotal(sumAllFormValues(getValues()))

  const applyValueForField = (value: number, fieldName: Path<TFieldValues>) => {
    setValue(fieldName, value as PathValue<TFieldValues, Path<TFieldValues>>)
    updateNcpState({
      categoryName,
      key: 'dynamicFieldsName' in props ? props.columns.labels[extractIndexFromDynamicFieldName(fieldName)] : fieldName,
      data: value,
    })
    updateTotal()
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    applyValueForField(Number.isNaN(value) ? 0 : Number(value), name as Path<TFieldValues>)
  }

  useEffect(() => {
    if (!ncpState.reset) return

    const category = ncpState.ncpData.categoryWeightings[categoryName]
    for (const field in category) {
      const resetValue = category[field as keyof (MonthType | PortfolioSplitTypes)] as PathValue<
        TFieldValues,
        Path<TFieldValues>
      >
      const fieldIdentifier = isDynamic
        ? getDynamicFieldName(props.columns.labels.indexOf(field))
        : (field as Path<TFieldValues>)
      setValue(fieldIdentifier, resetValue)
    }
    ncpDispatch({ type: 'reset', payload: { reset: false } })
    updateTotal()
  }, [ncpState.reset])

  useEffect(() => {
    if (fields.length === 1) {
      applyValueForField(100, getFieldName(fields[0], 0))
    } else if (fields.length === 2 && previousNumberOfFields === 1) {
      applyValueForField(0, getFieldName(fields[0], 0))
    } else {
      updateTotal()
    }
    setPreviousNumberOfFields(fields.length)
  }, [fields])

  return (
    <NcpTable title={props.first ? 'Portfolio Splits' : undefined}>
      <CategoryHead {...props} />
      <Tbody>
        <Tr>
          <Td
            style={{
              position: 'sticky',
              left: `${firstStickyOffset}px`,
              zIndex: 2,
              background: '#fff',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              textTransform: 'none',
            }}
          >
            Split %
          </Td>
          <Td
            style={{
              position: 'sticky',
              left: `${secondStickyOffset}px`,
              zIndex: 2,
              background: '#fff',
              textTransform: 'none',
            }}
          >
            <NcpTableReadOnlyInputWithTooltip
              id={`Total % ${props.categoryLabel}`}
              value={total}
              isInvalid={isInvalid}
            />
          </Td>
          {fields.map((field, index) => {
            const id = sanitizeId(`${props.categoryLabel} ${labels[index]}`)
            return (
              <Td key={getUniqueComponentKey(field, index)}>
                <Input
                  {...register(getFieldName(field, index), { valueAsNumber: true })}
                  size={'sm'}
                  borderRadius={'md'}
                  id={id}
                  data-testid={id}
                  type="number"
                  onChange={handleInputChange}
                  disabled={isNcpPreview || fields.length === 1}
                  isInvalid={isInvalid}
                />
              </Td>
            )
          })}
          {props.formContentEditor && (
            <Td
              style={{
                position: 'sticky',
                right: 0,
                zIndex: 2,
                background: '#fff',
                textAlign: 'right',
              }}
            />
          )}
        </Tr>
      </Tbody>
    </NcpTable>
  )
}

type CategoryHeadProps = Pick<
  CategoryTableProps<any, any>,
  'categoryLabel' | 'columns' | 'required' | 'formContentEditor'
>

const CategoryHead = memo((props: CategoryHeadProps) => {
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)

  return (
    <NcpTableHead>
      <NcpTableHeadCell
        stickyOffset={firstStickyOffset}
        width={`${secondStickyOffset}px`}
      >
        <Box
          width={'70px'}
          data-testid="categoryNameHeader"
        >
          {props.categoryLabel}
          <Asterisk visible={!!props.required} />
        </Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell
        stickyOffset={secondStickyOffset}
        width="92px"
      >
        <Box width={'60px'}>Total</Box>
      </NcpTableHeadCell>
      {props.columns.labels.map((column, index) => (
        <NcpTableHeadCell key={index}>
          <Box minWidth="50px">{column}</Box>
        </NcpTableHeadCell>
      ))}
      {props.formContentEditor && (
        <NcpTableHeadCell
          style={{
            position: 'sticky',
            right: 0,
            textAlign: 'right',
            zIndex: 5,
            background: '#F8F8F8',
          }}
        >
          {!isNcpPreview && props.formContentEditor}
        </NcpTableHeadCell>
      )}
    </NcpTableHead>
  )
})

/**
 * @description Category Table component.
 * To be used exclusively for Portfolio Splits Table.
 *
 * Prerequisites:
 * - Expected to be used within <b>FormProvider</b> from <i>React Hook Form</i>.
 * If not, it will throw an error. This means, that the parent component is responsible for initializing the form to be used for displaying columns with respective values.
 * - Expected to be used underneath <b>NewCustomPortfolioStateProvider</b> from <i>providers</i> folder. It is necessary for the component to read and update ncpState data properly.
 *
 * <b>For docs and example of using dynamic tables (meaning, you as a dev <i>DO NOT</i> know number and names of columns to be present in the table upfront), see <i>DynamicCategoryTable</i> component.</b>
 *
 * Reusability outside of this context is not guaranteed, though probably possible with some changes.
 *
 * For description of <i>props</i> and <i>generic types</i>, read on.
 *
 * For usage example, see e.g. <i>PlacementTypesTable</i> component.
 *
 * @param TFieldValues
 * - Generic type representing the fields of the table.
 * This type extends <b>FieldValues</b> which is type for fields definition coming from <i>React Hook Form</i>.
 *
 * @param TIsDynamic
 * - Generic type representing whether the table is going to be <b>Dynamic</b> or <b>Static</b>.
 *
 * @param first
 * - Signalizes whether this category table is going to be first in the <i>Portfolio Splits Table</i>.
 * If true, it will render the title "Portfolio Splits" above the table.
 *
 * @param categoryLabel
 * - The label of the category. This will be rendered as label of given table.
 *
 * @param categoryName
 * - The name of the category. This will be used to update the state of the New Custom Portfolio.
 * It has to be one of the known <i>CategoryWeightings</i> keys.
 *
 * @param columns
 * - Object containing labels and fields of the table to be displayed.
 * Number of labels and fields is expected to be the same at all times.
 * Behavior undefined when otherwise.
 *
 * @param columns.labels
 * - Array of labels representing the labels of the columns to be displayed in the table. Ideally human-readable.
 *
 * @param columns.fields
 * - Array of fields making it possible for <b>React Hook Form</b> to register, update and read the fields values.
 * These fields differ in type based on whether the <i>CategoryTable</i> is said to be <b>Dynamic</b> or <b>Static</b> via generic <b>TIsDynamic</b>
 *
 * @param required
 * - Signalizes whether the category is required. If true, a red <b>asterisk</b> will be rendered next to the category label.
 *
 * @param formContentEditor
 * - Optional. If provided, it will render the content editor in the last column of the table.
 * This will be fixed to the right at all times and won't move even if we scroll the table horizontally. Originally meant for Dynamic tables.
 *
 * @param dynamicFieldsName
 * - Required if table is said to be Dynamic.
 * It will be used to determine the wrapping name of the dynamic fields in the table
 * so that the state of the New Custom Portfolio can be updated and worked with.
 */
export const CategoryTable = memo(NonMemoizedCategoryTable) as typeof NonMemoizedCategoryTable
