import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react'
import ReactSwitch from 'react-switch'

import Divider from '../divider/LineDivider'
import SectionHeader from '../section-header/SectionHeader'
import DropdownWithTextfield from '../dropdown-with-textfield/DropdownWithTextfield'
import { ResultbyDistributionbyLossType } from '../../backend/stacked-scenario-parameterisation'
import { groupParameterisationByRow } from '../../pages/parameterisation/ParameterisationRows'
import {
  attritionalInputValueValidation,
  calculateOverrideInputError,
  calculateSingleRiskMeanFirstPercentageBoxError,
} from '../team-parameterisation-request-section/StackedScenarioParameterisationRequestContainer'
import { OptionsType } from '../../utils/lists'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { displayLargeDollarValue } from '../../utils/numbers'
import { calculateValueIncludingSuffix } from '../../utils/onChange'

type Props = {
  one: OptionsType
  setOne: Dispatch<SetStateAction<OptionsType>>
  two: OptionsType
  setTwo: Dispatch<SetStateAction<OptionsType>>
  three: OptionsType
  setThree: Dispatch<SetStateAction<OptionsType>>
  four: OptionsType
  setFour: Dispatch<SetStateAction<OptionsType>>

  dataFilteredByDistributionType: ResultbyDistributionbyLossType[]
  handleToggleOverride: () => void
  isOverrideToggleEnabled: boolean
  setIsUptoDate: Dispatch<SetStateAction<boolean>>
  shouldValidateOverrideInputs: boolean
}

export function OverrideDistribution(props: Props): JSX.Element {
  const { underlyingMarketParamData } = useContext(TeamParameterisationDataContext)
  const { one, setOne, two, setTwo, three, setThree, four, setFour } = props

  const listOfDataToBeDisplayed: ResultbyDistributionbyLossType[] = useMemo(() => {
    return props.dataFilteredByDistributionType.filter((item) => item.display)
  }, [props.dataFilteredByDistributionType])

  const oneOptions: OptionsType[] = makeAttritionalOne(listOfDataToBeDisplayed)
  const twoOptions: OptionsType[] = makeAttritionalTwo(listOfDataToBeDisplayed)
  const threeOptions: OptionsType[] = makeMeanThree(listOfDataToBeDisplayed)
  const fourOptions: OptionsType[] = makeMeanFour(listOfDataToBeDisplayed)

  function setStateWithSettingNotUpToDate<T>(setState: Dispatch<SetStateAction<T>>): Dispatch<SetStateAction<T>> {
    return (item: SetStateAction<T>): void => {
      setState(item)
      props.setIsUptoDate(false)
    }
  }

  if (props.dataFilteredByDistributionType.length <= 0) {
    return <div />
  }
  const isSeverity = three.label === 'Severity'
  return (
    <>
      <Divider />
      <div className="OverrideContainer">
        <div className="OverrideToggleContainer">
          <SectionHeader title="Override Distributions" />
          <ReactSwitch
            className="ToggleOverride"
            onChange={props.handleToggleOverride}
            checked={props.isOverrideToggleEnabled}
            onColor="#0BC893"
            offColor="#4E566B"
            handleDiameter={14}
            height={18}
            width={32}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>

        {props.isOverrideToggleEnabled && (
          <div className="OverrideDropdownWithInputContainer">
            <SectionHeader title="Attritional" />
            {/* One: Attritional - 50th percentile */}
            <div className="DropdownContainer">
              <div className="DropdownWithInput">
                <DropdownWithTextfield
                  step="0.1"
                  onDropdownSelect={handleOnDropdownSelect(setOne)}
                  options={oneOptions}
                  selected={[one]}
                  onTextFieldChange={(ev) => {
                    const option: SetStateAction<OptionsType> = valueToOption(ev.target.value)
                    return setStateWithSettingNotUpToDate(setOne)(option)
                  }}
                  textFieldPlaceholder="0"
                  textFieldType="number"
                  textFieldIsPercentage
                  textFieldValue={one.value}
                  textFieldError={
                    props.shouldValidateOverrideInputs
                      ? calculateOverrideInputError(one.value) || attritionalInputValueValidation(one, two)
                      : ''
                  }
                />
              </div>
              {/* Two: Attritional - Mean */}
              <div className="DropdownWithInput">
                <DropdownWithTextfield
                  step="0.1"
                  onDropdownSelect={handleOnDropdownSelect(setTwo)}
                  options={twoOptions}
                  selected={[two]}
                  onTextFieldChange={(ev) => {
                    const option: SetStateAction<OptionsType> = valueToOption(ev.target.value)
                    return setStateWithSettingNotUpToDate(setTwo)(option)
                  }}
                  textFieldValue={two.value}
                  textFieldError={props.shouldValidateOverrideInputs ? calculateOverrideInputError(two.value) : ''}
                  textFieldPlaceholder="0"
                  textFieldType="number"
                  textFieldIsPercentage
                />
              </div>
            </div>
            <SectionHeader title="Single-risk Mean" />
            {/* Three: Mean - Frequency */}
            <div className="DropdownContainer">
              <div className="DropdownWithInput">
                <DropdownWithTextfield
                  step="0.1"
                  onDropdownSelect={handleOnDropdownSelect(setThree)}
                  options={threeOptions}
                  selected={[three]}
                  onTextFieldChange={(ev) => {
                    const option: SetStateAction<OptionsType> = valueToOption(
                      isSeverity ? calculateValueIncludingSuffix(ev.target.value)?.toString() || '' : ev.target.value,
                    )
                    return setStateWithSettingNotUpToDate(setThree)(option)
                  }}
                  textFieldPlaceholder="0"
                  textFieldType={isSeverity ? undefined : 'number'}
                  textFieldValue={(isSeverity
                    ? displayLargeDollarValue(parseInt(three.value))
                    : three.value
                  ).toString()}
                  textFieldError={
                    props.shouldValidateOverrideInputs
                      ? calculateSingleRiskMeanFirstPercentageBoxError(
                          three.label,
                          three.value,
                          underlyingMarketParamData,
                        )
                      : ''
                  }
                />
              </div>
              {/* Mean Four - Freq Volatility */}
              <div className="DropdownWithInput">
                <DropdownWithTextfield
                  step="0.1"
                  onDropdownSelect={handleOnDropdownSelect(setFour)}
                  options={fourOptions}
                  selected={[four]}
                  onTextFieldChange={(ev) => {
                    const option: SetStateAction<OptionsType> = valueToOption(ev.target.value)
                    return setStateWithSettingNotUpToDate(setFour)(option)
                  }}
                  textFieldPlaceholder="0"
                  textFieldType="number"
                  textFieldValue={four.value}
                  textFieldError={props.shouldValidateOverrideInputs ? calculateOverrideInputError(four.value) : ''}
                  textFieldIsPercentage
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

function makeAttritionalOne(listOfDataToBeDisplayed: ResultbyDistributionbyLossType[]): OptionsType[] {
  return Object.keys(groupParameterisationByRow(listOfDataToBeDisplayed))
    .filter((item) => item !== 'Mean')
    .map((label) => ({ label, value: '' }))
}

function makeAttritionalTwo(listOfDataToBeDisplayed: ResultbyDistributionbyLossType[]): OptionsType[] {
  return Object.keys(groupParameterisationByRow(listOfDataToBeDisplayed))
    .filter((item) => item !== 'SD' && item !== 'CoV')
    .map((label) => ({ label, value: '' }))
}

function makeMeanThree(listOfDataToBeDisplayed: ResultbyDistributionbyLossType[]): OptionsType[] {
  return [
    { label: 'Frequency', value: '' },
    { label: 'Severity', value: '' },
  ]
}

function makeMeanFour(listOfDataToBeDisplayed: ResultbyDistributionbyLossType[]): OptionsType[] {
  return [{ label: 'Freq Volatility Uplift', value: '' }]
}

export function valueToOption(value: string): SetStateAction<OptionsType> {
  console.log('valueToOption::', value)
  return (option) => ({ ...option, value })
}

function handleOnDropdownSelect(setOption: Dispatch<SetStateAction<OptionsType>>) {
  return function ([option]: OptionsType[]): void {
    console.log('handleOnDropdownSelect::', option)
    return setOption(option)
  }
}
