import React, { useContext, useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import './ActualDropdown.scss'

import { PortfolioListingContext, PortfolioStatus } from '../../providers/PortfolioListingProvider'
import { ActualProps, ActualsContext } from '../../providers/ActualDataChoiceProvider'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { MultiSelectDropdown, Props as MultiSelectDropdownProps } from '../multi-select-dropdown/MultiSelectDropdown'
import { DropdownGroup, OptionsType, toOption } from '../../utils/lists'
import { Level } from '../../utils/urls'
import { Actual } from '../../backend/teams'
import { PortfolioListing } from '../../backend/api-types/PortfolioListing.types'
import { Flag } from '../../pages/exposures/StackedExposures'
import { updateExposuresReportOutdated } from './ActualsExposuresReportsOutdated'

type Props = {
  year: Level['year']
  division: Level['division']
  disabled: boolean
  testId?: string | undefined
  id?: string
}

const unloaded = 'Unloaded'
const loading = 'Loading...'
const err = 'Error'

export function ActualDropdown(props: Props): JSX.Element {
  const { year, division, disabled } = props

  const { snowflake } = useFlags<Flag>()

  const { portfolioStatus, actuals } = useContext(PortfolioListingContext)
  const { actualsQuery } = useContext(ActualsContext)
  const { currentScenario, updateScenario } = useContext(ScenarioContext)

  const [actual, setActual] = actualsQuery

  async function selectActual(optionValue: string): Promise<void> {
    // When an option is selected, it sets the state with the selected value
    setActual(optionValue) // need to set it here so the selected value is showed on the UI when creating a new scenario

    await updateScenario({
      currentYearActualsDataName: optionValue,
    })
    makeSelected(optionValue)

    if (!currentScenario) {
      return
    }

    await markExposuresReportsOutdated(currentScenario.id)

    // setActual(optionValue) // need to set it again for exposures report to be marked as outdated
  }

  const selected: MultiSelectDropdownProps['selected'] = useMemo(() => {
    const defaultActual = 'None' as const
    let currentActual: string
    if (currentScenario?.currentYearActualsDataName) {
      currentActual = currentScenario?.currentYearActualsDataName
    } else if (actual) {
      currentActual = actual
    } else {
      currentActual = defaultActual
    }
    return makeSelected(currentActual)
  }, [currentScenario, actual])

  const options = useMemo(() => {
    return makeOptions(portfolioStatus, year, division, actuals)
  }, [portfolioStatus, year, division, actuals])

  const title = `${year} Actual Data (YTD)`
  return (
    <MultiSelectDropdown
      title={title}
      id={props.id}
      isDisabled={disabled}
      isOptionDisabled={(option) => [unloaded, loading, err].includes(option.value)}
      className="ActualDataChooser"
      onSelect={([option]) => selectActual(option.value)}
      selected={selected}
      isSearchable={false}
      placeholder="Actual Data"
      options={hideSnowflakeOnEnv(snowflake, options)}
      boxed={true}
      testId={props.testId}
    />
  )
}

/**
 * Hide snowflake options depending on the environment
 */
export function hideSnowflakeOnEnv<DS extends DropdownGroup[]>(snowflake: Flag['snowflake'], group: DS): DS {
  if (snowflake === 'show') {
    return group
  }

  const filterSnowflake = (x: OptionsType | DropdownGroup) => x.label.toLowerCase() !== 'snowflake'

  return (
    group
      // Filter snowflake from the top level:
      .filter(filterSnowflake)
      // If options exists then also filter at the level below:
      .map((x) => {
        const clean = x.options.filter(filterSnowflake)
        return { ...x, options: clean }
      }) as DS
  )
}

function makeSelected(
  actual: ActualProps['actualsQuery'][0],
): (undefined | [OptionsType]) & MultiSelectDropdownProps['selected'] {
  return actual ? [toOption(actual)] : undefined
}

function makeOptions(
  portfolioStatus: PortfolioStatus,
  year: Level['year'],
  division: Level['division'],
  actuals: undefined | Actual[],
): DropdownGroup[] & MultiSelectDropdownProps['options'] {
  const initialGroup: DropdownGroup[] = []
  const snowflakeGroup: DropdownGroup = makeSnowflakeActuals(actuals)
  const csvGroup: DropdownGroup = makeCsvActuals(portfolioStatus, year, division)

  return initialGroup.concat(snowflakeGroup).concat(csvGroup)
}

function noActual(): OptionsType {
  return { label: 'No Actuals', value: 'None' } as const
}

function makeSnowflakeActuals(actuals: undefined | Actual[]): DropdownGroup {
  const months = snowflakeOptions(actuals)
  const options: OptionsType[] = [noActual()].concat(months)

  return { label: 'Snowflake', options }
}

function snowflakeOptions(actuals: undefined | Actual[]): OptionsType[] {
  const months: undefined | string[] = actuals?.map((a) => a.MONTH_ABBR)
  // TODO: see if unique can be done on sql side
  const uniqueList: OptionsType[] = Array.from(new Set(months)).map(toOption)

  return uniqueList
}

function makeCsvActuals(
  portfolioStatus: PortfolioStatus,
  year: Level['year'],
  division: Level['division'],
): DropdownGroup {
  const months: OptionsType[] = csvOptions(portfolioStatus, year, division)
  const options: OptionsType[] = [noActual()].concat(months)

  return { label: 'CSV', options }
}

function csvOptions(portfolioStatus: PortfolioStatus, year: Level['year'], division: Level['division']): OptionsType[] {
  if (!year) {
    console.warn(`No year selected, so no CSV actuals available`)
    return []
  }
  if (!division) {
    console.warn(`No division selected, so no CSV actuals available`)
    return []
  }
  if (portfolioStatus.status === 'error') {
    console.warn(`Error loading portfolio, so no CSV actuals available`)
    return [toOption(err)]
  }
  if (portfolioStatus.status === 'unloaded') {
    return [toOption(unloaded)]
  }
  if (portfolioStatus.status === 'loading') {
    return [toOption(loading)]
  }
  const divisionListing: undefined | PortfolioListing['divisions'][number] =
    portfolioStatus.portfolio?.portfolioListing.divisions[division]
  if (!divisionListing) {
    console.warn(`No division listing found for "${division}", so no CSV actuals available`)
    return []
  }

  return divisionListing.partialDataChoices
    .filter((choice) => choice.year === year)
    .map((choice) => choice.name)
    .map(toOption)
}

const markExposuresReportsOutdated = async (scenarioId: string) => {
  await updateExposuresReportOutdated(scenarioId)
}
