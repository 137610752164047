import React, { useContext } from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'
import { formatForDisplay } from '../../../utils/numbers'
import { GraphContainerContext } from '../../graph-container/GraphContainer'
import { GraphStyleContext } from '../GraphStyleProvider'
import { WrappedTick } from '../WrappedTick'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

/**
 * Using the example from https://github.com/plouc/nivo/issues/609#issuecomment-743909813
 */
export interface WaterfallChartDataItem {
  category: string
  GWP: number
}

interface IWaterfallBarDatum extends WaterfallChartDataItem {
  amount: number
  subtotal: number
  paintSubtotal: number
  color: string
}

/**
 * Shamelessly stolen from https://codesandbox.io/s/nivo-waterfall-chart-o8tgz?file=/src/WaterfallChart/constants.ts:643-2292
 */
export const calcWaterfallDataWSign = (
  incomeData: WaterfallChartDataItem[],
  initialValue?: number, // tells us whether to start the first bar from zero or from some other value
): IWaterfallBarDatum[] => {
  if (!incomeData.length) return []
  let prev = initialValue || 0
  let currentSign = Math.sign(prev)

  const dataPoints: IWaterfallBarDatum[] = incomeData.map(({ category, GWP }) => {
    const color = GWP > 0 ? GRAPH_COLOURS[0] : '#FF8EA3'
    let subtotal: number = 0
    let amount: number = GWP
    let paintSubtotal = 0
    const next = prev + GWP

    // no sign change
    if (Math.sign(next) === currentSign) {
      // stays as positive value
      if (currentSign === 1) {
        if (GWP < 0) {
          subtotal = next
          amount = Math.abs(GWP)
        } else {
          subtotal = prev
          amount = GWP
        }
      } else if (currentSign === -1) {
        // stays as negative value
        subtotal = GWP < 0 ? prev : next
        if (GWP < 0) {
          subtotal = prev
          amount = GWP
        } else {
          subtotal = next
          amount = -GWP
        }
      }
    } else if (Math.sign(next) !== currentSign) {
      // sign change
      if (currentSign === 1) {
        // from + to -
        amount = prev
        subtotal = next
        paintSubtotal = 1
      } else if (currentSign === -1) {
        // from - to +
        amount = next
        subtotal = prev
        paintSubtotal = 1
      }
    }

    prev = next
    currentSign = Math.sign(prev)

    return {
      subtotal,
      amount,
      color,
      paintSubtotal,
      accumulated: next,
      category,
      GWP,
    }
  })

  return [
    ...dataPoints,
    {
      category: 'Total',
      subtotal: 0,
      amount: prev,
      GWP: prev,
      color: GRAPH_COLOURS[1],
      paintSubtotal: 0,
    },
  ]
}

interface WaterfallChartProps {
  data: WaterfallChartDataItem[]
}

const WaterfallChart = (props: WaterfallChartProps) => {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const { data: _data } = props

  const optionsIfGraphExpanded: any =
    currentlyExpandedGraph === graphId
      ? {
          margin: { top: 10, right: 80, bottom: 150, left: 0 },
          axisRight: {
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: (item: any) => '$' + formatForDisplay(item as number),
            legend: 'Change in GWP from Original GWP',
            legendOffset: 64,
            legendPosition: 'middle',
          },
          axisBottom: {
            renderTick: WrappedTick,
            tickRotation: 30,
          },
        }
      : {}

  const data = calcWaterfallDataWSign(_data)
  const transparent = 'rgba(0, 0, 0, 0)'

  return (
    <ResponsiveBarCanvas
      data={data}
      keys={['subtotal', 'amount']}
      indexBy={'category'}
      {...optionsIfGraphExpanded}
      padding={0.3}
      colors={({ id, data }) => (id !== 'subtotal' || !!data.paintSubtotal ? data.color : transparent)}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      label={(item) => '$' + formatForDisplay(item.value)}
      tooltipFormat={(item: any) => '$' + formatForDisplay(item)}
      axisLeft={null}
      enableLabel={false}
    />
  )
}

export default WaterfallChart
