import React from 'react'
import './Grid.scss'

type Props = {
  classNameOverride?: string
}

export function GridContainer(props: React.PropsWithChildren<Props>) {
  let className = 'GridContainer'
  if (props.classNameOverride) {
    className = props.classNameOverride
  }
  return <div className={className}>{props.children}</div>
}
