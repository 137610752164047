import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'
import {
  createNewCustomPortfolioAlertInitialState,
  NewCustomPortfolioAlertAction,
  newCustomPortfolioAlertReducer,
  NewCustomPortfolioAlertState,
} from '../components/new-custom-portfolio-splits/NewCustomPortfolioAlertState'

export const NcpAlertContext = createContext<NewCustomPortfolioAlertState | undefined>(undefined)
export const NcpAlertDispatchContext = createContext<Dispatch<NewCustomPortfolioAlertAction> | undefined>(undefined)

export const NewCustomPortfolioAlertStateProvider = (props: PropsWithChildren) => {
  const [newCustomPortfolioAlertState, newCustomPortfolioAlertDispatch] = useReducer(
    newCustomPortfolioAlertReducer,
    createNewCustomPortfolioAlertInitialState(),
  )
  return (
    <NcpAlertContext.Provider value={newCustomPortfolioAlertState}>
      <NcpAlertDispatchContext.Provider value={newCustomPortfolioAlertDispatch}>
        {props.children}
      </NcpAlertDispatchContext.Provider>
    </NcpAlertContext.Provider>
  )
}
