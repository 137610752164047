import './PortfolioSplitsTable.scss'
import { InceptionMonthsTable } from './InceptionMonthsTable'
import { PlacementTypesTable } from './PlacementTypesTable'
import { EntityTable } from './EntityTable'
import { ExposureRegionTable } from './ExposureRegionTable'
import { InterestTypeTable } from './InterestTypeTable'
import { BrokerTable } from './BrokerTable'
import { Cob3Table } from './Cob3Table'

export const PortfolioSplitsTable = () => {
  return (
    <div className="PortfolioSplitsTable">
      <InceptionMonthsTable />
      <PlacementTypesTable />
      <EntityTable />
      <ExposureRegionTable />
      <Cob3Table />
      <InterestTypeTable />
      <BrokerTable />
    </div>
  )
}
