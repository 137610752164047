import { ChangeEventHandler, ForwardedRef, forwardRef, ReactElement } from 'react'

import './TextField.scss'

import { css } from '../../utils/css'
import { ignoreNanForNumbers } from '../../utils/ignoreNanForNumbers'

export type Props = {
  title?: string
  percentage?: boolean
  value?: string
  placeholder?: string
  pattern?: string
  type?: string
  warning?: string
  error?: string
  className?: string | string[]
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  step?: string
}

type Ref = ForwardedRef<null | HTMLInputElement>

export const TextField = forwardRef((props: Props, ref: Ref): ReactElement => {
  const { percentage, value, ...otherProps } = props

  const realValue = ignoreNanForNumbers(value)

  return (
    <div className={css('TextField', props.className)}>
      {props.title && <p className="Title">{props.title}</p>}
      <div
        className={
          props.title && props.percentage ? 'SuffixWithTitle' : '' || props.percentage ? 'SuffixWithoutTitle' : ''
        }
      >
        <input
          ref={ref}
          {...otherProps}
          placeholder={props.title}
          value={realValue}
          className={css('Input', props.warning ? 'Warning' : '', props.error ? 'Error' : '')}
          disabled={props.disabled}
          step={props.step}
        />
      </div>
      {props.warning && <p className="WarningText">{props.warning}</p>}
      {props.error && <p className="ErrorText">{props.error}</p>}
    </div>
  )
})
