import { ReactElement } from 'react'
import {
  formatForDisplay,
  formatPercentageAsIntegerForDisplay,
  IndividualDataItem,
  isZero,
  numericValueIsDefined,
  ONE_HUNDRED,
  sumListAsIntegers,
  sumListAsIntegersWithFallbackKeys,
  ZERO,
} from '../../../utils/numbers'
import {
  ADJUSTED_CONVEX_LIMIT,
  calculateGrarcKpi,
  calculateProfitabilityChange,
  PREMIUM_COLUMN_NAME,
  ScenarioKeyPerformanceIndicators,
  TOTAL_ADJUSTED_GWP_COLUMN,
  WeightedAvgKpis,
} from '../../../backend/calculate-kpis'
import Big from 'big.js'
import { CONVEX_SHARE_COLUMN_NAME, LIMIT_COLUMN_NAME } from '../../../backend/calculate-possible-filter-values'
import { AggregatedMarketV2 } from 'src/backend/aggregated-market-V2'
import { filterByDataType, WayfinderDataType } from '../../../backend/TypeOfData'

export interface TableOfKpiColumnInfo {
  title: string
  additionalCssClassNames?: string
  customCssClassName?: string
  perRowValueFormatter: (something: any) => ReactElement | string | number
  perRowValueGenerator: (context: {
    fullKpisForRow: ScenarioKeyPerformanceIndicators
    newKpisForRow: ScenarioKeyPerformanceIndicators
    renewalKpisForRow: ScenarioKeyPerformanceIndicators
    dataSplitIntoRenewalAndNew: {
      renewal: IndividualDataItem[]
      new: IndividualDataItem[]
      newClient: IndividualDataItem[]
    }
    fullWeightedAverageKpisForRow: WeightedAvgKpis
    renewalWeightedAverageKpisForRow: WeightedAvgKpis
    dataForThisRow: IndividualDataItem[]
    aggregatedMarketData: AggregatedMarketV2 | undefined
    title: string
  }) => Big | string | number
}

const ORIGINAL_ONE_HUNDRED_PERCENT_PREMIUM_COLUMN_NAME = 'Original 100% Premium'

function enrichWithOneHundredPercentPremium(currentDataItem: IndividualDataItem) {
  try {
    const currentConvexShare = new Big(currentDataItem[CONVEX_SHARE_COLUMN_NAME])
    const currentPremium = new Big(currentDataItem[PREMIUM_COLUMN_NAME])
    let oneHundredPercentPremium = ZERO

    if (numericValueIsDefined(currentDataItem[CONVEX_SHARE_COLUMN_NAME]) && !isZero(currentConvexShare)) {
      oneHundredPercentPremium = currentPremium.div(currentConvexShare)
    }

    return {
      ...currentDataItem,
      [ORIGINAL_ONE_HUNDRED_PERCENT_PREMIUM_COLUMN_NAME]: oneHundredPercentPremium,
    }
  } catch (e) {
    return currentDataItem
  }
}

export function getTableOfKpiColumns(isStackedScenario: boolean): TableOfKpiColumnInfo[] {
  let scenarioColumns: TableOfKpiColumnInfo[] = [
    {
      title: 'Expiring GWP',
      perRowValueGenerator: ({ dataForThisRow }) => {
        const nonNewTypes = [
          WayfinderDataType.RENEWAL_ADJUSTED,
          WayfinderDataType.RENEWAL_CLIENT,
          WayfinderDataType.LEGACY_RENEWAL_CLIENT,
          WayfinderDataType.RENEWAL_UNADJUSTED,
        ]
        const nonNewData = filterByDataType(dataForThisRow, nonNewTypes)
        return sumListAsIntegers(nonNewData, PREMIUM_COLUMN_NAME)
      },
      perRowValueFormatter: formatForDisplay,
    },
    {
      title: 'Renewal Retention',
      perRowValueGenerator: ({ renewalWeightedAverageKpisForRow }) =>
        renewalWeightedAverageKpisForRow.renewalRetentionKpi || ZERO,
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'Convex Share Change',
      perRowValueGenerator: ({ fullWeightedAverageKpisForRow }) =>
        fullWeightedAverageKpisForRow.convexShareChangeKpi || ZERO,
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'GRARC',
      perRowValueGenerator: ({ dataForThisRow }) => calculateGrarcKpi(dataForThisRow) || ZERO,
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'Renewal GWP',
      perRowValueGenerator: ({ renewalKpisForRow }) => renewalKpisForRow[TOTAL_ADJUSTED_GWP_COLUMN],
      perRowValueFormatter: formatForDisplay,
    },
    {
      title: 'New GWP',
      perRowValueGenerator: ({ newKpisForRow }) => newKpisForRow[TOTAL_ADJUSTED_GWP_COLUMN],
      perRowValueFormatter: formatForDisplay,
    },
    {
      title: 'Total Adjusted GWP',
      perRowValueGenerator: ({ newKpisForRow, renewalKpisForRow }) => {
        return newKpisForRow[TOTAL_ADJUSTED_GWP_COLUMN].add(renewalKpisForRow[TOTAL_ADJUSTED_GWP_COLUMN])
      },
      perRowValueFormatter: formatForDisplay,
    },
    {
      title: 'Acq. Ratio',
      perRowValueGenerator: ({ fullWeightedAverageKpisForRow }) => fullWeightedAverageKpisForRow.acqRatioKpi || ZERO,
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'EULR',
      perRowValueGenerator: ({ fullWeightedAverageKpisForRow }) => fullWeightedAverageKpisForRow.lossRatioKpi || ZERO,
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'WF Adjusted Line',
      perRowValueGenerator: ({ renewalKpisForRow, dataSplitIntoRenewalAndNew }) => {
        const totalAdjustedGWP = renewalKpisForRow[TOTAL_ADJUSTED_GWP_COLUMN]
        const dataWithExtraColumnAdded = dataSplitIntoRenewalAndNew.renewal.map(enrichWithOneHundredPercentPremium)

        const total100PercentPremium = sumListAsIntegersWithFallbackKeys(
          dataWithExtraColumnAdded,
          'WF 100% Adjusted Premium Value',
          [ORIGINAL_ONE_HUNDRED_PERCENT_PREMIUM_COLUMN_NAME],
        )
        if (isZero(total100PercentPremium)) {
          return ZERO
        }
        return totalAdjustedGWP.div(total100PercentPremium).mul(ONE_HUNDRED)
      },
      perRowValueFormatter: formatPercentageAsIntegerForDisplay,
    },
    {
      title: 'Average Adjusted Limit',
      perRowValueGenerator: ({ dataForThisRow }) =>
        dataForThisRow.length
          ? sumListAsIntegersWithFallbackKeys(dataForThisRow, ADJUSTED_CONVEX_LIMIT, [LIMIT_COLUMN_NAME]).div(
              dataForThisRow.length,
            )
          : ZERO,
      perRowValueFormatter: formatForDisplay,
    },
  ]

  if (isStackedScenario) {
    scenarioColumns = [
      ...scenarioColumns,
      {
        title: 'Inflation',
        perRowValueGenerator: ({ aggregatedMarketData, title }) => {
          if (!aggregatedMarketData) {
            return 0
          }
          if (title === 'Total') {
            return (
              Number(aggregatedMarketData.uwInflation.aggregatedMarketKpi) ||
              Number(aggregatedMarketData.inflation.aggregatedMarketKpi)
            )
          }
          if (
            !(title in aggregatedMarketData.uwInflation.individualMarketKpi) &&
            !(title in aggregatedMarketData.inflation.individualMarketKpi)
          ) {
            return 0
          }
          const inflation =
            Number(aggregatedMarketData.uwInflation.individualMarketKpi[title]) ||
            Number(aggregatedMarketData.inflation.individualMarketKpi[title])
          if (isNaN(inflation)) {
            return 0
          }
          return inflation
        },
        perRowValueFormatter: formatPercentageAsIntegerForDisplay,
      },
      {
        title: 'Profitability Change',
        perRowValueGenerator: ({ aggregatedMarketData, dataForThisRow, title }) => {
          if (!aggregatedMarketData) {
            return 0
          }
          if (title === 'Total') {
            return calculateProfitabilityChange(
              calculateGrarcKpi(dataForThisRow),
              Number(aggregatedMarketData.uwInflation.aggregatedMarketKpi) ||
                Number(aggregatedMarketData.inflation.aggregatedMarketKpi),
            )
          }
          if (
            !(title in aggregatedMarketData.uwInflation.individualMarketKpi) &&
            !(title in aggregatedMarketData.inflation.individualMarketKpi)
          ) {
            return 0
          }
          const inflation =
            Number(aggregatedMarketData.uwInflation.individualMarketKpi[title]) ||
            Number(aggregatedMarketData.inflation.individualMarketKpi[title])
          if (isNaN(inflation)) {
            return calculateProfitabilityChange(calculateGrarcKpi(dataForThisRow), 0)
          }
          return calculateProfitabilityChange(calculateGrarcKpi(dataForThisRow), inflation)
        },
        perRowValueFormatter: formatPercentageAsIntegerForDisplay,
      },
      {
        title: 'ABE GGLR',
        perRowValueGenerator: ({ aggregatedMarketData, title }) => {
          if (!aggregatedMarketData) {
            return 0
          }
          if (title === 'Total') {
            return (
              Number(aggregatedMarketData.uwAbeGglr.aggregatedMarketKpi) ||
              Number(aggregatedMarketData.AbeGglr.aggregatedMarketKpi)
            )
          }
          if (
            !(title in aggregatedMarketData.uwAbeGglr.individualMarketKpi) &&
            !(title in aggregatedMarketData.AbeGglr.individualMarketKpi)
          ) {
            return 0
          }
          const abeGglr =
            Number(aggregatedMarketData.uwAbeGglr.individualMarketKpi[title]) ||
            Number(aggregatedMarketData.AbeGglr.individualMarketKpi[title])
          if (isNaN(abeGglr)) {
            return 0
          }
          return abeGglr
        },
        perRowValueFormatter: formatPercentageAsIntegerForDisplay,
      },
    ]
  }
  return scenarioColumns
}
