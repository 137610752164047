import React from 'react'
import Plotly from 'plotly.js/lib/core.js'
import createPlotlyComponent from 'react-plotly.js/factory'
import { GraphHeader } from '../graph-header/GraphHeader'
import { handlePercentageAndOutputString } from '../../utils/numbers'
;(Plotly as any).register([require('plotly.js/lib/box')])
const Plot = createPlotlyComponent(Plotly)

interface LossRatioDisplay {
  title: string
  value: number
  x: string
}

interface MarketBoxPlotProps {
  graphData: any[]

  otherLossRatios: Array<LossRatioDisplay>
}

export default function MarketBoxPlot(props: MarketBoxPlotProps) {
  if (!props?.graphData?.length) {
    return <div />
  }

  return (
    <>
      <GraphHeader
        title={'GGLR Quantiles'}
        hideExpand
      />
      <div style={{ width: '100%', height: '100%' }}>
        <Plot
          className={'BoxPlot'}
          data={[
            ...(props.graphData as any[]),
            ...props.otherLossRatios.map((currentLRDisplay) => ({
              x: [currentLRDisplay.x],
              y: [handlePercentageAndOutputString(currentLRDisplay.value)],
              name: currentLRDisplay.title,
            })),
          ]}
          layout={{
            autosize: true,
            yaxis: { ticksuffix: '%' },
            margin: { t: 0, b: 20, l: 40 },
          }}
          config={{ displayModeBar: false }}
          useResizeHandler
        />
      </div>
    </>
  )
}
