import { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from 'react'
import {
  createNewCustomPortfolioInitialState,
  createNewCustomPortfolioReducer,
  NewCustomPortfolioAction,
  NewCustomPortfolioState,
} from '../components/new-custom-portfolio-splits/NewCustomPortfolioState'
import { AppQueryContext } from './AppQueryProvider'
import { KnownDivision } from '../components/portfolio-splits-table/portfolioSplitsColumns'

export const NcpContext = createContext<NewCustomPortfolioState | undefined>(undefined)
export const NcpDispatchContext = createContext<Dispatch<NewCustomPortfolioAction> | undefined>(undefined)

export const NewCustomPortfolioStateProvider = (props: PropsWithChildren) => {
  const { divisionQuery } = useContext(AppQueryContext)
  const division = divisionQuery[0] as KnownDivision

  const [newCustomPortfolioState, newCustomPortfolioDispatch] = useReducer(
    createNewCustomPortfolioReducer(),
    createNewCustomPortfolioInitialState(division),
  )

  return (
    <NcpContext.Provider value={newCustomPortfolioState}>
      <NcpDispatchContext.Provider value={newCustomPortfolioDispatch}>{props.children}</NcpDispatchContext.Provider>
    </NcpContext.Provider>
  )
}
