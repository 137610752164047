import { createContext, PropsWithChildren, useContext } from 'react'

import { AppQueryContext } from '../../providers/AppQueryProvider'

interface StackedScenariosViewContainer {
  currentYear: string | undefined | null
  currentPortfolioLevel: string | undefined | null
  currentDivision: string | undefined | null
  currentTeam: string | undefined | null
}

export const StackedScenariosViewContext = createContext<StackedScenariosViewContainer>({
  currentYear: undefined,
  currentPortfolioLevel: undefined,
  currentDivision: undefined,
  currentTeam: undefined,
})

export function StackedScenariosViewProvider(props: PropsWithChildren<{}>): JSX.Element {
  const { yearQuery, portfoliolevelQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery
  const [portfoliolevelFromQuery] = portfoliolevelQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  return (
    <StackedScenariosViewContext.Provider
      value={{
        currentYear: yearFromQuery,
        currentPortfolioLevel: portfoliolevelFromQuery,
        currentDivision: divisionFromQuery,
        currentTeam: teamFromQuery,
      }}
      children={props.children}
    />
  )
}
