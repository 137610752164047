import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export interface NCPSegments {
  name: string
  gwp: number
  gwpPercent: number
  eulrPercent: number
  writtenLinePercent: number
  acqCostPercent: number
  grarcPercent: number
  avgGwpPerLayer: number
  avgConvexLimit: number
  avgAttachmentPoint: number
}
export interface MonthType {
  '1': number
  '2': number
  '3': number
  '4': number
  '5': number
  '6': number
  '7': number
  '8': number
  '9': number
  '10': number
  '11': number
  '12': number
}

export type PortfolioSplitTypes = {
  [key: string]: number
}

export interface CategoryWeightings {
  inceptionMonth: MonthType
  placementType: PortfolioSplitTypes
  entity: PortfolioSplitTypes
  exposureRegion: PortfolioSplitTypes
  cob3?: PortfolioSplitTypes
  interestType?: PortfolioSplitTypes
  broker?: PortfolioSplitTypes
}

export interface NewCustomPortfolioItem {
  id?: string
  scenarioId?: string
  name: string
  description: string
  segments: NCPSegments[]
  categoryWeightings: CategoryWeightings
  isEnabled: boolean
  totalGwp: number
  totalGwpPercent: number
  totalGrarcPercent: number
  totalAvgGwpPerLayer: number
  totalAvgConvexLimit: number
  totalAvgAttachmentPoint: number
  totalEulrPercent: number
  totalWrittenLinePercent: number
  totalAcqCostPercent: number
}

export async function createNewCustomPortfolioItem(
  scenarioId: string,
  createNewCustomPortfolioItemRequest: NewCustomPortfolioItem,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/custom-portfolio-adjustments`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(createNewCustomPortfolioItemRequest),
    },
  )

  const res = await response.json()

  if (response.status === 400 || response.status === 500) {
    return Promise.reject(res)
  }

  return res
}

export async function fetchNewCustomPortfolioItems(scenarioId: string): Promise<NewCustomPortfolioItem[]> {
  const res = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/custom-portfolio-adjustments`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )
  if (res.status === 400 || res.status === 500) {
    return Promise.reject(res)
  }
  return await res.json()
}
export async function fetchNewCustomPortfolioItem(
  scenarioId: string,
  adjustmentId: string,
): Promise<NewCustomPortfolioItem> {
  const res = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/custom-portfolio-adjustments/${adjustmentId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )
  if (res.status === 400 || res.status === 500) {
    return Promise.reject(res)
  }
  return await res.json()
}
export async function patchNewCustomPortfolioItem(
  scenarioId: string,
  adjustmentId: string,
  patchNewCustomPortfolioItemRequest: Partial<NewCustomPortfolioItem>,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/custom-portfolio-adjustments/${adjustmentId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchNewCustomPortfolioItemRequest),
    },
  )

  if (response.status === 400 || response.status === 500) {
    const res = await response.json()
    return Promise.reject(res)
  }
  return Promise.resolve()
}

export async function deleteNewCustomPortfolioItem(scenarioId: string, adjustmentId: string) {
  const res = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/custom-portfolio-adjustments/${adjustmentId}`,
    {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (res.status === 400 || res.status === 500) {
    return Promise.reject(res.status)
  }
  return Promise.resolve()
}
