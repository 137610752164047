interface ConfigMap {
  apiHost: string
  pseudoPortfolioHost: string
  ssoRedirectLocation: string
}

export function getCurrentConfigSettings(): ConfigMap {
  const hostName = window.location.hostname
  const protocol = window.location.protocol
  const port = window.location.port

  let apiHost = `${protocol}//${hostName.replace('ui', 'api')}${port ? `:${port}` : ''}`

  if (hostName === 'localhost') {
    // apiHost = 'https://api.dev.wayfinder.systemtoo.convexin.com';
    apiHost = 'http://localhost:3001'
  }

  let pseudoPortfolioHost = apiHost

  if (hostName === 'localhost') {
    // Use with the `yarn run ppg-proxy` command
    // pseudoPortfolioHost = "http://localhost:8010/proxy"
  }

  return {
    apiHost,
    pseudoPortfolioHost,
    ssoRedirectLocation: `${protocol}//${hostName}${port ? `:${port}` : ''}`,
  }
}
