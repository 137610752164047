import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import {
  DefaultParameterData,
  fetchDefaultTeamMarketDependencies,
} from '../../backend/stacked-scenario-parameterisation'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { StackedScenarioContext } from '../StackedScenarioProvider'

export const ParameterisationDefaultTeamMarketDataContext =
  createContext<ParameterisationDefaultMarketDataContextContainer>({
    defaultTeamMarketData: undefined,
    triggerLoadMarketDefaults: () => void 0,
  })

export interface ParameterisationDefaultMarketDataContextContainer {
  defaultTeamMarketData: Array<DefaultParameterData> | undefined
  triggerLoadMarketDefaults: () => void
}

export const TeamParameterisationDefaultMarketDataProvider = (props: PropsWithChildren) => {
  const [defaultTeamMarketData, setDefaultTeamMarketData] = useState<Array<DefaultParameterData> | undefined>(undefined)

  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const triggerLoadMarketDefaults = () => {
    if (!currentStackedScenario) {
      return
    }
    updateIndividualProgressItem('fetchingTeamParameterisationDefaults', ProgressState.LOADING)

    fetchDefaultTeamMarketDependencies(currentStackedScenario.id)
      .then((defaultParamList) => {
        if (!defaultParamList || defaultParamList.length <= 0) {
          throw new Error('Missing defaultParamTeamList')
        }
        return defaultParamList.filter((y) => y.year === currentStackedScenario.year)
      })
      .then(setDefaultTeamMarketData)
      .then(() => updateIndividualProgressItem('fetchingTeamParameterisationDefaults', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingTeamParameterisationDefaults', ProgressState.ERROR))
  }

  useEffect(triggerLoadMarketDefaults, [currentStackedScenario, updateIndividualProgressItem])

  return (
    <ParameterisationDefaultTeamMarketDataContext.Provider
      value={{
        defaultTeamMarketData,
        triggerLoadMarketDefaults,
      }}
    >
      {props.children}
    </ParameterisationDefaultTeamMarketDataContext.Provider>
  )
}
