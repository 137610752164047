import { Auth } from 'aws-amplify'

import { queryStringify } from '../utils/urls'
import { getCurrentConfigSettings } from '../utils/config'
import { PortfolioResponse } from './api-types/PortfolioListing.types'
import { Version } from './api-types/SnowflakeScenario.types'

export async function getAccessToken(): Promise<string> {
  return Auth.currentSession()
    .then((item) => item.getIdToken())
    .then((accessToken) => accessToken.getJwtToken())
}

export async function fetchPortfolioListing(): Promise<PortfolioResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/portfolios`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status !== 200) {
    return Promise.reject()
  }
  return await response.json()
}

export type SnowflakeVersionResponse = {
  versions: Version[]
}

export type SnowflakeVersionOptions = {
  signal: AbortSignal
  parameters: {
    year: undefined | string
    division: undefined | string
    team: undefined | string
    market: undefined | string
  }
}

export async function fetchSnowflakeVersions(options: SnowflakeVersionOptions): Promise<SnowflakeVersionResponse> {
  const { signal, parameters } = options

  const baseUrl: string = getCurrentConfigSettings().apiHost
  const queryParams = queryStringify(parameters)
  const url = `${baseUrl}/scenarios/snowflake/snapshot-versions${queryParams}`

  const response = await fetch(url, {
    signal,
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status !== 200) {
    return Promise.reject()
  }
  const res = await response.json()
  return res
}

export type ActualsResponse = {
  actuals: Actual[]
}

// TODO: refine the sql query as all these properties are not needed. Ideally only `MONTH_ABBR` is sent back when assuming year, division, team and market are sent in
export type Actual = {
  YEAR: number // 2020;
  MONTH_NUMBER: number //1;
  MONTH_ABBR: string //'Jan';
  DIVISION: null | string //'reinsurance';
  TEAM: null | string //'Specialty Re';
  MARKET: null | string //'Aerospace';
  GWP_MONTH: number //234137.767374219;
  GWP_MONTH_RUNNING_TOTAL: number //88254639.5928281;
}

export type ActualsOptions = {
  signal: AbortSignal
  parameters: {
    year: undefined | string
    division: undefined | string
    team: undefined | string
    market: undefined | string
  }
}

export async function fetchActuals(options: ActualsOptions): Promise<ActualsResponse> {
  const { signal, parameters } = options

  const baseUrl: string = getCurrentConfigSettings().apiHost
  const queryParams = queryStringify(parameters)
  const url = `${baseUrl}/scenarios/actuals${queryParams}`

  const response = await fetch(url, {
    signal,
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status !== 200) {
    return Promise.reject()
  }
  const res = await response.json()
  return res
}
