import { Reducer } from 'react'
import { State } from '../StateTransition/StateTransitions'
import { DepthSelector, DynamicTableState } from './DynamicTable'

export type TableState = {
  table: DynamicTableState<number>
  state: State
}

export type Action =
  | { type: 'initialiseTable'; payload: { tableState: DynamicTableState<number> } }
  | { type: 'updateCell'; payload: { id: number; value: number } }
  | { type: 'applyUpdateFunction'; payload: { updateFunction: (id: number, value: number) => void } }
  | { type: 'applyChangeDepthSelector'; payload: { depthSelectorFunction: (id: number, depthKey: string) => void } }
  | { type: 'updateColumnOffset'; payload: { id: number; depthKey: string } }
  | { type: 'updateScaler'; payload: { id: number; scaleFactor: number; rowName: string; columnPosition: number } }
  | {
      type: 'applyScalerUpdate'
      payload: {
        scalerUpdateFunction: (id: number, scaleFactor: number, rowName: string, columnPostion: number) => void
      }
    }
  | { type: 'setDisabled'; payload: { disabled: boolean } }
  | { type: 'error' }

export const createInitialDynamicTableState = (): TableState => {
  return {
    table: {
      cells: [],
      columns: [],
      rows: [],
      depth: [],
      columnOffset: [],
      scaler: {},
      selector: [],
      selectorRowName: '',
      disabled: false,
    },
    state: 'fetching',
  }
}

export const dynamicTableReducer: Reducer<TableState, Action> = (state, action): TableState => {
  switch (action.type) {
    case 'initialiseTable':
      const disabled = state.table.disabled
      return {
        ...state,
        table: {
          ...action.payload.tableState,
          disabled: disabled,
        },
        state: 'fulfilled',
      }
    case 'updateCell':
      const newCellState = state.table.cells.slice()
      newCellState[action.payload.id].data = action.payload.value

      return {
        ...state,
        table: {
          ...state.table,
          cells: newCellState,
        },
      }
    case 'applyUpdateFunction': {
      const newCellState = state.table.cells.slice()
      newCellState.forEach((cell) => {
        cell.update = action.payload.updateFunction
      })

      return {
        ...state,
        table: {
          ...state.table,
          cells: newCellState,
        },
      }
    }
    case 'updateColumnOffset': {
      const depthPosition = state.table.depth.indexOf(action.payload.depthKey)
      // if the value was not found then don't do anything and return the state unchanged
      if (depthPosition === -1) {
        return state
      }

      // create a copy of the column offset
      const columnOffset = state.table.columnOffset.slice()

      // calculate the column from the position
      const columnPosition = action.payload.id % state.table.columns.length
      columnOffset[columnPosition] = depthPosition

      const newSelectors = state.table.selector.slice()
      newSelectors[action.payload.id].currentDepth = action.payload.depthKey
      newSelectors[action.payload.id].depthIndex = depthPosition

      return {
        ...state,
        table: {
          ...state.table,
          columnOffset,
          selector: newSelectors,
        },
      }
    }
    case 'applyChangeDepthSelector': {
      const newSelectors: DepthSelector[] = state.table.selector
      newSelectors.forEach((s) => (s.updateActiveColumn = action.payload.depthSelectorFunction))

      return {
        ...state,
        table: {
          ...state.table,
          selector: newSelectors,
        },
      }
    }
    case 'updateScaler': {
      const scalerCopy = Object.assign({}, state.table.scaler)
      scalerCopy[action.payload.rowName][action.payload.columnPosition] = action.payload.scaleFactor

      return {
        ...state,
        table: {
          ...state.table,
          scaler: scalerCopy,
        },
      }
    }

    case 'applyScalerUpdate': {
      const newCells = state.table.cells.slice()
      newCells.forEach((c) => {
        if (c.scaler) {
          c.updateScaler = action.payload.scalerUpdateFunction
        }
      })
      return {
        ...state,
      }
    }

    case 'setDisabled': {
      return {
        ...state,
        table: {
          ...state.table,
          disabled: action.payload.disabled,
        },
      }
    }
    case 'error': {
      return {
        ...state,
        state: 'rejected',
      }
    }
  }
}
