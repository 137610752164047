import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import { App } from './App'

export const rootDom: undefined | HTMLElement = document.getElementById('root') ?? undefined
if (!rootDom) {
  throw new Error('rootDom not found')
}

const root = createRoot(rootDom)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
