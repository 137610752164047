import { FilterValues } from './calculate-possible-filter-values'
import { getCurrentConfigSettings } from '../utils/config'
import { patchScenario } from './scenarios'
import { getAccessToken } from './teams'
import { NewAdjustment, PatchNewAdjustment } from './api-types/NewAdjustment.types'
import { Currency } from './currency'
import { PlacementType } from './new-clients'
import { isSameObject } from '../utils/objectComparison'

export const NEW_ITEM_PREVIEW_ID = 'preview'

export const itemIsNotPreview = (newItem: NewAdjustment): boolean => {
  return NEW_ITEM_PREVIEW_ID !== newItem.id
}

export const itemIsActive = (newItem: NewAdjustment): boolean => {
  return newItem.isEnabled
}

export const previewPortfolioNewAdjustmentIsUntouched = (newItem: NewAdjustment): boolean => {
  return isSameObject(newItem, defaultUntouchedPreviewPortfolioAdjustment)
}

export const defaultUntouchedPreviewPortfolioAdjustment: Omit<NewAdjustment, 'orderNumber' | 'scenarioId'> = {
  id: NEW_ITEM_PREVIEW_ID,
  isEnabled: true,
  name: '',
  description: '',
  gwpValue: 0,
  grossRiskAdjustedRateChange: '0',
  appliedFilters: {},
}

export interface CreateNewPortfolioItemRequest {
  name: string
  appliedFilters: FilterValues
  gwpValue: number
  grossRiskAdjustedRateChange: string
  description: string
}

export interface CreateNewPortfolioClientRequest {
  name: string
  description: string
  inceptionMonth: number
  segment: string
  classOfBusiness: string
  entity: string
  convexGwp: string
  attachment: string
  grossRiskAdjustedRateChange: number
  lossInput: number
  acqCost: number
  convexShare: number
  convexWrittenShare: number
  convexLimit: string
  probability: number
  currency: Currency
  placementType: PlacementType
}

export const isRelevantNewItem = (newItemId: string) => (newItem: NewAdjustment) => {
  return newItem.id === newItemId
}

export const isIrrelevantNewItem = (newItemId: string) => (newItem: NewAdjustment) => {
  return newItem.id !== newItemId
}

export function sortNewPortfolioItemsByOldestFirst(newItems: NewAdjustment[]): NewAdjustment[] {
  return [...newItems].sort((first, second) => first.orderNumber - second.orderNumber)
}

export async function createNewPortfolioItem(
  scenarioId: string,
  createNewPortfolioItemRequest: CreateNewPortfolioItemRequest,
) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createNewPortfolioItemRequest),
  })
  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}
  return await response.json()
}

// The patchScenario call will be removed in WAY-1599. Currently, it's used
// to update the updatedAt field of the scenario. It should be the responsibility
// of the backend to perform this update.
export async function deleteNewPortfolioItem(scenarioId: string, adjustmentId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments/${adjustmentId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return Promise.resolve()
}

export async function deleteAllPortfolioItems(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
}

export async function fetchNewPortfolioItem(scenarioId: string, adjustmentId: string): Promise<NewAdjustment> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments/${adjustmentId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

export async function fetchNewPortfolioItems(scenarioId: string): Promise<NewAdjustment[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  return await response.json()
}

export async function patchNewPortfolioItem(
  scenarioId: string,
  adjustmentId: string,
  patchAdjustmentRequest: PatchNewAdjustment,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/adjustments/${adjustmentId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchAdjustmentRequest),
    },
  )

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return await response.json()
}

export async function createNewPortfolioClient(
  scenarioId: string,
  createNewPortfolioClientRequest: CreateNewPortfolioClientRequest,
) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/new/clients`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createNewPortfolioClientRequest),
  })

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return await response.json()
}
