import React, { ReactElement } from 'react'
import './SplitContentContainer.scss'
export type SplitContentContainerProps = React.PropsWithChildren<{
  leftcolumn: ReactElement
  rightcolumn: ReactElement
}>

export function SplitContentContainer(props: SplitContentContainerProps) {
  return (
    <div
      className="SplitContentContainer"
      {...props}
    >
      <div className="SplitContentContainerLeft">{props.leftcolumn}</div>
      <div className="SplitContentContainerRight">{props.rightcolumn}</div>
    </div>
  )
}

export default SplitContentContainer
