import React, { MouseEventHandler, useContext, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import qs from 'qs'
import { RouteComponentProps, withRouter } from 'react-router'

import './StackedScenariosView.scss'

import StackedScenarioCard from '../stacked-scenario-card/StackedScenarioCard'
import SectionHeader from '../section-header/SectionHeader'
import { StackedScenarioListContext } from '../stacked-scenarios-list/StackedScenarioListProvider'
import SectionHeaderContainer from '../section-header-container/SectionHeaderContainer'
import { StartComparisonIcon } from '../../pages/scenario-chooser/StartComparisonIcon'
import Divider from '../divider/LineDivider'
import { StopComparisonIcon } from '../../pages/scenario-chooser/StopComparisonIcon'
import { css } from '../../utils/css'
import Button from '../../components/button/Button'
import { AppQueryContext } from '../../providers/AppQueryProvider'

export interface StackedScenariosViewProps extends RouteComponentProps {
  currentStackedScenarioGroupName: string
  currentlyChosenScenarioId: string | undefined
  onCurrentCreatingSelectChanges: (currentTeam: string, currentScenarioId: string | undefined) => void
  possibleToCreateDivisionScenario: (currentTeam: string, hasStackedScenario: boolean | undefined) => void
  isComparing: boolean
  currentlySelectedStackedScenarioIds: Array<string>
  updateCurrentlySelectedStackedScenarioIds: (newValues: Array<string>) => void
  onStartComparison: () => void
  onEndComparison: () => void
  onClickForComparison: (name: string | undefined) => void
  className: string
}

function StackedScenariosView(props: StackedScenariosViewProps): JSX.Element {
  const {
    className,
    currentStackedScenarioGroupName,
    currentlyChosenScenarioId,
    onCurrentCreatingSelectChanges,
    possibleToCreateDivisionScenario,
    isComparing,
    currentlySelectedStackedScenarioIds,
    updateCurrentlySelectedStackedScenarioIds,
    onStartComparison,
    onEndComparison,
    onClickForComparison,
    ...otherProps
  } = props

  const [actualDataItemName] = useQueryParam('actuals', StringParam)

  const { currentStackedScenarios, reloadStackedScenarios } = useContext(StackedScenarioListContext)
  const { yearQuery, portfoliolevelQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery
  const [portfoliolevelFromQuery, setPortfoliolevel] = portfoliolevelQuery
  const [divisionFromQuery, setDivision] = divisionQuery
  const [teamFromQuery, setTeam] = teamQuery

  const [currentlyComparingGroup] = useState<string | undefined>(undefined)

  const sortCurrentScenariosByUpdatedDate = currentStackedScenarios.sort(
    (a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt),
  )

  useEffect(
    () => possibleToCreateDivisionScenario(currentStackedScenarioGroupName, currentStackedScenarios.length > 0),
    [possibleToCreateDivisionScenario, currentStackedScenarioGroupName, currentStackedScenarios.length],
  )

  const toggleIsCreating = (scenarioIdIsBeingToggled: string) => () => {
    if (currentlyChosenScenarioId === scenarioIdIsBeingToggled) {
      onCurrentCreatingSelectChanges(currentStackedScenarioGroupName, undefined)
    } else {
      onCurrentCreatingSelectChanges(currentStackedScenarioGroupName, scenarioIdIsBeingToggled)
    }
  }

  const toggleIsComparing = (stackedScenarioId: string) => () => {
    const alreadyThere = currentlySelectedStackedScenarioIds.includes(stackedScenarioId)

    if (alreadyThere) {
      updateCurrentlySelectedStackedScenarioIds(
        currentlySelectedStackedScenarioIds.filter((id) => id !== stackedScenarioId),
      )
    } else {
      updateCurrentlySelectedStackedScenarioIds([...currentlySelectedStackedScenarioIds, stackedScenarioId])
    }
  }

  const startComparingScenarios = () => {
    updateCurrentlySelectedStackedScenarioIds([])
    onClickForComparison(currentStackedScenarioGroupName)
    onStartComparison()
  }

  const stopComparingScenarios = () => {
    updateCurrentlySelectedStackedScenarioIds([])
    onClickForComparison(undefined)
    onEndComparison()
  }

  const goToStackedComparisonScreen = () => {
    if (currentlySelectedStackedScenarioIds.length) {
      const queryParams = {
        stackedScenarioIds: currentlySelectedStackedScenarioIds,
        portfoliolevel: portfoliolevelFromQuery,
        division: divisionFromQuery || currentlyComparingGroup,
        team: teamFromQuery,
        year: yearFromQuery,
        actuals: actualDataItemName,
      }

      // eslint-disable-next-line
      const fullRoute = `/stacked-scenarios-comparison?${qs.stringify(queryParams)}`
      otherProps.history.push(fullRoute)
    }
  }

  const navigateToCorrectPlace: MouseEventHandler = (e) => {
    if (portfoliolevelFromQuery === 'Organisational') {
      setPortfoliolevel('Divisional')
      return
    }

    if (!divisionFromQuery) {
      setDivision(currentStackedScenarioGroupName)
      return
    }
    if (!teamFromQuery) {
      //Must be divisional level
      setTeam(currentStackedScenarioGroupName)
      return
    }
  }

  return (
    <div className={css('StackedScenariosContainer', className)}>
      <SectionHeaderContainer>
        <div className="StackedScenariosContainerLeft">
          {!isComparing && <StartComparisonIcon onClick={startComparingScenarios} />}
          {isComparing && <StopComparisonIcon onClick={stopComparingScenarios} />}
          <SectionHeader
            title={currentStackedScenarioGroupName}
            onClick={navigateToCorrectPlace}
          />
        </div>
        <div className="StackedScenariosContainerRight">
          {isComparing && (
            <Button
              title="View Comparison"
              onClick={goToStackedComparisonScreen}
              className="ViewComparisonButton"
            />
          )}
        </div>
      </SectionHeaderContainer>
      <div className="StackedScenarioCards">
        {sortCurrentScenariosByUpdatedDate.map((stackedScenario) => (
          <StackedScenarioCard
            key={stackedScenario.id}
            stackedScenario={stackedScenario}
            triggerStackedScenarioReload={reloadStackedScenarios}
            creatingIsSelected={currentlyChosenScenarioId === stackedScenario.id}
            creatingOnToggle={toggleIsCreating(stackedScenario.id)}
            selectable
            comparingOnToggle={toggleIsComparing(stackedScenario.id)}
            isComparing={isComparing}
            comparingIsSelected={currentlySelectedStackedScenarioIds.includes(stackedScenario.id)}
          />
        ))}
      </div>
      <div className="EndPadding" />
      <Divider />
    </div>
  )
}

export default withRouter(StackedScenariosView)
