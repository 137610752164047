import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'

import './OutwardScenarioChooser.scss'

import { OptionsType } from '../../utils/lists'
import { FinishCreatingStackedScenarioModal } from '../scenario-chooser/FinishCreatingStackedScenarioModal'
import { onSelectSingleValueFromMulti } from '../scenario-chooser/ScenarioChooser'
import OutwardScenarioList from './OutwardScenarioList'
import { OutwardsPortfolioListingContext } from '../../providers/outwards/OutwardsPortfolioListingProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import AllOutwardScenarioProvider from '../../providers/outwards/AllOutwardScenarioProvider'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { ProgramListing, TreatyListing, VersionListing } from '../../backend/outwards-portfolioListing'
import ContentContainer from '../../components/content-container/ContentContainer'
import { StackedScenarioEditingContext } from '../../components/stacked-scenarios-list/StackedScenarioEditingProvider'
import { FinishCreatingTagModal } from '../../components/tag/FinishCreatingTagModal'
import { areEqual } from '../../utils/objects'

interface OutwardScenarioChooserContainer {
  availableTreaties: string[]
  availableYears: string[]
  availablePrograms: string[]
  setAvailablePrograms: (item: string[]) => void
  availableLayers: string[]
  setAvailableLayers: (item: string[]) => void
  availableVersions: string[]
  setAvailableVersions: (item: string[]) => void
  selectNewProgramme: (item: OptionsType[]) => void
}

export const OutwardScenarioChooserContext = createContext<OutwardScenarioChooserContainer>({
  availablePrograms: [],
  availableTreaties: [],
  availableYears: [],
  availableLayers: [],
  availableVersions: [],
  setAvailableLayers: () => void 0,
  selectNewProgramme: () => void 0,
  setAvailablePrograms: () => void 0,
  setAvailableVersions: () => void 0,
})

export function OutwardScenarioChooserProvider(props: PropsWithChildren<{}>) {
  const [programmeFromQuery, setProgramme] = useQueryParam('programme', StringParam)
  const [treatyFromQuery, setTreaty] = useQueryParam('treaty', StringParam)
  const [versionFromQuery, setVersion] = useQueryParam('version', StringParam)

  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { outwardPortfolioListing, allTreatiesWithVersionsAndYearsListing } = useContext(
    OutwardsPortfolioListingContext,
  )
  const { yearQuery } = useContext(AppQueryContext)

  const [yearFromQuery, setYear] = yearQuery

  const currentYear = currentOutwardScenario ? currentOutwardScenario?.year : yearFromQuery
  const currentProgramme = currentOutwardScenario ? currentOutwardScenario?.programme : programmeFromQuery
  const currentTreaty = currentOutwardScenario ? currentOutwardScenario?.treaty : treatyFromQuery
  const currentVersion = currentOutwardScenario ? currentOutwardScenario?.version : versionFromQuery

  const [availableYears, setAvailableYears] = useState<string[]>([])
  const [availablePrograms, setAvailablePrograms] = useState<string[]>([])
  const [availableVersions, setAvailableVersions] = useState<string[]>([])
  const [availableTreaties, setAvailableTreaties] = useState<string[]>([])
  const [availableLayers, setAvailableLayers] = useState<string[]>([])

  const stringVersionOfAvailableProgrammes = JSON.stringify(availablePrograms)
  const isOnOutwardScenarioChooserPage = window.location.pathname.includes('/outwardScenario-chooser')

  useEffect(() => {
    let versionsForCurrentYear: VersionListing | undefined = undefined
    let programmesForCurrentVersion: ProgramListing | undefined = undefined
    let treatiesForCurrentProgramme: TreatyListing | undefined = undefined

    if (outwardPortfolioListing && Object.keys(outwardPortfolioListing).length) {
      setAvailableYears(Object.keys(outwardPortfolioListing))

      if (availableYears && currentYear !== undefined) {
        versionsForCurrentYear = outwardPortfolioListing[currentYear as string] || {}
        setAvailableVersions(Object.keys(versionsForCurrentYear))

        if (availableVersions.includes(currentVersion || '')) {
          programmesForCurrentVersion = versionsForCurrentYear[currentVersion as string] || {}
          setAvailablePrograms(Object.keys(programmesForCurrentVersion))

          if (availablePrograms.includes(currentProgramme || '')) {
            treatiesForCurrentProgramme = programmesForCurrentVersion[currentProgramme as string] || {}
            setAvailableTreaties(Object.keys(treatiesForCurrentProgramme))

            if (availableTreaties.includes(currentTreaty || '')) {
              const layerDetailsForCurrentTreaty = Object.values(
                treatiesForCurrentProgramme[currentTreaty as string] || {},
              ).flatMap((layerDetails) => ({ layers: layerDetails.layer }))
              setAvailableLayers(layerDetailsForCurrentTreaty.map((layerDetail) => layerDetail.layers))
            }
          }
        }
      }
    }

    const newAvailableTreaties =
      currentProgramme && treatiesForCurrentProgramme ? Object.keys(treatiesForCurrentProgramme) : []
    if (!areEqual(newAvailableTreaties, availableTreaties)) {
      setAvailableTreaties(newAvailableTreaties)
      setAvailableLayers([])
    }

    const newAvailablePrograms =
      currentYear && programmesForCurrentVersion ? Object.keys(programmesForCurrentVersion) : []
    if (!areEqual(newAvailablePrograms, availablePrograms)) {
      setAvailablePrograms(newAvailablePrograms)
      setAvailableTreaties([])
    }

    const newAvailableVersions = currentYear && versionsForCurrentYear ? Object.keys(versionsForCurrentYear) : []
    if (!areEqual(newAvailableVersions, availableVersions)) {
      setAvailableVersions(newAvailableVersions)
      setAvailablePrograms([])
    }

    if (isOnOutwardScenarioChooserPage && currentYear && !currentVersion && availableVersions) {
      setVersion(availableVersions[availableVersions.length - 1])
    }

    if (currentYear && currentVersion && !currentTreaty && allTreatiesWithVersionsAndYearsListing) {
      const treatiesForCurrentVersion = allTreatiesWithVersionsAndYearsListing[currentYear]
      if (!treatiesForCurrentVersion) {
        console.warn(`Something went wrong if we're seeing this message!!`)
        return
      }
      const layerDetailList = treatiesForCurrentVersion[currentVersion]

      const allLayersForCurrentYearAndCurrentVersion = layerDetailList.map((layerDetail) => layerDetail.layer)
      setAvailableLayers(allLayersForCurrentYearAndCurrentVersion)
    }

    if (currentProgramme && !newAvailablePrograms.includes(`${currentProgramme}`)) {
      setProgramme(null)
      setTreaty(null)
    }

    if (!currentProgramme && currentTreaty) {
      setTreaty(null)
    }

    if (currentTreaty && !newAvailableTreaties.includes(currentTreaty as string)) {
      setTreaty(null)
    }
    // eslint-disable-next-line
  }, [
    outwardPortfolioListing,
    stringVersionOfAvailableProgrammes,
    setAvailablePrograms,
    setAvailableLayers,
    setAvailableVersions,
    currentProgramme,
    setProgramme,
    currentTreaty,
    setTreaty,
    currentYear,
    setYear,
    currentVersion,
    setVersion,
  ])

  const selectNewProgramme = (values: OptionsType[]) => {
    setTreaty('')
    onSelectSingleValueFromMulti(setProgramme)(values)
  }

  return (
    <OutwardScenarioChooserContext.Provider
      children={props.children}
      value={{
        availablePrograms,
        setAvailablePrograms,
        availableLayers,
        setAvailableLayers,
        availableVersions,
        setAvailableVersions,
        selectNewProgramme,
        availableYears,
        availableTreaties,
      }}
    />
  )
}

function OutwardScenarioChooser() {
  const [programmeFromQuery] = useQueryParam('programme', StringParam)
  const [treatyFromQuery] = useQueryParam('treaty', StringParam)
  const [versionFromQuery] = useQueryParam('version', StringParam)

  const { availableTreaties, availableLayers, availablePrograms } = useContext(OutwardScenarioChooserContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { allTreatiesWithVersionsAndYearsListing, allTreatiesWithProgrammesListing, reloadListing } = useContext(
    OutwardsPortfolioListingContext,
  )
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery

  const currentYear = currentOutwardScenario?.year || yearFromQuery
  const currentProgramme = currentOutwardScenario ? currentOutwardScenario?.programme : programmeFromQuery
  const currentTreaty = currentOutwardScenario ? currentOutwardScenario?.treaty : treatyFromQuery
  const currentVersion = currentOutwardScenario ? currentOutwardScenario?.version : versionFromQuery

  useEffect(() => {
    updateIndividualProgressItem('outwardsPortfolioListingFetch', ProgressState.LOADING)
    reloadListing().then(() => updateIndividualProgressItem('outwardsPortfolioListingFetch', ProgressState.FINISHED))
    // eslint-disable-next-line
  }, [updateIndividualProgressItem])

  const { currentlyChosenScenarioIds, setCurrentlyChosenScenarioIds, currentlyCreatingStackedScenario } =
    useContext(StackedScenarioEditingContext)

  const updateCurrentlySelectedScenarios = (layerToChange: string, newValueOfScenarioId: string | undefined) => {
    const newValue = {
      ...currentlyChosenScenarioIds,
      [layerToChange]: newValueOfScenarioId,
    }
    setCurrentlyChosenScenarioIds(newValue)
  }

  return (
    <div className="OutwardScenarioChooser">
      <FinishCreatingStackedScenarioModal
        availableDivisions={availablePrograms}
        availableMarkets={availableLayers}
        availableTeams={availableTreaties}
        allowValidationIfUserConfirms
      />
      <FinishCreatingTagModal />
      <ContentContainer>
        <div className="ScenarioListContainer">
          {currentYear &&
            currentVersion &&
            !currentProgramme &&
            !currentTreaty &&
            allTreatiesWithVersionsAndYearsListing &&
            allTreatiesWithVersionsAndYearsListing[currentYear] &&
            allTreatiesWithVersionsAndYearsListing[currentYear]![currentVersion]?.map((layerDetails) => (
              <OutwardScenarioList
                key={`${currentVersion}-${currentProgramme}-${currentTreaty}-${layerDetails.layer}`}
                programme={layerDetails.program}
                treaty={layerDetails.treaty}
                layer={layerDetails.layer}
                version={layerDetails.planVersion}
                year={currentYear}
                className={''}
                isCreatingStackedScenario={currentlyCreatingStackedScenario}
                currentlyChosenScenarioIds={
                  currentlyChosenScenarioIds ? currentlyChosenScenarioIds[layerDetails.layer] : undefined
                }
                onCurrentCreatingSelectChanges={updateCurrentlySelectedScenarios}
              />
            ))}
          {currentYear &&
            currentVersion &&
            currentProgramme &&
            !currentTreaty &&
            allTreatiesWithProgrammesListing &&
            allTreatiesWithProgrammesListing[currentYear] &&
            allTreatiesWithProgrammesListing[currentYear]![currentVersion] &&
            allTreatiesWithProgrammesListing[currentYear]![currentVersion]![currentProgramme]?.map((layerDetails) => (
              <OutwardScenarioList
                key={`${currentVersion}-${currentProgramme}-${currentTreaty}-${layerDetails.layer}`}
                programme={layerDetails.program}
                treaty={layerDetails.treaty}
                layer={layerDetails.layer}
                version={layerDetails.planVersion}
                year={currentYear}
                className={''}
                isCreatingStackedScenario={currentlyCreatingStackedScenario}
                currentlyChosenScenarioIds={
                  currentlyChosenScenarioIds ? currentlyChosenScenarioIds[layerDetails.layer] : undefined
                }
                onCurrentCreatingSelectChanges={updateCurrentlySelectedScenarios}
              />
            ))}
          {currentVersion &&
            currentProgramme &&
            currentTreaty &&
            currentYear &&
            availableTreaties &&
            availableLayers.map((layer) => (
              <OutwardScenarioList
                key={`${currentVersion}-${currentProgramme}-${currentTreaty}-${layer}`}
                programme={currentProgramme}
                treaty={currentTreaty}
                layer={layer}
                version={currentVersion}
                year={currentYear}
                className={''}
                isCreatingStackedScenario={currentlyCreatingStackedScenario}
                currentlyChosenScenarioIds={currentlyChosenScenarioIds ? currentlyChosenScenarioIds[layer] : undefined}
                onCurrentCreatingSelectChanges={updateCurrentlySelectedScenarios}
              />
            ))}
        </div>
      </ContentContainer>
    </div>
  )
}

const OutwardScenarioChooserWithRouter = withRouter(OutwardScenarioChooser)
const OutwardScenarioChooserWithPortfolioListing = () => (
  <AllOutwardScenarioProvider>
    <OutwardScenarioChooserWithRouter />
  </AllOutwardScenarioProvider>
)

export default OutwardScenarioChooserWithPortfolioListing
