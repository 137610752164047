import { useCallback, useContext } from 'react'
import { PseudoPortfolioContext } from '../../../providers/NewPseudoPortfolioItemsProvider'
import { NewClientItemsContext } from '../../../providers/NewClientItemsProvider'
import { NewCustomPortfolioAdjustmentContext } from '../../../providers/NewCustomPortfolioAdjustmentsProvider'
import { NewAdjustment } from '../../../backend/api-types/NewAdjustment.types'
import { itemIsActive, previewPortfolioNewAdjustmentIsUntouched } from '../../../backend/new-portfolio-items'

export const useCountNewAdjustments = (): number => {
  const { newDataInputs: defaultNewAdjustments } = useContext(PseudoPortfolioContext)
  const { newClients } = useContext(NewClientItemsContext)
  const { ncpAdjustments } = useContext(NewCustomPortfolioAdjustmentContext)

  const relevantNewAdjustments: (adjustment: NewAdjustment) => boolean = useCallback(
    (adjustment: NewAdjustment) => itemIsActive(adjustment) && !previewPortfolioNewAdjustmentIsUntouched(adjustment),
    [],
  )

  return (
    (defaultNewAdjustments?.filter(relevantNewAdjustments).length || 0) +
    (newClients?.filter((client) => client.isEnabled).length || 0) +
    (ncpAdjustments?.filter(relevantNewAdjustments).length || 0)
  )
}
