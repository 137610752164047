import React, { useState } from 'react'

import './MarketDependencySelector.scss'

import { AttritionalDependencyShorthand } from '../../backend/parameterisation'
import Button from '../button/Button'
import { CssGridItem } from '../css-grid/CssGridRows'
import { GridContainer } from '../css-grid/GridContainer'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { toOption } from '../../utils/lists'
import { areEqual } from '../../utils/objects'

type Props = {
  initialMatrix: Matrix
  handleSetMatrix: (matrix: Matrix) => void
  ok: () => void
  cancel: () => void
}

const options: Array<{ label: AttritionalDependencyShorthand; value: AttritionalDependencyShorthand }> = [
  { label: 'L', value: 'L' },
  { label: 'M', value: 'M' },
  { label: 'H', value: 'H' },
]

type DependencyValue = string | AttritionalDependencyShorthand | '1'

export type MarketDependency = {
  division: string
  team: string
  market_1: string
  market_2: string
  year: string
  dependency: DependencyValue
}

export type MatrixLine = {
  key: number
  row: number
  column: number
  item: undefined | MarketDependency
  label?: string
  status: Status
}

export type Matrix = MatrixLine[]

export type PartialMatrixLine = Omit<MatrixLine, 'key'>

type Status = 'enabled' | 'hidden' | 'disabled' | 'row-heading' | 'col-heading'

export function MarketDependencySelector(props: Props) {
  const [active, setActive] = useState<Matrix>(props.initialMatrix || [])

  const isUpToDateWithRemote = areEqual(props.initialMatrix, active)

  return (
    <div className="MarketDependency">
      <GridContainer classNameOverride="GridContainerDependencySelector">
        {renderMatrix(setActive, active)}
      </GridContainer>

      <div className="SaveContainer">
        <div className={isUpToDateWithRemote ? 'SaveContainerTitle' : 'UnsaveContainerTitle'}>
          {isUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}
        </div>
        <div>
          <Button
            title="Cancel"
            className="MarketDependencyButton"
            onClick={() => props.cancel()}
            secondary={true}
          />
        </div>
        <div>
          <Button
            title="Save"
            className="Button"
            onClick={() => {
              const next: Matrix = active
              props.handleSetMatrix(next)
              props.ok()
            }}
          />
        </div>
      </div>
    </div>
  )
}

function renderMatrix(setActive: React.Dispatch<React.SetStateAction<Matrix>>, matrix: Matrix): JSX.Element[] {
  const topLeftCorner: JSX.Element = (
    <CssGridItem
      classNameOverride="GridItem"
      key={'corner'}
      rowNumber={1}
      columnNumber={1}
    >
      <div />
    </CssGridItem>
  )
  return matrix
    .map((matrixLine) => {
      if (matrixLine.status === 'hidden') {
        return (
          <CssGridItem
            classNameOverride="GridItem"
            key={matrixLine.key}
            rowNumber={matrixLine.row}
            columnNumber={matrixLine.column}
          >
            <div />
          </CssGridItem>
        )
      }
      if (matrixLine.status === 'row-heading') {
        return (
          <CssGridItem
            classNameOverride="GridItem"
            key={matrixLine.key}
            rowNumber={matrixLine.row}
            columnNumber={matrixLine.column}
          >
            <div
              style={{
                fontWeight: 'bold',
                writingMode: 'vertical-rl',
                transform: 'rotate(-180deg)',
              }}
            >
              {matrixLine.label}
            </div>
          </CssGridItem>
        )
      }
      if (matrixLine.status === 'col-heading') {
        return (
          <CssGridItem
            classNameOverride="GridItem"
            key={matrixLine.key}
            rowNumber={matrixLine.row}
            columnNumber={matrixLine.column}
          >
            <div
              style={{
                fontWeight: 'bold',
              }}
            >
              {matrixLine.label}
            </div>
          </CssGridItem>
        )
      }
      if (matrixLine.status === 'enabled') {
        return (
          <CssGridItem
            key={matrixLine.key}
            rowNumber={matrixLine.row}
            columnNumber={matrixLine.column}
          >
            <MultiSelectDropdown
              options={options}
              isDisabled={false}
              selected={[toOption(matrixLine.item!.dependency)]}
              onSelect={(select) => {
                const dependency = select[0].value as AttritionalDependencyShorthand
                setActive((previous) => updateMatrix(dependency, matrixLine, previous))
              }}
            />
          </CssGridItem>
        )
      }
      if (matrixLine.status === 'disabled') {
        return (
          <CssGridItem
            key={matrixLine.key}
            rowNumber={matrixLine.row}
            columnNumber={matrixLine.column}
          >
            <MultiSelectDropdown
              options={options}
              isDisabled={true}
              selected={[toOption(matrixLine.item!.dependency)]}
              onSelect={() => {}}
            />
          </CssGridItem>
        )
      }
      throw new Error(`Unhandled status: ${JSON.stringify(matrixLine)}`)
    })
    .concat(topLeftCorner)
}

// Update the matrix with new values.
// The matrix mirrors values when an item is changed, so for example:
// an item at [3,1] will also update the item at [1,3]
function updateMatrix(dependency: AttritionalDependencyShorthand, target: MatrixLine, previous: Matrix): Matrix {
  // Build a list of items which need to be replaced in the array. The index is current targets which is later used to splice into the new array
  const nextMatrixLineItems: { [index: number]: MatrixLine } = previous
    // Pluck out both items
    .filter((p: MatrixLine) => {
      const same = target.column === p.column && target.row === p.row
      const rotated = target.column === p.row && target.row === p.column
      return same || rotated
    })
    // Look up their positions in the array
    .map((m) => previous.findIndex((p) => p.key === m.key))
    // Extract the values while retaining the position
    .map((index) => ({ index, matrixLine: previous[index] }))
    // Update the line item with the updated dependency
    .map(({ index, matrixLine }) => {
      const nextMatrixLine: MatrixLine = { ...matrixLine, item: { ...matrixLine.item!, dependency } }
      return { index, matrixLine: nextMatrixLine }
    })
    // Prepare the pending object to a format which allows it to be merged in using Object.assign()
    .reduce(
      (p, { index, matrixLine }) => {
        return { ...p, [index]: matrixLine }
      },
      {} as { [index: number]: MatrixLine },
    )
  // Merge in the results with the updated status
  return Object.assign([], previous, nextMatrixLineItems)
}
