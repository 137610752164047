import { useCategoryForm } from './hooks/useCategoryForm'
import { FormProvider } from 'react-hook-form'
import { useContext, useMemo } from 'react'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { getTop10TargetMarketValues } from '../../utils/targetMarketColumns'
import { AutoSelectAllColumns, DynamicCategoryTable } from './DynamicCategoryTable'
import { InterestTypeFields } from './types'

export const InterestTypeTable = () => {
  const { filteredRenewalDataForScenario } = useContext(FullScenarioDataContext)
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)

  const form = useCategoryForm<InterestTypeFields>({
    defaultValues: { interestTypes: [] },
  })

  const top10InterestTypes = useMemo(
    () => getTop10TargetMarketValues(filteredRenewalDataForScenario, 'Interest Type'),
    [filteredRenewalDataForScenario],
  )
  const interestTypeOptions = useMemo(() => top10InterestTypes.map((x) => ({ label: x })), [top10InterestTypes])

  return top10InterestTypes.length === 0 ? (
    <></>
  ) : (
    <FormProvider {...form}>
      <DynamicCategoryTable<InterestTypeFields, AutoSelectAllColumns>
        categoryLabel="Interest Type"
        categoryName="interestType"
        dynamicFieldsName="interestTypes"
        columnsOptions={interestTypeOptions}
      />
    </FormProvider>
  )
}
