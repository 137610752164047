import React, { MouseEventHandler } from 'react'
import { css } from '../../utils/css'
import './SectionHeader.scss'

export type SectionHeaderProps = React.PropsWithChildren<{
  title: string
  className?: string | string[]
  onClick?: MouseEventHandler
}>

export function SectionHeader(props: SectionHeaderProps) {
  return (
    <h2
      className={css('SectionHeader', props.className)}
      onClick={props.onClick}
    >
      {props.title}
    </h2>
  )
}

export default SectionHeader
