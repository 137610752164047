import { PortfolioListing } from '../../../../backend/src/modules/portfolios/domain/PortfolioListing.types'

export interface DefaultLocation {
  teamName?: string
  divisionName?: string
}

export function calculateLocationToSendUserTo(portfolio: PortfolioListing): DefaultLocation | undefined {
  const allDivisionNames = Object.keys(portfolio?.divisions || {})

  if (!allDivisionNames.length) {
    return undefined
  }

  const teamsToRedirectTo = allDivisionNames
    .map((currentDivisionName) => {
      const currentDivision = portfolio?.divisions[currentDivisionName]!

      const firstTeamWithWriteAccess = currentDivision?.teams?.find((item) => item.canWriteToTeam)

      if (firstTeamWithWriteAccess) {
        return {
          teamName: firstTeamWithWriteAccess?.name,
          divisionName: currentDivisionName,
        }
      }

      return undefined
    })
    .filter((item) => item !== undefined)

  if (teamsToRedirectTo.length === 1) {
    return teamsToRedirectTo[0]
  }

  if (allDivisionNames.length === 1) {
    return { divisionName: allDivisionNames[0] }
  }

  return undefined
}
