import { PossiblyNegativeNumber } from '../../../utils/numbers'
import { NewBusinessTypeWithCustomPortfolio } from '../../../pages/new/New'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from '../../../providers/ProgressProvider'
import { PseudoPortfolioContext } from '../../../providers/NewPseudoPortfolioItemsProvider'
import { transformIntoFlatStructure } from '../../../providers/FilterProvider'
import { NcpContext, NcpDispatchContext } from '../../../providers/NewCustomPortfolioStateProvider'
import { useHandleBackendErrors } from './useHandleBackendErrors'
import { calculateNewGrarcInputError, calculateNewGwpInputError } from '../validationMessages'
import { isValidName } from '../../../utils/validity'
import { validateDescription } from '../../create-scenario-card/CreateScenarioCard'
import { createNewPortfolioItem, patchNewPortfolioItem } from '../../../backend/new-portfolio-items'
import {
  createNewCustomPortfolioItem,
  NewCustomPortfolioItem,
  patchNewCustomPortfolioItem,
} from '../../../backend/new-custom-portfolio-items'
import { getCategoryWeightings } from '../../../utils/ncpCreation'
import { useResetAfterSuccess, UseResetAfterSuccessOptions } from './useResetAfterSuccess'
import { NewCustomPortfolioAdjustmentContext } from '../../../providers/NewCustomPortfolioAdjustmentsProvider'
import { FilterContext } from '../../../providers/FilterProvider'

type UseOnClickCreateOrUpdateItemOptions = {
  currentGwpInput: PossiblyNegativeNumber
  newPortfolioType: NewBusinessTypeWithCustomPortfolio
  currentGrarcInput: PossiblyNegativeNumber
  currentName: string
  currentDescription: string
  isPreview: boolean
  itemBeingEditedId: string
  scenarioId: string
} & UseResetAfterSuccessOptions

export const useOnClickCreateOrUpdateItem = (options: UseOnClickCreateOrUpdateItemOptions): (() => Promise<void>) => {
  const [resetData, setResetData] = useState<boolean>(false)

  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { triggerReloadSavedNewDataInputs, errors } = useContext(PseudoPortfolioContext)
  const { triggerReloadSavedNcpAdjustments } = useContext(NewCustomPortfolioAdjustmentContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const ncpState = useContext(NcpContext)
  const ncpDispatch = useContext(NcpDispatchContext)
  const handleBackendErrors: (err: any) => void = useHandleBackendErrors((message: string) =>
    options.setErrorUniqueName(message),
  )
  const resetAfterSuccess: () => void = useResetAfterSuccess(options)

  useEffect(() => {
    const triggerReloadOfDataWhenGwpAndGrarcInputsHasBeenResetAfterSuccessfulAddingOfNcp = () => {
      if (options.currentGwpInput === ('' as any) && options.currentGrarcInput === ('' as any) && resetData) {
        // TODO the bloody PossiblyNegativeNumber nonsense...
        setResetData(false)
        triggerReloadSavedNewDataInputs()
        triggerReloadSavedNcpAdjustments()
      }
    }
    triggerReloadOfDataWhenGwpAndGrarcInputsHasBeenResetAfterSuccessfulAddingOfNcp()
  }, [options.currentGwpInput, options.currentGrarcInput, resetData])

  useEffect(() => {
    if (ncpState?.reset) {
      setResetData(true)
      ncpDispatch?.({ type: 'reset', payload: { reset: false } })
    }
  }, [ncpState?.reset])

  // TODO this is a monster method definitely needs some refactor
  return useCallback(async () => {
    options.setShouldValidate(true)

    if (
      calculateNewGwpInputError(options.currentGwpInput) ||
      (options.newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL &&
        calculateNewGrarcInputError(options.currentGrarcInput)) ||
      !isValidName(options.currentName) ||
      validateDescription(options.currentDescription) ||
      errors.length
    ) {
      return
    }

    const checkBackendResponse = (response: object) => {
      updateIndividualProgressItem('savingNewItem', ProgressState.FINISHED)

      if (JSON.stringify(response).toString().includes('Adjustment name already exists for scenario')) {
        options.setErrorUniqueName('Unique name is required')
        updateIndividualProgressItem('savingNewItem', ProgressState.ERROR)
        throw Error
      }
    }

    updateIndividualProgressItem('savingNewItem', ProgressState.LOADING)

    if (options.isPreview) {
      if (options.newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL) {
        try {
          const response = await createNewPortfolioItem(options.scenarioId, {
            name: options.currentName,
            gwpValue: options.currentGwpInput as number,
            grossRiskAdjustedRateChange: options.currentGrarcInput as string,
            description: options.currentDescription,
            appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
          })
          checkBackendResponse(response)
          resetAfterSuccess()
        } catch (err: unknown) {
          handleBackendErrors(err)
        }
      }
      if (options.newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO) {
        const createNcpRequestBody: NewCustomPortfolioItem = {
          ...ncpState!.ncpData,
          categoryWeightings: getCategoryWeightings(ncpState!.ncpData.categoryWeightings),
          name: options.currentName,
          description: options.currentDescription,
          isEnabled: true,
        }
        try {
          await createNewCustomPortfolioItem(options.scenarioId, createNcpRequestBody)
          updateIndividualProgressItem('savingNewItem', ProgressState.FINISHED)
          resetAfterSuccess()
        } catch (err: unknown) {
          updateIndividualProgressItem('savingNewItem', ProgressState.FINISHED)
          handleBackendErrors(err)
        }
      }
    } else {
      if (options.newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL) {
        patchNewPortfolioItem(options.scenarioId, options.itemBeingEditedId, {
          name: options.currentName,
          gwpValue: options.currentGwpInput as number,
          grossRiskAdjustedRateChange: options.currentGrarcInput as string,
          description: options.currentDescription,
          appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
        }).then((response) => {
          checkBackendResponse(response)
          resetAfterSuccess()
        })
      }
      if (options.newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO) {
        const patchNcpRequestBody: Partial<NewCustomPortfolioItem> = {
          ...ncpState!.ncpData,
          name: options.currentName,
          description: options.currentDescription,
          segments: ncpState!.ncpData.segments,
          categoryWeightings: getCategoryWeightings(ncpState!.ncpData.categoryWeightings),
        }
        try {
          await patchNewCustomPortfolioItem(options.scenarioId, options.itemBeingEditedId, patchNcpRequestBody)
          updateIndividualProgressItem('savingNewItem', ProgressState.FINISHED)
          resetAfterSuccess()
        } catch (err: unknown) {
          updateIndividualProgressItem('savingNewItem', ProgressState.FINISHED)
          handleBackendErrors(err)
        }
      }
    }
  }, [
    updateIndividualProgressItem,
    handleBackendErrors,
    resetAfterSuccess,
    errors,
    dropdownFilterOptions,
    ncpState,
    options,
  ])
}
