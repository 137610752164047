import { useContext } from 'react'
import { StackedScenarioMarketContentV2 } from './StackedScenarioMarketContentV2'
import { NotesDataProvider, NoteSectionNames } from '../notes/NotesDataProvider'
import { AggregatedMarketDataProviderV2 } from '../../providers/AggregatedMarketDataProviderV2'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'

export function StackedScenarioMarketV2() {
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  if (!currentStackedScenario?.id) {
    return (
      <div className="Market">
        <SelectScenarioMessage />
      </div>
    )
  }

  const NotesPrefix = 'stacked-scenario-market-v2-'
  const idToUse = `${NotesPrefix}-${currentStackedScenario.id}`
  const segmentsToDisplay = [
    NoteSectionNames.MARKET_COMMENTARY,
    NoteSectionNames.PEAK_CONVEX_VISION,
    NoteSectionNames.UW_COMMENTARY_ON_ABE,
  ]

  return (
    <div>
      <MarketV2Provider>
        <AggregatedMarketDataProviderV2>
          <StackedScenarioDataProvider stackedScenario={currentStackedScenario}>
            <NotesDataProvider
              idToUse={idToUse}
              segmentsToDisplay={segmentsToDisplay}
            >
              <StackedScenarioMarketContentV2 currentStackedScenario={currentStackedScenario} />
            </NotesDataProvider>
          </StackedScenarioDataProvider>
        </AggregatedMarketDataProviderV2>
      </MarketV2Provider>
    </div>
  )
}
