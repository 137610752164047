/**
 * @description just a type for options
 */
type QueryStringifyOptions = {
  year?: null | string
  division?: null | string
  team?: null | string
  market?: null | string

  version?: null | string
}

function joinParams(options: QueryStringifyOptions): string {
  return Object.entries(options)
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => [encodeURIComponent(key), encodeURIComponent(String(value))])
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

/**
 * @description Format the query params. (Ideally) To update the url params (instead) use the setQuery() handlers in AppQueryProvider (this file), instead of setting them directly
 */
export function queryStringify(options: QueryStringifyOptions): string {
  const params = joinParams(options)

  return params ? `?${params}` : ''
}

/**
 * @description Convenience type for holding the possible options for scenarios and stacked-scenarios
 */
export type Level = {
  year: undefined | string
  division: undefined | string
  team: undefined | string
  market: undefined | string
}

export function getEnvironment() {
  const fullUrl = window.location.toString()

  if (fullUrl?.includes('localhost') || fullUrl.includes('.test.')) {
    return 'test'
  }
  if (fullUrl.includes('.preprod.')) {
    return 'preprod'
  }
  if (fullUrl.includes('.dev.')) {
    return 'dev'
  }
  if (fullUrl.includes('.integration.')) {
    return 'integration'
  }
  return 'prod'
}
