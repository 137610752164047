import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { isOnMarketEditor } from '../../utils/paths/Paths'
import { AppQueryContext } from '../../providers/AppQueryProvider'

export const MarketV2Context = createContext<Market>({
  isMarketEditorView: true,
})

interface Market {
  isMarketEditorView: boolean
}

type MarketProps = PropsWithChildren<{}>

export const MarketV2Provider = ({ children }: MarketProps) => {
  const { currentScenario } = useContext(ScenarioContext)
  const { yearQuery, marketQuery } = useContext(AppQueryContext)

  const [, setCurrentYear] = yearQuery
  const [, setCurrentMarketName] = marketQuery

  useEffect(() => {
    if (currentScenario) {
      setCurrentMarketName(currentScenario.market)
      setCurrentYear(currentScenario.year)
    }
  }, [currentScenario, currentScenario?.market, currentScenario?.year, setCurrentMarketName, setCurrentYear])

  const isMarketEditorView = isOnMarketEditor(useLocation().pathname)

  return (
    <MarketV2Context.Provider
      value={{
        isMarketEditorView,
      }}
    >
      {children}
    </MarketV2Context.Provider>
  )
}
