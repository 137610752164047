import { PropsWithChildren } from 'react'
import { Tooltip } from '@chakra-ui/react'

export const NcpTableCellTooltip = (props: PropsWithChildren<{ label: string | number }>) => {
  return (
    <Tooltip
      label={props.label}
      placement="bottom"
      backgroundColor="white"
      textColor="black"
    >
      {props.children}
    </Tooltip>
  )
}
