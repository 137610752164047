import React, { useContext, useMemo, useState } from 'react'
import { CssGridItem } from '../css-grid/CssGridRows'
import { ParameterisationDataContext } from '../../providers/parameterisation/ParameterisationDataProvider'
import { GridContainer } from '../css-grid/GridContainer'
import { TextField } from '../react_library_text_field/TextField'
import KpiCard from '../kpi-card/KpiCard'
import {
  displayLargeDollarValue,
  isZero,
  numericValueIsDefined,
  ONE,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  ZERO,
} from '../../utils/numbers'
import { onNumberInputUpdateState, setValueIncludingSuffixShorthand } from '../../utils/onChange'
import Big from 'big.js'
import { ParameterisationByIdResponse, ResultbyDistributionbyLossType } from '../../backend/parameterisation'
import { sortListBy } from '../../utils/lists'
import { NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW } from '../parameterisation-table/parameterisation-table'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { ParameterisationTeamScenarioByIdResponse } from '../../backend/stacked-scenario-parameterisation'

export interface ColumnContentProps {
  columnNumber: number
  columnName: string
  parameterisationData: null | undefined | ParameterisationByIdResponse | ParameterisationTeamScenarioByIdResponse
  lossToggle: string
}

export function calculateRp(percentile: string) {
  const divisor = ONE.minus(percentile)

  if (isZero(divisor)) {
    return ZERO
  }

  return ONE.div(divisor)
}

export function calculateClosestToAndLowerThan(
  allInputValues: Array<ResultbyDistributionbyLossType>,
  value: number,
): ResultbyDistributionbyLossType | undefined {
  const listWithAmendedStuffForSorting = allInputValues.map((item) => ({
    ...item,
    sortKey: extractPercentileValue(item)?.toNumber(),
  }))
  const sortedBiggestToSmallest = sortListBy(listWithAmendedStuffForSorting, ['sortKey']).reverse()

  for (const item of sortedBiggestToSmallest) {
    if (item?.value < value) {
      return item
    }
  }
  return undefined
}

export function extractPercentileValue(closestResult: ResultbyDistributionbyLossType | undefined): Big | undefined {
  if (!closestResult) {
    return undefined
  }
  const withoutNonsense = closestResult?.stat
    ?.replace(' percentile', '')
    .replace('st', '')
    .replace('th', '')
    .replace('rd', '')
    .replace('nd', '')
  return new Big(withoutNonsense)
}

/**
 * Do I understand how this works? No
 *
 * Can I copy and paste from a spreadsheet? Yes
 * See https://www.dropbox.com/home/Private%20-%20Wayfinder/2.%20Methodology%20Working?preview=Interpolation+Calc.xlsx for workings
 *
 * Given a value to look for in the data, it finds the values which are closest to it and extrapolates out...
 */
export function calculateInterpolatedPercentile(
  parameterisationData: null | undefined | ParameterisationByIdResponse | ParameterisationTeamScenarioByIdResponse,
  columnName: string,
  lossToggle: string,
  valueToLookFor: PossiblyNegativeNumber,
): string {
  if (!numericValueIsDefined(valueToLookFor)) {
    return ZERO.toFixed(0)
  }
  if (columnName.includes('Frequency')) {
    lossToggle = 'Frequency'
  }
  const valueToLookForAsNumber: number = Number(valueToLookFor)
  const allInputValues =
    parameterisationData?.result?.resultbyDistributionbyLossType?.filter(
      (item) => item.stat.includes('percentile') && item.lossType === lossToggle && item.distribution === columnName,
    ) || []

  const closestByLowerInputValue = calculateClosestToAndLowerThan(allInputValues, valueToLookForAsNumber)

  if (!closestByLowerInputValue) {
    return bottomLimit()
  }

  const indexOfFoundRecord = allInputValues.findIndex(
    (possibleRow) => possibleRow.stat === closestByLowerInputValue.stat,
  )
  const closestByHigherInputValue = allInputValues[indexOfFoundRecord + 1]

  const closestByLowerOutputValue: undefined | Big = extractPercentileValue(closestByLowerInputValue)
  const closestByHigherOutputValue: undefined | Big = extractPercentileValue(closestByHigherInputValue)

  if (!closestByHigherOutputValue) {
    return topLimit()
  }

  const topOfFunction: Big = (closestByHigherOutputValue || ZERO).minus(closestByLowerOutputValue || ZERO)
  const bottomOfFunction: number = closestByHigherInputValue!.value - closestByLowerInputValue?.value
  if (isZero(new Big(bottomOfFunction))) {
    return topLimit()
  }
  const multiplied: Big = new Big(((valueToLookFor as number) || 0) - closestByLowerInputValue?.value).mul(
    new Big(topOfFunction).div(bottomOfFunction),
  )
  const result: string = (closestByLowerOutputValue || ZERO).plus(multiplied).div(ONE_HUNDRED).toFixed(4)
  if (Number(result) < 0) {
    return bottomLimit()
  }
  return result
}

// This is the lowest number to yield both the "99.9"th percentile and RP (Return Period) of "1_000".
// Other higher numbers can be used, however they need to also do so without overflowing either of the two results above.
function topLimit(): string {
  return String(0.99_89999_5) // Leading delimiters (_) to highlight the changes between numbers
}

function bottomLimit(): string {
  return '0'
}

export function ParameterisationInputTableColumnContent(props: ColumnContentProps): JSX.Element {
  const isValueBased = props.lossToggle === 'Loss Value'
  const isFrequency = props.columnName.includes('Frequency')
  const [valueEnteredByUser, setValueEnteredByUser] = useState<PossiblyNegativeNumber>('' as any)

  const valueToLookFor = useMemo(() => {
    if (isValueBased || isFrequency) {
      return valueEnteredByUser
    } else {
      try {
        if (numericValueIsDefined(valueEnteredByUser)) {
          return new Big(valueEnteredByUser!).div(ONE_HUNDRED)?.toNumber()
        }
      } catch (e) {
        return valueEnteredByUser
      }
    }
    return valueEnteredByUser
  }, [valueEnteredByUser, isValueBased, isFrequency])

  const percentile = useMemo(
    () =>
      calculateInterpolatedPercentile(props.parameterisationData, props.columnName, props.lossToggle, valueToLookFor),
    [valueToLookFor, props.columnName, props.lossToggle, props.parameterisationData],
  )
  const rp = useMemo(() => calculateRp(percentile), [percentile])
  return (
    <>
      <CssGridItem
        columnNumber={props.columnNumber}
        rowNumber={1}
        key={`${props.columnName}-${props.columnNumber}-input`}
      >
        <TextField
          step="0.1"
          type={isValueBased ? undefined : 'number'}
          value={isValueBased ? (displayLargeDollarValue(valueEnteredByUser) as any) : valueEnteredByUser}
          onChange={
            isValueBased
              ? setValueIncludingSuffixShorthand(setValueEnteredByUser)
              : onNumberInputUpdateState(setValueEnteredByUser)
          }
        />
      </CssGridItem>
      <CssGridItem
        columnNumber={props.columnNumber}
        rowNumber={2}
        key={`${props.columnName}-${props.columnNumber}-percentile`}
      >
        <KpiCard
          value={ONE_HUNDRED.mul(percentile)}
          title={'Percentile'}
          decimals={1}
        />
      </CssGridItem>
      <CssGridItem
        columnNumber={props.columnNumber}
        rowNumber={3}
        key={`${props.columnName}-${props.columnNumber}-rp`}
      >
        <KpiCard
          value={new Big(rp)}
          title={'RP'}
          decimals={1}
        />
      </CssGridItem>
    </>
  )
}

export function ParameterisationInputTable(): JSX.Element {
  const marketParamContext = useContext(ParameterisationDataContext)
  const stackedParamContext = useContext(TeamParameterisationDataContext)

  const contextToUse = marketParamContext?.parameterisationData ? marketParamContext : stackedParamContext

  return (
    <div className="ParamInputTable">
      <GridContainer>
        <CssGridItem
          columnNumber={1}
          rowNumber={1}
        >
          Input Value
        </CssGridItem>
        <CssGridItem
          columnNumber={1}
          rowNumber={2}
        >
          Percentile
        </CssGridItem>
        <CssGridItem
          columnNumber={1}
          rowNumber={3}
        >
          RP
        </CssGridItem>
        {contextToUse.allUniqueColumns.map((columnName, currentTypeIndex) => (
          <ParameterisationInputTableColumnContent
            key={columnName}
            columnName={columnName}
            columnNumber={currentTypeIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
            lossToggle={contextToUse.lossToggle}
            parameterisationData={contextToUse.parameterisationData}
          />
        ))}
      </GridContainer>
    </div>
  )
}
