import Papa from 'papaparse'

const itemIsRelevant = (division?: string, team?: string, market?: string) => (item: any) =>
  (division === undefined ? true : item['UW Division'] === division) &&
  (team === undefined ? true : item['UW Team'] === team) &&
  (market === undefined ? true : item['A1 Market'] === market)
async function fetchUnderlyingData(dataUrl: string, division?: string, team?: string, market?: string): Promise<any[]> {
  let urlToUse = dataUrl

  // urlToUse = "http://localhost:8011/proxy" + dataUrl

  return new Promise((resolve, reject) => {
    Papa.parse(urlToUse, {
      download: true,
      header: true,
      complete: (parseResult) => {
        resolve(parseResult.data.filter(itemIsRelevant(division, team, market)))
      },
    })
  })
}

export { fetchUnderlyingData }
