import { getAccessToken } from '../../backend/teams'
import { getCurrentConfigSettings } from '../../utils/config'

export async function updateExposuresReportOutdated(scenarioId: string): Promise<boolean> {
  const request: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/outdated/${scenarioId}`,
    request,
  )
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `POST Exposures Report Outdated responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return await response.json()
}
