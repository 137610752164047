import { Card } from '../../components/card/Card'
import TagIcon from '../../components/icons/TagIcon'
import './HideUntaggedButton.scss'

interface HideUntaggedProps {
  hideUntagged: boolean
  toggleHideUntagged(): void
}

function HideUntaggedButton(props: HideUntaggedProps): JSX.Element {
  const { hideUntagged, toggleHideUntagged } = props
  const title = `${hideUntagged ? 'Show' : 'Hide'} Untagged`

  return (
    <div
      title={title}
      style={{ lineHeight: '0px' }}
      className="HideUntaggedButton"
      onClick={toggleHideUntagged}
    >
      <Card className={hideUntagged ? 'selected' : ''}>
        <TagIcon />
      </Card>
    </div>
  )
}

export { HideUntaggedButton }
