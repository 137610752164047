import { useContext, useMemo } from 'react'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'
import Big from 'big.js'
import { ZERO } from '../../../utils/numbers'
import { formatAcquisitionCostChange } from '../utils/acquisitionCostChange'

export type AvgAcqCostsMode = 'Expiring' | 'New'
type AvgAcqCostsColumns =
  | { acqCostsColumn: 'Acq Costs'; gwpColumnName: 'Convex GWP' }
  | { acqCostsColumn: 'WF Acq Cost'; gwpColumnName: 'WF Adjusted Convex GWP with Basic Retention' }

const columns: Record<AvgAcqCostsMode, AvgAcqCostsColumns> = {
  Expiring: {
    acqCostsColumn: 'Acq Costs',
    gwpColumnName: 'Convex GWP',
  },
  New: {
    acqCostsColumn: 'WF Acq Cost',
    gwpColumnName: 'WF Adjusted Convex GWP with Basic Retention',
  },
}

const isCellValueValid = (cell: any) => cell !== undefined && cell !== ''

export const useWeightedAvgAcqCost = (mode: AvgAcqCostsMode, adjustmentId: string): number | undefined => {
  const { filteredRenewalDataForScenario } = useContext(FullScenarioDataContext)

  return useMemo(() => {
    if (filteredRenewalDataForScenario.length <= 0) return undefined

    const renewalDataWithRowsBelongingToSpecificAdjustment = filteredRenewalDataForScenario.filter(
      (curr) => curr['WF Adjustment ID'] === adjustmentId,
    )

    const shouldNotCountCurrentRow = (curr: any) =>
      !isCellValueValid(curr[columns[mode].acqCostsColumn]) || !isCellValueValid(curr[columns[mode].gwpColumnName])

    const convexGwpSum: Big = renewalDataWithRowsBelongingToSpecificAdjustment.reduce((acc: Big, curr) => {
      return shouldNotCountCurrentRow(curr) ? acc : acc.plus(new Big(curr[columns[mode].gwpColumnName]))
    }, ZERO)

    const weightedCosts: Big = renewalDataWithRowsBelongingToSpecificAdjustment.reduce((acc: Big, curr) => {
      return shouldNotCountCurrentRow(curr)
        ? acc
        : acc.plus(new Big(curr[columns[mode].gwpColumnName]).mul(new Big(curr[columns[mode].acqCostsColumn])))
    }, ZERO)

    if (weightedCosts.eq(ZERO) || convexGwpSum.eq(ZERO)) return undefined

    const expiringAvgAcqCost = weightedCosts.div(convexGwpSum).mul(100).toNumber()

    return formatAcquisitionCostChange(expiringAvgAcqCost)
  }, [filteredRenewalDataForScenario, mode, adjustmentId])
}
