import { TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_GWP_COLUMN } from 'src/backend/calculate-kpis'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from 'src/backend/calculate-with-actuals'
import { CulmlativeGwpItem } from './GwpCumulativeLineChart.types'

export function aggregateCumulativeGwpForEachMonth(summarisedData: Array<any>): Array<CulmlativeGwpItem> {
  let cumGwpsum = 0
  let cumAdjustedGwpsum = 0
  return summarisedData.map((dataItem) => ({
    [RENEWAL_INCEPTION_MONTH_COLUMN_NAME]: dataItem[RENEWAL_INCEPTION_MONTH_COLUMN_NAME],
    'Total Adjusted GWP': (cumAdjustedGwpsum += dataItem[TOTAL_ADJUSTED_GWP_COLUMN]),
    'Total GWP': (cumGwpsum += dataItem[TOTAL_GWP_COLUMN]),
  }))
}
