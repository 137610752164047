import React, { useContext } from 'react'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { NotesDataProvider, NoteSectionNames } from '../notes/NotesDataProvider'
import MarketV2Content from './MarketV2Content'
import { MarketV2Provider } from './MarketV2Provider'

export const NotesPrefix = 'market-v2'

export function MarketV2Wrapper() {
  const { yearQuery, marketQuery } = useContext(AppQueryContext)

  const [year] = yearQuery
  const [currentMarketName] = marketQuery

  return (
    <MarketV2Provider>
      <NotesDataProvider
        idToUse={`${NotesPrefix}-${currentMarketName}-${year}`}
        segmentsToDisplay={[
          NoteSectionNames.MARKET_COMMENTARY,
          NoteSectionNames.PEAK_CONVEX_VISION,
          NoteSectionNames.UW_COMMENTARY_ON_ABE,
        ]}
      >
        <MarketV2Content />
      </NotesDataProvider>
    </MarketV2Provider>
  )
}
