import { groupListOfObjsBy } from '../utils/lists'
import { calculateConvexGwpForSegment } from './calculate-kpis'
import { MarketRowV2, MarketSegmentV2 } from './market-V2'
import { RawEntry } from './rawEntry'

export function toMarketRowV2(segments: MarketSegmentV2[], fullDataForScenario: RawEntry[]): MarketRowV2[] {
  const scenarioDataBySegment = groupListOfObjsBy(fullDataForScenario, 'A1 Segment')
  return segments
    .map((marketSegment) => {
      const convexGwp = calculateConvexGwpForSegment(scenarioDataBySegment[marketSegment.segmentName] || [])
      const marketShare = (convexGwp / Number(marketSegment.marketGwp)) * 100 || 0
      return {
        id: marketSegment.id,
        segmentName: marketSegment.segmentName,
        marketId: marketSegment.marketId,
        marketPercentage: Number(marketSegment.marketPercentage),
        marketGwp: Number(marketSegment.marketGwp),
        convexGwp: convexGwp,
        marketShare: marketShare,
        inflation: Number(marketSegment.inflation),
        rateEnvironment: marketSegment.rateEnvironment,
      }
    })
    .sort((first, second) => first.segmentName.localeCompare(second.segmentName))
}
