export type PortfolioSplitsColumns = string[]
export type KnownDivision = 'Reinsurance' | 'Insurance'

export const inceptionMonths: PortfolioSplitsColumns = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const placementTypes: PortfolioSplitsColumns = [
  'Insurance',
  'Lineslip',
  'Non-Proportional Treaty',
  'Bulking Lineslip',
  'Retrocession Proportional',
  'Fac RI',
  'Proportional Treaty',
  'Declaration',
  'Binder',
  'Retrocession Non-Proportional',
]

export const reinsuranceEntities: PortfolioSplitsColumns = ['CIL', 'CES', 'CRL']
export const insuranceEntities: PortfolioSplitsColumns = ['CIL', 'CES', 'CGU']
