import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'

export const StackedScenarioEditingContext = createContext<StackedScenarioEditingContainer>({
  currentlyEditingStackedScenarioId: undefined,
  setCurrentlyEditingStackedScenarioId: () => void 0,
  currentlyCreatingStackedScenario: false,
  setCurrentlyCreatingStackedScenario: () => void 0,
  currentlyChosenScenarioIds: undefined,
  setCurrentlyChosenScenarioIds: () => void 0,
  nameOfCurrentlyEditedStackedScenario: '',
  setNameOfCurrentlyEditedStackedScenario: () => void 0,
  descriptionOfCurrentlyEditedStackedScenario: undefined,
  setDescriptionOfCurrentlyEditedStackedScenario: () => void 0,
  createStackedScenarioModalIsOpen: false,
  setCreateStackedScenarioModalIsOpen: () => void 0,
  currentlyPreviewingStackedScenarioId: undefined,
  setCurrentlyPreviewingStackedScenarioId: () => void 0,
})

interface StackedScenarioEditingContainer {
  currentlyEditingStackedScenarioId: string | undefined
  setCurrentlyEditingStackedScenarioId: (id: string | undefined) => void
  currentlyCreatingStackedScenario: boolean
  setCurrentlyCreatingStackedScenario: (newValue: boolean) => void
  currentlyChosenScenarioIds: Partial<Record<string, string | undefined>> | undefined
  setCurrentlyChosenScenarioIds: (newValue: Partial<Record<string, string | undefined>> | undefined) => void
  nameOfCurrentlyEditedStackedScenario: string
  setNameOfCurrentlyEditedStackedScenario: Dispatch<SetStateAction<string>>
  descriptionOfCurrentlyEditedStackedScenario: string | undefined
  setDescriptionOfCurrentlyEditedStackedScenario: (value: string | undefined) => void
  createStackedScenarioModalIsOpen: boolean
  setCreateStackedScenarioModalIsOpen: (value: boolean) => void
  currentlyPreviewingStackedScenarioId: string | undefined
  setCurrentlyPreviewingStackedScenarioId: (id: string | undefined) => void
}

export function StackedScenarioEditingProvider(props: React.PropsWithChildren<{}>) {
  const [currentlyEditingStackedScenarioId, setCurrentlyEditingStackedScenarioId] = useState<string | undefined>()
  const [currentlyCreatingStackedScenario, setCurrentlyCreatingStackedScenario] = useState(false)
  const [currentlyChosenScenarioIds, setCurrentlyChosenScenarioIds] = useState<
    Record<string, string | undefined> | undefined
  >({})
  const [nameOfCurrentlyEditedStackedScenario, setNameOfCurrentlyEditedStackedScenario] = useState<string>('Change me')
  const [descriptionOfCurrentlyEditedStackedScenario, setDescriptionOfCurrentlyEditedStackedScenario] = useState<
    string | undefined
  >(undefined)
  const [createStackedScenarioModalIsOpen, setCreateStackedScenarioModalIsOpen] = useState(false)
  const [currentlyPreviewingStackedScenarioId, setCurrentlyPreviewingStackedScenarioId] = useState<string | undefined>()

  const setToNotCreatingStackedScenarios = () => {
    setCurrentlyCreatingStackedScenario(false)
  }
  useEffect(setToNotCreatingStackedScenarios, [])

  return (
    <StackedScenarioEditingContext.Provider
      value={{
        currentlyChosenScenarioIds,
        setCurrentlyChosenScenarioIds,
        currentlyCreatingStackedScenario,
        setCurrentlyCreatingStackedScenario,
        currentlyEditingStackedScenarioId,
        setCurrentlyEditingStackedScenarioId,
        nameOfCurrentlyEditedStackedScenario,
        setNameOfCurrentlyEditedStackedScenario,
        descriptionOfCurrentlyEditedStackedScenario,
        setDescriptionOfCurrentlyEditedStackedScenario,
        createStackedScenarioModalIsOpen,
        setCreateStackedScenarioModalIsOpen,
        currentlyPreviewingStackedScenarioId,
        setCurrentlyPreviewingStackedScenarioId,
      }}
      children={props.children}
    />
  )
}
