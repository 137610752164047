import { Table, TableContainer } from '@chakra-ui/react'
import { TableBody } from './TableBody'
import { TableHead } from './TableHead'

export type DynamicTableState<T> = {
  cells: Cell<number, T>[]
  columns: string[]
  rows: string[]
  depth: string[]
  scaler: ScalingFactors
  selector: DepthSelector[]
  selectorRowName: string
  columnOffset: number[]
  disabled: boolean
}

type ScalingFactors = {
  [rowName: string]: number[]
}

export type DepthSelector = {
  currentDepth: string
  depthIndex: number
  updateActiveColumn?: (id: number, depthKey: string) => void
}

type CellValidation = {
  bounds?: Bound<number>
}

type CellError = {
  message: string
}

export type Cell<T, K> = {
  id: T
  data: K
  update?: (id: T, value: K) => void
  updateScaler?: (id: T, scaleFactor: number, rowName: string, columnPosition: number) => void
  readonly: boolean
  disabled?: boolean
  validation?: CellValidation
  dependencies?: Dependency<T>
  error?: CellError
  propagation?: T[]
  scaler?: T
}

type Dependency<T> = {
  sumOf: T[]
  mulOf: T[]
  equalOf: T
}

type Bound<T> = {
  fixed?: NumericBounds
  dynamic?: CellBound<T>
}

type NumericBounds = {
  max?: number
  min?: number
}

type CellBound<T> = {
  upper?: T
  lower?: T
}

export function attachUpdateFunction<T>(
  currentTableState: DynamicTableState<T>,
  updateFunction: (id: number, value: T) => void,
) {
  currentTableState.cells.forEach((cell) => {
    cell.update = updateFunction
  })
}

export const DynamicTable = (props: DynamicTableState<number>) => {
  const { cells, columns, rows, depth, columnOffset, scaler, selector, selectorRowName, disabled } = props

  return (
    <TableContainer>
      <Table
        fontSize={'sm'}
        whiteSpace={'normal'}
        textAlign={'center'}
        mt={'2rem'}
      >
        <TableHead columns={columns} />
        <TableBody
          cells={cells}
          rows={rows}
          depth={depth}
          columns={columns}
          columnOffset={columnOffset}
          scaler={scaler}
          selector={selector}
          selectorRowName={selectorRowName}
          disabled={disabled}
        />
      </Table>
    </TableContainer>
  )
}
