import update from 'immutability-helper'

export function getItemsInNewOrderIgnoringPreview<T>(
  items: T[],
  originalIndex: number,
  finalIndex: number,
  filterToItemsWhichNeedChanging: (item: T) => boolean,
): Array<T> {
  const dragCard = items![originalIndex]

  const inCorrectOrder = update(items!, {
    $splice: [
      [originalIndex, 1],
      [finalIndex, 0, dragCard],
    ],
  })

  return inCorrectOrder.filter(filterToItemsWhichNeedChanging).map((item, index) => ({
    ...item,
    orderNumber: index,
  }))
}
