import { Reducer } from 'react'
import { ExposureGrowth } from 'src/backend/exposures'

const pageTypes = ['loading', 'missingTemplate', 'list', 'newReport', 'report', 'err'] as const
export type PageType = (typeof pageTypes)[number]

export type State = {
  page: PageType
  reportId: string
  errMessage: string
  market: string
  exposuresCalculationData: ExposureGrowth
  canCreateNew: boolean
  ylt_dvid: number
  asofdate: string
}

export type Action =
  | { type: 'toLoading' }
  | { type: 'toList' }
  | { type: 'toReport'; payload: { reportId: string } }
  | { type: 'toNew' }
  | { type: 'toMissingTemplate' }
  | { type: 'toErrorPage'; payload: { message: string } }
  | { type: 'setMarket'; payload: { market: string } }
  | { type: 'loadExposuresCalculationData'; payload: { data: ExposureGrowth; isValid: boolean } }
  | { type: 'setYltAndAsofdate'; payload: { ylt_dvid: number; asofdate: string } }

export const createInitialPageState = (): State => {
  return {
    page: 'loading',
    reportId: '',
    errMessage: '',
    market: '',
    exposuresCalculationData: {
      outputType: [],
      convexGWP: [],
      convexGWPWithBasicRetention: [],
      riskPremiumChange: [],
      grossRiskAdjustedRateChange: [],
      gwpChange: [],
    },
    canCreateNew: false,
    ylt_dvid: 0,
    asofdate: '',
  }
}

export const reducer: Reducer<State, Action> = (state, action): State => {
  switch (action.type) {
    case 'toErrorPage': {
      return {
        ...state,
        errMessage: action.payload.message,
        page: 'err',
      }
    }
    case 'toMissingTemplate': {
      return {
        ...state,
        page: 'missingTemplate',
      }
    }
    case 'toList': {
      return {
        ...state,
        page: 'list',
      }
    }
    case 'toReport': {
      if (action.payload.reportId === undefined || action.payload.reportId === '') {
        return {
          ...state,
          reportId: '',
          page: 'list',
        }
      }

      return {
        ...state,
        reportId: action.payload.reportId,
        page: 'report',
      }
    }
    case 'toNew': {
      return {
        ...state,
        reportId: '',
        page: 'newReport',
      }
    }
    case 'setMarket': {
      return {
        ...state,
        market: action.payload.market,
      }
    }
    case 'loadExposuresCalculationData': {
      return {
        ...state,
        exposuresCalculationData: action.payload.data,
        canCreateNew: action.payload.isValid,
      }
    }
    case 'setYltAndAsofdate': {
      return {
        ...state,
        ylt_dvid: action.payload.ylt_dvid,
        asofdate: action.payload.asofdate,
      }
    }

    default: {
      throw new Error(`Unhandled action, "${JSON.stringify(action, null, 2)}"`)
    }
  }
}
