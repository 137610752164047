export const exposureRegionsReferenceData = [
  'Worldwide',
  'Afghanistan',
  'Africa',
  'Africa: Central & Southern Africa',
  'Africa: Northern Africa',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Asia',
  'Asia: Central Asia',
  'Asia: South Asia',
  'Australasia',
  'Australia',
  'Australia and New Zealand',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Canada East: Ontario/Quebec/New Brunswick/Nova Scotia/Price Edward Island/Newfoundland And Labrador',
  'Canada West: British Columbia',
  'Canada: Alberta',
  'Canada: British Colombia',
  'Canada: Manitoba',
  'Canada: Newfoundland and Labrador',
  'Canada: Ontario',
  'Canada: Quebec',
  'Canada: Saskatchewan/Manitoba/Alberta',
  'Caribbean',
  'Caribbean: Bahamas / Turks & Caicos Islands',
  'Caribbean: Jamaica/Caymans/Dominican Repub/Haiti',
  'Caribbean: Trinidad&Tobago/Curacao/Bonaire/Aruba',
  'Caribbean: Virgin Islands / Leeward Islands',
  'Cayman Islands',
  'Central African Republic',
  'Central America',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic Of',
  'Costa Rica',
  "Cote D'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Dubai',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Eswatini (Swaziland)',
  'Ethiopia',
  'Europe',
  'Europe: Central & Eastern Europe',
  'Europe: Central Europe',
  'Europe: Eastern',
  'Europe: Eastern Europe',
  'Europe: Northern',
  'Europe: Scandinavia',
  'Europe: Southern',
  'Europe: Western',
  'Europe: Western Europe',
  'Europe: Western Europe (Excl. UK)',
  'Falkland Islands',
  'Far East: Indonesia / Malaysia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Germany & Poland',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indian Sub Continent',
  'Indonesia',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey, Guernsey and French Mainland',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mariana Islands',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Middle East',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North America',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oceania',
  'Oman',
  'Pakistan',
  'Palestinian territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of Ireland',
  'Romania',
  'Russia',
  'Russian Federation',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Vincent',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South America',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'UK and Republic of Ireland',
  'UK: Channel Islands',
  'UK: Guernsey',
  'UK: Isle of Man',
  'UK: Jersey',
  'UK: United Kingdom',
  'USA and Canada',
  'USA and Caribbean',
  'USA, Canada and Puerto Rico',
  'USA: Alabama',
  'USA: Alaska',
  'USA: Arizona',
  'USA: Arkansas',
  'USA: California',
  'USA: Central Mid West (Ar/Ok/Mo/Ka/Nb/Ia/Sd/Nd/Mn)',
  'USA: Colorado',
  'USA: Connecticut',
  'USA: D Of Columbia',
  'USA: Delaware',
  'USA: District Of Columbia',
  'USA: Florida',
  'USA: Georgia',
  'USA: Gulf (Al/Ms/La/Tx)',
  'USA: Hawaii',
  'USA: Idaho',
  'USA: Illinois',
  'USA: Indiana',
  'USA: Iowa',
  'USA: Kansas',
  'USA: Kentucky',
  'USA: Louisiana',
  'USA: Maine',
  'USA: Maryland',
  'USA: Massachusetts',
  'USA: Michigan',
  'USA: Mid Atlantic (Va/Md/De/Dc/Nc)',
  'USA: Mid West (Tn/Kt/Wv/Oh/In/Il/Mi/Ws)',
  'USA: Minnesota',
  'USA: Mississippi',
  'USA: Missouri',
  'USA: Montana',
  'USA: Nebraska',
  'USA: Nevada',
  'USA: New Jersey',
  'USA: New Mexico',
  'USA: New York',
  'USA: North Carolina',
  'USA: North Dakota',
  'USA: North East (Me/Nh/Vt/Ny/Ma/Ri/Ct/Nj/Pa)',
  'USA: Ohio',
  'USA: Oklahoma',
  'USA: Oregon',
  'USA: Pennsylvania',
  'USA: Rhode Island',
  'USA: South Carolina',
  'USA: South Dakota',
  'USA: South East (Fl/Ga/Sc)',
  'USA: Tennessee',
  'USA: Texas',
  'USA: United States Of America',
  'USA: Utah',
  'USA: Vermont',
  'USA: Virginia',
  'USA: Washington',
  'USA: West (Ca/Az/Nm/Nv/Ut/Co/Wy/Mt/Id/Wa/Or)',
  'USA: West Virginia',
  'USA: Wisconsin',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands / Leeward Islands',
  'Western Sahara',
  'Worldwide Excl. USA',
  'Worldwide Excl. USA & Can',
  'Yemen',
  'Zambia',
  'Zimbabwe',
] as const

export type Cob1ReferenceKey =
  | 'Accident & Health'
  | 'Casualty'
  | 'ELA'
  | 'Political Risk & Surety'
  | 'Marine'
  | 'Energy'
  | 'Property'
  | 'Crisis Management'
  | 'Aerospace'
  | 'FAS'
  | 'Whole Account'
  | 'Speciality'
  | 'Aviation'

export type Cob2n3ReferenceDataType = {
  [K in Cob1ReferenceKey]: string[]
}

export const cob2n3ReferenceData: Cob2n3ReferenceDataType = {
  'Accident & Health': [
    'Cyber: All',
    'Health: Critical Illness',
    'Health: Disease specific',
    'Health: Whole Account',
    'Health: Managed Care',
    'Health: Medical Expenses - Expat',
    'Health: Medical Expenses - LCN',
    'Health: Student Medical',
    'Health: Travel Medical',
    'Health: Medical Expenses - Expat & LCN',
    'Personal Accident: Credit Card',
    'Personal Accident: Disability - PTD',
    'Personal Accident: Disability - TTD',
    'Personal Accident: Loss of Licence',
    'Personal Accident: Occupational Accident',
    'Personal Accident: PA - Group',
    'Personal Accident: PA - Individual',
    'Personal Accident: PA and Sickness - Group',
    'Personal Accident: PA and Sickness - Individual',
    'Personal Accident: Whole Account',
    'Personal Accident: Sports Disability',
    'Personal Accident: Sports Disability - Per Person',
    'Personal Accident: War and Terrorism',
    'Personal Accident: PA - Marine',
    'Personal Accident: Disability - LTD',
    'Personal Accident: PA - Aviation & Loss of Licence',
    'Personal Accident: Sports Disability - Group',
    'Personal Accident: Sports Disability - Individual',
    'Travel: Business Travel (inc BTA)',
    'Travel: Evacuation and Repatriation',
    'Travel: Leisure Travel',
    'Travel: Student Travel',
    'Travel: Whole Account',
    'Travel: Credit Card',
    'Whole Account: All',
  ],
  Casualty: [
    'Cyber: All',
    'Med Mal: Allied Health',
    'Med Mal: Hospital and Physicians',
    'Med Mal: Long Term Care',
    'Med Mal: Miscellaneous',
    'Med Mal: Whole Account',
    'Financial Lines: Commercial D&O',
    'Financial Lines: Commercial PI',
    'Financial Lines: Financial Institutions Crime',
    'Financial Lines: Financial Institutions D&O',
    'Financial Lines: Financial Institutions Other',
    'Financial Lines: Financial Institutions PI',
    'Financial Lines: Financial Institutions PI/Crime',
    'Financial Lines: Financial Institutions POSI',
    'Financial Lines: Whole Account',
    'Financial Lines: W&I',
    'Financial Lines: Financial Institutions PIDOCrime',
    "General Liability: Contractors' All Risks",
    'General Liability: Construction',
    'General Liability: Employers',
    'General Liability: Environmental',
    'General Liability: Miscellaneous',
    'General Liability: Whole Account',
    'General Liability: Life Science',
    'General Liability: Public Entity',
    'General Liability: Public & Products',
    'General Liability: Rail',
    'General Liability: Trucking',
    'General Liability: Umbrella',
    'Motor Liability: Whole Account',
    'Motor Liability: Commercial',
    'Motor Liability: Personal',
    "Workers' Comp: All",
    'Whole Account: All',
    // TODO: Check Parent
    'Casualty: Casualty Whole Account',
    'Casualty: Financial Lines',
    'Casualty: General Liability',
    'Casualty: Med Mal',
    'Casualty: Motor Liabs',
    'Casualty: Motor Other',
    'Casualty: Professional Lines',
    "Casualty: Workers' Comp",
  ],
  ELA: [
    'Aquaculture: All',
    'Cyber: All',
    'Equine: Bloodstock',
    'Equine: Equestrian',
    'Equine: Equine Other',
    'Livestock: All',
    'Whole Account: All',
  ],
  'Political Risk & Surety': [
    'Cyber: All',
    'Contract Frustration: Financial Guarantee',
    'Contract Frustration: Performance',
    'Contract Frustration: Pre-shipment',
    'Contract Frustration: Secured non-payment',
    'Contract Frustration: Unsecured non-payment',
    'Contract Frustration: Whole Account',
    'Contract Frustration: Sovereign non-payment',
    'Political Risk: Multi-country',
    'Political Risk: CEND',
    'Political Risk: CI/ET',
    'Political Risk: Political Violence',
    'Political Risk: Pre-shipment',
    'Political Risk: Whole Account',
    'Credit: Financial Guarantee',
    'Credit: Performance',
    'Credit: Pre-shipment',
    'Credit: Whole Account',
    'Credit: Secured non-payment',
    'Credit: Unsecured non-payment',
    'Credit: Surety All',
    'Credit: Trade Credit',
    'Credit: Mortgage',
    'Whole Account: All',
  ],
  Marine: [
    'Cyber: All',
    'Hull: Builders Risks',
    'Hull: Hull',
    'Hull: War',
    'Hull: Whole Account',
    'Hull: IV',
    'Hull: Recreational',
    "Liability: Employers' Liability",
    'Liability: General Liability',
    'Liability: International Group',
    'Liability: Miscellaneous',
    'Liability: Whole Account',
    'Liability: P&I',
    'Liability: Shoreside Operations',
    'Liability: Umbrella',
    'Liability: Vessel Liability',
    'Cargo: Whole Account',
    'Cargo: Open Cargo',
    'Cargo: Project Cargo',
    'Cargo: Stock Throughput',
    'Cargo: Excess Stock',
    'Cargo: Cargo War',
    'Whole Account: All',
    // Todo: Check Parent
    'Marine: Goods in Transit',
    'Marine: Inland Marine',
    'Marine: Marine Hull',
    'Marine: Marine Liability',
    'Marine: Marine Recreational',
    'Marine: Marine War',
    'Marine: Marine Whole Account',
  ],
  Energy: [
    'Cyber: All',
    'Downstream: Liability',
    'Downstream: Property',
    'Power: All',
    'Upstream: Liability',
    'Upstream: Property',
    'Whole Account: All',
    'Renewables: Liability',
    'Renewables: Property',
    // TODO: Check Parent
    'Energy: Energy Downstream Liabs',
    'Energy: Energy Downstream Property',
    'Energy: Energy Upstream Liabs',
    'Energy: Energy Upstream Property',
    'Energy: Energy Whole Account',
    'Energy: Power',
  ],
  Property: [
    'Agriculture/Crop: All',
    'Cyber: All',
    'Construction and Engineering: All',
    'Homeowners: All',
    'Industrial: All',
    'Motor: Commercial',
    'Motor: Personal',
    'Nuclear: Liability',
    'Nuclear: Property',
    'Commercial Lines: All',
    'Homeowners and Small Commercial: All',
    'Whole Account: All',
    // Todo: Check Parent
    'Property: Construction',
    'Property: Engineering',
    'Property: Nuclear',
    'Property: Property Whole Account',
  ],
  'Crisis Management': [
    'Cyber: All',
    'Trade Disruption: All',
    'Contingency: Whole Account',
    'Contingency: Event Cancellation',
    'Contingency: Film Production',
    'Contingency: Miscellaneous',
    'Contingency: Non-Appearance',
    'Contingency: Prize Indemnity',
    'Kidnap and Ransom: Corporate',
    'Kidnap and Ransom: Whole Account',
    'Kidnap and Ransom: Piracy',
    'Kidnap and Ransom: Private',
    'Kidnap and Ransom: Project',
    'Product Recall: All',
    'Terrorism & Political Violence: Event Cancellation',
    'Terrorism & Political Violence: Political Violence',
    'Terrorism & Political Violence: SRCC',
    'Terrorism & Political Violence: Terrorism Liability',
    'Terrorism & Political Violence: Terrorism and Sabotage',
    'Terrorism & Political Violence: Whole Account',
    'Terrorism & Political Violence: Cyber',
    'Whole Account: All',
  ],
  Aerospace: [
    'Airline: Hull',
    'Airline: Hull War',
    'Airline: Liability',
    'Airline: Liability War',
    'Airline: Whole Account',
    'Cyber: All',
    'General Aviation: Hull',
    'General Aviation: Hull War',
    'General Aviation: Liability',
    'General Aviation: Liability War',
    'General Aviation: Whole Account',
    'Products & Airport: Hull',
    'Products & Airport: Hull War',
    'Products & Airport: Liability',
    'Products & Airport: Liability War',
    'Products & Airport: Whole Account',
    'Space: Ground Risks and Other',
    'Space: Launch Plus',
    'Space: Orbit',
    'Space: Liability',
    'Space: LVFO',
    'Space: Whole Account',
    'Whole Account: All',
  ],
  // FAS not in use
  FAS: [
    'Cyber: All',
    'Specie: Cash in Transit',
    'Specie: Fine Art',
    'Specie: General Specie',
    'Specie: Jewellers Block',
    'Specie: Mining',
    'Specie: SIPC/CIPF',
    'Whole Account: All',
  ],
  // Whole Account not in use
  'Whole Account': [
    'Whole Account: External',
    'Whole Account: Inter Company',
    // Todo: Check parent
    'Whole Account: Whole Account Convex Intercompany',
    'Whole Account: Whole Account External',
    'Whole Account: Whole Account',
  ],
  // Speciality not in use
  Speciality: [
    'A&H: A&H Whole Account',
    'A&H: Personal Accident',
    'A&H: Travel',
    'Credit & Surety: Credit & Surety Whole Account',
    'Credit & Surety: Income Protection',
    'Credit & Surety: Legal Expenses',
    'Credit & Surety: Medical Expenses',
    'Credit & Surety: Structured Risk',
    'Credit & Surety: Surety',
    'Credit & Surety: Trade Credit',
    'Crisis Management: Crisis Management Whole Account',
    'Crisis Management: Product Recall',
    'Crisis Management: Political Risk',
    'Crisis Management: Terrorism',
    'ELA: Agriculture',
    'ELA: Aquaculture',
    'ELA: Bloodstock',
    'ELA: ELA Whole Account',
    'ELA: Equine',
    'ELA: Livestock',
    'FAS: Cash in Transit',
    'FAS: Fine Art',
    'FAS: FAS Whole Account',
    'FAS: General Specie',
    "FAS: Jeweller's Block",
  ],
  // Aviation not in use
  Aviation: [
    'Aviation: Airline',
    'Aviation: Aviation',
    'Aviation: Aviation Products',
    'Aviation: Aviation War',
    'Aviation: Aviation Whole Account',
    'Aviation: General Aviation',
    'Aviation: Space',
  ],
}
