import React, { useContext, useEffect, useState } from 'react'
import './EditPerilCard.scss'
import { Card } from '../card/Card'
import Button from '../button/Button'
import TextField from '../text-field/WFTextField'
import { onInputUpdateState } from '../../utils/onChange'
import { saveOrUpdateListOfApplicableMarkets, updateExcludedPerilList } from '../../backend/perils'
import { OutwardsApplicablePerilsContext } from '../../providers/outwards/OutwardsApplicablePerilsProvider'

export type EditPerilCardProps = {
  outwardScenarioId: string
  applicablePerils: string[]
  excludedPerils: string[]
  description: string
  triggerSaveChanges: () => void
  triggerExit: () => void
  stopEdit?: boolean
}

interface Peril {
  name: string
  label: string
  checked: boolean
}

export function EditPerilCard({
  outwardScenarioId,
  applicablePerils,
  excludedPerils,
  description,
  triggerSaveChanges,
  triggerExit,
  stopEdit,
}: EditPerilCardProps) {
  const { applicableMarkets } = useContext(OutwardsApplicablePerilsContext)

  const [currentDescription, setDescription] = useState<EditPerilCardProps['description']>(description)
  const [perilLists, setPerilLists] = useState<Array<Peril>>([])
  const initialPerList = JSON.stringify(applicablePerils)

  useEffect(() => {
    const transformedPerilList = applicablePerils.map((peril) => {
      if (excludedPerils.includes(peril)) {
        return { name: peril, label: peril, checked: false }
      } else {
        return { name: peril, label: peril, checked: true }
      }
    })
    setPerilLists(transformedPerilList)
    // eslint-disable-next-line
  }, [initialPerList, excludedPerils])

  const setPeril = (index: number, checked: boolean) => {
    const newCheckedPerils = [...perilLists]
    newCheckedPerils[index].checked = checked
    setPerilLists(newCheckedPerils)
  }

  const handleUpdatePerilList = () => {
    const excludedPerils = perilLists.filter((peril) => !peril.checked).map((p) => p.name)
    updateExcludedPerilList(outwardScenarioId, {
      excludedPerilNames: excludedPerils,
      outwardScenarioId: outwardScenarioId,
      description: currentDescription,
    })
      .then(() => saveOrUpdateListOfApplicableMarkets(outwardScenarioId, { applicableMarkets: applicableMarkets }))
      .then(triggerSaveChanges)
  }

  const onToggleSelected = () => {
    const firstPerilIsTicked = perilLists[0]?.checked

    const valueToSetTo = !firstPerilIsTicked

    perilLists.forEach((peril, index) => {
      setPeril(index, valueToSetTo)
    })
  }

  return (
    <>
      <Card className="EditPerilCard">
        <h4 className="PerilsCardTitle">Perils</h4>
        {!stopEdit && (
          <Button
            secondary
            title={'Toggle Selected'}
            onClick={onToggleSelected}
          />
        )}
        {perilLists.map((peril, index) => (
          <div className="PerilList">
            <input
              type="checkbox"
              checked={peril.checked}
              onChange={() => setPeril(index, !peril.checked)}
              disabled={stopEdit}
            />
            <label>{peril.label}</label>
          </div>
        ))}
        <TextField
          className="Description"
          title="Description"
          placeholder="Description"
          value={currentDescription}
          onChange={onInputUpdateState(setDescription)}
        />
        <Button
          title="Done"
          onClick={handleUpdatePerilList}
        />
      </Card>
    </>
  )
}

export default EditPerilCard
