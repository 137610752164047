import React, { ChangeEvent } from 'react'
import { Column, Row } from 'react-table'

import { displayLargeDollarValue, isNumber, numericValueIsDefined, PossiblyNegativeNumber } from '../../utils/numbers'
import TextField from '../text-field/WFTextField'
import { calculateValueIncludingSuffix } from '../../utils/onChange'

export interface EditableCellProps {
  value: PossiblyNegativeNumber | string
  row: Row
  column: Column
  updateMyData: (index: number, id: string | undefined, value: number | undefined | string) => void
  setIsUpToDate: (isUpToDate: boolean) => void
  shouldValidate: boolean
}

export function EditableCell({
  value,
  row: { index },
  column: { id },
  updateMyData,
  setIsUpToDate,
  shouldValidate,
}: EditableCellProps) {
  return (
    <TextField
      className="MarketSizeCell"
      value={displayLargeDollarValue(value)}
      placeholder="$0"
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        updateMyData(index, id, String(calculateValueIncludingSuffix(event.target.value)))
        setIsUpToDate(false)
      }}
      error={shouldValidate && !numericValueIsDefined(value) ? 'This must have a value' : undefined}
    />
  )
}

export function calculateErrorForMarketPercentage(shouldValidate: boolean, value: string | number | undefined) {
  if (!shouldValidate) {
    return undefined
  }
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }
  if (value === undefined) {
    return undefined
  }
  if (!isNumber(value)) {
    return 'This must be a number'
  }
  if (value > 100) {
    return 'This must be less than 100'
  }
  if (value < 0) {
    return 'This must be greater than 0'
  }
  return undefined
}

export function EditableCellPercentage({
  value,
  row: { index },
  column: { id },
  updateMyData,
  setIsUpToDate,
  shouldValidate,
}: EditableCellProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
      <TextField
        className="EditableCellPercentage"
        value={value}
        placeholder="0"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          updateMyData(index, id, event.target.valueAsNumber)
          setIsUpToDate(false)
        }}
        type="number"
        step={'1'}
        error={calculateErrorForMarketPercentage(shouldValidate, value)}
        percentage
      />
    </div>
  )
}

export default EditableCell
