import { Button, Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'

type CreateNewButtonProps = {
  createNew: () => void
  canCreateNew: boolean
  id?: string
}

export function CreateNewButton(props: CreateNewButtonProps): ReactElement {
  const { createNew, canCreateNew } = props

  if (canCreateNew) {
    return (
      <Button
        onClick={createNew}
        isDisabled={false}
        id={props.id}
      >
        Create New
      </Button>
    )
  }

  return (
    <Tooltip
      label={'Cannot create new exposure report - missing adjustment data'}
      placement={'auto-start'}
    >
      <Button
        onClick={createNew}
        isDisabled={true}
        id={props.id}
        _hover={{
          backgroundColor: 'red',
        }}
      >
        Create New
      </Button>
    </Tooltip>
  )
}
