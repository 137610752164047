import { useCallback, useContext, useEffect, useState } from 'react'
import './New.scss'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import NewPortfolioItemContainer from '../../components/new-portfolio-item-container/NewPortfolioItemContainer'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { GraphStyleProvider } from '../../components/renewal-graph/GraphStyleProvider'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import KpiBar from '../../components/kpi-bar/KpiBar'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import SectionHeader from '../../components/section-header/SectionHeader'
import {
  NewPseudoPortfolioItemsContextContainer,
  PseudoPortfolioContext,
} from '../../providers/NewPseudoPortfolioItemsProvider'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { CreateNewPortfolioItemCard } from '../../components/create-new-portfolio-item-card/CreateNewPortfolioItemCard'
import CreateNewPortfolioclientCard from '../../components/create-new-protfolio-client-card/CreateNewPortfolioClientCard'
import { isRelevantNewItem, NEW_ITEM_PREVIEW_ID } from '../../backend/new-portfolio-items'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import SharedGraphs from '../renewal/SharedGraphs'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../exposures/StackedExposures'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { NewCustomPortfolioSplits } from '../../components/new-custom-portfolio-splits/NewCustomPortfolioSplits'
import { MarketDataContextV2 } from '../../providers/MarketDataProviderV2'
import { PossiblyNegativeNumber } from '../../utils/numbers'
import { NcpDispatchContext, NewCustomPortfolioStateProvider } from '../../providers/NewCustomPortfolioStateProvider'
import { NewCustomPortfolioAlertStateProvider } from '../../providers/NewCustomPortfolioAlertStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import {
  NewCustomPortfolioAdjustmentContext,
  NewCustomPortfolioAdjustmentProvider,
} from '../../providers/NewCustomPortfolioAdjustmentsProvider'
import { useMarketSegments } from '../../backend/hooks/useMarketSegments'

export interface AdjustmentContainerProps {
  hideCreateAndEdit?: boolean
}

export enum NewBusinessType {
  VIRTUAL = 'PORTFOLIO',
  NEW_CLIENT = 'CLIENT',
}

export enum NewBusinessTypeWithCustomPortfolio {
  VIRTUAL = 'PORTFOLIO',
  NEW_CLIENT = 'CLIENT',
  CUSTOM_PORTFOLIO = 'CUSTOM PORTFOLIO',
}

const New = (props: AdjustmentContainerProps) => {
  const { currentScenario } = useContext(ScenarioContext)
  const { defaultMarketData } = useContext(MarketDataContextV2)
  const { newDataInputs, currentlyEditingPseudoItemId, setWhichItemIsBeingEdited } =
    useContext<NewPseudoPortfolioItemsContextContainer>(PseudoPortfolioContext)
  const { currentlyEditingNewClientItemId, setCurrentlyEditingNewClientId } = useContext(NewClientItemsContext)
  const ensureCurrentlyEditingItemExists = () => {
    if (
      newDataInputs !== undefined &&
      currentlyEditingPseudoItemId &&
      currentlyEditingPseudoItemId !== NEW_ITEM_PREVIEW_ID &&
      (!newDataInputs.length || !newDataInputs.find(isRelevantNewItem(currentlyEditingPseudoItemId)))
    ) {
      setWhichItemIsBeingEdited('')
    } else if (newDataInputs !== undefined && !newDataInputs.length && !currentlyEditingPseudoItemId) {
      setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
    }
  }

  const { ncpAdjustments, currentlyEditingNcpItemId, setCurrentlyEditingNcpId } = useContext(
    NewCustomPortfolioAdjustmentContext,
  )

  const ensureCurrentlyEditingNcpItemExists = () => {
    const isValidNcpItem =
      ncpAdjustments !== undefined &&
      currentlyEditingNcpItemId &&
      currentlyEditingNcpItemId !== NEW_ITEM_PREVIEW_ID &&
      (!ncpAdjustments.length || !ncpAdjustments.find(isRelevantNewItem(currentlyEditingNcpItemId)))
    if (isValidNcpItem) {
      setCurrentlyEditingNcpId('')
    } else if (ncpAdjustments !== undefined && !ncpAdjustments.length && !currentlyEditingNcpItemId) {
      setCurrentlyEditingNcpId(NEW_ITEM_PREVIEW_ID)
    }
  }

  const { businessQuery } = useContext(AppQueryContext)
  const [currentBusinessType, setBusinessType] = businessQuery
  const { isAdmin } = useContext(IsAdminContext)
  const canBeEdited =
    !props.hideCreateAndEdit &&
    currentScenario?.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)
  const [newPortfolioType, setNewPortfolioType] = useState<NewBusinessTypeWithCustomPortfolio>(
    NewBusinessTypeWithCustomPortfolio.VIRTUAL,
  )
  const [currentGwpInput, setGwpInput] = useState<PossiblyNegativeNumber>('' as any)
  const ncpStateDispatch = useContext(NcpDispatchContext)
  assertNotNil(ncpStateDispatch)
  const marketSegments = useMarketSegments()
  useResetFiltersOnFirstRender()

  const { newCustomPortfolio, way1523DisableNcpFilters } = useFlags<Flag>()

  useEffect(ensureCurrentlyEditingItemExists, [newDataInputs, setWhichItemIsBeingEdited])

  useEffect(ensureCurrentlyEditingNcpItemExists, [ncpAdjustments, setCurrentlyEditingNcpId])

  const setDefaultView = useCallback(() => {
    setBusinessType(NewBusinessTypeWithCustomPortfolio.VIRTUAL)
  }, [setBusinessType])

  useEffect(() => {
    if (!currentBusinessType) {
      setDefaultView()
    }
  }, [setDefaultView, currentBusinessType])

  const updateNewPortfolioType = (newType: NewBusinessTypeWithCustomPortfolio) => setNewPortfolioType(newType)
  const updateCurrentGwpInput = (newGwp: PossiblyNegativeNumber) => setGwpInput(newGwp)

  const defaultMarketSegmentNames = defaultMarketData?.marketSegments.map((segment) => segment.segmentName) ?? []

  useEffect(() => {
    ncpStateDispatch({
      type: 'initialiseSegments',
      payload: { segmentNames: defaultMarketSegmentNames },
    })
  }, [marketSegments])

  if (!currentScenario?.id) {
    return (
      <div className="New">
        <SelectScenarioMessage />
      </div>
    )
  }

  const isPreviewingPortfolio =
    newPortfolioType === NewBusinessTypeWithCustomPortfolio.VIRTUAL &&
    currentlyEditingPseudoItemId === NEW_ITEM_PREVIEW_ID

  const isPreviewingNcp =
    newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO &&
    currentlyEditingNcpItemId === NEW_ITEM_PREVIEW_ID

  return (
    <div className="New">
      <GraphStyleProvider>
        <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
          <SplitContentContainerWithSideMenu
            leftColumm={
              <FiltersContainer>
                <SectionHeader title="Adjustment Control" />
                <ScenarioFilterCard
                  availableBusinessTypes={
                    newCustomPortfolio === 'show'
                      ? Object.values(NewBusinessTypeWithCustomPortfolio)
                      : Object.values(NewBusinessType)
                  }
                  currentBusinessType={currentBusinessType}
                  setBusinessType={setBusinessType}
                  isDisabled={
                    currentBusinessType !== NewBusinessTypeWithCustomPortfolio.NEW_CLIENT &&
                    newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO &&
                    way1523DisableNcpFilters
                  }
                />
                {canBeEdited && (
                  <div className="CreateNewPortfolioItem">
                    {(isPreviewingPortfolio || isPreviewingNcp) &&
                      currentBusinessType !== NewBusinessTypeWithCustomPortfolio.NEW_CLIENT && (
                        <CreateNewPortfolioItemCard
                          scenarioId={currentScenario!.id}
                          itemBeingEditedId={NEW_ITEM_PREVIEW_ID}
                          currentGwpInput={currentGwpInput}
                          newPortfolioType={newPortfolioType}
                          requestCloseModal={() => {
                            if (isPreviewingPortfolio) {
                              setCurrentlyEditingNcpId(NEW_ITEM_PREVIEW_ID)
                            } else {
                              setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
                            }
                          }}
                          updateCurrentGwpInput={updateCurrentGwpInput}
                          updateNewPortfolioType={updateNewPortfolioType}
                        />
                      )}
                    {currentBusinessType === NewBusinessTypeWithCustomPortfolio.NEW_CLIENT &&
                      currentlyEditingNewClientItemId === NEW_ITEM_PREVIEW_ID && (
                        <CreateNewPortfolioclientCard
                          scenarioId={currentScenario!.id}
                          itemBeingEditedId={NEW_ITEM_PREVIEW_ID}
                          defaultMarketDataSegmentNames={defaultMarketSegmentNames}
                          requestCloseModal={() => {
                            setCurrentlyEditingNewClientId(NEW_ITEM_PREVIEW_ID)
                          }}
                        />
                      )}
                  </div>
                )}
              </FiltersContainer>
            }
            rightColumn={
              !graphPanelIsOpen ? (
                <div>
                  <SectionHeader title="Adjustments" />
                  <NewPortfolioItemContainer
                    currentBusinessType={currentBusinessType}
                    defaultMarketDataSegmentNames={defaultMarketSegmentNames}
                    currentGwpInput={currentGwpInput}
                    setBusinessType={setBusinessType}
                    updateNewPortfolioType={updateNewPortfolioType}
                    updateCurrentGwpInput={updateCurrentGwpInput}
                  />
                  {currentBusinessType !== NewBusinessTypeWithCustomPortfolio.NEW_CLIENT &&
                    newPortfolioType === NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO &&
                    isPreviewingNcp && (
                      <NewCustomPortfolioSplits
                        currentGwpInput={currentGwpInput}
                        isPreview
                      />
                    )}
                </div>
              ) : (
                <SharedGraphs />
              )
            }
            sideMenu={
              <KpiBar>
                <FullKpiDisplay />
              </KpiBar>
            }
          />
        </RenewalGraphFilterDataProvider>
      </GraphStyleProvider>
    </div>
  )
}

const NewWithFiltersAndProgress = () => (
  <MarketV2Provider>
    <NewCustomPortfolioStateProvider>
      <NewCustomPortfolioAlertStateProvider>
        <NewCustomPortfolioAdjustmentProvider>
          <New />
        </NewCustomPortfolioAdjustmentProvider>
      </NewCustomPortfolioAlertStateProvider>
    </NewCustomPortfolioStateProvider>
  </MarketV2Provider>
)

export default NewWithFiltersAndProgress
