import React, { createContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

const GraphStyleContext = createContext<GraphStyle>({
  currentlyExpandedGraph: '',
  setCurrentlyExpandedGraph: () => void 0,
})

const { Provider } = GraphStyleContext

export interface GraphStyle {
  currentlyExpandedGraph?: string | null
  setCurrentlyExpandedGraph: (value: string | undefined) => void
}

type GraphStyleProps = React.PropsWithChildren<{}>

const GraphStyleProvider = ({ children }: GraphStyleProps) => {
  const [currentlyExpandedGraph, setCurrentlyExpandedGraph] = useQueryParam('currentlyExpandedGraphId', StringParam)
  return (
    <Provider
      value={{ currentlyExpandedGraph, setCurrentlyExpandedGraph }}
      children={children}
    />
  )
}

export { GraphStyleProvider, GraphStyleContext }
