import React, { createContext, useContext, useEffect, useState } from 'react'

import { LoadingStatus } from '../utils/lists'
import { fetchPortfolioListing, fetchActuals, Actual, ActualsOptions } from '../backend/teams'
import { AppQueryContext } from './AppQueryProvider'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { PortfolioResponse } from '../backend/api-types/PortfolioListing.types'

type Container = {
  portfolioStatus: PortfolioStatus
  actuals: undefined | Actual[] // TODO: make this snowflake actuals
  reloadListing: () => Promise<void>
}

export type PortfolioStatus = LoadingStatus<'portfolio', PortfolioResponse>

export const PortfolioListingContext = createContext<Container>({
  portfolioStatus: { status: 'unloaded', portfolio: undefined },
  actuals: undefined,
  reloadListing: () => Promise.resolve(),
})

type Props = React.PropsWithChildren<{}>

export function PortfolioListingProvider(props: Props): JSX.Element {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery, divisionQuery, teamQuery, marketQuery } = useContext(AppQueryContext)

  const [actuals, setActuals] = useState<Container['actuals']>(undefined)
  const [portfolioStatus, setPortfolioStatus] = useState<Container['portfolioStatus']>({
    status: 'unloaded',
    portfolio: undefined,
  })

  const [yearFromQuery] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery
  const [marketFromQuery] = marketQuery

  // `reloadListing()` (seems to be) triggered explicity from other components to generate a new listing of data
  const reloadListing = async (): Promise<void> => {
    updateIndividualProgressItem('portfolioListingFetch', ProgressState.LOADING)
    setPortfolioStatus({ status: 'loading', portfolio: undefined })
    fetchPortfolioListing()
      .then(
        (portfolio) => setPortfolioStatus({ status: 'loaded', portfolio }),
        (err) => setPortfolioStatus({ status: 'error', portfolio: undefined, error: err?.message }),
      )
      .finally(() => updateIndividualProgressItem('portfolioListingFetch', ProgressState.FINISHED))
  }

  // Use useEffect to fetch the actuals data anytime the query params change
  useEffect(() => {
    updateIndividualProgressItem('actuals', ProgressState.LOADING)
    const controller = new AbortController()
    const options: ActualsOptions = {
      signal: controller.signal,
      parameters: {
        year: yearFromQuery ?? undefined,
        division: divisionFromQuery ?? undefined,
        team: teamFromQuery ?? undefined,
        market: marketFromQuery ?? undefined,
      },
    }
    fetchActuals(options) // -> fetchSnowflakeActuals
      .then(
        (res) => setActuals(res.actuals),
        (err) => console.warn(err?.message),
      )
      .finally(() => updateIndividualProgressItem('actuals', ProgressState.FINISHED))

    return () => {
      return controller.abort()
    }
  }, [updateIndividualProgressItem, yearFromQuery, divisionFromQuery, teamFromQuery, marketFromQuery])

  useEffect(
    () => {
      reloadListing()
    },
    // `reloadListing()` is used as a handle, as well as inside this useEffect. Adding it to the dependency array causes an infinite loop. TODO: Change the way this is handled
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <PortfolioListingContext.Provider value={{ portfolioStatus, reloadListing, actuals }}>
      {props.children}
    </PortfolioListingContext.Provider>
  )
}
