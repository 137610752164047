import { useContext } from 'react'
import { withRouter } from 'react-router'
import './PortfolioDataView.scss'
import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import PortfolioDataTable, {
  appendColumnsToEnd,
  WayfinderInputFieldsToAppendToEnd,
} from '../../components/portfolio-data-table/PortfolioDataTable'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import Button from '../../components/button/Button'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { filterOutColumnsWeDontWant } from '../../utils/outputs/outputFilter'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import SplitContentContainer from '../../components/split-content-container/SplitContentContainer'
import Spacer from '../../components/spacer/Spacer'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import { INCEPTION_MONTH_COLUMN_NAME } from '../../components/renewal-graph/gwp-bar-chart/inception-month-graph'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'

function PortfolioDataView() {
  const { currentScenario } = useContext(ScenarioContext)
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)

  useResetFiltersOnFirstRender()

  if (!currentScenario) {
    return (
      <div className="PortfolioDataView">
        <SelectScenarioMessage />
      </div>
    )
  }
  const name = `${currentScenario.division} - ${currentScenario.team} - ${currentScenario.market} - ${currentScenario.name}`
  const downloadCsv = () => {
    const filteredDataColumnsRemoved = filterOutColumnsWeDontWant(filteredDataToUseInGraph)
    const filteredDataColumnsAppended = appendColumnsToEnd(filteredDataColumnsRemoved, [
      WayfinderInputFieldsToAppendToEnd.DIGITAL_CHANNEL,
      WayfinderInputFieldsToAppendToEnd.ORIG_TO_BASE_EXCHANGE_RATE,
    ])
    const allRawLines = createCsvFile(filteredDataColumnsAppended)
    saveDataToFile(name, allRawLines)
  }

  return (
    <div className="PortfolioDataView">
      <PageHeaderContainer>
        <Spacer />
        <Button
          title={'Get CSV'}
          onClick={downloadCsv}
        />
      </PageHeaderContainer>
      <SplitContentContainer
        leftcolumn={
          <FiltersContainer>
            <ScenarioFilterCard extraFilterValues={[INCEPTION_MONTH_COLUMN_NAME]} />
          </FiltersContainer>
        }
        rightcolumn={
          <div className="PortfolioDataTable">
            <PortfolioDataTable />
          </div>
        }
      />
    </div>
  )
}

const PortfolioDataViewWithProgress = () => (
  <MarketV2Provider>
    <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
      <PortfolioDataView />
    </RenewalGraphFilterDataProvider>
  </MarketV2Provider>
)
export default withRouter(PortfolioDataViewWithProgress)
