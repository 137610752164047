import React, { MouseEventHandler, PropsWithChildren } from 'react'
import './Tag.scss'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'

export type TagProps = React.PropsWithChildren<{
  title: string
  tooltip: string
  textColor: string
  backgroundColor: string
  onDelete?: MouseEventHandler | undefined | false
  id?: string
}>

export enum STACKED_SCENARIO_AVAILABLE_TAGS {
  BUSINESS_PLAN = 'BUSINESS_PLAN',
  ACTIVITY_1 = 'ACTIVITY_1',
}

export enum SCENARIO_AVAILABLE_TAGS {
  CURRENT_ESTIMATE = 'CURRENT_ESTIMATE',
}

export function getDisplayForTag(tagName: string) {
  if (tagName === STACKED_SCENARIO_AVAILABLE_TAGS.ACTIVITY_1) {
    return 'AF'
  }
  if (tagName === SCENARIO_AVAILABLE_TAGS.CURRENT_ESTIMATE) {
    return 'CE'
  }

  return tagName
    .split(/[-_ ]+/)
    .map((item) => item[0].toUpperCase())
    .join('')
}

export function getTooltipForTag(tagName: string) {
  if (tagName === STACKED_SCENARIO_AVAILABLE_TAGS.ACTIVITY_1) {
    return 'Activity 1 Forecast'
  }
  if (tagName === SCENARIO_AVAILABLE_TAGS.CURRENT_ESTIMATE) {
    return 'Current Estimate'
  }
  return formatEnumForDisplay(tagName)
}

export function getColourForTag(tagName: string) {
  return 'white'
}

export function getBackgroundColourForTag(tagName: string) {
  switch (tagName) {
    case STACKED_SCENARIO_AVAILABLE_TAGS.BUSINESS_PLAN:
      return 'orange'
    case STACKED_SCENARIO_AVAILABLE_TAGS.ACTIVITY_1:
      return 'purple'
    case SCENARIO_AVAILABLE_TAGS.CURRENT_ESTIMATE:
      return 'deepskyblue'
    default:
      return 'grey'
  }
}

export function Tag(props: TagProps) {
  return (
    <div
      className="Tag"
      style={{ color: props.textColor, backgroundColor: props.backgroundColor }}
      title={props.tooltip}
    >
      <p
        className="TagTitle"
        id={props.id}
      >
        {props.title}
      </p>
    </div>
  )
}

export function TagContainer(props: PropsWithChildren<{}>) {
  return <div className="TagContainer">{props.children}</div>
}

export default Tag
