import React from 'react'
import './ContentContainer.scss'
export type ContentContainerProps = React.PropsWithChildren<{}>

export function ContentContainer(props: ContentContainerProps) {
  return (
    <div
      className="ContentContainer"
      {...props}
    >
      {props.children}
    </div>
  )
}

export default ContentContainer
