import React, { SVGProps } from 'react'

const SummaryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#4E566B"
      fillRule="evenodd"
      d="M8,1.33333333 C4.33333333,1.33333333 1.33333333,4.33333333 1.33333333,8 C1.33333333,11.6666667 4.33333333,14.6666667 8,14.6666667 C11.6666667,14.6666667 14.6666667,11.6666667 14.6666667,8 C14.6666667,4.33333333 11.6666667,1.33333333 8,1.33333333 Z M8.66666667,2.71333333 C11.0733333,3.01333333 12.9866667,4.93333333 13.2866667,7.33333333 L8.66666667,7.33333333 L8.66666667,2.71333333 Z M2.66666667,8 C2.66666667,5.29333333 4.71333333,3.04 7.33333333,2.71333333 L7.33333333,13.2933333 C4.71333333,12.96 2.66666667,10.7066667 2.66666667,8 L2.66666667,8 Z M8.66666667,13.2866667 L8.66666667,8.66666667 L13.2866667,8.66666667 C12.9866667,11.0733333 11.0733333,12.9866667 8.66666667,13.2866667 Z"
    />
  </svg>
)

export default SummaryIcon
