import React, { MouseEvent, MouseEventHandler, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import './Button.scss'
import { css } from '../../utils/css'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'

export type ButtonProps = {
  title: string | JSX.Element
  id?: string
  secondary?: boolean
  className?: string
  onClick: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => Promise<void> | void
  disabled?: boolean
}

export function Button(props: PropsWithChildren<ButtonProps>): JSX.Element {
  const { secondary, title, disabled, className } = props
  const { progressIndicators, updateIndividualProgressItem } = useContext(ProgressContext)

  const buttonIdentifier: string = useMemo(() => `button-${title}-${uuid()}`, [title])

  const isLoading: boolean = useMemo(
    () => progressIndicators[buttonIdentifier] === ProgressState.LOADING,
    [progressIndicators, buttonIdentifier],
  )

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      updateIndividualProgressItem(buttonIdentifier, ProgressState.LOADING)
      try {
        await props.onClick(event)
        updateIndividualProgressItem(buttonIdentifier, ProgressState.FINISHED)
      } catch (e) {
        updateIndividualProgressItem(buttonIdentifier, ProgressState.ERROR)
      }
    },
    [buttonIdentifier, props, updateIndividualProgressItem],
  )

  return (
    <button
      disabled={disabled}
      id={props.id}
      className={css(
        'Button',
        className,
        secondary ? 'Secondary' : '',
        isLoading ? 'Loading' : '',
        disabled ? 'Disabled' : '',
      )}
      onClick={isLoading ? undefined : handleOnClick}
    >
      {title}
    </button>
  )
}

export default Button
