import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { fetchNewPortfolioItems, NEW_ITEM_PREVIEW_ID } from '../backend/new-portfolio-items'
import { NewAdjustment } from '../../../backend/src/modules/new-adjustments/domain/NewAdjustment.types'
import { ScenarioContext } from './ScenarioProvider'
import { FullScenarioDataContext } from './FullScenarioData/FullScenarioDataProvider'

export const defaultPseudoPortfolioContext: NewPseudoPortfolioItemsContextContainer = {
  newDataInputs: undefined,
  changeNewDataInputs: () => undefined,
  triggerReloadSavedNewDataInputs: () => undefined,
  errors: [],
  erroredAdjustmentIds: [],
  currentlyEditingPseudoItemId: NEW_ITEM_PREVIEW_ID,
  setWhichItemIsBeingEdited: () => undefined,
  currentlyPreviewingPseudoItemId: '',
  setWhichItemIsBeingPreviewed: () => undefined,
}

export const PseudoPortfolioContext =
  createContext<NewPseudoPortfolioItemsContextContainer>(defaultPseudoPortfolioContext)

export interface NewPseudoPortfolioItemsContextContainer {
  newDataInputs: NewAdjustment[] | undefined
  changeNewDataInputs: (newDataInputs: NewAdjustment[] | undefined) => void
  triggerReloadSavedNewDataInputs: () => void
  errors: string[]
  erroredAdjustmentIds: string[]
  currentlyEditingPseudoItemId: string
  setWhichItemIsBeingEdited: (input: string) => void
  currentlyPreviewingPseudoItemId: string
  setWhichItemIsBeingPreviewed: (input: string) => void
}

export const PseudoPortfolioProvider = (props: PropsWithChildren) => {
  const { reloadData, setPreviewNewVirtual } = useContext(FullScenarioDataContext)
  const [currentlyEditingItemId, setWhichItemIsBeingEdited] = useState<string>(NEW_ITEM_PREVIEW_ID)
  const [currentlyPreviewingPseudoItemId, setWhichItemIsBeingPreviewed] = useState<string>('')
  const [newDataInputs, setNewDataInputs] = useState<NewAdjustment[] | undefined>(undefined)
  const [erroredAdjustmentIds] = useState<string[]>([]) // TODO no way to set it -> 1) no need to be state 2) will always be []
  const [errors] = useState<string[]>([]) // TODO no way to set it -> 1) no need to be state 2) will always be []
  const { currentScenario } = useContext(ScenarioContext)

  const triggerReloadSavedNewDataInputsInternal: () => Promise<void> = useCallback(async () => {
    if (currentScenario) {
      const newAdjustments = await fetchNewPortfolioItems(currentScenario!.id)
      setNewDataInputs(newAdjustments)
    }
  }, [currentScenario])

  const triggerReloadSavedNewDataInputs: () => Promise<void> = useCallback(async () => {
    await triggerReloadSavedNewDataInputsInternal()
    currentScenario && reloadData()
  }, [triggerReloadSavedNewDataInputsInternal, reloadData, currentScenario])

  const changeNewDataInputs: (newDataInputs: NewAdjustment[] | undefined) => void = useCallback(
    (newDataInputs) => {
      setNewDataInputs(newDataInputs)
      const possiblePreviewAdjustment = newDataInputs?.find((item) => item.id === NEW_ITEM_PREVIEW_ID)
      setPreviewNewVirtual(possiblePreviewAdjustment && currentScenario ? possiblePreviewAdjustment : undefined)
    },
    [currentScenario],
  )

  useEffect(() => {
    triggerReloadSavedNewDataInputsInternal()
  }, [triggerReloadSavedNewDataInputsInternal])

  return (
    <PseudoPortfolioContext.Provider
      value={{
        newDataInputs,
        changeNewDataInputs,
        triggerReloadSavedNewDataInputs,
        errors,
        erroredAdjustmentIds,
        currentlyEditingPseudoItemId: currentlyEditingItemId,
        setWhichItemIsBeingEdited,
        currentlyPreviewingPseudoItemId,
        setWhichItemIsBeingPreviewed,
      }}
    >
      {props.children}
    </PseudoPortfolioContext.Provider>
  )
}
