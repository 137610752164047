import { ScenarioStatus } from './ScenarioStatus'

const EVERY_STATUS_ALLOWED_TRANSITIONS: Record<ScenarioStatus, Array<ScenarioStatus>> = {
  [ScenarioStatus.IN_PROGRESS]: [ScenarioStatus.IN_REVIEW],
  [ScenarioStatus.IN_REVIEW]: [ScenarioStatus.IN_PROGRESS, ScenarioStatus.COMPLETE],
  [ScenarioStatus.COMPLETE]: [],
  [ScenarioStatus.DELETED]: [],
}

export function calculatePossibleStatusesToMoveTo(
  isAdmin: boolean,
  currentStatus: ScenarioStatus,
): Array<ScenarioStatus> {
  if (isAdmin) {
    return [ScenarioStatus.IN_PROGRESS, ScenarioStatus.IN_REVIEW, ScenarioStatus.COMPLETE]
  }

  return EVERY_STATUS_ALLOWED_TRANSITIONS[currentStatus]
}

export function scenarioCanStillBeWorkedOn(isAdmin: boolean, currentStatus: ScenarioStatus): boolean {
  return currentStatus === ScenarioStatus.IN_PROGRESS
}
