import { useCallback, useContext, useEffect, useMemo } from 'react'
import { UrlUpdateType } from 'use-query-params'

import './NewPortfolioItemContainer.scss'

import {
  NewPseudoPortfolioItemsContextContainer,
  PseudoPortfolioContext,
} from '../../providers/NewPseudoPortfolioItemsProvider'
import {
  deleteNewPortfolioItem,
  isIrrelevantNewItem,
  isRelevantNewItem,
  NEW_ITEM_PREVIEW_ID,
  patchNewPortfolioItem,
  sortNewPortfolioItemsByOldestFirst,
} from '../../backend/new-portfolio-items'
import { deleteNewCustomPortfolioItem, patchNewCustomPortfolioItem } from '../../backend/new-custom-portfolio-items'
import { CreateNewPortfolioItemCard } from '../create-new-portfolio-item-card/CreateNewPortfolioItemCard'
import CreateNewPortfolioclientCard from '../create-new-protfolio-client-card/CreateNewPortfolioClientCard'
import PortfolioItemCard from '../portfolio-item-card/PortfolioItemCard'
import { PREVIEW_ID } from '../../backend/adjustments'
import { getItemsInNewOrderIgnoringPreview } from '../draggable-card/draggable-order'
import {
  ADJUSTED_PREMIUM_VALUE,
  ADJUSTMENT_ID,
  PREMIUM_COLUMN_NAME,
  TOTAL_ADJUSTED_GWP_COLUMN,
  TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  TOTAL_GWP_COLUMN,
  TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
} from '../../backend/calculate-kpis'
import {
  formatPercentageAsIntegerForProgressBar,
  isZero,
  numericValueIsDefined,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  sumListAsIntegersWithFallbackKeys,
  ZERO,
} from '../../utils/numbers'
import { ACTUALS_ADJUSTMENT_ID } from '../../backend/calculate-with-actuals'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import NewClientItemCard from '../portfolio-item-card/NewClientItemCard'
import { FilterContext } from '../../providers/FilterProvider'
import { NewBusinessTypeWithCustomPortfolio } from '../../pages/new/New'
import { NewCustomPortfolioAdjustmentContext } from '../../providers/NewCustomPortfolioAdjustmentsProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../../pages/exposures/StackedExposures'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { NcpDispatchContext } from '../../providers/NewCustomPortfolioStateProvider'
import { NcpAlertDispatchContext } from '../../providers/NewCustomPortfolioAlertStateProvider'

export interface AdjustmentContainerProps {
  hideCreateAndEdit?: boolean
  currentBusinessType: string | null | undefined
  defaultMarketDataSegmentNames?: string[]
  currentGwpInput?: PossiblyNegativeNumber
  setBusinessType: (newValue: NewBusinessTypeWithCustomPortfolio, updateType?: UrlUpdateType | undefined) => void
  updateNewPortfolioType?: (newType: NewBusinessTypeWithCustomPortfolio) => void
  updateCurrentGwpInput?: (newGwp: PossiblyNegativeNumber) => void
}

function NewPortfolioItemContainer({
  hideCreateAndEdit,
  currentBusinessType,
  defaultMarketDataSegmentNames,
  currentGwpInput,
  updateNewPortfolioType,
  updateCurrentGwpInput,
}: AdjustmentContainerProps) {
  /**
   * @TODO refactor setWhichItemIsBeingEdited/setCurrentlyEditingNewClientId
   * Since the new adjustment and new NCP adjustment IDs are unique, we can use the same state to track the currently edited item
   */
  const {
    newDataInputs,
    triggerReloadSavedNewDataInputs,
    changeNewDataInputs,
    currentlyEditingPseudoItemId,
    setWhichItemIsBeingEdited,
    erroredAdjustmentIds,
  } = useContext<NewPseudoPortfolioItemsContextContainer>(PseudoPortfolioContext)
  const {
    ncpAdjustments,
    currentlyEditingNcpItemId,
    setCurrentlyEditingNcpId,
    triggerReloadSavedNcpAdjustments,
    setIsNcpPreview,
  } = useContext(NewCustomPortfolioAdjustmentContext)
  const { newClients, currentlyEditingNewClientItemId, setCurrentlyEditingNewClientId } =
    useContext(NewClientItemsContext)
  const { fullRenewalKpis, fullNewKpis, filteredNewClientDataForScenario, fullDataForScenario } =
    useContext(FullScenarioDataContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { onChangeFilters } = useContext(FilterContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { way1401NewCustomPortfolio } = useFlags<Flag>()
  const numberOfDataInputs = useMemo(() => newDataInputs?.length || 0, [newDataInputs])
  const ncpDispatch = useContext(NcpDispatchContext)
  const ncpAlertDispatch = useContext(NcpAlertDispatchContext)

  const newDataInputsReadyForDisplay = newDataInputs
    ? sortNewPortfolioItemsByOldestFirst(newDataInputs.filter(isIrrelevantNewItem(NEW_ITEM_PREVIEW_ID)))
    : []
  const ncpDataReadyForDisplay = ncpAdjustments
    ? sortNewPortfolioItemsByOldestFirst(ncpAdjustments.filter(isIrrelevantNewItem(NEW_ITEM_PREVIEW_ID)))
    : []
  const ensureCurrentlyEditingItemExists = () => {
    if (
      newDataInputs !== undefined &&
      currentlyEditingPseudoItemId &&
      currentlyEditingPseudoItemId !== NEW_ITEM_PREVIEW_ID &&
      (!newDataInputs.length || !newDataInputs.find(isRelevantNewItem(currentlyEditingPseudoItemId)))
    ) {
      setWhichItemIsBeingEdited('')
    } else if (newDataInputs !== undefined && !newDataInputs.length && !currentlyEditingPseudoItemId) {
      setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
    }
  }

  const onHover = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const adjustmentsWithUpdatedOrderNumber = getItemsInNewOrderIgnoringPreview(
        newDataInputs!,
        dragIndex,
        hoverIndex,
        isIrrelevantNewItem(PREVIEW_ID),
      )

      const previewAdjustment = newDataInputs!.find(isRelevantNewItem(PREVIEW_ID))

      if (previewAdjustment) {
        adjustmentsWithUpdatedOrderNumber.push(previewAdjustment)
      }

      changeNewDataInputs(adjustmentsWithUpdatedOrderNumber)

      return adjustmentsWithUpdatedOrderNumber
    },
    [newDataInputs, changeNewDataInputs],
  )

  const resetAdjustmentsPage = () => {
    triggerReloadSavedNewDataInputs()
    setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
    setCurrentlyEditingNewClientId(NEW_ITEM_PREVIEW_ID)
    onChangeFilters({})
  }

  const toggleShowingAdjustment = async (adjustmentId: string, isEnabled: boolean, isNcpAdjustment: boolean) => {
    updateIndividualProgressItem('togglingNewItem', ProgressState.LOADING)

    try {
      const patchItem = isNcpAdjustment ? patchNewCustomPortfolioItem : patchNewPortfolioItem
      const reload = isNcpAdjustment ? triggerReloadSavedNcpAdjustments : triggerReloadSavedNewDataInputs

      await patchItem(currentScenario!.id, adjustmentId, {
        isEnabled: !isEnabled,
      })
      reload()
      updateIndividualProgressItem('togglingNewItem', ProgressState.FINISHED)
    } catch {
      updateIndividualProgressItem('togglingNewItem', ProgressState.ERROR)
    }
  }

  const onDeleteItem = async (adjustmentId: string, isNcpAdjustment: boolean) => {
    updateIndividualProgressItem('deletingNewItem', ProgressState.LOADING)

    try {
      const deleteItem = isNcpAdjustment ? deleteNewCustomPortfolioItem : deleteNewPortfolioItem
      const reload = isNcpAdjustment ? triggerReloadSavedNcpAdjustments : triggerReloadSavedNewDataInputs

      await deleteItem(currentScenario!.id, adjustmentId)
      reload()
      updateIndividualProgressItem('deletingNewItem', ProgressState.FINISHED)
    } catch {
      updateIndividualProgressItem('deletingNewItem', ProgressState.ERROR)
    }
  }

  const onDrop = async (dragIndex: number, hoverIndex: number) => {
    const adjustmentsWithUpdatedOrderNumber = await onHover(dragIndex, hoverIndex)
    const allPromises = adjustmentsWithUpdatedOrderNumber
      .filter(isIrrelevantNewItem(PREVIEW_ID))
      .map((changedAdjustment) =>
        patchNewPortfolioItem(currentScenario!.id, changedAdjustment.id, {
          orderNumber: changedAdjustment.orderNumber,
        }),
      )

    await Promise.all(allPromises)
    resetAdjustmentsPage()
  }

  useEffect(ensureCurrentlyEditingItemExists, [newDataInputs, setWhichItemIsBeingEdited])

  const calculateAdjustedPercentageOfOriginalGWP = (
    adjustmentId: string,
    fieldToUseToWorkOutValue: 'Total Adjusted GWP per Adjustment' | 'Total Original GWP per Adjustment',
  ) => {
    const currentAdjustmentGWP =
      fullNewKpis &&
      fullNewKpis[fieldToUseToWorkOutValue] &&
      numericValueIsDefined(fullNewKpis[fieldToUseToWorkOutValue][adjustmentId])
        ? fullNewKpis[fieldToUseToWorkOutValue][adjustmentId]!
        : ZERO

    const totalOfNewAdjustments = fullNewKpis ? fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN] : ZERO
    const totalOfOtherStuff = fullRenewalKpis ? fullRenewalKpis[TOTAL_GWP_COLUMN] : ZERO

    const totalOfBoth = totalOfNewAdjustments.plus(totalOfOtherStuff)

    const value = isZero(totalOfBoth) ? ZERO : currentAdjustmentGWP.div(totalOfBoth).mul(ONE_HUNDRED)
    return formatPercentageAsIntegerForProgressBar(value)
  }

  const calculateNumberOfItems = (adjustmentId: string) => {
    const dataCausedByAdj = fullDataForScenario.filter((item) => item[ADJUSTMENT_ID] === adjustmentId)

    return dataCausedByAdj.length
  }

  const canBeEdited =
    !hideCreateAndEdit &&
    currentScenario?.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)

  return (
    <div className="NewPortfolioItemContainer">
      {fullNewKpis && fullNewKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][ACTUALS_ADJUSTMENT_ID] && (
        <PortfolioItemCard
          index={1}
          gwpValue={fullNewKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][ACTUALS_ADJUSTMENT_ID]?.toNumber() || 0}
          adjustedGwpValue={
            fullNewKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][ACTUALS_ADJUSTMENT_ID]?.toNumber() || 0
          }
          canDrag={false}
          name={'Actuals'}
          itemId={ACTUALS_ADJUSTMENT_ID}
          description={''}
          scenarioId={currentScenario!.id}
          isEnabled={true}
          hasErrored={false}
          key={ACTUALS_ADJUSTMENT_ID}
          adjustedPercentage={calculateAdjustedPercentageOfOriginalGWP(
            ACTUALS_ADJUSTMENT_ID,
            TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
          )}
          hideCreateAndEdit={true}
          toggleShowingAdjustment={() => toggleShowingAdjustment(ACTUALS_ADJUSTMENT_ID, true, false)}
          onDeleteItem={() => onDeleteItem(ACTUALS_ADJUSTMENT_ID, false)}
        />
      )}
      {Boolean(filteredNewClientDataForScenario.length) && (
        <PortfolioItemCard
          index={1}
          gwpValue={sumListAsIntegersWithFallbackKeys(filteredNewClientDataForScenario, ADJUSTED_PREMIUM_VALUE, [
            PREMIUM_COLUMN_NAME,
          ]).toNumber()}
          adjustedGwpValue={sumListAsIntegersWithFallbackKeys(
            filteredNewClientDataForScenario,
            ADJUSTED_PREMIUM_VALUE,
            [PREMIUM_COLUMN_NAME],
          ).toNumber()}
          canDrag={false}
          name={'New Clients'}
          itemId={'NEW_CLIENT_SUMMARY_ID'}
          description={''}
          numberOfItems={filteredNewClientDataForScenario?.length}
          scenarioId={currentScenario!.id}
          isEnabled={true}
          hasErrored={false}
          key={'NEW_CLIENT_SUMMARY_ID'}
          hideCreateAndEdit={true}
          adjustedPercentage={'0'}
          toggleShowingAdjustment={() => toggleShowingAdjustment('NEW_CLIENT_SUMMARY_ID', true, false)}
          onDeleteItem={() => onDeleteItem('NEW_CLIENT_SUMMARY_ID', false)}
        />
      )}
      <div className="SmallDivider" />
      {currentBusinessType === NewBusinessTypeWithCustomPortfolio.VIRTUAL &&
        newDataInputsReadyForDisplay.map((currentDataInput, index) =>
          currentlyEditingPseudoItemId === currentDataInput.id ? (
            <CreateNewPortfolioItemCard
              scenarioId={currentScenario!.id}
              key={'create-' + currentlyEditingPseudoItemId}
              itemBeingEditedId={currentlyEditingPseudoItemId}
              currentGwpInput={currentGwpInput}
              newPortfolioType={currentBusinessType}
              requestCloseModal={() => {
                onChangeFilters({})
                setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
              }}
              updateCurrentGwpInput={updateCurrentGwpInput}
              updateNewPortfolioType={updateNewPortfolioType}
            />
          ) : (
            <PortfolioItemCard
              index={index}
              key={'view-' + currentDataInput.id}
              itemId={currentDataInput.id}
              name={currentDataInput.name}
              gwpValue={currentDataInput.gwpValue}
              adjustedGwpValue={
                fullNewKpis?.[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]?.[currentDataInput.id]?.toNumber() || 0
              }
              grarcValue={currentDataInput.grossRiskAdjustedRateChange}
              description={currentDataInput.description}
              orderNumber={currentDataInput.orderNumber}
              lowestOrderNumberIs0={
                Math.min.apply(Math, newDataInputsReadyForDisplay?.map((item) => item.orderNumber) || []) === 0
              }
              scenarioId={currentScenario!.id}
              isEnabled={currentDataInput.isEnabled}
              onDrop={onDrop}
              canDrag={canBeEdited ? currentlyEditingPseudoItemId === NEW_ITEM_PREVIEW_ID : false}
              adjustedPercentage={calculateAdjustedPercentageOfOriginalGWP(
                currentDataInput.id,
                TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
              )}
              numberOfItems={calculateNumberOfItems(currentDataInput.id)}
              onHover={canBeEdited ? onHover : undefined}
              onTriggerEditItem={
                canBeEdited
                  ? () => {
                      setWhichItemIsBeingEdited(currentDataInput.id)
                      setCurrentlyEditingNcpId(NEW_ITEM_PREVIEW_ID)
                    }
                  : undefined
              }
              onPreviewItem={() => {
                setIsNcpPreview(false)
                setCurrentlyEditingNcpId(NEW_ITEM_PREVIEW_ID)
                updateNewPortfolioType && updateNewPortfolioType(NewBusinessTypeWithCustomPortfolio.VIRTUAL)
              }}
              hasErrored={erroredAdjustmentIds.includes(currentDataInput.id)}
              hideCreateAndEdit={hideCreateAndEdit}
              way1401NewCustomPortfolio={true}
              toggleShowingAdjustment={() =>
                toggleShowingAdjustment(currentDataInput.id, currentDataInput.isEnabled, false)
              }
              onDeleteItem={() => onDeleteItem(currentDataInput.id, false)}
            />
          ),
        )}
      {currentBusinessType === NewBusinessTypeWithCustomPortfolio.NEW_CLIENT &&
        newClients.map((currentDataInput, index) =>
          currentlyEditingNewClientItemId === currentDataInput.id ? (
            <CreateNewPortfolioclientCard
              scenarioId={currentScenario!.id}
              key={'create-' + currentlyEditingNewClientItemId}
              itemBeingEditedId={currentlyEditingNewClientItemId}
              defaultMarketDataSegmentNames={defaultMarketDataSegmentNames || []}
              requestCloseModal={() => {
                onChangeFilters({})
                setCurrentlyEditingNewClientId(NEW_ITEM_PREVIEW_ID)
              }}
            />
          ) : (
            <NewClientItemCard
              index={index}
              originalItem={currentDataInput}
              key={'view-' + currentDataInput.id}
              scenarioId={currentScenario!.id}
              onDrop={onDrop}
              canDrag={canBeEdited ? !Boolean(currentlyEditingNewClientItemId) : false}
              numberOfItems={calculateNumberOfItems(currentDataInput.id)}
              onHover={canBeEdited ? onHover : undefined}
              onTriggerEditItem={canBeEdited ? setCurrentlyEditingNewClientId : undefined}
              hasErrored={erroredAdjustmentIds.includes(currentDataInput.id)}
              hideCreateAndEdit={hideCreateAndEdit}
            />
          ),
        )}
      {way1401NewCustomPortfolio &&
        currentBusinessType === NewBusinessTypeWithCustomPortfolio.VIRTUAL &&
        ncpDataReadyForDisplay.map((currentDataInput, index) =>
          currentlyEditingNcpItemId === currentDataInput.id ? (
            <CreateNewPortfolioItemCard
              scenarioId={currentScenario!.id}
              key={'create-' + currentlyEditingNcpItemId}
              itemBeingEditedId={currentlyEditingNcpItemId}
              currentGwpInput={currentGwpInput}
              newPortfolioType={NewBusinessTypeWithCustomPortfolio.CUSTOM_PORTFOLIO}
              requestCloseModal={() => {
                const resetDefaultPortfolioType = () =>
                  updateNewPortfolioType && updateNewPortfolioType(NewBusinessTypeWithCustomPortfolio.VIRTUAL)
                onChangeFilters({})
                setCurrentlyEditingNcpId(NEW_ITEM_PREVIEW_ID)
                resetDefaultPortfolioType()
                setIsNcpPreview(false)
                ncpAlertDispatch?.({ type: 'reset' })
                ncpDispatch?.({ type: 'reset', payload: { reset: true } })
                ncpDispatch?.({
                  type: 'resetNcpData',
                })
                updateCurrentGwpInput && updateCurrentGwpInput('' as PossiblyNegativeNumber)
              }}
              updateCurrentGwpInput={updateCurrentGwpInput}
              updateNewPortfolioType={updateNewPortfolioType}
            />
          ) : (
            <PortfolioItemCard
              index={index + numberOfDataInputs}
              key={'view-' + currentDataInput.id}
              itemId={currentDataInput.id}
              name={currentDataInput.name}
              gwpValue={currentDataInput.gwpValue}
              adjustedGwpValue={
                fullNewKpis?.[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]?.[currentDataInput.id]?.toNumber() || 0
              }
              description={currentDataInput.description}
              scenarioId={currentScenario!.id}
              isEnabled={currentDataInput.isEnabled}
              canDrag={false}
              onTriggerEditItem={() => {
                setCurrentlyEditingNcpId(currentDataInput.id)
                setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
                setIsNcpPreview(false)
              }}
              adjustedPercentage={calculateAdjustedPercentageOfOriginalGWP(
                currentDataInput.id,
                TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
              )}
              numberOfItems={calculateNumberOfItems(currentDataInput.id)}
              hasErrored={false}
              hidePreviewFilter
              isNcpAdjustment
              way1401NewCustomPortfolio
              toggleShowingAdjustment={() =>
                toggleShowingAdjustment(currentDataInput.id, currentDataInput.isEnabled, true)
              }
              onDeleteItem={() => onDeleteItem(currentDataInput.id, true)}
              onPreviewItem={() => {
                setCurrentlyEditingNcpId(currentDataInput.id)
                setWhichItemIsBeingEdited(NEW_ITEM_PREVIEW_ID)
                setIsNcpPreview(true)
              }}
            />
          ),
        )}
    </div>
  )
}

export default NewPortfolioItemContainer
