import { useContext, useEffect } from 'react'
import './Summary.scss'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { GraphStyleProvider } from '../../components/renewal-graph/GraphStyleProvider'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { EXTRA_FILTERS_FOR_SUMMARY_PAGE } from '../../backend/calculate-possible-filter-values'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import SectionHeader from '../../components/section-header/SectionHeader'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import SharedGraphs from '../renewal/SharedGraphs'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import { SummaryTableContent } from './SummaryTableContent'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import { NewCustomPortfolioAdjustmentProvider } from '../../providers/NewCustomPortfolioAdjustmentsProvider'
import { fetchNewClients, refreshClientAdjustments } from '../../backend/new-clients'

function Summary() {
  const { currentScenario } = useContext(ScenarioContext)
  const { triggerReloadNewClients } = useContext(NewClientItemsContext)
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  useResetFiltersOnFirstRender()

  useEffect(() => {
    if (!currentScenario) return

    const getNewClientsWithAdjustments = async () => {
      const clients = await fetchNewClients(currentScenario.id)
      if (clients && clients.length > 0) {
        await refreshClientAdjustments(currentScenario?.id, clients[0].id) //TODO this returns NewClient and it is ignored - can it be used instead of triggerReloadNewClients()?
        triggerReloadNewClients() // TODO this does fetchNewClients again, why is it necessary? Maybe WAYF-137?
      }
    }

    getNewClientsWithAdjustments()
  }, [currentScenario])

  if (!currentScenario?.id) {
    return <SelectScenarioMessage />
  }

  return (
    <div className="Summary">
      <SplitContentContainerWithSideMenu
        leftColumm={
          <FiltersContainer>
            <SectionHeader title="Filter Control" />
            <ScenarioFilterCard
              extraFilterValues={EXTRA_FILTERS_FOR_SUMMARY_PAGE}
              outward={true}
            />
          </FiltersContainer>
        }
        rightColumn={!graphPanelIsOpen ? <SummaryTableContent /> : <SharedGraphs />}
        sideMenu={
          <KpiBar>
            <FullKpiDisplay />
          </KpiBar>
        }
      />
    </div>
  )
}

const SummaryWithProgress = () => (
  <MarketV2Provider>
    <RenewalGraphFilterDataProvider
      extraFilterValues={EXTRA_FILTERS_FOR_SUMMARY_PAGE}
      onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}
    >
      <GraphStyleProvider>
        <NewCustomPortfolioAdjustmentProvider>
          <Summary />
        </NewCustomPortfolioAdjustmentProvider>
      </GraphStyleProvider>
    </RenewalGraphFilterDataProvider>
  </MarketV2Provider>
)

export default SummaryWithProgress
