import React, { ReactElement, useContext } from 'react'
import { ResponsiveBarCanvas } from '@nivo/bar'
import { Theme } from '@nivo/core'

import { TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_GWP_COLUMN } from '../../../backend/calculate-kpis'
import { formatForDisplay, numericValueIsDefined } from '../../../utils/numbers'
import { DEFAULT_GRAPH_THEME } from '../graph-theme'
import { GraphDataContext } from '../GraphDataProvider'
import { GraphContainerContext } from '../../graph-container/GraphContainer'
import { GraphStyleContext } from '../GraphStyleProvider'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

type Props = {
  theme?: Theme
  tickRotation?: number
  legendOffset?: number
}

// const DEFAULT_PRIMARY_COLOUR = "#71B8A4"
// const DEFAULT_SECONDARY_COLOUR = "#4D7F71"

const DEFAULT_VALUE_FORMATTING = (value: string | number | Date | undefined): string => {
  if (typeof value === 'number' && numericValueIsDefined(value)) {
    return value.toString()
  } else if (value === undefined || value === 'undefined') {
    return 'Unknown'
  } else {
    return value.toString()
  }
}

export function GwpAndAdjustedGwpGroupedByFieldGraph(props: Props): ReactElement {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const { summarisedData, formatDataFieldForDisplay, fieldNameToGroupBy } = useContext(GraphDataContext)

  const optionsIfGraphExpanded: any =
    currentlyExpandedGraph === graphId
      ? {
          margin: { top: 10, right: 0, bottom: 150, left: 80 },
          axisLeft: {
            format: (item: any) => '$' + formatForDisplay(item as string),
            legend: 'Convex Premium ($)',
            legendPosition: 'middle',
            legendOffset: -64,
          },
          axisBottom: {
            format: formatDataFieldForDisplay ? formatDataFieldForDisplay : DEFAULT_VALUE_FORMATTING,
            tickRotation: props.tickRotation || 0,
            legendPosition: 'middle',
            legendOffset: props.legendOffset || 60,
          },
        }
      : {}

  return (
    <ResponsiveBarCanvas
      data={summarisedData}
      {...optionsIfGraphExpanded}
      label={(item) => '$' + formatForDisplay(item.value)}
      enableLabel={false}
      indexBy={fieldNameToGroupBy}
      keys={[TOTAL_GWP_COLUMN, TOTAL_ADJUSTED_GWP_COLUMN]}
      labelSkipHeight={15}
      labelSkipWidth={20}
      tooltipFormat={(item: any) => '$' + formatForDisplay(item)}
      groupMode="grouped"
      labelTextColor="#fff"
      colors={GRAPH_COLOURS}
      theme={props.theme ? props.theme : DEFAULT_GRAPH_THEME}
    />
  )
}
