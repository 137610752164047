import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { fetchLeavesForStackedScenario, fetchStackedScenarioById, StackedScenario } from '../backend/stacked-scenarios'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { Scenario } from '../backend/scenarios'

export interface StackedScenarioContainer {
  currentStackedScenario: StackedScenario | undefined
  leafNodes: Scenario[]
}

export const StackedScenarioComparisonContext = createContext<StackedScenarioContainer>({
  currentStackedScenario: undefined,
  leafNodes: [],
})

const StackedScenarioInitialDataProvider = (props: PropsWithChildren<{ stackedScenarioId: string }>) => {
  const [currentStackedScenario, setStackedScenario] = useState<StackedScenario>()
  const [leafNodes, setLeafNodes] = useState<Array<Scenario>>([])
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const reloadStackedScenario = useCallback(async () => {
    setStackedScenario(undefined)
    updateIndividualProgressItem('fetchStackedScenario', ProgressState.LOADING)
    try {
      const stackedScenario = await fetchStackedScenarioById(props.stackedScenarioId)
      setStackedScenario(stackedScenario)
      updateIndividualProgressItem('fetchStackedScenario', ProgressState.FINISHED)
    } catch (error) {
      updateIndividualProgressItem('fetchStackedScenario', ProgressState.ERROR)
    }
  }, [updateIndividualProgressItem, props.stackedScenarioId])

  const reloadLeafNodes = useCallback(async () => {
    setLeafNodes([])
    updateIndividualProgressItem('fetchLeafNodes', ProgressState.LOADING)
    const stackedScenarioOnPromise = fetchLeavesForStackedScenario(props.stackedScenarioId)

    try {
      const items = await stackedScenarioOnPromise
      const filteredItems = items.filter((item) => Boolean(item)) as Scenario[]
      setLeafNodes(filteredItems)
      updateIndividualProgressItem('fetchLeafNodes', ProgressState.FINISHED)
    } catch (error) {
      updateIndividualProgressItem('fetchLeafNodes', ProgressState.ERROR)
    }
  }, [updateIndividualProgressItem, props.stackedScenarioId])

  useEffect(() => {
    reloadLeafNodes()
  }, [reloadLeafNodes])

  useEffect(() => {
    reloadStackedScenario()
  }, [reloadStackedScenario])

  return (
    <StackedScenarioComparisonContext.Provider
      value={{
        currentStackedScenario: currentStackedScenario,
        leafNodes,
      }}
    >
      {props.children}
    </StackedScenarioComparisonContext.Provider>
  )
}

export { StackedScenarioInitialDataProvider }
