import Big from 'big.js'

export const formatAcquisitionCostChange = (value: number | undefined): number | undefined => {
  if (value === undefined) return undefined

  return new Big(value).round(getNumberOfAllowedDecimals(value)).toNumber()
}

export const isWithin3Digits = (numberString: string) => {
  const digitRegex = /\d/g
  const matches = numberString.match(digitRegex)
  return matches ? matches.length <= 3 : true
}

export const getNumberOfAllowedDecimals = (value: number | undefined): number => {
  if (value === undefined) return -1

  const numberOfDigitsOfWholeNumber = parseInt(`${value}`).toString().length

  if (numberOfDigitsOfWholeNumber === 1) {
    return 2
  } else if (numberOfDigitsOfWholeNumber === 2) {
    return 1
  } else {
    return 0
  }
}

export const isValidNumberString = (value: string): boolean => !Number.isNaN(Number(value)) || value === '-'
