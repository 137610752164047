export const WF_TYPE_OF_DATA_COLUMN = 'WF Output Type'

export enum WayfinderDataType {
  RENEWAL_ADJUSTED = 'RENEWAL_ADJUSTED',
  RENEWAL_CLIENT = 'RENEWAL_CLIENT',
  LEGACY_RENEWAL_CLIENT = 'CLIENT_SPECIFIC',
  RENEWAL_UNADJUSTED = 'RENEWAL_UNADJUSTED',
  NEW = 'NEW',
  ACTUALS = 'ACTUALS',
  NEW_CLIENT = 'NEW_CLIENT',
  NEW_CUSTOM_PORTFOLIO = 'NEW_CUSTOM_PORTFOLIO',
}

export function filterByDataType(data: any[], typesToInclude: WayfinderDataType[]) {
  if (!data || !Array.isArray(data)) {
    return []
  }
  return data?.filter((currentDataItem) => typesToInclude.includes(currentDataItem[WF_TYPE_OF_DATA_COLUMN]))
}
