import { RouteComponentProps, withRouter } from 'react-router'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { validate as uuidValidate } from 'uuid'
import { StackedOutwardsDataProvider } from '../../providers/outwards/StackedOutwardsDataProvider'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import SectionHeader from '../../components/section-header/SectionHeader'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { EXTRA_FILTERS_FOR_SUMMARY_PAGE } from '../../backend/calculate-possible-filter-values'
import { FullStackedKpiDisplay } from '../../components/kpis/FullStackedKpiDisplay'
import { SummaryTableContentWithoutItsOwnDataProvider } from '../summary/SummaryTableContent'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'

const EXTRA_OUTWARD_COLUMNS = ['WF Outwards Programme', 'WF Outwards Treaty', 'WF Outwards Layer']

function OutwardScenarioStackedSummary(): JSX.Element {
  useResetFiltersOnFirstRender()

  return (
    <div
      className="OutwardCoverage"
      id="OutwardCoverage"
    >
      <SplitContentContainerWithSideMenu
        leftColumm={
          <FiltersContainer>
            <SectionHeader title="Filter Control" />
            <ScenarioFilterCard
              extraFilterValues={[...EXTRA_OUTWARD_COLUMNS, ...EXTRA_FILTERS_FOR_SUMMARY_PAGE]}
              outward={true}
            />
          </FiltersContainer>
        }
        rightColumn={<OutwardScenarioStackedSummaryContent />}
        sideMenu={
          <KpiBar>
            <FullStackedKpiDisplay />
          </KpiBar>
        }
      />
    </div>
  )
}

function OutwardScenarioStackedSummaryContent() {
  return <SummaryTableContentWithoutItsOwnDataProvider columnToDefaultTo={'WF Outwards Programme'} />
}

const OutwardStackedWithProivders = (props: RouteComponentProps<{}>): JSX.Element => {
  const scenarioIdToUse = props.location.pathname.split('/').filter((item) => uuidValidate(item))[0]

  return (
    <StackedOutwardsDataProvider stackedOutwardsScenarioId={scenarioIdToUse}>
      <RenewalGraphFilterDataProvider
        extraFilterValues={[...EXTRA_OUTWARD_COLUMNS, ...EXTRA_FILTERS_FOR_SUMMARY_PAGE]}
        onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}
      >
        <OutwardScenarioStackedSummary />
      </RenewalGraphFilterDataProvider>
    </StackedOutwardsDataProvider>
  )
}

export default withRouter(OutwardStackedWithProivders)
