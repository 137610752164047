import Big, { BigSource } from 'big.js'

import { A1_MARKET_COLUMN_NAME } from '../backend/calculate-with-actuals'
import { isDate } from './validity'

export type PossiblyNegativeNumber = number | '-' | undefined

export const ZERO_POINT_ZERO_ONE = new Big('0.01')
export const ZERO = new Big('0')
export const ONE = new Big('1')
export const TEN = new Big('10')
export const ONE_HUNDRED = new Big('100')
export const ONE_THOUSAND = new Big('1000')
export const ONE_MILLION = new Big('1000000')
export const ONE_BILLION = new Big('1000000000')
export const ONE_TRILLION = new Big('1000000000000')

export enum NumberType {
  DOLLAR = 'DOLLAR',
  PERCENTAGE = 'PERCENTAGE',
}

function isZero(number?: Big) {
  return numericValueIsDefined(number) && number!.cmp(ZERO) === 0
}

export const asOneBasedPercentage = (number: number | string | Big) => ONE.plus(new Big(number).div(ONE_HUNDRED))
export const asZeroBasedPercentage = (number: number | string | Big) => new Big(number).div(ONE_HUNDRED)

export function sumListOfPossiblyUndefinedOrStringIntegers(list: Array<string | undefined | number>) {
  return list
    .filter((item) => item !== undefined)
    .map((item) => {
      if (typeof item === 'number') {
        return item
      }
      return parseInt(item!)
    })
    .reduce((first, second) => first + second, 0)
}

export const displayDecimalAsPercentage = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    if (numericValueIsDefined(value)) {
      return value * 100 + '%'
    }
  }
  if (!value) {
    return ''
  }

  return value
}

export const displayLargeDollarValue = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    if (numericValueIsDefined(value)) {
      if (Number.isInteger(value)) {
        return '$' + value.toLocaleString()
      } else {
        return '$' + value
      }
    }
  }
  if (!value) {
    return ''
  }

  return value
}

function removeTrailingZeros(number: string) {
  return number.replace(/\.0+$/, '')
}

function formatForAnimationForPercentage(inputValue: string | Big | number): number {
  const number = typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

  let numberToDisplay = number

  if (number.cmp(ZERO) === 0) {
    numberToDisplay = ZERO
  }

  return numberToDisplay.toNumber()
}

function formatForAnimation(inputValue: undefined | string | Big | number): number {
  if (!inputValue) {
    return 0
  }
  const number = typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

  let numberToDisplay = number

  if (number.cmp(ZERO) === 0) {
    numberToDisplay = ZERO
  }

  if (number.cmp(ONE_TRILLION) >= 0) {
    numberToDisplay = number.div(ONE_TRILLION)
  } else if (number.cmp(ONE_BILLION) >= 0) {
    numberToDisplay = number.div(ONE_BILLION)
  } else if (number.cmp(ONE_MILLION) >= 0) {
    numberToDisplay = number.div(ONE_MILLION)
  } else if (number.cmp(ONE_THOUSAND) >= 0) {
    numberToDisplay = number.div(ONE_THOUSAND)
  }

  return numberToDisplay.toNumber()
}

function formatForDisplay(inputValue: null | undefined | Date | string | Big | number): string {
  if (inputValue === null || inputValue === undefined || isDate(inputValue)) {
    return '-'
  }
  if (typeof inputValue === 'number' && isNaN(inputValue)) {
    return '0'
  }

  try {
    const number: Big =
      typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

    let numberToDisplay = number
    let suffix = ''

    if (number.cmp(ZERO) === 0) {
      numberToDisplay = ZERO
    }

    if (number.abs().cmp(ONE_TRILLION) >= 0) {
      numberToDisplay = number.div(ONE_TRILLION)
      suffix = 't'
    } else if (number.abs().cmp(ONE_BILLION) >= 0) {
      numberToDisplay = number.div(ONE_BILLION)
      suffix = 'b'
    } else if (number.abs().cmp(ONE_MILLION) >= 0) {
      numberToDisplay = number.div(ONE_MILLION)
      suffix = 'm'
    } else if (number.abs().cmp(ONE_THOUSAND) >= 0) {
      numberToDisplay = number.div(ONE_THOUSAND)
      suffix = 'k'
    }

    return removeTrailingZeros(numberToDisplay.toPrecision(3)) + suffix
  } catch (e) {
    return '-'
  }
}

function suffixForValue(inputValue: string | Big | number): string {
  try {
    const number = typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

    let suffix = ''

    if (number.cmp(ZERO) === 0) {
      suffix = ''
    }

    if (number.cmp(ONE_BILLION) >= 0) {
      suffix = 'b'
    } else if (number.cmp(ONE_MILLION) >= 0) {
      suffix = 'm'
    } else if (number.cmp(ONE_THOUSAND) >= 0) {
      suffix = 'k'
    }

    return suffix
  } catch (e) {
    return ''
  }
}

function formatForProgressBar(total: string | Big | number, currentValue: string | Big | number): number {
  const max = typeof total === 'number' || typeof total === 'string' ? new Big(total) : total
  const min =
    typeof currentValue === 'number' || typeof currentValue === 'string' ? new Big(currentValue) : currentValue

  if (max.cmp(ZERO) === 0) {
    return 0
  }

  const value = ONE_HUNDRED.minus(new Big(min.div(max).times(100))).toNumber()

  return new Big(value.toPrecision(4)).toNumber()
}

function numericValueIsDefined(value: any) {
  return value !== undefined && value !== null && !isNaN(value) && value !== '-' && value !== ''
}

function numericValueIsZero(value: any) {
  return value === 0 || value === '0' || value === '0.0'
}

function isNumber(value: any): value is number {
  return value && typeof value === 'number' && !isNaN(value)
}

function isNumberOrZero(value: any): number {
  if (isNumber(value)) {
    return value
  }

  return 0
}

function formatPercentageAsIntegerForDisplay(inputValue: number | Big | string): string {
  const percentageAsInteger =
    typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

  return removeTrailingZeros(percentageAsInteger.toPrecision(3)) + '%'
}

function formatPercentageToFixedDecimalPoint(inputValue: number | Big | string, dp: number): string {
  const percentageAsInteger =
    typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

  return removeTrailingZeros(percentageAsInteger.toFixed(dp)) + '%'
}

function formatPercentageAsIntegerForProgressBar(inputValue: number | Big | string): string {
  const percentageAsInteger =
    typeof inputValue === 'number' || typeof inputValue === 'string' ? new Big(inputValue) : inputValue

  return removeTrailingZeros(percentageAsInteger.toPrecision(3))
}

export interface IndividualDataItem {
  [someKey: string]: any
}

function countByColumn(data: IndividualDataItem[], keyToCountBy: string) {
  return data.reduce((dataSoFar, nextItem) => {
    const valueToCountBy = nextItem[keyToCountBy]

    const existingValue = dataSoFar[valueToCountBy] || ZERO

    return { ...dataSoFar, [valueToCountBy]: existingValue.plus(ONE) }
  }, {})
}

function groupThenSumByColumn(
  data: IndividualDataItem[],
  keyToGroupBy: string,
  keyToSum: string,
  fallbackKeyToSum?: string,
): { [key: string]: Big } {
  return data.reduce((obj, item) => {
    const key = item[keyToGroupBy]

    if (!obj.hasOwnProperty(key)) {
      obj[key] = ZERO
    }

    let dataToAdd = item[keyToSum]
    if (!numericValueIsDefined(dataToAdd) && fallbackKeyToSum) {
      dataToAdd = item[fallbackKeyToSum]
    }
    if (!numericValueIsDefined(dataToAdd)) {
      dataToAdd = 0
    }

    obj[key] = obj[key].plus(dataToAdd)

    return obj
  }, {})
}

export function sumListAsIntegersForManyFields(
  data: IndividualDataItem[],
  eachFieldToSum: Array<{
    fieldName: string
    fallbackFieldNames?: string[]
    resultName: string
    filterLogic: (dataItem: IndividualDataItem) => boolean
    startingValue?: number
  }>,
): Record<string, Big | undefined> {
  let resultTotals: Record<string, Big | undefined> = eachFieldToSum.reduce(
    (current, nextField) => ({ ...current, [nextField.resultName]: ZERO }),
    {},
  )

  data.forEach((currentDataItem) => {
    eachFieldToSum.forEach((currentFieldToOperateOn) => {
      if (currentFieldToOperateOn.filterLogic(currentDataItem)) {
        const currentResultValue = resultTotals[currentFieldToOperateOn.resultName] || ZERO

        for (const fieldName of [
          currentFieldToOperateOn.fieldName,
          ...(currentFieldToOperateOn.fallbackFieldNames || []),
        ]) {
          const dataValue = currentDataItem[fieldName]
          if (numericValueIsDefined(dataValue)) {
            try {
              resultTotals[currentFieldToOperateOn.resultName] = currentResultValue.plus(dataValue)
            } catch (e) {
              console.log(`Error when trying to add ${dataValue} to ${currentFieldToOperateOn.resultName}`)
            }
            break
          }
        }
      }
    })
  })

  return resultTotals
}

function sumListAsIntegers(data: IndividualDataItem[], someKeyToExtract: string, startingValue?: number): Big {
  return data
    .map((dataItem) => dataItem[someKeyToExtract])
    .filter((value) => numericValueIsDefined(value))
    .reduce((first, second) => first.plus(second), startingValue ? new Big(startingValue) : new Big(0))
}

function calculateKeyToSumForWeighted(
  dataItem: IndividualDataItem,
  keyToSum: string,
  fallbackKeyToSum: string | undefined,
  fallbackValue?: Record<string, string | number | Big | undefined>,
): Big | undefined {
  if (!(keyToSum in dataItem)) {
    return fallback(dataItem, fallbackKeyToSum, fallbackValue)
  }
  const res = dataItem[keyToSum]
  if (!numericValueIsDefined(res) || numericValueIsZero(res)) {
    return fallback(dataItem, fallbackKeyToSum, fallbackValue)
  }
  return new Big(res)
}

function fallback(
  dataItem: IndividualDataItem,
  fallbackKeyToSum: string | undefined,
  fallbackValue?: Record<string, string | number | Big | undefined>,
): Big | undefined {
  if (
    fallbackKeyToSum &&
    fallbackKeyToSum in dataItem &&
    numericValueIsDefined(dataItem[fallbackKeyToSum]) &&
    !numericValueIsZero(dataItem[fallbackKeyToSum])
  ) {
    return new Big(dataItem[fallbackKeyToSum])
  }
  if (fallbackValue === undefined) {
    return
  }
  if (!(dataItem[A1_MARKET_COLUMN_NAME] in fallbackValue)) {
    return
  }
  const fallbackData = fallbackValue[dataItem[A1_MARKET_COLUMN_NAME]]
  if (fallbackData !== undefined) {
    return new Big(fallbackData)
  }
}

export function sumListAsIntegersWeightedByKey(
  data: IndividualDataItem[],
  keyToSum: string,
  fallbackKeyToSum: string | undefined,
  keysToWeightBy: string[],
  startingValue?: number,
  fallbackValue?: Record<string, string | number | Big | undefined>,
): Big {
  const allDataWithValidKey = data
    .map((dataItem) => {
      try {
        const keyThatShouldBeWeightedBy = keysToWeightBy.find(
          (currentKeyToWeightBy) =>
            numericValueIsDefined(dataItem[currentKeyToWeightBy]) && dataItem[currentKeyToWeightBy] !== '0',
        )
        return {
          keyToSum: calculateKeyToSumForWeighted(dataItem, keyToSum, fallbackKeyToSum, fallbackValue),
          keyToWeightBy: keyThatShouldBeWeightedBy ? dataItem[keyThatShouldBeWeightedBy] : ZERO,
        }
      } catch (e) {
        return { keyToSum: undefined, keyToWeightBy: undefined }
      }
    })
    .filter((value) => numericValueIsDefined(value.keyToSum))

  const totalSumValueOfWeightedKey = allDataWithValidKey.reduce((acc, curr) => acc.plus(curr.keyToWeightBy), ZERO)

  if (isZero(totalSumValueOfWeightedKey)) {
    return ZERO
  }

  return allDataWithValidKey.reduce(
    (acc, curr) => acc.plus(curr.keyToSum!.mul(curr.keyToWeightBy).div(totalSumValueOfWeightedKey)),
    startingValue ? new Big(startingValue) : ZERO,
  )
}

function sumListAsIntegersWithFallbackKeys(
  data: IndividualDataItem[],
  someKeyToExtract: string,
  fallbackKeys: string[],
  startingValue?: number,
): Big {
  return data
    .map((dataItem) => {
      for (const keyToExtract of [someKeyToExtract, ...fallbackKeys]) {
        const result = dataItem[keyToExtract]
        if (numericValueIsDefined(result)) return result
      }

      return undefined
    })
    .filter((value) => numericValueIsDefined(value))
    .reduce((first, second) => first.plus(second), startingValue ? new Big(startingValue) : new Big(0))
}

export function handlePercentageAndOutputString(input: undefined | null | BigSource): string {
  if (input === undefined || input === null) {
    return '0'
  }

  if (typeof input === 'number') {
    if (isNaN(input)) {
      return '0'
    }

    return input.toFixed(2)
  }

  if (typeof input === 'object') {
    return input.toFixed(2)
  }

  return input
}

export function validNumber(n: PossiblyNegativeNumber): boolean {
  const value = Number(n)
  return !isNaN(value)
}

export {
  formatForAnimation,
  formatForAnimationForPercentage,
  formatForDisplay,
  suffixForValue,
  formatForProgressBar,
  formatPercentageAsIntegerForDisplay,
  formatPercentageToFixedDecimalPoint,
  formatPercentageAsIntegerForProgressBar,
  sumListAsIntegers,
  sumListAsIntegersWithFallbackKeys,
  numericValueIsDefined,
  isNumber,
  isNumberOrZero,
  isZero,
  groupThenSumByColumn,
  countByColumn,
}
