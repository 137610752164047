import { numericValueIsDefined, ONE_BILLION, PossiblyNegativeNumber } from '../../utils/numbers'

export const calculateNewGwpInputWarning = (value: PossiblyNegativeNumber): string | undefined => {
  if (numericValueIsDefined(value) && (value as number) < 100000) {
    return 'Should this be under $100k?'
  }
}

export const calculateNewGwpInputError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if (!Number.isInteger(value)) {
    return 'This must be a whole number'
  }

  if ((value as number) <= 0) {
    return 'This must be greater than 0'
  }

  if ((value as number) > Number(ONE_BILLION.times(2))) {
    return 'This must be less than or equal to 2,000,000,000'
  }

  return undefined
}

export const calculateNewGrarcInputError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  return undefined
}
export const UNSAFE_calculateNewGwpError = (value: PossiblyNegativeNumber): string | undefined => {
  if (!numericValueIsDefined(value)) {
    return 'This must have a value'
  }

  if ((value as number) <= 0) {
    return 'This must be greater than 0'
  }

  return undefined
}
