import { ReactElement } from 'react'
import { PageType } from './ExposuresState'

type ExposuresSwitchProps = {
  stateMap: {
    [key in PageType]: ReactElement
  }
  pageType: PageType
}

export const ExposuresSwitch = (props: ExposuresSwitchProps): ReactElement => {
  const { stateMap, pageType } = props

  return stateMap[pageType]
}
