import React from 'react'
import { Column, Row } from 'react-table'

import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import MultiSelectDropdownSmall from '../multi-select-dropdown-small/MultiSelectDropdownSmall'
import { ErrorText } from '../text-area/ErrorText'
import { formatForDisplay } from '../../utils/numbers'
import EditableCell, { EditableCellPercentage } from './EditableCell'
import { toOption } from '../../utils/lists'

export const CYCLE_POSITION = ['Very High', 'High', 'Average', 'Low', 'Very Low']
export const CYCLE_MOMENTUM = ['Fast Increasing', 'Steady Increasing', 'Flat', 'Steady Decreasing', 'Fast Decreasing']
export const RATE_ENVIRONMENT_OPTIONS = ['Increasing', 'Neutral', 'Decreasing']

export interface DropdownCellProps {
  value: string
  row: Row
  column: Column
  options: string[]
  updateMyData: (index: number, id: string | undefined, value: number | undefined | string) => void
  setIsUpToDate: (isUpToDate: boolean) => void
  shouldValidate: boolean
}

function RateEnvironmentDropdown({
  value,
  row: { index },
  column: { id },
  updateMyData,
  setIsUpToDate,
  shouldValidate,
}: DropdownCellProps) {
  const onSelect = (option: string) => {
    if (updateMyData) {
      updateMyData(index, id, option)
      setIsUpToDate(false)
    }
  }
  return (
    <>
      <MultiSelectDropdownSmall
        onSelect={onSelectSingleValueFromMulti(onSelect)}
        options={RATE_ENVIRONMENT_OPTIONS.map(toOption)}
        selected={[toOption(value)]}
      />
      {shouldValidate && !value && <ErrorText error={'This must have a value'} />}
    </>
  )
}

export const headerGroup = (isMarketEditor: boolean | undefined) => {
  const headers = [
    {
      Header: 'Segment',
      accessor: 'segmentName',
    },
    {
      Header: '% of Market',
      accessor: 'marketPercentage',
      Cell: isMarketEditor ? EditableCellPercentage : ({ value }: any) => (value ? value + '%' : '0%'),
    },
    {
      Header: 'Market GWP',
      accessor: 'marketGwp',
      Cell: isMarketEditor ? EditableCell : ({ value }: any) => (value ? '$' + formatForDisplay(value) : '$0'),
    },
    {
      Header: 'Inflation',
      accessor: 'inflation',
      Cell: isMarketEditor ? EditableCellPercentage : ({ value }: any) => (value ? value + '%' : '0%'),
    },
    {
      Header: 'Rate Environment',
      accessor: 'rateEnvironment',
      Cell: isMarketEditor ? RateEnvironmentDropdown : ({ value }: any) => (value ? value : 'Not set'),
    },
  ]
  if (!isMarketEditor) {
    headers.splice(
      3,
      0,
      {
        Header: 'Convex GWP',
        accessor: 'convexGwp',
        Cell: ({ value }: any) => '$' + formatForDisplay(value),
      },
      {
        Header: 'Market Share',
        accessor: 'marketShare',
        Cell: ({ value }: any) => (value ? value.toFixed(2) + '%' : '0%'),
      },
    )
  }
  return headers
}

export interface PerSegmentDataItem {
  id?: string
  marketId: string
  segmentName: string
  marketPercentage: number
  marketGwp: number
  convexGwp: number
  marketShare: number
  inflation: number
  rateEnvironment: string
}

export function convertGroupedDataToFullTableDataWithDefaultsFormat(
  groupedData: Record<string, any[]>,
): Array<PerSegmentDataItem> {
  return Object.entries(groupedData).map(([fieldValueThatWasGroupedBy, listOfData]) => {
    return {
      marketId: '',
      segmentName: '',
      marketPercentage: 0,
      marketGwp: 0,
      convexGwp: 0,
      marketShare: 0,
      inflation: 0,
      rateEnvironment: '',
    }
  })
}
