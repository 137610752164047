import React from 'react'
import { Card } from '../../components/card/Card'
import CancelIcon from '../../components/icons/CancelIcon'
import './StopAddIcon.scss'

interface StopAddIconProps {
  onClick: () => void
}

function StopAddIcon(props: StopAddIconProps) {
  return (
    <div
      className="StopAddIcon"
      key={'stop-add'}
      onClick={props.onClick}
    >
      <Card>
        <CancelIcon />
      </Card>
    </div>
  )
}

export { StopAddIcon }
