import { ReactElement, useContext, useEffect, useState } from 'react'

import { ExposuresDataContext, ExposuresDataProvider } from '../../providers/ExposuresDataProvider'
import { MultiSelectDropdown } from '../../components/multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../scenario-chooser/ScenarioChooser'
import Graphs from '../../components/graphs/Graphs'
import { GraphContainer } from '../../components/graph-container/GraphContainer'
import { GraphHeader } from '../../components/graph-header/GraphHeader'
import DropdownContainer from '../../components/dropdown-container/DropdownContainer'
import { MarketShareBarGraph } from '../../components/exposure-graphs/MarketShareBarGraph'
import { AverageAnnualLossesBarGraph } from '../../components/exposure-graphs/AverageAnnualLossesBarGraph'
import Button from '../../components/button/Button'
import Spacer from '../../components/spacer/Spacer'
import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import { ExposuresContextPath, fetchFullAALData, fetchFullMarketShareData } from '../../backend/exposures'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { toOption } from '../../utils/lists'
import { GraphStyleProvider } from 'src/components/renewal-graph/GraphStyleProvider'

export const DEFAULT_LOADING_STATE = 'Loading...'

function isDisabled(bandNames: string[]): boolean {
  return bandNames.includes(DEFAULT_LOADING_STATE)
}

const DEFAULT_PERIL = 'NAWS'

export function ExposuresOldContent(): ReactElement {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const exposuresDataContext = useContext(ExposuresDataContext)
  const { isAdmin } = useContext(IsAdminContext)
  const allPerils = Object.values(exposuresDataContext?.marketShareValuesGroupedByZoneName?.results || {}).map(
    (item) => item.peril,
  )
  const perils = Array.from(new Set(allPerils))

  const [currentlySelectedBand, setCurrentlySelectedBand] = useState<string>('2.5b')
  const [currentPerilToFilterBy, setCurrentSelectedFilterPeril] = useState<string>(
    perils?.includes(DEFAULT_PERIL) ? DEFAULT_PERIL : '',
  )

  const bands = exposuresDataContext.availableExposureFilters?.bandNames ?? [DEFAULT_LOADING_STATE]

  const reloadMarketShareData = () => {
    exposuresDataContext.reloadMarketShareData(currentlySelectedBand)
  }

  const reloadMarketShareDataGroupedByZoneName = () => {
    exposuresDataContext.reloadMarketShareDataGroupedByZoneName(currentlySelectedBand)
  }

  const updatePerilToDefaultWhenListChanges = () => {
    setCurrentSelectedFilterPeril(perils?.includes(DEFAULT_PERIL) ? DEFAULT_PERIL : '')
  }

  //eslint-disable-next-line
  useEffect(updatePerilToDefaultWhenListChanges, [JSON.stringify(perils)])

  const bandNameOptions = bands.map(toOption)

  const returnPeriodOptions = [
    { value: 'averageAnnualLoss', label: 'Average Annual Loss' },
    { value: 'oneIn200', label: '1 in 200' },
  ]

  // eslint-disable-next-line
  useEffect(reloadMarketShareData, [currentlySelectedBand])
  // eslint-disable-next-line
  useEffect(reloadMarketShareDataGroupedByZoneName, [currentlySelectedBand])

  const saveAalData = async () => {
    if (!currentScenario && !currentStackedScenario) {
      window.alert("Couldn't find a scenarioId to fetch the data from")
      return
    }

    let name = ''
    let id = ''
    let context = ExposuresContextPath.MARKET

    if (currentScenario) {
      name = `${currentScenario.division} - ${currentScenario.team} - ${currentScenario.market} - ${currentScenario.name} - aal-data`
      id = currentScenario.id
    }

    if (currentStackedScenario) {
      name = `${currentStackedScenario.division} - ${currentStackedScenario.team} - ${currentStackedScenario.name} - aal-data`
      id = currentStackedScenario.id
      context = ExposuresContextPath.STACKED
    }

    const result = await fetchFullAALData(id, context)

    if (result?.length) {
      const csvFile = createCsvFile(result)

      saveDataToFile(name, csvFile)
    } else {
      window.alert('Sorry, something went wrong while fetching the data')
    }
  }

  const saveMarketShareData = async () => {
    if (!currentScenario && !currentStackedScenario) {
      window.alert("Couldn't find a scenarioId to fetch the data from")
      return
    }

    let name = ''
    let id = ''
    let context = ExposuresContextPath.MARKET

    if (currentScenario) {
      name = `${currentScenario.division} - ${currentScenario.team} - ${currentScenario.market} - ${currentScenario.name} - market-share-data`
      id = currentScenario.id
    }

    if (currentStackedScenario) {
      name = `${currentStackedScenario.division} - ${currentStackedScenario.team} - ${currentStackedScenario.name} - market-share-data`
      id = currentStackedScenario.id
      context = ExposuresContextPath.STACKED
    }

    const result = await fetchFullMarketShareData(id, context)

    if (result?.length) {
      const csvFile = createCsvFile(result)

      saveDataToFile(name, csvFile)
    } else {
      window.alert('Sorry, something went wrong while fetching the data')
    }
  }

  return (
    <GraphStyleProvider>
      <ExposuresDataProvider>
        <div className="ExposuresContent">
          {isAdmin && (
            <PageHeaderContainer>
              <Spacer />
              <div className="SaveContainer">
                <div className="ButtonsContainer">
                  <Button
                    title="Get Full AAL Data CSV"
                    onClick={saveAalData}
                  />
                  <div className="CsvButton">
                    <Button
                      title="Get Full Market Share Data CSV"
                      onClick={saveMarketShareData}
                    />
                  </div>
                </div>
              </div>
            </PageHeaderContainer>
          )}
          <Graphs>
            <GraphContainer
              className="GraphLeft"
              graphId="ExposuresMarketShareBarGraph"
            >
              <DropdownContainer>
                <MultiSelectDropdown
                  title={'Zonal Losses Banding'}
                  className="ExposureBandSelection SelectionBox"
                  onSelect={onSelectSingleValueFromMulti(setCurrentlySelectedBand)}
                  selected={currentlySelectedBand ? [toOption(currentlySelectedBand)] : []}
                  isSearchable={false}
                  isDisabled={isDisabled(bands)}
                  placeholder="None Selected"
                  options={bandNameOptions}
                  boxed={false}
                />
              </DropdownContainer>
              <GraphHeader title={'Market Share Losses - Bar Graph'} />
              <MarketShareBarGraph hideNonMainZones />
            </GraphContainer>

            <GraphContainer
              className="GraphRight"
              graphId="ExposuresAalBarGraph"
            >
              <GraphHeader title={'Average Annual Losses - Bar Graph'} />
              {!isDisabled(bands) && (
                <AverageAnnualLossesBarGraph currentlySelectedReturnPeriod={returnPeriodOptions[0].value} />
              )}
            </GraphContainer>

            <GraphContainer
              className="GraphLeft"
              graphId="ExposuresMarketSharePerilGraph"
            >
              <MultiSelectDropdown
                title={'Peril'}
                className="MarketSharePerilSelection SelectionBox"
                onSelect={onSelectSingleValueFromMulti(setCurrentSelectedFilterPeril)}
                selected={currentPerilToFilterBy ? [toOption(currentPerilToFilterBy)] : []}
                isSearchable={false}
                isDisabled={!Boolean(perils?.length)}
                placeholder="None Selected"
                options={[{ label: 'None Selected', value: '' }, ...perils?.map(toOption)]}
                boxed={false}
              />
              <GraphHeader title={'Market Share Losses - By Peril'} />

              <MarketShareBarGraph filterByPeril={currentPerilToFilterBy} />
            </GraphContainer>

            <GraphContainer
              className="GraphRight"
              graphId="ExposuresOneIn200Graph"
            >
              <GraphHeader title={'One in 200 - Bar Graph'} />
              {isDisabled(bands) && (
                <AverageAnnualLossesBarGraph currentlySelectedReturnPeriod={returnPeriodOptions[1].value} />
              )}
            </GraphContainer>
          </Graphs>
        </div>
      </ExposuresDataProvider>
    </GraphStyleProvider>
  )
}
