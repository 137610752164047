import { isNumber, numericValueIsDefined } from './numbers'

export const ignoreNanForNumbers = (value: any) => {
  if (isNumber(value)) {
    if (!numericValueIsDefined(value)) {
      return ''
    } else {
      return value
    }
  }
  return value
}
