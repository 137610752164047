import React, { useContext, useEffect, useState } from 'react'
import OutwardRuleCard, { RULE_PREVIEW_ID } from '../outward-rule-card/OutwardRuleCard'
import './OutwardRulesContainer.scss'
import { OutwardScenarioRuleContext } from '../../providers/outwards/OutwardScenarioRulesProvider'
import { CreateOutwardRuleCard } from '../create-outward-rule-card/CreateOutwardRuleCard'
import { isIrrelevantRule, isRelevantRule, Rule, sortRulesByOldestFirst } from '../../backend/outward-rules'
import { FilterContext } from '../../providers/FilterProvider'
import { OutwardsApplicablePerilsContext } from '../../providers/outwards/OutwardsApplicablePerilsProvider'
import { OutwardsExcludedPerilsContext } from '../../providers/outwards/OutwardsExcludedPerilsProvider'
import PerilCard from '../outward-peril-card/PerilCard'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import EditPerilCard from '../edit-peril-card/EditPerilCard'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'

export interface OutwardRulesContainerProps {}

export function OutwardRulesContainer(props: OutwardRulesContainerProps) {
  const { rules, triggerReloadSavedRules } = useContext(OutwardScenarioRuleContext)
  const { allApplicablePerils } = useContext(OutwardsApplicablePerilsContext)
  const { peril, reloadExcludedPerils } = useContext(OutwardsExcludedPerilsContext)
  const { onChangeFilters } = useContext(FilterContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  const [currentlyEditingRuleId, setRuleIdIsBeingEdited] = useState<string>('')
  const [currentlyEditingPeril, setPerilIsBeingEdited] = useState<string | undefined>(undefined)
  const [currentlyPreviewingPeril, setPerilIsBeingPreviewed] = useState<string | undefined>(undefined)
  const [rulesSortedForDisplay, setRulesSortedForDisplay] = useState<Rule[]>([])

  const canBeEdited = currentOutwardScenario?.status !== ScenarioStatus.COMPLETE
  const { excludedPerilNames, description } = peril
  const currentDescription: string = description ?? ''

  useEffect(() => {
    setRulesSortedForDisplay(rules ? sortRulesByOldestFirst(rules.filter(isIrrelevantRule(RULE_PREVIEW_ID))) : [])
  }, [rules])

  const ensureCurrentlyEditingRuleExists = () => {
    if (
      rulesSortedForDisplay !== undefined &&
      currentlyEditingRuleId &&
      currentlyEditingRuleId !== RULE_PREVIEW_ID &&
      (!setRulesSortedForDisplay.length || !rulesSortedForDisplay.find(isRelevantRule(currentlyEditingRuleId)))
    ) {
      setRuleIdIsBeingEdited('')
    } else if (
      currentOutwardScenario?.canWrite &&
      rulesSortedForDisplay !== undefined &&
      !rulesSortedForDisplay.length &&
      !currentlyEditingRuleId
    ) {
      setRuleIdIsBeingEdited(RULE_PREVIEW_ID)
    }
  }
  // eslint-disable-next-line
  useEffect(ensureCurrentlyEditingRuleExists, [rules])

  const reloadSavedRulesAndResetRulesPage = () => {
    triggerReloadSavedRules()
    setRuleIdIsBeingEdited('')
    onChangeFilters({})
  }

  const triggerEditPerilCard = (outwardScenarioId: string) => {
    setPerilIsBeingEdited(outwardScenarioId)
  }

  const triggerPreviewPerilCard = (outwardScenarioId: string) => {
    setPerilIsBeingPreviewed(outwardScenarioId)
  }

  return (
    <div className="OutwardRulesContainer">
      {currentlyEditingPeril === currentOutwardScenario!.id ||
      currentlyPreviewingPeril === currentOutwardScenario?.id ? (
        <EditPerilCard
          key={`peril-outward-scenario=${currentOutwardScenario!.id}`}
          applicablePerils={allApplicablePerils}
          excludedPerils={excludedPerilNames}
          description={currentDescription}
          triggerSaveChanges={() => {
            setPerilIsBeingEdited(undefined)
            setPerilIsBeingPreviewed(undefined)
            reloadExcludedPerils()
          }}
          triggerExit={() => {
            setPerilIsBeingEdited(undefined)
            setPerilIsBeingPreviewed(undefined)
          }}
          stopEdit={Boolean(currentlyPreviewingPeril)}
          outwardScenarioId={currentOutwardScenario!.id}
        />
      ) : (
        <PerilCard
          key={`peril-outward-scenario=${currentOutwardScenario!.id}`}
          outwardScenario={currentOutwardScenario!}
          applicablePerils={allApplicablePerils}
          excludedPerils={excludedPerilNames}
          triggerEditPeril={triggerEditPerilCard}
          triggerPreviewPeril={triggerPreviewPerilCard}
          description={currentDescription}
        />
      )}
      <div className="SmallDivider" />
      {rulesSortedForDisplay.map((currentRule, index) =>
        currentlyEditingRuleId === currentRule.id ? (
          <CreateOutwardRuleCard
            ruleBeingEditedId={currentlyEditingRuleId}
            reloadSavedRulesAndResetRulesPage={reloadSavedRulesAndResetRulesPage}
          />
        ) : (
          <OutwardRuleCard
            index={index}
            key={'view-' + currentRule.id}
            rule={currentRule}
            fullListOfRules={rulesSortedForDisplay}
            onTriggerEditRule={canBeEdited ? setRuleIdIsBeingEdited : undefined}
            hideCreateAndEdit={!canBeEdited}
          />
        ),
      )}
    </div>
  )
}

export default OutwardRulesContainer
