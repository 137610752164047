import React, { ReactElement } from 'react'
import { components, OptionProps } from 'react-select'

interface Props<T, IsMulti extends boolean> extends OptionProps<T, IsMulti> {
  onModifierKeyWasBeingPressed: (callback: () => void) => void
}

export function OptionWithModifierSmall<T, IsMulti extends boolean>(props: Props<T, IsMulti>): ReactElement {
  const { children, onModifierKeyWasBeingPressed, ...rest } = props
  // Create a copy of the props.innerProps.onClick function:
  const { onClick: originalOnClick } = rest.innerProps
  // Replace the props.innerProps.onClick function with a new function (it isn't very reacty, but it works):
  rest.innerProps.onClick = (mouseEvent) => {
    if (!originalOnClick) {
      return console.log('No onClick function')
    }
    if (mouseEvent.metaKey || mouseEvent.ctrlKey) {
      mouseEvent.stopPropagation()
      // These are necessary as the downstream react-select seems to do stuff based on this, but we want to override that behaviour
      mouseEvent.ctrlKey = false
      mouseEvent.metaKey = false
      onModifierKeyWasBeingPressed(() => originalOnClick(mouseEvent))
    } else {
      originalOnClick(mouseEvent)
    }
  }

  return <components.Option {...rest}>{children}</components.Option>
}
