import React, { useContext, useEffect, useMemo, useState } from 'react'

import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { StackedScenarioListContext } from './StackedScenarioListProvider'
import { fetchTagForEntity } from '../../backend/tags'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { OptionsType } from '../../utils/lists'

export interface StackedScenarioSelectProps {
  title?: string
  tagsToInclude?: string[]
  onStackedScenarioSelected: (newScenario: StackedScenario | null | undefined) => void
  currentSelectedStackedScenarioId: string | undefined | null
  boxed?: boolean
}

function findMatchingScenario(
  currentStackedScenarios: StackedScenario[],
  idToMatch: string | null | undefined,
): StackedScenario | null | undefined {
  return currentStackedScenarios.find((item) => item.id === idToMatch)
}

export function StackedScenarioSelect(props: StackedScenarioSelectProps) {
  const { currentStackedScenarios } = useContext(StackedScenarioListContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const [scenarioIdToListOfTags, setScenarioIdToListOfTags] = useState<Record<string, string[]>>({})

  const selectedStackedScenario = useMemo(
    () => findMatchingScenario(currentStackedScenarios, props.currentSelectedStackedScenarioId),
    [currentStackedScenarios, props.currentSelectedStackedScenarioId],
  )

  const fetchTags = async () => {
    const result: Record<string, string[]> = {}
    updateIndividualProgressItem('updatingTagsForStackedScenarioSelection', ProgressState.LOADING)
    try {
      for (const currentStackedScenario of currentStackedScenarios) {
        const allTags = await fetchTagForEntity(currentStackedScenario.id)
        result[currentStackedScenario.id] = allTags.map((tagResp) => tagResp.name)
      }
    } catch (e) {
      updateIndividualProgressItem('updatingTagsForStackedScenarioSelection', ProgressState.ERROR)
    }
    updateIndividualProgressItem('updatingTagsForStackedScenarioSelection', ProgressState.FINISHED)
    setScenarioIdToListOfTags(result)
  }

  useEffect(
    () => {
      fetchTags()
    },
    //eslint-disable-next-line
    [currentStackedScenarios],
  )

  const scenarioIsTagged = (scenario: StackedScenario) => {
    if (!props.tagsToInclude?.length || Object.entries(scenarioIdToListOfTags).length === 0) {
      return true
    }

    if (scenario.name === 'Current Estimate') {
      return true
    }

    const tagsForScenario = scenarioIdToListOfTags?.[scenario.id] ?? []

    return props.tagsToInclude?.find((tagToInclude) => tagsForScenario.includes(tagToInclude))
  }

  const options: OptionsType[] = currentStackedScenarios
    .filter((scenario) => scenarioIsTagged(scenario))
    .map((scenario) => ({ label: scenario.name, value: scenario.id }))

  return (
    <MultiSelectDropdown
      title={props.title || 'Choose Scenario'}
      placeholder="Choose..."
      className="Data"
      boxed={props.boxed}
      onSelect={onSelectSingleValueFromMulti((newScenarioId: string) =>
        props.onStackedScenarioSelected(findMatchingScenario(currentStackedScenarios, newScenarioId)),
      )}
      selected={
        selectedStackedScenario ? [{ label: selectedStackedScenario.name, value: selectedStackedScenario.id }] : []
      }
      options={options}
    />
  )
}
