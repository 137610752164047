import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export type AttritionalDependency = 'High' | 'Medium' | 'Low'

export type AttritionalDependencyShorthand = 'H' | 'M' | 'L'

export function findMarketInputDependencyLevel(attritionalDependency: string): AttritionalDependencyShorthand {
  if (attritionalDependency === 'High') {
    return 'H'
  }
  if (attritionalDependency === 'Medium') {
    return 'M'
  }
  if (attritionalDependency === 'Low') {
    return 'L'
  }
  if (attritionalDependency === 'H') {
    return 'H'
  }
  if (attritionalDependency === 'M') {
    return 'M'
  }
  if (attritionalDependency === 'L') {
    return 'L'
  }
  throw new Error(`Unhandled attritional dependency: ${attritionalDependency}`)
}
export interface MarketInputByMarketInput {
  market: string
  type: keyof ParameterInputs
  value: number
}

export interface MarketInputDependency {
  value: AttritionalDependencyShorthand
}

export type ExportToDB = 'TRUE' | 'FALSE'

export interface CreateTcsRequestBody {
  inputs: ParameterisationInputs
  callback_url?: string | undefined | null
}

export interface ParameterisationInputs {
  exportToDB: ExportToDB
  marketInputByMarketInput: MarketInputByMarketInput[]
  marketInputDependency: MarketInputDependency[]
  marketNameSelected: string
}

export interface DefaultParameterData {
  market: string
  convexMax: number
  convexTotalLossRatio: string
  convexAttritionalLossRatio: string
  convexSingleRiskLossRatio: string
  convexClashLossRatio: string
  convexThreshold: number
  singleRiskFrequencyMean: string
  attritionalDependencySelection: string
}

export interface ParameterisationResponse {
  id: string
  scenarioId: string
  modelStatus: ModelStatus
}

export type ResultbyDistributionbyLossType = {
  lossType: string
  distribution: string // column
  stat: string
  display: number
  value: number
  type: string
  name: string
}
export interface ParameterisationByIdResponse extends ParameterisationResponse {
  result: TcsOutput
  input: CreateTcsRequestBody
  createdAt: string
  updatedAt: string
}

export type ModelStatus =
  | 'SERVER_ERROR'
  | 'FIRST_TIME_LOAD'
  | 'NEVER_RAN_PARAMETERISATION'
  | 'COMPLETE_NO_DATA'
  | TcsStatus

export enum TcsStatus {
  INPROGRESS = 'INPROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  DOWN = 'DOWN',
}

export interface TcsOutput {
  resultbyDistributionbyLossType: ResultbyDistributionbyLossType[]

  resultByDistributionDistribution?: string[] | undefined
  resultByDistributionStat?: string[] | undefined
  resultByDistributionValue?: string[] | undefined
  status: string
  error?: string[] | undefined
}

export interface ParameterInputs {
  GWP: number
  Max: number
  LR_Total: number
  LR_Attritional: number
  LR_PerilTotal: number
  Threshold: number
  SRFrequency_Mean: number
}

export async function fetchDefaultParameters(scenarioId: string): Promise<DefaultParameterData | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/parameterisation/scenario/${scenarioId}/default-market`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  const responseObj = await response.json()

  if (Object.keys(responseObj).length === 0) {
    return undefined
  }

  return responseObj
}

export async function createTcsRequest(
  scenarioId: string,
  createTcsRequestBody: CreateTcsRequestBody,
): Promise<ParameterisationResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/parameterisation/scenario/${scenarioId}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createTcsRequestBody),
  })

  if (response.status !== 201) {
    return Promise.reject('Fail to create TCS id')
  }

  return await response.json()
}

export async function fetchParameterisationByScenarioId(scenarioId: string): Promise<ParameterisationByIdResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/parameterisation/scenario/${scenarioId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status >= 500) {
    return Promise.reject('Unhandled server error')
  }
  if (response.status >= 400) {
    return Promise.reject('Fail to retrieve TCS id')
  }

  return await response.json()
}
