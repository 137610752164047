import { Adjustment } from '../../../backend/adjustments'
import { areEqual } from '../../../utils/objects'

export type CalculationsRelevantAdjustment = Pick<
  Adjustment,
  | 'convexShareChange'
  | 'pureGrossRateChange'
  | 'riskAdjustedPremiumRateChange'
  | 'retention'
  | 'appliedFilters'
  | 'acqCostChange'
>

const defaultRenewalAdjustment: CalculationsRelevantAdjustment = {
  convexShareChange: 0,
  pureGrossRateChange: 0,
  riskAdjustedPremiumRateChange: 0,
  retention: 0,
  appliedFilters: {},
  acqCostChange: 0,
}

export const isEqualToDefault = (adj: CalculationsRelevantAdjustment) => {
  return (
    defaultRenewalAdjustment.convexShareChange === adj.convexShareChange &&
    defaultRenewalAdjustment.pureGrossRateChange === adj.pureGrossRateChange &&
    defaultRenewalAdjustment.riskAdjustedPremiumRateChange === adj.riskAdjustedPremiumRateChange &&
    defaultRenewalAdjustment.retention === adj.retention &&
    defaultRenewalAdjustment.acqCostChange === adj.acqCostChange &&
    areEqual(defaultRenewalAdjustment.appliedFilters, adj.appliedFilters)
  )
}
