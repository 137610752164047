import React, { useContext } from 'react'

import { StackedScenarioParameterisationContent } from './StackedScenarioParameterisationContent'
import { TeamParameterisationDefaultMarketDataProvider } from '../../providers/parameterisation/TeamParameterisationDefaultMarketDataProvider'
import { TeamParameterisationDataProvider } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { EXTRA_FILTERS_FOR_SUMMARY_PAGE } from '../../backend/calculate-possible-filter-values'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { StackedScenarioInitialDataProvider } from '../../providers/StackedScenarioComparisonProvider'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'
import { AggregatedMarketDataProviderV2 } from '../../providers/AggregatedMarketDataProviderV2'

export const StackedScenarioParameterisation = () => {
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  if (!currentStackedScenario) {
    return <div />
  }

  return (
    <MarketV2Provider>
      <StackedScenarioDataProvider stackedScenario={currentStackedScenario}>
        <StackedScenarioInitialDataProvider stackedScenarioId={currentStackedScenario.id}>
          <AggregatedMarketDataProviderV2>
            <RenewalGraphFilterDataProvider
              extraFilterValues={EXTRA_FILTERS_FOR_SUMMARY_PAGE}
              onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}
            >
              {' '}
              <TeamParameterisationDefaultMarketDataProvider>
                <TeamParameterisationDataProvider>
                  <StackedScenarioParameterisationContent />
                </TeamParameterisationDataProvider>
              </TeamParameterisationDefaultMarketDataProvider>
            </RenewalGraphFilterDataProvider>
          </AggregatedMarketDataProviderV2>
        </StackedScenarioInitialDataProvider>
      </StackedScenarioDataProvider>
    </MarketV2Provider>
  )
}
