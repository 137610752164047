import { useTheme } from '@nivo/core'
import React from 'react'

export const WrappedTick = (tick: any) => {
  const theme = useTheme()
  const fontSize = 10
  const words = (tick.value as string).split(' ')
  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <line
        strokeWidth={1.5}
        y1={-22}
        y2={-12}
        style={theme.axis.ticks.line}
      />
      {words.map((x, i) => (
        <text
          key={x}
          textAnchor="middle"
          dominantBaseline="middle"
          y={fontSize * i}
          style={{
            ...theme.axis.ticks.text,
            fontSize,
          }}
        >
          {x}
        </text>
      ))}
    </g>
  )
}
