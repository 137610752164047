import React, { useContext, useEffect, useState } from 'react'

import { GraphDataProvider } from '../GraphDataProvider'
import { GraphFilterDataContext } from '../GraphFilterDataProvider'
import { MultiSelectDropdown } from '../../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../../pages/scenario-chooser/ScenarioChooser'
import { formatInceptionYearForDisplay, sortInceptionMonth } from './inception-month-graph'
import { GwpAndAdjustedGwpGroupedByFieldGraph } from './GwpAndAdjustedGwpGroupedByFieldGraph'
import { GraphHeader } from '../../graph-header/GraphHeader'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'
import { toOption } from '../../../utils/lists'

interface GwpAndAdjustedGwpBarChartWithColumnChooserProps {
  defaultColumn: string
  numberOfItemsToInclude?: number
  overrideColumnValue?: string
  hideOthers?: boolean
  fieldsToOrderBy?: [string, string]
  hideNew?: boolean
}

export function GwpAndAdjustedGwpBarChartWithColumnChooser(props: GwpAndAdjustedGwpBarChartWithColumnChooserProps) {
  const { possibleStringColumnValues } = useContext(GraphFilterDataContext)

  const [currentGraphColumn, setCurrentGraphColumn] = useState(props.defaultColumn)

  useEffect(() => {
    if (props.overrideColumnValue) {
      setCurrentGraphColumn(props.overrideColumnValue)
    } else {
      setCurrentGraphColumn(props.defaultColumn || 'A1 Segment')
    }
  }, [props.overrideColumnValue, props.defaultColumn])

  return (
    <>
      <GraphHeader title="Original vs Adjusted GWP" />
      <MultiSelectDropdown
        onSelect={onSelectSingleValueFromMulti(setCurrentGraphColumn)}
        options={possibleStringColumnValues.map(toOption)}
        selected={[toOption(currentGraphColumn)]}
      />
      <GraphDataProvider
        fieldNameToGroupBy={currentGraphColumn}
        sortDataFunction={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME
            ? (data) => sortInceptionMonth(data, currentGraphColumn)
            : undefined
        }
        formatDataFieldForDisplay={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME ? formatInceptionYearForDisplay : undefined
        }
        numberOfItemsToInclude={props.numberOfItemsToInclude}
        hideOthers={props.hideOthers}
        fieldsToOrderBy={props.fieldsToOrderBy}
      >
        <div className="Graph">
          <GwpAndAdjustedGwpGroupedByFieldGraph
            tickRotation={30}
            legendOffset={70}
          />
        </div>
      </GraphDataProvider>
    </>
  )
}
