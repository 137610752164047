import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export interface TagResponse {
  readonly name: string
  readonly entityId: string
  readonly year: string
  readonly createdAt: Date
  readonly updatedAt: Date
}

export async function fetchTagForEntity(entityId: string): Promise<Array<TagResponse>> {
  const promises: Promise<Response>[] = []
  promises.push(
    fetch(`${getCurrentConfigSettings().apiHost}/tags/by-entity-id-and-higher-level-scenarios/${entityId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    }),
  )
  promises.push(
    fetch(`${getCurrentConfigSettings().apiHost}/tags/by-entity-id-and-lower-level-scenarios/${entityId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    }),
  )
  const responses = await Promise.all(promises)
  const tags: TagResponse[] = []
  for (let response of responses) {
    if (response.status === 404) {
      return Promise.resolve([])
    }
    tags.push(...(await response.json()))
  }
  return tags
}

export async function createTag(name: string, year: string, entityId: string, market?: string) {
  if (!market) {
    market = 'ORGANISATIONAL'
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/tags/by-tag-name/${name}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ year, entityId, market }),
  })

  if (response.status === 200) {
    return Promise.resolve([])
  }

  return Promise.reject(await response.json())
}

export async function deleteTag(name: string, year: string, market?: string): Promise<Array<TagResponse>> {
  if (!market) {
    market = 'ORGANISATIONAL'
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/tags/by-tag-name/${name}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ year, market }),
  })

  if (response.status === 404) {
    return Promise.resolve([])
  }

  return await response.json()
}
