import React from 'react'
import './Asterisk.scss'

interface Props {
  visible: boolean
}

export const Asterisk = (props: Props) => {
  return props.visible ? (
    <b
      className="Asterisk"
      data-testid="required-asterisk"
    >
      *
    </b>
  ) : (
    <></>
  )
}
