import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ExcludedPerilsResponse, fetchExcludedPerils } from '../../backend/perils'
import { OutwardScenarioContext } from './OutwardScenarioProvider'
import { ProgressContext, ProgressState } from '../ProgressProvider'

type Peril = Omit<ExcludedPerilsResponse, 'id'>

interface OutwardsExcludedPerilsContainer {
  reloadExcludedPerils: () => void
  peril: Peril
}

const initialPeril: Readonly<Peril> = {
  excludedPerilNames: [],
  description: undefined,
}

export const OutwardsExcludedPerilsContext = createContext<OutwardsExcludedPerilsContainer>({
  peril: initialPeril,
  reloadExcludedPerils: () => void 0,
})

export function OutwardsExcludedPerilsProvider(props: PropsWithChildren) {
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const [peril, setPeril] = useState<Peril>(initialPeril)

  const reloadExcludedPerils = () => {
    if (currentOutwardScenario) {
      updateIndividualProgressItem('fetchingOutwardScenarioPerils', ProgressState.LOADING)
      fetchExcludedPerils(currentOutwardScenario!.id)
        .then((response) => {
          if (!response) {
            return setPeril(initialPeril)
          }
          const { excludedPerilNames, description } = response
          setPeril({ excludedPerilNames, description })
        })
        .then(() => updateIndividualProgressItem('fetchingOutwardScenarioPerils', ProgressState.FINISHED))
    }
  }

  useEffect(reloadExcludedPerils, [currentOutwardScenario, updateIndividualProgressItem])

  return (
    <OutwardsExcludedPerilsContext.Provider
      value={{
        peril,
        reloadExcludedPerils,
      }}
    >
      {props.children}
    </OutwardsExcludedPerilsContext.Provider>
  )
}
