import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { NewCustomPortfolioItem, fetchNewCustomPortfolioItems } from '../backend/new-custom-portfolio-items'
import { ScenarioContext } from './ScenarioProvider'
import { NewAdjustment } from '../../../backend/src/modules/new-adjustments/domain/NewAdjustment.types'
import { NEW_ITEM_PREVIEW_ID } from '../backend/new-portfolio-items'
import { FullScenarioDataContext } from './FullScenarioData/FullScenarioDataProvider'

export interface NewCustomPortfolioAdjustmentContainer {
  ncpAdjustments: NewAdjustment[]
  triggerReloadSavedNcpAdjustments: () => void
  currentlyEditingNcpItemId: string | undefined
  setCurrentlyEditingNcpId: (ncpId: string) => void
  isNcpPreview: boolean
  setIsNcpPreview: (isPreview: boolean) => void
}

export const defaultNewCustomPortfolioAdjustmentContext: NewCustomPortfolioAdjustmentContainer = {
  ncpAdjustments: [],
  triggerReloadSavedNcpAdjustments: () => undefined,
  currentlyEditingNcpItemId: NEW_ITEM_PREVIEW_ID,
  setCurrentlyEditingNcpId: () => undefined,
  isNcpPreview: false,
  setIsNcpPreview: () => undefined,
}

export const NewCustomPortfolioAdjustmentContext = createContext<NewCustomPortfolioAdjustmentContainer>(
  defaultNewCustomPortfolioAdjustmentContext,
)

export const NewCustomPortfolioAdjustmentProvider = (props: PropsWithChildren) => {
  const { currentScenario } = useContext(ScenarioContext)
  const [ncpAdjustments, setNcpAdjustments] = useState<NewAdjustment[]>([])
  const { reloadData } = useContext(FullScenarioDataContext)
  const [currentlyEditingNcpItemId, setCurrentlyEditingNcpId] = useState<string>(NEW_ITEM_PREVIEW_ID)
  const [isNcpPreview, setIsNcpPreview] = useState<boolean>(false)

  const reloadNewNcpItemsFromApi: () => Promise<void> = useCallback(async () => {
    currentScenario &&
      setNcpAdjustments((await fetchNewCustomPortfolioItems(currentScenario.id)).map(ncpItemToNewAdjustment))
    currentScenario && reloadData()
  }, [currentScenario])

  useEffect(() => {
    reloadNewNcpItemsFromApi()
  }, [reloadNewNcpItemsFromApi])

  return (
    <NewCustomPortfolioAdjustmentContext.Provider
      value={{
        triggerReloadSavedNcpAdjustments: reloadNewNcpItemsFromApi,
        ncpAdjustments,
        currentlyEditingNcpItemId,
        setCurrentlyEditingNcpId,
        isNcpPreview,
        setIsNcpPreview,
      }}
    >
      {props.children}
    </NewCustomPortfolioAdjustmentContext.Provider>
  )
}

const ncpItemToNewAdjustment = (ncpItem: NewCustomPortfolioItem): NewAdjustment => ({
  id: ncpItem.id!,
  orderNumber: 0,
  scenarioId: ncpItem.scenarioId!,
  isEnabled: ncpItem.isEnabled,
  name: ncpItem.name,
  description: ncpItem.description,
  gwpValue: ncpItem.totalGwp,
  grossRiskAdjustedRateChange: ncpItem.totalGrarcPercent.toString(),
  appliedFilters: {},
})
