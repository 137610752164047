import React from 'react'
import { css } from '../../utils/css'
import CountUp from 'react-countup'
import './KpiCardSmall.scss'
import Big from 'big.js'
import { formatForAnimation, numericValueIsDefined } from '../../utils/numbers'
import { usePrevious } from '../../utils/hooks/usePrevious'

export type KpiCardSmallProps = React.PropsWithChildren<{
  value: undefined | Big
  title: string
  prefix?: string
  suffix?: string
  tooltip?: string
  className?: string | string[]
  decimals?: number
}>

export function KpiCardSmall(props: KpiCardSmallProps) {
  const previousValue = usePrevious(props.value)

  return (
    <div
      className={css('KpiCardSmall', props.className)}
      title={props.tooltip || props.value?.toString()}
    >
      <h4 className="Title">{props.title}</h4>
      <div className="Value">
        <CountUp
          duration={0.75}
          start={numericValueIsDefined(previousValue) ? formatForAnimation(previousValue) : 0}
          end={formatForAnimation(props.value)}
          decimals={
            props.value?.toFixed(props.decimals).endsWith('.' + '0'.repeat(props.decimals || 1)) ? 0 : props.decimals
          }
          prefix={props.prefix}
          suffix={props.suffix}
        />
      </div>
    </div>
  )
}

export function KpiCardSmallText(props: { value: string; title: string; className?: string }) {
  return (
    <div
      className={css('KpiCardSmall', props.className)}
      title={props.value?.toString()}
    >
      <h4 className="Title">{props.title}</h4>
      <div className="Value">{props.value}</div>
    </div>
  )
}
