import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'

export const INCEPTION_MONTH_COLUMN_NAME = 'Inception Month'

export const ALL_MONTHs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

function sortInceptionMonth(data: any[], columnName: string = RENEWAL_INCEPTION_MONTH_COLUMN_NAME) {
  return data.sort((firstItem, secondItem) => firstItem[columnName] - secondItem[columnName])
}

export function getMonthIndexFromString(month: string): string {
  switch (month) {
    case 'Jan':
      return '1'
    case 'Feb':
      return '2'
    case 'Mar':
      return '3'
    case 'Apr':
      return '4'
    case 'May':
      return '5'
    case 'Jun':
      return '6'
    case 'Jul':
      return '7'
    case 'Aug':
      return '8'
    case 'Sep':
      return '9'
    case 'Oct':
      return '10'
    case 'Nov':
      return '11'
    case 'Dec':
      return '12'
  }

  return month?.toString() || 'Unknown'
}

function formatInceptionYearForDisplay(month: string | number | Date): string {
  switch (month) {
    case '1':
      return 'Jan'
    case '2':
      return 'Feb'
    case '3':
      return 'Mar'
    case '4':
      return 'Apr'
    case '5':
      return 'May'
    case '6':
      return 'Jun'
    case '7':
      return 'Jul'
    case '8':
      return 'Aug'
    case '9':
      return 'Sep'
    case '10':
      return 'Oct'
    case '11':
      return 'Nov'
    case '12':
      return 'Dec'
  }

  return month?.toString() || 'Unknown'
}

export { formatInceptionYearForDisplay, sortInceptionMonth }
