import { useContext, useState, useEffect } from 'react'
import { TableOfKpis } from '../../components/summary-adjustments-graph/table-of-kpis/TableOfKpis'
import {
  GraphFilterDataContext,
  RenewalGraphFilterDataProvider,
} from '../../components/renewal-graph/GraphFilterDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { ScenarioLevel, StackedScenario } from '../../backend/stacked-scenarios'

const INCLUDED_DATA_COLUMNS_THAT_CAN_BE_SPLIT_BY = [
  'WF Outwards Programme',
  'WF Outwards Treaty',
  'WF Outwards Layer',
  'UW Division',
  'UW Team',
  'A1 Market',
  'A1 Segment',
  'Broker',
  'Entity',
  'Placement Type',
  'COB - Tier 3',
  'Operating Territory',
]

function isIncludedColumn(columnName: string) {
  return INCLUDED_DATA_COLUMNS_THAT_CAN_BE_SPLIT_BY.includes(columnName)
}

export function fieldToDefaultTo(currentStackedScenario: StackedScenario | undefined) {
  if (!currentStackedScenario) {
    return 'A1 Segment'
  }

  if (currentStackedScenario.scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
    return 'UW Division'
  }

  if (currentStackedScenario.scenarioLevel === ScenarioLevel.DIVISIONAL) {
    return 'UW Team'
  }

  return 'A1 Market'
}

export function SummaryTableContent() {
  return (
    <RenewalGraphFilterDataProvider
      extraFilterValues={INCLUDED_DATA_COLUMNS_THAT_CAN_BE_SPLIT_BY}
      onesToIgnore={[]}
    >
      <SummaryTableContentWithoutItsOwnDataProvider />
    </RenewalGraphFilterDataProvider>
  )
}

export function SummaryTableContentWithoutItsOwnDataProvider(props: { columnToDefaultTo?: string }) {
  const { allPossibleColumnValuesForSummary } = useContext(GraphFilterDataContext)
  const columns: string[] = allPossibleColumnValuesForSummary.filter(isIncludedColumn)

  return (
    <Component
      columns={columns}
      columnToDefaultTo={props.columnToDefaultTo}
    />
  )
}

type Props = {
  columns: string[]
  columnToDefaultTo?: string
}

function Component(props: Props): JSX.Element {
  const { columns } = props
  const { currentStackedScenario } = useContext(StackedScenarioContext)

  const [init, setInit] = useState<string>(props.columnToDefaultTo ?? fieldToDefaultTo(currentStackedScenario))

  const depObject = JSON.stringify(currentStackedScenario)
  useEffect(() => {
    if (!props.columnToDefaultTo) {
      const next = fieldToDefaultTo(currentStackedScenario)
      setInit(next)
    }
  }, [props.columnToDefaultTo, columns, currentStackedScenario, depObject])

  return (
    <div>
      <TableOfKpis
        columnsThatArePossible={columns}
        startingColumn={init}
      />
    </div>
  )
}
