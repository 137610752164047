import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import {
  fetchOutwardsPortfolioListing,
  OutwardPortfolioListing,
  TreatiesForCurrentProgramListing,
  TreatiesForCurrentVersionListing,
} from '../../backend/outwards-portfolioListing'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { mapValues } from '../../utils/lists'

type OutwardsPortfolioListingProviderProps = PropsWithChildren

export interface OutwardsPortfolioListingContainer {
  outwardPortfolioListing: OutwardPortfolioListing | null
  allTreatiesWithVersionsAndYearsListing: TreatiesForCurrentVersionListing | null
  allTreatiesWithProgrammesListing: TreatiesForCurrentProgramListing | null
  reloadListing: () => Promise<void>
  canAccessOutwards: boolean
  canWriteToOutwards: boolean
}

const OutwardsPortfolioListingContext = createContext<OutwardsPortfolioListingContainer>({
  outwardPortfolioListing: null,
  canAccessOutwards: false,
  canWriteToOutwards: false,
  allTreatiesWithVersionsAndYearsListing: null,
  allTreatiesWithProgrammesListing: null,
  reloadListing: () => Promise.resolve(),
})

function OutwardsPortfolioListingProvider(props: OutwardsPortfolioListingProviderProps) {
  const [outwardPortfolioListing, updateOutwardPortfolioListing] = useState<OutwardPortfolioListing | null>(null)
  const [allTreatiesWithVersionsAndYearsListing, setAllTreatiesWithVersionAndYearsListing] =
    useState<TreatiesForCurrentVersionListing | null>(null)
  const [allTreatiesWithProgrammesListing, setAllTreatiesWithProgrammesListing] =
    useState<TreatiesForCurrentProgramListing | null>(null)
  const [canAccessOutwards, setCanAccessOutwards] = useState<boolean>(false)
  const [canWriteToOutwards, setCanWriteToOutwards] = useState<boolean>(false)

  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const reloadListing = async () => {
    fetchOutwardsPortfolioListing()
      .then(({ listing, canWrite }) => {
        updateOutwardPortfolioListing(listing)
        setCanAccessOutwards(true)
        setCanWriteToOutwards(canWrite)
      }, console.error)
      .catch(() => setCanAccessOutwards(false))
  }

  useEffect(() => {
    updateIndividualProgressItem('outwardsPortfolioListingFetch', ProgressState.LOADING)
    reloadListing().then(() => updateIndividualProgressItem('outwardsPortfolioListingFetch', ProgressState.FINISHED))
  }, [updateIndividualProgressItem])

  useEffect(() => {
    if (outwardPortfolioListing && Object.entries(outwardPortfolioListing).length) {
      const allTreatiesForCurrentProgramme = mapValues(outwardPortfolioListing, (versionsForCurrentYear) => {
        return mapValues(versionsForCurrentYear!, (programmesForCurrentVersion) => {
          return mapValues(programmesForCurrentVersion!, (treatiesForCurrentVersion) => {
            return Object.values(treatiesForCurrentVersion!).flatMap((layersForCurrentTreaty) => {
              return layersForCurrentTreaty!.map((layerDetails) => {
                return {
                  layer: layerDetails.layer,
                  treaty: layerDetails.treaty,
                  program: layerDetails.program,
                  planVersion: layerDetails.planVersion,
                  year: layerDetails.year,
                }
              })
            })
          })
        })
      })

      const allTreatiesForCurrentVersion = mapValues(allTreatiesForCurrentProgramme, (versionsForCurrentYear) => {
        return mapValues(versionsForCurrentYear!, (programmesForCurrentVersion) => {
          return Object.values(programmesForCurrentVersion!).flatMap((layersForCurrentTreaty) => {
            return layersForCurrentTreaty!.map((layerDetails) => ({
              layer: layerDetails.layer,
              treaty: layerDetails.treaty,
              program: layerDetails.program,
              planVersion: layerDetails.planVersion,
              year: layerDetails.year,
            }))
          })
        })
      })

      setAllTreatiesWithProgrammesListing(allTreatiesForCurrentProgramme as TreatiesForCurrentProgramListing)
      setAllTreatiesWithVersionAndYearsListing(allTreatiesForCurrentVersion as TreatiesForCurrentVersionListing)
    }
  }, [outwardPortfolioListing])

  return (
    <OutwardsPortfolioListingContext.Provider
      value={{
        canWriteToOutwards,
        canAccessOutwards,
        outwardPortfolioListing,
        reloadListing,
        allTreatiesWithVersionsAndYearsListing,
        allTreatiesWithProgrammesListing,
      }}
    >
      {props.children}
    </OutwardsPortfolioListingContext.Provider>
  )
}

export { OutwardsPortfolioListingProvider, OutwardsPortfolioListingContext }
