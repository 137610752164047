import React, { useState } from 'react'

import { AddingTagModalContext } from './AddingTagModalContext'

type Props = React.PropsWithChildren<{}>

export function AddingTagModalProvider(props: Props): React.ReactElement {
  const [entityId, setEntityId] = useState<string>()
  const [market, setMarket] = useState<string>()
  const [division, setDivision] = useState<string>()

  return (
    <AddingTagModalContext.Provider
      value={{ entityId, setEntityId, market, setMarket, division, setDivision }}
      children={props.children}
    />
  )
}
