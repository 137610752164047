import {
  ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_ONE_HUNDRED_PERCENT_PREMIUM_VALUE,
  ADJUSTMENT_ID,
  WAYFINDER_UNIQUE_ID,
} from '../../backend/calculate-kpis'

const COLUMNS_TO_REMOVE = [
  ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_ONE_HUNDRED_PERCENT_PREMIUM_VALUE,
  WAYFINDER_UNIQUE_ID,
  ADJUSTMENT_ID,
  'Loss Input',
  'WF Adjustment Renewal Retention',
]

export function filterOutColumnsWeDontWant(data: any[]): any[] {
  return data.map((currentDataItem) => {
    const newDataItem = { ...currentDataItem }

    COLUMNS_TO_REMOVE.forEach((columnToRemove) => delete newDataItem[columnToRemove])

    return newDataItem
  })
}
