import { createContext, useContext, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { fetchStackedScenariosByVersion, StackedOutwardScenario } from '../../backend/stacked-outwardScenarios'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { useIsMounted } from '../../utils/mounting'
import { AppQueryContext } from '../AppQueryProvider'

export const StackedOutwardScenarioListContext = createContext<StackedOutwardScenarioContainer>({
  currentStackedOutwardScenarios: [],
  reloadStackedOutwardScenarios: () => void 0,
})

interface StackedOutwardScenarioContainer {
  currentStackedOutwardScenarios: StackedOutwardScenario[]
  reloadStackedOutwardScenarios: () => void
}

export function StackedOutwardScenarioListProvider(props: React.PropsWithChildren<{}>) {
  const [currentVersion] = useQueryParam('version', StringParam)

  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery } = useContext(AppQueryContext)

  const isMounted = useIsMounted()

  const [currentStackedOutwardScenarios, setStackedOutwardScenarios] = useState<StackedOutwardScenario[]>([])

  const [currentYear] = yearQuery

  const reloadStackedOutwardScenarios = () => {
    const progressKey = `fetchStackedOutwardScenarioList-${currentYear}-${currentVersion}`

    updateIndividualProgressItem(progressKey, ProgressState.LOADING)
    fetchStackedScenariosByVersion(currentYear, currentVersion)
      .then((newListOfScenarios) => {
        if (!isMounted()) return null
        if (newListOfScenarios && newListOfScenarios.length) {
          setStackedOutwardScenarios(newListOfScenarios)
        } else {
          setStackedOutwardScenarios([])
        }
      })
      .then(() => updateIndividualProgressItem(progressKey, ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem(progressKey, ProgressState.ERROR))
  }

  useEffect(reloadStackedOutwardScenarios, [currentYear, currentVersion, updateIndividualProgressItem, isMounted])

  return (
    <StackedOutwardScenarioListContext.Provider
      value={{
        currentStackedOutwardScenarios,
        reloadStackedOutwardScenarios,
      }}
      children={props.children}
    />
  )
}
