import React from 'react'
import './ProgressLine.scss'
import { css } from '../../utils/css'
import { ProgressState } from '../../providers/ProgressProvider'

interface ProgressLineProps {
  state?: ProgressState
}

const stateToCssClassMap: Record<ProgressState, string> = {
  [ProgressState.FINISHED]: 'Finished',
  [ProgressState.LOADING]: 'InProgress',
  [ProgressState.ERROR]: '', // Do not set a class when in the 'Error' Progress State
}

export default function ProgressLine(props: ProgressLineProps) {
  return <div className={css('ProgressLine', stateToCssClassMap[props.state || ProgressState.LOADING])} />
}
