import React, { createContext, useState } from 'react'

import { MultiSelectDropdown } from '../components/multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../pages/scenario-chooser/ScenarioChooser'
import DropdownContainer from '../components/dropdown-container/DropdownContainer'
import { toOption } from '../utils/lists'

export enum FieldToGroupPortfolioBy {
  CONVEX_REQUIREMENT = 'Convex Requirement Name',
  CONVEX_SUBMISSION = 'Convex Submission Ref',
  CONVEX_LAYER = 'Convex Layer Ref',
}

export interface PortfolioGroupDropdownContainer {
  currentFieldToGroupBy: FieldToGroupPortfolioBy
}

const PortfolioGroupDropdownContext = createContext<PortfolioGroupDropdownContainer>({
  currentFieldToGroupBy: Object.values(FieldToGroupPortfolioBy)[0],
})

const { Consumer } = PortfolioGroupDropdownContext

function PortfolioGroupDropdownProvider(props: React.PropsWithChildren<{}>) {
  const [currentFieldToGroupBy, setCurrentFieldToGroupBy] = useState<FieldToGroupPortfolioBy>(
    Object.values(FieldToGroupPortfolioBy)[0],
  )

  return (
    <>
      <DropdownContainer>
        <MultiSelectDropdown
          options={Object.values(FieldToGroupPortfolioBy).map(toOption)}
          selected={[toOption(currentFieldToGroupBy)]}
          onSelect={onSelectSingleValueFromMulti(setCurrentFieldToGroupBy)}
        />
      </DropdownContainer>
      <PortfolioGroupDropdownContext.Provider value={{ currentFieldToGroupBy }}>
        {props.children}
      </PortfolioGroupDropdownContext.Provider>
    </>
  )
}

export { Consumer as PortfolioGroupDropdownConsumer, PortfolioGroupDropdownProvider, PortfolioGroupDropdownContext }
