import { useContext } from 'react'
import HeadingWithTitle from '../../components/heading-with-title/HeadingWithTitle'
import './PortfolioItemCard.scss'
import { css } from '../../utils/css'
import {
  NewPseudoPortfolioItemsContextContainer,
  PseudoPortfolioContext,
} from '../../providers/NewPseudoPortfolioItemsProvider'
import { fetchNewPortfolioItem } from '../../backend/new-portfolio-items'
import { ONE_HUNDRED, suffixForValue } from '../../utils/numbers'
import ReactSwitch from 'react-switch'
import DraggableCard from '../draggable-card/DraggableCard'
import LockedIcon from '../icons/LockedIcon'
import EditIcon from '../icons/EditIcon'
import DeleteIcon from '../icons/DeleteIcon'
import { FilterContext } from '../../providers/FilterProvider'
import { FilterValues } from '../../backend/calculate-possible-filter-values'
import { ACTUALS_ADJUSTMENT_ID, OVERRIDDEN_BY_ACTUALS } from '../../backend/calculate-with-actuals'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import PreviewIcon from '../icons/PreviewIcon'
import { NewAdjustment } from '../../../../backend/src/modules/new-adjustments/domain/NewAdjustment.types'
import { MAX_LENGTH_OF_SHORT_STRINGS, trimStringIfTooLong } from '../../utils/strings'
import StopPreviewIcon from '../icons/StopPreviewIcon'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import {
  calculateCurrentAdjustmentIds,
  calculateItemsLowerThanThis,
  calculatePercentageOfOriginalGwpAdjustedBy,
  sortItemsNewClientFirstThenThis,
} from '../adjustment-card/ProgressBar'
import { TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN } from '../../backend/calculate-kpis'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import { sanitizeId } from '../../utils/ids'
import { calculateDisplayOrderNumber } from '../adjustment-card/utils'
import { CategoryWeightings, fetchNewCustomPortfolioItem } from '../../backend/new-custom-portfolio-items'
import { NcpDispatchContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { ManyDropdownFilterValues } from '../../providers/FilterProvider/model'
import ZoomIcon from '../icons/ZoomIcon'

export type PortfolioItemCardProps = {
  index: number
  itemId: string
  name: string
  gwpValue: number
  adjustedGwpValue: number
  grarcValue?: string
  description: string
  isEnabled: boolean
  scenarioId: string
  onDrop?: (hoverIndex: number, dropIndex: number) => void
  onHover?: (hoverIndex: number, dropIndex: number) => void
  toggleShowingAdjustment: () => void
  onDeleteItem: () => void
  canDrag: boolean
  adjustedPercentage: string
  numberOfItems?: number
  onTriggerEditItem?: () => void
  uuid?: string
  hasErrored: boolean
  hideCreateAndEdit?: boolean
  hidePreviewFilter?: boolean
  lowestOrderNumberIs0?: boolean
  orderNumber?: number
  isNcpAdjustment?: boolean
  way1401NewCustomPortfolio?: boolean
  onPreviewItem?: () => void
}

export function PortfolioItemCard(props: PortfolioItemCardProps) {
  const { newDataInputs, currentlyPreviewingPseudoItemId, setWhichItemIsBeingPreviewed } =
    useContext<NewPseudoPortfolioItemsContextContainer>(PseudoPortfolioContext)
  const { onChangeFilters } = useContext(FilterContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { newClients } = useContext(NewClientItemsContext)
  const { fullNewKpis } = useContext(FullScenarioDataContext)
  const ncpStateDispatch = useContext(NcpDispatchContext)
  assertNotNil(ncpStateDispatch)
  const displayOrderNumber = calculateDisplayOrderNumber(props.orderNumber, props.lowestOrderNumberIs0)

  const onUpdateFiltersWithValues = (savedAdjustment: NewAdjustment | undefined) => {
    onChangeFilters(
      Object.entries(savedAdjustment?.appliedFilters! as FilterValues).reduce(
        (dataSoFar: ManyDropdownFilterValues, [columnName, chosenValue], index) => ({
          ['filter' + (index + 1).toString()]: { columnName, chosenValue },
          ...dataSoFar,
        }),
        {} as ManyDropdownFilterValues,
      ),
    )
  }

  const onPreviewFilters = async () => {
    const savedAdjustment = await fetchNewPortfolioItem(props.scenarioId, props.itemId)
    setWhichItemIsBeingPreviewed(props.itemId)
    props.onPreviewItem?.()
    onUpdateFiltersWithValues(savedAdjustment)
  }

  const onFinishPreview = async () => {
    setWhichItemIsBeingPreviewed('')
    onChangeFilters({})
  }

  const onStartEditAdjustment = async () => {
    const savedAdjustment = await fetchNewPortfolioItem(props.scenarioId, props.itemId)
    onUpdateFiltersWithValues(savedAdjustment)
    props.onTriggerEditItem && props.onTriggerEditItem()
  }

  const onPreviewNcpAdjustment = async () => {
    await loadNcpDataToDisplay()
    props.onPreviewItem?.()
  }

  const onEditNcpAdjustment = async () => {
    await loadNcpDataToDisplay()
    props.onTriggerEditItem?.()
  }

  const loadNcpDataToDisplay = async () => {
    const savedNcpAdjustment = await fetchNewCustomPortfolioItem(props.scenarioId, props.itemId)

    ncpStateDispatch({
      type: 'updateSegmentData',
      payload: { segmentSplitsData: savedNcpAdjustment.segments },
    })

    Object.entries(savedNcpAdjustment.categoryWeightings).forEach(([category, field]) => {
      Object.entries(field).forEach(([key, value]) => {
        ncpStateDispatch({
          type: 'updateSingleKeyForCategoryWeighting',
          payload: {
            categoryName: category as keyof CategoryWeightings,
            key,
            data: Number(value),
          },
        })
      })
    })
  }

  const canBeEdited =
    !props.hideCreateAndEdit &&
    currentScenario?.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)

  const otherCardIsBeingPreviewed = currentlyPreviewingPseudoItemId && currentlyPreviewingPseudoItemId !== props.itemId

  const idsOfCurrentAdjustment = calculateCurrentAdjustmentIds(
    props.itemId,
    [...newClients, ...(newDataInputs || [])] || [],
  )

  const percentageAdjustedByThisAdjustment = calculatePercentageOfOriginalGwpAdjustedBy(
    fullNewKpis,
    idsOfCurrentAdjustment,
    TOTAL_ADJUSTED_GWP_COLUMN,
    TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  )

  const adjustmentsLowerThanThisInTheChain = calculateItemsLowerThanThis(
    [...newClients, ...(newDataInputs || [])] || [],
    props.itemId,
    sortItemsNewClientFirstThenThis,
  )

  const percentageAdjustedByLowerAdjustments = calculatePercentageOfOriginalGwpAdjustedBy(
    fullNewKpis,
    [
      props.itemId === 'Actuals' ? '' : OVERRIDDEN_BY_ACTUALS,
      ...adjustmentsLowerThanThisInTheChain.map((item) => item.id),
    ],
    TOTAL_ADJUSTED_GWP_COLUMN,
    TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  )

  const leftoverPercentage = ONE_HUNDRED.minus(percentageAdjustedByThisAdjustment).minus(
    percentageAdjustedByLowerAdjustments,
  )

  return (
    <div
      className={css(
        'PortfolioItemCard',
        props.hasErrored ? 'PortfolioItemCardErrored' : '',
        props.canDrag ? 'CanGrab' : 'CantDrag',
        otherCardIsBeingPreviewed ? 'Inactive' : '',
      )}
      key={props.itemId}
    >
      <DraggableCard
        index={props.index}
        id={props.itemId}
        onHover={props.onHover ? props.onHover : () => void 0}
        onDrop={props.onDrop ? props.onDrop : () => void 0}
        canDrag={props.canDrag}
      >
        <div className="ProgressBars">
          <div
            className="ProgressBarOne"
            style={{ width: `${percentageAdjustedByLowerAdjustments}%` }}
            data-tip={`${percentageAdjustedByLowerAdjustments}%`}
          />
          <div
            className="ProgressBarTwo"
            style={{ width: `${percentageAdjustedByThisAdjustment}%` }}
            data-tip={`${percentageAdjustedByLowerAdjustments}%`}
          />
          <div
            className="ProgressBarThree"
            style={{ width: `${leftoverPercentage}%` }}
            data-tip={`${leftoverPercentage}%`}
          />
        </div>
        <div className="CardHeader">
          <h4
            className={css('NewPortfolioItemCardTitle', props.isEnabled ? 'Checked' : 'Unchecked')}
            id={`${sanitizeId(props.name)}-Name`}
          >
            {displayOrderNumber === undefined ? '' : displayOrderNumber + '. '}
            {trimStringIfTooLong(props.name, MAX_LENGTH_OF_SHORT_STRINGS)}
          </h4>
          {canBeEdited && props.itemId !== ACTUALS_ADJUSTMENT_ID && props.itemId !== 'NEW_CLIENT_SUMMARY_ID' ? (
            <div className="CardOptions">
              {props.isNcpAdjustment && (
                <ZoomIcon
                  className="PreviewButton"
                  onClick={onPreviewNcpAdjustment}
                  data-testid="portfolio-item-card-preview-icon"
                />
              )}
              {!props.isNcpAdjustment && !currentlyPreviewingPseudoItemId && (
                <PreviewIcon
                  className="PreviewButton"
                  onClick={onPreviewFilters}
                  data-testid="portfolio-item-card-preview-icon"
                />
              )}
              {!props.isNcpAdjustment && currentlyPreviewingPseudoItemId === props.itemId && (
                <StopPreviewIcon
                  className="StopPreviewButton"
                  onClick={onFinishPreview}
                />
              )}
              <EditIcon
                className="EditButton"
                onClick={props.isNcpAdjustment ? onEditNcpAdjustment : onStartEditAdjustment}
                data-testid="portfolio-item-card-edit-icon"
              />
              <DeleteIcon
                className="DeleteButton"
                onClick={props.onDeleteItem}
                data-testid="portfolio-item-card-delete-icon"
              />
              <div data-tip={props.isEnabled ? 'On' : 'Off'}>
                <ReactSwitch
                  className="ToggleAdjustment"
                  onChange={props.toggleShowingAdjustment}
                  checked={Boolean(props.isEnabled)}
                  onColor="#0BC893"
                  offColor="#4E566B"
                  handleDiameter={8}
                  height={12}
                  width={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  data-testid="portfolio-item-card-toggle-is-enabled"
                />
              </div>
            </div>
          ) : (
            <div className="CardOptions">
              {props.itemId !== ACTUALS_ADJUSTMENT_ID && props.itemId !== 'NEW_CLIENT_SUMMARY_ID' && (
                <>
                  {props.isNcpAdjustment && (
                    <ZoomIcon
                      className="PreviewButton"
                      onClick={onPreviewNcpAdjustment}
                    />
                  )}
                  {!props.isNcpAdjustment && !currentlyPreviewingPseudoItemId && (
                    <PreviewIcon
                      className="PreviewButton"
                      onClick={onPreviewFilters}
                    />
                  )}
                  {!props.isNcpAdjustment && currentlyPreviewingPseudoItemId === props.itemId && (
                    <StopPreviewIcon
                      className="StopPreviewButton"
                      onClick={onFinishPreview}
                    />
                  )}
                </>
              )}
              <LockedIcon className="LockedIcon" />
            </div>
          )}
        </div>
        {props.way1401NewCustomPortfolio && (
          <div
            className="CardSubHeader"
            data-testid="portfolio-item-card-subheading"
          >
            <p className="NewPortfolioItemCardTitle">
              {props.isNcpAdjustment ? 'Customised portfolio splits' : 'Sampled from existing renewable data'}
            </p>
          </div>
        )}
        <div className="PortfolioItemCardContent">
          <div className="PortfolioItemNumbersContainer">
            <div className={css('Values', props.isEnabled ? 'Checked' : 'Unchecked')}>
              <HeadingWithTitle
                title="GWP"
                value={props.gwpValue}
                className="GWP"
                decimals={1}
                prefix="$"
                suffix={suffixForValue(props.gwpValue)}
                id={`${sanitizeId(props.name)}-GWP`}
              />
              <HeadingWithTitle
                title="Adjusted GWP"
                value={props.adjustedGwpValue}
                className="GWP"
                decimals={1}
                prefix="$"
                suffix={suffixForValue(props.adjustedGwpValue)}
                id={`${sanitizeId(props.name)}-Adjusted-GWP`}
              />
              {!!props.grarcValue && !props.isNcpAdjustment && (
                <HeadingWithTitle
                  title="GRARC"
                  value={Number.parseFloat(props.grarcValue)}
                  className="GRARC"
                  decimals={2}
                  omitFormattingValue
                  suffix={'%'}
                  id={`${sanitizeId(props.name)}-GRARC`}
                />
              )}
              {props.numberOfItems !== undefined && (
                <HeadingWithTitle
                  title={props.itemId === 'NEW_CLIENT_SUMMARY_ID' ? '# New Clients ' : 'Rows Created'}
                  value={props.numberOfItems}
                  className="CreatedItems"
                  suffix={suffixForValue(props.numberOfItems)}
                  id={`${sanitizeId(props.name)}-${sanitizeId(
                    props.itemId === 'NEW_CLIENT_SUMMARY_ID' ? 'Number New Clients' : 'Rows Created',
                  )}`}
                />
              )}
            </div>
          </div>
          <div className="PortfolioItemDescriptionContainer">
            {props.description && (
              <div className="ItemDescription">
                <p id={`${sanitizeId(props.name)}-Description`}>{trimStringIfTooLong(props.description)}</p>
              </div>
            )}
          </div>
        </div>
        {props.hasErrored && <p className="ErrorMessage">This item has no affect</p>}
      </DraggableCard>
    </div>
  )
}

export default PortfolioItemCard
