import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import {
  AvailableExposureFilters,
  AverageAnnualLossTableData,
  ExposuresContextPath,
  fetchAvailableExposureFilters,
  fetchAverageAnnualLossTableData,
  fetchMarketShareValues,
  fetchMarketShareValuesGroupedByColumn,
  MarketShareValues,
  MarketShareValuesGroupedByColumn,
} from '../backend/exposures'
import { ScenarioContext } from './ScenarioProvider'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { StackedScenarioContext } from './StackedScenarioProvider'

export interface ExposuresDataContainer {
  availableExposureFilters: AvailableExposureFilters | undefined
  marketShareValues: MarketShareValues | undefined
  marketShareValuesGroupedByZoneName: MarketShareValuesGroupedByColumn | undefined
  averageAnnualLossTableData: AverageAnnualLossTableData | undefined
  reloadMarketShareData: (bandName: string) => Promise<void>
  reloadMarketShareDataGroupedByZoneName: (bandName: string) => Promise<void>
}

const ExposuresDataContext = createContext<ExposuresDataContainer>({
  availableExposureFilters: undefined,
  marketShareValues: undefined,
  marketShareValuesGroupedByZoneName: undefined,
  averageAnnualLossTableData: undefined,
  reloadMarketShareData: () => Promise.resolve(),
  reloadMarketShareDataGroupedByZoneName: () => Promise.resolve(),
})

function ExposuresDataProvider(props: PropsWithChildren<{}>) {
  const [availableExposureFilters, setAvailableExposureFilters] = useState<AvailableExposureFilters | undefined>()
  const [marketShareValues, setMarketShareValues] = useState<MarketShareValues | undefined>()
  const [marketShareValuesGroupedByZoneName, setMarketShareValuesGroupedByZoneName] = useState<
    MarketShareValuesGroupedByColumn | undefined
  >()
  const [averageAnnualLossTableData, setAverageAnnualLossTableData] = useState<AverageAnnualLossTableData | undefined>()

  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)

  const currentContext = useMemo(
    () =>
      currentScenario ? ExposuresContextPath.MARKET : currentStackedScenario ? ExposuresContextPath.STACKED : undefined,
    [currentScenario, currentStackedScenario],
  )

  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const reloadExposuresFiltersFromRemote = () => {
    if (!currentScenario && !currentStackedScenario) {
      return
    }

    updateIndividualProgressItem('fetchAvailableExposureFilters', ProgressState.LOADING)
    fetchAvailableExposureFilters(currentScenario?.id || currentStackedScenario?.id!, currentContext)
      .then(setAvailableExposureFilters)
      .finally(() => updateIndividualProgressItem('fetchAvailableExposureFilters', ProgressState.FINISHED))
  }

  const reloadMarketShareValuesFromRemote = async (bandName: string) => {
    if (!currentScenario && !currentStackedScenario) {
      return
    }

    updateIndividualProgressItem('fetchMarketShareValues', ProgressState.LOADING)
    fetchMarketShareValues(currentScenario?.id || currentStackedScenario?.id!, bandName, currentContext)
      .then(setMarketShareValues)
      .finally(() => updateIndividualProgressItem('fetchMarketShareValues', ProgressState.FINISHED))
  }

  const reloadMarketShareValuesGroupedByZoneNameFromRemote = async (bandName: string) => {
    if (!currentScenario && !currentStackedScenario) {
      return
    }

    updateIndividualProgressItem('fetchMarketShareValuesGroupedByZoneName', ProgressState.LOADING)
    fetchMarketShareValuesGroupedByColumn(
      currentScenario?.id || currentStackedScenario?.id!,
      bandName,
      'zoneName',
      currentContext,
    )
      .then(setMarketShareValuesGroupedByZoneName)
      .finally(() => updateIndividualProgressItem('fetchMarketShareValuesGroupedByZoneName', ProgressState.FINISHED))
  }

  const reloadAverageAnnualLossTableDataFromRemote = () => {
    if (!currentScenario && !currentStackedScenario) {
      return
    }

    updateIndividualProgressItem('fetchAverageAnnualLossTableData', ProgressState.LOADING)
    fetchAverageAnnualLossTableData(currentScenario?.id || currentStackedScenario?.id!, undefined, currentContext)
      .then(setAverageAnnualLossTableData)
      .finally(() => updateIndividualProgressItem('fetchAverageAnnualLossTableData', ProgressState.FINISHED))
  }

  useEffect(reloadExposuresFiltersFromRemote, [
    currentScenario,
    currentStackedScenario,
    currentContext,
    updateIndividualProgressItem,
  ])
  useEffect(reloadAverageAnnualLossTableDataFromRemote, [
    currentScenario,
    currentStackedScenario,
    currentContext,
    updateIndividualProgressItem,
  ])

  return (
    <ExposuresDataContext.Provider
      value={{
        availableExposureFilters: availableExposureFilters,
        marketShareValues: marketShareValues,
        marketShareValuesGroupedByZoneName: marketShareValuesGroupedByZoneName,
        averageAnnualLossTableData: averageAnnualLossTableData,
        reloadMarketShareData: reloadMarketShareValuesFromRemote,
        reloadMarketShareDataGroupedByZoneName: reloadMarketShareValuesGroupedByZoneNameFromRemote,
      }}
      children={props.children}
    />
  )
}

export { ExposuresDataProvider, ExposuresDataContext }
