import { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import './StackedScenarioPortfolio.scss'
import { FilterProvider } from '../../providers/FilterProvider'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import PortfolioDataTable from '../../components/portfolio-data-table/PortfolioDataTable'
import Button from '../../components/button/Button'
import { createCsvFile, saveDataToFile } from '../../utils/outputs/createCsv'
import { filterOutColumnsWeDontWant } from '../../utils/outputs/outputFilter'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import Spacer from '../../components/spacer/Spacer'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import SplitContentContainer from '../../components/split-content-container/SplitContentContainer'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'

function StackedScenarioPortfolio(props: RouteComponentProps) {
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  if (!currentStackedScenario) {
    return <div />
  }

  const downloadCsv = () => {
    const allRawLines = createCsvFile(filterOutColumnsWeDontWant(fullDataForScenario))
    saveDataToFile(currentStackedScenario.name, allRawLines)
  }

  return (
    <div className="StackedDataView">
      <PageHeaderContainer>
        <Spacer />
        <Button
          title={'Get CSV'}
          onClick={downloadCsv}
        />
      </PageHeaderContainer>
      <SplitContentContainer
        leftcolumn={
          <FiltersContainer>
            <ScenarioFilterCard />
          </FiltersContainer>
        }
        rightcolumn={
          <div className="PortfolioDataTable">
            <PortfolioDataTable />
          </div>
        }
      />
    </div>
  )
}

const StackedScenarioPortfolioWithRouter = withRouter(StackedScenarioPortfolio)

const StackedScenarioWithProviders = () => {
  const { currentStackedScenario } = useContext(StackedScenarioContext)

  if (!currentStackedScenario) {
    return <div />
  }

  return (
    <FilterProvider>
      <MarketV2Provider>
        <StackedScenarioDataProvider stackedScenario={currentStackedScenario}>
          <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
            <StackedScenarioPortfolioWithRouter />
          </RenewalGraphFilterDataProvider>
        </StackedScenarioDataProvider>
      </MarketV2Provider>
    </FilterProvider>
  )
}

export default withRouter(StackedScenarioWithProviders)
