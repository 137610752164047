import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
  fetchTeamParameterisationByScenarioId,
  fetchUnderlyingMarketDataForTeamParameterisationByTeamScenarioId, // flip
  ParameterisationTeamScenarioByIdResponse, //
  ResultbyDistributionbyLossType,
  ModelStatus,
  TcsStatus,
} from '../../backend/stacked-scenario-parameterisation'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { getSetofColumns } from '../../pages/parameterisation/ParameterisationRows'
import { StackedScenarioContext } from '../StackedScenarioProvider'
import { ParameterisationByIdResponse } from '../../backend/parameterisation'

const REPORT_REFRESH_INTERVAL_IN_MS = 5_000
type IntervalFunction = () => unknown | void

export function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = useRef<IntervalFunction | null>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [callback, delay])
}

export const TeamParameterisationDataContext = createContext<TeamParameterisationDataContextContainer>({
  parameterisationData: undefined,
  needToPollForMoreData: 'FIRST_TIME_LOAD',
  underlyingMarketParamData: [],
  setNeedToPollForMoreData: (modelStatus: ModelStatus) => void 0,
  lastUpdatedAt: undefined,
  setLastUpdatedAt: (lastUpdatedAt: string) => void 0,
  lossToggle: 'Loss Ratio',
  dataFilteredByDistributionType: [],
  allUniqueColumns: [],
  setLossToggle: (x) => void 0,
})

export type LossToggle = 'Loss Ratio' | 'Loss Value'

export interface TeamParameterisationDataContextContainer {
  parameterisationData: undefined | ParameterisationTeamScenarioByIdResponse
  underlyingMarketParamData: Array<null | ParameterisationByIdResponse>
  needToPollForMoreData: ModelStatus
  setNeedToPollForMoreData: (modelStatus: ModelStatus) => void
  lastUpdatedAt: undefined | string
  setLastUpdatedAt: (lastUpdatedAt: string) => void
  dataFilteredByDistributionType: Array<ResultbyDistributionbyLossType>
  allUniqueColumns: Array<string>
  lossToggle: LossToggle
  setLossToggle: (x: LossToggle) => void
}
function fromDistribution(
  p: ParameterisationTeamScenarioByIdResponse | undefined,
  lossToggle: LossToggle,
): Array<ResultbyDistributionbyLossType> {
  if (!p?.result?.resultbyDistributionbyLossType) {
    return []
  }

  const byLossType = p.result.resultbyDistributionbyLossType

  return byLossType.filter(
    (x) => x.lossType === lossToggle || (lossToggle === 'Loss Ratio' && x.lossType === 'Frequency'),
  )
}

export const TeamParameterisationDataProvider = (props: PropsWithChildren) => {
  const [parameterisationData, setParameterisationData] = useState<
    ParameterisationTeamScenarioByIdResponse | undefined
  >(undefined)
  const [underlyingMarketParamData, setUnderlyingMarketParamData] = useState<ParameterisationByIdResponse[]>([])
  const [needToPollForMoreData, setNeedToPollForMoreData] = useState<ModelStatus>('FIRST_TIME_LOAD')
  const [lastUpdatedAt, setLastUpdatedAt] = useState<undefined | string>(undefined)
  const [lossToggle, setLossToggle] = useState<LossToggle>('Loss Ratio')
  const dataFilteredByDistributionType = useMemo(() => {
    return fromDistribution(parameterisationData, lossToggle)
  }, [lossToggle, parameterisationData])
  const allUniqueColumns = getSetofColumns(dataFilteredByDistributionType)

  const { currentStackedScenario } = useContext(StackedScenarioContext)

  const { progressIndicators, updateIndividualProgressItem } = useContext(ProgressContext)

  const triggerReloadUnderlyingMarketData = () => {
    if (!currentStackedScenario) {
      return
    }
    updateIndividualProgressItem('fetchingUnderlyingData', ProgressState.LOADING)

    fetchUnderlyingMarketDataForTeamParameterisationByTeamScenarioId(currentStackedScenario.id)
      .then((response) => setUnderlyingMarketParamData(response))
      .then(() => updateIndividualProgressItem('fetchingUnderlyingData', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingUnderlyingData', ProgressState.ERROR))
  }

  const triggerReloadParameterisationData = () => {
    const finishPollingStatuses: ModelStatus[] = [
      'SERVER_ERROR',
      TcsStatus.COMPLETE,
      'NEVER_RAN_PARAMETERISATION',
      TcsStatus.FAILED,
    ]
    const cancelAnotherFetch =
      finishPollingStatuses.includes(needToPollForMoreData) ||
      !currentStackedScenario ||
      progressIndicators['fetchingParameterisationData'] === ProgressState.LOADING

    if (cancelAnotherFetch) {
      return
    }

    updateIndividualProgressItem('fetchingParameterisationData', ProgressState.LOADING)

    // console.log('currentScenario.id::', currentStackedScenario!.id);
    fetchTeamParameterisationByScenarioId(currentStackedScenario!.id) //
      .then((responseFromBackend) => {
        if (responseFromBackend.modelStatus === 'FAILED') {
          setLastUpdatedAt(undefined)
          setNeedToPollForMoreData(TcsStatus.FAILED)
        }
        if (responseFromBackend.modelStatus === 'INPROGRESS') {
          setLastUpdatedAt(responseFromBackend.updatedAt)
          setNeedToPollForMoreData(TcsStatus.INPROGRESS)
        }
        if (responseFromBackend.modelStatus === 'COMPLETE' && responseFromBackend.result === null) {
          setLastUpdatedAt(undefined)
          setNeedToPollForMoreData('COMPLETE_NO_DATA')
        }
        if (responseFromBackend.modelStatus === 'COMPLETE') {
          setLastUpdatedAt(undefined)
          setNeedToPollForMoreData(TcsStatus.COMPLETE)
          setParameterisationData(responseFromBackend)
          triggerReloadUnderlyingMarketData()
        }
      })
      .then(() => updateIndividualProgressItem('fetchingParameterisationData', ProgressState.FINISHED))
      .catch((err) => {
        setLastUpdatedAt(undefined)
        if (err.message === `markets haven't been updated`) {
          setNeedToPollForMoreData('NOT_UP_TO_DATE')
          updateIndividualProgressItem('fetchingParameterisationData', ProgressState.FINISHED)
          return
        }
        if (err.status === 'Unhandled server error') {
          setNeedToPollForMoreData('SERVER_ERROR')
          updateIndividualProgressItem('fetchingParameterisationData', ProgressState.FINISHED)
          return
        }
        if (err.status === 'Fail to retrieve TCS id') {
          setNeedToPollForMoreData('NEVER_RAN_PARAMETERISATION')
          updateIndividualProgressItem('fetchingParameterisationData', ProgressState.FINISHED)
          return
        }
        updateIndividualProgressItem('fetchingParameterisationData', ProgressState.ERROR)
      })
  }

  // eslint-disable-next-line
  useEffect(() => triggerReloadParameterisationData(), [currentStackedScenario, needToPollForMoreData])
  useInterval(triggerReloadParameterisationData, REPORT_REFRESH_INTERVAL_IN_MS)
  // eslint-disable-next-line
  useEffect(() => triggerReloadUnderlyingMarketData(), [currentStackedScenario])

  return (
    <TeamParameterisationDataContext.Provider
      value={{
        parameterisationData,
        underlyingMarketParamData,
        needToPollForMoreData,
        setNeedToPollForMoreData,
        lastUpdatedAt,
        setLastUpdatedAt,
        dataFilteredByDistributionType,
        allUniqueColumns,
        lossToggle,
        setLossToggle,
      }}
    >
      {props.children}
    </TeamParameterisationDataContext.Provider>
  )
}
