import React, { SVGProps } from 'react'

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#4E566B"
        fillRule="evenodd"
        d="M9.37333333,6.01333333 L9.98666667,6.62666667 L3.94666667,12.6666667 L3.33333333,12.6666667 L3.33333333,12.0533333 L9.37333333,6.01333333 M11.7733333,2 C11.6066667,2 11.4333333,2.06666667 11.3066667,2.19333333 L10.0866667,3.41333333 L12.5866667,5.91333333 L13.8066667,4.69333333 C14.0666667,4.43333333 14.0666667,4.01333333 13.8066667,3.75333333 L12.2466667,2.19333333 C12.1133333,2.06 11.9466667,2 11.7733333,2 Z M9.37333333,4.12666667 L2,11.5 L2,14 L4.5,14 L11.8733333,6.62666667 L9.37333333,4.12666667 Z"
      />
    </svg>
  </div>
)

export default EditIcon
