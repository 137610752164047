import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export interface ExcludedPerilsResponse {
  readonly id: string
  readonly excludedPerilNames: string[]
  readonly description?: undefined | null | string
}

interface ApplicablePerilsRequest {
  applicableMarkets: Array<MarketSearchRequest>
}

interface ApplicableMarketsResponse {
  id: string
  applicableMarkets: Array<MarketSearchRequest>
  applicablePerilsMinusExcludedPerils: Array<string>
}

export interface MarketSearchRequest {
  team: string
  market: string
}

export interface ApplicablePerilsResponse {
  allMatchingPerils: string[]
}

export interface PatchPerilRequest {
  outwardScenarioId?: string
  excludedPerilNames?: string[]
  description?: undefined | null | string
}

export async function fetchExcludedPerils(outwardScenarioId: string): Promise<ExcludedPerilsResponse | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}/perils`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status === 404) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function updateExcludedPerilList(outwardScenarioId: string, patchPerilRequest: PatchPerilRequest) {
  const url = `${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}/perils`
  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(patchPerilRequest),
  })

  return await response.json()
}

export async function fetchApplicablePerils(
  searchRequest: ApplicablePerilsRequest,
): Promise<ApplicablePerilsResponse | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/perils/applicable-by-market`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(searchRequest),
  })

  if (response.status === 404) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchApplicableMarkets(outwardScenarioId: string): Promise<ApplicableMarketsResponse> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}/applicable-markets`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return response.json()
}

export async function fetchOutwardScenarioMarketOutput(outwardScenarioId: string): Promise<ApplicableMarketsResponse> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}/applicable-markets/full-output`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return response.json()
}

export async function fetchOutwardScenarioPerilsOutput(outwardScenarioId: string): Promise<ApplicableMarketsResponse> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/perils/${outwardScenarioId}/applicable-by-scenario`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return response.json()
}

export async function saveOrUpdateListOfApplicableMarkets(
  outwardScenarioId: string,
  createRequest: ApplicablePerilsRequest,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}/applicable-markets`,
    {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(createRequest),
    },
  )

  return await response.json()
}
