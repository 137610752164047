import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export interface MarketGraphDataV2 {
  y: (number | string)[]
  x?: string[]
  type?: string
  title?: string
  boxpoints?: boolean
  name: string
}

export type MarketSegmentV2 = {
  id?: string
  segmentName: string
  marketId: string
  marketPercentage: number
  marketGwp: number
  inflation: number
  rateEnvironment: string
}

export type MarketRowV2 = MarketSegmentV2 & {
  convexGwp?: number
  marketShare?: number
}

export type MarketV2 = {
  id?: string
  inflation: string
  rateEnvironment: string
  marketName: string
  year: string
  totalMarketGwp: number
  pmlScenario1In100: string
  confidenceInMarketSize: string
  accessOrCapability: string
  inAppetite: string
  addressableMarketGwp: number
  peakConvexGwp: number
  peakConvexMarketShare: string
  convexEstimatedShareOfPml: number
  previousYearGnlr: string
  previousYearGglr: string
  previousYearConvexAcquisitionRatio: string
  convexAcquisitionRatio: string
  marketGrarc: string
  marketNrarc: string
  AbeGglr: string
  uwConvexAcquisitionRatio: string
  uwMarketGrarc: string
  uwInflation: string
  uwAbeGglr: string
  graphData?: Array<MarketGraphDataV2>
  marketSegments: Array<MarketSegmentV2>
}

export async function fetchMarketV2(division: string, marketName: string, year: string): Promise<MarketV2 | undefined> {
  console.log(`Fetching market for name of '${marketName}' and year '${year}'`)
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/markets/${year}/${division}/${marketName}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status >= 400) {
    return undefined
  }

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return undefined
  }

  return responseObject
}

export async function fetchDefaultMarketV2(
  division: string,
  marketName: string,
  year: string,
): Promise<MarketV2 | undefined> {
  console.log(`Fetching default market for name of '${marketName}' and year '${year}'`)
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/markets/default/${year}/${division}/${marketName}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )
  if (response.status >= 400) {
    return undefined
  }

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return undefined
  }

  return responseObject
}

export async function createMarketV2(
  division: string | null | undefined,
  marketName: string | null | undefined,
  year: string | number | null | undefined,
  createMarketRequest: MarketV2,
): Promise<MarketV2 | undefined> {
  console.log(`Creating market for name of '${marketName}' and year '${year}'`)
  console.log(createMarketRequest)
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/markets/${division}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createMarketRequest),
  })

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return undefined
  }

  return responseObject
}

export async function patchMarketV2(
  division: string | null | undefined,
  marketName: string | null | undefined,
  year: string | number | null | undefined,
  patchMarketRequest: MarketV2,
): Promise<MarketV2 | undefined> {
  console.log(`Patching market for name of '${marketName}' and year '${year}'`)
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/markets/${division}`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(patchMarketRequest),
  })

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return undefined
  }

  return responseObject
}
