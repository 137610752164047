import qs from 'qs'

import { getCurrentConfigSettings } from '../utils/config'
import { ScenarioStatus } from '../utils/status/ScenarioStatus'
import { deleteAllRules } from './outward-rules'
import { getAccessToken } from './teams'

export interface OutwardScenario {
  id: string
  name: string
  version: string
  programme: string
  treaty: string
  layer: string
  year: string
  inwardScenarioId: string
  updatedAt: string
  canWrite: boolean
  status?: ScenarioStatus
  description?: string
}

export interface CreateOutwardScenarioRequest {
  name: string
  // description?: string | undefined | null;
  inwardScenarioId: string
}

export interface UpdateOutwardScenarioRequest {
  name?: string
  description?: string
  status?: ScenarioStatus
  inwardScenarioId?: string
}

export async function fetchAllOutwardScenarios(
  programme: string,
  treaty: string,
  layer: string | undefined,
  version: string,
  year: string,
): Promise<OutwardScenario[]> {
  const queryContent: Record<string, string | null | undefined> = { year, version, programme, treaty, layer }
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/outward-scenarios?${qs.stringify(queryContent, { skipNulls: true })}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

export async function fetchOutwardScenarioById(scenarioId: string): Promise<OutwardScenario | undefined> {
  if (scenarioId === undefined) {
    return undefined
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function patchOutwardScenario(scenarioId: string, patchRequest: UpdateOutwardScenarioRequest) {
  if (scenarioId === undefined) {
    return undefined
  }

  try {
    const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${scenarioId}`, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchRequest),
    })

    if (response.status !== 200) {
      return Promise.resolve(undefined)
    }

    return await response.json()
  } catch (err: unknown) {
    console.error(err)
  }
}

export async function createOutwardScenario(
  programme: string,
  treaty: string,
  layer: string,
  version: string,
  year: string,
  csr: CreateOutwardScenarioRequest,
) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ programme, treaty, layer, version, year, ...csr }),
  })

  return (await response.json()).id
}

export async function deleteOutwardScenario(outwardScenarioId: string) {
  await deleteAllRules(outwardScenarioId)
  await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/${outwardScenarioId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  return Promise.resolve()
}

export async function duplicateOutwardVersion(year: string, oldVersion: string, newVersion: string) {
  return await fetch(`${getCurrentConfigSettings().apiHost}/outward-scenarios/duplicate`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ year, oldVersion, newVersion }),
  })
}
