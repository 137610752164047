/**
 * Compares two objects for equality. Comparison is based on equality of string representation of objects.
 *
 * Not suitable for cases where objects have different key orders or contain non-serializable values (like functions, undefined, or Symbol).
 *
 * Fails in cases where objects have the same data but different key orders.
 * Cannot handle non-serializable values.
 *
 * <h3><b>Use with caution: This function is not suitable for all types of comparisons.</b></h3>
 *
 * <b>For performance</b>: <i>JSON.stringify() is faster for simple and shallow objects but may not be accurate for all types of comparisons.</i>
 * <b>For accuracy and complex objects</b>: <i>Lodash's _.isEqual() is more reliable (at the time of writing this, lodash is not part of this project) but generally slower.</i>
 * @param a first object to compare
 * @param b second object to compare
 * @returns true if the objects are equal, false otherwise
 */
export const areEqual = <T extends object>(a: T, b: T): boolean => JSON.stringify(a) === JSON.stringify(b)
