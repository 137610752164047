import { Box, Divider, Text } from '@chakra-ui/react'
import { ScenarioStatus } from 'src/utils/status/ScenarioStatus'

function formatSignOffDate(date: string): string {
  const dateObj = new Date(date)
  const year = dateObj.getFullYear()
  const month = dateObj.getMonth() + 1
  const day = dateObj.getDate()

  const hour = dateObj.getHours()
  const minute = dateObj.getMinutes()

  const monthStr = month < 10 ? `0${month}` : `${month}`
  const dayStr = day < 10 ? `0${day}` : `${day}`
  const hourStr = hour < 10 ? `0${hour}` : `${hour}`
  const minuteStr = minute < 10 ? `0${minute}` : `${minute}`
  return `${year}-${monthStr}-${dayStr} at ${hourStr}:${minuteStr}`
}

function formatUser(userEmail: string): string {
  const user = userEmail.split('@')[0]
  const userParts = user.split('.')
  const userPartsCapitalized = userParts.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
  return userPartsCapitalized.join(' ')
}

type SignOffSummaryProps = {
  userEmail: string | undefined | null
  signOffDate: string | undefined | null
  status: ScenarioStatus | undefined
}

export default function SignOffSummary(props: SignOffSummaryProps): JSX.Element {
  const { userEmail, signOffDate, status } = props

  if (status === undefined || status === ScenarioStatus.IN_PROGRESS) {
    return <></>
  }

  if (!userEmail) {
    return <></>
  }

  if (!signOffDate) {
    return <></>
  }

  return (
    <Box
      mb="6"
      w="full"
    >
      <Text
        fontSize={'14px'}
        fontWeight={'bold'}
      >
        Signed Off by:
      </Text>
      <Text
        fontSize={'14px'}
        pb={'10px'}
      >
        {formatUser(userEmail)} on {formatSignOffDate(signOffDate)}
      </Text>
      <Divider />
    </Box>
  )
}
