export interface WayfinderRawPath {
  path: string
  displayName: string
}

export const ScenarioRawPaths: WayfinderRawPath[] = [
  {
    path: `/scenarios/:scenarioId/summary`,
    displayName: 'Summary',
  },
  {
    path: `/scenarios/:scenarioId/market`,
    displayName: 'Market',
  },
  {
    path: `/scenarios/:scenarioId/renewal`,
    displayName: 'Renewal',
  },
  {
    path: `/scenarios/:scenarioId/new`,
    displayName: 'New',
  },
  {
    path: `/scenarios/:scenarioId/parameterisation`,
    displayName: 'Parameterisation',
  },
  {
    path: `/scenarios/:scenarioId/portfolio`,
    displayName: 'Portfolio',
  },
  {
    path: `/scenarios/:scenarioId/exposures`,
    displayName: 'Exposures',
  },
  {
    path: `/scenarios/:scenarioId/notes`,
    displayName: 'Notes',
  },
]

export let StackedScenarioRawPaths: WayfinderRawPath[] = [
  {
    path: `/stacked-scenarios/:stackedScenarioId/summary`,
    displayName: 'Summary',
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/market`,
    displayName: 'Market',
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/portfolio`,
    displayName: 'Portfolio',
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/notes`,
    displayName: 'Notes',
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/parameterisation`,
    displayName: 'Parameterisation',
  },
  {
    path: `/stacked-scenarios/:stackedScenarioId/exposures`,
    displayName: 'Exposures',
  },
]
