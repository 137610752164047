import { Th, Thead, Tr } from '@chakra-ui/react'

type TableHeadProps = {
  columns: string[]
}

export const TableHead = (props: TableHeadProps) => {
  const { columns } = props

  return (
    <Thead backgroundColor={'rgba(0,0,0,0.05)'}>
      <Tr>
        <Th></Th>
        {columns.map((col, index) => {
          return <Th key={'col' + index.toString()}>{col}</Th>
        })}
      </Tr>
    </Thead>
  )
}
