import React, { SVGProps } from 'react'

const CompareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M10.1733333,1.83333333 C10.6866667,1.84666667 11.1733333,2.15333333 11.38,2.65333333 L11.38,2.65333333 L14.6866667,10.6333333 C14.9666667,11.3066667 14.64,12.0866667 13.96,12.3666667 L13.96,12.3666667 L9.05333333,14.4 C8.88666667,14.4666667 8.71333333,14.5 8.54,14.5 C8.02,14.4933333 7.52666667,14.1866667 7.32,13.68 L7.32,13.68 L4.01333333,5.7 C3.94,5.52666667 3.90666667,5.34666667 3.91333333,5.16666667 C3.92666667,4.65333333 4.23333333,4.17333333 4.73333333,3.96666667 L4.73333333,3.96666667 L9.64666667,1.93333333 C9.82,1.86 10,1.83333333 10.1733333,1.83333333 Z M3.92,8.94 L6.22,14.5 L5.25333333,14.5 C4.52,14.5 3.92,13.9 3.92,13.1666667 L3.92,13.1666667 L3.92,8.94 Z M2.58,7.45333333 L2.58,13.4733333 L1.68666667,13.1 C1.01333333,12.82 0.686666667,12.04 0.96,11.36 L0.96,11.36 L2.58,7.45333333 Z M10.1533333,3.16666667 L10.1466667,3.16666667 L5.24666667,5.19333333 L8.55333333,13.1666667 L13.4533333,11.1333333 L10.1533333,3.16666667 Z M7.33333333,5.33333333 C7.70152317,5.33333333 8,5.63181017 8,6 C8,6.36818983 7.70152317,6.66666667 7.33333333,6.66666667 C6.9651435,6.66666667 6.66666667,6.36818983 6.66666667,6 C6.66666667,5.63181017 6.9651435,5.33333333 7.33333333,5.33333333 Z"
        id="Combined-Shape"
        fill="#4E566B"
      />
    </g>
  </svg>
)

export default CompareIcon
