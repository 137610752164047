import { ExposuresReportStatus } from '../pages/exposures/ReportStatus'
import { DynamicTableState } from '../components/dynamic-table/DynamicTable'
import { ReportMeta } from '../pages/exposures-list/ExposuresListState'
import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'
import { WayfinderDataType } from './TypeOfData'
import { ExistingReportPayload, ReportPayload } from 'src/pages/exposures/ExposureReport'

const DEFAULT_EXPOSURES_GROUPING_COLUMN = 'peril'
const GROUPING_FOR_MARKET_SHARE = 'zoneName'

export interface MarketShareValues {
  marketShare: string
  marketShareWithRetention: string
  contractLoss: string
  contractLossWithRetention: string
  peril: string
}

export interface AvailableExposureFilters {
  zoneNames: string[]
  bandNames: string[]
}

export interface MarketShareValuesGroupedByColumn {
  results: Record<string, MarketShareValues>
}

export interface AverageAnnualLossTableRowData {
  fieldThatWasSplitByValue: string
  oneIn200: string
  adjustedOneIn200: string
  oneIn250: string
  adjustedOneIn250: string
  averageAnnualLoss: string
  adjustedAverageAnnualLoss: string
  averageLimit: string
  adjustedAverageLimit: string
  adjustedGwp: string
  adjustedPercentageOfPortfolio: string
}

export interface AverageAnnualLossTableData {
  fieldThatWasSplitBy: string
  rows: AverageAnnualLossTableRowData[]
}

export enum ExposuresContextPath {
  STACKED = 'stacked-scenarios',
  MARKET = 'scenarios',
}

export async function fetchMarketShareValues(
  id: string,
  bandName: string,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<MarketShareValues | undefined> {
  if (id === undefined) {
    return undefined
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/${context}/${id}/exposures/market-share/by-band/${bandName}`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchMarketShareValuesGroupedByColumn(
  id: string,
  bandName: string,
  groupingColumn: string | undefined,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<MarketShareValuesGroupedByColumn | undefined> {
  if (id === undefined) {
    return undefined
  }

  if (!groupingColumn) {
    groupingColumn = GROUPING_FOR_MARKET_SHARE
  }

  const response = await fetch(
    `${
      getCurrentConfigSettings().apiHost
    }/${context}/${id}/exposures/market-share/by-band-and-then-grouped-by/${bandName}/${groupingColumn}`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchFullMarketShareData(
  id: string,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<Record<string, string>[] | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/${context}/${id}/exposures/market-share/full`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchFullAALData(
  id: string,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<Record<string, string>[] | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/${context}/${id}/exposures/average-annual-loss/full`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchAvailableExposureFilters(
  id: string,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<AvailableExposureFilters | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/${context}/${id}/exposures/market-share/available-terms`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

export async function fetchAverageAnnualLossTableData(
  id: string,
  groupBy: string | undefined,
  context: ExposuresContextPath = ExposuresContextPath.MARKET,
): Promise<AverageAnnualLossTableData | undefined> {
  const queryParams = new URLSearchParams({ groupBy: groupBy ? groupBy : DEFAULT_EXPOSURES_GROUPING_COLUMN })

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/${context}/${id}/exposures/average-annual-loss-table-data?${queryParams}`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status !== 201) {
    return Promise.resolve(undefined)
  }

  return await response.json()
}

// --------------------------------------------------------------

export type PostExposureResponse = {
  id: string
}

export async function updateReport(id: string, report: ExistingReportPayload): Promise<PostExposureResponse> {
  const request: RequestInit = {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(report),
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${id}`, request)

  if (!response.ok || response.status >= 400) {
    return Promise.reject(`PUT Exposures Report responded with a non-200 status: expected 200, got ${response.status}`)
  }

  return await response.json()
}

export async function createReport(newExposuresReport: ReportPayload<'new'>): Promise<PostExposureResponse> {
  const request: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(newExposuresReport),
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/reports`, request)

  if (!response.ok || response.status >= 400) {
    return Promise.reject(`POST Exposures State responded with a non-200 status: expected 200, got ${response.status}`)
  }

  return await response.json()
}

export async function getExposureReportsFromScenarioId(scenarioId: string): Promise<ReportMeta[]> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/scenarios/${scenarioId}`,
    request,
  )
  if (!response.ok || response.status >= 400) {
    return Promise.reject(`GET Exposure Reports responded with a non-200 status: expected 200, got ${response.status}`)
  }

  return response.json()
}

export async function getTableState(reportId: string): Promise<DynamicTableState<number>> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${reportId}/tableState`,
    request,
  )
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Table State responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return response.json()
}

export type ReportMetaData = {
  reportName: string
  reportStatus: ExposuresReportStatus
  reportOutdated: boolean
  reportFinal: boolean
}

export async function getReportMetaData(reportId: string): Promise<ReportMetaData> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${reportId}/meta`, request)
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Report Meta Data responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return response.json()
}

export type OutputType = keyof typeof WayfinderDataType

export function isOutputType(output: string): output is OutputType {
  const values: readonly string[] = Object.values(WayfinderDataType)
  return values.includes(output)
}

type Percentage = number

export type ExposureGrowth = {
  outputType: OutputType[]
  convexGWPWithBasicRetention: number[]
  convexGWP: number[]
  riskPremiumChange: Percentage[]
  grossRiskAdjustedRateChange: Percentage[]
  gwpChange: Percentage[]
}

export type NewTableStateRequest = {
  exposureGrowth: ExposureGrowth
  market: string
}

export async function getNewTableState(
  scenarioId: string,
  body: NewTableStateRequest,
): Promise<DynamicTableState<number>> {
  const request: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(body),
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/tableState/${scenarioId}`, request)
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET New Exposures Report Table State responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return response.json()
}

export async function getNewReportMetaData(): Promise<ReportMetaData> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/reports/meta`, request)
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET New Exposures Report Meta Data responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return response.json()
}

export async function sendReportToSnowflake(id: string) {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${id}/submit-to-snowflake`,
    request,
  )

  if (!response.ok || response.status >= 400) {
    return Promise.reject(`GET Exposures Report responded with a non-200 status: expected 200, got ${response.status}`)
  }

  return await response.json()
}

export type ReportsOfTargetMarket = {
  id: string
  name: string
  status: ExposuresReportStatus
  tmv: string
}

export async function noPendingReportsInTargetMarket(tmv: string): Promise<boolean> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }
  const encodedTmv = encodeURIComponent(tmv)
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${encodedTmv}`, request)
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Reports for ${tmv} responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return await response.json()
}

export async function importCalculation(id: string): Promise<boolean> {
  const request: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${id}/overnight-ingestion`,
    request,
  )

  if (!response.ok || response.status >= 400) {
    return false
  }
  return true
}
