import React, { useContext } from 'react'
import { ParameterisationByIdResponse, ResultbyDistributionbyLossType } from '../../backend/parameterisation'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { GridContainer } from '../css-grid/GridContainer'
import { CssGridItem } from '../css-grid/CssGridRows'
import { formatForDisplay, numericValueIsDefined, ONE_HUNDRED } from '../../utils/numbers'
import Big from 'big.js'

export type ParameterizationRow = Record<string, ResultbyDistributionbyLossType>

export const NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW = 2

export const calculateHowToGetValueForMarketData =
  (underlyingMarketData: Array<null | ParameterisationByIdResponse>) => (marketName: string, columnName: string) => {
    const currentMarketResults = underlyingMarketData?.find(
      (result) => result?.input?.inputs?.marketNameSelected === marketName,
    )

    const result = currentMarketResults?.input?.inputs?.marketInputByMarketInput?.find(
      (input) => input.type === columnName,
    )

    return result?.value
  }

export function formatValueReadyForDisplay(columnName: string, value: string | number) {
  if (columnName === 'GWP' || columnName === 'Max' || columnName === 'Threshold') {
    return '$' + formatForDisplay(value)
  }

  if (columnName === 'LR_Total' || columnName === 'LR_Attritional' || columnName === 'LR_PerilTotal') {
    if (numericValueIsDefined(value)) {
      return ONE_HUNDRED.mul(value) + '%'
    } else {
      return '0%'
    }
  }

  return value
}

export function displayNameForColumn(columnName: string) {
  if (columnName === 'LR_Total') {
    return 'ABE GGLR'
  } else if (columnName === 'LR_Attritional') {
    return 'Attritional Loss Ratio'
  } else if (columnName === 'SRFrequency_Mean') {
    return 'Expected Number of Single-risk Losses'
  } else if (columnName === 'Max') {
    return 'Max Line'
  } else if (columnName === 'Threshold') {
    return 'Max Threshold'
  } else if (columnName === 'Attritional dependency selection') {
    return 'Attritional Dependency Selection'
  } else if (columnName === 'LR_PerilTotal') {
    return 'Clash Loss Ratio'
  }

  return columnName
}

function TeamParameterisationMarketSummaryTable(): JSX.Element {
  const { underlyingMarketParamData } = useContext(TeamParameterisationDataContext)

  const allColumns = underlyingMarketParamData
    .flatMap((item) => item?.input?.inputs?.marketInputByMarketInput || [])
    .map((item) => item.type)
    .filter((item) => Boolean(item))

  const allUniqueColumns = [...Array.from(new Set(allColumns)), 'Attritional dependency selection']
  const indexOfTheLastLossRatio = allUniqueColumns.findIndex((item) => item.includes('LR'))
  allUniqueColumns.splice(indexOfTheLastLossRatio + 3, 0, 'Single-risk Loss Ratio')

  const allUniqueRows = underlyingMarketParamData
    .map((item) => item?.input?.inputs?.marketNameSelected)
    .filter((item) => Boolean(item))

  const getValueFromMarketData = calculateHowToGetValueForMarketData(underlyingMarketParamData)

  const getValueToDisplay = (marketName: string, columnName: string) => {
    if (columnName === 'Single-risk Loss Ratio') {
      const totalLossRatio = getValueFromMarketData(marketName, 'LR_Total')
      const attritionalLossRatio = getValueFromMarketData(marketName, 'LR_Attritional')
      const clashLossRatio = getValueFromMarketData(marketName, 'LR_PerilTotal')
      return (
        new Big(totalLossRatio || 0)
          .minus(attritionalLossRatio || 0)
          .minus(clashLossRatio || 0)
          .mul(ONE_HUNDRED)
          .toFixed(2) + '%'
      )
    } else {
      return getValueFromMarketData(marketName, columnName)
    }
  }

  return (
    <div className="ParameterisationTableContainer">
      <GridContainer>
        <CssGridItem
          columnNumber={1}
          rowNumber={1}
        >
          Stat
        </CssGridItem>
        {allUniqueColumns.map((columnName, columnIndex) => {
          return (
            <CssGridItem
              columnNumber={columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              rowNumber={1}
              key={`column-header-${columnName}`}
            >
              {displayNameForColumn(columnName)}
            </CssGridItem>
          )
        })}
        {allUniqueRows.map((rowName, rowIndex) => (
          <>
            <CssGridItem
              columnNumber={1}
              rowNumber={rowIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              key={`row-header-${rowName}`}
            >
              {rowName}
            </CssGridItem>
            {allUniqueColumns.map((columnName, columnIndex) => (
              <CssGridItem
                columnNumber={columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
                rowNumber={rowIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
                key={`row-header-${columnName}-${rowName}`}
              >
                {rowName && formatValueReadyForDisplay(columnName, getValueToDisplay(rowName, columnName) || '')}
              </CssGridItem>
            ))}
            <CssGridItem
              columnNumber={allUniqueColumns.length - 1 + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              rowNumber={rowIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              key={`hacky-dependency-header-${rowName}`}
            >
              {
                underlyingMarketParamData
                  ?.find((result) => result?.input?.inputs?.marketNameSelected === rowName)
                  ?.input?.inputs?.marketInputDependency?.find((item) => Boolean(item))?.value
              }
            </CssGridItem>
          </>
        ))}
      </GridContainer>
    </div>
  )
}

export default TeamParameterisationMarketSummaryTable
