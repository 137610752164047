import React from 'react'
import Big from 'big.js'
import CountUp from 'react-countup'

import './HeadingWithTitle.scss'

import { css } from '../../utils/css'
import { formatForAnimation, numericValueIsDefined } from '../../utils/numbers'
import { calculateNumberOfDecimalsToDisplay } from '../kpi-card/KpiCard'
import { usePrevious } from '../../utils/hooks/usePrevious'

export type HeadingWithTitleProps = React.PropsWithChildren<{
  title?: string
  id?: string
  value?: Big | number
  className?: string | string[]
  prefix?: string
  suffix?: string
  decimals?: number
  omitFormattingValue?: boolean
}>

export function HeadingWithTitle(props: HeadingWithTitleProps) {
  const currentValue = typeof props.value === 'number' ? new Big(props.value) : props.value
  const previousValue = usePrevious(currentValue)

  const animationStartValue = numericValueIsDefined(previousValue)
    ? props.omitFormattingValue
      ? previousValue!.toNumber()
      : formatForAnimation(previousValue)
    : 0

  const animationEndValue =
    currentValue !== undefined
      ? props.omitFormattingValue
        ? currentValue.toNumber()
        : formatForAnimation(currentValue)
      : undefined

  return (
    <div
      className={css('HeadingWithTitle', props.className)}
      title={currentValue?.toString()}
    >
      <div
        className="Heading"
        id={props.id}
        data-testid={props.id}
      >
        {props.title && <p className="Title">{props.title}</p>}
        {currentValue !== undefined ? (
          <CountUp
            duration={0.75}
            decimals={calculateNumberOfDecimalsToDisplay(animationEndValue!, props.decimals)}
            start={animationStartValue}
            end={animationEndValue!}
            prefix={props.prefix}
            suffix={props.suffix}
          />
        ) : (
          '-'
        )}
      </div>
    </div>
  )
}

export default HeadingWithTitle
