import { createContext, PropsWithChildren, useEffect } from 'react'
import { UrlUpdateType, useQueryParam } from 'use-query-params'
import { StringParam } from 'serialize-query-params'

// re-defined from useQueryParam in `use-query-params`
type NewValueType<D> = D | ((latestValue: D) => D)
type SetQuery<T> = (newValue: NewValueType<T>, updateType?: UrlUpdateType | undefined) => void
export type QueryState<T> = [undefined | null | T, SetQuery<undefined | null | T>]

export type YearQueryState<T> = [undefined | T, SetQuery<undefined | T>]

export function initialQuery<T>(): QueryState<T> {
  return [undefined, () => undefined]
}

export type Business = 'PORTFOLIO' | 'CLIENT' | 'CUSTOM PORTFOLIO'

export function initialYearQuery<T>(): YearQueryState<T> {
  return [undefined, () => undefined]
}

export type AppQuery = {
  yearQuery: YearQueryState<string>
  portfoliolevelQuery: QueryState<string>
  divisionQuery: QueryState<string>
  teamQuery: QueryState<string>
  marketQuery: QueryState<string>
  actualsQuery: QueryState<string>
  businessQuery: QueryState<string>
}

export const AppQueryContext = createContext<AppQuery>({
  yearQuery: initialYearQuery(),
  portfoliolevelQuery: initialQuery(),
  divisionQuery: initialQuery(),
  teamQuery: initialQuery(),
  marketQuery: initialQuery(),
  actualsQuery: initialQuery(),
  businessQuery: initialQuery(),
})

type Props = PropsWithChildren<{}>

const StringParamUndefinedCoersionParam = {
  encode: (value: string | undefined): string | undefined => {
    if (Array.isArray(value)) {
      return undefined
    }
    return value ?? undefined
  },
  decode: (value: string | (string | null)[] | null | undefined): string | undefined => {
    if (Array.isArray(value)) {
      return undefined
    }
    return value ?? undefined
  },
}

export function AppQueryProvider(props: Props): JSX.Element {
  const yearQuery = useQueryParam('year', StringParamUndefinedCoersionParam)
  const portfoliolevelQuery = useQueryParam('portfoliolevel', StringParam)
  const divisionQuery = useQueryParam('division', StringParam)
  const teamQuery = useQueryParam('team', StringParam)
  const marketQuery = useQueryParam('market', StringParam)
  const actualsQuery = useQueryParam('actuals', StringParam)
  const businessQuery = useQueryParam('business', StringParam)

  const [yearFromQuery, setYear] = yearQuery
  const [businessFromQuery, setBusiness] = businessQuery

  useEffect(() => {
    if (!yearFromQuery) {
      const currentYear = new Date().getFullYear().toString()
      console.warn(
        `AppQueryProvider - warning: No value for year set in query params. Setting year as the current year: ${currentYear}`,
      )
      setYear(currentYear)
    }
  }, [yearFromQuery])

  useEffect(() => {
    if (!businessFromQuery) {
      const currentBusiness: Business = 'PORTFOLIO'
      console.warn(
        `AppQueryProvider - warning: No value for business set in query params. Setting business as the current business: ${currentBusiness}`,
      )
      setBusiness(currentBusiness)
    }
  }, [businessFromQuery])

  return (
    <AppQueryContext.Provider
      value={{
        yearQuery,
        portfoliolevelQuery,
        divisionQuery,
        teamQuery,
        marketQuery,
        actualsQuery,
        businessQuery,
      }}
      children={props.children}
    />
  )
}
