import { Reducer } from 'react'
import { loadingType } from './ExposuresGraphState'
import { ExceedanceProbabilityAALGraph } from '../../../backend/exposures-V2'

export type ExposuresLineGraphMarkersState = {
  status: loadingType
  data: ExceedanceProbabilityAALGraph[]
  message: string
}

export type ExposuresLineGraphMarkersAction =
  | { type: 'setError'; payload: { message: string; error: string } }
  | { type: 'setLoading' }
  | { type: 'setLoaded'; payload: { data: ExceedanceProbabilityAALGraph[] } }

export const createLineGraphMarkersInitialState = (): ExposuresLineGraphMarkersState => {
  return {
    status: 'loading',
    data: [],
    message: '',
  }
}

export const lineGraphMarkersReducer: Reducer<ExposuresLineGraphMarkersState, ExposuresLineGraphMarkersAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'setLoading': {
      return { ...state, status: 'loading', data: [], message: '' }
    }
    case 'setError': {
      return { ...state, status: 'error', data: [], message: action.payload.error }
    }
    case 'setLoaded': {
      if (state.status === 'error') {
        return state
      }
      return { ...state, status: 'loaded', data: action.payload.data, message: '' }
    }
  }
}
