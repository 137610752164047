import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { StringParam, useQueryParam } from 'use-query-params'

import { fetchAllOutwardScenarios, OutwardScenario } from '../../backend/outward-scenarios'
import { groupListOfObjsBy, mapValues } from '../../utils/lists'
import { OutwardScenarioContext } from './OutwardScenarioProvider'
import { useIsMounted } from '../../utils/mounting'
import { AppQueryContext } from '../AppQueryProvider'

export const AllOutwardScenarioContext = createContext<AllOutwardScenarioContextContainer>({
  listOfOutwardScenarios: [],
  triggerReloadAllOutwardScenario: () => void 0,
  defaultOptionsForCreatingStackedScenario: {},
})

export interface AllOutwardScenarioContextContainer {
  listOfOutwardScenarios: OutwardScenario[]
  triggerReloadAllOutwardScenario: () => void
  defaultOptionsForCreatingStackedScenario: { [p: string]: any } | undefined
}

export const getDefaultOptionsForCreatingStackedScenario = (outwardScenarios: OutwardScenario[]) => {
  if (outwardScenarios.length >= 1) {
    const scenariosGroupedByLayer = groupListOfObjsBy(outwardScenarios, 'layer')
    const sortScenariosByUpdatedDate = mapValues(scenariosGroupedByLayer, (scenarios) =>
      scenarios.sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt)),
    )
    return mapValues(sortScenariosByUpdatedDate, (scenarios) => scenarios[0]?.id)
  }
}

const AllOutwardScenarioProvider = (props: PropsWithChildren) => {
  const [programmeFromQuery] = useQueryParam('programme', StringParam)
  const [treatyFromQuery] = useQueryParam('treaty', StringParam)
  const [versionFromQuery] = useQueryParam('version', StringParam)

  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery

  const currentYear = currentOutwardScenario?.year || yearFromQuery
  const currentProgramme = currentOutwardScenario ? currentOutwardScenario?.programme : programmeFromQuery
  const currentTreaty = currentOutwardScenario ? currentOutwardScenario?.treaty : treatyFromQuery
  const currentVersion = currentOutwardScenario ? currentOutwardScenario?.version : versionFromQuery

  const [currentOutwardScenarios, setOutwardScenarios] = useState<OutwardScenario[]>([])

  const isMounted = useIsMounted()

  const triggerReloadAllOutwardScenario = () => {
    updateIndividualProgressItem('fetchingAllOutwardScenario', ProgressState.LOADING)
    fetchAllOutwardScenarios(currentProgramme!, currentTreaty!, undefined, currentVersion!, currentYear!)
      .then((x) => {
        if (!isMounted()) return null
        setOutwardScenarios(x)
      })
      .then(() => updateIndividualProgressItem('fetchingAllOutwardScenario', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingAllOutwardScenario', ProgressState.ERROR))
  }

  const defaultOptionsForCreatingStackedScenario = useMemo(
    () => getDefaultOptionsForCreatingStackedScenario(currentOutwardScenarios),
    [currentOutwardScenarios],
  )

  useEffect(triggerReloadAllOutwardScenario, [
    currentProgramme,
    currentTreaty,
    currentVersion,
    currentYear,
    updateIndividualProgressItem,
    isMounted,
  ])

  return (
    <AllOutwardScenarioContext.Provider
      value={{
        listOfOutwardScenarios: currentOutwardScenarios,
        triggerReloadAllOutwardScenario,
        defaultOptionsForCreatingStackedScenario,
      }}
    >
      {props.children}
    </AllOutwardScenarioContext.Provider>
  )
}

export default AllOutwardScenarioProvider
