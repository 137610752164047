import {
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { useState, ChangeEvent, useEffect, memo, useRef, RefObject, useCallback } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import { DynamicContentEditorProps } from '../portfolio-splits-table/types'
import { sanitizeId } from '../../utils/ids'

type MultiSelectDropdownMenuProps = DynamicContentEditorProps

export const MultiSelectDropdownMenu = memo((props: MultiSelectDropdownMenuProps) => {
  const { options, onSelect, onUnselect, maxSelections, shouldReset } = props
  const [filteredOptions, setFilteredOptions] = useState<MultiSelectDropdownMenuProps['options']>(options)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const numberOfSelectedItems = options.filter((opt) => opt.checked).length
  const searchInputRef = useSearchInputReFocus([searchTerm])

  const handleSelect = (item: MultiSelectDropdownMenuProps['options'][number]) => {
    if (item.checked) {
      onUnselect(item)
    } else if (numberOfSelectedItems < maxSelections) {
      onSelect(item)
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const resetMenu: (shouldReset: boolean) => void = useCallback(
    (shouldReset) => {
      if (shouldReset) {
        setFilteredOptions(options)
        setSearchTerm('')
      }
    },
    [options],
  )

  useEffect(() => {
    resetMenu(shouldReset)
  }, [shouldReset])

  useEffect(() => {
    // FIXME
    //  For some reason, [{ label: 'Label1', checked: true }, { label: 'Label2', checked: false }].filter(...) would return
    //  [{ label: 'Label1', checked: false }, { label: 'Label2', checked: false }]
    //  instead of
    //  [{ label: 'Label1', checked: true }, { label: 'Label2', checked: false }]
    //  when first rendering the component after coming back form the other radio button.
    //  For this reason, as a workaround, we are filtering the options only if searchTerm is different from ''.
    const newFilteredOptions =
      searchTerm === ''
        ? options
        : options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
    setFilteredOptions(newFilteredOptions)
  }, [searchTerm])

  // TODO WAY-1557 MenuList makes the page higher than we want it in certain cases, try to fix it if 1556 is way faster than originally estimated
  return (
    <Menu
      closeOnSelect={false}
      placement="bottom"
      onClose={() => resetMenu(true)}
    >
      <MenuButton
        as={Button}
        variant="outline"
        textColor={'#3182CE'}
        borderColor={'#3182CE'}
        zIndex={500}
        size={'sm'}
        fontSize={'sm'}
      >
        {numberOfSelectedItems > 0 ? 'Edit' : 'Add'}
      </MenuButton>
      <Portal>
        <MenuList
          minWidth="200px"
          maxWidth="300px"
          zIndex={500}
          maxHeight="350px"
          overflowY="scroll"
        >
          <InputGroup
            padding={'0.5rem'}
            position={'relative'}
          >
            <Input
              data-testid={'multiSelect-dropdownSearch'}
              placeholder="Search..."
              onChange={handleSearch}
              ref={searchInputRef}
              value={searchTerm}
            />
            <InputRightElement padding={'1.8rem'}>
              <SearchIcon color={'gray'} />
            </InputRightElement>
          </InputGroup>

          {filteredOptions.map((option, index) => (
            <MenuItem
              key={index}
              id={sanitizeId(`${option.label}`)}
              data-testid={sanitizeId(`${option.label}`)}
            >
              <Checkbox
                colorScheme="blue"
                isChecked={option.checked}
                disabled={numberOfSelectedItems >= maxSelections && !option.checked}
                onChange={() => {
                  handleSelect(option)
                }}
              >
                {option.label}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
})

const useSearchInputReFocus: (dependencies: any[]) => RefObject<HTMLInputElement> = (dependencies) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // trick to refocus search input. Keeping focus nor refocusing did work with 'ordinary' approaches
    const timer = setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus()
      }
    }, 0)

    return () => clearTimeout(timer)
  }, dependencies)

  return searchInputRef
}
