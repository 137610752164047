import { useContext } from 'react'
import './NewClientItemCard.scss'
import { css } from '../../utils/css'
import ReactSwitch from 'react-switch'
import DraggableCard from '../draggable-card/DraggableCard'
import LockedIcon from '../icons/LockedIcon'
import EditIcon from '../icons/EditIcon'
import DeleteIcon from '../icons/DeleteIcon'
import { ACTUALS_ADJUSTMENT_ID, OVERRIDDEN_BY_ACTUALS } from '../../backend/calculate-with-actuals'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { NewClientItemsContext } from '../../providers/NewClientItemsProvider'
import { deleteNewClientItem, NewClient, patchNewClientItem } from '../../backend/new-clients'
import HeadingWithTitle from '../heading-with-title/HeadingWithTitle'
import { ONE_HUNDRED, suffixForValue } from '../../utils/numbers'
import { MAX_LENGTH_OF_SHORT_STRINGS, trimStringIfTooLong } from '../../utils/strings'
import TextWithTitle from '../heading-with-title/TextWithTitle'
import { formatInceptionYearForDisplay } from '../renewal-graph/gwp-bar-chart/inception-month-graph'
import {
  calculateCurrentAdjustmentIds,
  calculateItemsLowerThanThis,
  calculatePercentageOfOriginalGwpAdjustedBy,
} from '../adjustment-card/ProgressBar'
import { TOTAL_ADJUSTED_GWP_COLUMN, TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN } from '../../backend/calculate-kpis'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { PseudoPortfolioContext } from '../../providers/NewPseudoPortfolioItemsProvider'
import { sanitizeId } from 'src/utils/ids'
import { calculateDescription } from '../adjustment-card/utils'

export type NewClientItemCardProps = {
  index: number
  originalItem: NewClient
  scenarioId: string
  onDrop?: (hoverIndex: number, dropIndex: number) => void
  onHover?: (hoverIndex: number, dropIndex: number) => void
  canDrag: boolean
  numberOfItems?: number
  onTriggerEditItem?: (id: string) => void
  uuid?: string
  hasErrored: boolean
  hideCreateAndEdit?: boolean
  id?: string
}

export function NewClientItemCard(props: NewClientItemCardProps) {
  const { newDataInputs } = useContext(PseudoPortfolioContext)
  const { newClients, triggerReloadNewClients } = useContext(NewClientItemsContext)
  const { fullNewKpis } = useContext(FullScenarioDataContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { isAdmin } = useContext(IsAdminContext)

  const actualDescription = calculateDescription(props.originalItem.description, props.originalItem.id)

  const idsOfCurrentAdjustment = calculateCurrentAdjustmentIds(
    props.originalItem.id,
    [...newClients, ...(newDataInputs || [])] || [],
  )

  const percentageAdjustedByThisAdjustment = calculatePercentageOfOriginalGwpAdjustedBy(
    fullNewKpis,
    idsOfCurrentAdjustment,
    TOTAL_ADJUSTED_GWP_COLUMN,
    TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  )

  const adjustmentsLowerThanThisInTheChain = calculateItemsLowerThanThis(
    [...newClients, ...(newDataInputs || [])] || [],
    props.originalItem.id,
  )

  const percentageAdjustedByLowerAdjustments = calculatePercentageOfOriginalGwpAdjustedBy(
    fullNewKpis,
    [
      props.originalItem.id === 'Actuals' ? '' : OVERRIDDEN_BY_ACTUALS,
      ...adjustmentsLowerThanThisInTheChain.map((item) => item.id),
    ],
    TOTAL_ADJUSTED_GWP_COLUMN,
    TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  )

  const leftoverPercentage = ONE_HUNDRED.minus(percentageAdjustedByThisAdjustment).minus(
    percentageAdjustedByLowerAdjustments,
  )

  const onDeleteItem = async () => {
    deleteNewClientItem(props.scenarioId, props.originalItem.id).then(triggerReloadNewClients)
  }

  const toggleShowingAdjustment = () => {
    patchNewClientItem(props.scenarioId, props.originalItem.id, {
      isEnabled: !props.originalItem.isEnabled,
    }).then(triggerReloadNewClients)
  }

  const onStartEditAdjustment = async () => {
    props.onTriggerEditItem && props.onTriggerEditItem(props.originalItem.id)
  }
  const canBeEdited =
    !props.hideCreateAndEdit &&
    currentScenario?.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)

  return (
    <div
      className={css(
        'NewClientItemCard',
        props.hasErrored ? 'NewClientItemCardErrored' : '',
        props.canDrag ? 'CanGrab' : 'CantDrag',
      )}
      key={props.originalItem.id}
      id={`${props.originalItem.name}-New-Client-Card`}
    >
      <DraggableCard
        index={props.index}
        id={props.originalItem.id}
        onHover={props.onHover ? props.onHover : () => undefined}
        onDrop={props.onDrop ? props.onDrop : () => undefined}
        canDrag={props.canDrag}
      >
        <div className="ProgressBars">
          <div
            className="ProgressBarOne"
            style={{ width: `${percentageAdjustedByLowerAdjustments}%` }}
            data-tip={`${percentageAdjustedByLowerAdjustments}%`}
          />
          <div
            className="ProgressBarTwo"
            style={{ width: `${percentageAdjustedByThisAdjustment}%` }}
            data-tip={`${percentageAdjustedByLowerAdjustments}%`}
          />
          <div
            className="ProgressBarThree"
            style={{ width: `${leftoverPercentage}%` }}
            data-tip={`${leftoverPercentage}%`}
          />
        </div>
        <div className="CardHeader">
          <h4
            className={css('NewClientItemCardTitle', props.originalItem.isEnabled ? 'Checked' : 'Unchecked')}
            id={`${sanitizeId(props.originalItem.name)}-Name`}
          >
            {trimStringIfTooLong(props.originalItem.name, MAX_LENGTH_OF_SHORT_STRINGS)}
          </h4>
          {canBeEdited && props.originalItem.id !== ACTUALS_ADJUSTMENT_ID ? (
            <div className="CardOptions">
              <EditIcon
                className="EditButton"
                onClick={onStartEditAdjustment}
                id={`${sanitizeId(props.originalItem.name)}-Edit-Button`}
              />
              <DeleteIcon
                className="DeleteButton"
                onClick={onDeleteItem}
                id={`${sanitizeId(props.originalItem.name)}-Delete-Button`}
              />
              <div data-tip={props.originalItem.isEnabled ? 'On' : 'Off'}>
                <ReactSwitch
                  className="ToggleAdjustment"
                  onChange={toggleShowingAdjustment}
                  checked={Boolean(props.originalItem.isEnabled)}
                  onColor="#0BC893"
                  offColor="#4E566B"
                  handleDiameter={8}
                  height={12}
                  width={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  id={`${sanitizeId(props.originalItem.name)}-Activation-Switch`}
                />
              </div>
            </div>
          ) : (
            <div className="CardOptions">
              <LockedIcon className="LockedIcon" />
            </div>
          )}
        </div>
        <div className="NewClientItemCardContent">
          <div className={css('NewClientItemCardValues', props.originalItem.isEnabled ? 'Checked' : 'Unchecked')}>
            <HeadingWithTitle
              title="GWP"
              value={props.originalItem.convexGwp}
              className="GWP"
              decimals={1}
              prefix="$"
              suffix={suffixForValue(props.originalItem.convexGwp)}
              id={`${sanitizeId(props.originalItem.name)}-GWP`}
            />
            <HeadingWithTitle
              title="Convex Share"
              value={props.originalItem.convexShare}
              className="ConvexShare"
              suffix={'%'}
              id={`${sanitizeId(props.originalItem.name)}-Convex-Share`}
            />
            <HeadingWithTitle
              title="Convex Limit"
              value={props.originalItem.convexLimit}
              className="ConvexLimit"
              decimals={1}
              prefix={'$'}
              suffix={suffixForValue(props.originalItem.convexLimit)}
              id={`${sanitizeId(props.originalItem.name)}-Convex-Limit`}
            />
            <HeadingWithTitle
              title="Probability"
              value={props.originalItem.probability}
              className="Probability"
              decimals={1}
              suffix={'%'}
              id={`${sanitizeId(props.originalItem.name)}-Probability`}
            />
          </div>
          <div className={css('NewClientItemCardData', props.originalItem.isEnabled ? 'Checked' : 'Unchecked')}>
            <div className="NewClientItemCardDataTop">
              <TextWithTitle
                title="Inception Month"
                value={formatInceptionYearForDisplay(props.originalItem.inceptionMonth)}
                className="InceptionMonth"
                id={`${sanitizeId(props.originalItem.name)}-Inception-Month`}
              />
              <TextWithTitle
                title="A1 Segment"
                value={props.originalItem.segment}
                className="A1Segment"
                id={`${sanitizeId(props.originalItem.name)}-A1-Segment`}
              />
            </div>
            <div className="NewClientItemCardDataBottom">
              <TextWithTitle
                title="Placement Type"
                value={props.originalItem.placementType?.name}
                className="PlacementTypeStructure"
                id={`${sanitizeId(props.originalItem.name)}-Placement-Type`}
              />
              <TextWithTitle
                title="COB - Tier 2"
                value={props.originalItem.classOfBusiness}
                className="COB"
                id={`${sanitizeId(props.originalItem.name)}-COB-Tier-2`}
              />
            </div>
          </div>
          <div className="NewClientItemCardSummaryContainer">
            {actualDescription && (
              <div className={css('NewClientItemCardSummary', props.originalItem.isEnabled ? 'Checked' : 'Unchecked')}>
                <p id={`${sanitizeId(props.originalItem.name)}-Description`}>{actualDescription}</p>
              </div>
            )}
          </div>
        </div>
      </DraggableCard>
    </div>
  )
}

export default NewClientItemCard
