import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { PseudoPortfolioContext } from '../../../providers/NewPseudoPortfolioItemsProvider'
import { NewClientItemsContext } from '../../../providers/NewClientItemsProvider'
import { RenewalAdjustmentContext } from '../../../providers/RenewalAdjustmentsProvider'
import {
  generateNewSplit,
  generateRenewalSplit,
  generateNewClientSplit,
  generateUnadjustedSplit,
  RENEWAL_ADJUSTMENT_COLUMN_TYPE,
} from '../sunburst/SummaryOfAdjustmentsByFieldThenTypeProvider'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'

export interface TreeMapNode {
  name?: string
  type?: string
  children?: TreeMapNode[]
  color?: string
  value?: number
}

interface SummaryOfAdjustmentsByTypeContainer {
  graphData: TreeMapNode
}

const SummaryOfAdjustmentsByTypeContext = createContext<SummaryOfAdjustmentsByTypeContainer>({ graphData: {} })

function SummaryOfAdjustmentsByTypeProvider(
  props: PropsWithChildren<{
    numericColumnToUse?: RENEWAL_ADJUSTMENT_COLUMN_TYPE
    hideRenewal?: boolean
    hideNew?: boolean
  }>,
) {
  const [graphData, setGraphData] = useState({})
  const { newDataInputs } = useContext(PseudoPortfolioContext)
  const { newClients } = useContext(NewClientItemsContext)
  const { adjustments } = useContext(RenewalAdjustmentContext)
  const { filteredNewKpis, filteredRenewalKpis } = useContext(FullScenarioDataContext)

  useEffect(() => {
    if (!filteredRenewalKpis || !filteredNewKpis) {
      return
    }

    let newAdjusted: TreeMapNode | undefined
    if (!props.hideNew) {
      newAdjusted = {
        name: 'New - Adjusted',
        type: 'ADJ_NEW',
      } as TreeMapNode

      const newData = generateNewSplit('', newDataInputs || [], filteredNewKpis)
      const newClientData = generateNewClientSplit('', newClients || [], filteredNewKpis)
      newAdjusted.children = [...(newData || []), ...(newClientData || [])]
    }

    const renewalData = props.hideRenewal
      ? undefined
      : generateRenewalSplit('', adjustments || [], filteredRenewalKpis, props.numericColumnToUse)

    const unadjustedRewewalTotal = props.hideRenewal ? undefined : generateUnadjustedSplit('', filteredRenewalKpis)

    const graphData: TreeMapNode = {
      name: 'All',
      type: 'ALL',
      children: [renewalData, newAdjusted, unadjustedRewewalTotal].filter(
        (item) => item !== undefined,
      ) as TreeMapNode[],
    }
    setGraphData(graphData)
  }, [
    newDataInputs,
    filteredNewKpis,
    adjustments,
    filteredRenewalKpis,
    props.numericColumnToUse,
    props.hideRenewal,
    props.hideNew,
    newClients,
  ])

  return (
    <SummaryOfAdjustmentsByTypeContext.Provider
      value={{ graphData }}
      children={props.children}
    />
  )
}

export { SummaryOfAdjustmentsByTypeProvider, SummaryOfAdjustmentsByTypeContext }
