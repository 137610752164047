import React, { MouseEventHandler } from 'react'
import './PerilCard.scss'
import { Card } from '../card/Card'
import EditIcon from '../icons/EditIcon'
import { OutwardScenario } from '../../backend/outward-scenarios'
import ParagraphWithTitle from '../paragraph-with-title/ParagraphWithTitle'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import LockedIcon from '../icons/LockedIcon'
import PreviewIcon from '../icons/PreviewIcon'

export type PerilCardProps = {
  outwardScenario: OutwardScenario
  applicablePerils: string[]
  excludedPerils: string[]
  triggerEditPeril: (outwardScenarioId: string) => void
  triggerPreviewPeril: (outwardScenarioId: string) => void
  description: string
}

export function PerilCard(props: PerilCardProps) {
  const onStartPreviewPeril: MouseEventHandler = (event) => {
    event.stopPropagation()
    props.triggerPreviewPeril(props.outwardScenario.id)
  }

  const onStartEditPeril: MouseEventHandler = (event) => {
    event.stopPropagation()
    props.triggerEditPeril(props.outwardScenario.id)
  }
  return (
    <>
      <Card className="PerilsCard">
        <div className="CardHeader">
          <div className="CardOptions">
            <PreviewIcon
              className="PreviewButton"
              onClick={onStartPreviewPeril}
            />
            {props.outwardScenario.canWrite && props.outwardScenario.status !== ScenarioStatus.COMPLETE && (
              <EditIcon
                className="EditButton"
                onClick={onStartEditPeril}
              />
            )}
            {props.outwardScenario.canWrite && props.outwardScenario.status === ScenarioStatus.COMPLETE && (
              <LockedIcon className="LockedIcon" />
            )}
          </div>
        </div>
        <h4 className="PerilsCardTitle">Perils</h4>
        <div className="PerilsInformation">
          <p>{`${props.applicablePerils.length} applicable perils`}</p>
          <p>{`${props.excludedPerils.length} excluded perils`}</p>
        </div>
        {props.description && (
          <ParagraphWithTitle
            className="Description"
            title="Description"
            content={props.description}
          />
        )}
      </Card>
    </>
  )
}

export default PerilCard
