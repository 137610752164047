import React, { SVGProps } from 'react'
export function ExposuresIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        id="Icon-/-Exposures"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M8,4.33333333 C10.5266667,4.33333333 12.78,5.75333333 13.88,8 C12.78,10.2466667 10.5266667,11.6666667 8,11.6666667 C5.47333333,11.6666667 3.22,10.2466667 2.12,8 C3.22,5.75333333 5.47333333,4.33333333 8,4.33333333 M8,3 C4.66666667,3 1.82,5.07333333 0.666666667,8 C1.82,10.9266667 4.66666667,13 8,13 C11.3333333,13 14.18,10.9266667 15.3333333,8 C14.18,5.07333333 11.3333333,3 8,3 Z M8,6.33333333 C8.92,6.33333333 9.66666667,7.08 9.66666667,8 C9.66666667,8.92 8.92,9.66666667 8,9.66666667 C7.08,9.66666667 6.33333333,8.92 6.33333333,8 C6.33333333,7.08 7.08,6.33333333 8,6.33333333 M8,5 C6.34666667,5 5,6.34666667 5,8 C5,9.65333333 6.34666667,11 8,11 C9.65333333,11 11,9.65333333 11,8 C11,6.34666667 9.65333333,5 8,5 Z"
          id="Shape"
          fill="#4E566B"
        ></path>
      </g>
    </svg>
  )
}
