import React, { SVGProps } from 'react'

export function DuplicateIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10.5909092,1.6666665 L3.68181807,1.6666665 C3.04848472,1.6666665 2.53030289,2.18484833 2.53030289,2.81818168 L2.53030289,10.878788 L3.68181807,10.878788 L3.68181807,2.81818168 L10.5909092,2.81818168 L10.5909092,1.6666665 Z M12.3181819,3.96969686 L5.98484843,3.96969686 C5.35151508,3.96969686 4.83333325,4.4878787 4.83333325,5.12121205 L4.83333325,13.1818183 C4.83333325,13.8151517 5.35151508,14.3333335 5.98484843,14.3333335 L12.3181819,14.3333335 C12.9515153,14.3333335 13.4696971,13.8151517 13.4696971,13.1818183 L13.4696971,5.12121205 C13.4696971,4.4878787 12.9515153,3.96969686 12.3181819,3.96969686 Z M12.3181819,13.1818183 L5.98484843,13.1818183 L5.98484843,5.12121205 L12.3181819,5.12121205 L12.3181819,13.1818183 Z"
          id="Shape"
          fill="#4E566B"
        ></path>
      </g>
    </svg>
  )
}
