export const isSameObject = (comparedObject: object, templateObject: object): boolean => {
  const relevantKeys = Object.getOwnPropertyNames(templateObject) as (keyof typeof templateObject)[]
  return relevantKeys.reduce((acc, curr) => {
    const isSameValue =
      typeof templateObject[curr] === 'object' && typeof comparedObject[curr] === 'object'
        ? isSameObject(comparedObject[curr], templateObject[curr])
        : comparedObject[curr] === templateObject[curr]
    return acc && isSameValue
  }, true)
}
