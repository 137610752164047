import { Alert, AlertIcon, AlertStatus, CloseButton, Spacer } from '@chakra-ui/react'
import { Dispatch, Reducer } from 'react'

type ExposuresAlertProps = ExposuresAlertState & { onClose: () => void }

export type ExposuresAlertState = {
  alertStatus: AlertStatus
  message: string
  isActive: boolean
}

export type ExposuresAlertAction =
  | { type: 'setError'; payload: { message: string; error: string } }
  | { type: 'setSuccess'; payload: { message: string } }
  | { type: 'setWarning'; payload: { message: string } }
  | { type: 'closeAlert' }

export const createErrorHandler = (
  dispatch: Dispatch<ExposuresAlertAction>,
): ((message: string, err: unknown) => void) => {
  return (message: string, err: unknown) => {
    let errorMessage = ''
    if (typeof err === 'string') {
      errorMessage = err
    }

    if (err instanceof Error) {
      errorMessage = err.message
    }

    dispatch({ type: 'setError', payload: { message, error: errorMessage } })
  }
}

export const createExposuresAlertInitialState = (): ExposuresAlertState => {
  return {
    alertStatus: 'loading',
    message: '',
    isActive: false,
  }
}

export const exposuresAlertReducer: Reducer<ExposuresAlertState, ExposuresAlertAction> = (state, action) => {
  switch (action.type) {
    case 'setError': {
      let message = action.payload.message
      if (message === '') {
        message = 'An unexpected error occurred'
      }

      console.error(action.payload.error)

      return {
        alertStatus: 'error',
        message,
        isActive: true,
      }
    }
    case 'setSuccess': {
      let message = action.payload.message
      let isActive = true
      if (message === '') {
        console.info('Success message was empty - will not display alert')
        isActive = false
      }

      return {
        alertStatus: 'success',
        message,
        isActive,
      }
    }
    case 'setWarning': {
      let message = action.payload.message
      let isActive = true
      if (message === '') {
        console.info('Warning message was empty - will not display alert')
        isActive = false
      }

      return {
        alertStatus: 'warning',
        message,
        isActive,
      }
    }
    case 'closeAlert': {
      return {
        ...state,
        isActive: false,
      }
    }
  }
}

export const ExposuresAlert = (props: ExposuresAlertProps) => {
  const { alertStatus, message, isActive, onClose } = props

  if (!isActive) {
    return <></>
  }

  return (
    <Alert
      status={alertStatus}
      borderRadius={'md'}
    >
      <AlertIcon />
      {message}
      <Spacer />
      {alertStatus === 'success' && <CloseButton onClick={onClose} />}
    </Alert>
  )
}
