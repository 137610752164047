import React, { SVGProps } from 'react'

const ContractIcon = (props: SVGProps<SVGSVGElement>) => (
  <div className="Icon">
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M14.6666667,2.27333333 L11.14,5.8 L13.3333333,8 L8,8 L8,2.66666667 L10.1933333,4.86 L13.7266667,1.33333333 L14.6666667,2.27333333 Z M2.27333333,14.6666667 L5.8,11.14 L8,13.3333333 L8,8 L2.66666667,8 L4.86,10.1933333 L1.33333333,13.7266667 L2.27333333,14.6666667 Z"
          id="Shape"
          fill="#4E566B"
        />
      </g>
    </svg>
  </div>
)

export default ContractIcon
