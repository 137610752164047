import { PropsWithChildren } from 'react'
import { Tfoot, Tr } from '@chakra-ui/react'

export const NcpTableFoot = (props: PropsWithChildren) => {
  return (
    <Tfoot>
      <Tr>{props.children}</Tr>
    </Tfoot>
  )
}
