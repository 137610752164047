import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Card } from '../card/Card'
import { css } from '../../utils/css'
import HeadingWithTitle from '../../components/heading-with-title/HeadingWithTitle'
import TextField from '../text-field/WFTextField'
import TextArea from '../../components/text-area/TextArea'
import Button from '../../components/button/Button'
import './CreateAdjustmentCard.scss'
import { onInputUpdateState, onNumberInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import { FilterContext, transformIntoFlatStructure } from '../../providers/FilterProvider'
import { ADJUSTMENT_ID, TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN, TOTAL_GWP_COLUMN } from '../../backend/calculate-kpis'
import { isNumberOrZero, PossiblyNegativeNumber, suffixForValue, ZERO } from '../../utils/numbers'
import {
  calculateGrossRiskAdjustedRateChange,
  createAdjustment,
  fetchAdjustment,
  isIrrelevantAdjustment,
  isRelevantAdjustment,
  patchAdjustment,
  PatchAdjustmentRequest,
  PREVIEW_ID,
} from '../../backend/adjustments'
import { RenewalAdjustmentContext } from '../../providers/RenewalAdjustmentsProvider'
import { ErrorText } from '../text-area/ErrorText'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { validateDescription } from '../create-scenario-card/CreateScenarioCard'
import Big from 'big.js'
import {
  calculateAcquisitionCostChangeError,
  calculateConvexShareChangeError,
  calculateConvexShareChangeErrorRealTime,
  calculateConvexShareChangeWarning,
  calculateErrorIfIsClientSpecificAndNoClientSelected,
  calculatePureGrossRateChangeError,
  calculatePureGrossRateChangeWarning,
  calculateRetentionChangeError,
  calculateRetentionChangeErrorRealTime,
  calculateRiskAdjustedPremiumRateChangeError,
  calculateRiskAdjustedPremiumRateChangeWarning,
  checkBackendResponse,
  createNewAdjustment,
  createNewAdjustmentRequest,
  makeAdjustableGwp,
  valueToString,
} from './utils'
import { isEqualToDefault, CalculationsRelevantAdjustment } from './utils/isEqual'
import { useResetAdjustments } from '../../hooks/useResetAdjustments'
import { useWeightedAvgAcqCost } from './hooks/useWeightedAvgAcqCost'
import {
  formatAcquisitionCostChange,
  getNumberOfAllowedDecimals,
  isValidNumberString,
  isWithin3Digits,
} from './utils/acquisitionCostChange'

export type CreateAdjustmentCardProps = {
  adjustmentBeingEditedId: string
  adjustmentType: string
  defaultRenewalRetention?: number
}

const convertToNumberOrNull = (value: number): number | null => (Number.isNaN(value) ? null : value)

export function CreateAdjustmentCard(props: CreateAdjustmentCardProps) {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [riskAdjustedPremiumRateChange, setRiskAdjustedPremiumRateChange] = useState<PossiblyNegativeNumber>(0)
  const [acqCostChange, setAcqCostChange] = useState<string>('0')
  const [acqCostChangeInputError, setAcqCostChangeInputError] = useState<string | undefined>(undefined)
  const [pureGrossRateChange, setPureGrossRateChange] = useState<PossiblyNegativeNumber>(0)
  const [retention, setRetention] = useState<PossiblyNegativeNumber>(isNumberOrZero(props.defaultRenewalRetention))

  const [convexShareChange, setConvexShareChange] = useState<PossiblyNegativeNumber>(0)
  const [convexWrittenShareChange, setConvexWrittenShareChange] = useState<PossiblyNegativeNumber>(0)

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [errorUniqueName, setErrorUniqueName] = useState<string>('')

  const { currentScenario } = useContext(ScenarioContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const { adjustments, changeAdjustments, currentDefaultAdjustmentNameToUse } = useContext(RenewalAdjustmentContext)
  const { fullDataForScenario, filteredRenewalKpis } = useContext(FullScenarioDataContext)
  const resetAdjustments: () => void = useResetAdjustments()
  const numberOfItemsRelevantToAdjustmentId = fullDataForScenario.filter(
    (item) => item[ADJUSTMENT_ID] === props.adjustmentBeingEditedId,
  ).length
  const isPreview = useMemo(() => props.adjustmentBeingEditedId === PREVIEW_ID, [props.adjustmentBeingEditedId])
  const addOrSaveWord = isPreview ? 'Add' : 'Save'

  const expiringAvgAcqCost = useWeightedAvgAcqCost('Expiring', props.adjustmentBeingEditedId)
  const newAvgAcqCost = useWeightedAvgAcqCost('New', props.adjustmentBeingEditedId)

  useEffect(() => {
    if (currentDefaultAdjustmentNameToUse !== undefined) {
      setName(currentDefaultAdjustmentNameToUse)
    }
  }, [currentDefaultAdjustmentNameToUse])

  const updatePreviewAdjustment = () => {
    const filters = transformIntoFlatStructure(dropdownFilterOptions)

    const adjustmentsWithoutCurrentOne = (adjustments ? adjustments : []).filter(
      isIrrelevantAdjustment(props.adjustmentBeingEditedId),
    )
    const existingEntity = (adjustments ? adjustments : []).find(isRelevantAdjustment(props.adjustmentBeingEditedId))! // TODO another lie (!)

    const adjustmentToShow = createNewAdjustment(isPreview, {
      id: props.adjustmentBeingEditedId,
      filters,
      currentName: name,
      convexShareChange,
      convexWrittenShareChange,
      riskAdjustedPremiumRateChange,
      pureGrossRateChange,
      retention,
      description,
      subtype: props.adjustmentType,
      existingEntity,
      acqCostChange: convertToNumberOrNull(parseFloat(acqCostChange)),
    })

    const calculationsRelevantAdjustmentsData: CalculationsRelevantAdjustment = {
      convexShareChange: adjustmentToShow.convexShareChange,
      pureGrossRateChange: adjustmentToShow.pureGrossRateChange,
      riskAdjustedPremiumRateChange: adjustmentToShow.riskAdjustedPremiumRateChange,
      retention: adjustmentToShow.retention,
      appliedFilters: adjustmentToShow.appliedFilters,
      acqCostChange: adjustmentToShow.acqCostChange,
    }

    changeAdjustments([
      ...adjustmentsWithoutCurrentOne,
      ...(isEqualToDefault(calculationsRelevantAdjustmentsData) && isPreview ? [] : [adjustmentToShow]),
    ])
  }

  const updateBasedOnNewScenario: () => Promise<void> = useCallback(async () => {
    if (PREVIEW_ID === props.adjustmentBeingEditedId) {
      return
    }

    const savedVersionOfAdjustment = await fetchAdjustment(currentScenario!.id, props.adjustmentBeingEditedId)

    if (!savedVersionOfAdjustment) {
      return
    }

    setShouldValidate(false)
    setName(savedVersionOfAdjustment.name)
    setDescription(savedVersionOfAdjustment.description)
    setConvexShareChange(savedVersionOfAdjustment.convexShareChange)
    setConvexWrittenShareChange(savedVersionOfAdjustment.convexWrittenShareChange)
    setRiskAdjustedPremiumRateChange(savedVersionOfAdjustment.riskAdjustedPremiumRateChange)
    setPureGrossRateChange(savedVersionOfAdjustment.pureGrossRateChange)
    setRetention(savedVersionOfAdjustment.retention)
    setAcqCostChange(`${savedVersionOfAdjustment.acqCostChange}`)
  }, [currentScenario, props.adjustmentBeingEditedId])

  useEffect(updatePreviewAdjustment, [
    riskAdjustedPremiumRateChange,
    pureGrossRateChange,
    convexShareChange,
    retention,
    dropdownFilterOptions,
    acqCostChange,
  ])

  useEffect(() => {
    updateBasedOnNewScenario()
  }, [updateBasedOnNewScenario])

  async function validateThenCreateOrUpdateAdjustment() {
    setShouldValidate(true)

    if (
      calculateConvexShareChangeError(convexShareChange) ||
      calculateConvexShareChangeErrorRealTime(convexShareChange) ||
      calculatePureGrossRateChangeError(pureGrossRateChange) ||
      calculateRiskAdjustedPremiumRateChangeError(riskAdjustedPremiumRateChange) ||
      calculateRetentionChangeError(retention) ||
      numberOfItemsRelevantToAdjustmentId === 0 ||
      !isValidName(name) ||
      validateDescription(description) ||
      calculateErrorIfIsClientSpecificAndNoClientSelected(props.adjustmentType, dropdownFilterOptions)
    ) {
      return
    }

    const filters = transformIntoFlatStructure(dropdownFilterOptions)
    const existingEntity = (adjustments ? adjustments : []).find(isRelevantAdjustment(props.adjustmentBeingEditedId))!

    try {
      if (!currentScenario) {
        console.error(`Missing currentScenario - unable to create or patch adjustment`)
        return
      }

      if (isPreview) {
        const adjustmentToCreate = createNewAdjustmentRequest(isPreview, {
          id: '',
          filters,
          currentName: name,
          convexShareChange,
          convexWrittenShareChange,
          riskAdjustedPremiumRateChange,
          pureGrossRateChange,
          retention,
          description,
          subtype: props.adjustmentType,
          existingEntity,
          acqCostChange: convertToNumberOrNull(parseFloat(acqCostChange)),
        })

        const res = await createAdjustment(currentScenario.id, adjustmentToCreate)
        checkBackendResponse(res, (error: string) => setErrorUniqueName(error))
        cancelEditing()
        return
      }

      const adjustmentToCreate: PatchAdjustmentRequest = {
        name: name,
        description: description,
        convexShareChange: valueToString(convexShareChange),
        convexWrittenShareChange: valueToString(convexWrittenShareChange),
        riskAdjustedPremiumRateChange: valueToString(riskAdjustedPremiumRateChange),
        pureGrossRateChange: valueToString(pureGrossRateChange),
        retention: valueToString(retention),
        isEnabled: true,
        orderNumber: existingEntity.orderNumber,
        subtype: props.adjustmentType,
        appliedFilters: filters,
        acqCostChange: convertToNumberOrNull(parseFloat(acqCostChange)),
      }

      const res = await patchAdjustment(currentScenario.id, props.adjustmentBeingEditedId, adjustmentToCreate)
      checkBackendResponse(res, (error: string) => setErrorUniqueName(error))
      cancelEditing()
    } catch (err: unknown) {
      if (typeof err === 'object' && err && 'message' in err && typeof err.message === 'string') {
        console.error(
          `An error occurred whilst creating or patching an adjustment, adjustmentBeingEditedId: ${props.adjustmentBeingEditedId} - ${err.message}`,
        )
        return
      }

      console.error(`An unknown error occurred, adjustmentBeingEditedId: ${props.adjustmentBeingEditedId}`)
    }
  }

  const cancelEditing = () => {
    setName('')
    setDescription('')
    setRiskAdjustedPremiumRateChange(0)
    setPureGrossRateChange(0)
    setConvexShareChange(0)
    setConvexWrittenShareChange(0)
    setRetention(0)
    setShouldValidate(false)
    setErrorUniqueName('')
    resetAdjustments()
    setAcqCostChange('0')
    setAcqCostChangeInputError(undefined)
  }

  const clientSpecificErrorText = calculateErrorIfIsClientSpecificAndNoClientSelected(
    props.adjustmentType,
    dropdownFilterOptions,
  )
  const adjustableGwp: Big = makeAdjustableGwp(filteredRenewalKpis, props.adjustmentBeingEditedId)

  let ggRateChangeForCalc = pureGrossRateChange ? (pureGrossRateChange as number) : 0
  let riskAdjRateChangeForCalc = riskAdjustedPremiumRateChange ? (riskAdjustedPremiumRateChange as number) : 0
  let grarc = calculateGrossRiskAdjustedRateChange(ggRateChangeForCalc, riskAdjRateChangeForCalc)

  const onChangeAcqCost = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const isValidNumber = isValidNumberString(value)
    if (isWithin3Digits(value) && isValidNumber) {
      setAcqCostChange(value)
      setAcqCostChangeInputError(undefined)
    } else {
      isValidNumber && setAcqCostChangeInputError('Only values up to three numerical places allowed')
    }
  }

  return (
    <div
      className={css(
        'CreateAdjustmentCard',
        props.adjustmentBeingEditedId !== PREVIEW_ID ? 'CreateAdjustmentCardEditing' : '',
      )}
    >
      <Card>
        <h3 className="CreateAdjustmentCardTitle">
          {props.adjustmentBeingEditedId === PREVIEW_ID ? 'Add Adjustment' : 'Edit Adjustment'}
        </h3>
        <div className="NumbersBottomBorder">
          <HeadingWithTitle
            title="Original GWP"
            id="Renewal-Adjustment-Card-Header-Original-GWP"
            value={filteredRenewalKpis ? filteredRenewalKpis[TOTAL_GWP_COLUMN] : ZERO}
            decimals={0}
            className="GWP"
            prefix="$"
            suffix={filteredRenewalKpis ? suffixForValue(filteredRenewalKpis[TOTAL_GWP_COLUMN]) : ''}
          />
          <HeadingWithTitle
            title="Adjustable GWP"
            id="Renewal-Adjustment-Card-Header-Adjustable-GWP"
            value={adjustableGwp}
            decimals={0}
            className="Adjust"
            prefix="$"
            suffix={suffixForValue(adjustableGwp)}
          />
        </div>
        <div className="MiddleOptions">
          <TextField
            title="Risk Adj Prem Change"
            id="Renewal-Adjustment-Card-TextField-Risk-Adj-Prem-Change"
            className="RiskAdjustedPremiumChange"
            value={riskAdjustedPremiumRateChange}
            onChange={onNumberInputUpdateState(setRiskAdjustedPremiumRateChange)}
            warning={calculateRiskAdjustedPremiumRateChangeWarning(riskAdjustedPremiumRateChange)}
            error={shouldValidate ? calculateRiskAdjustedPremiumRateChangeError(riskAdjustedPremiumRateChange) : ''}
            type="number"
            percentage
          />
          <TextField
            title="GG Premium Change"
            id="Renewal-Adjustment-Card-TextField-GG-Premium-Change"
            className="PremiumChange"
            value={pureGrossRateChange}
            onChange={onNumberInputUpdateState(setPureGrossRateChange)}
            warning={calculatePureGrossRateChangeWarning(pureGrossRateChange)}
            error={shouldValidate ? calculatePureGrossRateChangeError(pureGrossRateChange) : ''}
            type="number"
            percentage
          />
          <TextField
            title="Convex Signed Share Change"
            id="Renewal-Adjustment-Card-TextField-Convex-Signed-Share-Change"
            className="ShareChange"
            type="number"
            value={convexShareChange}
            onChange={onNumberInputUpdateState(setConvexShareChange)}
            warning={calculateConvexShareChangeWarning(convexShareChange)}
            error={
              calculateConvexShareChangeErrorRealTime(convexShareChange) ||
              (shouldValidate ? calculateConvexShareChangeError(convexShareChange) : '')
            }
            percentage
          />
          <TextField
            title="Convex Written Share Change"
            id="Renewal-Adjustment-Card-TextField-Convex-Written-Share-Change"
            className="ShareChange"
            type="number"
            value={convexWrittenShareChange}
            onChange={onNumberInputUpdateState(setConvexWrittenShareChange)}
            warning={calculateConvexShareChangeWarning(convexWrittenShareChange)}
            percentage
          />
          <TextField
            title="Renewal Retention"
            id="Renewal-Adjustment-Card-TextField-Renewal-Retention"
            className="RenewalRetention"
            type="number"
            value={retention}
            onChange={onNumberInputUpdateState(setRetention)}
            error={
              calculateRetentionChangeErrorRealTime(retention) ||
              (shouldValidate ? calculateRetentionChangeError(retention) : '')
            }
            percentage
          />
        </div>
        <div className="NumbersBottomBorder">
          <HeadingWithTitle
            title="GRARC"
            id="Renewal-Adjustment-Card-Heading-GRARC"
            value={grarc}
            decimals={2}
            className="GRARC"
            prefix={undefined}
            suffix="%"
          />

          <HeadingWithTitle
            title="Adjusted GWP"
            id="Renewal-Adjustment-Card-Header-Adjusted-GWP"
            value={
              filteredRenewalKpis?.[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]?.[props.adjustmentBeingEditedId] || ZERO
            }
            decimals={0}
            className="Adjusted"
            prefix="$"
            suffix={
              filteredRenewalKpis &&
              filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN] &&
              filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][props.adjustmentBeingEditedId]
                ? suffixForValue(
                    filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][props.adjustmentBeingEditedId]!,
                  )
                : ''
            }
          />
        </div>
        <h3 className="CreateAdjustmentCardTitle">Acquisition Costs</h3>
        <div className="NumbersNoBottomBorder">
          <HeadingWithTitle
            title="Expiring Year Acq. Cost"
            id="Renewal-Adjustment-Card-Heading-Exp-Acq-Cost"
            value={formatAcquisitionCostChange(expiringAvgAcqCost)}
            decimals={getNumberOfAllowedDecimals(expiringAvgAcqCost)}
            className="Expiring"
            suffix="%"
            omitFormattingValue
          />
          <HeadingWithTitle
            title="Estimated New Acq. Cost"
            id="Renewal-Adjustment-Card-Heading-Est-New-Acq-Cost"
            value={formatAcquisitionCostChange(newAvgAcqCost)}
            decimals={getNumberOfAllowedDecimals(newAvgAcqCost)}
            className="Estimated"
            suffix="%"
            omitFormattingValue
          />
        </div>
        <div className="NumbersBottomBorder">
          <TextField
            title="Acq. Cost Change"
            id="Renewal-Adjustment-Card-TextField-Acq-Cost-Change"
            className="AcqCostChange"
            value={acqCostChange === 'null' ? '' : acqCostChange}
            onChange={onChangeAcqCost}
            error={calculateAcquisitionCostChangeError(parseFloat(acqCostChange))}
            validationError={acqCostChangeInputError}
            type="text"
            percentage
          />
        </div>
        <div className="BottomOptions">
          <TextField
            title="Name"
            id="Renewal-Adjustment-Card-TextField-Name"
            placeholder="Name"
            value={name}
            onChange={onInputUpdateState(setName)}
            error={shouldValidate ? (isValidName(name) ? '' : 'Please enter a name for the adjustment') : ''}
            className="AdjustmentName"
          />
          <TextArea
            className="AdjustmentDescription"
            id="Renewal-Adjustment-Card-TextField-Description"
            title="Description"
            placeholder="Description"
            value={description}
            onChange={onTextAreaUpdateState(setDescription)}
            error={shouldValidate ? validateDescription(description) : ''}
          />
        </div>
        <div className="ButtonContainer">
          {props.adjustmentBeingEditedId !== PREVIEW_ID && (
            <Button
              title="Cancel"
              id="Renewal-Adjustment-Card-Button-Cancel"
              secondary
              onClick={cancelEditing}
            />
          )}
          <Button
            title={addOrSaveWord}
            id="Renewal-Adjustment-Card-Button-Add-Or-Save"
            onClick={validateThenCreateOrUpdateAdjustment}
          />
        </div>
        {shouldValidate &&
          (numberOfItemsRelevantToAdjustmentId === 0 || clientSpecificErrorText || errorUniqueName.length > 0) && (
            <div className="CreateAdjustmentCardErrorText">
              <ErrorText error="You can't create an adjustment that affects 0 records" />
              {shouldValidate && clientSpecificErrorText && <ErrorText error={clientSpecificErrorText} />}
              {shouldValidate && errorUniqueName.length > 0 && <ErrorText error={errorUniqueName} />}
            </div>
          )}
      </Card>
    </div>
  )
}
