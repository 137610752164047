import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import './OutwardOrInwardToggle.scss'

import SegmentedControlSwitch from '../../components/segmented-control/SegmentedControl'
import { formatEnumForDisplay } from '../../components/ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { OutwardsPortfolioListingContext } from '../../providers/outwards/OutwardsPortfolioListingProvider'

export enum AvailableScenarioChooserPages {
  OUTWARD = 'OUTWARD',
  INWARD = 'INWARD',
}

interface WithChooserProps extends RouteComponentProps {
  currentlySelected: AvailableScenarioChooserPages
}

export const PATHS_FOR_SCENARIO_PAGES: Record<AvailableScenarioChooserPages, string> = {
  [AvailableScenarioChooserPages.OUTWARD]: `/outwardScenario-chooser`,
  [AvailableScenarioChooserPages.INWARD]: `/scenario-chooser`,
}

function WhichScenarioChooserToggle(props: WithChooserProps): JSX.Element {
  const { canAccessOutwards } = useContext(OutwardsPortfolioListingContext)

  if (canAccessOutwards) {
    return renderSwitch()
  }

  return <></>

  function renderSwitch(): JSX.Element {
    return (
      <div className="WhichScenariosChooser">
        <SegmentedControlSwitch
          options={Object.keys(AvailableScenarioChooserPages).map((availablePage) => ({
            name: formatEnumForDisplay(availablePage),
            value: availablePage,
          }))}
          selectedOption={Object.keys(AvailableScenarioChooserPages).indexOf(props.currentlySelected)}
          onChange={(selectedItem) =>
            props.history.push(PATHS_FOR_SCENARIO_PAGES[selectedItem as AvailableScenarioChooserPages])
          }
        />
      </div>
    )
  }
}

export default withRouter(WhichScenarioChooserToggle)
