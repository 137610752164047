import { Select, Tbody, Td, Tr } from '@chakra-ui/react'
import { ChangeEvent, useEffect } from 'react'
import { DynamicTableState } from './DynamicTable'
import { DynamicTableInput, dynamicInputInvalid } from './DynamicTableInput'

type TableBodyProps = DynamicTableState<number>

export const TableBody = (props: TableBodyProps) => {
  const { cells, rows, depth, columns, columnOffset, selector, selectorRowName, disabled } = props

  const tableSize = columns.length * rows.length
  const maxLossesInvalid = dynamicInputInvalid(cells)

  // currently shows empty values as a 0 value - consider showing an empty input field
  const onChange = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    const stringVal = e.target.value
    let value = 0
    if (stringVal !== '') {
      value = parseFloat(e.target.value)
    }
    cells[id].update?.(id, value)
  }

  // Need to optimise the onBlur - track the rows / columns with dependencies and only update those values
  const updateDepedencies = () => {
    cells.forEach((c) => {
      if (!c.dependencies) {
        return
      }

      const sumOfIds = c.dependencies.sumOf

      const sum = sumOfIds.reduce((prev, currId) => {
        return prev + cells[currId].data
      }, 0)

      cells[c.id].update?.(c.id, sum)
    })
  }

  const propagateChanges = (id: number, e: ChangeEvent<HTMLInputElement>, scaleFactor: number) => {
    let v = e.target.value
    if (v === '') {
      v = '0'
    }

    const prop = cells[id].propagation
    if (!prop) {
      return
    }

    let value = parseFloat(v)

    prop.forEach((p) => {
      if (cells[id].scaler) {
        const propCellRelationId = cells[p].scaler
        if (!propCellRelationId) {
          return
        }

        const propCellRelation = cells[propCellRelationId]
        value = propCellRelation.data * scaleFactor
      }

      cells[p].update?.(p, value)
    })
  }

  const updateScaler = (id: number, e: ChangeEvent<HTMLInputElement>): number => {
    let value = 0
    if (e.target.value !== '') {
      value = parseFloat(e.target.value)
    }
    const tableSize = columns.length * rows.length
    const depth = (id / tableSize) >> 0
    const flatCellPosition = id - tableSize * depth
    const scalerRowPosition = (flatCellPosition / columns.length) >> 0
    const scalerColumnPosition = flatCellPosition % columns.length
    const rowName = rows[scalerRowPosition]

    const currentCell = cells[id]
    const relationId = currentCell.scaler
    if (!relationId) {
      return 1
    }

    const scaleFactor = value / cells[relationId].data

    cells[id].updateScaler?.(id, scaleFactor, rowName, scalerColumnPosition)
    return scaleFactor
  }

  const updateSelector = (id: number, e: ChangeEvent<HTMLSelectElement>) => {
    const depth = e.target.value
    selector[id].updateActiveColumn?.(id, depth)
  }

  useEffect(() => {
    updateDepedencies()
  }, [])

  return (
    <Tbody gap={'8px'}>
      <Tr key={'selector'}>
        <Td key={'selectorHeader'}>{selectorRowName}</Td>
        {selector.map((s, index) => {
          return (
            <Td key={'selectorRow' + index}>
              <Select
                key={'selector' + index}
                value={s.currentDepth}
                id={`Exposures-Selector-${selectorRowName}`}
                onChange={(e) => updateSelector(index, e)}
                size={'sm'}
              >
                {depth.map((d, index) => {
                  return (
                    <option
                      key={`option-${index}`}
                      value={d}
                    >
                      {d}
                    </option>
                  )
                })}
              </Select>
            </Td>
          )
        })}
      </Tr>
      {rows.map((row, index) => {
        const cellStartingIndex = index * columns.length
        return (
          <Tr key={'row' + index.toString()}>
            <>
              <Td key={'rowHeader' + index.toString()}>{row}</Td>
              {cells.slice(cellStartingIndex, cellStartingIndex + columns.length).map((_cell, cellIndex) => {
                const cellPosition = calculateDisplayedCellPosition(
                  cellIndex,
                  index,
                  columnOffset,
                  columns.length,
                  tableSize,
                )
                const currentCell = cells[cellPosition]
                if (currentCell.dependencies) {
                  return (
                    <Td
                      key={'cell' + currentCell.id}
                      p={'4px'}
                    >
                      <DynamicTableInput
                        currentCell={currentCell}
                        updateDependencies={updateDepedencies}
                        onChange={() => {}}
                        disabled={disabled}
                        maxLossesInvalid={maxLossesInvalid}
                      />
                    </Td>
                  )
                }

                if (typeof currentCell.update === 'function') {
                  return (
                    <Td
                      key={'cell' + currentCell.id}
                      p={'4px'}
                    >
                      <DynamicTableInput
                        currentCell={currentCell}
                        updateDependencies={updateDepedencies}
                        onChange={(e) => {
                          onChange(currentCell.id, e)
                          let scaleFactor = 1
                          if (currentCell.scaler) {
                            scaleFactor = updateScaler(currentCell.id, e)
                          }

                          if (currentCell.propagation) {
                            propagateChanges(currentCell.id, e, scaleFactor)
                          }
                        }}
                        disabled={disabled}
                        maxLossesInvalid={maxLossesInvalid}
                      />
                    </Td>
                  )
                }

                return <Td key={'currentCell' + currentCell.id}>{currentCell.data}</Td>
              })}
            </>
          </Tr>
        )
      })}
    </Tbody>
  )
}

function calculateDisplayedCellPosition(
  currentCol: number,
  currentRow: number,
  columnOffset: number[] | undefined,
  columnLength: number,
  tableSize: number,
): number {
  if (columnOffset === undefined) {
    return currentCol + columnLength * currentRow
  }

  return currentCol + columnLength * currentRow + tableSize * columnOffset[currentCol]
}
