import Modal from 'react-modal'
import React, { useContext, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Card } from '../../components/card/Card'

import './ScenarioModal.scss'

import Button from '../../components/button/Button'
import {
  createStackedScenario,
  CreateStackedScenarioRequest,
  patchStackedScenario,
  ScenarioLevel,
} from '../../backend/stacked-scenarios'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { StackedScenarioListContext } from '../../components/stacked-scenarios-list/StackedScenarioListProvider'
import { StackedScenarioEditingContext } from '../../components/stacked-scenarios-list/StackedScenarioEditingProvider'
import { onInputUpdateState } from '../../utils/onChange'
import TextField from '../../components/text-field/WFTextField'
import TextArea from '../../components/text-area/TextArea'
import { isArrayEqual } from './ScenarioChooser'
import {
  createStackedOutwardScenario,
  CreateStackedOutwardScenarioRequest,
  patchStackedOutwardScenario,
} from '../../backend/stacked-outwardScenarios'
import { StackedOutwardScenarioListContext } from '../../providers/outwards/StackedOutwardScenarioListProvider'
import { AppQueryContext } from '../../providers/AppQueryProvider'

interface FinishCreatingStackedScenarioModalProps {
  availableTeams: Array<string>
  availableMarkets: Array<string>
  availableDivisions: Array<string>
  allowValidationIfUserConfirms?: boolean
}

function scenarioLevelToString(scenarioLevel: ScenarioLevel): string {
  switch (scenarioLevel) {
    case ScenarioLevel.TEAM:
      return 'team'
    case ScenarioLevel.DIVISIONAL:
      return 'division'
    default:
      return 'organisation'
  }
}

function calculateErrorForStackedScenarioName(
  currentId: string | undefined,
  value: string,
  stackedScenarios: any[],
  scenarioLevel: ScenarioLevel,
): undefined | string {
  if (!value) {
    return 'This must have a value'
  }

  const differentScenariosWithTheSameName = stackedScenarios
    .filter((item) => item.id !== currentId)
    .filter((item) => item.name === value)

  if (differentScenariosWithTheSameName.length <= 0) {
    return undefined
  }

  return `Stacked scenario name must be unique within a ${scenarioLevelToString(scenarioLevel)} for a given year`
}

function calculateScenarioLevel(
  currentDivision: string | null | undefined,
  currentTeam: string | null | undefined,
): ScenarioLevel {
  let scenarioLevel = ScenarioLevel.ORGANISATIONAL

  if (currentDivision) {
    scenarioLevel = ScenarioLevel.DIVISIONAL
  }

  if (currentTeam) {
    scenarioLevel = ScenarioLevel.TEAM
  }

  return scenarioLevel
}

function calculateAvailableGroups(
  props: FinishCreatingStackedScenarioModalProps,
  scenarioLevel: ScenarioLevel | undefined,
  outward: boolean,
): string[] {
  if (outward) {
    return props.availableMarkets
  }

  if (scenarioLevel === ScenarioLevel.TEAM) {
    return props.availableMarkets
  }
  if (scenarioLevel === ScenarioLevel.DIVISIONAL) {
    return props.availableTeams
  }
  if (scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
    return props.availableDivisions
  }

  throw new Error(`Unknown lookup for "scenarioLevel"`)
}

export function FinishCreatingStackedScenarioModal(props: FinishCreatingStackedScenarioModalProps): JSX.Element {
  const [currentVersion] = useQueryParam('version', StringParam)

  const { reloadStackedScenarios, currentStackedScenarios } = useContext(StackedScenarioListContext)
  const { reloadStackedOutwardScenarios, currentStackedOutwardScenarios } = useContext(
    StackedOutwardScenarioListContext,
  )
  const {
    currentlyChosenScenarioIds,
    createStackedScenarioModalIsOpen,
    setCreateStackedScenarioModalIsOpen,
    currentlyEditingStackedScenarioId,
    setCurrentlyChosenScenarioIds,
    nameOfCurrentlyEditedStackedScenario,
    setNameOfCurrentlyEditedStackedScenario,
    setCurrentlyCreatingStackedScenario,
    descriptionOfCurrentlyEditedStackedScenario,
    setDescriptionOfCurrentlyEditedStackedScenario,
    setCurrentlyEditingStackedScenarioId,
  } = useContext(StackedScenarioEditingContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { yearQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [shouldValidate, setShouldValidate] = useState(false)

  const [yearFromQuery] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  const isOutward = Boolean(currentVersion)

  const cancelChoosingScenarios = () => {
    setCreateStackedScenarioModalIsOpen(false)
    setCurrentlyChosenScenarioIds(undefined)
    setCurrentlyCreatingStackedScenario(false)
    setCurrentlyEditingStackedScenarioId(undefined)
    setShouldValidate(false)
  }

  const saveChosenScenarios = async () => {
    setShouldValidate(true)

    if (!currentlyChosenScenarioIds) {
      console.warn(`Missing currentlyChosenScenarioIds`)
      return
    }
    const currentChosenIds = Object.values(currentlyChosenScenarioIds || {}).every((item) => item !== undefined)
    const scenarioLevel = calculateScenarioLevel(divisionFromQuery, teamFromQuery)
    const availableGroups = calculateAvailableGroups(props, scenarioLevel, isOutward)

    const maybeError: undefined | string = isOutward
      ? calculateErrorForStackedScenarioName(
          currentlyEditingStackedScenarioId,
          nameOfCurrentlyEditedStackedScenario,
          currentStackedOutwardScenarios,
          scenarioLevel,
        )
      : calculateErrorForStackedScenarioName(
          currentlyEditingStackedScenarioId,
          nameOfCurrentlyEditedStackedScenario,
          currentStackedScenarios,
          scenarioLevel,
        )
    if (maybeError) {
      console.warn('Error::', maybeError)
      return
    }

    if (!currentlyChosenScenarioIds) {
      console.warn(`Missing currentlyChosenScenarioIds`)
      return
    }
    const ids = Object.keys(currentlyChosenScenarioIds)
    const scenarioChosenFromThisLevel = isArrayEqual(ids, availableGroups)
    const canCreate = scenarioChosenFromThisLevel && currentChosenIds

    if (
      canCreate ||
      (props.allowValidationIfUserConfirms &&
        window.confirm('Not all values have a value selected - do you want to continue?'))
    ) {
      setCreateStackedScenarioModalIsOpen(false)

      if (currentVersion !== undefined) {
        const request: CreateStackedOutwardScenarioRequest = {
          name: nameOfCurrentlyEditedStackedScenario,
          description: descriptionOfCurrentlyEditedStackedScenario || '',
          version: currentVersion!,
          subScenarioIds: Object.values(currentlyChosenScenarioIds!) as string[],
          year: yearFromQuery!,
        }

        const createOrUpdateStackOutward = (request: CreateStackedOutwardScenarioRequest) =>
          currentlyEditingStackedScenarioId
            ? patchStackedOutwardScenario(currentlyEditingStackedScenarioId, request)
            : createStackedOutwardScenario(request)

        updateIndividualProgressItem('createStackedOutwardScenario', ProgressState.LOADING)
        await createOrUpdateStackOutward(request)
          .then(reloadStackedOutwardScenarios)
          .then(cancelChoosingScenarios)
          .then(() => updateIndividualProgressItem('createStackedOutwardScenario', ProgressState.FINISHED))
          .catch(() => updateIndividualProgressItem('createStackedOutwardScenario', ProgressState.ERROR))

        return
      }

      const request: CreateStackedScenarioRequest = {
        name: nameOfCurrentlyEditedStackedScenario,
        description: descriptionOfCurrentlyEditedStackedScenario || '',
        division: divisionFromQuery,
        team: teamFromQuery,
        subScenarioIds: Object.values(currentlyChosenScenarioIds!) as string[],
        scenarioLevel: scenarioLevel,
        year: yearFromQuery!,
      }

      const createOrUpdateStack = (request: CreateStackedScenarioRequest) =>
        currentlyEditingStackedScenarioId
          ? patchStackedScenario(currentlyEditingStackedScenarioId, request)
          : createStackedScenario(request)

      updateIndividualProgressItem('createStackedScenario', ProgressState.LOADING)
      createOrUpdateStack(request)
        .then(reloadStackedScenarios)
        .then(cancelChoosingScenarios)
        .then(() => updateIndividualProgressItem('createStackedScenario', ProgressState.FINISHED))
        .catch(() => updateIndividualProgressItem('createStackedScenario', ProgressState.ERROR))
    }

    setShouldValidate(false)
  }

  const displayErrorMessageBasedOnScenarioInOrOutward = (): undefined | string => {
    if (isOutward) {
      return calculateErrorForStackedScenarioName(
        currentlyEditingStackedScenarioId!,
        nameOfCurrentlyEditedStackedScenario,
        currentStackedOutwardScenarios,
        ScenarioLevel.ORGANISATIONAL,
      )
    } else {
      return calculateErrorForStackedScenarioName(
        currentlyEditingStackedScenarioId!,
        nameOfCurrentlyEditedStackedScenario,
        currentStackedScenarios,
        calculateScenarioLevel(divisionFromQuery, teamFromQuery),
      )
    }
  }

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={createStackedScenarioModalIsOpen}
      className="ScenarioModal"
      overlayClassName="ModalOverlay"
    >
      <Card>
        <h3 className="StackedScenarioModalTitle">{`${
          teamFromQuery ? 'Team' : divisionFromQuery ? 'Division' : 'Organisational'
        } Scenario`}</h3>
        <TextField
          className="StackedScenarioName"
          title="Name"
          value={nameOfCurrentlyEditedStackedScenario}
          error={shouldValidate && displayErrorMessageBasedOnScenarioInOrOutward()}
          onChange={onInputUpdateState(setNameOfCurrentlyEditedStackedScenario)}
        />
        <TextArea
          className="StackedScenarioDescription"
          title="Description"
          value={descriptionOfCurrentlyEditedStackedScenario}
          onChange={(e) => setDescriptionOfCurrentlyEditedStackedScenario(e.target.value)}
        />

        <div className="ButtonContainer">
          <Button
            title="Cancel"
            onClick={cancelChoosingScenarios}
            secondary
          />
          <Button
            title={`Save`}
            onClick={saveChosenScenarios}
          />
        </div>
      </Card>
    </Modal>
  )
}
