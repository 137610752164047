import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Adjustment, fetchAdjustments, PREVIEW_ID } from '../backend/adjustments'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { ScenarioContext } from './ScenarioProvider'
import { FullScenarioDataContext } from './FullScenarioData/FullScenarioDataProvider'
import { StackedScenarioContext } from './StackedScenarioProvider'

export const defaultRenewalAdjustmentContext: RenewalAdjustmentsContextContainer = {
  adjustments: undefined,
  triggerReloadSavedAdjustments: () => undefined,
  changeAdjustments: () => undefined,
  currentlyEditingAdjustmentId: undefined,
  setWhichAdjustmentIsBeingEdited: () => undefined,
  currentlyPreviewingAdjustmentId: undefined,
  setWhichAdjustmentIsBeingPreviewed: () => undefined,
  currentDefaultAdjustmentNameToUse: undefined,
  setCurrentDefaultAdjustmentNameToUse: () => undefined,
}

const RenewalAdjustmentContext = createContext<RenewalAdjustmentsContextContainer>(defaultRenewalAdjustmentContext)

export interface RenewalAdjustmentsContextContainer {
  adjustments: Adjustment[] | undefined
  changeAdjustments: (adjustments: Adjustment[]) => void
  triggerReloadSavedAdjustments: () => void
  currentlyEditingAdjustmentId: string | undefined
  setWhichAdjustmentIsBeingEdited: (input: string) => void
  currentlyPreviewingAdjustmentId: string | undefined
  setWhichAdjustmentIsBeingPreviewed: (input: string) => void
  currentDefaultAdjustmentNameToUse: string | undefined
  setCurrentDefaultAdjustmentNameToUse: (input: string) => void
}

const RenewalAdjustmentsProvider = (props: PropsWithChildren) => {
  const { reloadData, setPreviewRenewal } = useContext(FullScenarioDataContext)
  const [adjustments, updateAdjustmentState] = useState<Adjustment[] | undefined>(undefined)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const scenario = currentScenario ?? currentStackedScenario
  const [currentlyEditingAdjustmentId, setWhichAdjustmentIsBeingEdited] = useState<string>('')
  const [currentlyPreviewingAdjustmentId, setWhichAdjustmentIsBeingPreviewed] = useState<string>('')
  const [currentDefaultAdjustmentNameToUse, setCurrentDefaultAdjustmentNameToUse] = useState<string | undefined>()
  const changeAdjustments = (newAdjustments: Adjustment[]) => {
    updateAdjustmentState(newAdjustments)
    const possiblePreviewAdjustment = newAdjustments?.find((item) => item.id === PREVIEW_ID)
    if (possiblePreviewAdjustment && scenario) {
      setPreviewRenewal({
        ...possiblePreviewAdjustment,
        isEnabled: true,
        subtype: possiblePreviewAdjustment.subtype as any,
      })
    } else {
      setPreviewRenewal(undefined)
    }
  }

  // The 'triggerReloadSavedAdjustmentsInternal' function makes the dependencies of useEffect Hook
  // change on every render. To fix this, wrap the definition of 'triggerReloadSavedAdjustmentsInternal' in its own
  // useCallback() Hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerReloadSavedAdjustmentsInternal = () => {
    if (!scenario) {
      return
    }
    updateIndividualProgressItem('fetchingRenewalAdjustments', ProgressState.LOADING)
    return fetchAdjustments(scenario.id)
      .then(updateAdjustmentState)
      .then(() => updateIndividualProgressItem('fetchingRenewalAdjustments', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingRenewalAdjustments', ProgressState.ERROR))
  }

  const triggerReloadSavedAdjustments = () => {
    triggerReloadSavedAdjustmentsInternal()?.then(reloadData)
  }

  // eslint-disable-next-line
  useEffect(() => {
    triggerReloadSavedAdjustmentsInternal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIndividualProgressItem, scenario])

  return (
    <RenewalAdjustmentContext.Provider
      value={{
        setWhichAdjustmentIsBeingEdited,
        currentlyEditingAdjustmentId,
        adjustments,
        triggerReloadSavedAdjustments,
        changeAdjustments,
        currentlyPreviewingAdjustmentId,
        setWhichAdjustmentIsBeingPreviewed,
        currentDefaultAdjustmentNameToUse,
        setCurrentDefaultAdjustmentNameToUse,
      }}
    >
      {props.children}
    </RenewalAdjustmentContext.Provider>
  )
}

export { RenewalAdjustmentContext, RenewalAdjustmentsProvider }
