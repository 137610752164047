import React, { MutableRefObject, useRef } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { Card } from '../card/Card'

export type DraggableCardProps<DropResult> = React.PropsWithChildren<{
  id: string
  index: number
  onHover: (dragIndex: number, hoverIndex: number) => void
  onDrop: (dragIndex: number, hoverIndex: number) => DropResult
  canDrag?: boolean
}>

const ITEM_TYPE = Symbol('CARD')

export interface DraggableItem {
  id: string
  index: number
}

interface CollectableProps {
  handlerId: string | symbol | null
}

function onDraggableHover<DragObject extends DraggableItem, DropResult>(
  currentIndex: number,
  ref: MutableRefObject<any>,
  callback: (pickedUpIndex: number, currentIndex: number) => DropResult,
) {
  return (item: DragObject, monitor: DropTargetMonitor) => {
    if (!ref.current) {
      return
    }
    const dragIndex = item.index
    const hoverIndex = currentIndex
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = ref.current!.getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset!.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }
    // Time to actually perform the action
    // moveCard(dragIndex, hoverIndex);
    callback(dragIndex, hoverIndex)
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex
  }
}

export function DraggableCard<
  DragObject extends DraggableItem,
  DropResult, // eslint-disable-line @typescript-eslint/no-unused-vars
  CollectedProps extends CollectableProps, // eslint-disable-line @typescript-eslint/no-unused-vars
>({ id, index, children, onHover, onDrop, ...otherProps }: DraggableCardProps<any>) {
  const ref: MutableRefObject<any> = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ handlerId }, dropTarget] = useDrop<DragObject, void, CollectableProps>({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: onDraggableHover(index, ref, onHover),
    drop: (item) => {
      onDrop(item.index, index)
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: otherProps.canDrag === undefined || otherProps.canDrag,
  })
  const opacity = isDragging ? 0 : 1
  drag(dropTarget(ref))
  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Card {...otherProps}>{children}</Card>
    </div>
  )
}

export default DraggableCard
