import OutwardScenarioChooserWithPortfolioListing from './OutwardScenarioChooser'
import './OutwardScenarios.scss'
import CardSideBarContainer from '../../components/card-side-bar-container/CardSideBarContainer'
import { StackedScenarioEditingProvider } from '../../components/stacked-scenarios-list/StackedScenarioEditingProvider'
import StackedOutwardScenariosSidebarList from '../../components/stacked-outward-scenario-list/StackedOutwardScenariosList'
import { StackedOutwardScenarioListProvider } from '../../providers/outwards/StackedOutwardScenarioListProvider'

function OutwardScenarios() {
  return (
    <div
      className="OutwardScenarios"
      id="OutwardScenarios"
    >
      <StackedScenarioEditingProvider>
        <StackedOutwardScenarioListProvider>
          <CardSideBarContainer
            leftColumm={<OutwardScenarioChooserWithPortfolioListing />}
            rightColumn={<StackedOutwardScenariosSidebarList />}
          />
        </StackedOutwardScenarioListProvider>
      </StackedScenarioEditingProvider>
    </div>
  )
}

export default OutwardScenarios
