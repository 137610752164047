import React, { SVGProps } from 'react'

const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <div className="Icon">
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <polygon
          id="Path"
          fill="#4E566B"
          points="14 7.33333333 14 2 8.66666667 2 10.86 4.19333333 4.19333333 10.86 2 8.66666667 2 14 7.33333333 14 5.14 11.8066667 11.8066667 5.14"
        />
      </g>
    </svg>
  </div>
)

export default ExpandIcon
