import { Dispatch } from 'react'
import { getReportMetaData, getTableState, updateReport } from 'src/backend/exposures'
import {
  fetchExceedanceProbabilityDataByReportId,
  fetchMarketShareMatrixDataByReportId,
} from 'src/backend/exposures-V2'
import { ExposureReport, ReportPayload } from './ExposureReport'
import { Action, State } from './ExposuresState'

type ExistingExposuresReportProps = {
  exposuresDispatch: Dispatch<Action>
  exposuresState: State
  scenarioId: string
}

export default function ExisitingExposuresReport(props: ExistingExposuresReportProps) {
  const { exposuresDispatch, exposuresState, scenarioId } = props

  return (
    <ExposureReport
      changePage={exposuresDispatch}
      getTableState={() => getTableState(exposuresState.reportId)}
      saveReport={(reportId: string, reportPayload: ReportPayload<'update'>) => {
        return updateReport(reportId, reportPayload)
      }}
      getReportMetaData={() => getReportMetaData(exposuresState.reportId)}
      scenarioId={scenarioId}
      reportId={exposuresState.reportId}
      tmv={exposuresState.market}
      ylt_dvid={exposuresState.ylt_dvid}
      asofdate={exposuresState.asofdate}
      getExceedanceGraphData={() => {
        return fetchExceedanceProbabilityDataByReportId(exposuresState.reportId)
      }}
      getMarketShareMatrixGraphData={() => {
        return fetchMarketShareMatrixDataByReportId(exposuresState.reportId)
      }}
    />
  )
}
