import { useContext, useState } from 'react'

import { ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION, ADJUSTMENT_NAME } from '../../../backend/calculate-kpis'
import { SummaryTreeMapWithSelectorsProvider } from './SummaryTreeMapWithSelectorsProvider'
import SummaryAdjustmentTreeMap from './SummaryAdjustmentsTreeMap'
import { MultiSelectDropdown } from '../../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../../pages/scenario-chooser/ScenarioChooser'
import { WF_TYPE_OF_DATA_COLUMN } from '../../../backend/TypeOfData'
import DropdownContainer from '../../dropdown-container/DropdownContainer'
import { GraphFilterDataContext } from '../../renewal-graph/GraphFilterDataProvider'
import { toOption } from '../../../utils/lists'

export function SummaryTreeMapWithSelectors(props: { defaultGroup1Field?: string; defaultGroup2Field?: string }) {
  const { possibleStringColumnValues } = useContext(GraphFilterDataContext)
  const [currentGroup1Field, setCurrentGroup1Field] = useState<string>(
    props.defaultGroup1Field || WF_TYPE_OF_DATA_COLUMN,
  )
  const [currentGroup2Field, setCurrentGroup2Field] = useState<string>(props.defaultGroup2Field || ADJUSTMENT_NAME)

  return (
    <>
      <DropdownContainer>
        <MultiSelectDropdown
          onSelect={onSelectSingleValueFromMulti(setCurrentGroup1Field)}
          options={possibleStringColumnValues.map(toOption)}
          selected={[toOption(currentGroup1Field)]}
        />
        <MultiSelectDropdown
          onSelect={onSelectSingleValueFromMulti(setCurrentGroup2Field)}
          options={possibleStringColumnValues.map(toOption)}
          selected={[toOption(currentGroup2Field)]}
        />
      </DropdownContainer>
      <SummaryTreeMapWithSelectorsProvider
        groupByField1ToUse={currentGroup1Field}
        groupByField2ToUse={currentGroup2Field}
        numericFieldToUse={ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION}
      >
        <div className="Graph">
          <SummaryAdjustmentTreeMap useDefaultColours={true} />
        </div>
      </SummaryTreeMapWithSelectorsProvider>
    </>
  )
}
