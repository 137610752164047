import { MouseEventHandler, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Card } from '../card/Card'
import { RouteComponentProps, withRouter } from 'react-router'

import './StackedScenarioCard.scss'

import {
  deleteStackedScenario,
  fetchStackedScenarioById,
  patchStackedScenario,
  ScenarioLevel,
  StackedScenario,
} from '../../backend/stacked-scenarios'
import ParagraphWithTitle, { Title } from '../paragraph-with-title/ParagraphWithTitle'
import { ScenarioStatusLine } from '../scenario-card/ScenarioStatusLine'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import DeleteIcon from '../icons/DeleteIcon'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import EditIcon from '../icons/EditIcon'
import { fetchScenario } from '../../backend/scenarios'
import TextField from '../text-field/WFTextField'
import { onInputUpdateState } from '../../utils/onChange'
import { css } from '../../utils/css'
import Tag, {
  getBackgroundColourForTag,
  getColourForTag,
  getDisplayForTag,
  getTooltipForTag,
  TagContainer,
} from '../tag/Tag'
import { SCENARIO_STATUS_COLORS } from '../scenario-card/ScenarioStatusColours'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { displayStatus, scenarioShouldBeColoured } from '../scenario-card/ScenarioCard'
import { StackedScenarioEditingContext } from '../stacked-scenarios-list/StackedScenarioEditingProvider'
import Button from '../button/Button'
import { deleteTag, fetchTagForEntity, TagResponse } from '../../backend/tags'
import NotesIcon from '../icons/NotesIcon'
import PreviewIcon from '../icons/PreviewIcon'
import StopPreviewIcon from '../icons/StopPreviewIcon'
import TagIcon from '../icons/TagIcon'
import StackedIcon from '../icons/StackedIcon'
import { AddingTagModalContext } from '../adding-tag-modal/AddingTagModalContext'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { useIsMounted } from '../../utils/mounting'
import { ScenarioChooserContext } from '../../pages/scenario-chooser/ScenarioChooser'
import { sanitizeId } from 'src/utils/ids'

export function onHandleDeleteTag(tagName: string, year: string, reloadTags: () => void): MouseEventHandler {
  return async (event) => {
    event.stopPropagation()
    await deleteTag(tagName, year)
    reloadTags()
  }
}

type Props = RouteComponentProps & {
  stackedScenario: StackedScenario
  triggerStackedScenarioReload: () => void
  creatingIsSelected: boolean
  creatingOnToggle: () => void
  ableToEditUnderlyingScenarios?: boolean
  selectable: boolean
  isComparing: boolean
  comparingIsSelected: boolean
  className?: string
  comparingOnToggle: () => void
}

function StackedScenarioCard(props: Props): ReactElement {
  const {
    stackedScenario,
    triggerStackedScenarioReload,
    creatingIsSelected,
    creatingOnToggle,
    ableToEditUnderlyingScenarios,
    selectable,
    isComparing,
    comparingIsSelected,
    className,
    comparingOnToggle,
    ...otherProps
  } = props

  const { progressIndicators, updateIndividualProgressItem } = useContext(ProgressContext)

  const isMounted = useIsMounted()

  const {
    currentlyEditingStackedScenarioId,
    currentlyCreatingStackedScenario,
    setCurrentlyChosenScenarioIds,
    setCurrentlyCreatingStackedScenario,
    currentlyChosenScenarioIds,
    setCurrentlyEditingStackedScenarioId,
    descriptionOfCurrentlyEditedStackedScenario,
    setDescriptionOfCurrentlyEditedStackedScenario,
    nameOfCurrentlyEditedStackedScenario,
    setNameOfCurrentlyEditedStackedScenario,
    currentlyPreviewingStackedScenarioId,
    setCurrentlyPreviewingStackedScenarioId,
  } = useContext(StackedScenarioEditingContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { setEntityId, setDivision } = useContext(AddingTagModalContext)
  const { hideUntagged } = useContext(ScenarioChooserContext)

  const [tags, setListOfTags] = useState<TagResponse[]>([])

  const reloadTags = useCallback(async () => {
    const listOfTags = await fetchTagForEntity(stackedScenario.id)
    if (isMounted()) setListOfTags(listOfTags)
  }, [stackedScenario.id, isMounted])

  useEffect(() => {
    reloadTags()
  }, [progressIndicators.createStackedScenario, reloadTags])

  const onDelete: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('deleteStackedScenario', ProgressState.LOADING)
    try {
      await deleteStackedScenario(stackedScenario.id)
      triggerStackedScenarioReload()
      updateIndividualProgressItem('deleteStackedScenario', ProgressState.FINISHED)
    } catch (error) {
      updateIndividualProgressItem('deleteStackedScenario', ProgressState.ERROR)
    }
  }

  const onOpenTagModal: MouseEventHandler = (event) => {
    event.stopPropagation()
    setEntityId(stackedScenario.id)
    setDivision(stackedScenario.division)
  }

  const clearChosenScenarios: MouseEventHandler = (event) => {
    event.stopPropagation()
    setCurrentlyChosenScenarioIds(undefined)
    setCurrentlyCreatingStackedScenario(false)
    setCurrentlyPreviewingStackedScenarioId(undefined)
  }

  const highlightCurrentScenarios = async (isCreatingStackedScenario: boolean) => {
    if (ableToEditUnderlyingScenarios) {
      if (stackedScenario.scenarioLevel === ScenarioLevel.TEAM) {
        const allScenarioDataPromises = (await Promise.all(stackedScenario.subScenarioIds.map(fetchScenario))).filter(
          (item) => item !== undefined,
        )

        const allScenarioData = allScenarioDataPromises.map((scenario) => [scenario!.market!, scenario!.id!])

        setCurrentlyCreatingStackedScenario(isCreatingStackedScenario)
        setCurrentlyChosenScenarioIds(Object.fromEntries(allScenarioData))
      } else {
        const allStackedScenarioDataPromises = (
          await Promise.all(stackedScenario.subScenarioIds.map(fetchStackedScenarioById))
        ).filter((item) => item !== undefined)

        const allScenarioData = allStackedScenarioDataPromises.map((stackedScenario) => [
          stackedScenario?.scenarioLevel === ScenarioLevel.DIVISIONAL
            ? stackedScenario.division!
            : stackedScenario?.team,
          stackedScenario!.id!,
        ])

        setCurrentlyCreatingStackedScenario(isCreatingStackedScenario)
        setCurrentlyChosenScenarioIds(Object.fromEntries(allScenarioData))
      }
    }
  }

  const onStartPreviewStackedScenario: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('startToPreviewStackedScenario', ProgressState.LOADING)

    await highlightCurrentScenarios(false)

    setCurrentlyPreviewingStackedScenarioId(stackedScenario.id)
    updateIndividualProgressItem('startToPreviewStackedScenario', ProgressState.FINISHED)
  }

  const onStartEditStackedScenario: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('startToEditStackedScenario', ProgressState.LOADING)

    await highlightCurrentScenarios(true)

    setCurrentlyEditingStackedScenarioId(stackedScenario.id)
    setNameOfCurrentlyEditedStackedScenario(stackedScenario.name)
    setDescriptionOfCurrentlyEditedStackedScenario(stackedScenario.description)
    updateIndividualProgressItem('startToEditStackedScenario', ProgressState.FINISHED)
  }

  const onNotes: MouseEventHandler = (event) => {
    event.stopPropagation()
    otherProps.history.push(`/stacked-scenarios/${stackedScenario.id}/notes`)
  }

  const stopEditing: MouseEventHandler = async (event) => {
    event.stopPropagation()
    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
  }

  const isTaggedStackedScenario = (): boolean => {
    const found = tags.find((tag) => tag.name === 'CURRENT_ESTIMATE')
    if (found === undefined) {
      return false
    }
    return true
  }

  const patchNameAndStopEditing: MouseEventHandler = async (event) => {
    event.stopPropagation()

    updateIndividualProgressItem('patchStackedScenario', ProgressState.LOADING)

    await patchStackedScenario(stackedScenario.id, {
      name: nameOfCurrentlyEditedStackedScenario,
      description: descriptionOfCurrentlyEditedStackedScenario!,
    })

    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
    triggerStackedScenarioReload()

    updateIndividualProgressItem('patchStackedScenario', ProgressState.FINISHED)
  }
  const hasBeenSelected =
    currentlyChosenScenarioIds && Object.values(currentlyChosenScenarioIds).includes(stackedScenario.id)
  const somethingIsSelected =
    currentlyChosenScenarioIds &&
    (currentlyChosenScenarioIds[stackedScenario.team] !== undefined ||
      currentlyChosenScenarioIds[stackedScenario.division] !== undefined)

  const isEditingThisCard = useMemo(
    () => currentlyEditingStackedScenarioId === stackedScenario.id,
    [stackedScenario, currentlyEditingStackedScenarioId],
  )

  const isPreviewingThisCard = useMemo(
    () => currentlyPreviewingStackedScenarioId === stackedScenario.id,
    [stackedScenario, currentlyPreviewingStackedScenarioId],
  )

  const canWriteToCard = stackedScenario.status !== ScenarioStatus.COMPLETE

  const cardOnClick = () => {
    if (isEditingThisCard) {
      return undefined
    }
    if (isComparing) {
      return comparingOnToggle
    }
    if (currentlyCreatingStackedScenario) {
      return creatingOnToggle
    }
    return () => otherProps.history.push(`/stacked-scenarios/${stackedScenario.id}/summary`)
  }

  const isInactive =
    (somethingIsSelected || currentlyPreviewingStackedScenarioId) && !hasBeenSelected && !isPreviewingThisCard

  const isHidden = tags.length === 0 && hideUntagged

  return isHidden ? (
    <div />
  ) : (
    <div
      className={css(
        'StackedScenarioCard',
        className,
        currentlyCreatingStackedScenario && !selectable && !isEditingThisCard && !isPreviewingThisCard
          ? 'StackedScenarioCardNotSelectable'
          : '',
        isInactive ? 'StackedScenarioCardInactive' : '',
      )}
      onClick={cardOnClick()}
    >
      <Card className={scenarioShouldBeColoured(stackedScenario.name) ? 'ActivityOneColoured' : ''}>
        <ScenarioStatusLine status={stackedScenario.status} />
        <div className="Content">
          <div className="CardHeader">
            {!isEditingThisCard && <h4 className="StackedScenarioCardTitle">{stackedScenario.name}</h4>}
            {isComparing && (
              <div className="CardOptions">
                <input
                  type="checkbox"
                  id={`${sanitizeId(`${stackedScenario.division}-${stackedScenario.team}-${stackedScenario.name}`)}`}
                  checked={comparingIsSelected}
                  onChange={comparingOnToggle}
                />
              </div>
            )}
            {!isComparing && !currentlyCreatingStackedScenario && !isEditingThisCard && (
              <div className="CardOptions">
                <div className="LeftCardOptions">
                  <div title="Stacked Scenario">
                    <StackedIcon
                      className="StackedButton"
                      id={`${stackedScenario.name}-Stacked`}
                    />
                  </div>
                </div>
                <div className="RightCardOptions">
                  {!selectable &&
                    (currentlyChosenScenarioIds === undefined ||
                    Object.keys(currentlyChosenScenarioIds).length === 0 ? (
                      <div title="View">
                        <PreviewIcon
                          className="PreviewButton"
                          id={`${stackedScenario.name}-Preview`}
                          onClick={onStartPreviewStackedScenario}
                        />
                      </div>
                    ) : isPreviewingThisCard ? (
                      <div title="Stop Viewing">
                        <StopPreviewIcon
                          className="PreviewButton"
                          id={`${stackedScenario.name}-Stop-Viewing`}
                          onClick={clearChosenScenarios}
                        />
                      </div>
                    ) : (
                      <div title="View">
                        <PreviewIcon
                          className="PreviewButton"
                          id={`${stackedScenario.name}-Preview`}
                          onClick={onStartPreviewStackedScenario}
                        />
                      </div>
                    ))}
                  {isAdmin &&
                    stackedScenario.scenarioLevel === ScenarioLevel.ORGANISATIONAL &&
                    !isTaggedStackedScenario() && (
                      <div title="Tags">
                        <TagIcon
                          className="TagButton"
                          id={`${stackedScenario.name}-Tags`}
                          onClick={onOpenTagModal}
                        />
                      </div>
                    )}
                  {canWriteToCard && !isTaggedStackedScenario() && (
                    <div title="Edit">
                      <EditIcon
                        className="EditButton"
                        id={`${stackedScenario.name}-Edit`}
                        onClick={onStartEditStackedScenario}
                      />
                    </div>
                  )}
                  {!isTaggedStackedScenario() && (
                    <div title="Notes">
                      <NotesIcon
                        className="NotesButton"
                        id={`${stackedScenario.name}-Notes`}
                        onClick={onNotes}
                      />
                    </div>
                  )}
                  {canWriteToCard && !isTaggedStackedScenario() && (
                    <div title="Delete">
                      <DeleteIcon
                        className="DeleteButton"
                        id={`${stackedScenario.name}-Delete`}
                        onClick={onDelete}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isComparing && currentlyCreatingStackedScenario && selectable && (
              <div className="CardOptions">
                <input
                  type="checkbox"
                  id={`${sanitizeId(`${stackedScenario.division}-${stackedScenario.team}-${stackedScenario.name}`)}`}
                  checked={creatingIsSelected}
                  onChange={creatingOnToggle}
                />
              </div>
            )}
          </div>
          {isEditingThisCard && (
            <TextField
              className="Name"
              id={`${stackedScenario.name}-TextField-Name`}
              placeholder="Name"
              title="Name"
              value={nameOfCurrentlyEditedStackedScenario}
              onChange={onInputUpdateState(setNameOfCurrentlyEditedStackedScenario)}
            />
          )}
          <div className="CardContent">
            <div className="Options">
              <div className="LeftOptions">
                <ParagraphWithTitle
                  title="Status"
                  id={`${stackedScenario.name}-Status`}
                  content={displayStatus[stackedScenario.status || ScenarioStatus.IN_PROGRESS]}
                  className="Status"
                />
                <div className="ScenarioStatus">
                  <Title title="Sub-scenario Status" />
                  <TagContainer>
                    {Object.entries(stackedScenario.subStatuses)
                      .filter(
                        ([statusIdentifier, numberInStatus]) =>
                          statusIdentifier !== ScenarioStatus.DELETED || numberInStatus > 0,
                      )
                      .map(([statusIdentifier, numberInStatus]) => (
                        <Tag
                          title={numberInStatus.toString()}
                          textColor={
                            (statusIdentifier as ScenarioStatus) === ScenarioStatus.IN_REVIEW ? '#1A1F36' : 'white'
                          }
                          backgroundColor={SCENARIO_STATUS_COLORS[statusIdentifier as ScenarioStatus]}
                          key={`${stackedScenario.id}-${statusIdentifier}`}
                          tooltip={formatEnumForDisplay(statusIdentifier) + ' - ' + numberInStatus}
                          id={`${stackedScenario.name}-Sub-Scenario-Status-${statusIdentifier}`}
                        />
                      ))}
                  </TagContainer>
                </div>
              </div>
              <div className="RightOptions">
                <ParagraphWithTitle
                  title="Updated"
                  id={`${stackedScenario.name}-Updated`}
                  content={
                    Boolean(stackedScenario.updatedAt)
                      ? new Date(stackedScenario.updatedAt).toLocaleDateString('en-GB')
                      : '-'
                  }
                  className="Updated"
                />
                {(!!tags.length || isEditingThisCard) && (
                  <div className="Tags">
                    <Title title={'Tags'} />
                    <TagContainer>
                      {tags.map((tagObject) => (
                        <Tag
                          title={getDisplayForTag(tagObject.name)}
                          textColor={getColourForTag(tagObject.name)}
                          backgroundColor={getBackgroundColourForTag(tagObject.name)}
                          key={`${stackedScenario.id}-${tagObject.name}`}
                          tooltip={getTooltipForTag(tagObject.name)}
                          onDelete={isEditingThisCard && onHandleDeleteTag(tagObject.name, tagObject.year, reloadTags)}
                          id={stackedScenario.name + '-' + getDisplayForTag(tagObject.name)}
                        />
                      ))}
                    </TagContainer>
                  </div>
                )}
              </div>
            </div>
            {stackedScenario.description && (
              <div className="StackedScenarioCardSummary">
                <p>{stackedScenario.description}</p>
              </div>
            )}
          </div>
          {!currentlyCreatingStackedScenario && isEditingThisCard && (
            <div className="ButtonContainer">
              <Button
                title="Cancel"
                id="Edit-Stacked-Scenario-Card-Button-Cancel"
                secondary
                onClick={stopEditing}
              />
              <Button
                title={'Save'}
                onClick={patchNameAndStopEditing}
                id="Edit-Stacked-Scenario-Card-Button-Save"
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default withRouter(StackedScenarioCard)
