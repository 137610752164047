import { createContext, PropsWithChildren, useState } from 'react'
import {
  FilterValues,
  isNameListFilter,
  isNegatedNumericFilter,
  isNegatedStringFilter,
  isNumericFilter,
} from '../../backend/calculate-possible-filter-values'
import { IndividualFilterValue, ManyDropdownFilterValues } from './model'

export function transformIntoFlatStructure(componentState: ManyDropdownFilterValues): FilterValues {
  return Object.values(componentState).reduce((fullValueSoFar: any, example: IndividualFilterValue | undefined) => {
    if (!example || !example?.chosenValue || !example?.columnName) {
      console.warn('MISSING VAR')
      return fullValueSoFar
    }
    const { chosenValue, columnName } = example

    if (isNameListFilter(chosenValue) && chosenValue.length) {
      const next = { [columnName]: chosenValue }
      return { ...fullValueSoFar, ...next }
    } else if (isNegatedStringFilter(chosenValue) && chosenValue.valuesToBeIgnored.length) {
      const next = { [columnName]: chosenValue }
      return { ...fullValueSoFar, ...next }
    } else if (isNumericFilter(chosenValue)) {
      const next = { [columnName]: chosenValue }
      return { ...fullValueSoFar, ...next }
    } else if (isNegatedNumericFilter(chosenValue)) {
      const next = { [columnName]: chosenValue }
      return { ...fullValueSoFar, ...next }
    } else {
      return fullValueSoFar
    }
  }, {})
}

export const defaultFilterContext: FilterContextContainer = {
  dropdownFilterOptions: {},
  onChangeFilters: () => undefined,
}
export const FilterContext = createContext<FilterContextContainer>(defaultFilterContext)

export interface FilterContextContainer {
  dropdownFilterOptions: ManyDropdownFilterValues
  onChangeFilters: (values: ManyDropdownFilterValues) => void
}

export const FilterProvider = (props: PropsWithChildren) => {
  const [dropdownFilterOptions, setDropdownFilterOptions] = useState<ManyDropdownFilterValues>({})

  const onChangeFilters = (values: ManyDropdownFilterValues) => {
    setDropdownFilterOptions(values)
  }

  return (
    <FilterContext.Provider value={{ dropdownFilterOptions, onChangeFilters }}>{props.children}</FilterContext.Provider>
  )
}
