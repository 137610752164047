import { ScenarioComparisonRows } from './ScenarioComparisonRows'
import { CssGridItem } from '../css-grid/CssGridRows'
import { ScenarioFilterCard } from '../scenario-filter-card/ScenarioFilterCard'

export function ScenarioComparisonHeadersColumn() {
  return (
    <>
      {ScenarioComparisonRows.map((currentRow, currentRowIndex) => (
        <CssGridItem
          columnNumber={1}
          rowNumber={currentRowIndex + 1}
          key={`scenario-header-${currentRow.title}`}
          additionalCssClassNames={currentRow.additionalCssClassNames}
        >
          {currentRowIndex === 0 ? <ScenarioFilterCard /> : <h3>{currentRow.title}</h3>}
        </CssGridItem>
      ))}
    </>
  )
}
