import React, { SVGProps } from 'react'

const ScenariosIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        fill="#4E566B"
        fillRule="evenodd"
        d="M13.3333333,4.66666667 L2.66666667,4.66666667 L2.66666667,6 L13.3333333,6 L13.3333333,4.66666667 Z M2.66666667,8.66666667 L13.3333333,8.66666667 L13.3333333,7.33333333 L2.66666667,7.33333333 L2.66666667,8.66666667 Z M2.66666667,11.3333333 L13.3333333,11.3333333 L13.3333333,10 L2.66666667,10 L2.66666667,11.3333333 Z"
      />
    </svg>
  </div>
)

export default ScenariosIcon
