import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import {
  calculatePossibleNumericFilterValues,
  calculatePossibleStringFilterValuesWithExtras,
  combineFilters,
  FilterValues,
  GRAPH_THINGS_TO_USE_AS_NUMERIC_FILTERS,
} from '../../backend/calculate-possible-filter-values'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { RawEntry } from '../../backend/rawEntry'

export const GraphFilterDataContext = createContext<GraphFilterData>({
  fullDataForScenario: [],
  possibleFilterValues: {},
  possibleFilterValuesForSummary: {},
  allPossibleColumnValues: [],
  allPossibleColumnValuesForSummary: [],
  possibleNumericColumnValues: [],
  possibleStringColumnValues: [],
  extraColumnsToInclude: [],
  setExtraColumnsToInclude: (extraColumns: string[]) => void 0,
})

export interface GraphFilterData {
  fullDataForScenario: RawEntry[]
  possibleFilterValues: FilterValues
  possibleFilterValuesForSummary: FilterValues
  allPossibleColumnValues: string[]
  allPossibleColumnValuesForSummary: string[]
  possibleNumericColumnValues: string[]
  possibleStringColumnValues: string[]
  extraColumnsToInclude: string[]
  setExtraColumnsToInclude: (extraColumns: string[]) => void
}

type RenewalGraphFilterDataProps = PropsWithChildren<{
  extraFilterValues?: string[]
  onesToIgnore: string[]
}>

export const RenewalGraphFilterDataProvider = (props: RenewalGraphFilterDataProps) => {
  const [possibleFilterValues, setPossibleFilterValues] = useState<FilterValues>({})
  const [possibleFilterValuesForSummary, setPossibleFilterValuesForSummary] = useState<FilterValues>({})
  const [possibleNumericFilterValues, setPossibleNumericFilterValues] = useState<FilterValues>({})
  const [possibleStringFilterValues, setPossibleStringFilterValues] = useState<FilterValues>({})
  const [extraColumnsToInclude, setExtraColumnsToInclude] = useState<Array<string>>([])
  const { fullDataForScenario } = useContext(FullScenarioDataContext)

  const stringVersionOfExtraColumnArrayForPerformanceReasons = JSON.stringify(extraColumnsToInclude)

  useEffect(() => {
    const numericFilterValues = calculatePossibleNumericFilterValues(
      fullDataForScenario,
      GRAPH_THINGS_TO_USE_AS_NUMERIC_FILTERS,
    )
    const stringFilterValues = calculatePossibleStringFilterValuesWithExtras(
      fullDataForScenario,
      [...extraColumnsToInclude, ...(props.extraFilterValues || [])],
      false,
    )
    const stringFilterValuesForSummary = calculatePossibleStringFilterValuesWithExtras(
      fullDataForScenario,
      [...extraColumnsToInclude, ...(props.extraFilterValues || [])],
      true,
    )

    props.onesToIgnore?.forEach((optionToIgnore) => {
      delete stringFilterValues[optionToIgnore]
      delete numericFilterValues[optionToIgnore]
    })

    const allFilters: FilterValues = combineFilters(stringFilterValues, numericFilterValues)
    const allFiltersForSummary: FilterValues = combineFilters(stringFilterValuesForSummary, numericFilterValues)
    setPossibleFilterValues(allFilters)
    setPossibleFilterValuesForSummary(allFiltersForSummary)
    setPossibleNumericFilterValues(numericFilterValues)
    setPossibleStringFilterValues(stringFilterValues)
    //eslint-disable-next-line
  }, [fullDataForScenario, props.onesToIgnore, stringVersionOfExtraColumnArrayForPerformanceReasons])

  const allPossibleColumnValues = Object.keys(possibleFilterValues)
  const allPossibleColumnValuesForSummary = Object.keys(possibleFilterValuesForSummary)
  const possibleNumericColumnValues = Object.keys(possibleNumericFilterValues)
  const possibleStringColumnValues = Object.keys(possibleStringFilterValues)

  return (
    <GraphFilterDataContext.Provider
      value={{
        fullDataForScenario,
        possibleFilterValues,
        possibleFilterValuesForSummary,
        allPossibleColumnValues,
        allPossibleColumnValuesForSummary,
        possibleNumericColumnValues,
        possibleStringColumnValues,
        extraColumnsToInclude,
        setExtraColumnsToInclude,
      }}
    >
      {props.children}
    </GraphFilterDataContext.Provider>
  )
}
