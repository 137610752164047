import { useContext } from 'react'
import OutwardScenarioCoverageContent from './OutwardScenarioCoverageContent'
import OutwardRulesContainer from '../../components/outward-rules-container/OutwardRulesContainer'
import { OutwardScenarioRuleProvider } from '../../providers/outwards/OutwardScenarioRulesProvider'
import { withRouter } from 'react-router'
import './OutwardScenarioCoverage.scss'
import { OutwardsDataProvider } from '../../providers/outwards/OutwardsDataProvider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { FixedStackedScenarioProvider } from '../../providers/StackedScenarioProvider'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import { OutwardsApplicablePerilsProvider } from '../../providers/outwards/OutwardsApplicablePerilsProvider'
import { OutwardsExcludedPerilsProvider } from '../../providers/outwards/OutwardsExcludedPerilsProvider'
import { suffixForValue, ZERO } from '../../utils/numbers'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import KpiBar from '../../components/kpi-bar/KpiBar'
import KpiCard from '../../components/kpi-card/KpiCard'
import { CLIENT_NAME_COLUMN_IDENTIFIER, TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { useResetFiltersOnFirstRender } from '../../providers/FilterProvider/useResetFiltersOnFirstRender'

function OutwardScenarioCoverage(): JSX.Element {
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { filteredRenewalKpis, filteredNewKpis } = useContext(FullScenarioDataContext)

  useResetFiltersOnFirstRender()

  const totalInwardsGwp =
    filteredRenewalKpis && filteredNewKpis
      ? filteredRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN].add(filteredNewKpis[TOTAL_ADJUSTED_GWP_COLUMN])
      : ZERO

  if (!currentOutwardScenario) {
    return <div />
  }

  return (
    <div
      className="OutwardCoverage"
      id="OutwardCoverage"
    >
      <SplitContentContainerWithSideMenu
        leftColumm={<OutwardScenarioCoverageContent />}
        rightColumn={<OutwardRulesContainer />}
        sideMenu={
          <KpiBar>
            <KpiCard
              title="Inwards GWP"
              value={totalInwardsGwp}
              prefix="$"
              suffix={suffixForValue(totalInwardsGwp)}
            />
          </KpiBar>
        }
      />
    </div>
  )
}

const CoverageWithFilter = (): JSX.Element => {
  const outwardScenarioContext = useContext(OutwardScenarioContext)

  return (
    <FixedStackedScenarioProvider stackedScenarioId={outwardScenarioContext?.inwardScenarioIdToUse || ''}>
      <StackedScenarioDataProvider>
        <RenewalGraphFilterDataProvider onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}>
          <OutwardScenarioRuleProvider>
            <OutwardsDataProvider>
              <OutwardsApplicablePerilsProvider>
                <OutwardsExcludedPerilsProvider>
                  <OutwardScenarioCoverage />
                </OutwardsExcludedPerilsProvider>
              </OutwardsApplicablePerilsProvider>
            </OutwardsDataProvider>
          </OutwardScenarioRuleProvider>
        </RenewalGraphFilterDataProvider>
      </StackedScenarioDataProvider>
    </FixedStackedScenarioProvider>
  )
}

export default withRouter(CoverageWithFilter)
