import { Input, Text } from '@chakra-ui/react'
import { ChangeEvent, ReactElement, useState } from 'react'
import { Cell } from './DynamicTable'

type DynamicTableInputProps = {
  currentCell: Cell<number, number>
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  updateDependencies: () => void
  disabled: boolean
  maxLossesInvalid: dynamicInputValidation
}

export function DynamicTableInput(props: DynamicTableInputProps): ReactElement {
  const { currentCell, updateDependencies, onChange, disabled, maxLossesInvalid } = props

  const [focussed, setFocussed] = useState<boolean>(false)
  let cellInvalid = false

  if (maxLossesInvalid.invalid && maxLossesInvalid.invalidCells.includes(currentCell.id)) {
    cellInvalid = true
  }

  const onFocus = () => {
    setFocussed(true)
  }

  const onBlur = () => {
    setFocussed(false)
    updateDependencies()
  }

  if (currentCell.error) {
    return (
      <Text
        textColor={'red'}
        textAlign="center"
      >
        {currentCell.error.message}
      </Text>
    )
  }

  return (
    <Input
      type={focussed ? 'number' : 'text'}
      textAlign={'center'}
      key={currentCell.id}
      value={focussed ? currentCell.data : formatInputDisplay(currentCell.data)}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={currentCell.disabled ? currentCell.disabled : disabled}
      readOnly={currentCell.readonly}
      size={'sm'}
      variant={currentCell.readonly ? 'filled' : 'outline'}
      isInvalid={isInvalid(currentCell) || cellInvalid}
      borderRadius={'md'}
    />
  )
}

function formatInputDisplay(val: number): string {
  if (val === 0) {
    return ''
  }

  return (Math.round(val * 100) / 100).toLocaleString('en-GB')
}

export function isInvalid(cell: Cell<number, number>): boolean {
  const bounds = cell?.validation?.bounds?.fixed
  if (!bounds) {
    return false
  }

  const min = bounds.min
  const max = bounds.max

  if (min !== undefined && max !== undefined) {
    return cell.data < min || cell.data > max
  }

  if (min !== undefined) {
    return cell.data < min
  }

  if (max !== undefined) {
    return cell.data > max
  }

  return false
}

type dynamicInputValidation = {
  invalid: boolean
  invalidCells: number[]
}

export function dynamicInputInvalid(cells: Cell<number, number>[]): dynamicInputValidation {
  let flag = false
  let invalidCells: number[] = []
  for (let i = 0; i < cells.length; i++) {
    let bounds = cells[i]?.validation?.bounds?.dynamic
    if (bounds?.lower) {
      const min = cells[bounds.lower].data
      if (min !== undefined) {
        if (cells[i].data > 0 && cells[i].data < min) {
          flag = true
          invalidCells.push(i)
        }
      }
    }
  }

  return { invalid: flag, invalidCells: invalidCells }
}
