import React, { useContext, useState } from 'react'
import Modal from 'react-modal'
import { Card } from '../card/Card'

import './Tag.scss'

import Button from '../../components/button/Button'
import { createTag } from '../../backend/tags'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { STACKED_SCENARIO_AVAILABLE_TAGS, SCENARIO_AVAILABLE_TAGS } from './Tag'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { AddingTagModalContext } from '../adding-tag-modal/AddingTagModalContext'

type Props = {}

export function FinishCreatingTagModal(props: Props): React.ReactElement {
  const { entityId, setEntityId, market } = useContext(AddingTagModalContext)
  const { yearQuery } = useContext(AppQueryContext)

  const [currentTagName, setCurrentTagName] = useState<string>()

  const [currentYear] = yearQuery

  const exitModal = () => {
    setEntityId(undefined)
  }

  const save = async () => {
    if (!currentTagName) {
      return
    }

    await createTag(currentTagName!, currentYear!, entityId!, market)
    exitModal()
    window.location.reload() //Hacky way of getting everything to update
  }

  const headerText = (): string => {
    if (market === undefined) {
      return `Add a tag to the Stacked Scenario`
    }
    return `Add a tag to the Scenario`
  }

  const getAvailableTags = (): string[] => {
    if (market === undefined) {
      return Object.keys(STACKED_SCENARIO_AVAILABLE_TAGS)
    }
    return Object.keys(SCENARIO_AVAILABLE_TAGS)
  }

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={Boolean(entityId)}
      className="TagModal"
      overlayClassName="ModalOverlay"
    >
      <Card>
        <h3 className="StackedScenarioModalTitle">{headerText()}</h3>

        <MultiSelectDropdown
          onSelect={(options) => setCurrentTagName(options[0]?.value)}
          selected={currentTagName ? [{ label: formatEnumForDisplay(currentTagName), value: currentTagName }] : []}
          className="ColumnFilter"
          placeholder="Choose a field"
          options={getAvailableTags().map((item) => ({ label: formatEnumForDisplay(item), value: item }))}
        />

        <div className="ButtonContainer">
          <Button
            title="Cancel"
            id="Stacked-Scenario-Modal-Button-Cancel"
            onClick={exitModal}
            secondary
          />
          <Button
            title={`Save`}
            id="Stacked-Scenario-Modal-Button-Save"
            onClick={save}
          />
        </div>
      </Card>
    </Modal>
  )
}
