import { useContext, useMemo } from 'react'
import { RouterProps, withRouter } from 'react-router'
import qs from 'qs'
import { StringParam, useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'

import './StackedScenarioComparison.scss'

import PageHeaderContainer from '../../components/page-header-container/PageHeaderContainer'
import PageHeaderText from '../../components/page-header-text/PageHeaderText'
import ContentContainer from '../../components/content-container/ContentContainer'
import { CssGridItem } from '../../components/css-grid/CssGridRows'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import Button from '../../components/button/Button'
import { StackedScenarioComparisonHeadersColumn } from '../../components/stacked-scenario-comparison/StackedScenarioComparisonHeadersColumn'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import {
  StackedScenarioComparisonContext,
  StackedScenarioInitialDataProvider,
} from '../../providers/StackedScenarioComparisonProvider'
import { StackedScenarioComparisonRows } from '../../components/stacked-scenario-comparison/StackedScenarioComparisonRows'
import { NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW } from '../../components/parameterisation-table/parameterisation-table'
import { FilterProvider } from '../../providers/FilterProvider'
import {
  ScenarioComparisonDataSwallower,
  ScenarioComparisonFullDataAvailableProvider,
} from '../../providers/ScenarioComparisonFullDataAvailableProvider'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { GraphStyleContext } from '../../components/renewal-graph/GraphStyleProvider'
import Graphs from '../../components/graphs/Graphs'
import { GraphContainer } from '../../components/graph-container/GraphContainer'
import { GraphHeader } from '../../components/graph-header/GraphHeader'
import { ComparisonGwpCumulativeLineChartDataProvider } from '../../components/renewal-graph/gwp-cumulative-line-chart/GwpCumulativeLineChartDataProvider'
import { GwpAndAdjustedGwpCumulativeLineChart } from '../../components/renewal-graph/gwp-cumulative-line-chart/GwpAndAdjustedGwpCumulativeLineChart'
import { GraphDataContext } from '../../components/renewal-graph/GraphDataProvider'
import {
  formatInceptionYearForDisplay,
  sortInceptionMonth,
} from '../../components/renewal-graph/gwp-bar-chart/inception-month-graph'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../backend/calculate-with-actuals'
import {
  AggregatedMarketDataContextV2,
  AggregatedMarketDataProviderV2,
} from '../../providers/AggregatedMarketDataProviderV2'
import { AppQueryContext } from '../../providers/AppQueryProvider'

function getStackedScenarioIds(searchString: string): string[] {
  const queryParamWithoutLeadingQMark = searchString.slice(1)
  const parsedQueryParam = qs.parse(queryParamWithoutLeadingQMark)['stackedScenarioIds']
  const scenarioIds: Array<string> =
    typeof parsedQueryParam === 'string' ? [parsedQueryParam] : (parsedQueryParam as string[])

  return scenarioIds
}

function StackedScenarioComparisonColumnContent(props: {
  stackedScenarioId: string
  columnNumber: number
}): JSX.Element {
  const stackedScenarioContainer = useContext(StackedScenarioComparisonContext)
  const fullScenarioContext = useContext(FullScenarioDataContext)
  const stackedMarketContext = useContext(AggregatedMarketDataContextV2)

  return (
    <>
      {StackedScenarioComparisonRows.map((currentRow, currentRowIndex) => (
        <CssGridItem
          columnNumber={props.columnNumber}
          rowNumber={currentRowIndex + 1}
          key={`${props.stackedScenarioId}-${currentRow.title}-value`}
          classNameOverride={currentRow.customCssClassName}
          additionalCssClassNames={currentRow.additionalCssClassNames}
        >
          {currentRow.perScenarioContentGenerator({
            stackedScenarioContainer: stackedScenarioContainer,
            fullScenarioContext,
            marketContext: stackedMarketContext,
          })}
        </CssGridItem>
      ))}
    </>
  )
}

interface StackedScenarioComparisonProps {
  stackedScenarioId: string
  columnNumber: number
}

function StackedScenarioComparisonColumnWithProviders(props: StackedScenarioComparisonProps): JSX.Element {
  //StackedScenarioDataProvider here is overriding ScenarioComparisonFullDataAvailableProvider
  return (
    <StackedScenarioInitialDataProvider stackedScenarioId={props.stackedScenarioId}>
      <StackedScenarioDataProvider>
        <AggregatedMarketDataProviderV2>
          <ScenarioComparisonDataSwallower />
          <StackedScenarioComparisonColumnContent {...props} />
        </AggregatedMarketDataProviderV2>
      </StackedScenarioDataProvider>
    </StackedScenarioInitialDataProvider>
  )
}

function StackedScenarioComparison(props: RouterProps): JSX.Element {
  const stackedScenarioIds = useMemo<Array<string>>(
    () => getStackedScenarioIds(props.history.location.search),
    [props.history.location.search],
  )

  const [actualDataItemName] = useQueryParam('actuals', StringParam)
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  const { yearQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [yearFromQuery] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  const backToHomePage = useMemo(
    () => () => {
      const queryParams = {
        division: divisionFromQuery,
        team: teamFromQuery,
        year: yearFromQuery,
        actuals: actualDataItemName,
      }

      const fullRoute = `/scenario-chooser?${qs.stringify(queryParams)}`
      props.history.push(fullRoute)
    },
    [divisionFromQuery, teamFromQuery, yearFromQuery, actualDataItemName, props.history],
  )

  return (
    <div className="ScenarioComparison">
      <FilterProvider>
        <ScenarioComparisonFullDataAvailableProvider>
          <RenewalGraphFilterDataProvider onesToIgnore={[]}>
            <PageHeaderContainer>
              <PageHeaderText>Stacked Scenario Comparison</PageHeaderText>
              <Button
                title={'Back'}
                onClick={backToHomePage}
              />
            </PageHeaderContainer>
            <ContentContainer>
              {!graphPanelIsOpen && (
                <div className="ScenarioComparisonGrid">
                  <StackedScenarioComparisonHeadersColumn />
                  {stackedScenarioIds.map((currentId, currentIndex) => (
                    <StackedScenarioComparisonColumnWithProviders
                      stackedScenarioId={currentId}
                      key={currentId}
                      columnNumber={currentIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
                    />
                  ))}
                </div>
              )}
              {graphPanelIsOpen && (
                <GraphStyleContext.Provider
                  value={{ currentlyExpandedGraph: 'OriginalVsAdjustedGwp', setCurrentlyExpandedGraph: () => void 0 }}
                >
                  <Graphs>
                    <GraphContainer
                      className="GraphRight"
                      graphId="OriginalVsAdjustedGwp"
                    >
                      <GraphHeader title="Original vs Adjusted GWP" />
                      <div className="Graph CumulativeLineChart">
                        <GraphDataContext.Provider
                          value={{
                            summarisedData: [], //Not used
                            sortDataFunction: sortInceptionMonth,
                            formatDataFieldForDisplay: formatInceptionYearForDisplay,
                            fieldNameToGroupBy: RENEWAL_INCEPTION_MONTH_COLUMN_NAME,
                          }}
                        >
                          <ComparisonGwpCumulativeLineChartDataProvider>
                            <GwpAndAdjustedGwpCumulativeLineChart />
                          </ComparisonGwpCumulativeLineChartDataProvider>
                        </GraphDataContext.Provider>
                      </div>
                    </GraphContainer>
                  </Graphs>
                </GraphStyleContext.Provider>
              )}
            </ContentContainer>
          </RenewalGraphFilterDataProvider>
        </ScenarioComparisonFullDataAvailableProvider>
      </FilterProvider>
    </div>
  )
}

export default withRouter(StackedScenarioComparison)
