import { Box, Heading, Spinner } from '@chakra-ui/react'
import { useReducer } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { ExposuresGraphAlert, createGraphAlertInitialState, graphAlertReducer } from './ExposuresGraphAlert'
import { loadingType } from './ExposuresGraphState'

type MarketShareObject = {
  inforce: number
  scaled: number
  projected?: number
}

export type BarGraphData = { ilcBand: string; marketShare: MarketShareObject }[]

type ExposuresBarGraphProps = {
  graphData: BarGraphData
  leftAxis: string
  bottomAxis: string
  loadingState: loadingType
}

export const ExposuresBarGraph = (props: ExposuresBarGraphProps) => {
  const { graphData, leftAxis, bottomAxis, loadingState } = props
  const [graphAlertState, graphAlertDispatch] = useReducer(graphAlertReducer, createGraphAlertInitialState())

  if (loadingState === 'loading') {
    return (
      <Box>
        <Heading size={'sm'}>Loading</Heading>
        <Spinner />
      </Box>
    )
  }

  const onCloseAlert = () => {
    graphAlertDispatch({ type: 'closeAlert' })
  }

  if (loadingState === 'error') {
    return (
      <ExposuresGraphAlert
        alertStatus={graphAlertState.alertStatus}
        message={graphAlertState.message}
        isActive={graphAlertState.isActive}
        onClose={onCloseAlert}
      />
    )
  }

  if (!graphData) {
    return (
      <Box>
        <Heading size={'sm'}>Market Share Losses data for this peril not available</Heading>
      </Box>
    )
  }

  return (
    <ResponsiveBar
      data={graphData.map((dataObj) => {
        const projected = dataObj.marketShare.projected
        if (projected && typeof projected === 'number') {
          return {
            group: dataObj.ilcBand,
            Inforce: dataObj.marketShare.inforce,
            Scaled: dataObj.marketShare.scaled,
            Projected: projected,
          }
        }
        return {
          group: dataObj.ilcBand,
          Inforce: dataObj.marketShare.inforce,
          Scaled: dataObj.marketShare.scaled,
          Projected: 0,
        }
      })}
      keys={Object.keys(graphData[0].marketShare).map((k) => k[0].toUpperCase() + k.slice(1).toString())}
      indexBy="group"
      margin={{ top: 30, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#386357', '#DCEFC6', '#5BC597']}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: bottomAxis,
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 1,
        tickPadding: 1,
        tickRotation: 0,
        legend: leftAxis,
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: -2,
          translateY: -34,
          itemsSpacing: 3,
          itemWidth: 98,
          itemHeight: 22,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 18,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="BarChart"
      groupMode={'grouped'}
    />
  )
}
