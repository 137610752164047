import { Flex, Heading, Input } from '@chakra-ui/react'
import { ChangeEvent } from 'react'

type ExposuresReportNameInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  reportName: string
  isDisabled: boolean
  id?: string
}

export const ExposuresReportNameInput = (props: ExposuresReportNameInputProps) => {
  const { onChange, reportName, isDisabled } = props

  return (
    <Flex
      py={'1rem'}
      maxWidth={'24rem'}
      flexDirection={'column'}
    >
      <Heading
        pb={'0.5rem'}
        size={'md'}
      >
        Report Name
      </Heading>
      <Input
        onChange={onChange}
        id="Exposures-TextField-Report-Name"
        value={reportName}
        isDisabled={isDisabled}
      />
    </Flex>
  )
}
