import React from 'react'
import { css } from '../../utils/css'
import './DropdownContainer.scss'

export type DropdownContainerProps = React.PropsWithChildren<{
  className?: string | string[]
}>

export function DropdownContainer(props: DropdownContainerProps) {
  return <div className={css('DropdownContainer', props.className)}>{props.children}</div>
}

export default DropdownContainer
