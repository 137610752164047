import { Alert, AlertIcon, AlertStatus, Spacer } from '@chakra-ui/react'
import { Reducer } from 'react'

type ExposuresReportOutdatedAlertProps = ExposuresReportOutdatedAlertState

export type ExposuresReportOutdatedAlertState = {
  alertStatus: AlertStatus
  message: string
  isActive: boolean
}

export type ExposuresReportOutdatedAlertAction =
  | { type: 'setError'; payload: { message: string; error: string } }
  | { type: 'setWarning'; payload: { message: string } }

export const createExposuresReportOutdatedAlertInitialState = (): ExposuresReportOutdatedAlertState => {
  return {
    alertStatus: 'loading',
    message: '',
    isActive: false,
  }
}

export const exposuresReportOutdatedAlertReducer: Reducer<
  ExposuresReportOutdatedAlertState,
  ExposuresReportOutdatedAlertAction
> = (state, action) => {
  switch (action.type) {
    case 'setError': {
      let message = action.payload.message
      if (message === '') {
        message = 'An unexpected error occurred'
      }

      console.error(action.payload.error)

      return {
        alertStatus: 'error',
        message,
        isActive: true,
      }
    }
    case 'setWarning': {
      let message = action.payload.message
      let isActive = true
      if (message === '') {
        console.info('Warning message was empty - will not display alert')
        isActive = false
      }

      return {
        alertStatus: 'warning',
        message,
        isActive,
      }
    }
  }
}

export const ExposuresReportOutdatedAlert = (props: ExposuresReportOutdatedAlertProps) => {
  const { alertStatus, message, isActive } = props

  if (!isActive) {
    return <></>
  }

  return (
    <Alert
      status={alertStatus}
      borderRadius={'md'}
    >
      <AlertIcon />
      {message}
      <Spacer />
    </Alert>
  )
}
