import { PropsWithChildren } from 'react'
import { Th } from '@chakra-ui/react'

export const NcpTableFootCell = (props: PropsWithChildren<{ sticky?: true }>) => {
  return props.sticky ? (
    <Th style={{ position: 'sticky', left: 0, zIndex: 1, background: '#fff', textTransform: 'none' }}>
      {props.children}
    </Th>
  ) : (
    <Th p="4px">{props.children}</Th>
  )
}
