import React, { createContext, useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState'

export interface AuthStateContainer {
  state: AuthState
  user?: CognitoUser | null | undefined
}

export type AuthState = 'signIn' | 'signOut' | 'initial'
type Props = React.PropsWithChildren<{}>

export const AuthContext = createContext<AuthStateContainer>({
  state: 'initial',
  user: undefined,
})

const { Provider } = AuthContext

const initialiseLaunchDarklyContext = (user: CognitoUser): LDSingleKindContext => {
  let email: CognitoUserAttribute | undefined
  user.getUserAttributes((err, attributes) => {
    if (err) {
      console.error('unable to get user attribute for launch darkly context')
      // If anonymous is true then LD won't store the context
      return {
        kind: 'user',
        anonymous: true,
      }
    }

    email = attributes?.find((v) => v.Name === 'email')
  })

  return {
    kind: 'user',
    key: user.getUsername(),
    name: email?.Value,
  }
}

function initialiseDatadogLogs(user: CognitoUser, datadogUserTracking: boolean) {
  try {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.REACT_APP_ENV,
      service: 'wayfinder-ui',
    })
    if (datadogUserTracking) {
      const userEmail = user.getUsername().split('_')[1]
      datadogLogs.setUser({ email: userEmail })
    }
  } catch (err) {
    console.error('App.tsx - Datadog Logs init - ', err)
  }
}

function initialiseDatadogRum(user: CognitoUser, datadogUserTracking: boolean) {
  try {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DD_APPLICATION_ID ?? '',
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? '',
      site: 'datadoghq.eu',
      service: 'wayfinder',
      env: process.env.REACT_APP_ENV,
      version: '1.0.0',
      sessionSampleRate: 100, // 100%
      sessionReplaySampleRate: 100, // 100%
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    })
    datadogRum.startSessionReplayRecording()
    if (datadogUserTracking) {
      const userEmail = user.getUsername().split('_')[1]
      datadogRum.setUser({ email: userEmail })
    }
  } catch (err) {
    console.error('App.tsx - Datadog RUM init - ', err)
  }
}

export const AuthProvider = (props: Props): JSX.Element => {
  const [auth, setAuth] = useState<AuthStateContainer>({ state: 'initial' })
  const ldClient = useLDClient()

  useEffect(() => {
    ;(async () => {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser()
        ldClient?.identify(initialiseLaunchDarklyContext(user))
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, 'signedIn')
        setAuth({ state: 'signIn', user })
      } catch (err: unknown) {
        setAuth({ state: 'signOut', user: null })
        const lsState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE)
        if (lsState === 'signedIn') {
          Auth.signOut()
        }
      }
    })()
  }, [ldClient])

  useEffect(() => {
    ;(async () => {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser()
        const datadogUserTracking = ldClient?.variation('datadog-user-tracking')
        initialiseDatadogLogs(user, datadogUserTracking)
        initialiseDatadogRum(user, datadogUserTracking)
      } catch (err: unknown) {
        console.error('Failed to fetch user data while initialising Datadog')
      }
    })()
  }, [])

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload
      if (event === 'signOut') {
        setAuth({ state: event, user: null })
        localStorage.removeItem(AUTHENTICATOR_AUTHSTATE)
        return
      }
    })
  }, [])

  return <Provider value={auth}>{props.children}</Provider>
}
