import { CSSProperties } from 'react'
import { Input } from '@chakra-ui/react'
import { sanitizeId } from '../../utils/ids'
import { NcpTableCellTooltip } from './NcpTableCellTooltip'

export type ReadOnlyInputWithTooltip = {
  id: string
  value: number | string
  style?: CSSProperties | 'none'
  isInvalid?: boolean
}

export const NcpTableReadOnlyInputWithTooltip = (props: ReadOnlyInputWithTooltip) => {
  const style =
    props.style === undefined
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      : props.style === 'none'
      ? undefined
      : props.style
  const id = sanitizeId(props.id)

  return (
    <NcpTableCellTooltip label={props.value}>
      <Input
        size={'sm'}
        borderRadius={'md'}
        id={id}
        data-testid={id}
        style={style}
        disabled
        value={props.value}
        type="text"
        isInvalid={!!props.isInvalid}
      />
    </NcpTableCellTooltip>
  )
}
