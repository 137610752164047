import { Reducer } from 'react'
import { ExposuresReportStatus } from '../ReportStatus'

export type State = {
  id: string
  reportName: string
  reportStatus: ExposuresReportStatus
  selectedPeril: string
  scalingFactors: number[]
  noPendingReports: boolean
  reportOutdated: boolean
  reportFinal: boolean
}

export type Action =
  | { type: 'setReportId'; payload: { id: string } }
  | { type: 'setReportName'; payload: { reportName: string } }
  | { type: 'setSelectedPeril'; payload: { selectedPeril: string } }
  | { type: 'setReportStatus'; payload: { reportStatus: ExposuresReportStatus } }
  | { type: 'setNoPendingReports'; payload: { noPendingReports: boolean } }
  | { type: 'setReportOutdated'; payload: { reportOutdated: boolean } }
  | { type: 'setReportFinal'; payload: { reportFinal: boolean } }

export const createInitialState = (): State => {
  return {
    id: '',
    reportName: '',
    reportStatus: ExposuresReportStatus.SCALED_ANALYSIS,
    selectedPeril: '',
    scalingFactors: [],
    noPendingReports: true,
    reportOutdated: false,
    reportFinal: false,
  }
}

export const ExposuresReportReducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'setReportId': {
      return {
        ...state,
        id: action.payload.id,
      }
    }
    case 'setReportName': {
      return {
        ...state,
        reportName: action.payload.reportName,
      }
    }
    case 'setReportStatus': {
      return {
        ...state,
        reportStatus: action.payload.reportStatus,
      }
    }
    case 'setSelectedPeril': {
      return {
        ...state,
        selectedPeril: action.payload.selectedPeril,
      }
    }
    case 'setNoPendingReports': {
      return {
        ...state,
        noPendingReports: action.payload.noPendingReports,
      }
    }
    case 'setReportOutdated': {
      return {
        ...state,
        reportOutdated: action.payload.reportOutdated,
      }
    }
    case 'setReportFinal': {
      return {
        ...state,
        reportFinal: action.payload.reportFinal,
      }
    }
  }
}
