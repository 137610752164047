import React from 'react'
import { Card } from '../../components/card/Card'
import AddIcon from '../../components/icons/AddIcon'
import './AddScenarioCard.scss'

interface AddScenarioCardProps {
  onClick: () => void
  id?: string
}

function AddScenarioCard(props: AddScenarioCardProps) {
  return (
    <div
      className="AddScenarioCard"
      key={'create-minimised'}
      onClick={props.onClick}
    >
      <Card>
        <AddIcon id={props.id} />
      </Card>
    </div>
  )
}

export { AddScenarioCard }
