function isPromiseFulfilled<T>(value: unknown): value is PromiseFulfilledResult<T> {
  if (value === undefined || value === null) {
    return false
  }

  if (typeof value !== 'object') {
    return false
  }

  if (!('status' in value)) {
    return false
  }

  return value.status === 'fulfilled'
}

function isPromiseRejected(value: unknown): value is PromiseRejectedResult {
  if (value === undefined || value === null) {
    return false
  }

  if (typeof value !== 'object') {
    return false
  }

  if (!('status' in value)) {
    return false
  }

  return value.status === 'rejected'
}

async function getOnlyResolvedPromises<T>(value: Promise<T>[]): Promise<T[]> {
  const promises = await Promise.allSettled(value)
  const filtered: T[] = []
  promises.forEach((p) => {
    if (isPromiseFulfilled(p)) {
      filtered.push(p.value)
    }
  })

  return filtered
}

export { isPromiseFulfilled, isPromiseRejected, getOnlyResolvedPromises }
