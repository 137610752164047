import { PREVIEW_ID } from '../../backend/adjustments'

export function calculateDisplayOrderNumber(
  orderNumber: number | undefined,
  lowestOrderNumberIs0: boolean | undefined,
) {
  if (orderNumber === undefined) {
    return undefined
  }

  if (lowestOrderNumberIs0) {
    return (orderNumber || 0) + 1
  }

  return orderNumber
}

export function calculateDescription(passedInDescription: string, adjustmentId: string) {
  if (passedInDescription) {
    return passedInDescription
  }
  // TODO: remove unreachable code below
  if (adjustmentId === PREVIEW_ID) {
    return 'Aggregation of all Actual data'
  }

  if (adjustmentId === 'CLIENT_SPECIFIC_SUMMARY') {
    return 'Aggregation of all client specific adjustments'
  }

  return undefined
}
