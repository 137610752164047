import { WayfinderDataType } from '../../backend/TypeOfData'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

export const useDataTypesInGraphs = (): WayfinderDataType[] | undefined => {
  const location = useLocation()

  return useMemo(() => {
    if (location.pathname.endsWith('/new')) {
      return [WayfinderDataType.ACTUALS, WayfinderDataType.NEW_CLIENT, WayfinderDataType.NEW]
    } else if (location.pathname.endsWith('/renewal')) {
      return [
        WayfinderDataType.RENEWAL_UNADJUSTED,
        WayfinderDataType.RENEWAL_ADJUSTED,
        WayfinderDataType.RENEWAL_CLIENT,
        WayfinderDataType.LEGACY_RENEWAL_CLIENT,
        WayfinderDataType.ACTUALS,
      ]
    } else {
      return undefined
    }
  }, [location.pathname])
}
