import { ReactElement, useEffect } from 'react'

export type State = 'fetching' | 'fulfilled' | 'rejected'
type OnEvent = 'fetch' | 'resolve' | 'reject'

export type Machine = {
  initial: State
  states: { [s in State]: { on: Partial<{ [e in OnEvent]: State }> } }
}

export const stateMachine = {
  initial: 'fetching',
  states: {
    fetching: {
      on: {
        resolve: 'fulfilled',
        reject: 'rejected',
      },
    },
    fulfilled: { on: { fetch: 'fetching' } },
    rejected: { on: { fetch: 'fetching' } },
  },
} satisfies Machine

type StateTransitionProps<T> = {
  state: State
  components: {
    [key in State]: ReactElement
  }
  dataFetchingConfig: FetchingConfig<T>
}

type FetchingConfig<T> = {
  fetch: () => Promise<T>
  dispatch: (data: T) => void
}

export function StateTransition<T>(props: StateTransitionProps<T>) {
  const { state, components, dataFetchingConfig } = props

  useEffect(() => {
    ;(async () => {
      try {
        const data = await dataFetchingConfig.fetch()
        dataFetchingConfig.dispatch(data)
      } catch (e: unknown) {
        console.log(e)
      }
    })()
  }, [])

  return components[state]
}
