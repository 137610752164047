import { IndividualDataItem } from '../utils/numbers'
import { ADJUSTMENT_ID } from './calculate-kpis'

export const PREVIOUS_SUBMISSION_REF_IDENTIFIER = 'Previous Convex Submission Ref'
export const RENEWAL_INCEPTION_MONTH_COLUMN_NAME = 'Renewal Inception Month'
export const A1_MARKET_COLUMN_NAME = 'A1 Market'

export function calculateWhichActualDataIsARenewal(rawActualData: any[]): any[] {
  return rawActualData.filter((item) => item[PREVIOUS_SUBMISSION_REF_IDENTIFIER])
}

export function calculateWhichActualDataIsNew(rawActualData: any[]): any[] {
  return rawActualData.filter((item) => !item[PREVIOUS_SUBMISSION_REF_IDENTIFIER])
}

export const isNotReplacedByActuals = (dataItem: IndividualDataItem) => {
  return dataItem[ADJUSTMENT_ID] !== OVERRIDDEN_BY_ACTUALS
}

export const isNotActuals = (dataItem: any) => {
  return dataItem[ADJUSTMENT_ID] !== ACTUALS_ADJUSTMENT_ID
}

export const OVERRIDDEN_BY_ACTUALS = 'Overridden By Actuals'
export const ACTUALS_ADJUSTMENT_ID = 'Actuals'
