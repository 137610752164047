import React, { useContext } from 'react'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { DEFAULT_GRAPH_THEME } from '../../renewal-graph/graph-theme'
import { SummaryOfAdjustmentsByTypeContext, TreeMapNode } from './SummaryOfAdjustmentsByTypeProvider'
import { formatForDisplay } from '../../../utils/numbers'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

interface SummaryAdjustmentsGraphProps {
  useDefaultColours?: boolean
}

interface PointData {
  data: TreeMapNode
  formattedValue: string
  height: number
  id: string
  opacity: number
  value: number
  width: number
}

const displayIdAndValue = ((item: PointData) => `${item.id} - ${formatForDisplay(item.value)}`) as any

function SummaryAdjustmentTreeMap(props: SummaryAdjustmentsGraphProps) {
  const { graphData } = useContext(SummaryOfAdjustmentsByTypeContext)
  return (
    <div
      className="Graph"
      style={{ height: '115%' }}
    >
      <ResponsiveTreeMap
        data={graphData}
        identity="name"
        key={'tree-map'}
        parentLabelPadding={18}
        parentLabelSize={48}
        parentLabel={displayIdAndValue}
        label={displayIdAndValue}
        valueFormat="<-,.2d"
        // margin={{top: 10, right: 10, bottom: 10, left: 10}}
        labelSkipSize={18}
        colors={GRAPH_COLOURS}
        theme={{ ...DEFAULT_GRAPH_THEME, text: { ...DEFAULT_GRAPH_THEME.text, fontSize: 10 } }}
      />
    </div>
  )
}

export default SummaryAdjustmentTreeMap
