import React, { SVGProps } from 'react'

const CancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path"
        fill="#4E566B"
        points="11.0304576 12.0406102 8 9.01015254 4.96954237 12.0406102 3.95938982 11.0304576 6.98984746 8 3.95938982 4.96954237 4.96954237 3.95938982 8 6.98984746 11.0304576 3.95938982 12.0406102 4.96954237 9.01015254 8 12.0406102 11.0304576"
      />
    </g>
  </svg>
)

export default CancelIcon
