import React, { useContext, useEffect, useState } from 'react'

import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { GraphDataProvider } from './GraphDataProvider'
import { AdjustedGwpBreakdownPieGraph } from './AdjustedGwpBreakdownPieGraph'
import { GraphFilterDataContext } from './GraphFilterDataProvider'
import { GraphHeader } from '../graph-header/GraphHeader'
import { TOTAL_GWP_COLUMN } from '../../backend/calculate-kpis'
import { formatInceptionYearForDisplay, sortInceptionMonth } from './gwp-bar-chart/inception-month-graph'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../backend/calculate-with-actuals'
import { toOption } from '../../utils/lists'

interface PieChartProps {
  defaultGraphColumn?: string
  overrideColumnValue?: string
  numberOfItemsToInclude?: number
  numericFieldToUse?: string
  hideOthers?: boolean
  fieldsToOrderBy?: [string, string]
  hideNew?: boolean
}

function calculateGraphTitle(props: PieChartProps) {
  if (props.numericFieldToUse === TOTAL_GWP_COLUMN) {
    return 'Original GWP Breakdown'
  }

  return 'Adjusted GWP Breakdown'
}

export function AdjustedGwpBreakdownPieGraphWithFilter(props: PieChartProps) {
  const [currentGraphColumn, setCurrentGraphColumn] = useState(props.defaultGraphColumn || 'A1 Segment')
  const { possibleStringColumnValues } = useContext(GraphFilterDataContext)

  useEffect(() => {
    if (props.overrideColumnValue) {
      setCurrentGraphColumn(props.overrideColumnValue)
    } else {
      setCurrentGraphColumn(props.defaultGraphColumn || 'A1 Segment')
    }
  }, [props.overrideColumnValue, props.defaultGraphColumn])

  return (
    <>
      <GraphHeader title={calculateGraphTitle(props)} />
      <MultiSelectDropdown
        onSelect={onSelectSingleValueFromMulti(setCurrentGraphColumn)}
        options={possibleStringColumnValues.map(toOption)}
        selected={[toOption(currentGraphColumn)]}
      />
      <GraphDataProvider
        fieldNameToGroupBy={currentGraphColumn}
        numberOfItemsToInclude={props.numberOfItemsToInclude}
        hideOthers={props.hideOthers}
        fieldsToOrderBy={props.fieldsToOrderBy}
        sortDataFunction={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME
            ? (data) => sortInceptionMonth(data, currentGraphColumn)
            : undefined
        }
        formatDataFieldForDisplay={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME ? formatInceptionYearForDisplay : undefined
        }
      >
        <div className="Graph">
          <AdjustedGwpBreakdownPieGraph numericFieldToUse={props.numericFieldToUse} />
        </div>
      </GraphDataProvider>
    </>
  )
}
