import { getCurrentConfigSettings } from '../utils/config'
import { MarketGraphDataV2 } from './market-V2'
import { getAccessToken } from './teams'

export interface AggregatedMarketV2 {
  aggregateObjectLabel: string
  aggregateObjectId: string

  totalMarketGwp: number
  pmlScenario1In100: string
  confidenceInMarketSize: string
  accessOrCapability: string
  inAppetite: string
  addressableMarketGwp: number
  rateEnvironment: string
  peakConvexGwp: number
  peakConvexMarketShare: string
  convexEstimatedShareOfPml: number
  previousYearGnlr: string
  previousYearGglr: string
  previousYearConvexAcquisitionRatio: string
  convexAcquisitionRatio: string
  marketGrarc: string
  marketNrarc: string
  inflation: AggregatedMarketKpi
  AbeGglr: AggregatedMarketKpi
  uwConvexAcquisitionRatio: string
  uwMarketGrarc: string
  uwInflation: AggregatedMarketKpi
  uwAbeGglr: AggregatedMarketKpi
  graphData: Array<MarketGraphDataV2>

  composedOf?: AggregatedMarketV2[]
}

export type AggregatedMarketKpi = {
  aggregatedMarketKpi: string
  individualMarketKpi: IndividualMarketKpi
}
type MarketKpi = string
type Level = string
export type IndividualMarketKpi = { [key: Level]: MarketKpi }

export async function fetchAggregatedMarketV2(stackedScenarioId: string): Promise<AggregatedMarketV2 | undefined> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/markets/stacked-scenario/${stackedScenarioId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return undefined
  }

  return responseObject
}
