import React from 'react'
import { Redirect } from 'react-router'
import { DEFAULT_LOGGED_IN_PATH } from '../../utils/paths/Paths'
import { LinkButton } from '../button/LinkButton'
import { AuthContext } from '../../providers/AmplifyAuthProvider'
import { getCurrentConfigSettings } from '../../utils/config'
import { AMPLIFY_CLIENT_ID, COGNITO_URL } from '../../config/SSOConfig'

export interface LoginWithJumpCloudProps {
  changeLocation: (newPath: string) => void
}

const LOCATION = `https://${COGNITO_URL}/oauth2/authorize?identity_provider=JumpCloud&redirect_uri=${
  getCurrentConfigSettings().ssoRedirectLocation
}&response_type=CODE&client_id=${AMPLIFY_CLIENT_ID}&scope=phone email profile openid aws.cognito.signin.user.admin`

export default function LoginWithJumpCloud(props: LoginWithJumpCloudProps) {
  return (
    <AuthContext.Consumer>
      {(auth) =>
        auth.user ? (
          <Redirect to={DEFAULT_LOGGED_IN_PATH.path} />
        ) : (
          <LinkButton href={LOCATION}>Sign in with Jumpcloud</LinkButton>
        )
      }
    </AuthContext.Consumer>
  )
}
