import { Flex, Heading, Table, TableContainer } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { PropsWithChildren } from 'react'

interface NcpTableProps {
  title?: string
  subtitle?: string
}

export const NcpTable = (props: PropsWithChildren<NcpTableProps>) => {
  return (
    <Flex
      flexDirection={'column'}
      pb={'3rem'}
      pt={'1rem'}
    >
      {props.title && (
        <Heading
          as="h6"
          size="sm"
          paddingBottom={'0.3rem'}
          paddingTop={'1rem'}
        >
          {props.title}
        </Heading>
      )}
      {props.subtitle && <p>{props.subtitle}</p>}
      <TableContainer paddingTop={'1rem'}>
        <Table
          size="sm"
          className="NcpTable"
          css={css`
            &.NcpTable {
              th {
                white-space: normal;
                word-wrap: break-word;
                text-transform: none;
                text-align: left;
                vertical-align: top;
                padding-top: 6px;
              }
            }
          `}
        >
          {props.children}
        </Table>
      </TableContainer>
    </Flex>
  )
}
