import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

type ScenarioStatusSelectorModalProps = {
  isOpen: boolean
  onCancel: () => void
  onComplete: () => void
}

export function ScenarioStatusSelectorModal({ isOpen, onCancel, onComplete }: ScenarioStatusSelectorModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'md'}>Confirm scenario completion</ModalHeader>
        <ModalBody>
          <Text fontSize={'sm'}>
            By changing the status to 'In review' or 'Complete', you are confirming that you are happy with the
            underlying assumptions, such as Monthly Phasing, Entity Splits, Placing Basis, Rate, and EPI. Please bear in
            mind that these assumptions represent a snapshot in time and may evolve over the course of the year.
          </Text>
          <br />
          <Text fontSize={'sm'}>Please note that status cannot be changed back to In Progress.</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant={'outline'}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button onClick={onComplete}>Yes, complete</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
