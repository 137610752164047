import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useState } from 'react'

export enum ProgressState {
  LOADING = 'Loading',
  FINISHED = 'Finished',
  ERROR = 'Error',
}

type MenuState = 'show' | 'hide'
export interface ProgressContainer {
  showMenu: MenuState
  setShowMenu: Dispatch<SetStateAction<MenuState>>
  progressIndicators: Record<string, ProgressState | undefined>
  updateIndividualProgressItem: (name: string, newState: ProgressState) => void
}

export function aggregateProgress(progressIndicators: Record<string, ProgressState | undefined>): ProgressState {
  const allValues = Object.values(progressIndicators)

  if (allValues.find((value) => ProgressState.ERROR === value)) {
    return ProgressState.ERROR
  }

  if (allValues.find((value) => ProgressState.LOADING === value)) {
    return ProgressState.LOADING
  }

  return ProgressState.FINISHED
}

export const initialProgressContextValue: ProgressContainer = {
  showMenu: 'show',
  setShowMenu: (x) => x,
  progressIndicators: {},
  updateIndividualProgressItem: () => void 0,
}

export const ProgressContext = createContext<ProgressContainer>(initialProgressContextValue)

const ProgressConsumer = ProgressContext.Consumer // eslint-disable-line @typescript-eslint/no-unused-vars

export const ProgressProvider: FC<React.PropsWithChildren<{}>> = (props) => {
  const [progressIndicators, setProgressIndicators] = useState<Record<string, ProgressState>>({})
  const [showMenu, setShowMenu] = useState<MenuState>('show')

  const updateIndividualProgressItem = useCallback((name: string, newState: ProgressState): void => {
    setProgressIndicators((x) => ({ ...x, [name]: newState }))
  }, [])

  return (
    <ProgressContext.Provider
      value={{
        progressIndicators,
        updateIndividualProgressItem,
        showMenu,
        setShowMenu,
      }}
    >
      {props.children}
    </ProgressContext.Provider>
  )
}
