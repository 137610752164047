import { useContext } from 'react'
import { FullScenarioDataContext } from '../FullScenarioData/FullScenarioDataProvider'

export const useResetPreviewData = (): (() => void) => {
  const { setPreviewNewClient, setPreviewNewVirtual, setPreviewRenewal } = useContext(FullScenarioDataContext)

  return () => {
    setPreviewNewVirtual(undefined)
    setPreviewNewClient(undefined)
    setPreviewRenewal(undefined)
  }
}
