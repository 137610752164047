import React, { useContext, useEffect, useState } from 'react'
import qs from 'qs'
import { StringParam, useQueryParam } from 'use-query-params'
import { RouteComponentProps, withRouter } from 'react-router'

import './ScenarioList.scss'

import SectionHeader from '../../components/section-header/SectionHeader'
import Divider from '../../components/divider/LineDivider'
import { fetchAllScenarios, Scenario } from '../../backend/scenarios'
import ScenarioCard from '../../components/scenario-card/ScenarioCard'
import EditScenarioCard from '../../components/edit-scenario-card/EditScenarioCard'
import CreateScenarioCard from '../../components/create-scenario-card/CreateScenarioCard'
import { AddScenarioCard } from './AddScenarioCard'
import { PortfolioListingContext } from '../../providers/PortfolioListingProvider'
import { StartComparisonIcon } from './StartComparisonIcon'
import { css } from '../../utils/css'
import { StopComparisonIcon } from './StopComparisonIcon'
import SectionHeaderContainer from '../../components/section-header-container/SectionHeaderContainer'
import { StopAddIcon } from './StopAddIcon'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { FinishDuplicatingScenarioModal } from './FinishDuplicatingScenarioModal'
import Button from '../../components/button/Button'
import { MarketCard } from './MarketCard'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { sanitizeId } from '../../utils/ids'

export interface ScenariosListProps extends RouteComponentProps {
  market: string
  id?: string
  division: string
  team: string
  year: string
  onStartComparison: () => void
  currentlyComparedScenarioIds: Array<string>
  onCurrentComparisonSelectChanges: (newValues: Array<string>) => void
  onEndComparison: () => void
  isComparing: boolean
  possibleToCreateTeamScenario: (currentMarket: string, hasScenario: boolean | undefined) => void
  isCreatingTeamScenario: boolean
  currentlyChosenScenarioIds: string | undefined
  onCurrentCreatingSelectChanges: (currentMarket: string, currentScenarioId: string | undefined) => void
  className: string
}

const NEW_SCENARIO_FAKE_ID = 'NEW'

function Component(props: ScenariosListProps): React.ReactElement {
  const {
    division,
    team,
    market,
    id,
    year,
    onStartComparison,
    onEndComparison,
    className,
    isComparing,
    currentlyComparedScenarioIds,
    onCurrentComparisonSelectChanges,
    possibleToCreateTeamScenario,
    isCreatingTeamScenario,
    currentlyChosenScenarioIds,
    onCurrentCreatingSelectChanges,
    ...otherProps
  } = props

  const [actualDataItemName] = useQueryParam('actuals', StringParam)

  const { currentScenario } = useContext(ScenarioContext)
  const { portfolioStatus } = useContext(PortfolioListingContext)
  const { yearQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [currentScenarios, setScenarios] = useState<Scenario[]>([])
  const [currentlyEditingScenarioId, setCurrentlyEditingScenarioId] = useState<string | undefined>(undefined)

  const [yearFromQuery] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery] = teamQuery

  const reloadScenarios = (): void => {
    fetchAllScenarios(division, team, market, year).then((newListOfScenarios) => {
      setScenarios(newListOfScenarios)
    })
  }

  useEffect(reloadScenarios, [division, team, market, currentScenario, year])
  useEffect(
    () => possibleToCreateTeamScenario(market, currentScenarios.length > 0),
    [possibleToCreateTeamScenario, market, currentScenarios.length],
  )

  const sortCurrentScenariosByUpdatedDate = currentScenarios.sort(
    (a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt),
  )

  const startComparingScenarios = (): void => {
    setCurrentlyEditingScenarioId(undefined)
    onCurrentComparisonSelectChanges([])
    onStartComparison()
  }

  const stopComparisonScenarios = (): void => {
    setCurrentlyEditingScenarioId(undefined)
    onCurrentComparisonSelectChanges([])
    onEndComparison()
  }

  const toggleIsComparing = (scenarioId: string) => (): void => {
    const alreadyThere = currentlyComparedScenarioIds.includes(scenarioId)

    if (alreadyThere) {
      onCurrentComparisonSelectChanges(currentlyComparedScenarioIds.filter((id) => id !== scenarioId))
    } else {
      onCurrentComparisonSelectChanges([...currentlyComparedScenarioIds, scenarioId])
    }
  }

  const toggleIsCreating = (scenarioIdIsBeingToggled: string) => (): void => {
    if (currentlyChosenScenarioIds === scenarioIdIsBeingToggled) {
      onCurrentCreatingSelectChanges(market, undefined)
    } else {
      onCurrentCreatingSelectChanges(market, scenarioIdIsBeingToggled)
    }
  }

  const triggerEditScenario = (id: string) => {
    setCurrentlyEditingScenarioId(id)
  }

  const goToComparisonScreen = () => {
    if (currentlyComparedScenarioIds.length) {
      const queryParams = {
        scenarioIds: currentlyComparedScenarioIds,
        division: divisionFromQuery,
        team: teamFromQuery,
        year: yearFromQuery,
        actuals: actualDataItemName,
      }

      // eslint-disable-next-line
      const fullRoute = `/scenarios-comparison?${qs.stringify(queryParams)}`
      otherProps.history.push(fullRoute)
    }
  }
  const canWrite: boolean | undefined = portfolioStatus.portfolio?.portfolioListing.divisions[division]?.teams?.find(
    (currentTeam) => currentTeam.name === team,
  )?.canWriteToTeam

  return (
    <div
      className={css('ScenarioList', className)}
      key={market}
    >
      <FinishDuplicatingScenarioModal
        currentScenarios={currentScenarios}
        reloadScenarios={reloadScenarios}
      />
      <SectionHeaderContainer>
        <div className="StackedScenariosContainerLeft">
          {isComparing && <StopComparisonIcon onClick={stopComparisonScenarios} />}
          {currentlyEditingScenarioId && currentScenarios.length > 0 && (
            <StopAddIcon onClick={stopComparisonScenarios} />
          )}
          {!isComparing && !isCreatingTeamScenario && canWrite && !currentlyEditingScenarioId && (
            <AddScenarioCard
              onClick={() => {
                setCurrentlyEditingScenarioId(NEW_SCENARIO_FAKE_ID)
              }}
              id={`Add-Scenario-Button-${sanitizeId(props.market)}`}
            />
          )}
          {!isComparing && !isCreatingTeamScenario && currentScenarios.length > 1 && (
            <StartComparisonIcon onClick={startComparingScenarios} />
          )}
          {portfolioStatus.portfolio?.canEditMarket ? (
            <MarketCard
              marketName={market}
              year={year}
              division={division}
              team={team}
            />
          ) : (
            <></>
          )}
          <SectionHeader title={market} />
        </div>
        <div className="StackedScenariosContainerRight">
          {isComparing && (
            <Button
              title="View Comparison"
              onClick={goToComparisonScreen}
              className="ViewComparisonButton"
            />
          )}
        </div>
      </SectionHeaderContainer>
      <div className="ScenarioCards">
        {!isComparing && canWrite && currentlyEditingScenarioId === NEW_SCENARIO_FAKE_ID && (
          <CreateScenarioCard
            division={division}
            team={team}
            year={year}
            market={market}
            onCreate={reloadScenarios}
            onCancel={() => setCurrentlyEditingScenarioId(undefined)}
            scenarios={currentScenarios}
          />
        )}
        {sortCurrentScenariosByUpdatedDate.map((scenario) =>
          currentlyEditingScenarioId === scenario.id ? (
            <EditScenarioCard
              key={`view-${scenario.id}`}
              scenario={scenario}
              triggerStopEditing={() => setCurrentlyEditingScenarioId(undefined)}
              triggerSaveChanges={() => {
                setCurrentlyEditingScenarioId(undefined)
                reloadScenarios()
              }}
            />
          ) : (
            <ScenarioCard
              className={scenario.summary ? 'ScenarioCardWithSideSummary' : ''}
              key={`view-${scenario.id}`}
              scenario={scenario}
              triggerScenarioReload={reloadScenarios}
              triggerEditScenario={triggerEditScenario}
              canWrite={!isComparing && !isCreatingTeamScenario && (canWrite || false)}
              isComparing={isComparing}
              comparingIsSelected={currentlyComparedScenarioIds.includes(scenario.id)}
              comparingOnToggle={toggleIsComparing(scenario.id)}
              isCreatingTeamScenario={isCreatingTeamScenario}
              creatingIsSelected={currentlyChosenScenarioIds === scenario.id}
              creatingOnToggle={toggleIsCreating(scenario.id)}
              stopClicking={currentlyEditingScenarioId !== undefined}
            />
          ),
        )}
        <div className="EndPadding" />
      </div>
      <Divider />
    </div>
  )
}

export const ScenariosList = withRouter(Component)
