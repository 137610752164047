import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { fetchOutwardScenarioById, OutwardScenario } from '../../backend/outward-scenarios'
import { validate as uuidValidate } from 'uuid'
import { useLocation } from 'react-router-dom'

export const OutwardScenarioContext = createContext<OutwardScenarioContextContainer>({
  currentOutwardScenario: undefined,
  inwardScenarioIdToUse: undefined,
  setInwardScenarioIdToUSe: (newId: string | undefined) => undefined,
  triggerReloadSavedOutwardScenario: () => undefined,
})

export interface OutwardScenarioContextContainer {
  currentOutwardScenario: OutwardScenario | undefined
  inwardScenarioIdToUse: string | undefined
  setInwardScenarioIdToUSe: (newId: string | undefined) => void
  triggerReloadSavedOutwardScenario: () => void
}

const OutwardScenarioProvider = (props: PropsWithChildren) => {
  const [currentOutwardScenario, setOutwardScenario] = useState<OutwardScenario>()
  const [currentInwardScenarioIdToUse, setInwardScenarioIdToUSe] = useState<string | undefined>(undefined)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const location = useLocation()

  const currentOutwardScenarioId = location.pathname.split('/').filter((item) => uuidValidate(item))[0]
  const isOnOutwardScenarioPage = location.pathname.includes('/outward-scenarios/')

  const triggerReloadSavedOutwardScenario = () => {
    if (isOnOutwardScenarioPage && currentOutwardScenarioId) {
      updateIndividualProgressItem('fetchingOutwardScenario', ProgressState.LOADING)
      fetchOutwardScenarioById(currentOutwardScenarioId)
        .then(setOutwardScenario)
        .then(() => updateIndividualProgressItem('fetchingOutwardScenario', ProgressState.FINISHED))
        .catch(() => updateIndividualProgressItem('fetchingOutwardScenario', ProgressState.ERROR))
    } else {
      setOutwardScenario(undefined)
    }
  }
  useEffect(triggerReloadSavedOutwardScenario, [
    currentOutwardScenarioId,
    isOnOutwardScenarioPage,
    updateIndividualProgressItem,
  ])

  useEffect(() => {
    if (currentOutwardScenario?.inwardScenarioId) {
      setInwardScenarioIdToUSe(currentOutwardScenario.inwardScenarioId)
    }
  }, [currentOutwardScenario])

  return (
    <OutwardScenarioContext.Provider
      value={{
        currentOutwardScenario,
        inwardScenarioIdToUse: currentInwardScenarioIdToUse,
        setInwardScenarioIdToUSe,
        triggerReloadSavedOutwardScenario,
      }}
    >
      {props.children}
    </OutwardScenarioContext.Provider>
  )
}

export default OutwardScenarioProvider
