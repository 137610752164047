import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  formatPercentageAsIntegerForProgressBar,
  isZero,
  numericValueIsDefined,
  ONE_HUNDRED,
  ZERO,
} from '../../utils/numbers'
import AdjustmentCard from '../adjustment-card/AdjustmentCard'

import {
  RenewalAdjustmentContext,
  RenewalAdjustmentsContextContainer,
} from '../../providers/RenewalAdjustmentsProvider'
import {
  ADJUSTMENT_ID,
  calculateGrarcKpi,
  calculateKPIs,
  filterData,
  ScenarioKeyPerformanceIndicators,
  TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN,
  TOTAL_GWP_COLUMN,
  TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
} from '../../backend/calculate-kpis'
import {
  Adjustment,
  calculateGrossRiskAdjustedRateChange,
  isIrrelevantAdjustment,
  isNotPreview,
  isRelevantAdjustment,
  patchAdjustment,
  PREVIEW_ID,
  sortAdjustmentsByOldestFirst,
} from '../../backend/adjustments'
import { getItemsInNewOrderIgnoringPreview } from '../draggable-card/draggable-order'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ACTUALS_ADJUSTMENT_ID, OVERRIDDEN_BY_ACTUALS } from '../../backend/calculate-with-actuals'
import './Adjustments.scss'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { CreateAdjustmentCard } from '../create-adjustment-card/CreateAdjustmentCard'
import SectionHeader from '../../components/section-header/SectionHeader'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { useResetAdjustments } from 'src/hooks/useResetAdjustments'

interface AdjustmentsProps {
  adjustmentType: string
  hideCreateAndEdit?: boolean
}

function calculateAggregatedFieldChange(
  fullRenewalKpis: ScenarioKeyPerformanceIndicators | undefined,
  adjustments: Adjustment[],
  fieldExtractor: (adjustment: Adjustment) => number,
) {
  if (!fullRenewalKpis) {
    return ZERO
  }

  const originalTotalGwp = adjustments
    .map((adjustment) => fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN][adjustment.id] || ZERO)
    .reduce((current, next) => current.plus(next), ZERO)

  if (isZero(originalTotalGwp)) {
    return ZERO
  }

  const totalAdjustedGwp = adjustments
    .map((adjustment) =>
      (fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN][adjustment.id] || ZERO).mul(
        fieldExtractor(adjustment) || ZERO,
      ),
    )
    .reduce((current, next) => current.plus(next), ZERO)

  return totalAdjustedGwp.div(originalTotalGwp)
}

export function Adjustments(props: AdjustmentsProps) {
  const { fullDataForScenario, fullRenewalKpis } = useContext(FullScenarioDataContext)
  const { adjustments, changeAdjustments, currentlyEditingAdjustmentId, setWhichAdjustmentIsBeingEdited } =
    useContext<RenewalAdjustmentsContextContainer>(RenewalAdjustmentContext)
  const resetAdjustments: () => void = useResetAdjustments()
  const [currentlyReorderingAdjustments, setCurrentlyReorderingAdjustments] = useState<Adjustment[] | undefined>()
  const { currentScenario } = useContext(ScenarioContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const adjustmentsSortedForDisplay = useMemo(
    () =>
      adjustments
        ? sortAdjustmentsByOldestFirst(currentlyReorderingAdjustments || adjustments)
            .filter(isNotPreview)
            .filter((item) => item.subtype === props.adjustmentType)
        : undefined,
    [adjustments, currentlyReorderingAdjustments, props.adjustmentType],
  )

  const allClientSpecificAdjustments = adjustments?.filter((item) => item.subtype === 'CLIENT_SPECIFIC') || []
  const allClientSpecificAdjustmentsIgnoringPreview =
    allClientSpecificAdjustments?.filter(isIrrelevantAdjustment(PREVIEW_ID)) || []
  const calculateAdjustedPercentageOfOriginalGWPForMultipleAdjustments = (adjustmentIds: string[]) => {
    const totalOfAllAdjustmentsAmount = adjustmentIds.reduce(
      (current, nextAdjustmentId) =>
        current.plus(
          fullRenewalKpis &&
            fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN] &&
            numericValueIsDefined(fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN][nextAdjustmentId])
            ? fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN][nextAdjustmentId]!
            : ZERO,
        ),
      ZERO,
    )

    const value =
      !fullRenewalKpis || isZero(fullRenewalKpis[TOTAL_GWP_COLUMN])
        ? ZERO
        : totalOfAllAdjustmentsAmount.div(fullRenewalKpis[TOTAL_GWP_COLUMN]).mul(ONE_HUNDRED)
    return formatPercentageAsIntegerForProgressBar(value)
  }

  const ensureCurrentlyEditingAdjustmentExists = () => {
    if (
      adjustmentsSortedForDisplay !== undefined &&
      currentlyEditingAdjustmentId &&
      currentlyEditingAdjustmentId !== PREVIEW_ID &&
      (!adjustmentsSortedForDisplay.length ||
        !adjustmentsSortedForDisplay.find(isRelevantAdjustment(currentlyEditingAdjustmentId)))
    ) {
      setWhichAdjustmentIsBeingEdited('')
    } else if (
      adjustmentsSortedForDisplay !== undefined &&
      !adjustmentsSortedForDisplay.length &&
      !currentlyEditingAdjustmentId
    ) {
      setWhichAdjustmentIsBeingEdited(PREVIEW_ID)
    }
  }

  // eslint-disable-next-line
  useEffect(ensureCurrentlyEditingAdjustmentExists, [adjustments])

  const onHover = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const sortedAdjustments = sortAdjustmentsByOldestFirst([
        ...(currentlyReorderingAdjustments || adjustments || []).filter(
          (item) => item.subtype === props.adjustmentType,
        ),
      ])
      const adjustmentsWithUpdatedOrderNumber = getItemsInNewOrderIgnoringPreview(
        sortedAdjustments,
        dragIndex,
        hoverIndex,
        isIrrelevantAdjustment(PREVIEW_ID),
      )

      const previewAdjustment = adjustments!.find(isRelevantAdjustment(PREVIEW_ID))

      if (previewAdjustment) {
        adjustmentsWithUpdatedOrderNumber.push(previewAdjustment)
      }

      setCurrentlyReorderingAdjustments(adjustmentsWithUpdatedOrderNumber)

      return adjustmentsWithUpdatedOrderNumber
    },
    [adjustments, currentlyReorderingAdjustments, props.adjustmentType],
  )

  const onDrop = async (dragIndex: number, hoverIndex: number) => {
    const adjustmentsWithUpdatedOrderNumber = await onHover(dragIndex, hoverIndex)
    setCurrentlyReorderingAdjustments(undefined)
    updateIndividualProgressItem('reorderingAdjustments', ProgressState.LOADING)
    const allPromises = adjustmentsWithUpdatedOrderNumber
      .filter(isIrrelevantAdjustment(PREVIEW_ID))
      .map((changedAdjustment) =>
        patchAdjustment(currentScenario!.id, changedAdjustment.id, {
          name: changedAdjustment.name,
          orderNumber: changedAdjustment.orderNumber,
        }),
      )

    await Promise.all(allPromises)
    resetAdjustments()
    changeAdjustments(adjustmentsWithUpdatedOrderNumber)
    updateIndividualProgressItem('reorderingAdjustments', ProgressState.FINISHED)
  }

  const calculateAggregatedGrossRateChange = (adjustments: Adjustment[]) => {
    return calculateAggregatedFieldChange(fullRenewalKpis, adjustments, (adjustment) => adjustment.pureGrossRateChange)
  }

  const calculateAggregatedRiskAdjustedPremiumRateChange = (adjustments: Adjustment[]) => {
    return calculateAggregatedFieldChange(
      fullRenewalKpis,
      adjustments,
      (adjustment) => adjustment.riskAdjustedPremiumRateChange,
    )
  }

  const calculateAggregatedConvexShareChange = (adjustments: Adjustment[]) => {
    return calculateAggregatedFieldChange(fullRenewalKpis, adjustments, (adjustment) => adjustment.convexShareChange)
  }

  const actualData = fullDataForScenario?.filter((item) => item[ADJUSTMENT_ID] === ACTUALS_ADJUSTMENT_ID)

  const canBeEdited =
    !props.hideCreateAndEdit &&
    currentScenario?.canWriteScenario &&
    scenarioCanStillBeWorkedOn(isAdmin, currentScenario.status[0].status)

  const totalClientSpecificAdjustedAmount = fullRenewalKpis
    ? allClientSpecificAdjustmentsIgnoringPreview.reduce(
        (current, nextAdjustment) =>
          current.plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][nextAdjustment.id] || ZERO),
        ZERO,
      )
    : ZERO

  const totalClientSpecificOriginalAmount = fullRenewalKpis
    ? allClientSpecificAdjustmentsIgnoringPreview.reduce(
        (current, nextAdjustment) =>
          current.plus(fullRenewalKpis[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN][nextAdjustment.id] || ZERO),
        ZERO,
      )
    : ZERO

  return (
    <div className="Adjustments">
      <SectionHeader title="Adjustments" />
      <div className="AdjustmentList">
        {fullRenewalKpis && fullRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][ACTUALS_ADJUSTMENT_ID] && (
          <AdjustmentCard
            index={1}
            adjustmentId={ACTUALS_ADJUSTMENT_ID}
            key={ACTUALS_ADJUSTMENT_ID}
            grarc={calculateGrarcKpi(actualData)?.toNumber()}
            adjustmentName={'Actuals'}
            adjustedGwp={fullRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][ACTUALS_ADJUSTMENT_ID] || ZERO}
            adjustedPercentage={calculateAdjustedPercentageOfOriginalGWPForMultipleAdjustments([OVERRIDDEN_BY_ACTUALS])}
            adjustmentDescription={''}
            isAdjustmentEnabled={true}
          />
        )}
        {Boolean(allClientSpecificAdjustmentsIgnoringPreview.length) && !isZero(totalClientSpecificAdjustedAmount) && (
          <AdjustmentCard
            index={1}
            adjustmentId={'CLIENT_SPECIFIC_SUMMARY'}
            key={'CLIENT_SPECIFIC_SUMMARY'}
            adjustmentName={'Client Specific'}
            adjustedGwp={totalClientSpecificAdjustedAmount}
            originalGwp={totalClientSpecificOriginalAmount}
            adjustedPercentage={calculateAdjustedPercentageOfOriginalGWPForMultipleAdjustments(
              allClientSpecificAdjustmentsIgnoringPreview.map((item) => item.id),
            )}
            gwpThatMatchesAdjFilter={allClientSpecificAdjustmentsIgnoringPreview.reduce((currentTotal, currentAdj) => {
              return currentTotal.plus(
                calculateKPIs(filterData(fullDataForScenario, currentAdj.appliedFilters))?.[TOTAL_GWP_COLUMN] || ZERO,
              )
            }, ZERO)}
            riskAdjustedPremiumRateChange={calculateAggregatedRiskAdjustedPremiumRateChange(
              allClientSpecificAdjustmentsIgnoringPreview,
            ).toNumber()}
            grossRateChange={calculateAggregatedGrossRateChange(allClientSpecificAdjustmentsIgnoringPreview).toNumber()}
            convexShareChange={calculateAggregatedConvexShareChange(
              allClientSpecificAdjustmentsIgnoringPreview,
            ).toNumber()}
            grarc={calculateGrossRiskAdjustedRateChange(
              calculateAggregatedGrossRateChange(allClientSpecificAdjustmentsIgnoringPreview).toNumber(),
              calculateAggregatedRiskAdjustedPremiumRateChange(allClientSpecificAdjustmentsIgnoringPreview).toNumber(),
            )}
            retention={calculateAggregatedFieldChange(
              fullRenewalKpis,
              allClientSpecificAdjustmentsIgnoringPreview,
              (adjustment) => adjustment.retention,
            ).toNumber()}
            adjustmentDescription={''}
            isAdjustmentEnabled={true}
          />
        )}
        <div className="SmallDivider"></div>
        {(adjustmentsSortedForDisplay || []).map((currentAdjustment, index) =>
          currentlyEditingAdjustmentId === currentAdjustment.id ? (
            <CreateAdjustmentCard
              key={'create-' + currentAdjustment.id}
              adjustmentBeingEditedId={currentlyEditingAdjustmentId}
              adjustmentType={props.adjustmentType}
            />
          ) : (
            <AdjustmentCard
              index={index}
              adjustmentId={currentAdjustment.id}
              key={currentAdjustment.id}
              adjustmentName={currentAdjustment.name}
              lowestOrderNumberIs0={true}
              orderNumber={index}
              gwpThatMatchesAdjFilter={
                calculateKPIs(filterData(fullDataForScenario, currentAdjustment.appliedFilters))?.[TOTAL_GWP_COLUMN] ||
                ZERO
              }
              originalGwp={fullRenewalKpis?.[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN]?.[currentAdjustment.id] || ZERO}
              riskAdjustedPremiumRateChange={currentAdjustment.riskAdjustedPremiumRateChange}
              grossRateChange={currentAdjustment.pureGrossRateChange}
              convexShareChange={currentAdjustment.convexShareChange}
              retention={currentAdjustment.retention}
              grarc={calculateGrossRiskAdjustedRateChange(
                currentAdjustment.pureGrossRateChange,
                currentAdjustment.riskAdjustedPremiumRateChange,
              )}
              adjustedGwp={fullRenewalKpis?.[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]?.[currentAdjustment.id] || ZERO}
              adjustedPercentage={calculateAdjustedPercentageOfOriginalGWPForMultipleAdjustments([
                currentAdjustment.id,
              ])}
              adjustmentDescription={currentAdjustment.description}
              isAdjustmentEnabled={currentAdjustment.isEnabled}
              onTriggerEditAdjustment={canBeEdited ? setWhichAdjustmentIsBeingEdited : undefined}
              onDrop={canBeEdited ? onDrop : undefined}
              onHover={canBeEdited ? onHover : undefined}
              hideCreateAndEdit={props.hideCreateAndEdit}
            />
          ),
        )}
      </div>
    </div>
  )
}
