import React from 'react'
import { withRouter } from 'react-router'

import './Scenarios.scss'

import { MainSelector } from './ScenarioChooser'
import CardSideBarContainer from '../../components/card-side-bar-container/CardSideBarContainer'
import { SidebarSelector } from '../../components/stacked-scenarios-list/StackedScenariosList'
import { StackedScenariosViewProvider } from '../../components/stacked-scenarios-view/StackedScenariosViewProvider'
import { StackedScenarioEditingProvider } from '../../components/stacked-scenarios-list/StackedScenarioEditingProvider'
import { StackedScenarioListProvider } from '../../components/stacked-scenarios-list/StackedScenarioListProvider'

function Component(): React.ReactElement {
  return (
    <div
      className="Scenarios"
      id="Scenarios"
    >
      <StackedScenariosViewProvider>
        <StackedScenarioListProvider>
          <StackedScenarioEditingProvider>
            <CardSideBarContainer
              leftColumm={<MainSelector />}
              rightColumn={<SidebarSelector />}
            />
          </StackedScenarioEditingProvider>
        </StackedScenarioListProvider>
      </StackedScenariosViewProvider>
    </div>
  )
}

export const Scenarios = withRouter(Component)
