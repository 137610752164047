import { Flex, Heading, Text } from '@chakra-ui/react'

export function ExposuresError() {
  return (
    <Flex
      flexDir={'column'}
      gap={'1rem'}
      height={'100%'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Heading size={'lg'}>Sorry, something went wrong</Heading>
      <Text>This page couldn't load. Please try again later.</Text>
    </Flex>
  )
}
