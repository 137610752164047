import React from 'react'
import Select, { OptionProps, Props as ReactSelectProps } from 'react-select'

import './MultiSelectDropdown.scss'

import { css } from '../../utils/css'
import { OptionWithModifier } from './SelectWithModifier'
import { DropdownGroup, OptionsType } from '../../utils/lists'

type Item = OptionsType[] | DropdownGroup[]

export type Props = {
  className?: ReactSelectProps['className']
  title?: string
  id?: string
  placeholder?: string
  options: ReactSelectProps['options'] & Item
  selected: undefined | OptionsType[]
  hasSelectAll?: boolean
  hasMultipleSelection?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
  testId?: string
  onSelect: <T extends OptionsType[]>(selectedItems: T) => void
  isOptionDisabled?: ReactSelectProps<OptionsType>['isOptionDisabled']
  onClick?: () => void
  onModifierKeyWasBeingPressed?: (callback: () => void) => void
  negated?: boolean
  boxed?: boolean
  link?: boolean
  hideArrow?: boolean
  error?: string
}

export function MultiSelectDropdown(props: Props): JSX.Element {
  const onOptionChosen = (value: any): void => {
    const options: OptionsType[] = [value].flat().filter(Boolean)
    props.onSelect(options)
  }

  return (
    <div
      className={css(
        'MultiSelectDropdown',
        props.className,
        props.boxed ? 'MultiSelectDropdownBoxed' : '',
        props.link ? 'MultiSelectDropdownLink' : '',
        props.hideArrow ? 'HideArrow' : '',
      )}
      data-testid={props.testId}
      onClick={props.onClick}
    >
      {props.title && (
        <h4
          className={css(
            'MultiSelectDropdownTitle',
            props.isDisabled && props.boxed ? 'MultiSelectDropdownTitleDisabled' : '',
          )}
        >
          {props.title}
        </h4>
      )}
      <Select
        data-testid={props.testId + '-select'}
        id={props.id}
        placeholder={props.placeholder}
        className={`react-select-container${props.negated ? ' negated' : ''} ${props.error ? 'error' : ''}`}
        components={
          props.onModifierKeyWasBeingPressed
            ? {
                Option: (optionProps: OptionProps<any, any>) => (
                  <OptionWithModifier
                    onModifierKeyWasBeingPressed={props.onModifierKeyWasBeingPressed!}
                    {...optionProps}
                  />
                ),
              }
            : undefined
        }
        classNamePrefix="react-select"
        options={props.options}
        onChange={onOptionChosen}
        value={props.selected}
        isMulti={props.hasMultipleSelection}
        closeMenuOnSelect={!props.hasMultipleSelection}
        isDisabled={props.link || props.isDisabled}
        isOptionDisabled={props.isOptionDisabled}
        isSearchable={props.isSearchable}
        isClearable={false}
      />
      {props.error ? <p className="error-text">{props.error}</p> : ''}
    </div>
  )
}
