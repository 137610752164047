import { StackedScenarioComparisonRows } from './StackedScenarioComparisonRows'
import { CssGridItem } from '../css-grid/CssGridRows'
import { ScenarioFilterCard } from '../scenario-filter-card/ScenarioFilterCard'

export function StackedScenarioComparisonHeadersColumn() {
  return (
    <>
      {StackedScenarioComparisonRows.map((currentRow, currentRowIndex) => (
        <CssGridItem
          columnNumber={1}
          rowNumber={currentRowIndex + 1}
          key={`scenario-header-${currentRow.title}`}
        >
          {currentRowIndex === 0 ? <ScenarioFilterCard /> : <h3>{currentRow.title}</h3>}
        </CssGridItem>
      ))}
    </>
  )
}
