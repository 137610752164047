import React, { useContext, useMemo } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import './StackedScenarioParameterisation.scss'

import {
  StackedParamTableDisplayOptions,
  StackedScenarioParameterisationRequestContainer,
} from '../../components/team-parameterisation-request-section/StackedScenarioParameterisationRequestContainer'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import TeamParameterisationTable from '../../components/parameterisation-table/team-parameterisation-table'
import TeamParameterisationMarketSummaryTable from '../../components/parameterisation-table/team-parameterisation-market-summary-table'
import TeamParameterisationAttritionalComparisonTable from '../../components/parameterisation-table/team-parameterisation-attritional-comparison-table'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { ModelStatus, TcsStatus } from '../../backend/stacked-scenario-parameterisation'
import Divider from '../../components/divider/LineDivider'
import { ParameterisationInputTable } from '../../components/parameterisation-input-table/ParameterisationInputTable'
import { ParameterisationByIdResponse } from '../../backend/parameterisation'
import { NotesDataProvider, NoteSectionNames } from '../notes/NotesDataProvider'

export function StackedScenarioParameterisationContent() {
  const [tableDisplayStringFromQuery] = useQueryParam('tableDisplay', StringParam)
  const tableDisplay = useMemo(
    () => Object.keys(StackedParamTableDisplayOptions).find((item) => tableDisplayStringFromQuery === item),
    [tableDisplayStringFromQuery],
  )
  const { needToPollForMoreData, lastUpdatedAt, underlyingMarketParamData } = useContext(
    TeamParameterisationDataContext,
  )

  // Let the user know something is happening in the background
  const renderLastUpdatedAt = useMemo<undefined | string>(() => {
    if (!lastUpdatedAt) return
    return new Date(lastUpdatedAt).toLocaleTimeString([], { hour: '2-digit', minute: 'numeric', hour12: true })
  }, [lastUpdatedAt])

  const elementToReturnFactory = () => {
    const teamResultTableIsCurrentOne =
      !tableDisplay || tableDisplay === StackedParamTableDisplayOptions.TEAM_DISTRIBUTION
    const info = possibleMessageToDisplay(needToPollForMoreData, renderLastUpdatedAt, underlyingMarketParamData)
    if (info && teamResultTableIsCurrentOne) {
      return <div className="possibleMessageToDisplay">{info}</div>
    }
    return (
      <div className={'ParamTableAndInputContainer'}>
        {teamResultTableIsCurrentOne && <TeamParameterisationTable />}
        {tableDisplay === StackedParamTableDisplayOptions.MARKET_SUMMARY && <TeamParameterisationMarketSummaryTable />}
        {tableDisplay === StackedParamTableDisplayOptions.ATTRITIONAL_COMPARISON && (
          <TeamParameterisationAttritionalComparisonTable fieldNameThatWeAreComparing={'Attritional'} />
        )}
        {tableDisplay === StackedParamTableDisplayOptions.SINGLE_RISK_AEP_COMPARISON && (
          <TeamParameterisationAttritionalComparisonTable fieldNameThatWeAreComparing={'Single-risk AEP'} />
        )}
        {tableDisplay === StackedParamTableDisplayOptions.SINGLE_RISK_OEP_COMPARISON && (
          <TeamParameterisationAttritionalComparisonTable fieldNameThatWeAreComparing={'Single-risk OEP'} />
        )}
        {tableDisplay === StackedParamTableDisplayOptions.COMBINED_COMPARISON && (
          <TeamParameterisationAttritionalComparisonTable fieldNameThatWeAreComparing={'Combined'} />
        )}
        {tableDisplay === StackedParamTableDisplayOptions.SINGLE_RISK_FREQUENCY_COMPARISON && (
          <TeamParameterisationAttritionalComparisonTable
            fieldNameThatWeAreComparing={'Single-risk Frequency'}
            lossToggleOverride={'Frequency' as any}
          />
        )}
        {teamResultTableIsCurrentOne && (
          <>
            <Divider />
            <ParameterisationInputTable />
          </>
        )}
      </div>
    )
  }

  return (
    <div
      className="Parameterisation"
      id="Parameterisation"
    >
      <NotesDataProvider segmentsToDisplay={[NoteSectionNames.PARAM_GENERAL]}>
        <SplitContentContainerWithSideMenu
          leftColumm={<StackedScenarioParameterisationRequestContainer />}
          rightColumn={elementToReturnFactory()}
          sideMenu={
            <KpiBar>
              <FullKpiDisplay />
            </KpiBar>
          }
        />
      </NotesDataProvider>
    </div>
  )
}

function possibleMessageToDisplay(
  modelStatus: ModelStatus,
  time: undefined | string,
  underlyingMarketParamData: Array<null | ParameterisationByIdResponse>,
): undefined | JSX.Element {
  if (modelStatus === 'NOT_UP_TO_DATE') {
    return <p className="ParameterisationMessge">One or more markets have been updated. Please re-run</p>
  }
  if (!allUnderlyingHaveRun(underlyingMarketParamData)) {
    return <p className="ParameterisationMessge">All underlying markets need to first run</p>
  }
  if (modelStatus === TcsStatus.FAILED) {
    return <p className="ParameterisationMessge">TCS failed to return any results</p>
  }
  if (modelStatus === 'FIRST_TIME_LOAD') {
    return <p className="ParameterisationMessge">Looking up parameterisation results...</p>
  }
  if (modelStatus === 'SERVER_ERROR') {
    return <p className="ParameterisationMessge">Server error. Please try again later</p>
  }
  if (modelStatus === 'NEVER_RAN_PARAMETERISATION') {
    return <p className="ParameterisationMessge">No parameterisation results have been run yet</p>
  }
  if (modelStatus === TcsStatus.INPROGRESS) {
    const checked = time ? `(last checked at ${time})` : ''
    return <p className="ParameterisationMessge">Waiting for results... {checked}</p>
  }
  if (modelStatus === 'COMPLETE_NO_DATA') {
    return <p className="ParameterisationMessge">No Result for parameterisation</p>
  }
  return
}

// Tell typescript whether `underlyingMarketParamData` has enough (valid) data
export function allUnderlyingHaveRun(
  underlyingMarketParamData: Array<null | ParameterisationByIdResponse>,
): underlyingMarketParamData is ParameterisationByIdResponse[] {
  return underlyingMarketParamData.every(Boolean)
}
