import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { StringParam, useQueryParam } from 'use-query-params'

import './NavigationMenu.scss'

import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { OptionsType, toOption } from '../../utils/lists'
import { queryStringify } from '../../utils/urls'
import { Scenario } from '../../backend/scenarios'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { ActualProps, ActualsContext } from '../../providers/ActualDataChoiceProvider'
import { ScenarioStatusSelector } from '../scenario-status-selector/ScenarioStatusSelector'
import { PortfolioListingContext } from '../../providers/PortfolioListingProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { IsAdminContext } from '../../providers/IsAdminProvider'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti, ScenarioChooserContext } from '../../pages/scenario-chooser/ScenarioChooser'
import SectionHeader from '../../components/section-header/SectionHeader'
import Divider from '../../components/divider/LineDivider'
import WhichScenarioChooserToggle, {
  AvailableScenarioChooserPages,
} from '../../pages/scenario-chooser/OutwardOrInwardToggle'
import { OutwardScenarioChooserContext } from '../../pages/outward-scenario-chooser/OutwardScenarioChooser'
import { MarketV2Path } from '../../pages/marketV2/MarketV2'
import Button from '../button/Button'
import { LinkButton } from '../button/LinkButton'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { ActualDropdown } from '../actual-dropdown/ActualDropdown'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from 'src/pages/exposures/StackedExposures'

export type NavigationMenuProps = RouteComponentProps & React.PropsWithChildren<{}>

/**
 *
 * @deprecated
 * @description see AppQueryProvider.tsx for the new way of doing this
 */
export const addOnNeededQueryParams = (
  path: string,
  scenario: Scenario | StackedScenario | undefined,
  actual: ActualProps['actualsQuery'][0],
): string => {
  let queryOptions = {}
  if (scenario && path.includes('scenario-chooser')) {
    queryOptions = {
      ...queryOptions,
      team: scenario.team,
      division: scenario.division,
      year: scenario.year,
      portfoliolevel: 'Divisional',
      actuals: 'None',
    }
  }

  return path + '?' + qs.stringify(queryOptions)
}

const getPortfolioLevel = (
  stackedScenario: StackedScenario | undefined,
  scenario: Scenario | undefined,
  portfoliolevel: string | undefined | null,
  team: string | undefined | null,
) => {
  if (stackedScenario) {
    if (stackedScenario.scenarioLevel === 'ORGANISATIONAL') {
      return 'Organisational'
    }
    return 'Divisional'
  }
  if (scenario) {
    return 'Divisional'
  }
  if (portfoliolevel) {
    return portfoliolevel
  }
  if (team) {
    return 'Divisional'
  }
  return 'Organisational'
}

// returns: ['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028']
function yearsToUse(): string[] {
  const startYear: number = 2021
  const currentYear = new Date().getFullYear()
  const yearsNeeded = currentYear - startYear + 6

  let years: string[] = []
  for (let i = 0; i < yearsNeeded; i++) {
    years.push((startYear + i).toString())
  }
  return years
}

const PORTFOLIO_LEVELS = ['Organisational', 'Divisional']

function Component(props: NavigationMenuProps): React.ReactElement {
  const { way1383ReportBugButton, customUsLicensing } = useFlags<Flag>()
  const [programmeFromQuery] = useQueryParam('programme', StringParam)
  const [treatyFromQuery, setTreaty] = useQueryParam('treaty', StringParam)
  const [versionFromQuery, setVersion] = useQueryParam('version', StringParam)

  const { yearQuery, portfoliolevelQuery, divisionQuery, marketQuery, teamQuery } = useContext(AppQueryContext)
  const { availablePrograms, availableYears, availableVersions, selectNewProgramme, availableTreaties } =
    useContext(OutwardScenarioChooserContext)
  const { currentScenario, updateScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { isAdmin } = useContext(IsAdminContext)
  const { availableDivisions, availableTeams } = useContext(ScenarioChooserContext)
  const { reloadListing, portfolioStatus } = useContext(PortfolioListingContext)
  const { actualsQuery } = useContext(ActualsContext)

  const [yearFromQuery, setYear] = yearQuery
  const [portfoliolevelFromQuery, setPortfoliolevel] = portfoliolevelQuery
  const [divisionFromQuery, setDivision] = divisionQuery
  const [teamFromQuery, setTeam] = teamQuery
  const [marketFromQuery] = marketQuery
  const [actualFromQuery] = actualsQuery

  const currentYear: null | undefined | string =
    currentScenario?.year || currentStackedScenario?.year || currentOutwardScenario?.year || yearFromQuery
  const currentDivision: null | undefined | string = currentScenario
    ? currentScenario.division
    : currentStackedScenario
    ? currentStackedScenario.division
    : divisionFromQuery
  const currentProgramme: null | undefined | string = currentOutwardScenario
    ? currentOutwardScenario?.programme
    : programmeFromQuery
  const currentTreaty: null | undefined | string = currentOutwardScenario
    ? currentOutwardScenario?.treaty
    : treatyFromQuery
  const currentVersion: null | undefined | string = currentOutwardScenario
    ? currentOutwardScenario?.version
    : versionFromQuery
  const currentTeam: null | undefined | string = currentScenario?.team ?? currentStackedScenario?.team ?? teamFromQuery
  const market: null | undefined | string = currentScenario?.market ?? marketFromQuery
  const currentPortfolioLevel: string = getPortfolioLevel(
    currentStackedScenario,
    currentScenario,
    portfoliolevelFromQuery,
    currentTeam,
  )

  const isOnScenarioChooserPage = props.location.pathname.includes('/scenario-chooser')
  const isOnOutwardScenarioChooserPage = props.location.pathname.includes('/outwardScenario-chooser')
  const isOnMarketV2Page = props.location.pathname.includes(MarketV2Path)

  const onSelectProgramme = (values: OptionsType[]): void => {
    console.log('onSelectProgramme', values)
    if (!isOnOutwardScenarioChooserPage) {
      const params = qs.stringify({
        year: currentYear,
        division: currentDivision,
        version: currentVersion,
        programme: values[0].value,
      })
      const url = '/outwardScenario-chooser?' + params
      props.history.push(url)
    } else {
      selectNewProgramme(values)
    }
  }

  const onSelectTreaty = (values: OptionsType[]): void => {
    console.log('onSelectTreaty', values)
    if (isOnOutwardScenarioChooserPage) {
      setTreaty(values[0].value)
    } else {
      const params = qs.stringify({
        year: currentYear,
        version: currentVersion,
        division: currentDivision,
        programme: currentProgramme,
        treaty: values[0].value,
      })
      const url = '/outwardScenario-chooser?' + params
      props.history.push(url)
    }
  }

  const onSelectVersion = (values: OptionsType[]): void => {
    console.log('onSelectVersion', values)
    if (isOnOutwardScenarioChooserPage) {
      onSelectSingleValueFromMulti(setVersion)(values)
    } else {
      const params = queryStringify({
        year: currentYear,
        version: values[0].value,
        division: currentDivision,
      })
      const url = '/outwardScenario-chooser' + params
      props.history.push(url)
    }
  }

  const onSelectYear = (values: OptionsType[]): void => {
    console.log('onSelectYear', values)
    if (isOnOutwardScenarioChooserPage || isOnScenarioChooserPage) {
      onSelectSingleValueFromMulti(setYear)(values)
    } else if (currentOutwardScenario) {
      const params = qs.stringify({ year: values[0].value, division: currentDivision })
      const url = '/outwardScenario-chooser?' + params
      props.history.push(url)
    } else {
      const params = qs.stringify({ year: values[0].value, division: currentDivision })
      const url = '/scenario-chooser?' + params
      props.history.push(url)
    }
  }

  const onSelectNewPortfolioLevel = (values: OptionsType[]): void => {
    console.log('onSelectNewPortfolioLevel', values, isOnScenarioChooserPage)
    if (isOnScenarioChooserPage) {
      setDivision(undefined)
      setTeam(undefined)
      setPortfoliolevel(values[0].value)
    } else {
      const params = qs.stringify({
        year: currentYear,
        portfoliolevel: values[0].value,
        division: currentDivision,
      })
      const url = '/scenario-chooser?' + params
      props.history.push(url)
    }
  }

  const onSelectNewDivision = (values: OptionsType[]): void => {
    console.log('onSelectNewDivision', values)
    if (isOnScenarioChooserPage) {
      setTeam(undefined)
      setDivision(values[0].value)
    } else {
      const redirectToWithQueryParams = addOnNeededQueryParams(
        '/scenario-chooser',
        { ...currentScenario!, division: values[0].value, year: currentYear! },
        actualFromQuery,
      )
      props.history.push(redirectToWithQueryParams)
    }
  }

  const onSelectTeam = (values: OptionsType[]): void => {
    console.log('onSelectTeam', values)
    if (isOnScenarioChooserPage) {
      setTeam(values[0].value)
    } else {
      const redirectToWithQueryParams = addOnNeededQueryParams(
        '/scenario-chooser',
        {
          ...currentScenario!,
          division: currentDivision!,
          team: values[0].value,
          year: currentYear!,
        },
        actualFromQuery,
      )
      props.history.push(redirectToWithQueryParams)
    }
  }

  const onSelectMarket = (): void => {
    console.log('onSelectMarket', market)
    if (!isOnScenarioChooserPage && currentScenario) {
      const redirectToWithQueryParams = addOnNeededQueryParams(
        '/scenario-chooser',
        { ...currentScenario!, team: currentScenario.team, division: currentScenario.division },
        actualFromQuery,
      )
      props.history.push(redirectToWithQueryParams)
    }
  }

  const onSelectTitle = (): void => {
    console.log('onSelectTitle')
    if (isOnScenarioChooserPage) {
      return
    }
    const redirectToWithQueryParams = addOnNeededQueryParams(
      '/scenario-chooser',
      { ...currentScenario!, division: currentDivision!, team: currentTeam! },
      actualFromQuery,
    )
    props.history.push(redirectToWithQueryParams)
  }

  const onLogoutClick = (): void => {
    localStorage.clear()
    window.location.reload()
  }

  const redirectToAirtableForm = () => {
    window.open('https://airtable.com/apprJnVZc0dUSt2Xa/shrh9CCP07v8SwfVk', '_blank')
  }

  const yearActual: undefined | string =
    currentYear || currentScenario?.year || currentStackedScenario?.year || currentOutwardScenario?.year

  const showInwardsOutwardsToggle = isOnScenarioChooserPage || isOnOutwardScenarioChooserPage
  const showYearDropdown = currentScenario || currentStackedScenario || isOnScenarioChooserPage || isOnMarketV2Page
  const showPortfolioDropdown = currentScenario || currentStackedScenario || isOnScenarioChooserPage || isOnMarketV2Page
  const showDivisionDropdown =
    currentScenario || currentStackedScenario?.division || isOnScenarioChooserPage || isOnMarketV2Page
  const showTeamDropdown =
    currentScenario || currentStackedScenario?.team || isOnScenarioChooserPage || isOnMarketV2Page
  const showMarketDropdown = (currentScenario || isOnMarketV2Page) && market
  const showOutwardsDropdownItems = currentOutwardScenario || isOnOutwardScenarioChooserPage
  const showScenarioControlDropdown =
    !isOnScenarioChooserPage &&
    !isOnMarketV2Page &&
    yearActual &&
    !currentStackedScenario &&
    (currentDivision || currentScenario?.division)

  return (
    <div className="NavigationMenu">
      {props.children}
      {showInwardsOutwardsToggle && (
        <WhichScenarioChooserToggle
          currentlySelected={
            isOnOutwardScenarioChooserPage
              ? AvailableScenarioChooserPages.OUTWARD
              : AvailableScenarioChooserPages.INWARD
          }
        />
      )}
      <Link
        to={addOnNeededQueryParams('/scenario-chooser', currentScenario, undefined)}
        className="ScenarioNavigationMenuItem"
      ></Link>
      <div className="ScenarioFilter">
        {showYearDropdown && (
          <>
            <SectionHeader title="Scenario Navigator" />
            <MultiSelectDropdown
              title="Year"
              id="Scenario-Navigator-Dropdown-Year"
              placeholder="Select Year"
              className="Year"
              onSelect={onSelectYear}
              selected={currentYear ? [toOption(currentYear)] : []}
              options={yearsToUse().map(toOption)}
              boxed={true}
            />
          </>
        )}
        {showPortfolioDropdown && (
          <MultiSelectDropdown
            title="Portfolio Level"
            id="Scenario-Navigator-Dropdown-Portfolio-Level"
            onSelect={onSelectNewPortfolioLevel}
            selected={currentPortfolioLevel ? [toOption(currentPortfolioLevel)] : []}
            placeholder="Select a portfolio level"
            options={
              portfolioStatus.portfolio?.portfolioListing
                ? PORTFOLIO_LEVELS.map(toOption)
                : [toOption(PORTFOLIO_LEVELS[0])]
            }
            boxed={true}
          />
        )}
        {showDivisionDropdown && (
          <MultiSelectDropdown
            title="Division"
            id="Scenario-Navigator-Dropdown-Division"
            onSelect={onSelectNewDivision}
            selected={
              currentDivision
                ? [toOption(currentDivision)]
                : currentScenario
                ? [toOption(currentScenario.division)]
                : [{ label: 'All', value: '' }]
            }
            placeholder="Select a division"
            options={[{ label: 'All', value: '' }, ...availableDivisions.map(toOption)]}
            isDisabled={(currentPortfolioLevel || 'Organisational') === 'Organisational'}
            boxed={true}
          />
        )}
        {showTeamDropdown && (
          <MultiSelectDropdown
            title="Team"
            id="Scenario-Navigator-Dropdown-Team"
            testId="teams-dropdown"
            onSelect={onSelectTeam}
            selected={
              currentTeam
                ? [toOption(currentTeam)]
                : currentScenario
                ? [toOption(currentScenario.team)]
                : [{ label: 'All', value: '' }]
            }
            placeholder="Select a team"
            options={[{ label: 'All', value: '' }, ...availableTeams.map(toOption)]}
            isDisabled={
              customUsLicensing
                ? currentDivision !== 'Reinsurance' && currentDivision !== 'Insurance' && currentDivision !== 'CUS'
                : currentDivision !== 'Reinsurance' && currentDivision !== 'Insurance'
            }
            boxed={true}
          />
        )}
        {showMarketDropdown && (
          <MultiSelectDropdown
            title="Market"
            id="Scenario-Navigator-Dropdown-Market"
            testId="market-dropdown"
            onSelect={onSelectMarket}
            onClick={onSelectMarket}
            selected={[toOption(market)]}
            options={[]}
            boxed={true}
            link={true}
          />
        )}
        {currentScenario && (
          <MultiSelectDropdown
            title="Scenario"
            id="Scenario-Navigator-Dropdown-Scenario"
            testId="title-dropdown"
            onSelect={onSelectTitle}
            selected={[toOption(currentScenario.name)]}
            options={[]}
            boxed
            link
            className={'NormalCursor'}
            hideArrow
          />
        )}
        {currentStackedScenario && (
          <MultiSelectDropdown
            title="Stacked Scenario"
            id="Scenario-Navigator-Dropdown-Stacked-Scenario"
            testId="title-dropdown"
            onSelect={() => void 0}
            selected={[toOption(currentStackedScenario.name)]}
            options={[]}
            boxed
            link
            className={'NormalCursor'}
            hideArrow
          />
        )}
        {showOutwardsDropdownItems && (
          <>
            <SectionHeader title="Scenario Navigator" />
            <MultiSelectDropdown
              title="Year"
              id="Scenario-Navigator-Dropdown-Year"
              placeholder="Select Year"
              className="Year"
              boxed={true}
              onSelect={onSelectYear}
              options={availableYears.map(toOption)}
              selected={currentYear ? [toOption(currentYear)] : []}
            />
            <MultiSelectDropdown
              title="Version"
              id="Scenario-Navigator-Dropdown-Version"
              testId="versions-dropdown"
              boxed={true}
              onSelect={onSelectVersion}
              selected={currentVersion ? [toOption(currentVersion)] : []}
              placeholder="Select a version"
              options={availableVersions.map(toOption)}
              isDisabled={currentYear === undefined}
            />
            <MultiSelectDropdown
              title="Programme"
              id="Scenario-Navigator-Dropdown-Programme"
              boxed={true}
              onSelect={onSelectProgramme}
              selected={currentProgramme ? [toOption(currentProgramme)] : [{ label: 'All', value: '' }]}
              placeholder="Select a programme"
              options={[...[{ label: 'All', value: '' }], ...availablePrograms.map(toOption)]}
            />
            <MultiSelectDropdown
              title="Treaty"
              id="Scenario-Navigator-Dropdown-Treaty"
              testId="treaties-dropdown"
              boxed={true}
              onSelect={onSelectTreaty}
              selected={currentTreaty ? [toOption(currentTreaty)] : [{ label: 'All', value: '' }]}
              placeholder="Select a treaty"
              options={[...[{ label: 'All', value: '' }], ...availableTreaties.map(toOption)]}
              isDisabled={currentProgramme === ''}
            />
          </>
        )}
      </div>
      {showScenarioControlDropdown && (
        <div>
          <Divider />
          <SectionHeader title="Scenario Control" />
          <ActualDropdown
            year={yearActual}
            division={currentDivision || currentScenario?.division}
            disabled={currentScenario?.status[0].status === ScenarioStatus.COMPLETE}
          />
        </div>
      )}
      {currentScenario ? (
        isAdmin || currentScenario?.status[0].status !== ScenarioStatus.COMPLETE ? (
          <ScenarioStatusSelector
            existingScenario={currentScenario}
            onSelectFunction={(status: ScenarioStatus) => {
              // Reload the datachoices so this scenario is now included in the list
              if (status === ScenarioStatus.COMPLETE) {
                reloadListing()
              }
              updateScenario({
                scenarioStatus: {
                  user: null,
                  status,
                },
                currentYearActualsDataName: actualFromQuery,
              })
            }}
            currentStatus={currentScenario?.status[0].status}
            boxed={true}
          />
        ) : (
          <div className="NavigationMenuStatusComplete">
            <h4 className="NavigationMenuStatusCompleteTitle">Status</h4>
            <h4 className="NavigationMenuStatusCompleteStatus">Complete</h4>
          </div>
        )
      ) : (
        <div />
      )}

      <LinkButton
        href="https://app.powerbi.com/groups/2eff4d7a-9d1b-41f3-8be6-a1d010dce7bb/reports/b3d81ce2-25a2-4dec-a95d-d4261ac6e2ea/ReportSection"
        target="_blank"
        rel="noopener noreferrer"
        className="PowerBIButton"
      >
        PowerBI
      </LinkButton>

      <Button
        title={'Logout'}
        secondary={false}
        className={'LogoutButton'}
        onClick={onLogoutClick}
        disabled={false}
      />
      {way1383ReportBugButton && (
        <Button
          title={'Report a bug'}
          secondary={true}
          className={'reportBugButton'}
          onClick={redirectToAirtableForm}
        />
      )}
    </div>
  )
}

export const NavigationMenu = withRouter(Component)
