import React, { useContext, useEffect, useMemo, useState } from 'react'
import './Client.scss'
import { FilterContext } from '../../providers/FilterProvider'
import { GraphContainer } from '../../components/graph-container/GraphContainer'
import Graphs from '../../components/graphs/Graphs'
import { AdjustedGwpBreakdownPieGraphWithFilter } from '../../components/renewal-graph/AdjustedGwpBreakdownPieGraphWithFilter'
import {
  ADJUSTED_CONVEX_LIMIT,
  ADJUSTED_CONVEX_SHARE_VALUE,
  ADJUSTED_PREMIUM_VALUE,
  CONVEX_LIMIT_COLUMN_NAME,
  PREMIUM_COLUMN_NAME,
  REQUIREMENT_NAME_COLUMN_IDENTIFIER,
  TOTAL_ADJUSTED_GWP_COLUMN,
  TOTAL_GWP_COLUMN,
} from '../../backend/calculate-kpis'
import { GwpAndAdjustedGwpBarChartWithColumnChooser } from '../../components/renewal-graph/gwp-bar-chart/GwpAndAdjustedGwpBarChartWithColumnChooser'
import { PossiblyNegativeNumber } from '../../utils/numbers'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import PortfolioDataTable from '../../components/portfolio-data-table/PortfolioDataTable'
import { FieldToGroupPortfolioBy } from '../../providers/PortfolioGroupDropdownAndProvider'
import { CONVEX_SHARE_COLUMN_NAME } from '../../backend/calculate-possible-filter-values'
import { RawEntry } from '../../backend/rawEntry'
import { groupListOfObjsBy } from '../../utils/lists'
import { GraphStyleContext } from '../../components/renewal-graph/GraphStyleProvider'
import { CLIENT_NAME_FILTER_ID } from './filterIds'

const BASE_SET_OF_COLUMNS_TO_DISPLAY = [
  FieldToGroupPortfolioBy.CONVEX_REQUIREMENT,
  FieldToGroupPortfolioBy.CONVEX_LAYER,
  PREMIUM_COLUMN_NAME,
  ADJUSTED_PREMIUM_VALUE,
  CONVEX_LIMIT_COLUMN_NAME,
  ADJUSTED_CONVEX_LIMIT,
  CONVEX_SHARE_COLUMN_NAME,
  ADJUSTED_CONVEX_SHARE_VALUE,
  'Written Line',
  'Signed Line',
]

export const CLIENT_PARENT_NAME_COLUMN = 'Client Parent Name'

export function calculateColumnsToInclude(data: RawEntry[], clientColumnNameToUse: string): string[] {
  let columnsToInclude = [...BASE_SET_OF_COLUMNS_TO_DISPLAY]

  if (thereIsMoreThanOneValueInColumn(data, clientColumnNameToUse)) {
    columnsToInclude = [clientColumnNameToUse, ...columnsToInclude]
  }

  if (thereIsMoreThanOneValueInColumn(data, CLIENT_PARENT_NAME_COLUMN)) {
    columnsToInclude = [CLIENT_PARENT_NAME_COLUMN, ...columnsToInclude]
  }

  return columnsToInclude
}

function thereIsMoreThanOneValueInColumn(data: RawEntry[], columnName: string): boolean {
  const dataGroupedByColumn = groupListOfObjsBy(data, columnName)

  return Object.keys(dataGroupedByColumn).length > 1
}

function ClientGraphs(props: { numberOfClientsToDisplay: PossiblyNegativeNumber; clientNameColumnToUse: string }) {
  const { fullDataForScenario, filteredDataForScenario } = useContext(FullScenarioDataContext)
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const [setAvailableClients] = useState<string[]>([])
  useEffect(() => {
    const allRowsClientNames = fullDataForScenario.map((item) => item[props.clientNameColumnToUse])
    const allRowsUniqueClientNames = new Set(allRowsClientNames)
    const availableClients = Array.from(allRowsUniqueClientNames)
    availableClients.sort()
  }, [fullDataForScenario, setAvailableClients, props.clientNameColumnToUse])

  const currentlyChosenName = dropdownFilterOptions[CLIENT_NAME_FILTER_ID]
    ? (dropdownFilterOptions[CLIENT_NAME_FILTER_ID]!.chosenValue as string[])[0]
    : undefined

  useEffect(() => {
    const allRowsMatchingClient = fullDataForScenario
      .filter((item) => item[props.clientNameColumnToUse] === currentlyChosenName)
      .map((item) => item[REQUIREMENT_NAME_COLUMN_IDENTIFIER])
    const allRowsUnique = new Set(allRowsMatchingClient)
    const availableRequirements = Array.from(allRowsUnique)
    availableRequirements.sort()
  }, [currentlyChosenName, fullDataForScenario, props.clientNameColumnToUse])

  useEffect(() => {
    const allRowsMatchingClient = fullDataForScenario
      .filter((item) => item[props.clientNameColumnToUse] === currentlyChosenName)
      .map((item) => item[REQUIREMENT_NAME_COLUMN_IDENTIFIER])
    const allRowsUnique = new Set(allRowsMatchingClient)
    const availableRequirements = Array.from(allRowsUnique)
    availableRequirements.sort()
  }, [currentlyChosenName, fullDataForScenario, props.clientNameColumnToUse])

  const columnsToDisplay = useMemo(
    () => calculateColumnsToInclude(filteredDataForScenario, props.clientNameColumnToUse),
    [filteredDataForScenario, props.clientNameColumnToUse],
  )

  return (
    <div
      className="Client"
      id="Client"
    >
      <Graphs>
        <GraphContainer
          className="GraphLeft"
          graphId="GraphOne"
        >
          <AdjustedGwpBreakdownPieGraphWithFilter
            defaultGraphColumn={props.clientNameColumnToUse}
            overrideColumnValue={
              dropdownFilterOptions[CLIENT_NAME_FILTER_ID]?.chosenValue ? 'Convex Requirement Name' : undefined
            }
            numberOfItemsToInclude={props.numberOfClientsToDisplay as number}
            fieldsToOrderBy={[TOTAL_GWP_COLUMN, TOTAL_ADJUSTED_GWP_COLUMN]}
            numericFieldToUse={TOTAL_ADJUSTED_GWP_COLUMN}
            hideOthers
            hideNew
          />
        </GraphContainer>
        <GraphContainer
          className="GraphMediumRight"
          graphId="GraphTwo"
        >
          <GwpAndAdjustedGwpBarChartWithColumnChooser
            defaultColumn={props.clientNameColumnToUse}
            overrideColumnValue={
              dropdownFilterOptions[CLIENT_NAME_FILTER_ID]?.chosenValue ? 'Convex Requirement Name' : undefined
            }
            numberOfItemsToInclude={props.numberOfClientsToDisplay as number}
            fieldsToOrderBy={[TOTAL_GWP_COLUMN, TOTAL_ADJUSTED_GWP_COLUMN]}
            hideOthers
            hideNew
          />
        </GraphContainer>
      </Graphs>
      {!currentlyExpandedGraph && (
        <PortfolioDataTable
          includedColumns={columnsToDisplay}
          heightToSubtract={430}
        />
      )}
    </div>
  )
}

export default ClientGraphs
