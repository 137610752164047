import React, { useContext, useEffect, useState } from 'react'
import ChartComponent from 'react-chartjs-2'

import { GraphHeader } from '../../graph-header/GraphHeader'
import { GraphDataProvider } from '../GraphDataProvider'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'
import { formatInceptionYearForDisplay, sortInceptionMonth } from '../gwp-bar-chart/inception-month-graph'
import { GraphFilterDataContext } from '../GraphFilterDataProvider'
import { MultiSelectDropdown } from '../../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../../pages/scenario-chooser/ScenarioChooser'
import { LimitAndGwpLineChartContext, LimitAndGwpLineChartProvider } from './LimitAndGwpLineChartDataProvider'
import { GraphStyleContext } from '../GraphStyleProvider'
import { GraphContainerContext } from '../../graph-container/GraphContainer'
import { formatForDisplay } from '../../../utils/numbers'
import { toOption } from '../../../utils/lists'

interface LimitAndGwpLineWithColumnChooserProps {
  defaultColumn: string
  overrideColumnValue?: string
}

export function LimitAndGwpLine(props: LimitAndGwpLineWithColumnChooserProps): JSX.Element {
  const { possibleStringColumnValues } = useContext(GraphFilterDataContext)
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)

  const [currentGraphColumn, setCurrentGraphColumn] = useState(props.defaultColumn || 'A1 Segment')

  const isExpanded = currentlyExpandedGraph === graphId

  useEffect(() => {
    if (props.overrideColumnValue) {
      setCurrentGraphColumn(props.overrideColumnValue)
    } else {
      setCurrentGraphColumn(props.defaultColumn || 'A1 Segment')
    }
  }, [props.overrideColumnValue, props.defaultColumn])

  return (
    <>
      <GraphHeader title="Limit and GWP" />
      <MultiSelectDropdown
        onSelect={onSelectSingleValueFromMulti(setCurrentGraphColumn)}
        options={possibleStringColumnValues.map(toOption)}
        selected={[toOption(currentGraphColumn)]}
      />
      <GraphDataProvider
        fieldNameToGroupBy={currentGraphColumn}
        sortDataFunction={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME
            ? (data) => sortInceptionMonth(data, currentGraphColumn)
            : undefined
        }
        formatDataFieldForDisplay={
          currentGraphColumn === RENEWAL_INCEPTION_MONTH_COLUMN_NAME ? formatInceptionYearForDisplay : undefined
        }
      >
        <div className="Graph LimitAndGwpChart">
          <LimitAndGwpLineChartProvider>
            <LimitAndGwpLineChartContext.Consumer>
              {(context) => (
                <ChartComponent
                  data={context.graphData}
                  options={{
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        title: (tooltipItem: any, data: any) => {
                          return `${tooltipItem[0].label} - ${context?.graphData?.datasets?.[
                            tooltipItem?.[0]?.datasetIndex
                          ]?.label}`
                        },
                        label: (tooltipItem: any, data: any) => {
                          return formatForDisplay(tooltipItem.value)
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          type: 'category',
                          ticks: {
                            callback: (item: string | number) =>
                              typeof item === 'number' ? formatForDisplay(item) : item,
                          },
                          autoSkip: false,
                          display: isExpanded, //this will remove all the x-axis grid lines
                          offset: true,
                        },
                      ],
                      yAxes: [
                        {
                          id: 'gwp',
                          position: 'right',
                          ticks: {
                            callback: (item: string | number) =>
                              typeof item === 'number' ? formatForDisplay(item) : item,
                          },
                          display: isExpanded, //this will remove all the x-axis grid lines
                        },
                        {
                          id: 'limit',
                          ticks: {
                            callback: (item: string | number) =>
                              typeof item === 'number' ? formatForDisplay(item) : item,
                          },
                          display: isExpanded, //this will remove all the x-axis grid lines
                        },
                      ],
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                      autoPadding: true,
                    },
                  }}
                />
              )}
            </LimitAndGwpLineChartContext.Consumer>
          </LimitAndGwpLineChartProvider>
        </div>
      </GraphDataProvider>
    </>
  )
}
