import React, { SVGProps } from 'react'

const ChainedIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) translate(1.540757, 4.770378)"
          fill="#4E566B"
        >
          <path d="M1.22725618,3.22962152 C1.22725618,2.12509096 2.12509096,1.22725618 3.22962152,1.22725618 L5.81331873,1.22725618 L5.81331873,5.68434189e-14 L3.22962152,5.68434189e-14 C1.44687044,5.68434189e-14 0,1.44687044 0,3.22962152 C0,5.0123726 1.44687044,6.45924304 3.22962152,6.45924304 L5.81331873,6.45924304 L5.81331873,5.23198686 L3.22962152,5.23198686 C2.12509096,5.23198686 1.22725618,4.33415208 1.22725618,3.22962152 Z M3.87554582,3.87554582 L9.04294025,3.87554582 L9.04294025,2.58369721 L3.87554582,2.58369721 L3.87554582,3.87554582 Z M9.68886455,5.68434189e-14 L7.10516734,5.68434189e-14 L7.10516734,1.22725618 L9.68886455,1.22725618 C10.7933951,1.22725618 11.6912299,2.12509096 11.6912299,3.22962152 C11.6912299,4.33415208 10.7933951,5.23198686 9.68886455,5.23198686 L7.10516734,5.23198686 L7.10516734,6.45924304 L9.68886455,6.45924304 C11.4716156,6.45924304 12.9184861,5.0123726 12.9184861,3.22962152 C12.9184861,1.44687044 11.4716156,5.68434189e-14 9.68886455,5.68434189e-14 Z" />
        </g>
      </g>
    </svg>
  </div>
)

export default ChainedIcon
