import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Card } from '../card/Card'
import { useFlags } from 'launchdarkly-react-client-sdk'

import './CreateScenarioCard.scss'

import TextField from '../text-field/WFTextField'
import TextArea from '../../components/text-area/TextArea'
import Button from '../../components/button/Button'
import { createScenario, DateString, Scenario } from '../../backend/scenarios'
import { fetchSnowflakeVersions, SnowflakeVersionOptions } from '../../backend/teams'
import { onInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import { DropdownGroup, LoadingStatus, toOption } from '../../utils/lists'
import { Level } from '../../utils/urls'
import { DEFAULT_MAX_LENGTH_OF_STRING, MAX_LENGTH_OF_SHORT_STRINGS } from '../../utils/strings'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../pages/scenario-chooser/ScenarioChooser'
import { PortfolioListingContext, PortfolioStatus } from '../../providers/PortfolioListingProvider'
import { ActualsContext } from '../../providers/ActualDataChoiceProvider'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { ActualDropdown, hideSnowflakeOnEnv } from '../actual-dropdown/ActualDropdown'
import { DataChoice } from '../../backend/api-types/PortfolioListing.types'
import { Version } from '../../backend/api-types/SnowflakeScenario.types'
import { Flag } from '../../pages/exposures/StackedExposures'
import qs from 'qs'

export type CreateScenarioCardProps = RouteComponentProps & {
  year: undefined | string
  division: undefined | string
  team: undefined | string
  market: undefined | string
  onCreate?: () => void
  onCancel?: () => void
  scenarios: Scenario[]
}

interface ScenarioCreationRequest {
  currentData: undefined | string
  currentName: string
  currentSummary?: string | undefined | null
  actual: undefined | string
  currentDataScenarioId: string | undefined
  snowflakeDataSourceVersion: DateString | undefined
}

async function createScenarioButtonClicked(
  args: CreateScenarioCardProps,
  data: ScenarioCreationRequest,
  updateIndividualProgressItem: (key: string, state: ProgressState) => void,
): Promise<void> {
  const { onCreate, history, year, division, market, team } = args

  const { currentData, currentDataScenarioId, currentName, snowflakeDataSourceVersion, actual, currentSummary } = data
  if (!currentData) {
    throw new Error('No "currentData" selected')
  }
  const level: Level = { year, division, team, market }
  const scenarioId = await createScenario(level, {
    name: currentName,
    data: currentData,
    summary: currentSummary,
    previousYearActualsDataName: currentDataScenarioId ? actual : undefined,
    currentYearActualsDataName: currentDataScenarioId ? undefined : actual,
    snowflakeDataSourceVersion: currentDataScenarioId ? undefined : snowflakeDataSourceVersion,
    chainedScenarioId: currentDataScenarioId ? currentDataScenarioId : undefined,
  })
  updateIndividualProgressItem('creatingScenario', ProgressState.LOADING)
  try {
    updateIndividualProgressItem('creatingScenario', ProgressState.FINISHED)
    onCreate?.()
    const params = qs.stringify({ division, team, year, market })
    if (actual) {
      history.push(`/scenarios/${scenarioId}/renewal?${params}&actuals=${actual}`)
    } else {
      history.push(`/scenarios/${scenarioId}/renewal?${params}`)
    }
  } catch (e) {
    updateIndividualProgressItem('creatingScenario', ProgressState.ERROR)
  }
}

export function previousYears(level: Level, portfolioStatus: PortfolioStatus): DataChoice[] {
  const { status, portfolio } = portfolioStatus
  if (status === 'error') {
    console.warn('Error fetching portfolio listing')
    return [{ name: 'Error' } as DataChoice]
  }
  if (status === 'loading') {
    return [{ name: 'Loading' } as DataChoice]
  }
  if (status === 'unloaded') {
    console.warn('Portfolio listing not loaded')
    return [{ name: 'Unloaded' } as DataChoice]
  }

  const { year, division, team, market } = level
  if (!year) {
    console.warn('No year found')
    return []
  }
  if (!division) {
    console.warn('No division found')
    return []
  }

  const divisionListing = portfolio.portfolioListing.divisions[division]
  if (!divisionListing) {
    console.warn('No division listing found')
    return []
  }

  const { dataChoices } = divisionListing
  const portfolioListingsForCurrentYear: DataChoice[] = dataChoices.filter((choice) => {
    const sameYear = choice.year === year
    const sameTeam = choice.team === team
    const sameMarket = choice.market === market
    const emptyTeam = choice.team === ''
    const emptyMarket = choice.market === ''
    return !choice.isScenarioOutput && sameYear && ((sameTeam && sameMarket) || (emptyTeam && emptyMarket))
  })
  const scenarioOutputsForLastyear: DataChoice[] = dataChoices.filter((choice) => {
    const sameYear = choice.year === String(Number(year) - 1)
    const sameTeam = choice.team === team
    const sameMarket = choice.market === market
    const emptyTeam = choice.team === ''
    const emptyMarket = choice.market === ''
    return choice.isScenarioOutput && sameYear && ((sameTeam && sameMarket) || (emptyTeam && emptyMarket))
  })

  return [...portfolioListingsForCurrentYear, ...scenarioOutputsForLastyear]
}

function calculatePossibleVersionDataItems(snowflakeStatus: SnowflakeStatus): string[] {
  const { status, versions } = snowflakeStatus
  if (status === 'error') {
    console.warn('Error fetching snowflake listing')
    return ['Error']
  }
  if (status === 'unloaded') {
    console.warn('Snowflake listing not loaded')
    return ['Unloaded']
  }
  if (status === 'loading') {
    console.warn('Snowflake listing loading')
    return ['Loading']
  }

  const versionOptions: string[] = versions.map((v) => v.reportingDaySnapshot)

  return Array.from(new Set(versionOptions))
}

function giveListAsListOfOptions(label: string, labelList: Array<string>): DropdownGroup {
  const options = labelList.map((label) => ({ label, value: label }))
  return { label, options }
}

export function makeAllSourceOptions(possibleDataChoices: DataChoice[]): DropdownGroup[] {
  const rawDataChoices: DataChoice[] = possibleDataChoices.filter((item) => !item.isScenarioOutput)

  if (rawDataChoices.length) {
    const snowflakeOption: string[] = ['Snowflake']
    const options: string[] = snowflakeOption.concat(rawDataChoices.map((item) => item.name))
    const rawDataAsOptions: DropdownGroup = giveListAsListOfOptions('Raw Data', options)
    return [rawDataAsOptions]
  }

  const previousScenarioDataChoices: DataChoice[] = possibleDataChoices.filter((item) => item.isScenarioOutput)
  const options: string[] = previousScenarioDataChoices.map((item) => item.name)
  const previousScenariosAsOptions: DropdownGroup = giveListAsListOfOptions('Scenario Outputs', options)
  return [previousScenariosAsOptions]
}

function calculateOptionsAsVersionsForPossibleDataItems(versions: string[]): [DropdownGroup] {
  const versionAsOptions: DropdownGroup = giveListAsListOfOptions('Version', versions)
  return [versionAsOptions]
}

export function validateDescription(
  description: string | null | undefined,
  maxLength = DEFAULT_MAX_LENGTH_OF_STRING,
): undefined | string {
  if ((description?.length || 0) > maxLength) {
    return `Please enter a shorter description (${description?.length} / ${maxLength} characters)`
  }

  return undefined
}

export function validateNameAndCalculateIfErrorIsNeeded(
  nameToValidate: string,
  existingScenarios: Scenario[],
  maxLength = MAX_LENGTH_OF_SHORT_STRINGS,
): string | undefined {
  if ((nameToValidate?.length || 0) > maxLength) {
    return `Please enter a shorter name (${nameToValidate?.length} / ${maxLength} characters)`
  }

  if (!isValidName(nameToValidate)) {
    return 'Please enter a name for the scenario'
  }

  if (existingScenarios.length) {
    const index = existingScenarios.findIndex((scenario) => scenario.name === nameToValidate)
    if (index !== -1) {
      return 'Name must be unique for this combination of year, divison, team, market'
    }
  }

  return undefined
}

type SnowflakeStatus = LoadingStatus<'versions', Version[]>

function CreateScenarioCard(props: CreateScenarioCardProps): JSX.Element {
  const { division, team, year, market } = props

  const { snowflake } = useFlags<Flag>()

  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { portfolioStatus } = useContext(PortfolioListingContext)
  const { actualsQuery } = useContext(ActualsContext)

  const [dataSource, setDataSource] = useState<undefined | string>(undefined)
  const [currentVersionData, setVersionData] = useState<DateString>()
  const [currentName, setName] = useState<string>('')
  const [currentSummary, setSummary] = useState<string>('')
  const [shouldValidate, updateValidation] = useState<boolean>(false)
  const [canViewVersions, setCanViewVersions] = useState<boolean>(false)
  const [snowflakeStatus, setSnowflakeStatus] = useState<SnowflakeStatus>({ status: 'unloaded', versions: undefined })
  const [actual] = actualsQuery

  useEffect(() => {
    setCanViewVersions(dataSource === 'Snowflake')
  }, [dataSource])

  useEffect(() => {
    updateIndividualProgressItem('snowflakeVersions', ProgressState.LOADING)
    setSnowflakeStatus({ status: 'loading', versions: undefined })
    const controller = new AbortController()
    const options: SnowflakeVersionOptions = {
      signal: controller.signal,
      parameters: {
        year: year ?? undefined,
        division: division ?? undefined,
        team: team ?? undefined,
        market: market ?? undefined,
      },
    }
    fetchSnowflakeVersions(options)
      .then(
        (res) => setSnowflakeStatus({ status: 'loaded', versions: res.versions }),
        (err) => setSnowflakeStatus({ status: 'error', versions: undefined, error: err?.message }),
      )
      .finally(() => updateIndividualProgressItem('snowflakeVersions', ProgressState.FINISHED))

    return () => {
      return controller.abort()
    }
  }, [updateIndividualProgressItem, year, division, team, market])

  const snowflakeVersionOptionsForData: DropdownGroup[] = snowflakeGroupList(snowflakeStatus)

  const possibleDataItems: DataChoice[] = previousYears({ year, division, team, market }, portfolioStatus)
  const allSourceOptions: DropdownGroup[] = makeAllSourceOptions(possibleDataItems)
  const chosenDataIsFromPreviousScenario: undefined | boolean = previousScenario(possibleDataItems, dataSource)
  const currentDataScenarioId: undefined | string = getCurrentScenarioId(possibleDataItems, dataSource)

  if (!props.year) {
    console.warn('CreateScenarioCard: year is undefined')
    return <></>
  }

  const yearParsed: number = parseInt(props.year)
  const yearPrevious: string = (yearParsed - 1).toString()
  const yearCurrent: string = yearParsed.toString()

  const yearValue: string = chosenDataIsFromPreviousScenario ? yearPrevious : yearCurrent

  return (
    <div className="CreateScenarioCard">
      <Card>
        <h4 className="CreateScenarioCardTitle">Create Scenario</h4>
        <TextField
          title="Name"
          id="Create-Scenario-Card-TextField-Name"
          placeholder="Name"
          className="Name"
          value={currentName}
          onChange={onInputUpdateState(setName)}
          error={shouldValidate ? validateNameAndCalculateIfErrorIsNeeded(currentName, props.scenarios) : undefined}
        />
        <MultiSelectDropdown
          title="Source Data"
          id="Create-Scenario-Card-Dropdown-Source-Data"
          placeholder="Choose..."
          className="Data"
          onSelect={onSelectSingleValueFromMulti(setDataSource)}
          selected={dataSource ? [toOption(dataSource)] : []}
          options={hideSnowflakeOnEnv(snowflake, allSourceOptions)}
        />
        {canViewVersions ? (
          <MultiSelectDropdown
            title="Data Version"
            id="Create-Scenario-Card-Dropdown-Data-Version"
            placeholder="Choose..."
            className="Data Version"
            onSelect={onSelectSingleValueFromMulti(setVersionData)}
            selected={currentVersionData ? [toOption(currentVersionData)] : []}
            options={snowflakeVersionOptionsForData}
          />
        ) : (
          <></>
        )}
        <ActualDropdown
          year={yearValue}
          id="Create-Scenario-Card-Dropdown-Actual-Data"
          division={division}
          disabled={false}
        />
        <TextArea
          title="Summary"
          id="Create-Scenario-Card-TextArea-Summary"
          placeholder="Summary"
          value={currentSummary}
          onChange={onTextAreaUpdateState(setSummary)}
          error={shouldValidate ? validateDescription(currentSummary) : undefined}
        />
        <div className="ButtonContainer">
          {props.onCancel && (
            <Button
              title="Cancel"
              id="Create-Scenario-Card-Button-Cancel"
              onClick={props.onCancel}
              secondary
            />
          )}
          <Button
            title="Create"
            id="Create-Scenario-Card-Button-Create"
            onClick={async () => {
              if (!validate(updateValidation, currentName, props.scenarios)) {
                return
              }
              await createScenarioButtonClicked(
                props,
                {
                  currentName,
                  currentData: dataSource,
                  currentSummary,
                  actual: actual ?? undefined,
                  snowflakeDataSourceVersion: currentVersionData,
                  currentDataScenarioId,
                },
                updateIndividualProgressItem,
              )
            }}
          />
        </div>
      </Card>
    </div>
  )
}

function getCurrentScenarioId(possibleDataItems: DataChoice[], dataSource: undefined | string): undefined | string {
  const currentDataItem = possibleDataItems.find((item) => item.name === dataSource)
  return currentDataItem?.scenarioId
}

function previousScenario(possibleDataItems: DataChoice[], dataSource: undefined | string): undefined | boolean {
  const currentDataItem = possibleDataItems.find((item) => item.name === dataSource)
  return currentDataItem?.isScenarioOutput
}

function snowflakeGroupList(snowflakeStatus: SnowflakeStatus): DropdownGroup[] {
  if (snowflakeStatus.status !== 'loaded') {
    return []
  }
  const versions: string[] = calculatePossibleVersionDataItems(snowflakeStatus)
  return calculateOptionsAsVersionsForPossibleDataItems(versions)
}

function validate(
  updateValidation: React.Dispatch<React.SetStateAction<boolean>>,
  currentName: string,
  scenarios: Scenario[],
): boolean {
  updateValidation(true)
  const isValid = validateNameAndCalculateIfErrorIsNeeded(currentName, scenarios) === undefined
  if (!isValid) {
    console.log('isValid:', isValid)
  }
  return isValid
}

export default withRouter(CreateScenarioCard)
