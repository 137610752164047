import { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { FullScenarioDataContext, splitIntoRenewalAndNew } from '../FullScenarioData/FullScenarioDataProvider'
import { calculateGrarcKpi, calculateKPIs, filterData, WeightedAvgKpis } from '../../backend/calculate-kpis'
import { transformIntoFlatStructure, FilterContext } from '../FilterProvider'
import { RawEntry } from '../../backend/rawEntry'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { fetchFullOutwardsStackedScenarioData } from '../../backend/scenarios-data'
import { fetchUnderlyingData } from '../../backend/underlyingData'

interface StackedOutwardsDataProviderProps {
  stackedOutwardsScenarioId: string
}

export const StackedOutwardsDataProvider = (props: PropsWithChildren<StackedOutwardsDataProviderProps>) => {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const filtersToApply = useMemo(() => transformIntoFlatStructure(dropdownFilterOptions), [dropdownFilterOptions])

  const [fullStackedOutwardsData, setFullStackedOutwardsData] = useState<RawEntry[]>([])

  const reloadAllStackedScenarioDataSets = () => {
    if (!props.stackedOutwardsScenarioId) {
      return
    }
    updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.LOADING)

    fetchFullOutwardsStackedScenarioData(props.stackedOutwardsScenarioId)
      .then((downloadLinkResponse) => fetchUnderlyingData(downloadLinkResponse.downloadLink))
      .then((allData) => allData.flat())
      .then(setFullStackedOutwardsData)
      .then(() => updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('stackedScenarioDataFetch', ProgressState.ERROR))
  }

  useEffect(reloadAllStackedScenarioDataSets, [props.stackedOutwardsScenarioId, updateIndividualProgressItem])

  const fullRenewalAndNew = useMemo(() => {
    const result = splitIntoRenewalAndNew(fullStackedOutwardsData)
    return result
  }, [fullStackedOutwardsData])
  const fullRenewalKpis = useMemo(() => calculateKPIs(fullRenewalAndNew.renewal), [fullRenewalAndNew])
  const fullNewKpis = useMemo(
    () => calculateKPIs([...fullRenewalAndNew.new, ...fullRenewalAndNew.newClient]),
    [fullRenewalAndNew],
  )

  const filteredDataForScenario = useMemo(() => {
    const result = filterData(fullStackedOutwardsData, filtersToApply)
    return result
  }, [fullStackedOutwardsData, filtersToApply])

  const filteredRenewalAndNew = useMemo(() => {
    return splitIntoRenewalAndNew(filteredDataForScenario)
  }, [filteredDataForScenario])

  const filteredRenewalKpis = useMemo(() => calculateKPIs(filteredRenewalAndNew.renewal), [filteredRenewalAndNew])

  const filteredNewKpis = useMemo(
    () => calculateKPIs([...filteredRenewalAndNew.new, ...filteredRenewalAndNew.newClient]),
    [filteredRenewalAndNew],
  )

  const grarcKpi = useMemo(() => calculateGrarcKpi(fullStackedOutwardsData), [fullStackedOutwardsData])

  const newGrarcKpi = useMemo(
    () => calculateGrarcKpi([...filteredRenewalAndNew.new, ...filteredRenewalAndNew.newClient]),
    [filteredRenewalAndNew.new, filteredRenewalAndNew.newClient],
  )

  const renewalGrarcKpi = useMemo(
    () => calculateGrarcKpi([...filteredRenewalAndNew.renewal]),
    [filteredRenewalAndNew.renewal],
  )

  return (
    <FullScenarioDataContext.Provider
      value={{
        fullDataForScenario: fullStackedOutwardsData,
        fullRenewalKpis,
        fullNewKpis,
        fullWeightedAverageKpis: {} as WeightedAvgKpis,
        grarcKpi,
        newGrarcKpi,
        renewalGrarcKpi,
        weightedAverageRenewalKpis: {} as WeightedAvgKpis,
        filteredDataForScenario,
        filteredRenewalKpis,
        filteredNewKpis,
        filteredNewDataForScenario: filteredRenewalAndNew.new,
        filteredRenewalDataForScenario: filteredRenewalAndNew.renewal,
        filteredNewClientDataForScenario: filteredRenewalAndNew.newClient,
        filteredNewCustomPortfolioDataForScenario: filteredRenewalAndNew.newCustomPortfolio,
        filteredDataToUseInGraph: fullStackedOutwardsData,
        reloadData: () => void 0,
        setPreviewNewClient: () => void 0,
        setPreviewRenewal: () => void 0,
        setPreviewNewVirtual: () => void 0,
      }}
      children={props.children}
    />
  )
}
