import React from 'react'
import { css } from '../../utils/css'
import CountUp from 'react-countup'
import './KpiCard.scss'
import Big from 'big.js'
import { formatForAnimation, numericValueIsDefined } from '../../utils/numbers'
import { usePrevious } from '../../utils/hooks/usePrevious'

export type KpiCardProps = React.PropsWithChildren<{
  value: Big | undefined
  title: string
  prefix?: string
  suffix?: string
  className?: string | string[]
  decimals?: number
}>

export function calculateNumberOfDecimalsToDisplay(
  value: number,
  numberOfDecimalsToDisplay: number | undefined,
): number {
  if (numberOfDecimalsToDisplay === 0) {
    return 0
  }

  const numberEndsWithZero = value
    .toFixed(numberOfDecimalsToDisplay || 1)
    .endsWith('.' + '0'.repeat(numberOfDecimalsToDisplay || 1))

  if (numberEndsWithZero) {
    return 0
  }

  const lengthOfValueBeforeDecimalPoint = value.toFixed(0).length

  if (lengthOfValueBeforeDecimalPoint === 1) {
    return numberOfDecimalsToDisplay || 1
  }

  return numberOfDecimalsToDisplay || 0
}

export function KpiCard(props: KpiCardProps) {
  const applyFormatting = props.suffix === '%' ? (big: Big) => big.toNumber() : formatForAnimation

  const previousValue = usePrevious(props.value)
  return (
    <div
      className={css('KpiCard', props.className)}
      title={props.value?.toString()}
    >
      <h6 className="Title">{props.title}</h6>
      <div className="Value">
        {props.value !== undefined ? (
          <CountUp
            duration={0.75}
            start={numericValueIsDefined(previousValue) ? applyFormatting(previousValue!) : 0}
            end={applyFormatting(props.value)}
            prefix={props.prefix}
            suffix={props.suffix}
            decimals={calculateNumberOfDecimalsToDisplay(applyFormatting(props.value), props.decimals)}
          />
        ) : (
          '-'
        )}
      </div>
    </div>
  )
}

export default KpiCard
