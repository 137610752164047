import React from 'react'
import qs from 'qs'
import { Card } from '../../components/card/Card'
import { useHistory } from 'react-router-dom'

import './MarketCard.scss'
import MarketIcon from '../../components/icons/MarketIcon'

interface MarketCardProps {
  year: number | string
  marketName: string
  division: string
  team: string
}

export function MarketCard(props: MarketCardProps) {
  const { marketName, year, division, team } = props

  const history = useHistory()

  const handleClick = (): void => {
    const query = {
      division,
      team,
      market: marketName,
      year,
    }
    const path = `/market-editor?${qs.stringify(query)}`
    history.push(path)
  }

  return (
    <div
      className="MarketCard"
      key={'create-minimised'}
      onClick={handleClick}
    >
      <Card>
        <MarketIcon />
      </Card>
    </div>
  )
}
