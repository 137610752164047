import React, { useContext, useState } from 'react'
import { Card } from '../card/Card'
import './EditScenarioCard.scss'
import { patchScenario, Scenario } from '../../backend/scenarios'
import Button from '../button/Button'
import TextField from '../text-field/WFTextField'
import { onInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import TextArea from '../text-area/TextArea'
import { scenarioCanStillBeWorkedOn } from '../../utils/status/ScenarioStatusValidation'
import { IsAdminContext } from '../../providers/IsAdminProvider'

export type EditScenarioCardProps = {
  scenario: Scenario
  triggerStopEditing: () => void
  triggerSaveChanges: () => void
}

function EditScenarioCard({ scenario, triggerStopEditing, triggerSaveChanges, ...otherProps }: EditScenarioCardProps) {
  const [currentName, setName] = useState(scenario.name)
  const [currentSummary, setSummary] = useState(scenario.summary || '')
  const { isAdmin } = useContext(IsAdminContext)
  const [shouldValidate, setShouldValidate] = useState(false)

  const onClickSave = () => {
    setShouldValidate(true)

    if (!isValidName(currentName)) {
      return
    }

    patchScenario(scenario.id, {
      name: currentName,
      summary: currentSummary,
    }).then(triggerSaveChanges)
  }

  return (
    <div className="EditScenarioCard">
      <Card>
        <TextField
          title="Name"
          placeholder="Name"
          value={currentName}
          onChange={onInputUpdateState(setName)}
          error={shouldValidate ? (isValidName(currentName) ? '' : 'Please enter a name for the adjustment') : ''}
          className="Name"
          disabled={!scenarioCanStillBeWorkedOn(isAdmin, scenario.status[0].status)}
        />
        <TextArea
          title="Summary"
          placeholder="Summary"
          value={currentSummary}
          onChange={onTextAreaUpdateState(setSummary)}
        />
        <div className="ButtonContainer">
          <Button
            title="Cancel"
            secondary
            onClick={triggerStopEditing}
          />
          <Button
            title="Save"
            onClick={onClickSave}
          />
        </div>
      </Card>
    </div>
  )
}

export default EditScenarioCard
