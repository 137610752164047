import React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'

import { formatForDisplay } from '../../utils/numbers'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { displayHeaderBasedOnScenarioLevel } from '../../components/stacked-market-table/StackedMarketBreakdownByLevelTable'
import { AggregatedMarketV2 } from '../../backend/aggregated-market-V2'

type Accessor = keyof PerRowDataSet

export type HeaderGroupStacked = Column<{ Header: string; accessor: Accessor }>

// TODO: fix this constrctor
export const makeHeaderGroupList: (currentStackedScenario: StackedScenario | undefined) => HeaderGroupStacked[] = (
  currentStackedScenario: StackedScenario | undefined,
) => {
  const list = [
    {
      Header: displayHeaderBasedOnScenarioLevel(currentStackedScenario?.scenarioLevel),
      accessor: 'aggregateObjectLabel',
      Cell: (scenarioId: string) => (cellProps: any) => {
        const { value } = cellProps

        let route = `/scenarios/${scenarioId}/marketV2`

        return (
          <Link to={route}>
            <div>{value}</div>
          </Link>
        )
      },
    },
    {
      Header: 'Total Market GWP',
      accessor: 'totalMarketGwp',
    },
    {
      Header: 'PML Scenario 1 In 100',
      accessor: 'pmlScenario1In100',
    },
    {
      Header: 'Confidence In Market Size',
      accessor: 'confidenceInMarketSize',
    },
    {
      Header: 'Access Or Capability',
      accessor: 'accessOrCapability',
    },
    {
      Header: 'In Appetite',
      accessor: 'inAppetite',
    },
    {
      Header: 'Addressable Market GWP',
      accessor: 'addressableMarketGwp',
    },
    {
      Header: 'Rate Environment',
      accessor: 'rateEnvironment',
    },
    {
      Header: 'Peak Convex GWP',
      accessor: 'peakConvexGwp',
    },
    {
      Header: 'Peak Convex Market Share',
      accessor: 'peakConvexMarketShare',
    },
    {
      Header: 'Convex Estimated Share Of PML',
      accessor: 'convexEstimatedShareOfPml',
    },
    {
      Header: 'Previous Year GNLR',
      accessor: 'previousYearGnlr',
    },
    {
      Header: 'Previous Year GGLR',
      accessor: 'previousYearGglr',
    },
    {
      Header: 'Previous Year Convex Acquisition Ratio',
      accessor: 'previousYearConvexAcquisitionRatio',
    },
    {
      Header: 'Convex Acquisition Ratio',
      accessor: 'convexAcquisitionRatio',
    },
    {
      Header: 'Market GRARC',
      accessor: 'marketGrarc',
    },
    {
      Header: 'Market NRARC',
      accessor: 'marketNrarc',
    },
    {
      Header: 'Inflation',
      accessor: 'inflation',
    },
    {
      Header: 'ABE GGLR',
      accessor: 'AbeGglr',
    },
    {
      Header: 'Underwriter Convex Acquisition Ratio',
      accessor: 'uwConvexAcquisitionRatio',
    },
    {
      Header: 'Underwriter Market Grarc',
      accessor: 'uwMarketGrarc',
    },
    {
      Header: 'Underwriter Inflation',
      accessor: 'uwInflation',
    },
    {
      Header: 'Underwriter ABE GGLR',
      accessor: 'uwAbeGglr',
    },
  ]
  return list as unknown as any // TODO: fix this
}

export interface PerRowDataSet {
  aggregateObjectLabel: string
  aggregateObjectId: string

  totalMarketGwp: string
  pmlScenario1In100: string
  confidenceInMarketSize: string
  accessOrCapability: string
  inAppetite: string
  addressableMarketGwp: string
  rateEnvironment: string
  peakConvexGwp: string
  peakConvexMarketShare: string
  convexEstimatedShareOfPml: string
  previousYearGnlr: string
  previousYearGglr: string
  previousYearConvexAcquisitionRatio: string
  convexAcquisitionRatio: string
  marketGrarc: string
  marketNrarc: string
  inflation: string
  AbeGglr: string
  uwConvexAcquisitionRatio: string
  uwMarketGrarc: string
  uwInflation: string
  uwAbeGglr: string

  composedOf?: AggregatedMarketV2[]
}

export function convertAggregatedMarketToTableData(subScenariosData: AggregatedMarketV2[]): Array<PerRowDataSet> {
  return subScenariosData.map((subData) => {
    return {
      aggregateObjectLabel: subData.aggregateObjectLabel,
      aggregateObjectId: subData.aggregateObjectId,

      totalMarketGwp: '$' + formatForDisplay(subData.totalMarketGwp || 0),
      pmlScenario1In100: (Number(subData.pmlScenario1In100).toFixed(1) || 0) + '%',
      confidenceInMarketSize: (Number(subData.confidenceInMarketSize).toFixed(1) || 0) + '%',
      accessOrCapability: (Number(subData.accessOrCapability).toFixed(1) || 0) + '%',
      inAppetite: (Number(subData.inAppetite).toFixed(1) || 0) + '%',
      addressableMarketGwp: '$' + formatForDisplay(subData.addressableMarketGwp || 0),
      rateEnvironment: subData.rateEnvironment,
      peakConvexGwp: '$' + formatForDisplay(subData.peakConvexGwp || 0),
      peakConvexMarketShare: (Number(subData.peakConvexMarketShare).toFixed(1) || 0) + '%',
      convexEstimatedShareOfPml: '$' + formatForDisplay(subData.convexEstimatedShareOfPml || 0),
      previousYearGnlr: (Number(subData.previousYearGnlr).toFixed(1) || 0) + '%',
      previousYearGglr: (Number(subData.previousYearGglr).toFixed(1) || 0) + '%',
      previousYearConvexAcquisitionRatio: (Number(subData.previousYearConvexAcquisitionRatio).toFixed(1) || 0) + '%',
      convexAcquisitionRatio: (Number(subData.convexAcquisitionRatio).toFixed(1) || 0) + '%',
      marketGrarc: (Number(subData.marketGrarc).toFixed(1) || 0) + '%',
      marketNrarc: (Number(subData.marketNrarc).toFixed(1) || 0) + '%',
      inflation: (Number(subData.inflation).toFixed(1) || 0) + '%',
      AbeGglr: (Number(subData.AbeGglr).toFixed(1) || 0) + '%',
      uwConvexAcquisitionRatio: (Number(subData.uwConvexAcquisitionRatio).toFixed(1) || 0) + '%',
      uwMarketGrarc: (Number(subData.uwMarketGrarc).toFixed(1) || 0) + '%',
      uwInflation: (Number(subData.uwInflation).toFixed(1) || 0) + '%',
      uwAbeGglr: (Number(subData.uwAbeGglr).toFixed(1) || 0) + '%',
    }
  })
}
