import { EntityInsuranceFields, EntityReinsuranceFields } from '../types'

export const defaultEntityReinsuranceValues: EntityReinsuranceFields = {
  CIL: 0,
  CES: 0,
  CRL: 0,
}

export const defaultEntityInsuranceValues: EntityInsuranceFields = {
  CIL: 0,
  CES: 0,
  CGU: 0,
}

export const entityReinsuranceFieldNames: (keyof EntityReinsuranceFields)[] = Object.getOwnPropertyNames(
  defaultEntityReinsuranceValues,
) as (keyof EntityReinsuranceFields)[]

export const entityInsuranceFieldNames: (keyof EntityInsuranceFields)[] = Object.getOwnPropertyNames(
  defaultEntityInsuranceValues,
) as (keyof EntityInsuranceFields)[]
