import React from 'react'
import './LandingSideBar.scss'
export type LandingSideBarProps = React.PropsWithChildren<{}>

export function LandingSideBar(props: LandingSideBarProps) {
  return (
    <div
      className="LandingSideBar"
      {...props}
    >
      {props.children}
    </div>
  )
}

export default LandingSideBar
