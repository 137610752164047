import React, { createContext, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from '../../../providers/ProgressProvider'
import { GraphDataContext } from '../GraphDataProvider'
import {
  ADJUSTED_CONVEX_LIMIT,
  ADJUSTED_CONVEX_LIMIT_WITH_BASIC_RETENTION,
  CONVEX_LIMIT_COLUMN_NAME,
  TOTAL_ADJUSTED_GWP_COLUMN,
  TOTAL_GWP_COLUMN,
} from '../../../backend/calculate-kpis'
import Big from 'big.js'
import { sumListAsIntegers, sumListAsIntegersWithFallbackKeys } from '../../../utils/numbers'
import { GRAPH_COLOURS } from '../../graphs/Graphs'

const LimitAndGwpLineChartContext = createContext<LimitAndGwpLineChartContainer>({ graphData: [] })

const { Consumer, Provider } = LimitAndGwpLineChartContext

interface LimitAndGwpLineChartContainer {
  graphData: any
}

interface LimitAndGwpLineChartProps {}

function getDataPointsForField(
  summarisedData: any[],
  fieldNameToGroupBy: string,
  sumItemIntoBigFunction: (item: any) => Big,
) {
  return summarisedData.map((currentItem) => sumItemIntoBigFunction(currentItem))
}

const LimitAndGwpLineChartProvider = (props: React.PropsWithChildren<LimitAndGwpLineChartProps>) => {
  const [graphData, setGraphData] = useState<any>({})
  const { summarisedData, fieldNameToGroupBy } = useContext(GraphDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  useEffect(() => {
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.LOADING)
    const dataPointsForOriginalGwp = getDataPointsForField(
      summarisedData,
      fieldNameToGroupBy,
      (item) => item[TOTAL_GWP_COLUMN],
    )
    const dataPointsForAdjustedGwp = getDataPointsForField(
      summarisedData,
      fieldNameToGroupBy,
      (item) => item[TOTAL_ADJUSTED_GWP_COLUMN],
    )
    const dataPointsForLimit = getDataPointsForField(summarisedData, fieldNameToGroupBy, (item) =>
      sumListAsIntegers(item?.allOtherData || [], CONVEX_LIMIT_COLUMN_NAME),
    )
    const dataPointsForAdjustedLimit = getDataPointsForField(summarisedData, fieldNameToGroupBy, (item) =>
      sumListAsIntegersWithFallbackKeys(item?.allOtherData || [], ADJUSTED_CONVEX_LIMIT_WITH_BASIC_RETENTION, [
        ADJUSTED_CONVEX_LIMIT,
        CONVEX_LIMIT_COLUMN_NAME,
      ]),
    )

    const dataReadyForGraph = {
      labels: summarisedData.map((item) => item[fieldNameToGroupBy]),
      datasets: [
        {
          label: 'Original GWP',
          type: 'line',
          yAxisID: 'gwp',
          data: dataPointsForOriginalGwp,
          backgroundColor: GRAPH_COLOURS[0],
          fill: false,
        },
        {
          label: 'Adjusted GWP',
          type: 'line',
          yAxisID: 'gwp',
          backgroundColor: GRAPH_COLOURS[1],
          data: dataPointsForAdjustedGwp,
          fill: false,
          tension: 0,
        },
        {
          label: 'Original Limit',
          type: 'bar',
          yAxisID: 'limit',
          backgroundColor: GRAPH_COLOURS[0],
          data: dataPointsForLimit,
        },
        {
          label: 'Adjusted Limit',
          type: 'bar',
          yAxisID: 'limit',
          backgroundColor: GRAPH_COLOURS[1],
          data: dataPointsForAdjustedLimit,
        },
      ],
    }
    setGraphData(dataReadyForGraph)
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.FINISHED)
  }, [updateIndividualProgressItem, summarisedData, fieldNameToGroupBy])

  return (
    <Provider
      value={{ graphData }}
      children={props.children}
    />
  )
}

export { Consumer as GwpCumulativeLineChartDataConsumer, LimitAndGwpLineChartContext, LimitAndGwpLineChartProvider }
