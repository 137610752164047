import React from 'react'
import './Graphs.scss'

export const GRAPH_COLOURS = ['#E2F1CB', '#436F62', '#7999AC', '#FF8EA3', '#8DBAAD', '#97C7FF', '#5D6767']

export type GraphsProps = React.PropsWithChildren<{}>

export function Graphs(props: GraphsProps) {
  return <div className="Graphs">{props.children}</div>
}

export default Graphs
