import {
  ChooseMultipleColumnDropdownConsumer,
  ChooseMultipleColumnDropdownProvider,
} from '../ChooseMultipleColumnDropdownProvider'
import { NumericFieldChosenAndGroupdByOtherProvider } from './NumericFieldChooserAndGroupedByColumnProvider'
import React, { PropsWithChildren } from 'react'
import { GraphHeader } from '../../graph-header/GraphHeader'
import {
  PortfolioGroupDropdownConsumer,
  PortfolioGroupDropdownProvider,
} from '../../../providers/PortfolioGroupDropdownAndProvider'

interface NumericAndColumnChartWithFiltersProps {
  title: string
  defaultStringColumn?: string
}

export function NumericAndColumnChartWithFilters(props: PropsWithChildren<NumericAndColumnChartWithFiltersProps>) {
  return (
    <>
      <GraphHeader title={props.title} />
      <PortfolioGroupDropdownProvider>
        <PortfolioGroupDropdownConsumer>
          {(portfolioGroupContext) => (
            <ChooseMultipleColumnDropdownProvider defaultStringColumn={props.defaultStringColumn}>
              <ChooseMultipleColumnDropdownConsumer>
                {(multipleColumns) => (
                  <NumericFieldChosenAndGroupdByOtherProvider
                    chosenGroupingColumn={multipleColumns.currentlyChosenStringColumn}
                    chosenNumericColumn={multipleColumns.currentlyChosenNumericColumn}
                    portfolioGroupingColumn={portfolioGroupContext.currentFieldToGroupBy}
                  >
                    <div className="Graph">{props.children}</div>
                  </NumericFieldChosenAndGroupdByOtherProvider>
                )}
              </ChooseMultipleColumnDropdownConsumer>
            </ChooseMultipleColumnDropdownProvider>
          )}
        </PortfolioGroupDropdownConsumer>
      </PortfolioGroupDropdownProvider>
    </>
  )
}
