import { createContext, EffectCallback, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { StackedScenarioContext } from './StackedScenarioProvider'
import { AggregatedMarketV2 } from '../backend/aggregated-market-V2'
import { StackedScenarioComparisonContext } from './StackedScenarioComparisonProvider'
import { fetchAggregatedMarketV2 } from '../backend/aggregated-market-V2'
import { ScenarioLevel } from '../backend/stacked-scenarios'

const AggregatedMarketDataContextV2 = createContext<AggregatedMarketDataContainerV2>({
  aggregatedMarketData: undefined,
  reloadAggregatedMarketData: () => undefined,
})

export interface AggregatedMarketDataContainerV2 {
  aggregatedMarketData: AggregatedMarketV2 | undefined
  reloadAggregatedMarketData: () => void
}

export function getAFieldForEveryLeafNode(
  aggregatedMarketData: AggregatedMarketV2,
  currentStackedScenarioLevel: ScenarioLevel,
  field: keyof AggregatedMarketV2,
) {
  let allMarketLevelMarketdata: (AggregatedMarketV2 | undefined)[]
  if (currentStackedScenarioLevel === ScenarioLevel.ORGANISATIONAL) {
    allMarketLevelMarketdata =
      aggregatedMarketData.composedOf?.flatMap(
        (divisionLevelAggregations) =>
          divisionLevelAggregations.composedOf?.flatMap((teamLevelAggregations) => teamLevelAggregations.composedOf),
      ) || []
  } else if (currentStackedScenarioLevel === ScenarioLevel.DIVISIONAL) {
    allMarketLevelMarketdata =
      aggregatedMarketData.composedOf?.flatMap((teamLevelAggregations) => teamLevelAggregations.composedOf) || []
  } else {
    allMarketLevelMarketdata = aggregatedMarketData.composedOf || []
  }
  return allMarketLevelMarketdata.reduce(
    (marketAsKeyAndValueAsExtractedField, current) => ({
      ...marketAsKeyAndValueAsExtractedField,
      ...(current ? { [current.aggregateObjectLabel]: current[field] } : undefined),
    }),
    {},
  )
}

function AggregatedMarketDataProviderV2(props: PropsWithChildren<{}>) {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const normalContext = useContext(StackedScenarioContext)
  const comparisonContext = useContext(StackedScenarioComparisonContext)
  const currentStackedScenario = normalContext?.currentStackedScenario || comparisonContext?.currentStackedScenario // TODO: do we need this || fallback?

  const [currentAggregatedMarketData, setAggregatedMarketData] = useState<AggregatedMarketV2 | undefined>()

  const reloadAggregatedMarketData: EffectCallback = () => {
    if (!currentStackedScenario) {
      return
    }

    updateIndividualProgressItem('fetchAggregatedMarketData', ProgressState.LOADING)
    fetchAggregatedMarketV2(currentStackedScenario.id)
      .then(setAggregatedMarketData)
      .finally(() => updateIndividualProgressItem('fetchAggregatedMarketData', ProgressState.FINISHED))
  }

  useEffect(reloadAggregatedMarketData, [currentStackedScenario, updateIndividualProgressItem])

  return (
    <AggregatedMarketDataContextV2.Provider
      value={{
        aggregatedMarketData: currentAggregatedMarketData,
        reloadAggregatedMarketData,
      }}
      children={props.children}
    />
  )
}

export { AggregatedMarketDataProviderV2, AggregatedMarketDataContextV2 }
