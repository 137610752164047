import { ChangeEvent } from 'react'
import { css } from '../../utils/css'
import TextField from '../text-field/WFTextField'
import './MinMaxTextField.scss'
import { NumericFilter } from '../../backend/calculate-possible-filter-values'
import { displayDecimalAsPercentage, displayLargeDollarValue, NumberType } from '../../utils/numbers'
import { calculateValueIncludingSuffix } from '../../utils/onChange'

export type MinMaxTextFieldProps = {
  title?: string
  numberType: NumberType
  minValue: number
  maxValue: number
  minWarning?: string
  maxWarning?: string
  minError?: string | undefined | null | false
  maxError?: string | undefined | null | false
  className?: string | string[]
  onChange: (filter: NumericFilter) => void
  textCss?: string
  isDisabled?: boolean
}

export function removeOtherStuff(input: string) {
  return input.replace(/[A-Za-z,!@#$%^&*()]/g, '')
}

export function MinMaxTextField(props: MinMaxTextFieldProps) {
  const onChangeMin = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange({
      minimumValueInclusive: calculateValueIncludingSuffix(event.target.value, props.numberType) as any,
      maximumValueInclusive: props.maxValue,
    })
  }

  const onChangeMax = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange({
      minimumValueInclusive: props.minValue,
      maximumValueInclusive: calculateValueIncludingSuffix(event.target.value, props.numberType) as any,
    })
  }

  return (
    <div className={css('MinMaxTextField', props.className)}>
      {props.title && <p className="Title">{props.title}</p>}
      <div className="TextFields">
        <TextField
          value={
            props.numberType === NumberType.DOLLAR
              ? displayLargeDollarValue(props.minValue)
              : displayDecimalAsPercentage(props.minValue)
          }
          placeholder="Min"
          type="text"
          onChange={onChangeMin}
          warning={props.minWarning}
          error={props.minError}
          className={css('Min', props.textCss)}
          disabled={props.isDisabled}
        />
        <TextField
          value={
            props.numberType === NumberType.DOLLAR
              ? displayLargeDollarValue(props.maxValue)
              : displayDecimalAsPercentage(props.maxValue)
          }
          placeholder="Max"
          type="text"
          onChange={onChangeMax}
          warning={props.maxWarning}
          error={props.maxError}
          className={css('Max', props.textCss)}
          disabled={props.isDisabled}
        />
      </div>
    </div>
  )
}

export default MinMaxTextField
