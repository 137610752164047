import { useContext, useMemo } from 'react'
import { MarketDataContextV2 } from '../../providers/MarketDataProviderV2'
import { MarketSegmentV2 } from '../market-V2'

export const useMarketSegments = (): MarketSegmentV2[] => {
  const { marketData, defaultMarketData } = useContext(MarketDataContextV2)

  return useMemo(
    () => (marketData?.marketSegments ? marketData.marketSegments : defaultMarketData?.marketSegments ?? []),
    [marketData, defaultMarketData],
  )
}
