import React, { ReactElement } from 'react'
import { Card } from '../card/Card'
import './IconButton.scss'

interface IconButtonProps {
  icon: ReactElement
  onClick: () => void
}

function IconButton(props: IconButtonProps) {
  return (
    <div
      className="IconButton"
      onClick={props.onClick}
    >
      <Card>{props.icon}</Card>
    </div>
  )
}

export { IconButton }
