import { placementTypes } from './portfolioSplitsColumns'
import { CategoryTable } from './CategoryTable'
import { useCategoryForm } from './hooks/useCategoryForm'
import { FormProvider } from 'react-hook-form'
import { useContext } from 'react'
import { NcpContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { placementTypeFieldNames } from './data/placementType'
import { PlacementTypeFields } from './types'

export const PlacementTypesTable = () => {
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const initialPlacementTypeValues = ncpState.ncpData.categoryWeightings.placementType

  const form = useCategoryForm<PlacementTypeFields>({
    defaultValues: initialPlacementTypeValues,
  })

  return (
    <FormProvider {...form}>
      <CategoryTable<PlacementTypeFields>
        categoryLabel="Placement type"
        categoryName="placementType"
        columns={{
          labels: placementTypes,
          fields: placementTypeFieldNames,
        }}
        required
      />
    </FormProvider>
  )
}
