import React, { useContext, useMemo } from 'react'

import './Parameterisation.scss'

import { ParameterisationRequestContainer } from '../../components/parameterisation-request-section/ParameterisationRequestContainer'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import { ParameterisationTable } from '../../components/parameterisation-table/parameterisation-table'
import { ParameterisationInputTable } from '../../components/parameterisation-input-table/ParameterisationInputTable'
import Divider from '../../components/divider/LineDivider'
import { ParameterisationDataContext } from '../../providers/parameterisation/ParameterisationDataProvider'
import { ModelStatus, TcsStatus } from '../../backend/parameterisation'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { NotesDataProvider, NoteSectionNames } from '../notes/NotesDataProvider'

export function ParameterisationContent() {
  const { currentScenario } = useContext(ScenarioContext)
  const { needToPollForMoreData, lastUpdatedAt } = useContext(ParameterisationDataContext)

  // Let the user know something is happening in the background
  const renderLastUpdatedAt = useMemo<undefined | string>(() => {
    if (!lastUpdatedAt) return
    return new Date(lastUpdatedAt).toLocaleTimeString([], { hour: '2-digit', minute: 'numeric', hour12: true })
  }, [lastUpdatedAt])

  if (!currentScenario?.id) {
    return <SelectScenarioMessage />
  }

  const elementToReturnFactory = (): JSX.Element => {
    const info = possibleMessageToDisplay(needToPollForMoreData, renderLastUpdatedAt)
    if (info) {
      return <div className="possibleMessageToDisplay">{info}</div>
    }
    return (
      <div className={'ParamTableAndInputContainer'}>
        <ParameterisationTable />
        <Divider />
        <ParameterisationInputTable />
      </div>
    )
  }

  return (
    <div
      className="Parameterisation"
      id="Parameterisation"
    >
      <NotesDataProvider segmentsToDisplay={[NoteSectionNames.PARAM_GENERAL]}>
        <SplitContentContainerWithSideMenu
          leftColumm={<ParameterisationRequestContainer />}
          rightColumn={elementToReturnFactory()}
          sideMenu={
            <KpiBar>
              <FullKpiDisplay />
            </KpiBar>
          }
        />
      </NotesDataProvider>
    </div>
  )
}

function possibleMessageToDisplay(modelStatus: ModelStatus, time: undefined | string): undefined | JSX.Element {
  const textFormatter = (text: string) => <p className="ParameterisationMessge">{text}</p>
  if (modelStatus === TcsStatus.FAILED) {
    return textFormatter(`TCS failed to return any results`)
  }
  if (modelStatus === TcsStatus.DOWN) {
    return textFormatter(
      `TCS seems to be temporarily unavailable - please check back later or contact the Stochastic Analytics team on slack`,
    )
  }
  if (modelStatus === 'FIRST_TIME_LOAD') {
    return textFormatter(`Looking up parameterisation results...`)
  }
  if (modelStatus === 'SERVER_ERROR') {
    return textFormatter(`Server error. Please try again later`)
  }
  if (modelStatus === 'NEVER_RAN_PARAMETERISATION') {
    return textFormatter(`No parameterisation results have been run yet`)
  }
  if (modelStatus === TcsStatus.INPROGRESS) {
    const checked = time ? `(last checked at ${time})` : ''
    return textFormatter(`Waiting for results... ${checked}`)
  }
  if (modelStatus === 'COMPLETE_NO_DATA') {
    return textFormatter('No Result for parameterisation')
  }
  return
}
