import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react'
import { ExposuresAlert, ExposuresAlertState } from './ExposuresAlert'
import { ExposuresReportStatus } from '../ReportStatus'
import { useState } from 'react'
import { ExposuresConfirmOvernightModal } from './ExposuresConfirmOvernightModal'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../StackedExposures'
import { ExposuresReportOutdatedAlert, ExposuresReportOutdatedAlertState } from './ExposuresReportOutdatedAlert'

type ExposuresHeaderProps = {
  handleSave: () => void
  handleReprojection: () => void
  onCloseAlert: () => void
  alertStatus: ExposuresAlertState
  toReportsList: () => void
  reportStatus: ExposuresReportStatus
  targetMarketHasNoPendingReports: boolean
  reportId: string
  reportOutdatedAlertStatus: ExposuresReportOutdatedAlertState
  id?: string
}

export const ExposuresHeader = (props: ExposuresHeaderProps) => {
  const {
    handleSave,
    handleReprojection,
    alertStatus,
    onCloseAlert,
    toReportsList,
    reportStatus,
    targetMarketHasNoPendingReports,
    reportId,
    reportOutdatedAlertStatus,
  } = props
  const { exposuresV2Reprojection } = useFlags<Flag>()
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const handleOpenAlertDialog = () => {
    setIsAlertDialogOpen(true)
  }

  return (
    <>
      <Flex
        px={'1rem'}
        pt={'1rem'}
        mb={'1rem'}
      >
        <ExposuresAlert
          alertStatus={alertStatus.alertStatus}
          message={alertStatus.message}
          isActive={alertStatus.isActive}
          onClose={onCloseAlert}
        />
        <ExposuresReportOutdatedAlert
          alertStatus={reportOutdatedAlertStatus.alertStatus}
          message={reportOutdatedAlertStatus.message}
          isActive={reportOutdatedAlertStatus.isActive}
        />
      </Flex>
      <Flex
        borderBottom={'1px solid rgba(0,0,0,0.1)'}
        flexDirection={'column'}
        px={'3rem'}
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={toReportsList}>Exposures</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Report</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          py={'1rem'}
        >
          <Heading
            as="h1"
            size="lg"
          >
            Exposures
          </Heading>
          <Spacer />
          {reportStatus === ExposuresReportStatus.SCALED_ANALYSIS && (
            <div>
              <ButtonGroup>
                {reportOutdatedAlertStatus.alertStatus !== 'warning' && (
                  <Button
                    onClick={handleSave}
                    id="Exposures-Button-Save"
                  >
                    Save
                  </Button>
                )}
                {reportId && exposuresV2Reprojection && reportOutdatedAlertStatus.alertStatus !== 'warning' && (
                  <Button
                    onClick={handleOpenAlertDialog}
                    id="Exposures-Button-Request-Projected-Results"
                  >
                    Request Projected Results
                  </Button>
                )}
              </ButtonGroup>
              <ExposuresConfirmOvernightModal
                isOpen={isAlertDialogOpen}
                setIsOpen={setIsAlertDialogOpen}
                targetMarketHasNoPendingReports={targetMarketHasNoPendingReports}
                handleReprojection={handleReprojection}
              ></ExposuresConfirmOvernightModal>
            </div>
          )}
        </Flex>
      </Flex>
    </>
  )
}
