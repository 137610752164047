import React from 'react'

export function SortIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="SortIcon"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
      {...props}
    >
      <path
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
    </svg>
  )
}
