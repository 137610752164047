import React, { PropsWithChildren, MouseEvent } from 'react'
import { css } from '../../utils/css'

interface GridRowProps {
  columnNumber: number
  rowNumber: number
  classNameOverride?: string
  additionalCssClassNames?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}

export function CssGridItem(props: PropsWithChildren<GridRowProps>) {
  let className = 'GridItem'

  if (props.columnNumber === 1) {
    className = 'GridHeaderLeft'
  }

  if (props.rowNumber === 1) {
    className = 'GridHeaderTop'
  }

  if (props.classNameOverride || props.classNameOverride?.length) {
    className = props.classNameOverride
  }

  return (
    <div
      className={css(className, props.additionalCssClassNames)}
      style={{ gridRow: props.rowNumber, gridColumn: props.columnNumber }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}
