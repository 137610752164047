import { ReactElement, useContext } from 'react'
import { Scenario } from '../../backend/scenarios'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { Link } from 'react-router-dom'
import './NavigationMenuItem.scss'
import { css } from '../../utils/css'

type NavigationMenuItemProps = {
  icon: ReactElement
  title: string
  id?: string
  navigateTo: string
  isCurrentPage: boolean
}

const NavigationMenuItem = (props: NavigationMenuItemProps) => {
  const { currentScenario } = useContext(ScenarioContext)

  const redirectTo = props.navigateTo.replaceAll(':scenarioId', currentScenario?.id!)
  const redirectToWithQueryParams = addOnTeamAndDivisionQueryParamsIfNeeded(redirectTo, currentScenario)

  return (
    <Link
      to={redirectToWithQueryParams}
      className={css('NavigationMenuItem', props.isCurrentPage ? 'NavigationMenuItemSelected' : '')}
      title={props.title}
      id={`Navigation-Menu-Item-${props.title}`}
    >
      <div className="NavigationMenuItemTop">
        <p>{props.title}</p>
      </div>
      <div className="NavigationMenuItemSelectedUnderline" />
    </Link>
  )
}

const addOnTeamAndDivisionQueryParamsIfNeeded = (path: string, scenario: Scenario | undefined) => {
  if (!scenario || !path.includes('scenario-chooser')) {
    return path
  }

  return `${path}?team=${encodeURIComponent(scenario.team)}&division=${encodeURIComponent(
    scenario.division,
  )}&year=${encodeURIComponent(scenario.year)}`
}

export default NavigationMenuItem
