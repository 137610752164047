import React from 'react'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import './ScenarioStatusLine.scss'
import { SCENARIO_STATUS_COLORS } from './ScenarioStatusColours'

export function ScenarioStatusLine(props: { status: ScenarioStatus }) {
  const statusBarStyle = {
    backgroundColor: SCENARIO_STATUS_COLORS[props.status],
  }

  return (
    <div
      className="StatusBar"
      style={statusBarStyle}
    />
  )
}
