import React from 'react'
import { Card } from '../../components/card/Card'
import CompareIcon from '../../components/icons/CompareIcon'
import './StartComparisonIcon.scss'

interface StartComparisonIconProps {
  onClick: () => void
}

function StartComparisonIcon(props: StartComparisonIconProps) {
  return (
    <div
      className="StartComparisonIcon"
      key={'start-comparison'}
      onClick={props.onClick}
    >
      <Card>
        <CompareIcon />
      </Card>
    </div>
  )
}

export { StartComparisonIcon }
