import { Box, Flex, Heading, Spacer } from '@chakra-ui/react'
import { Dispatch, useContext, useEffect, useReducer, useState } from 'react'
import { getExposureReportsFromScenarioId, importCalculation } from '../../backend/exposures'
import { CreateNewButton } from '../exposures/components/CreateNewButton'
import {
  createErrorHandler,
  createExposuresAlertInitialState,
  ExposuresAlert,
  exposuresAlertReducer,
} from '../exposures/components/ExposuresAlert'
import { NoReportsMessage } from '../exposures/components/NoReportsMessage'
import { Action } from '../exposures/ExposuresState'
import { createInitialExposuresListState, reducer } from './ExposuresListState'
import { ExposuresListTable } from './ExposuresListTable'
import { ActualsContext } from '../../providers/ActualDataChoiceProvider'

type ExposuresListProps = {
  scenarioId: string
  changePage: Dispatch<Action>
  canCreateNew: boolean
  id?: string
}

export const ExposuresList = (props: ExposuresListProps) => {
  const { scenarioId, changePage, canCreateNew } = props

  const [exposuresListState, exposuresListDispatch] = useReducer(reducer, createInitialExposuresListState())
  const [alertState, alertDispatch] = useReducer(exposuresAlertReducer, createExposuresAlertInitialState())

  const errorHandler = createErrorHandler(alertDispatch)
  const { actualsQuery } = useContext(ActualsContext)
  const [actual] = actualsQuery
  const [calculationImported, setCalculationImported] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const reports = (await getExposureReportsFromScenarioId(scenarioId)).sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        )
        reports
          .filter(({ status }) => status === 'ANALYSIS_IN_PROGRESS')
          .forEach(async ({ id }) => {
            const res = await importCalculation(id)
            if (res) {
              setCalculationImported(!calculationImported)
            }
          })
        exposuresListDispatch({ type: 'setReports', payload: { reports } })
      } catch (err: unknown) {
        errorHandler('Unable to load reports', err)
      }
    })()
  }, [actual, calculationImported])

  const onCloseAlert = () => {
    alertDispatch({ type: 'closeAlert' })
  }

  const toReportPage = (reportId: string) => {
    changePage({ type: 'toReport', payload: { reportId } })
  }

  const createNew = () => {
    changePage({ type: 'toNew' })
  }

  if (exposuresListState.reports.length === 0) {
    return (
      <Box>
        <Flex
          px={'1rem'}
          pt={'1rem'}
          mb={'1rem'}
        >
          <ExposuresAlert
            alertStatus={alertState.alertStatus}
            message={alertState.message}
            isActive={alertState.isActive}
            onClose={onCloseAlert}
          />
        </Flex>
        <Flex
          px={'3rem'}
          py={'1.5rem'}
          borderBottom={'1px solid rgba(0,0,0,0.1)'}
        >
          <Heading>Exposures Reports</Heading>
          <Spacer />
          <CreateNewButton
            createNew={createNew}
            canCreateNew={canCreateNew}
            id="Exposures-Button-Create"
          />
        </Flex>

        <Flex
          px={'3rem'}
          pt={'1rem'}
          flexDirection={'column'}
          maxWidth={'75%'}
        >
          <NoReportsMessage
            createNew={createNew}
            canCreateNew={canCreateNew}
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box>
      <Flex
        px={'1rem'}
        pt={'1rem'}
        mb={'1rem'}
      >
        <ExposuresAlert
          alertStatus={alertState.alertStatus}
          message={alertState.message}
          isActive={alertState.isActive}
          onClose={onCloseAlert}
        />
      </Flex>
      <Flex
        px={'3rem'}
        py={'1.5rem'}
        borderBottom={'1px solid rgba(0,0,0,0.1)'}
      >
        <Heading>Exposures Reports</Heading>
        <Spacer />
        <CreateNewButton
          createNew={createNew}
          canCreateNew={canCreateNew}
          id="Exposures-Button-Create"
        />
      </Flex>

      <Flex
        px={'3rem'}
        pt={'1rem'}
        flexDirection={'column'}
        maxWidth={'75%'}
      >
        <Heading
          pb={'1rem'}
          size={'lg'}
        >
          All Reports
        </Heading>
        <ExposuresListTable
          reports={exposuresListState.reports}
          toReportPage={toReportPage}
          exposuresListDispatch={exposuresListDispatch}
        />
      </Flex>
    </Box>
  )
}
