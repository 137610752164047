import { PlacementType } from '../../backend/placement-type'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { toOption } from '../../utils/lists'

type PlacementTypeFieldProps = {
  placementTypes: PlacementType[]
  id?: string
  currentPlacementTypeId: string
  setCurrentPlacementTypeId: (placementTypeId: string) => void
  error?: string
}

export function PlacementTypeField(props: PlacementTypeFieldProps) {
  const { placementTypes, currentPlacementTypeId, setCurrentPlacementTypeId, error } = props

  return (
    <MultiSelectDropdown
      error={error ? 'Select placement type' : ''}
      title="Placement Type"
      id={props.id}
      onSelect={(option) => {
        if (option.length <= 0) {
          return
        }

        const placementType = placementTypes.find((c) => c.name === option[0].value)
        if (!placementType) {
          return
        }

        setCurrentPlacementTypeId(placementType.id)
      }}
      options={placementTypes.map((placementType) => {
        return toOption(placementType.name)
      })}
      selected={(() => {
        const placementType = placementTypes.find((c) => c.id === currentPlacementTypeId)
        if (!placementType) {
          return []
        }

        return [toOption(placementType.name)]
      })()}
    />
  )
}
