import React, { useContext } from 'react'
import { ResponsivePieCanvas } from '@nivo/pie'
import { GraphDataConsumer } from './GraphDataProvider'
import { TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import { formatForDisplay, sumListAsIntegers } from '../../utils/numbers'
import Big from 'big.js'
import { GraphStyleContext } from './GraphStyleProvider'
import { GraphContainerContext } from '../graph-container/GraphContainer'
import { GRAPH_COLOURS } from '../graphs/Graphs'

interface CenteredMetricsProps {
  total: Big
}

function CenteredMetric(props: CenteredMetricsProps) {
  return <>{'$' + formatForDisplay(props.total)}</>
}

interface PieGraphProps {
  numericFieldToUse?: string
}

export function AdjustedGwpBreakdownPieGraph(props: PieGraphProps) {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)
  const { graphId } = useContext(GraphContainerContext)
  const isExpanded = currentlyExpandedGraph === graphId
  return (
    <GraphDataConsumer>
      {({ summarisedData, fieldNameToGroupBy }) => (
        <>
          <ResponsivePieCanvas
            data={summarisedData}
            innerRadius={0.6}
            padAngle={0.3}
            cornerRadius={3}
            margin={isExpanded ? { top: 30, bottom: 30 } : undefined}
            id={fieldNameToGroupBy}
            value={props.numericFieldToUse || TOTAL_ADJUSTED_GWP_COLUMN}
            enableArcLinkLabels={isExpanded}
            enableArcLabels={isExpanded}
            valueFormat={(item) => '$' + formatForDisplay(item)}
            colors={GRAPH_COLOURS}
          />
          <div
            style={{
              position: 'absolute',
              width: '50%',
              height: '50%',
              top: isExpanded ? 20 : 0,
              bottom: isExpanded ? 20 : 0,
              left: 0,
              textAlign: 'center',
              lineHeight: '100%',
              transform: 'translate(50%, 90%)',
              fontSize: '36px',
            }}
          >
            <CenteredMetric
              total={sumListAsIntegers(summarisedData, props.numericFieldToUse || TOTAL_ADJUSTED_GWP_COLUMN)}
            />
          </div>
        </>
      )}
    </GraphDataConsumer>
  )
}
