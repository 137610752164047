import React, { Fragment, useContext, useMemo } from 'react'
import { ResultbyDistributionbyLossType } from '../../backend/parameterisation'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { GridContainer } from '../css-grid/GridContainer'
import { fetchFormatter } from '../../pages/parameterisation/ParameterisationRows'
import { CssGridItem } from '../css-grid/CssGridRows'
import { fromDistribution, LossToggle } from '../../providers/parameterisation/ParameterisationDataProvider'
import Divider from '../divider/LineDivider'
import { ParameterisationInputTableColumnContent } from '../parameterisation-input-table/ParameterisationInputTable'

export type ParameterizationRow = Record<string, ResultbyDistributionbyLossType>

export const NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW = 2

type ValidComparisonThing = 'Attritional' | 'Single-risk AEP' | 'Single-risk OEP' | 'Combined' | 'Single-risk Frequency'

function TeamParameterisationFieldComparisonTable(props: {
  fieldNameThatWeAreComparing: ValidComparisonThing
  lossToggleOverride?: LossToggle | 'Frequency'
}): JSX.Element {
  const { underlyingMarketParamData, lossToggle } = useContext(TeamParameterisationDataContext)

  const lossToggleToUse = props.lossToggleOverride || lossToggle

  const allUniqueColumns = underlyingMarketParamData
    .map((item) => item?.input?.inputs?.marketNameSelected)
    .filter((item) => Boolean(item))

  const allPossibleStats = underlyingMarketParamData
    ?.flatMap((item) => item?.result?.resultbyDistributionbyLossType || [])
    .filter((item) => item.display)
    .map((item) => item?.stat)

  const allUniqueStats = Array.from(new Set(allPossibleStats))
  const getValueForStatFromMarketResult = useMemo(
    () => (marketName: string, columnName: string) => {
      const currentMarketResults = underlyingMarketParamData?.find(
        (result) => result?.input?.inputs?.marketNameSelected === marketName,
      )
      const result = fromDistribution(currentMarketResults, lossToggleToUse).filter(
        (item) => item.stat === columnName && item.lossType === lossToggleToUse,
      )

      const resultingRow = result?.find((item) => item.distribution === props.fieldNameThatWeAreComparing)
      const formatter = fetchFormatter(resultingRow?.lossType)

      return formatter(resultingRow?.value || '0')
    },
    [lossToggleToUse, underlyingMarketParamData, props.fieldNameThatWeAreComparing],
  )

  return (
    <div className="ParameterisationTableContainer">
      <GridContainer>
        <CssGridItem
          columnNumber={1}
          rowNumber={1}
        >
          Stat
        </CssGridItem>
        {allUniqueColumns.map((columnName, columnIndex) => {
          return (
            <CssGridItem
              columnNumber={columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              rowNumber={1}
              key={`column-header-${columnName}-${columnIndex}`}
            >
              {columnName}
            </CssGridItem>
          )
        })}
        {allUniqueStats.map((statName, rowIndex) => (
          <Fragment key={`fragment-${rowIndex}`}>
            <CssGridItem
              columnNumber={1}
              rowNumber={rowIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              key={`row-header-${statName}-${0}`}
            >
              {statName}
            </CssGridItem>

            {allUniqueColumns.map((marketName, columnIndex) => (
              <CssGridItem
                columnNumber={columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
                rowNumber={rowIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
                key={`row-header-${statName}-${columnIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}`}
              >
                {marketName && getValueForStatFromMarketResult(marketName, statName)}
              </CssGridItem>
            ))}
          </Fragment>
        ))}
      </GridContainer>
      <Divider />
      <div className="ParamInputTable">
        <GridContainer>
          <CssGridItem
            columnNumber={1}
            rowNumber={1}
          >
            Input Value
          </CssGridItem>
          <CssGridItem
            columnNumber={1}
            rowNumber={2}
          >
            Percentile
          </CssGridItem>
          <CssGridItem
            columnNumber={1}
            rowNumber={3}
          >
            RP
          </CssGridItem>
          {allUniqueColumns.map((columnName, currentTypeIndex) => (
            <ParameterisationInputTableColumnContent
              key={columnName}
              columnName={props.fieldNameThatWeAreComparing}
              columnNumber={currentTypeIndex + NUMBER_TO_INCREASE_BY_TO_HANDLE_INDEX_AND_HEADER_ROW}
              parameterisationData={underlyingMarketParamData?.find(
                (result) => result?.input?.inputs?.marketNameSelected === columnName,
              )}
              lossToggle={lossToggleToUse}
            />
          ))}
        </GridContainer>
      </div>
    </div>
  )
}

export default TeamParameterisationFieldComparisonTable
