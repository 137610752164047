import { useContext, useMemo } from 'react'
import {
  ACCESS_OR_CAPABILITY,
  ACTUARIAL_ABE_GGLR,
  ACTUARIAL_INFLATION,
  ADDRESSABLE_MARKET_GWP,
  calculateGGLR,
  calculateMarketGrarc,
  calculateMarketNRARC,
  CONFIDENCE_IN_MARKET_SIZE,
  CONVEX_ACQUISITION_RATIO,
  CONVEX_ESTIMATED_SHARE_OF_PML,
  GRAPH_DATA,
  IN_APPETITE,
  MARKET_GRARC,
  MARKET_NRARC,
  MarketKeyPerformanceIndicators,
  PEAK_CONVEX_GWP,
  PEAK_CONVEX_MARKET_SHARE,
  PML_SCENARIO_1_IN_100,
  PREVIOUS_YEAR_ACQUISITION_RATIO,
  PREVIOUS_YEAR_GGLR,
  PREVIOUS_YEAR_GNLR,
  RATE_ENVIRONMENT,
  requiresAdjustment,
  SEGMENTS,
  TOTAL_MARKET_GWP,
  UW_ABE_GGLR,
  UW_CONVEX_ACQUISITION_RATIO,
  UW_INFLATION,
  UW_MARKET_GRARC,
} from '../calculate-kpis'
import { MarketDataContextV2 } from '../../providers/MarketDataProviderV2'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { useMarketSegments } from './useMarketSegments'

export const useCalculateMarketKpis = (): MarketKeyPerformanceIndicators | undefined => {
  const { marketData, defaultMarketData } = useContext(MarketDataContextV2)
  const { grarcKpi } = useContext(FullScenarioDataContext)
  const { yearQuery } = useContext(AppQueryContext)
  const [yearFromQuery] = yearQuery

  const calculatedSegments = useMarketSegments()

  return useMemo(() => {
    if (!yearFromQuery) {
      console.warn(
        `MarketV2Content - marketKpis - error: Cannot calculate value for marketKpis - No value for year set`,
      )
      return
    }

    if (!marketData && !defaultMarketData) {
      console.warn(
        'calculate-kpis - calculateMarketDataKpis - marketData and defaultMarketData are both undefined. Cannot calculate market KPIs',
      )
      return
    }

    const calculatedTotalMarketGwp = Number(marketData?.totalMarketGwp ?? defaultMarketData?.totalMarketGwp)
    const calculatedPmlScenario1In100 = Number(marketData?.pmlScenario1In100 ?? defaultMarketData?.pmlScenario1In100)
    const calculatedConfidenceInMarketSize = Number(
      marketData?.confidenceInMarketSize ?? defaultMarketData?.confidenceInMarketSize,
    )
    const calculatedAccessOrCapability = Number(marketData?.accessOrCapability ?? defaultMarketData?.accessOrCapability)
    const calculatedInAppetite = Number(marketData?.inAppetite ?? defaultMarketData?.inAppetite)
    const calculatedAddressableMarketGwp = Number(
      marketData?.addressableMarketGwp ?? defaultMarketData?.addressableMarketGwp,
    )
    const calculatedRateEnvironment = marketData?.rateEnvironment ?? String(defaultMarketData?.rateEnvironment)
    const calculatedPeakConvexGwp = Number(marketData?.peakConvexGwp ?? defaultMarketData?.peakConvexGwp)
    const calculatedPeakConvexMarketShare = Number(
      marketData?.peakConvexMarketShare ?? defaultMarketData?.peakConvexMarketShare,
    )
    const calculatedConvexEstimatedShareOfPml = Number(
      marketData?.convexEstimatedShareOfPml ?? defaultMarketData?.convexEstimatedShareOfPml,
    )
    const calculatedPreviousYearGnlr =
      marketData?.previousYearGnlr || marketData?.previousYearGnlr === '0'
        ? Number(marketData?.previousYearGnlr)
        : Number(defaultMarketData?.previousYearGnlr) ?? 0

    const calculatedPreviousYearAcquisitionRatio =
      marketData?.previousYearConvexAcquisitionRatio || marketData?.previousYearConvexAcquisitionRatio === '0'
        ? Number(marketData.previousYearConvexAcquisitionRatio)
        : Number(defaultMarketData?.previousYearConvexAcquisitionRatio) ?? 0

    const calculatedActuarialInflation = Number(
      marketData?.inflation || marketData?.inflation === '0' ? marketData.inflation : defaultMarketData?.inflation ?? 0,
    )
    const calculatedMarketGrarc = calculateMarketGrarc(grarcKpi, marketData, defaultMarketData)
    const calculatedPreviousYearGglr = Number(
      marketData?.previousYearGglr || marketData?.previousYearGglr === '0'
        ? marketData.previousYearGglr
        : calculateGGLR(
            calculatedPreviousYearGnlr,
            calculatedPreviousYearAcquisitionRatio,
            calculatedActuarialInflation,
            calculatedMarketGrarc,
          ),
    )
    const calculatedConvexAcquisitionRatio =
      marketData?.convexAcquisitionRatio || marketData?.convexAcquisitionRatio === '0'
        ? Number(marketData.convexAcquisitionRatio)
        : Number(defaultMarketData?.convexAcquisitionRatio) ?? 0
    // Additional inflation & Grarc values should be included in the calculation if you're looking at next year.
    const calculatedMarketNrarc = Number(
      marketData?.marketNrarc || marketData?.marketNrarc === '0'
        ? marketData.marketNrarc
        : calculateMarketNRARC(
            calculatedMarketGrarc,
            calculatedConvexAcquisitionRatio,
            calculatedPreviousYearAcquisitionRatio,
          ),
    )
    const shouldAdjustAbeGglr = requiresAdjustment(yearFromQuery)
    const calculatedActuarialAbeGglr = Number(
      marketData?.AbeGglr || marketData?.AbeGglr === '0'
        ? marketData?.AbeGglr
        : calculateGGLR(
            calculatedPreviousYearGnlr,
            defaultMarketData?.convexAcquisitionRatio,
            shouldAdjustAbeGglr ? calculatedActuarialInflation : undefined,
            shouldAdjustAbeGglr ? calculatedMarketGrarc : undefined,
          ),
    )
    const calculatedUwConvexAcquisitionRatio = Number(
      marketData?.uwConvexAcquisitionRatio ?? defaultMarketData?.uwConvexAcquisitionRatio,
    )
    const calculatedUwMarketGrarc = Number(marketData?.uwMarketGrarc ?? defaultMarketData?.uwMarketGrarc)
    const calculatedUwInflation = Number(marketData?.uwInflation ?? defaultMarketData?.uwInflation)
    const calculatedUwAbeGglr = Number(marketData?.uwAbeGglr ?? defaultMarketData?.uwAbeGglr)
    const calculatedGraphData = marketData?.graphData ? marketData.graphData : defaultMarketData?.graphData ?? []

    return {
      [TOTAL_MARKET_GWP]: calculatedTotalMarketGwp,
      [PML_SCENARIO_1_IN_100]: calculatedPmlScenario1In100,
      [CONFIDENCE_IN_MARKET_SIZE]: calculatedConfidenceInMarketSize,
      [ACCESS_OR_CAPABILITY]: calculatedAccessOrCapability,
      [IN_APPETITE]: calculatedInAppetite,
      [ADDRESSABLE_MARKET_GWP]: calculatedAddressableMarketGwp,
      [RATE_ENVIRONMENT]: calculatedRateEnvironment,
      [PEAK_CONVEX_GWP]: calculatedPeakConvexGwp,
      [PEAK_CONVEX_MARKET_SHARE]: calculatedPeakConvexMarketShare,
      [CONVEX_ESTIMATED_SHARE_OF_PML]: calculatedConvexEstimatedShareOfPml,
      [PREVIOUS_YEAR_GNLR]: calculatedPreviousYearGnlr,
      [PREVIOUS_YEAR_ACQUISITION_RATIO]: calculatedPreviousYearAcquisitionRatio,
      [ACTUARIAL_INFLATION]: calculatedActuarialInflation,
      [MARKET_GRARC]: calculatedMarketGrarc,
      [PREVIOUS_YEAR_GGLR]: calculatedPreviousYearGglr,
      [CONVEX_ACQUISITION_RATIO]: calculatedConvexAcquisitionRatio,
      [ACTUARIAL_ABE_GGLR]: calculatedActuarialAbeGglr,
      [UW_CONVEX_ACQUISITION_RATIO]: calculatedUwConvexAcquisitionRatio,
      [MARKET_NRARC]: calculatedMarketNrarc,
      [UW_MARKET_GRARC]: calculatedUwMarketGrarc,
      [UW_INFLATION]: calculatedUwInflation,
      [UW_ABE_GGLR]: calculatedUwAbeGglr,
      [GRAPH_DATA]: calculatedGraphData,
      [SEGMENTS]: calculatedSegments,
    }
  }, [marketData, defaultMarketData, yearFromQuery, grarcKpi, calculatedSegments])
}
