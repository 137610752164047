import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { fetchRules, Rule } from '../../backend/outward-rules'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { OutwardScenarioContext } from './OutwardScenarioProvider'

const OutwardScenarioRuleContext = createContext<OutwardScenarioRuleContextContainer>({
  rules: undefined,
  triggerReloadSavedRules: () => void 0,
  changeRules: () => void 0,
})

export interface OutwardScenarioRuleContextContainer {
  rules: Rule[] | undefined
  triggerReloadSavedRules: () => void
  changeRules: (rules: Rule[]) => void
}

const OutwardScenarioRuleProvider = (props: PropsWithChildren) => {
  const [rules, updatedRulesState] = useState<Rule[] | undefined>(undefined)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)

  const changeRules = (newRules: Rule[]) => {
    updatedRulesState(newRules)
  }

  const triggerReloadSavedRules = () => {
    if (!currentOutwardScenario) {
      return
    }
    updateIndividualProgressItem('fetchingOutwardScenarioRules', ProgressState.LOADING)
    fetchRules(currentOutwardScenario.id)
      .then(updatedRulesState)
      .then(() => updateIndividualProgressItem('fetchingOutwardScenarioRules', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('fetchingOutwardScenarioRules', ProgressState.ERROR))
  }

  useEffect(triggerReloadSavedRules, [currentOutwardScenario, updateIndividualProgressItem])

  return (
    <OutwardScenarioRuleContext.Provider
      value={{
        rules,
        changeRules,
        triggerReloadSavedRules,
      }}
    >
      {props.children}
    </OutwardScenarioRuleContext.Provider>
  )
}

export { OutwardScenarioRuleContext, OutwardScenarioRuleProvider }
