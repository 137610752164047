import { Tag } from '@chakra-ui/react'

export enum ExposuresReportStatus {
  SCALED_ANALYSIS = 'SCALED_ANALYSIS',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  ANALYSIS_COMPLETE = 'ANALYSIS_COMPLETE',
  ERROR = 'ERROR',
}

type ReportStatusProps = {
  reportStatus: ExposuresReportStatus
}

export const ReportStatus = (props: ReportStatusProps) => {
  const { reportStatus } = props
  switch (reportStatus) {
    case ExposuresReportStatus.ANALYSIS_IN_PROGRESS: {
      return (
        <Tag
          size={'md'}
          borderRadius="full"
          variant="solid"
          backgroundColor={'#FEEBCB'}
          color={'black'}
        >
          Analysis in Progress
        </Tag>
      )
    }
    case ExposuresReportStatus.ANALYSIS_COMPLETE: {
      return (
        <Tag
          size={'md'}
          borderRadius="full"
          variant="solid"
          backgroundColor={'#C6F6D5'}
          color={'black'}
        >
          Analysis Complete
        </Tag>
      )
    }
    case ExposuresReportStatus.ERROR: {
      return (
        <Tag
          size={'md'}
          borderRadius="full"
          variant="solid"
          backgroundColor={'#FED7D7'}
          color={'black'}
        >
          Error Occurred
        </Tag>
      )
    }
    default: {
      return (
        <Tag
          size={'md'}
          borderRadius="full"
          variant="solid"
          backgroundColor={'#E2E8F0'}
          color={'black'}
        >
          Scaled Analysis
        </Tag>
      )
    }
  }
}
