import React, { MouseEventHandler, useContext, useMemo } from 'react'
import { patchStackedScenario } from '../../backend/stacked-scenarios'
import { Card } from '../card/Card'
import ParagraphWithTitle, { Title } from '../paragraph-with-title/ParagraphWithTitle'
import { ScenarioStatusLine } from '../scenario-card/ScenarioStatusLine'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import './StackedOutwardScenarioCard.scss'
import DeleteIcon from '../icons/DeleteIcon'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import EditIcon from '../icons/EditIcon'
import TextField from '../text-field/WFTextField'
import { onInputUpdateState } from '../../utils/onChange'
import { css } from '../../utils/css'
import { RouteComponentProps, withRouter } from 'react-router'
import Tag, { TagContainer } from '../tag/Tag'
import { SCENARIO_STATUS_COLORS } from '../scenario-card/ScenarioStatusColours'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { displayStatus, scenarioShouldBeColoured } from '../scenario-card/ScenarioCard'
import { StackedScenarioEditingContext } from '../stacked-scenarios-list/StackedScenarioEditingProvider'
import Button from '../button/Button'
import NotesIcon from '../icons/NotesIcon'
import StackedIcon from '../icons/StackedIcon'
import { deleteStackedOutwardScenario, StackedOutwardScenario } from '../../backend/stacked-outwardScenarios'
import { fetchOutwardScenarioById } from '../../backend/outward-scenarios'
import MarketIcon from '../icons/MarketIcon'
import {
  fetchAssembleAndDownloadTheMarketDataForScenarios,
  fetchAssembleAndDownloadThePerilsDataForScenarios,
} from './OutwardOutputs'

export interface StackedOutwardScenarioCardProps {
  stackedScenario: StackedOutwardScenario
  triggerStackedOutwardScenarioReload: () => void
  ableToEditUnderlyingScenarios?: boolean
}

function StackedOutwardScenarioCard({
  stackedScenario,
  triggerStackedOutwardScenarioReload,
  ableToEditUnderlyingScenarios,
  ...otherProps
}: StackedOutwardScenarioCardProps & RouteComponentProps) {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const {
    currentlyCreatingStackedScenario,
    setCurrentlyCreatingStackedScenario,
    currentlyEditingStackedScenarioId,
    setCurrentlyEditingStackedScenarioId,
    descriptionOfCurrentlyEditedStackedScenario,
    setDescriptionOfCurrentlyEditedStackedScenario,
    nameOfCurrentlyEditedStackedScenario,
    setNameOfCurrentlyEditedStackedScenario,
    setCurrentlyChosenScenarioIds,
  } = useContext(StackedScenarioEditingContext)

  const onDelete: MouseEventHandler = (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('deleteStackedOutwardScenario', ProgressState.LOADING)
    deleteStackedOutwardScenario(stackedScenario.id)
      .then(triggerStackedOutwardScenarioReload)
      .then(() => updateIndividualProgressItem('deleteStackedOutwardScenario', ProgressState.FINISHED))
      .catch(() => updateIndividualProgressItem('deleteStackedOutwardScenario', ProgressState.ERROR))
  }

  const highlightCurrentScenarios = async (isCreatingStackedScenario: boolean) => {
    if (ableToEditUnderlyingScenarios) {
      const allScenarioDataPromises = (
        await Promise.all(stackedScenario.subScenarioIds.map(fetchOutwardScenarioById))
      ).filter((item) => item !== undefined)

      const allScenarioData = allScenarioDataPromises.map((scenario) => [scenario!.layer!, scenario!.id!])

      setCurrentlyCreatingStackedScenario(isCreatingStackedScenario)
      setCurrentlyChosenScenarioIds(Object.fromEntries(allScenarioData))
    }
  }

  const onTriggerPerilsDownload: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('triggerStackedOutwardPerilsDownload', ProgressState.LOADING)
    try {
      await fetchAssembleAndDownloadThePerilsDataForScenarios(stackedScenario.subScenarioIds, stackedScenario.name)

      updateIndividualProgressItem('triggerStackedOutwardPerilsDownload', ProgressState.FINISHED)
    } catch (e) {
      console.error('Error while downloading market data', e)
      updateIndividualProgressItem('triggerStackedOutwardPerilsDownload', ProgressState.ERROR)
    }
  }

  const onTriggerMarketDownload: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('triggerStackedOutwardMarketDownload', ProgressState.LOADING)
    try {
      await fetchAssembleAndDownloadTheMarketDataForScenarios(stackedScenario.subScenarioIds, stackedScenario.name)
      updateIndividualProgressItem('triggerStackedOutwardMarketDownload', ProgressState.FINISHED)
    } catch (e) {
      console.error('Error while downloading market data', e)
      updateIndividualProgressItem('triggerStackedOutwardMarketDownload', ProgressState.ERROR)
    }
  }

  const onStartEditStackedScenario: MouseEventHandler = async (event) => {
    event.stopPropagation()
    updateIndividualProgressItem('startToEditStackedOutwardScenario', ProgressState.LOADING)

    await highlightCurrentScenarios(true)

    setCurrentlyEditingStackedScenarioId(stackedScenario.id)
    setNameOfCurrentlyEditedStackedScenario(stackedScenario.name)
    setDescriptionOfCurrentlyEditedStackedScenario(stackedScenario.description)

    updateIndividualProgressItem('startToEditStackedOutwardScenario', ProgressState.FINISHED)
  }

  const onNotes: MouseEventHandler = (event) => {
    event.stopPropagation()
    otherProps.history.push(`/stacked-scenarios/${stackedScenario.id}/notes`)
  }

  const stopEditing: MouseEventHandler = async (event) => {
    event.stopPropagation()
    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
  }

  const patchNameAndStopEditing: MouseEventHandler = async (event) => {
    event.stopPropagation()

    updateIndividualProgressItem('patchStackedScenario', ProgressState.LOADING)

    await patchStackedScenario(stackedScenario.id, {
      name: nameOfCurrentlyEditedStackedScenario,
      description: descriptionOfCurrentlyEditedStackedScenario!,
    })

    setCurrentlyEditingStackedScenarioId(undefined)
    setNameOfCurrentlyEditedStackedScenario('')
    setDescriptionOfCurrentlyEditedStackedScenario(undefined)
    triggerStackedOutwardScenarioReload()

    updateIndividualProgressItem('patchStackedScenario', ProgressState.FINISHED)
  }

  const isEditingThisCard = useMemo(
    () => currentlyEditingStackedScenarioId === stackedScenario.id,
    [stackedScenario, currentlyEditingStackedScenarioId],
  )

  const canWriteToCard = stackedScenario?.canWrite || stackedScenario.status !== ScenarioStatus.COMPLETE

  const cardOnClick = () => {
    if (isEditingThisCard) {
      return undefined
    }
    return () => otherProps.history.push(`/outward-stacked-scenarios/${stackedScenario.id}/summary`)
  }
  return (
    <div
      className={css('StackedOutwardScenarioCard')}
      onClick={cardOnClick()}
    >
      <Card className={scenarioShouldBeColoured(stackedScenario.name) ? 'ActivityOneColoured' : ''}>
        <ScenarioStatusLine status={stackedScenario.status} />
        <div className="Content">
          <div className="CardHeader">
            {!isEditingThisCard && <h4 className="StackedScenarioCardTitle">{stackedScenario.name}</h4>}
            {!isEditingThisCard && (
              <div className="CardOptions">
                <div className="LeftCardOptions">
                  <div title="Stacked Scenario">
                    <StackedIcon className="StackedButton" />
                  </div>
                </div>
                <div className="RightCardOptions">
                  <div title="Download Applicable Perils">
                    <NotesIcon
                      className="NotesButton"
                      onClick={onTriggerPerilsDownload}
                    />
                  </div>
                  <div title="Download Applicable Markets">
                    <MarketIcon
                      className="DownloadButton"
                      onClick={onTriggerMarketDownload}
                    />
                  </div>
                  {canWriteToCard && (
                    <div title="Edit">
                      <EditIcon
                        className="EditButton"
                        onClick={onStartEditStackedScenario}
                      />
                    </div>
                  )}
                  <div title="Notes">
                    <NotesIcon
                      className="NotesButton"
                      onClick={onNotes}
                    />
                  </div>
                  {canWriteToCard && (
                    <div title="Delete">
                      <DeleteIcon
                        className="DeleteButton"
                        onClick={onDelete}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {isEditingThisCard && (
            <TextField
              className="Name"
              placeholder="Name"
              title="Name"
              value={nameOfCurrentlyEditedStackedScenario}
              onChange={onInputUpdateState(setNameOfCurrentlyEditedStackedScenario)}
            />
          )}
          <div className="Options">
            <div className="LeftOptions">
              <ParagraphWithTitle
                title="Status"
                content={displayStatus[stackedScenario.status || ScenarioStatus.IN_PROGRESS]}
                className="Status"
              />
              <div className="ScenarioStatus">
                <Title title="Sub-scenario Status" />
                <TagContainer>
                  {Object.entries(stackedScenario.subStatuses)
                    .filter(
                      ([statusIdentifier, numberInStatus]) =>
                        statusIdentifier !== ScenarioStatus.DELETED || numberInStatus > 0,
                    )
                    .map(([statusIdentifier, numberInStatus]) => (
                      <Tag
                        title={numberInStatus.toString()}
                        textColor={
                          (statusIdentifier as ScenarioStatus) === ScenarioStatus.IN_REVIEW ? '#1A1F36' : 'white'
                        }
                        backgroundColor={SCENARIO_STATUS_COLORS[statusIdentifier as ScenarioStatus]}
                        key={`${stackedScenario.id}-${statusIdentifier}`}
                        tooltip={formatEnumForDisplay(statusIdentifier) + ' - ' + numberInStatus}
                      />
                    ))}
                </TagContainer>
              </div>
            </div>
            <div className="RightOptions">
              <ParagraphWithTitle
                title="Updated"
                content={new Date(stackedScenario.updatedAt).toLocaleDateString('en-GB')}
                className="Updated"
              />
            </div>
          </div>
          {stackedScenario.description && (
            <ParagraphWithTitle
              title="Summary"
              content={stackedScenario.description}
              className="Summary"
            />
          )}
          {!currentlyCreatingStackedScenario && isEditingThisCard && (
            <div className="ButtonContainer">
              <Button
                title="Cancel"
                secondary
                onClick={stopEditing}
              />
              <Button
                title={'Save'}
                onClick={patchNameAndStopEditing}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default withRouter(StackedOutwardScenarioCard)
