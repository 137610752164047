import { Alert as CAlert, AlertIcon, Spacer } from '@chakra-ui/react'

type AlertProps = {
  message: string
}

export const Alert = (props: AlertProps) => {
  const { message } = props

  return (
    <CAlert
      status="error"
      borderRadius={'md'}
    >
      <AlertIcon />
      {message}
      <Spacer />
    </CAlert>
  )
}
