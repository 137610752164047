import { createContext } from 'react'

type Props = {
  entityId: string | undefined
  setEntityId: (newValue: string | undefined) => void
  market: string | undefined
  setMarket: (newValue: string | undefined) => void
  division: string | undefined
  setDivision: (newValue: string | undefined) => void
}

export const AddingTagModalContext = createContext<Props>({
  entityId: undefined,
  setEntityId: () => void 0,
  market: undefined,
  setMarket: () => void 0,
  division: undefined,
  setDivision: () => void 0,
})
