import { Dispatch } from 'react'
import { createReport, getNewReportMetaData, getNewTableState } from '../../backend/exposures'
import { fetchExceedanceProbabilityGraph, fetchMarketShareMatrixGraph } from '../../backend/exposures-V2'
import { ExposureReport, ReportPayload } from './ExposureReport'
import { Action, State } from './ExposuresState'
import { RawEntry } from 'src/backend/rawEntry'

type NewExposuresReportProps = {
  exposuresDispatch: Dispatch<Action>
  exposuresState: State
  scenarioId: string
  fullDataForScenario: RawEntry[]
}

export function NewExposuresReport(props: NewExposuresReportProps) {
  const { exposuresDispatch, exposuresState, scenarioId, fullDataForScenario } = props

  return (
    <ExposureReport
      changePage={exposuresDispatch}
      getTableState={() => {
        return getNewTableState(scenarioId, {
          exposureGrowth: exposuresState.exposuresCalculationData,
          market: exposuresState.market,
        })
      }}
      saveReport={(reportId: string, reportPayload: ReportPayload<'new'>) => {
        if (reportId !== '') {
          exposuresDispatch({
            type: 'toErrorPage',
            payload: { message: `Expected an export reportId but got ${reportId}` },
          })
        }

        return createReport({ ...reportPayload, portfolioData: fullDataForScenario })
      }}
      getReportMetaData={() => getNewReportMetaData()}
      scenarioId={scenarioId}
      reportId={exposuresState.reportId}
      tmv={exposuresState.market}
      ylt_dvid={exposuresState.ylt_dvid}
      asofdate={exposuresState.asofdate}
      getExceedanceGraphData={(perils) => {
        if (!perils) {
          return Promise.resolve([[], []])
        }

        return fetchExceedanceProbabilityGraph(exposuresState.market, exposuresState.exposuresCalculationData, perils)
      }}
      getMarketShareMatrixGraphData={(perils) => {
        if (!perils) {
          return Promise.resolve({})
        }
        return fetchMarketShareMatrixGraph(exposuresState.market, perils, exposuresState.exposuresCalculationData)
      }}
    />
  )
}
