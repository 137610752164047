import React from 'react'
import { css } from '../../utils/css'
import './ParagraphWithTitle.scss'

export type ParagraphWithTitleProps = React.PropsWithChildren<{
  title: string
  content?: string | undefined | null
  className?: string
  id?: string
}>

export function ParagraphWithTitle(props: ParagraphWithTitleProps) {
  return (
    <div className={css('ParagraphWithTitle', props.className)}>
      {props.title && <Title title={props.title} />}
      <p
        className="Paragraph"
        id={props.id}
      >
        {props.content}
      </p>
    </div>
  )
}

export function Title(props: { title: string }) {
  return <p className="ParagraphWithTitleText">{props.title}</p>
}

export default ParagraphWithTitle
