import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Big from 'big.js'

import './TableOfKpis.scss'

import { css } from '../../../utils/css'
import {
  FullScenarioDataContext,
  splitIntoRenewalAndNew,
} from '../../../providers/FullScenarioData/FullScenarioDataProvider'
import { IndividualDataItem, numericValueIsDefined, ONE_HUNDRED } from '../../../utils/numbers'
import { CssGridItem } from '../../css-grid/CssGridRows'
import { GridContainer } from '../../css-grid/GridContainer'
import { getTableOfKpiColumns, TableOfKpiColumnInfo } from './TableOfKpiRow'
import { groupListOfObjsBy, sortListBy, toOption } from '../../../utils/lists'
import { calculateKPIs, calculateWeightedAverageKpis } from '../../../backend/calculate-kpis'
import { MarketDataContextV2 } from '../../../providers/MarketDataProviderV2'
import { MultiSelectDropdown } from '../../multi-select-dropdown/MultiSelectDropdown'
import { SortIcon } from './SortIcon'
import { ScenarioContext } from '../../../providers/ScenarioProvider'
import { StackedScenarioContext } from '../../../providers/StackedScenarioProvider'
import {
  AggregatedMarketDataContextV2,
  getAFieldForEveryLeafNode,
} from '../../../providers/AggregatedMarketDataProviderV2'

type RecordOfThings = Record<string, Array<{ label: string; value: number }>>

type TableProps = PropsWithChildren<{
  columnsThatArePossible: string[]
  startingColumn: string
}>

export const TableOfKpis = (props: TableProps) => {
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)
  const { isStackedScenario } = useContext(StackedScenarioContext)

  const [rawDataAvailableForSorting, setRawDataAvailableForSorting] = useState<RecordOfThings>({})
  const [currentColumnThatIsSplitBy, setCurrentColumnThatIsSplitBy] = useState(props.startingColumn)
  const [currentColumnToSortBy, setCurrentColumnToSortBy] = useState('')
  const [sortDirection, setSortDirection] = useState(false)

  useEffect(() => {
    setCurrentColumnThatIsSplitBy(props.startingColumn)
  }, [props.startingColumn])

  const dataSplitByField = useMemo(
    () => groupListOfObjsBy(filteredDataToUseInGraph, currentColumnThatIsSplitBy),
    [filteredDataToUseInGraph, currentColumnThatIsSplitBy],
  )

  const sortedOrder = useMemo(() => {
    if (!currentColumnToSortBy) {
      return undefined
    } else {
      const sorted = sortListBy(rawDataAvailableForSorting[currentColumnToSortBy], ['value'])
      if (sortDirection) {
        sorted.reverse()
      }
      return sorted
    }
  }, [sortDirection, rawDataAvailableForSorting, currentColumnToSortBy])

  useEffect(() => {
    setCurrentColumnToSortBy('')
    setRawDataAvailableForSorting({})
  }, [currentColumnThatIsSplitBy])

  const tableOfKpiColumns = getTableOfKpiColumns(isStackedScenario)

  return (
    <GridContainer classNameOverride="TableOfKpisGridContainer">
      <CssGridItem
        columnNumber={1}
        rowNumber={1}
      >
        <MultiSelectDropdown
          onSelect={(options) => setCurrentColumnThatIsSplitBy(options[0].value)}
          selected={[toOption(currentColumnThatIsSplitBy)]}
          className="ColumnFilter"
          placeholder="Choose a field"
          options={props.columnsThatArePossible.map(toOption)}
        />
      </CssGridItem>
      {tableOfKpiColumns.map((currentColumn, index) => (
        <CssGridItem
          key={`column-for-data-${index}`}
          columnNumber={index + 2}
          rowNumber={1}
          onClick={(e) => {
            if (currentColumnToSortBy !== currentColumn.title) {
              setCurrentColumnToSortBy(currentColumn.title)
            } else {
              setSortDirection(!sortDirection)
            }
          }}
        >
          {currentColumn.title}
          <SortIcon />
        </CssGridItem>
      ))}
      {Object.entries(dataSplitByField).map(([currentGroupingLabel, currentGroupingListOfData], index) => (
        <TableRow
          rowNumber={(sortedOrder ? sortedOrder.findIndex((item) => item.label === currentGroupingLabel) : index) + 2}
          title={currentGroupingLabel}
          matchingData={currentGroupingListOfData}
          key={`row-for-${currentGroupingLabel}`}
          rawDataAvailableForSorting={rawDataAvailableForSorting}
          tableOfKpiColumns={tableOfKpiColumns}
        />
      ))}

      <TableRow
        rowNumber={Object.keys(dataSplitByField).length + 2}
        title={'Total'}
        matchingData={filteredDataToUseInGraph}
        key={'total-bottom-row'}
        rawDataAvailableForSorting={{}}
        additionalCssClassNames={['GreenBackground']}
        tableOfKpiColumns={tableOfKpiColumns}
      />
    </GridContainer>
  )
}

type RowProps = {
  rowNumber: number
  additionalCssClassNames?: string[]
  tableOfKpiColumns: TableOfKpiColumnInfo[]
} & CellProps

type CellProps = {
  title: string
  matchingData: IndividualDataItem[]
  rawDataAvailableForSorting: RecordOfThings
}

const TableRow = (props: RowProps) => {
  return (
    <>
      <CssGridItem
        columnNumber={1}
        rowNumber={props.rowNumber}
        additionalCssClassNames={css(props.additionalCssClassNames)}
      >
        {props.title || 'Placeholder'}
      </CssGridItem>
      {props.tableOfKpiColumns.map((currentColumn, index) => (
        <CssGridItem
          columnNumber={index + 2}
          rowNumber={props.rowNumber}
          key={`column-${currentColumn.title}-row-${props.rowNumber}`}
          additionalCssClassNames={css(props.additionalCssClassNames)}
        >
          <RowCellContent
            currentColumn={currentColumn}
            {...props}
          />
        </CssGridItem>
      ))}
    </>
  )
}

const RowCellContent = (props: { currentColumn: TableOfKpiColumnInfo } & CellProps) => {
  const { aggregatedMarketData } = useContext(AggregatedMarketDataContextV2)
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario, isStackedScenario } = useContext(StackedScenarioContext)
  const { marketData } = useContext(MarketDataContextV2)

  const dataSplitIntoRenewalAndNew = useMemo(() => splitIntoRenewalAndNew(props.matchingData), [props.matchingData])
  const renewalKpisForRow = useMemo(
    () => calculateKPIs(dataSplitIntoRenewalAndNew.renewal),
    [dataSplitIntoRenewalAndNew.renewal],
  )
  const newKpisForRow = useMemo(
    () =>
      calculateKPIs([
        ...dataSplitIntoRenewalAndNew.new,
        ...dataSplitIntoRenewalAndNew.newClient,
        ...dataSplitIntoRenewalAndNew.newCustomPortfolio,
      ]),
    [
      dataSplitIntoRenewalAndNew.new,
      dataSplitIntoRenewalAndNew.newClient,
      dataSplitIntoRenewalAndNew.newCustomPortfolio,
    ],
  )
  const fullKpisForRow = useMemo(() => calculateKPIs(props.matchingData), [props.matchingData])
  const fallbackAcqValue = useMemo(() => {
    if (currentScenario && numericValueIsDefined(marketData?.convexAcquisitionRatio)) {
      return {
        [currentScenario.market]: new Big(marketData?.convexAcquisitionRatio! /*TODO yet another lie*/).div(
          ONE_HUNDRED,
        ),
      }
    } else if (currentStackedScenario && aggregatedMarketData) {
      return getAFieldForEveryLeafNode(
        aggregatedMarketData,
        currentStackedScenario.scenarioLevel,
        'convexAcquisitionRatio',
      )
    }
    return {}
  }, [currentScenario, marketData, currentStackedScenario, aggregatedMarketData])
  const fullWeightedAverageKpisForRow = useMemo(
    () => calculateWeightedAverageKpis(props.matchingData, fallbackAcqValue),
    [props.matchingData, fallbackAcqValue],
  )
  const renewalWeightedAverageKpisForRow = useMemo(
    () => calculateWeightedAverageKpis(dataSplitIntoRenewalAndNew.renewal, fallbackAcqValue),
    [dataSplitIntoRenewalAndNew.renewal, fallbackAcqValue],
  )
  const value = useMemo(
    () =>
      props.currentColumn.perRowValueGenerator({
        dataForThisRow: props.matchingData,
        dataSplitIntoRenewalAndNew,
        renewalKpisForRow,
        newKpisForRow,
        fullKpisForRow,
        aggregatedMarketData,
        fullWeightedAverageKpisForRow,
        renewalWeightedAverageKpisForRow,
        title: props.title,
      }),
    [
      dataSplitIntoRenewalAndNew,
      renewalKpisForRow,
      newKpisForRow,
      fullKpisForRow,
      aggregatedMarketData,
      fullWeightedAverageKpisForRow,
      renewalWeightedAverageKpisForRow,
      props.matchingData,
      props.title,
    ],
  )

  const reportBackForSorting: (rawNumericValue: Big | string | number, title: string) => void = useCallback(
    (rawNumericValue, title) => {
      const existingItems = props.rawDataAvailableForSorting[title] || []
      const existingItemsWithOutDuplicates = existingItems.filter((item) => item.label !== props.title)
      props.rawDataAvailableForSorting[title] = [
        ...existingItemsWithOutDuplicates,
        { label: props.title, value: new Big(rawNumericValue || 0)?.toNumber() },
      ]
    },
    [props.rawDataAvailableForSorting, props.title],
  )

  if (isStackedScenario && !aggregatedMarketData) {
    console.warn('TableOfKpis - TableRow - generateContent - Cannot generate column content; no aggregated market data')
    return <></>
  } else {
    reportBackForSorting(value, props.currentColumn.title)
    return <>{props.currentColumn.perRowValueFormatter(value)}</>
  }
}
