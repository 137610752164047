import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { validate as uuidValidate } from 'uuid'
import { fetchStackedScenarioById, StackedScenario } from '../backend/stacked-scenarios'
import { useLocation } from 'react-router-dom'

export const StackedScenarioContext = createContext<StackedScenarioContainer>({
  currentStackedScenario: undefined,
  isStackedScenario: false,
})

export const TAGGED_STACKED_SCENARIO_ID_PREFIX = 'STK'
export interface StackedScenarioContainer {
  currentStackedScenario: StackedScenario | undefined
  isStackedScenario: boolean
}

export function FixedStackedScenarioProvider(props: React.PropsWithChildren<{ stackedScenarioId: string }>) {
  const [currentStackedScenario, setStackedScenarioData] = useState<StackedScenario>()
  const [isStackedScenario, setIsStackedScenario] = useState<boolean>(false)

  useEffect(() => {
    if (props.stackedScenarioId) {
      fetchStackedScenarioById(props.stackedScenarioId).then(setStackedScenarioData)
      setIsStackedScenario(true)
    } else {
      setIsStackedScenario(false)
    }
  }, [props.stackedScenarioId])

  return (
    <StackedScenarioContext.Provider
      value={{
        currentStackedScenario,
        isStackedScenario,
      }}
      children={props.children}
    />
  )
}

export function StackedScenarioProvider(props: PropsWithChildren) {
  const location = useLocation()
  if (!location.pathname.includes('/stacked-scenarios/')) {
    return <>{props.children}</>
  }

  let stackedScenarioIdToUse

  const pathParts = location.pathname.split('/')
  stackedScenarioIdToUse = pathParts.filter((item) => item.includes(TAGGED_STACKED_SCENARIO_ID_PREFIX))[0] // TODO WAY-1619 WAY-1620

  if (!stackedScenarioIdToUse || stackedScenarioIdToUse.length === 0) {
    stackedScenarioIdToUse = pathParts.filter((item) => uuidValidate(item))[0]
  }

  return (
    <FixedStackedScenarioProvider stackedScenarioId={stackedScenarioIdToUse}>
      {props.children}
    </FixedStackedScenarioProvider>
  )
}
