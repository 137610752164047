import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AmplifyAuthProvider'
import { Auth } from 'aws-amplify'
import * as groups from '../utils/groups'

function intialGroup(): IsAdminContainer {
  return { isAdmin: false }
}

export const IsAdminContext = createContext<IsAdminContainer>(intialGroup())

export interface IsAdminContainer {
  isAdmin: boolean
}

type Props = PropsWithChildren<{}>

// This stores the manual groups from cognito
const COGNITO_GROUP_DICT_KEY = 'cognito:groups'
// This stores the SAML groups in ID tokens
const CUSTOM_GROUP_DICT_KEY = 'custom:groups'

type JwtContent = {
  at_hash: string
  aud: string
  auth_time: number
  'cognito:groups': string[]
  'cognito:username': string
  'custom:groups': string
  email: string
  email_verified: boolean
  exp: number
  iat: number
  identities: [
    {
      dateCreated: string
      issuer: string
      primary: string
      providerName: string
      providerType: string
      userId: string
    },
  ]
  iss: string
  sub: string
  token_use: string
}

export function getGroupsFromJwtContent(jwtContent: JwtContent): string[] {
  if (!jwtContent) {
    return []
  }
  const cognitoGroups = jwtContent[COGNITO_GROUP_DICT_KEY]
  const samlGroups = jwtContent[CUSTOM_GROUP_DICT_KEY]

  const cognito = getGroupNamesFromContent(cognitoGroups)
  const saml = getGroupNamesFromContent(samlGroups)

  return [...saml, ...cognito]
}

function getGroupNamesFromContent(content: string | string[]): string[] {
  if (typeof content === 'string') {
    return content
      .replace(/[\[\]']+/g, '') // eslint-disable-line no-useless-escape
      .split(',')
      .map((item) => item.trim())
  } else if (Array.isArray(content)) {
    return content
  } else if (content) {
    console.warn('Unknown claim string being passed in' + content)
    return []
  } else {
    return []
  }
}

export function IsAdminProvider(props: Props) {
  const [group, setGroup] = useState(intialGroup())

  const authStateContainer = useContext(AuthContext)

  useEffect(() => {
    Auth.currentSession()
      .then((item) => item.getIdToken())
      .then((idToken) => {
        const jwtContent = idToken.decodePayload() as JwtContent
        const pluckedGroups = getGroupsFromJwtContent(jwtContent)
        const isAdmin = pluckedGroups.includes(groups.admin)
        setGroup({ isAdmin })
      })
  }, [authStateContainer])

  return <IsAdminContext.Provider value={group}>{props.children}</IsAdminContext.Provider>
}
