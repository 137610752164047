import { Currency } from '../../backend/currency'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { toOption } from '../../utils/lists'

type CurrencyFieldProps = {
  currencies: Currency[]
  currentCurrencyId: string
  id?: string
  setCurrentCurrencyId: (currencyId: string) => void
  error?: string
}

export function CurrencyField(props: CurrencyFieldProps) {
  const { currencies, currentCurrencyId, setCurrentCurrencyId, error } = props

  return (
    <MultiSelectDropdown
      error={error ? 'Select original currency' : ''}
      title="Original Currency"
      id={props.id}
      onSelect={(option) => {
        if (option.length <= 0) {
          return
        }

        const currency = currencies.find((c) => c.displayName === option[0].value)
        if (!currency) {
          return
        }

        setCurrentCurrencyId(currency.id)
      }}
      options={currencies.map((currency) => {
        return toOption(currency.displayName)
      })}
      selected={(() => {
        const currency = currencies.find((c) => c.id === currentCurrencyId)
        if (!currency) {
          return []
        }

        return [toOption(currency.displayName)]
      })()}
    />
  )
}
