import * as React from 'react'

import './Card.scss'

import { css } from '../../utils/css'

type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>

export function Card(props: Props): React.ReactElement {
  const { className, children } = props

  return <div className={css('Card', className)}>{children}</div>
}
