import { CategoryWeightings, PortfolioSplitTypes } from '../backend/new-custom-portfolio-items'

type PickCW<T extends keyof CategoryWeightings> = Pick<CategoryWeightings, T>

export const getEntityCategoryForCreation = (entity: PortfolioSplitTypes) => {
  return Object.getOwnPropertyNames(entity).length > 0
    ? entity
    : {
        UnknownDivision: 100,
      }
}

export const getCategoryWeightings = (categoryWeightings: Required<CategoryWeightings>): CategoryWeightings => {
  const getOptionalCategoryWeighting = (
    key: keyof CategoryWeightings,
    includeAllZeros: boolean = false,
  ): PickCW<'cob3'> | PickCW<'broker'> | PickCW<'interestType'> | undefined => {
    const category = categoryWeightings[key]
    const allValuesAreZero = () => Object.values(category).every((value) => value === 0)
    const shouldIncludeNonEmptyObject = includeAllZeros || !allValuesAreZero()

    return Object.getOwnPropertyNames(category).length > 0 && shouldIncludeNonEmptyObject
      ? { [key]: category }
      : undefined
  }

  return {
    inceptionMonth: categoryWeightings.inceptionMonth,
    placementType: categoryWeightings.placementType,
    entity: getEntityCategoryForCreation(categoryWeightings.entity),
    exposureRegion: categoryWeightings.exposureRegion,
    ...getOptionalCategoryWeighting('cob3', true),
    ...getOptionalCategoryWeighting('broker'),
    ...getOptionalCategoryWeighting('interestType'),
  }
}
