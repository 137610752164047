import { AlertStatus } from '@chakra-ui/react'
import { Reducer } from 'react'

export type NewCustomPortfolioAlertState = {
  alertStatus: AlertStatus
  title: string
  message: string[]
  field: string[]
  isActive: boolean
}

export type NcpError = {
  messages: string[]
  fields: string[]
}

export type NewCustomPortfolioAlertAction =
  | { type: 'setError'; payload: NcpError }
  | { type: 'setWarning'; payload: { title: string; message: string } }
  | { type: 'reset' }
  | { type: 'closeAlert' }

export const initialAlertState: NewCustomPortfolioAlertState = {
  alertStatus: 'loading',
  title: '',
  message: [''],
  field: [''],
  isActive: false,
}

export const createNewCustomPortfolioAlertInitialState = (): NewCustomPortfolioAlertState => initialAlertState

export const newCustomPortfolioAlertReducer: Reducer<NewCustomPortfolioAlertState, NewCustomPortfolioAlertAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'setError': {
      let messages = action.payload.messages
      if (messages.length === 0) {
        messages = ['An unexpected error occurred. Please try again.']
        return {
          alertStatus: 'error',
          title: 'There is a problem',
          message: messages,
          field: [''],
          isActive: true,
        }
      }

      return {
        alertStatus: 'error',
        title: 'There is a problem',
        message: messages,
        field: action.payload.fields,
        isActive: true,
      }
    }
    case 'setWarning': {
      return {
        alertStatus: 'warning',
        title: action.payload.title,
        message: [action.payload.message],
        field: [''],
        isActive: true,
      }
    }
    case 'reset': {
      return initialAlertState
    }
    case 'closeAlert': {
      return {
        ...state,
        isActive: false,
      }
    }
  }
}
