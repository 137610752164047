import { PlacementTypeFields } from '../types'

export const defaultPlacementTypeValues: PlacementTypeFields = {
  Insurance: 0,
  Lineslip: 0,
  'Non-Proportional Treaty': 0,
  'Bulking Lineslip': 0,
  'Retrocession Proportional': 0,
  'Fac RI': 0,
  'Proportional Treaty': 0,
  Declaration: 0,
  Binder: 0,
  'Retrocession Non-Proportional': 0,
}

export const placementTypeFieldNames: (keyof PlacementTypeFields)[] = Object.getOwnPropertyNames(
  defaultPlacementTypeValues,
) as (keyof PlacementTypeFields)[]
