import { Flex, Heading, Spinner } from '@chakra-ui/react'

import { Alert } from '../../../components/Alert/Alert'
import { StateTransition } from '../../../components/StateTransition/StateTransitions'
import { DynamicTable, DynamicTableState } from '../../../components/dynamic-table/DynamicTable'
import { TableState } from '../../../components/dynamic-table/DynamicTableState'

type ExposuresInputProps = {
  dynamicTableState: TableState
  fetch: () => Promise<DynamicTableState<number>>
  dispatchFunction: (data: DynamicTableState<number>) => void
  disabled: boolean
  asOfDate: string
}

export const ExposuresInput = (props: ExposuresInputProps) => {
  const { dynamicTableState, fetch, dispatchFunction, disabled, asOfDate } = props

  return (
    <StateTransition<DynamicTableState<number>>
      state={dynamicTableState.state}
      components={{
        fetching: <Spinner />,
        fulfilled: (
          <Flex
            flexDirection={'column'}
            pr={'3rem'}
            pt={'1rem'}
          >
            <Heading
              as="h2"
              size="md"
            >
              Vendor modelled losses
            </Heading>
            Current inforce modelled losses version: {asOfDate} no ALAE loading
            <DynamicTable
              cells={dynamicTableState.table.cells}
              columns={dynamicTableState.table.columns}
              rows={dynamicTableState.table.rows}
              depth={dynamicTableState.table.depth}
              columnOffset={dynamicTableState.table.columnOffset}
              selector={dynamicTableState.table.selector}
              selectorRowName={dynamicTableState.table.selectorRowName}
              scaler={dynamicTableState.table.scaler}
              disabled={disabled}
            />
          </Flex>
        ),
        rejected: <Alert message={'Unable to get report table state'} />,
      }}
      dataFetchingConfig={{
        fetch,
        dispatch: dispatchFunction,
      }}
    />
  )
}
