import React from 'react'
import { css } from '../../utils/css'
import SectionHeader from '../section-header/SectionHeader'
import './KpiBar.scss'

export type KpiBarProps = React.PropsWithChildren<{}>

export function KpiBar(props: KpiBarProps) {
  return (
    <div className={css('KpiBar')}>
      <SectionHeader title="KPIs" />
      {props.children}
    </div>
  )
}

export default KpiBar
