import React from 'react'
import './ScenarioCards.scss'
import ScenarioCard from './ScenarioCard'
import { Scenario } from '../../backend/scenarios'

interface ScenariosProps {
  scenarios: Scenario[] | undefined
}

function ScenarioCards(props: ScenariosProps) {
  if (props.scenarios === undefined) {
    return <div />
  }

  return (
    <div className="Scenarios">
      <div className="ScenarioList">
        {props.scenarios!.map((scenario, index) => (
          <ScenarioCard
            scenario={scenario}
            triggerScenarioReload={() => {}}
            triggerEditScenario={() => {}}
            canWrite={false}
            isComparing={false}
            comparingIsSelected={false}
            comparingOnToggle={() => {}}
            isCreatingTeamScenario={false}
            creatingIsSelected={false}
            creatingOnToggle={() => {}}
            stopClicking={false}
          />
        ))}
      </div>
    </div>
  )
}

export default ScenarioCards
