import React from 'react'
import './SegmentedControl.scss'
import { css } from '../../utils/css'
import { SegmentedControl } from 'segmented-control-react'

export interface Option {
  name: string
  value: string
}

export type SegmentedControlProps = React.PropsWithChildren<{
  options: Array<Option>
  selectedOption: number
  onChange: (newValue: string) => void
  isDisabled?: boolean
  className?: string
}>

export function SegmentedControlSwitch(props: SegmentedControlProps): JSX.Element {
  return (
    <div
      data-testid="segmented-control"
      className={css('SegmentedControl', props.className, props.isDisabled ? 'disabled' : '')}
    >
      <SegmentedControl
        segments={props.options}
        selected={props.selectedOption}
        variant="base"
        onChangeSegment={(indexOfItem: number) => props.onChange(props.options[indexOfItem].value)}
      />
    </div>
  )
}

export default SegmentedControlSwitch
