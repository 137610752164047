import React, { ReactElement } from 'react'
import './CardSideBarContainer.scss'
export type CardSideBarContainerProps = React.PropsWithChildren<{
  leftColumm: ReactElement
  rightColumn: ReactElement
}>

export function CardSideBarContainer(props: CardSideBarContainerProps) {
  return (
    <div className="CardSideBarContainer">
      {props.children}
      <div className="CardSideBarContainerLeftColumn">{props.leftColumm}</div>
      <div className="CardSideBarContainerRightColumn">{props.rightColumn}</div>
    </div>
  )
}

export default CardSideBarContainer
