export const DEFAULT_MAX_LENGTH_OF_STRING = 175
export const MAX_LENGTH_OF_SHORT_STRINGS = 50

export function trimStringIfTooLong(inputString?: string, maxLengthOfString: number = DEFAULT_MAX_LENGTH_OF_STRING) {
  if ((inputString?.length || 0) > maxLengthOfString) {
    return inputString?.slice(0, maxLengthOfString) + '…'
  }

  return inputString
}

export function trimObjStringValues(data: Record<string, any>): Record<string, any> {
  Object.keys(data).map((k) => (data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]))
  return data
}
