import React from 'react'
import { css } from '../../utils/css'
import './TextArea.scss'
import { ErrorText } from './ErrorText'

export type TextAreaProps = {
  title?: string
  id?: string
  percentage?: boolean
  value?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  placeholder?: string
  type?: string
  warning?: string
  error?: string
  className?: string | string[]
  disabled?: boolean
  rows?: number
}

export function TextArea(props: TextAreaProps) {
  return (
    <div className={css('TextArea', props.className)}>
      {props.title && <p className="Title">{props.title}</p>}
      <textarea
        {...props}
        id={props.id}
        className={css('Input', props.warning ? 'Warning' : '', props.error ? 'Error' : '')}
        disabled={props.disabled}
      />
      {props.warning && <p className="WarningText">{props.warning}</p>}
      {props.error && <ErrorText error={props.error} />}
    </div>
  )
}

export default TextArea
