import React, { SVGProps } from 'react'

const NotesIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M5.5333334,10.4666666 L10.4666666,10.4666666 L10.4666666,11.6999999 L5.5333334,11.6999999 L5.5333334,10.4666666 Z M5.5333334,8 L10.4666666,8 L10.4666666,9.2333333 L5.5333334,9.2333333 L5.5333334,8 Z M9.2333333,1.8333335 L4.3000001,1.8333335 C3.62166678,1.8333335 3.0666668,2.38833349 3.0666668,3.0666668 L3.0666668,12.9333332 C3.0666668,13.6116665 3.61550012,14.1666665 4.29383343,14.1666665 L11.6999999,14.1666665 C12.3783332,14.1666665 12.9333332,13.6116665 12.9333332,12.9333332 L12.9333332,5.5333334 L9.2333333,1.8333335 Z M11.6999999,12.9333332 L4.3000001,12.9333332 L4.3000001,3.0666668 L8.61666665,3.0666668 L8.61666665,6.15000005 L11.6999999,6.15000005 L11.6999999,12.9333332 Z"
          id="Shape"
          fill={props.fill ?? '#4E566B'}
        ></path>
      </g>
    </svg>
  </div>
)

export default NotesIcon
