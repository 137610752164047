import { PropsWithChildren, useContext, useMemo } from 'react'
import { FullScenarioDataContext, splitIntoRenewalAndNew } from '../FullScenarioData/FullScenarioDataProvider'
import { OutwardScenarioRuleContext } from './OutwardScenarioRulesProvider'
import { calculateKPIs, filterData } from '../../backend/calculate-kpis'
import { Rule } from '../../backend/outward-rules'
import { FilterContext, transformIntoFlatStructure } from '../FilterProvider'

interface OutwardsDataProviderProps {}

export function calculateFilteredDataApplyingRulesOneAfterAnother(
  fullDataSet: any[],
  rules: Rule[] | undefined,
): any[] {
  if (!rules || rules.length === 0) {
    return fullDataSet
  }
  return rules.reduce(
    (currentDataSet, nextRuleToApply) => filterData(currentDataSet, nextRuleToApply.appliedFilters),
    fullDataSet,
  )
}

export const OutwardsDataProvider = (props: PropsWithChildren<OutwardsDataProviderProps>) => {
  const { rules } = useContext(OutwardScenarioRuleContext)
  const {
    fullDataForScenario,
    fullRenewalKpis,
    fullNewKpis,
    grarcKpi,
    newGrarcKpi,
    renewalGrarcKpi,
    fullWeightedAverageKpis,
    weightedAverageRenewalKpis,
    reloadData,
    setPreviewRenewal,
    setPreviewNewClient,
    setPreviewNewVirtual,
  } = useContext(FullScenarioDataContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const filtersToApply = useMemo(() => transformIntoFlatStructure(dropdownFilterOptions), [dropdownFilterOptions])

  const filteredDataForScenario = useMemo(
    () => filterData(calculateFilteredDataApplyingRulesOneAfterAnother(fullDataForScenario, rules), filtersToApply),
    [fullDataForScenario, rules, filtersToApply],
  )

  const filteredRenewalAndNew = useMemo(
    () => splitIntoRenewalAndNew(filteredDataForScenario),
    [filteredDataForScenario],
  )
  const filteredDataToUseInGraph = useMemo(() => [...filteredDataForScenario], [filteredDataForScenario])
  const filteredRenewalKpis = useMemo(() => calculateKPIs(filteredRenewalAndNew.renewal), [filteredRenewalAndNew])
  const filteredNewKpis = useMemo(
    () => calculateKPIs([...filteredRenewalAndNew.new, ...filteredRenewalAndNew.newClient]),
    [filteredRenewalAndNew],
  )

  return (
    <FullScenarioDataContext.Provider
      value={{
        fullDataForScenario,
        fullRenewalKpis,
        fullNewKpis,
        fullWeightedAverageKpis,
        grarcKpi,
        newGrarcKpi,
        renewalGrarcKpi,
        weightedAverageRenewalKpis,
        filteredDataForScenario,
        filteredRenewalKpis,
        filteredNewKpis,
        filteredNewDataForScenario: filteredRenewalAndNew.new,
        filteredRenewalDataForScenario: filteredRenewalAndNew.renewal,
        filteredNewClientDataForScenario: filteredRenewalAndNew.newClient,
        filteredNewCustomPortfolioDataForScenario: filteredRenewalAndNew.newCustomPortfolio,
        filteredDataToUseInGraph,
        reloadData,
        setPreviewNewClient,
        setPreviewRenewal,
        setPreviewNewVirtual,
      }}
      children={props.children}
    />
  )
}
