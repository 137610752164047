import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Flex, Spacer } from '@chakra-ui/react'
import { useContext } from 'react'
import { NcpAlertContext, NcpAlertDispatchContext } from '../../providers/NewCustomPortfolioAlertStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'

export const NewCustomPortfolioAlert = () => {
  const ncpAlertState = useContext(NcpAlertContext)
  assertNotNil(ncpAlertState)
  const ncpAlertDispatch = useContext(NcpAlertDispatchContext)
  assertNotNil(ncpAlertDispatch)

  if (!ncpAlertState.isActive) {
    return <></>
  }

  return (
    <Alert
      status={ncpAlertState.alertStatus}
      borderRadius={'md'}
      width={'70%'}
    >
      <Box>
        <Flex flexDirection="column">
          <Flex justifyContent="flex-start">
            <AlertIcon />
            <AlertTitle>{ncpAlertState.title}</AlertTitle>
          </Flex>
          <AlertDescription
            textAlign="left"
            pl={8}
          >
            {ncpAlertState.message.map((msg, index) => (
              <Box key={index}>{msg}</Box>
            ))}
          </AlertDescription>
        </Flex>
      </Box>
      <Spacer />
      {ncpAlertState.alertStatus === 'warning' && (
        <CloseButton
          data-testid="ncp-alert-close-button"
          onClick={() => ncpAlertDispatch({ type: 'closeAlert' })}
        />
      )}
    </Alert>
  )
}
