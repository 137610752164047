import React, { createContext, useContext } from 'react'
import { css } from '../../utils/css'
import { GraphStyleContext } from '../renewal-graph/GraphStyleProvider'
import './GraphContainer.scss'

export type GraphContainerProps = React.PropsWithChildren<{
  className: string
  graphId?: string
}>

const GraphContainerContext = createContext<GraphContainerValue>({
  graphId: '',
})

export interface GraphContainerValue {
  graphId?: string
}

function GraphContainer(props: GraphContainerProps) {
  const { currentlyExpandedGraph } = useContext(GraphStyleContext)

  const getClassNameForGraph = (graphId?: string) => {
    if (!currentlyExpandedGraph) {
      return props.className
    }
    if (currentlyExpandedGraph === graphId) {
      return 'GraphExpanded'
    }
    return 'GraphHidden'
  }

  const isExposuresGraph = (graphId?: string) => {
    if (!graphId) {
      return ''
    }
    if (graphId.includes('Exposures')) {
      return 'ExposuresGraph'
    }
    return ''
  }

  return (
    <GraphContainerContext.Provider value={{ graphId: props.graphId }}>
      <div className={css('GraphContainer', getClassNameForGraph(props.graphId), isExposuresGraph(props.graphId))}>
        {props.children}
      </div>
    </GraphContainerContext.Provider>
  )
}

export { GraphContainer, GraphContainerContext }
