import React, { useContext, useMemo } from 'react'
import { TableOptions, useTable } from 'react-table'
import './MarketSplitsBreakdownBySegmentTable.scss'
import SectionHeader from '../section-header/SectionHeader'
import { displayLargeDollarValue, sumListOfPossiblyUndefinedOrStringIntegers } from '../../utils/numbers'
import { MarketSegmentV2 } from '../../backend/market-V2'
import { MarketV2Context } from '../../pages/marketV2/MarketV2Provider'

export interface MarketTableProps extends TableOptions<MarketSegmentV2> {
  updateMyData: (rowIndex: number, columnId: string, newValue: string) => void
  setIsUpToDate: (isUpToDate: boolean) => void
  shouldValidate: boolean
}

export function MarketSplitsBreakdownBySegmentTable(props: MarketTableProps) {
  const { columns, data, updateMyData, shouldValidate, setIsUpToDate } = props

  const { isMarketEditorView } = useContext(MarketV2Context)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateMyData,
    shouldValidate,
    setIsUpToDate,
  } as any)

  const totalOfSegments = useMemo(() => {
    return sumListOfPossiblyUndefinedOrStringIntegers(data.map((item) => item.marketGwp))
  }, [data])

  const totalOfPercentages = useMemo(() => {
    return sumListOfPossiblyUndefinedOrStringIntegers(data.map((item) => item.marketPercentage))
  }, [data])

  const totalOfConvexGwpForSegments = useMemo(() => {
    return sumListOfPossiblyUndefinedOrStringIntegers(data.map((item: any) => item.convexGwp))
  }, [data])

  const totalOfMarketShareForSegments = useMemo(() => {
    return sumListOfPossiblyUndefinedOrStringIntegers(data.map((item: any) => item.marketShare))
  }, [data])

  return (
    <div className="MarketSplitsBreakdownBySegmentTable">
      <SectionHeader
        className="TableTitle"
        title="Market Splits"
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          <tr>
            <td>Total</td>
            <td>{totalOfPercentages}%</td>
            <td>{displayLargeDollarValue(totalOfSegments)}</td>
            {!isMarketEditorView && <td>{displayLargeDollarValue(totalOfConvexGwpForSegments)}</td>}
            {!isMarketEditorView && <td>{totalOfMarketShareForSegments.toFixed(2)}%</td>}
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MarketSplitsBreakdownBySegmentTable
