import React from 'react'
import { Card } from '../../components/card/Card'
import CancelIcon from '../../components/icons/CancelIcon'
import './StopComparisonIcon.scss'

interface StopComparisonIconProps {
  onClick: () => void
}

function StopComparisonIcon(props: StopComparisonIconProps) {
  return (
    <div
      className="StopComparisonIcon"
      key={'stop-comparison'}
      onClick={props.onClick}
    >
      <Card>
        <CancelIcon />
      </Card>
    </div>
  )
}

export { StopComparisonIcon }
