import { getCurrentConfigSettings } from '../utils/config'
import {
  AttritionalDependencyShorthand,
  ParameterisationByIdResponse,
  ModelStatus as MarketModelStatus,
} from './parameterisation'
import { getAccessToken } from './teams'

export interface MatrixByMarketContent {
  correl1: string
  correl2: string
  value: AttritionalDependencyShorthand
}
export interface MarketInputByMarketInput {
  market: string
  type: string
  value: number | string
}

export type MarketName = {
  value: string
}

export interface MarketInputDependency {
  value: AttritionalDependencyShorthand
}

export type ExportToDB = 'TRUE' | 'FALSE'

export interface CreateTeamParameterisationRequestBody {
  inputs: ParameterisationInputsTeamScenario
  callback_url?: string | undefined | null
}

export interface CreateTeamParameterisationRankingRequest {
  inputs: ParameterisationRankingInputs
  callback_url?: string | undefined | null
}

export interface ParameterisationRankingInputs extends ParameterisationInputsTeamScenario {
  tycheInAttritionalMeanOverride: boolean
  tycheInAttritionalMeanOverrideValue: number
  tycheInSRMeanOverride: TycheInSRMeanOverrideContent[]
  tycheInSRFreqVolatilityScaling: number
  tycheInAttritionalOverrides: TycheInAttritionalOverridesContent[]
}

export interface TycheInSRMeanOverrideContent {
  distribution: string
  value: number
}

export interface TycheInAttritionalOverridesContent {
  stat: string
  percentile: number
  value: number
}

export interface ParameterisationInputsTeamScenario {
  exportToDB: ExportToDB
  paramClass: string
  marketNames: MarketName[]
  corrMatrixbyMarketMarket: MatrixByMarketContent[]
  marketInputByMarketInput: MarketInputByMarketInput[]
  marketInputDependency: MarketInputDependency[]
}

export interface DefaultParameterData {
  division: string
  team: string
  market_1: string
  market_2: string
  year: string // "2021", "2022", "2023"
  dependency: string
}

export interface ParameterisationTeamScenarioResponse {
  id: string
  scenarioId: string
  modelStatus: ModelStatus
}

export type ResultbyDistributionbyLossType = {
  lossType: string
  distribution: string // column
  stat: string
  display: number
  value: number
  type: string
  name: string
}

// TODO: combine with market-parameteriation
// (the input is different between team and market parameterisation)
export interface ParameterisationTeamScenarioByIdResponse extends ParameterisationTeamScenarioResponse {
  result: TcsOutput
  input: CreateTeamParameterisationRequestBody
  createdAt: string
  updatedAt: string
}

export type ModelStatus = 'NOT_UP_TO_DATE' | MarketModelStatus

export enum TcsStatus {
  INPROGRESS = 'INPROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export interface TcsOutput {
  resultbyDistributionbyLossType: ResultbyDistributionbyLossType[]

  resultByDistributionDistribution?: string[] | undefined
  resultByDistributionStat?: string[] | undefined
  resultByDistributionValue?: string[] | undefined
  status: string
  error?: string[] | undefined
}

export interface ParameterInputs {
  GWP: number
  Max: number
  LR_Total: string
  LR_Attritional: string
  LR_Clash: string
  Threshold: number
  expectedNumberOfSrLosses: string
}

export async function fetchDefaultTeamMarketDependencies(
  teamScenarioId: string,
): Promise<undefined | DefaultParameterData[]> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/parameterisation/team-scenario/${teamScenarioId}/market-dependencies`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  const responseObj: DefaultParameterData[] = await response.json()

  if (Object.keys(responseObj).length === 0) {
    return undefined
  }

  return responseObj
}

export async function createTeamParameterisationRequest(
  teamScenarioId: string,
  body: CreateTeamParameterisationRequestBody,
): Promise<ParameterisationTeamScenarioResponse> {
  const url = `${getCurrentConfigSettings().apiHost}/parameterisation/team-scenario/${teamScenarioId}`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(body),
  })

  // if (response.status !== 201) {
  //   return Promise.reject('Fail to create TCS id');
  // }

  const responseObj = await response.json()
  console.log('createTeamParameterisationRequest::', responseObj)

  return responseObj
}

export async function fetchTeamParameterisationByScenarioId(
  teamScenarioId: string,
): Promise<ParameterisationTeamScenarioByIdResponse> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/parameterisation/team-scenario/${teamScenarioId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status >= 500) {
    return Promise.reject({ status: 'Unhandled server error' })
  }
  if (response.status >= 400) {
    return Promise.reject({ status: 'Fail to retrieve TCS id', ...(await response.json()) })
  }

  return await response.json()
}

export async function fetchUnderlyingMarketDataForTeamParameterisationByTeamScenarioId(
  teamScenarioId: string,
): Promise<ParameterisationByIdResponse[]> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/parameterisation/team-scenario/${teamScenarioId}/full-data`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  if (response.status >= 400) {
    return Promise.reject('Fail to retrieve TCS id')
  }

  return await response.json()
}

export async function fetchTeamParameterisationByTcsId(
  tcsId: string,
): Promise<ParameterisationTeamScenarioByIdResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/parameterisation/team/${tcsId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status >= 400) {
    return Promise.reject('Fail to retrieve TCS id')
  }

  return await response.json()
}
