import React, { useContext, useMemo } from 'react'
import { ExposuresDataContext } from '../../providers/ExposuresDataProvider'
import { AverageAnnualLossTableRowData } from '../../backend/exposures'
import { TreeMapNode } from '../summary-adjustments-graph/treemap/SummaryOfAdjustmentsByTypeProvider'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { DEFAULT_GRAPH_THEME } from '../renewal-graph/graph-theme'
import { formatForDisplay } from '../../utils/numbers'
import { GRAPH_COLOURS } from '../graphs/Graphs'

export const DEFAULT_LOADING_STATE = 'Loading...'

interface PointData {
  data: TreeMapNode
  formattedValue: string
  height: number
  id: string
  opacity: number
  value: number
  width: number
}

const displayIdAndValue = ((item: PointData) => `${item.id} - $${formatForDisplay(item.value)}`) as any

interface AverageAnnualLossesTreeMapProps {
  currentlySelectedReturnPeriod: string
}

const AverageAnnualLossesTreeMap = (props: AverageAnnualLossesTreeMapProps) => {
  const exposuresDataContext = useContext(ExposuresDataContext)

  const averageAnnualLossTableData = useMemo(() => {
    return exposuresDataContext.averageAnnualLossTableData?.rows ?? [DEFAULT_LOADING_STATE]
  }, [exposuresDataContext.averageAnnualLossTableData])

  const calculatedAalTreeMapData = useMemo(() => {
    const groupedAdjustedLossData: object[] = []
    if (averageAnnualLossTableData) {
      const fieldToSelect = props.currentlySelectedReturnPeriod
      const adjustedFieldToSelect = 'adjusted' + fieldToSelect[0].toUpperCase() + fieldToSelect.slice(1)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(averageAnnualLossTableData)) {
        const aalRowData = value as AverageAnnualLossTableRowData
        groupedAdjustedLossData.push({
          name: aalRowData.fieldThatWasSplitByValue,
          value: parseInt(value[adjustedFieldToSelect]),
          children: [],
        })
      }
    }

    const aalTreeMapData: TreeMapNode = {
      name: 'All',
      type: 'ALL',
      children: groupedAdjustedLossData,
    }

    return aalTreeMapData
  }, [props.currentlySelectedReturnPeriod, averageAnnualLossTableData])

  return (
    <div className="Graph ExposuresAalTreeMap">
      <ResponsiveTreeMap
        data={calculatedAalTreeMapData}
        identity="name"
        key={'tree-map-2'}
        parentLabelPadding={18}
        parentLabelSize={48}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        parentLabel={displayIdAndValue}
        label={displayIdAndValue}
        valueFormat=">-$,.3r"
        labelSkipSize={18}
        colors={GRAPH_COLOURS}
        theme={{ ...DEFAULT_GRAPH_THEME, text: { ...DEFAULT_GRAPH_THEME.text, fontSize: 10 } }}
      />
    </div>
  )
}

export default AverageAnnualLossesTreeMap
