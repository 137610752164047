import React, { useContext, useState } from 'react'
import { Card } from '../card/Card'
import TextField from '../text-field/WFTextField'
import TextArea from '../../components/text-area/TextArea'
import Button from '../../components/button/Button'
import { onInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import './CreateOutwardScenarioCard.scss'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'
import { createOutwardScenario, OutwardScenario } from '../../backend/outward-scenarios'
import { isValidName } from '../../utils/validity'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { RouteComponentProps, withRouter } from 'react-router'
import { StackedScenariosViewContext } from '../stacked-scenarios-view/StackedScenariosViewProvider'
import { StackedScenarioListProvider } from '../stacked-scenarios-list/StackedScenarioListProvider'
import { StackedScenarioSelect } from '../stacked-scenarios-list/StackedScenarioSelect'
import { saveOrUpdateListOfApplicableMarkets, updateExcludedPerilList } from '../../backend/perils'
import { OutwardsApplicablePerilsContext } from '../../providers/outwards/OutwardsApplicablePerilsProvider'
import { STACKED_SCENARIO_AVAILABLE_TAGS } from '../tag/Tag'

export type CreateOutwardScenarioCardProps = RouteComponentProps & {
  programme: string
  treaty: string
  layer: string
  year: string
  version: string
  onCreate?: () => void
  onCancel?: () => void
  outwardScenarios: OutwardScenario[]
}

function validateNameAndCalculateIfErrorIsNeeded(
  nameToValidate: string,
  existingScenarios: OutwardScenario[],
): string | undefined {
  if (!isValidName(nameToValidate)) {
    return 'Please enter a name for the outward scenario'
  }

  if (existingScenarios.length) {
    const index = existingScenarios.findIndex((scenario) => scenario.name === nameToValidate)
    if (index !== -1) {
      return 'Name must be unique for this combination of year, programme, treaty, layer'
    }
  }

  return undefined
}

function CreateOutwardScenarioCard(props: CreateOutwardScenarioCardProps) {
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { applicableMarkets } = useContext(OutwardsApplicablePerilsContext)

  const [currentInwardScenario, setInwardScenario] = useState<StackedScenario | null | undefined>(null)

  const [currentName, setName] = useState<string>('')
  const [currentSummary, setSummary] = useState('')
  const [shouldValidate, updateValidation] = useState(false)

  function validate(): boolean {
    updateValidation(true)
    return validateNameAndCalculateIfErrorIsNeeded(currentName, props.outwardScenarios) === undefined
  }

  async function createOutwardScenarioOnClick() {
    updateIndividualProgressItem('creatingOutwardScenario', ProgressState.LOADING)
    const outwardScenarioId = await createOutwardScenario(
      props.programme,
      props.treaty,
      props.layer,
      props.version,
      props.year,
      {
        name: currentName,
        inwardScenarioId: currentInwardScenario!.id,
      },
    )
    updateIndividualProgressItem('creatingOutwardScenario', ProgressState.FINISHED)
    props.onCreate && props.onCreate()
    props.history.push(`/outward-scenarios/${outwardScenarioId}/coverage`)
    await updateExcludedPerilList(outwardScenarioId, {
      outwardScenarioId: outwardScenarioId,
      description: '',
      excludedPerilNames: [],
    })
    await saveOrUpdateListOfApplicableMarkets(outwardScenarioId, { applicableMarkets: applicableMarkets })
  }

  return (
    <div className="CreateScenarioCard">
      <Card>
        <h4 className="CreateScenarioCardTitle">Create Scenario</h4>
        <TextField
          title="Name"
          placeholder="Name"
          className="Name"
          value={currentName}
          onChange={onInputUpdateState(setName)}
          error={
            shouldValidate
              ? validateNameAndCalculateIfErrorIsNeeded(currentName, props.outwardScenarios)
                ? undefined
                : validateNameAndCalculateIfErrorIsNeeded(currentName, props.outwardScenarios)
              : undefined
          }
        />
        <StackedScenariosViewContext.Provider
          value={{
            currentYear: props.year,
            currentPortfolioLevel: 'Organisational',
            currentDivision: undefined,
            currentTeam: undefined,
          }}
        >
          <StackedScenarioListProvider>
            <StackedScenarioSelect
              title={'Inward Scenario'}
              currentSelectedStackedScenarioId={currentInwardScenario?.id}
              onStackedScenarioSelected={setInwardScenario}
              tagsToInclude={Object.values(STACKED_SCENARIO_AVAILABLE_TAGS)}
              boxed={false}
            />
          </StackedScenarioListProvider>
        </StackedScenariosViewContext.Provider>

        <TextArea
          title="Summary"
          placeholder="Summary"
          value={currentSummary}
          onChange={onTextAreaUpdateState(setSummary)}
        />
        <div className="ButtonContainer">
          {props.onCancel && (
            <Button
              title="Cancel"
              onClick={props.onCancel}
              secondary
            />
          )}
          <Button
            title="Create"
            onClick={async () => {
              if (validate()) {
                await createOutwardScenarioOnClick()
              }
            }}
          />
        </div>
      </Card>
    </div>
  )
}

export default withRouter(CreateOutwardScenarioCard)
