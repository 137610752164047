import React, { ReactElement } from 'react'
import { suffixForValue, ZERO } from '../../utils/numbers'
import KpiCard from '../kpi-card/KpiCard'
import { Big } from 'big.js'
import { FullScenarioDataContainer } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import StackedScenarioCard from '../stacked-scenario-card/StackedScenarioCard'
import { StackedScenarioContainer } from '../../providers/StackedScenarioComparisonProvider'
import { ScenarioKeyPerformanceIndicators, TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import ScenarioCards from '../scenario-card/ScenarioCards'
import { AggregatedMarketDataContainerV2 } from '../../providers/AggregatedMarketDataProviderV2'

export interface StackedScenarioComparisonRow {
  title: string
  additionalCssClassNames?: string
  customCssClassName?: string
  perScenarioContentGenerator: (context: {
    stackedScenarioContainer: StackedScenarioContainer
    fullScenarioContext: FullScenarioDataContainer
    marketContext: AggregatedMarketDataContainerV2
  }) => ReactElement | string | number
}

function handleConvexGWPWithNewAndRenewals(
  renewalKpis: ScenarioKeyPerformanceIndicators | undefined,
  newKpis: ScenarioKeyPerformanceIndicators | undefined,
): { value: Big; suffix: string } {
  let value = ZERO
  let suffix = ''
  value = value.add(
    renewalKpis && renewalKpis[TOTAL_ADJUSTED_GWP_COLUMN] ? renewalKpis[TOTAL_ADJUSTED_GWP_COLUMN] : ZERO,
  )
  value = value.add(newKpis && newKpis[TOTAL_ADJUSTED_GWP_COLUMN] ? newKpis[TOTAL_ADJUSTED_GWP_COLUMN] : ZERO)
  suffix = suffixForValue(value)
  return { value: value, suffix: suffix }
}

export const StackedScenarioComparisonRows: StackedScenarioComparisonRow[] = [
  {
    title: '',
    additionalCssClassNames: 'GridListOfCards',
    perScenarioContentGenerator: (context) =>
      context.stackedScenarioContainer.currentStackedScenario ? (
        <StackedScenarioCard
          stackedScenario={context.stackedScenarioContainer.currentStackedScenario!}
          triggerStackedScenarioReload={() => {}}
          creatingIsSelected={false}
          creatingOnToggle={() => {}}
          ableToEditUnderlyingScenarios={false}
          selectable={false}
          comparingOnToggle={() => {}}
          isComparing={false}
          comparingIsSelected={false}
        />
      ) : (
        ''
      ),
  },
  {
    title: 'Market Overview',
    perScenarioContentGenerator: (context) => '',
  },
  {
    title: 'Market GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext.aggregatedMarketData?.totalMarketGwp
            ? new Big(context.marketContext.aggregatedMarketData?.totalMarketGwp)
            : ZERO
        }
        title={''}
        suffix={suffixForValue(context.marketContext.aggregatedMarketData?.totalMarketGwp || 0)}
      />
    ),
  },
  {
    title: 'Market GRARC (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext.aggregatedMarketData?.marketGrarc
            ? new Big(context.marketContext.aggregatedMarketData?.marketGrarc)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Market NRARC (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext.aggregatedMarketData?.marketNrarc
            ? new Big(context.marketContext.aggregatedMarketData?.marketNrarc)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Convex Overview',
    perScenarioContentGenerator: (context) => '',
  },
  {
    title: 'Total Adjusted GWP',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          handleConvexGWPWithNewAndRenewals(
            context.fullScenarioContext.filteredRenewalKpis,
            context.fullScenarioContext.filteredNewKpis,
          ).value
        }
        title={''}
        suffix={
          handleConvexGWPWithNewAndRenewals(
            context.fullScenarioContext.filteredRenewalKpis,
            context.fullScenarioContext.filteredNewKpis,
          ).suffix
        }
      />
    ),
  },
  {
    title: 'Peak Convex Market Share (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext.aggregatedMarketData?.peakConvexMarketShare
            ? new Big(context.marketContext.aggregatedMarketData?.peakConvexMarketShare)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Convex Acquisition Ratio (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext.aggregatedMarketData?.convexAcquisitionRatio
            ? new Big(context.marketContext.aggregatedMarketData?.convexAcquisitionRatio)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Renewal GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={context.fullScenarioContext?.filteredRenewalKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO}
        title={''}
        suffix={suffixForValue(context.fullScenarioContext?.fullRenewalKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO)}
      />
    ),
  },
  {
    title: 'New GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={context.fullScenarioContext?.filteredNewKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO}
        title={''}
        suffix={suffixForValue(context.fullScenarioContext?.fullNewKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO)}
      />
    ),
  },
  {
    title: 'Stacked Scenarios',
    customCssClassName: 'GridListOfCards',
    perScenarioContentGenerator: (context) => <ScenarioCards scenarios={context.stackedScenarioContainer.leafNodes} />,
  },
]
