import React, { useContext, useMemo } from 'react'
import { AdjustedGwpBreakdownPieGraphWithFilter } from '../../components/renewal-graph/AdjustedGwpBreakdownPieGraphWithFilter'
import { AdjutedGwpWaterfalSplitByColumnFilter } from '../../components/renewal-graph/waterfall/AdjustedGwpWaterfallSplitByColumnFilter'
import { GraphContainer } from '../../components/graph-container/GraphContainer'
import { Graphs } from '../../components/graphs/Graphs'
import { NumericAndColumnChartWithFilters } from '../../components/renewal-graph/violin-chart/NumericAndColumnChartWithFilters'
import BoxAndWhiskersChart from '../../components/renewal-graph/violin-chart/BoxAndWhiskersChart'
import { GraphHeader } from '../../components/graph-header/GraphHeader'
import { GwpsCumulativeLine } from '../../components/renewal-graph/gwp-cumulative-line-chart/GwpsCumulativeLine'
import { SummaryTreeMapWithSelectors } from '../../components/summary-adjustments-graph/treemap/SummaryTreeMapWithSelectors'
import { GwpAndAdjustedGwpBarChartWithColumnChooser } from '../../components/renewal-graph/gwp-bar-chart/GwpAndAdjustedGwpBarChartWithColumnChooser'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../backend/calculate-with-actuals'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { ScenarioLevel } from '../../backend/stacked-scenarios'
import { WF_TYPE_OF_DATA_COLUMN } from '../../backend/TypeOfData'
import { LimitAndGwpLine } from '../../components/renewal-graph/limit-and-gwp-chart/LimitAndGwpLine'

export type RenewalGraphsProps = React.PropsWithChildren<{}>

export function SharedGraphs(props: RenewalGraphsProps) {
  const { currentStackedScenario } = useContext(StackedScenarioContext)

  const fieldToDefaultTo = useMemo(() => {
    if (!currentStackedScenario) {
      return 'A1 Segment'
    }

    if (currentStackedScenario.scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
      return 'UW Division'
    }

    if (currentStackedScenario.scenarioLevel === ScenarioLevel.DIVISIONAL) {
      return 'UW Team'
    }

    return 'A1 Market'
  }, [currentStackedScenario])

  const breakDownDefaultField = !currentStackedScenario ? WF_TYPE_OF_DATA_COLUMN : fieldToDefaultTo

  const secondLevelFieldToDefaultTo = useMemo(() => {
    if (!currentStackedScenario) {
      return 'A1 Segment'
    }

    if (currentStackedScenario.scenarioLevel === ScenarioLevel.ORGANISATIONAL) {
      return 'UW Team'
    }

    if (currentStackedScenario.scenarioLevel === ScenarioLevel.DIVISIONAL) {
      return 'A1 Market'
    }

    return 'A1 Segment'
  }, [currentStackedScenario])

  return (
    <div
      className="SharedGraphs"
      {...props}
    >
      <Graphs>
        <GraphContainer
          className="GraphLeft"
          graphId="GwpBreakdownTreeMap"
        >
          <GraphHeader title={'Breakdown of Adjusted GWP'} />
          <SummaryTreeMapWithSelectors
            defaultGroup1Field={breakDownDefaultField}
            defaultGroup2Field={secondLevelFieldToDefaultTo}
          />
        </GraphContainer>
        <GraphContainer
          className="GraphMiddle"
          graphId="GwpBreakdownPieGraph"
        >
          <AdjustedGwpBreakdownPieGraphWithFilter
            defaultGraphColumn={fieldToDefaultTo}
            hideNew
          />
        </GraphContainer>
        <GraphContainer
          className="GraphRight"
          graphId="GwpBreakdownByColumn"
        >
          <GwpAndAdjustedGwpBarChartWithColumnChooser defaultColumn={RENEWAL_INCEPTION_MONTH_COLUMN_NAME} />
        </GraphContainer>

        <GraphContainer
          className="GraphLeft"
          graphId="BoxAndWhiskers"
        >
          <NumericAndColumnChartWithFilters
            title="Box & Whiskers"
            defaultStringColumn={fieldToDefaultTo}
          >
            <BoxAndWhiskersChart />
          </NumericAndColumnChartWithFilters>
        </GraphContainer>
        <GraphContainer
          className="GraphMiddle"
          graphId="AdjustedGwpBreakdownWaterfall"
        >
          <AdjutedGwpWaterfalSplitByColumnFilter columnToDefaultTo={secondLevelFieldToDefaultTo} />
        </GraphContainer>

        <GraphContainer
          className="GraphRight"
          graphId="OriginalVsAdjustedGwp"
        >
          <GwpsCumulativeLine />
        </GraphContainer>
        <GraphContainer
          className="GraphLarge"
          graphId="LimitAndGwp"
        >
          <LimitAndGwpLine defaultColumn={fieldToDefaultTo} />
        </GraphContainer>
      </Graphs>
    </div>
  )
}
export default SharedGraphs
