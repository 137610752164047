import React, { SVGProps } from 'react'

const PortfolioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#4E566B"
      fillRule="evenodd"
      d="M2.66666667,4 L1.33333333,4 L1.33333333,13.3333333 C1.33333333,14.0666667 1.93333333,14.6666667 2.66666667,14.6666667 L12,14.6666667 L12,13.3333333 L2.66666667,13.3333333 L2.66666667,4 Z M13.3333333,1.33333333 L5.33333333,1.33333333 C4.6,1.33333333 4,1.93333333 4,2.66666667 L4,10.6666667 C4,11.4 4.6,12 5.33333333,12 L13.3333333,12 C14.0666667,12 14.6666667,11.4 14.6666667,10.6666667 L14.6666667,2.66666667 C14.6666667,1.93333333 14.0666667,1.33333333 13.3333333,1.33333333 Z M11.3333333,2.66666667 L11.3333333,6 L10.6666667,5.5 L10,6 L10,2.66666667 L11.3333333,2.66666667 Z M13.3333333,10.6666667 L5.33333333,10.6666667 L5.33333333,2.66666667 L8.66666667,2.66666667 L8.66666667,8.66666667 L10.6666667,7.16666667 L12.6666667,8.66666667 L12.6666667,2.66666667 L13.3333333,2.66666667 L13.3333333,10.6666667 Z"
    />
  </svg>
)

export default PortfolioIcon
