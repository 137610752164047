import { useCallback, useContext } from 'react'
import { FilterContext } from 'src/providers/FilterProvider'
import { RenewalAdjustmentsContextContainer, RenewalAdjustmentContext } from 'src/providers/RenewalAdjustmentsProvider'

export const useResetAdjustments = (): (() => void) => {
  const { triggerReloadSavedAdjustments, setWhichAdjustmentIsBeingEdited, setCurrentDefaultAdjustmentNameToUse } =
    useContext<RenewalAdjustmentsContextContainer>(RenewalAdjustmentContext)
  const { onChangeFilters } = useContext(FilterContext)

  return useCallback(() => {
    triggerReloadSavedAdjustments()
    setWhichAdjustmentIsBeingEdited('')
    setCurrentDefaultAdjustmentNameToUse('')
    onChangeFilters({})
  }, [
    onChangeFilters,
    triggerReloadSavedAdjustments,
    setWhichAdjustmentIsBeingEdited,
    setCurrentDefaultAdjustmentNameToUse,
  ])
}
