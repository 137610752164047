import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { ScenarioContext } from './ScenarioProvider'
import { fetchNewClients, NewClient } from '../backend/new-clients'
import { ProgressContext, ProgressState } from './ProgressProvider'
import { NEW_ITEM_PREVIEW_ID } from '../backend/new-portfolio-items'
import { FullScenarioDataContext } from './FullScenarioData/FullScenarioDataProvider'

export interface NewClientItemsContainer {
  newClients: Array<NewClient>
  triggerReloadNewClients: () => void
  currentlyEditingNewClientItemId: string | undefined
  setCurrentlyEditingNewClientId: (newClientId: string) => void
}

export const defaultNewClientItemsContext: NewClientItemsContainer = {
  newClients: [],
  triggerReloadNewClients: () => undefined,
  currentlyEditingNewClientItemId: NEW_ITEM_PREVIEW_ID,
  setCurrentlyEditingNewClientId: () => undefined,
}

export const NewClientItemsContext = createContext<NewClientItemsContainer>(defaultNewClientItemsContext)

export function NewClientItemsProvider(props: PropsWithChildren) {
  const { reloadData } = useContext(FullScenarioDataContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const [newClients, setNewClientsInMemory] = useState<Array<NewClient>>([])
  const [currentlyEditingNewClientItemId, setCurrentlyEditingNewClientId] = useState<string>(NEW_ITEM_PREVIEW_ID)

  const reloadNewClientsFromApi: () => Promise<void> = useCallback(async () => {
    if (!currentScenario) return

    updateIndividualProgressItem('fetchNewClients', ProgressState.LOADING)

    try {
      const listOfNewClients = await fetchNewClients(currentScenario.id)
      setNewClientsInMemory(listOfNewClients)
      updateIndividualProgressItem('fetchNewClients', ProgressState.FINISHED)
    } catch (_) {
      updateIndividualProgressItem('fetchNewClients', ProgressState.ERROR)
    }
  }, [currentScenario, updateIndividualProgressItem])

  const triggerReloadSavedNewClientDataInputs: () => Promise<void> = useCallback(async () => {
    await reloadNewClientsFromApi()
    currentScenario && reloadData()
  }, [currentScenario, reloadData, reloadNewClientsFromApi])

  useEffect(() => {
    reloadNewClientsFromApi()
  }, [reloadNewClientsFromApi])

  return (
    <NewClientItemsContext.Provider
      value={{
        newClients,
        triggerReloadNewClients: triggerReloadSavedNewClientDataInputs,
        currentlyEditingNewClientItemId,
        setCurrentlyEditingNewClientId,
      }}
    >
      {props.children}
    </NewClientItemsContext.Provider>
  )
}
