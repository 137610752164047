import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import {
  NumberType,
  ONE,
  ONE_BILLION,
  ONE_MILLION,
  ONE_THOUSAND,
  PossiblyNegativeNumber,
  ZERO_POINT_ZERO_ONE,
} from './numbers'
import Big from 'big.js'

export const onInputUpdateState =
  (setState: Dispatch<SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) =>
    setState(event.target.value)

export const onNumberInputUpdateState =
  (setState: Dispatch<SetStateAction<PossiblyNegativeNumber>>) => (event: ChangeEvent<HTMLInputElement>) =>
    setState(event.target.value === '-' ? event.target.value : event.target.valueAsNumber)

export const calculateValueIncludingSuffix = (inputValue: string, numberType: NumberType = NumberType.DOLLAR) => {
  if (inputValue === '-' || inputValue === '') {
    return inputValue
  }

  let multiplier = ONE
  try {
    const finalCharacter = inputValue.slice(-1)
    if (finalCharacter === 'b') {
      multiplier = ONE_BILLION
    } else if (finalCharacter === 'm') {
      multiplier = ONE_MILLION
    } else if (finalCharacter === 'k') {
      multiplier = ONE_THOUSAND
    } else if (finalCharacter === '%' || numberType === NumberType.PERCENTAGE) {
      multiplier = ZERO_POINT_ZERO_ONE
    } else if (finalCharacter === '.') {
      return inputValue
    }
  } catch (any) {}
  const numericPart = inputValue.replaceAll(/[^0-9.]/g, '')
  try {
    return multiplier.mul(new Big(numericPart)).toNumber()
  } catch (e) {
    return undefined
  }
}

export const setValueIncludingSuffixShorthand =
  (setState: Dispatch<SetStateAction<PossiblyNegativeNumber>>) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = calculateValueIncludingSuffix(event.target.value)
    setState(value as any)
  }

export const updateValueIncludingSuffixShorthand = (
  newGwp: string,
  updateState: ((value: PossiblyNegativeNumber) => void) | undefined,
) => {
  const value = calculateValueIncludingSuffix(newGwp)
  updateState?.(value as PossiblyNegativeNumber)
}

export const onTextAreaUpdateState =
  (setState: Dispatch<SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) =>
    setState(event.target.value)

export const onSelectUpdateState =
  (setState: Dispatch<SetStateAction<string>>) => (event: ChangeEvent<HTMLSelectElement>) =>
    setState(event.target.value)

export function onSelectUpdateQueryState(
  setState: (newValue: any, updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined) => void,
) {
  return (event: ChangeEvent<HTMLSelectElement>) => setState(event.target.value)
}
