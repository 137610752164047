import React, { ReactElement, useContext } from 'react'
import './SplitContentContainerWithSideMenu.scss'
import { ProgressContext } from '../../providers/ProgressProvider'
import Button from '../button/Button'

export type SplitContentContainerWithSideMenuProps = React.PropsWithChildren<{
  leftColumm: ReactElement
  rightColumn: ReactElement
  sideMenu: ReactElement
}>

export function SplitContentContainerWithSideMenu(props: SplitContentContainerWithSideMenuProps) {
  const { showMenu, setShowMenu } = useContext(ProgressContext)

  const onShow = (): void => setShowMenu((x) => (x === 'show' ? 'hide' : 'show'))

  return (
    <div className="SplitContentContainerWithSideMenu">
      <div className="Expand">
        <Button
          onClick={onShow}
          title={<div className={offsetClassName(showMenu)}>{'<'}</div>}
          className={'Round'}
        />
      </div>
      <div className={leftClassName(showMenu)}>{props.leftColumm}</div>
      <div className="Right">{props.rightColumn}</div>
      <div className="SideMenu">{props.sideMenu}</div>
    </div>
  )

  function offsetClassName(x: typeof showMenu): string {
    return `Offset ${x === 'show' ? '' : 'hide'}`
  }

  function leftClassName(x: typeof showMenu): string {
    return `Left ${x === 'show' ? '' : 'hide'}`
  }
}

export default SplitContentContainerWithSideMenu
