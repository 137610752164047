import { PropsWithChildren } from 'react'
import { TableColumnHeaderProps, Th } from '@chakra-ui/react'

interface Props {
  stickyOffset?: number
}

export const NcpTableHeadCell = (props: PropsWithChildren<Props & TableColumnHeaderProps>) => {
  const { stickyOffset, ...otherProps } = props

  return (
    <Th
      style={
        stickyOffset !== undefined
          ? {
              position: 'sticky',
              left: `${stickyOffset}px`,
              zIndex: 2,
              background: '#F8F8F8',
            }
          : undefined
      }
      {...otherProps}
    >
      {props.children}
    </Th>
  )
}
