import React, { ReactElement } from 'react'
import { ScenarioContainer } from '../../providers/ScenarioProvider'
import { RenewalAdjustmentsContextContainer } from '../../providers/RenewalAdjustmentsProvider'
import { NewPseudoPortfolioItemsContextContainer } from '../../providers/NewPseudoPortfolioItemsProvider'
import { suffixForValue, sumListOfPossiblyUndefinedOrStringIntegers, ZERO } from '../../utils/numbers'
import { TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import KpiCard from '../kpi-card/KpiCard'
import { Big } from 'big.js'
import { Adjustments } from '../adjustments/Adjustments'
import NewPortfolioItemContainer from '../new-portfolio-item-container/NewPortfolioItemContainer'
import ScenarioCard from '../scenario-card/ScenarioCard'
import { MarketDataContainerV2 } from '../../providers/MarketDataProviderV2'
import { FullScenarioDataContainer } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { NewBusinessTypeWithCustomPortfolio } from 'src/pages/new/New'
import { UrlUpdateType } from 'use-query-params'

export interface ScenarioComparisonRow {
  title: string
  additionalCssClassNames?: string
  customCssClassName?: string
  perScenarioContentGenerator: (context: {
    scenarioContainer: ScenarioContainer
    fullScenarioContext: FullScenarioDataContainer
    renewalAdjustmentContext: RenewalAdjustmentsContextContainer
    pseudoPortfolioContext: NewPseudoPortfolioItemsContextContainer
    marketContext: MarketDataContainerV2
    currentBusinessType: string | null | undefined
    setBusinessType: (newValue: NewBusinessTypeWithCustomPortfolio, updateType?: UrlUpdateType | undefined) => void
  }) => ReactElement | string | number
}

export const ScenarioComparisonRows: ScenarioComparisonRow[] = [
  {
    title: '',
    additionalCssClassNames: 'GridListOfCards',
    perScenarioContentGenerator: (context) =>
      context.scenarioContainer.currentScenario ? (
        <ScenarioCard
          scenario={context.scenarioContainer.currentScenario!}
          isComparing={false}
          canWrite={false}
          comparingIsSelected={false}
          comparingOnToggle={() => void 0}
          triggerEditScenario={() => void 0}
          triggerScenarioReload={() => void 0}
          isCreatingTeamScenario={false}
          creatingIsSelected={false}
          creatingOnToggle={() => void 0}
          stopClicking
        />
      ) : (
        ''
      ),
  },
  {
    title: 'Market - Actuarial Benchmark',
    additionalCssClassNames: 'GridSection',
    perScenarioContentGenerator: (context) => '',
  },
  {
    title: 'Market GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext?.marketData?.totalMarketGwp
            ? new Big(context.marketContext?.marketData.totalMarketGwp)
            : ZERO
        }
        title={''}
        suffix={
          context.marketContext?.marketData?.totalMarketGwp
            ? suffixForValue(context.marketContext?.marketData?.totalMarketGwp)
            : ''
        }
      />
    ),
  },
  {
    title: 'Convex Acquisition Ratio',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext?.marketData?.convexAcquisitionRatio
            ? new Big(context.marketContext?.marketData.convexAcquisitionRatio)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Market GRARC (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext?.marketData?.marketGrarc ? new Big(context.marketContext?.marketData.marketGrarc) : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Convex - Underwriter View',
    additionalCssClassNames: 'GridSection',
    perScenarioContentGenerator: (context) => '',
  },
  {
    title: 'Total Adjusted GWP',
    perScenarioContentGenerator: (context) => {
      const renewalGwp = context.fullScenarioContext?.filteredRenewalKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO
      const newGwp = context.fullScenarioContext?.filteredNewKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO
      const totalGwp = renewalGwp.plus(newGwp)
      return (
        <KpiCard
          value={totalGwp}
          title={''}
          suffix={suffixForValue(totalGwp)}
        />
      )
    },
  },
  {
    title: 'Peak Convex Market Share (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext?.marketData?.peakConvexMarketShare
            ? new Big(context.marketContext?.marketData.peakConvexMarketShare)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Convex Acquisition Ratio (%)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={
          context.marketContext?.marketData?.convexAcquisitionRatio
            ? new Big(context.marketContext?.marketData.convexAcquisitionRatio)
            : ZERO
        }
        title={''}
        suffix={'%'}
      />
    ),
  },
  {
    title: 'Segmentation',
    perScenarioContentGenerator: (context) => {
      const totalOfSegments = sumListOfPossiblyUndefinedOrStringIntegers(
        context?.marketContext?.marketData?.marketSegments?.map((item) => item.marketGwp) || [],
      )
      const matchesMarketSize =
        totalOfSegments === parseInt(context?.marketContext?.marketData?.totalMarketGwp?.toString() || '0')
      return <>{matchesMarketSize ? 'Segments match GWP' : 'Segments do not add up to market size'}</>
    },
  },
  {
    title: 'Adjustment Details Overview',
    additionalCssClassNames: 'GridSection',
    perScenarioContentGenerator: (context) => '',
  },
  {
    title: 'Renewal GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={context.fullScenarioContext?.filteredRenewalKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO}
        title={''}
        suffix={suffixForValue(context.fullScenarioContext?.fullRenewalKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO)}
      />
    ),
  },
  {
    title: 'New GWP ($)',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={context.fullScenarioContext?.filteredNewKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO}
        title={''}
        suffix={suffixForValue(context.fullScenarioContext?.fullNewKpis?.[TOTAL_ADJUSTED_GWP_COLUMN] || ZERO)}
      />
    ),
  },
  {
    title: 'Number of Renewal Adjustments',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={new Big(context.renewalAdjustmentContext?.adjustments?.length || 0)}
        title={''}
        suffix={suffixForValue(context.renewalAdjustmentContext?.adjustments?.length || ZERO)}
      />
    ),
  },
  {
    title: 'Number of New Adjustments',
    perScenarioContentGenerator: (context) => (
      <KpiCard
        value={new Big(context.pseudoPortfolioContext?.newDataInputs?.length || 0)}
        title={''}
        suffix={suffixForValue(context.pseudoPortfolioContext?.newDataInputs?.length || ZERO)}
      />
    ),
  },
  {
    title: 'Renewal Adjustments',
    customCssClassName: 'GridListOfCards',
    perScenarioContentGenerator: (context) => (
      <Adjustments
        adjustmentType={'NORMAL'}
        hideCreateAndEdit
      />
    ),
  },
  {
    title: 'New Adjustments',
    customCssClassName: 'GridListOfCards',
    perScenarioContentGenerator: (context) => (
      <NewPortfolioItemContainer
        hideCreateAndEdit
        currentBusinessType={context.currentBusinessType}
        setBusinessType={context.setBusinessType}
      />
    ),
  },
]
