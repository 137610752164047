import { PossiblyNegativeNumber } from '../../../utils/numbers'
import { useCallback, useContext } from 'react'
import { FilterContext } from '../../../providers/FilterProvider'
import { NcpDispatchContext } from '../../../providers/NewCustomPortfolioStateProvider'
import { NcpAlertDispatchContext } from '../../../providers/NewCustomPortfolioAlertStateProvider'

export type UseResetAfterSuccessOptions = {
  setErrorUniqueName: (val: string) => void
  requestCloseModal: () => void
  setName: (name: string) => void
  updateCurrentGwpInput: ((val: PossiblyNegativeNumber) => void) | undefined
  setGrarcInput: (val: PossiblyNegativeNumber) => void
  setShouldValidate: (val: boolean) => void
  setDescription: (val: string) => void
}

export const useResetAfterSuccess = (options: UseResetAfterSuccessOptions): (() => void) => {
  const { onChangeFilters } = useContext(FilterContext)
  const ncpDispatch = useContext(NcpDispatchContext)
  const ncpAlertDispatch = useContext(NcpAlertDispatchContext)

  return useCallback(() => {
    options.setErrorUniqueName('')
    options.requestCloseModal()
    onChangeFilters({})
    options.setName('')
    options.updateCurrentGwpInput?.('' as any)
    options.setGrarcInput('' as any)
    options.setDescription('')
    options.setShouldValidate(false)
    ncpDispatch?.({
      type: 'resetNcpData',
    })
    ncpDispatch?.({ type: 'reset', payload: { reset: true } })
    ncpAlertDispatch?.({ type: 'reset' })
  }, [onChangeFilters, ncpDispatch, ncpAlertDispatch, options])
}
