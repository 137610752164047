import { useContext } from 'react'
import { withRouter } from 'react-router'
import './Summary.scss'
import { FilterProvider } from '../../providers/FilterProvider'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { StackedScenarioDataProvider } from '../../providers/StackedScenarioDataProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { RenewalGraphFilterDataProvider } from '../../components/renewal-graph/GraphFilterDataProvider'
import { EXTRA_FILTERS_FOR_SUMMARY_PAGE } from '../../backend/calculate-possible-filter-values'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import { CLIENT_NAME_COLUMN_IDENTIFIER } from '../../backend/calculate-kpis'
import SectionHeader from '../../components/section-header/SectionHeader'
import SharedGraphs from '../renewal/SharedGraphs'
import { GraphStyleProvider } from '../../components/renewal-graph/GraphStyleProvider'
import { FullStackedKpiDisplay } from '../../components/kpis/FullStackedKpiDisplay'
import { StackedScenarioInitialDataProvider } from '../../providers/StackedScenarioComparisonProvider'
import { SummaryTableContent } from '../summary/SummaryTableContent'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import { MarketV2Provider } from '../marketV2/MarketV2Provider'
import { AggregatedMarketDataProviderV2 } from '../../providers/AggregatedMarketDataProviderV2'

function StackedScenarioSummary() {
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  if (!currentStackedScenario) {
    return <div />
  }

  return (
    <div className="Summary">
      <AggregatedMarketDataProviderV2>
        <StackedScenarioDataProvider stackedScenario={currentStackedScenario}>
          <StackedScenarioInitialDataProvider stackedScenarioId={currentStackedScenario.id}>
            <RenewalGraphFilterDataProvider
              extraFilterValues={EXTRA_FILTERS_FOR_SUMMARY_PAGE}
              onesToIgnore={[CLIENT_NAME_COLUMN_IDENTIFIER]}
            >
              <MarketV2Provider>
                <SplitContentContainerWithSideMenu
                  leftColumm={
                    <FiltersContainer>
                      <SectionHeader title="Filter Control" />
                      <ScenarioFilterCard
                        extraFilterValues={EXTRA_FILTERS_FOR_SUMMARY_PAGE}
                        outward={true}
                      />
                    </FiltersContainer>
                  }
                  rightColumn={
                    graphPanelIsOpen ? (
                      <GraphStyleProvider>
                        <SharedGraphs />
                      </GraphStyleProvider>
                    ) : (
                      <SummaryTableContent />
                    )
                  }
                  sideMenu={
                    <KpiBar>
                      <FullStackedKpiDisplay />
                    </KpiBar>
                  }
                />
              </MarketV2Provider>
            </RenewalGraphFilterDataProvider>
          </StackedScenarioInitialDataProvider>
        </StackedScenarioDataProvider>
      </AggregatedMarketDataProviderV2>
    </div>
  )
}

const StackedScenarioSummaryWithRouter = withRouter(StackedScenarioSummary)

const StackedScenarioWithProviders = () => (
  <FilterProvider>
    <MarketV2Provider>
      <StackedScenarioSummaryWithRouter />
    </MarketV2Provider>
  </FilterProvider>
)

export default withRouter(StackedScenarioWithProviders)
