import React, { useContext, useEffect, useState } from 'react'
import { Card } from '../card/Card'
import Button from '../button/Button'
import TextField from '../text-field/WFTextField'
import TextArea from '../text-area/TextArea'
import { onInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { transformIntoFlatStructure } from '../../providers/FilterProvider'
import {
  createRule,
  fetchRuleById,
  isIrrelevantRule,
  isRelevantRule,
  patchRule,
  Rule,
} from '../../backend/outward-rules'
import { RULE_PREVIEW_ID } from '../outward-rule-card/OutwardRuleCard'
import { isValidName } from '../../utils/validity'
import { OutwardScenarioRuleContext } from '../../providers/outwards/OutwardScenarioRulesProvider'
import './CreateOutwardRuleCard.scss'
import { ErrorText } from '../text-area/ErrorText'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { OutwardsApplicablePerilsContext } from '../../providers/outwards/OutwardsApplicablePerilsProvider'
import { saveOrUpdateListOfApplicableMarkets } from '../../backend/perils'
import { FilterContext } from '../../providers/FilterProvider'

export type CreateOutwardRuleCardProps = {
  ruleBeingEditedId: string
  reloadSavedRulesAndResetRulesPage: () => void
}

export function CreateOutwardRuleCard(props: CreateOutwardRuleCardProps) {
  const [currentName, setName] = useState('')
  const [currentDescription, setDescription] = useState('')
  const [shouldValidate, setShouldValidate] = useState(false)
  const [errorUniqueName, setErrorUniqueName] = useState<string>('')

  const { dropdownFilterOptions } = useContext(FilterContext)
  const { rules, triggerReloadSavedRules, changeRules } = useContext(OutwardScenarioRuleContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const { applicableMarkets } = useContext(OutwardsApplicablePerilsContext)

  const isPreview = props.ruleBeingEditedId === RULE_PREVIEW_ID
  const addOrSaveWord = isPreview ? 'Add' : 'Save'

  const updateBasedOnNewScenario = () => {
    if (isPreview) {
      return
    }

    fetchRuleById(currentOutwardScenario!.id, props.ruleBeingEditedId).then((savedVersionOfRule) => {
      if (!savedVersionOfRule) {
        return
      }
      setShouldValidate(false)
      setName(savedVersionOfRule.name)
      setDescription(savedVersionOfRule.description || '')
    })
  }

  const updatePreviewInput = () => {
    const existingOne = (rules ? rules : []).find(isRelevantRule(props.ruleBeingEditedId))!
    const ruleWithoutCurrentOne = (rules ? rules : []).filter(isIrrelevantRule(props.ruleBeingEditedId))

    const ruleToShow: Rule = {
      id: isPreview ? RULE_PREVIEW_ID : props.ruleBeingEditedId,
      name: isPreview ? currentName : currentName || existingOne.name,
      description: isPreview ? currentDescription : currentDescription || existingOne.description,
      appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
      updatedDate: isPreview ? 'not-yet-updated' : existingOne.updatedDate,
      orderNumber: isPreview ? Number.MAX_SAFE_INTEGER : existingOne.orderNumber,
      isEnabled: true,
    }

    changeRules([...ruleWithoutCurrentOne, ruleToShow])
  }
  useEffect(updateBasedOnNewScenario, [currentOutwardScenario, props.ruleBeingEditedId, isPreview])
  // eslint-disable-next-line
  useEffect(updatePreviewInput, [props.ruleBeingEditedId, dropdownFilterOptions])

  async function validateThenCreateOrUpdateRule() {
    setShouldValidate(true)

    if (!isValidName(currentName)) {
      return
    }

    const checkBackendResponse = (response: object) => {
      if (JSON.stringify(response).includes('Rule name already exist for outward scenario')) {
        setErrorUniqueName('Unique name is required')
        throw Error
      }
    }

    if (isPreview) {
      await createRule(currentOutwardScenario!.id, {
        name: currentName,
        description: currentDescription,
        appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
      }).then((response) => {
        checkBackendResponse(response)
        cancelEditing()
      })
      await saveOrUpdateListOfApplicableMarkets(currentOutwardScenario!.id, { applicableMarkets: applicableMarkets })
    } else {
      await patchRule(currentOutwardScenario!.id, props.ruleBeingEditedId, {
        name: currentName,
        description: currentDescription,
        appliedFilters: transformIntoFlatStructure(dropdownFilterOptions),
      }).then((response) => {
        checkBackendResponse(response)
        cancelEditing()
      })
      await saveOrUpdateListOfApplicableMarkets(currentOutwardScenario!.id, { applicableMarkets: applicableMarkets })
    }
  }

  const cancelEditing = () => {
    props.reloadSavedRulesAndResetRulesPage ? props.reloadSavedRulesAndResetRulesPage() : void 0

    setName('')
    setShouldValidate(false)
    setErrorUniqueName('')
    triggerReloadSavedRules()
  }

  return (
    <div className="CreateRuleCard">
      <Card>
        <h4 className="CreateRuleCardTitle">{isPreview ? 'Create' : 'Edit'} Adjustment</h4>
        <div className="RuleName">
          <TextField
            title="Name"
            placeholder="Name"
            value={currentName}
            onChange={onInputUpdateState(setName)}
            error={shouldValidate ? (isValidName(currentName) ? '' : 'Please enter a name for the rule') : ''}
            className="CreateRuleCardName"
          />
        </div>
        <div className="RuleDescription">
          <TextArea
            title="Description"
            placeholder="Description"
            value={currentDescription}
            onChange={onTextAreaUpdateState(setDescription)}
            className="CreateRuleCardDescription"
          />
        </div>
        <div className="ButtonContainer">
          {!isPreview && (
            <Button
              title="Cancel"
              secondary
              onClick={cancelEditing}
            />
          )}
          <Button
            title={addOrSaveWord}
            onClick={validateThenCreateOrUpdateRule}
          />
        </div>
        {shouldValidate && errorUniqueName.length > 0 && <ErrorText error={errorUniqueName} />}
      </Card>
    </div>
  )
}
