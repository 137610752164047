import { DetailedHTMLProps, InputHTMLAttributes, ReactElement, useEffect, useRef } from 'react'
import { css } from '../../utils/css'
import './WFTextField.scss'
import { Asterisk } from '../asterisk/Asterisk'
import { ignoreNanForNumbers } from '../../utils/ignoreNanForNumbers'

interface TextFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  percentage?: boolean
  titleHeading?: ReactElement
  warning?: string
  error?: string | undefined | null | false
  validationError?: string
}

export function TextField(props: TextFieldProps): ReactElement {
  const { percentage, titleHeading, warning, error, validationError, ...otherProps } = props
  const realValue = ignoreNanForNumbers(props.value)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    inputRef.current?.setCustomValidity(validationError ?? '')
    if (validationError) {
      inputRef.current?.reportValidity()
    }
  }, [validationError])

  const heading = titleHeading ?? (
    <p className="Title">
      {props.title} <Asterisk visible={!!props.required} />
    </p>
  )

  return (
    <div className={css('WFTextField', props.className)}>
      {heading}
      <div className={props.title && percentage ? 'SuffixWithTitle' : '' || percentage ? 'SuffixWithoutTitle' : ''}>
        <input
          {...otherProps}
          ref={inputRef}
          value={realValue}
          className={css('Input', warning ? 'Warning' : '', error ? 'Error' : '')}
        />
      </div>
      {warning && <p className="WarningText">{warning}</p>}
      {error && <p className="ErrorText">{error}</p>}
    </div>
  )
}

export default TextField
