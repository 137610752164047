import React from 'react'
import './SelectScenarioMessage.scss'

export type SelectScenarioMessageProps = React.PropsWithChildren<{}>

export function SelectScenarioMessage(props: SelectScenarioMessageProps) {
  return (
    <div className="SelectScenarioMessage">
      <p className="Title">No Scenario selected</p>
      <p className="Message">Select a Scenario to view it's data and edit it.</p>
    </div>
  )
}

export default SelectScenarioMessage
