import React, { useContext, useEffect, useState } from 'react'
import Big from 'big.js'
import ReactModal from 'react-modal'
import { StringParam, useQueryParam } from 'use-query-params'

import './StackedScenarioParameterisationRequestContainer.scss'

import Divider from '../divider/LineDivider'
import SectionHeader from '../section-header/SectionHeader'
import Button from '../button/Button'
import {
  CreateTeamParameterisationRankingRequest,
  createTeamParameterisationRequest,
  CreateTeamParameterisationRequestBody,
  DefaultParameterData,
  MarketName,
  MatrixByMarketContent,
} from '../../backend/stacked-scenario-parameterisation'
import { LossToggle } from '../../providers/parameterisation/ParameterisationDataProvider'
import SegmentedControlSwitch from '../segmented-control/SegmentedControl'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import {
  handlePercentageAndOutputString,
  isZero,
  numericValueIsDefined,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  ZERO,
} from '../../utils/numbers'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { OptionsType } from '../../utils/lists'
import {
  MarketDependencySelector,
  Matrix,
  PartialMatrixLine,
} from '../parameterisation-request-section/MarketDependencySelector'
import { TeamParameterisationDataContext } from '../../providers/parameterisation/TeamParameterisationDataProvider'
import { calculateHowToGetValueForMarketData } from '../parameterisation-table/team-parameterisation-market-summary-table'
import {
  AttritionalDependencyShorthand,
  MarketInputByMarketInput,
  MarketInputDependency,
  ParameterInputs,
  ParameterisationByIdResponse,
  TcsStatus,
} from '../../backend/parameterisation'
import { ParameterisationDefaultTeamMarketDataContext } from '../../providers/parameterisation/TeamParameterisationDefaultMarketDataProvider'
import { rootDom } from '../../'
import { OverrideDistribution } from '../override-distribution/OverrideDistribution'
import { StackedScenario } from '../../backend/stacked-scenarios'
import { allUnderlyingHaveRun } from '../../pages/stacked-scenario-parameterisation/StackedScenarioParameterisationContent'
import { NoteSections } from '../../pages/notes/NotesContent'
import { NotesDataContext } from '../../pages/notes/NotesDataProvider'

export enum StackedParamTableDisplayOptions {
  TEAM_DISTRIBUTION = 'TEAM_DISTRIBUTION',
  MARKET_SUMMARY = 'MARKET_SUMMARY',
  ATTRITIONAL_COMPARISON = 'ATTRITIONAL_COMPARISON',
  SINGLE_RISK_OEP_COMPARISON = 'SINGLE_RISK_OEP_COMPARISON',
  SINGLE_RISK_AEP_COMPARISON = 'SINGLE_RISK_AEP_COMPARISON',
  COMBINED_COMPARISON = 'COMBINED_COMPARISON',
  SINGLE_RISK_FREQUENCY_COMPARISON = 'SINGLE_RISK_FREQUENCY_COMPARISON',
}

const displayForDisplayOption: Record<StackedParamTableDisplayOptions, string> = {
  TEAM_DISTRIBUTION: 'Team Distribution',
  MARKET_SUMMARY: 'Market Summary',
  ATTRITIONAL_COMPARISON: 'Attritional Comparison',
  SINGLE_RISK_OEP_COMPARISON: 'Single-risk OEP Comparison',
  SINGLE_RISK_AEP_COMPARISON: 'Single-risk AEP Comparison',
  COMBINED_COMPARISON: 'Combined Comparison',
  SINGLE_RISK_FREQUENCY_COMPARISON: 'Single-risk Frequency Comparison',
}

export function attritionalInputValueValidation(
  attritionalFirstValue: OptionsType,
  attritionalSecondValue: OptionsType,
) {
  if (!numericValueIsDefined(attritionalFirstValue.value) || !numericValueIsDefined(attritionalSecondValue.value)) {
    return ''
  }
  if (
    attritionalFirstValue.label.toLowerCase().includes('percentile') &&
    attritionalSecondValue.label.toLowerCase().includes('percentile')
  ) {
    const firstPercentile = Number(
      attritionalFirstValue?.label
        ?.toLowerCase()
        .replace(' percentile', '')
        .replace('st', '')
        .replace('th', '')
        .replace('rd', '')
        .replace('nd', ''),
    )
    const secondPercentile = Number(
      attritionalSecondValue?.label
        ?.toLowerCase()
        .replace(' percentile', '')
        .replace('st', '')
        .replace('th', '')
        .replace('rd', '')
        .replace('nd', ''),
    )
    if (firstPercentile === secondPercentile) {
      return "Percentiles can't match"
    } else if (firstPercentile < secondPercentile) {
      if (attritionalFirstValue.value > attritionalSecondValue.value) {
        return 'Please ensure the value is lower'
      }
    } else {
      if (attritionalFirstValue.value < attritionalSecondValue.value) {
        return 'Please ensure the value is higher'
      }
    }
  }

  return ''
}

const dataToDisplayOptions = Object.entries(StackedParamTableDisplayOptions).map(([value, label]) => ({
  label: displayForDisplayOption[label],
  value,
}))

export function StackedScenarioParameterisationRequestContainer(): JSX.Element {
  const [tableDisplay, setTableDisplayType] = useQueryParam('tableDisplay', StringParam)

  const { triggerSaveOfNotes, notesAreUpToDateWithRemote } = useContext(NotesDataContext)
  const { currentStackedScenario: scenario } = useContext(StackedScenarioContext)
  const {
    parameterisationData,
    underlyingMarketParamData,
    setLossToggle,
    lossToggle,
    setNeedToPollForMoreData,
    dataFilteredByDistributionType,
  } = useContext(TeamParameterisationDataContext)
  const { defaultTeamMarketData } = useContext(ParameterisationDefaultTeamMarketDataContext)

  const [attritionalFirstChange, setAttritionalFirstChange] = useState<OptionsType>({
    label: '50th Percentile',
    value: '',
  })
  const [attritionalSecondChange, setAttritionalSecondChange] = useState<OptionsType>({ label: 'Mean', value: '' })
  const [singleRiskMeanFrequency, setSingleRiskMeanFrequency] = useState<OptionsType>({
    label: 'Frequency',
    value: '',
  })
  const [frequencyVolatilityUplift, setFrequencyVolatilityUplift] = useState<OptionsType>({
    label: 'Freq Volatility Uplift',
    value: '',
  })
  const [openUpdateDependenciesModal, setOpenUpdateDependenciesModal] = useState<boolean>(false)
  const [shouldValidateOverrideInputs, setShouldValidateOverrideInputs] = useState<boolean>(false)
  const [isUpToDateWithRemote, setIsUptoDate] = useState<boolean>(true)
  const [isOverrideToggleEnabled, setOverrideToggleEnabled] = useState<boolean>(false)
  const [initialMatrix, setInitialMatrix] = useState<undefined | Matrix>(undefined)
  const [requestMatrix, setRequestMatrix] = useState<undefined | Matrix>(undefined)

  useEffect(() => {
    if (!parameterisationData?.result) {
      const nextInitialMatrix: Matrix = toMatrix(defaultTeamMarketData || [])
      setInitialMatrix(nextInitialMatrix)
    } else {
      const matrixUsedLastTime = toMatrix(
        parameterisationData.input?.inputs?.corrMatrixbyMarketMarket
          ?.filter((item) => (item.value as any) !== '1')
          .map((item) => ({
            dependency: item.value,
            market_1: item.correl1,
            market_2: item.correl2,
            team: scenario?.team!,
            year: scenario?.year!,
            division: scenario?.division!,
          })) || [],
      )
      setInitialMatrix(matrixUsedLastTime)
    }
    //eslint-disable-next-line
  }, [scenario, defaultTeamMarketData, parameterisationData?.result])

  const resetSRMeanOVerrideValue = () => {
    if (singleRiskMeanFrequency.label === 'Frequency') {
      const allSingleRiskFrequencies = parameterisationData?.input?.inputs?.marketInputByMarketInput.filter(
        (inputItem) => inputItem.type === 'SRFrequency_Mean',
      )
      let valueToUse = allSingleRiskFrequencies?.reduce((subTotal, next) => subTotal.plus(next.value), ZERO) || ZERO

      if (parameterisationData?.result) {
        const valueFromResult = parameterisationData?.result?.resultbyDistributionbyLossType?.find(
          (item) => item.stat === 'Mean' && item.distribution === 'Single-risk Frequency',
        )?.value
        if (valueFromResult) {
          valueToUse = new Big(valueFromResult)
        }
      }

      if (!isZero(valueToUse)) {
        setSingleRiskMeanFrequency({ label: singleRiskMeanFrequency.label, value: valueToUse.toFixed(2) })
      }
    }
  }

  // eslint-disable-next-line
  useEffect(resetSRMeanOVerrideValue, [parameterisationData?.result])

  const resetAttritionalOverrideValue = () => {
    if (attritionalFirstChange.label === '50th Percentile') {
      const valueToUse = parameterisationData?.result.resultbyDistributionbyLossType.find(
        (item) => item.lossType === 'Loss Ratio' && item.distribution === 'Attritional' && item.stat?.includes('50'),
      )
      if (valueToUse) {
        setAttritionalFirstChange({
          label: attritionalFirstChange.label,
          value: new Big(valueToUse.value).mul(ONE_HUNDRED).toFixed(2),
        })
      }
    }
  }

  // eslint-disable-next-line
  useEffect(resetAttritionalOverrideValue, [parameterisationData?.result])

  function handleSetMatrix(matrix: Matrix): void {
    setRequestMatrix(matrix)
    setIsUptoDate(false)
  }

  function handleToggleOverride(): void {
    setOverrideToggleEnabled((toggle) => !toggle)
  }

  async function validateThenCreateParameterisation(): Promise<void> {
    if (!scenario) {
      throw new Error('Missing currentStackedScenario')
    }
    if (!allUnderlyingHaveRun(underlyingMarketParamData)) {
      // if (!allUnderlyingHaveRun(underlyingMarketParamData, allHaveRan)) {
      throw new Error('At least one sub-market parameterisation hasnt run')
    }
    try {
      const request: CreateTeamParameterisationRequestBody = calculateCreateRequest(
        initialMatrix,
        requestMatrix,
        underlyingMarketParamData,
        scenario,
      )
      if (isOverrideToggleEnabled) {
        setShouldValidateOverrideInputs(true)
        const hasError =
          [attritionalFirstChange, attritionalSecondChange, singleRiskMeanFrequency, frequencyVolatilityUplift]
            .map((attritionalOption) =>
              calculateSingleRiskMeanFirstPercentageBoxError(
                attritionalOption.label,
                attritionalOption.value,
                underlyingMarketParamData,
              ),
            )
            .some(Boolean) || attritionalInputValueValidation(attritionalFirstChange, attritionalSecondChange)
        if (hasError) {
          console.log('Validation error occurred')
          return
        }
        const overrideRequest = createOverride(
          request,
          attritionalFirstChange,
          attritionalSecondChange,
          singleRiskMeanFrequency,
          frequencyVolatilityUplift,
        )
        await createTeamParameterisationRequest(scenario.id, overrideRequest)
        setNeedToPollForMoreData(TcsStatus.INPROGRESS)
        setIsUptoDate(true)
        return
      }
      await createTeamParameterisationRequest(scenario.id, request)
      setNeedToPollForMoreData(TcsStatus.INPROGRESS)
      setIsUptoDate(true)
    } catch (e) {
      console.error(e)
    }
  }

  async function saveComments() {
    triggerSaveOfNotes()
  }

  return (
    <div className="TeamParameterisationRequestContainer">
      <SectionHeader title="Parameter Control" />

      <Divider />
      <SectionHeader title="Data to Display" />
      <MultiSelectDropdown
        onSelect={([option]) => {
          setTableDisplayType(option.value)
        }}
        options={dataToDisplayOptions}
        selected={[dataToDisplayOptions.find((item) => item.value === tableDisplay) || dataToDisplayOptions[0]]}
        hasMultipleSelection={false}
      />

      <Divider />
      {tableDisplay !== StackedParamTableDisplayOptions.MARKET_SUMMARY &&
        tableDisplay !== StackedParamTableDisplayOptions.SINGLE_RISK_FREQUENCY_COMPARISON && (
          <>
            <SectionHeader title="Distribution Type" />
            <SegmentedControlSwitch
              options={[
                { name: 'Loss Ratio', value: 'Loss Ratio' },
                { name: 'Loss Value', value: 'Loss Value' },
              ]}
              selectedOption={lossToggle === 'Loss Ratio' ? 0 : 1}
              onChange={(x) => setLossToggle(x as LossToggle)}
              className="ColumnAndValueFilterSegmentedControl"
            />
            <Divider />
          </>
        )}

      <SectionHeader title="Market Dependencies" />
      <Button
        title="Update Dependencies"
        onClick={() => setOpenUpdateDependenciesModal(true)}
      />
      <ReactModal
        appElement={rootDom}
        isOpen={openUpdateDependenciesModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <MarketDependencySelector
          initialMatrix={(requestMatrix ?? initialMatrix) || []}
          handleSetMatrix={handleSetMatrix}
          ok={() => setOpenUpdateDependenciesModal(false)}
          cancel={() => setOpenUpdateDependenciesModal(false)}
        />
      </ReactModal>

      <OverrideDistribution
        one={attritionalFirstChange}
        setOne={setAttritionalFirstChange}
        two={attritionalSecondChange}
        setTwo={setAttritionalSecondChange}
        three={singleRiskMeanFrequency}
        setThree={setSingleRiskMeanFrequency}
        four={frequencyVolatilityUplift}
        setFour={setFrequencyVolatilityUplift}
        dataFilteredByDistributionType={dataFilteredByDistributionType}
        handleToggleOverride={handleToggleOverride}
        isOverrideToggleEnabled={isOverrideToggleEnabled}
        setIsUptoDate={setIsUptoDate}
        shouldValidateOverrideInputs={shouldValidateOverrideInputs}
      />

      <Divider />
      <div className="SaveContainer">
        <div className={isUpToDateWithRemote ? 'SaveContainerTitle' : 'UnsaveContainerTitle'}>
          {isUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}
        </div>
        <div className="ButtonsContainer">
          <Button
            title="Save and run"
            onClick={validateThenCreateParameterisation}
          />
        </div>
      </div>

      <Divider />
      <NoteSections />
      <div className="SaveContainer">
        <div className={notesAreUpToDateWithRemote ? 'SaveContainerTitle' : 'UnsaveContainerTitle'}>
          {notesAreUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}
        </div>
        <div className="ButtonsContainer">
          <Button
            title="Save comments"
            onClick={saveComments}
          />
        </div>
      </div>
    </div>
  )
}

function createOverride(
  request: CreateTeamParameterisationRequestBody,
  one: OptionsType,
  two: OptionsType,
  three: OptionsType,
  four: OptionsType,
): CreateTeamParameterisationRankingRequest {
  const firstAttritionalOverride = numericValueIsDefined(one.value)
    ? [
        {
          stat: one.label,
          percentile: getPercentileValueFromStat(one.label),
          value: new Big(handlePercentageAndOutputString(one.value)).div(ONE_HUNDRED).toNumber(),
        },
      ]
    : []

  const secondAttritionalOverride = numericValueIsDefined(two.value)
    ? [
        {
          stat: two.label,
          percentile: getPercentileValueFromStat(two.label),
          value: new Big(handlePercentageAndOutputString(two.value)).div(ONE_HUNDRED).toNumber(),
        },
      ]
    : []

  const isMeanOverride = two.label.toLowerCase().includes('mean') && numericValueIsDefined(two.value)

  const overrideRequestInputs = {
    tycheInAttritionalMeanOverride: isMeanOverride,
    tycheInAttritionalOverrides: [...firstAttritionalOverride, ...secondAttritionalOverride],
    tycheInAttritionalMeanOverrideValue: isMeanOverride
      ? new Big(handlePercentageAndOutputString(two.value)).div(ONE_HUNDRED).toNumber()
      : 0,
    tycheInSRMeanOverride: numericValueIsDefined(three.value)
      ? [
          {
            distribution: three.label,
            value: new Big(handlePercentageAndOutputString(three.value)).toNumber(),
          },
        ]
      : [],
    tycheInSRFreqVolatilityScaling: numericValueIsDefined(four.value)
      ? new Big(handlePercentageAndOutputString(four.value)).div(ONE_HUNDRED).toNumber()
      : 0,
  }
  return {
    ...request,
    inputs: {
      ...request.inputs,
      ...overrideRequestInputs,
    },
  }
}

function scrubHeadingsFromMatrix(matrix: Matrix): Matrix {
  return matrix.filter((x) => x.status === 'enabled' || x.status === 'hidden')
}

function calculateCreateRequest(
  initialMatrix: undefined | Matrix,
  requestMatrix: undefined | Matrix,
  underlyingMarketParamData: ParameterisationByIdResponse[],
  currentStackedScenario: undefined | StackedScenario,
): CreateTeamParameterisationRequestBody {
  if (!initialMatrix) {
    throw new Error('Missing initialMatrix')
  }
  // First check whether the modal has been modified, or secondly, fallback to the initial unmodified matrix
  const rawMatrix: Matrix = requestMatrix ?? initialMatrix
  const matrix: Matrix = scrubHeadingsFromMatrix(rawMatrix)
  if (!matrix) {
    throw new Error(`Missing matrix`)
  }

  const marketNames: MarketName[] = underlyingMarketParamData
    .map((item) => item.input.inputs.marketNameSelected)
    .filter(Boolean)
    .map((marketName) => ({ value: marketName }))

  const corrMatrixbyMarketMarket: MatrixByMarketContent[] = matrix
    .filter(Boolean)
    .filter((x) => Boolean(x.item))
    .map((matrixLine) => {
      const { item } = matrixLine
      if (!item) {
        throw new Error(`Missing item`)
      }
      return {
        correl1: item.market_1,
        correl2: item.market_2,
        value: item.dependency as AttritionalDependencyShorthand,
      }
    })

  const marketInputDependency: MarketInputDependency[] = underlyingMarketParamData
    .map((item) => item.input.inputs.marketInputDependency[0].value as AttritionalDependencyShorthand)
    .map((value) => ({ value }))

  return {
    inputs: {
      exportToDB: 'TRUE',
      marketInputByMarketInput: underlyingMarketParamData.filter(Boolean).flatMap((underlyingMarketData) => {
        const market: string = underlyingMarketData.input.inputs.marketNameSelected
        const valueFetcher = makeValueFetcher(underlyingMarketParamData, market)
        return [
          {
            market,
            type: 'GWP',
            value: valueFetcher('GWP'),
          },
          {
            market,
            type: 'Max',
            value: valueFetcher('Max'),
          },
          {
            market,
            type: 'LR_Total',
            value: valueFetcher('LR_Total'),
          },
          {
            market,
            type: 'LR_Attritional',
            value: valueFetcher('LR_Attritional'),
          },
          {
            market,
            type: 'LR_PerilTotal',
            value: valueFetcher('LR_PerilTotal'),
          },
          {
            market,
            type: 'Threshold',
            value: valueFetcher('Threshold'),
          },
          {
            market,
            type: 'SRFrequency_Mean',
            value: valueFetcher('SRFrequency_Mean'),
          },
        ]
      }),
      marketInputDependency,
      paramClass: currentStackedScenario?.team || 'Example',
      marketNames,
      corrMatrixbyMarketMarket,
    },
    callback_url: null,
  }
}

export function calculateOverrideInputWarning(inputValue: string | PossiblyNegativeNumber): string | undefined {
  const input = new Big(inputValue || 0).div(ONE_HUNDRED)
  if (input.cmp(ZERO) < 0) {
    return 'This must be greater than 0'
  }
}

export function calculateOverrideInputError(inputValue: string | PossiblyNegativeNumber): string | undefined {
  const input = new Big(inputValue || 0).div(ONE_HUNDRED)
  if (!numericValueIsDefined(inputValue)) {
    return ''
  }
  if (input.cmp(ZERO) < 0) {
    return 'This must be greater than 0'
  }
}

export function getHighestItemFromUnderlyingMarketValues(
  underlyingMarketParamData: Array<null | ParameterisationByIdResponse>,
  statToSum: keyof ParameterInputs,
): number {
  const allItemsFromUnderlyingMarkets: number[] = underlyingMarketParamData
    .map(
      (underlyingMarketInput) =>
        underlyingMarketInput?.input?.inputs?.marketInputByMarketInput?.find((item) => item.type === statToSum),
    )
    .filter((item) => Boolean(item))
    .map((item) => item as MarketInputByMarketInput) //Only needed as the above nullcheck doesn't type properly
    .map((item) => item.value)
  return Math.max.apply(Math, allItemsFromUnderlyingMarkets)
}

export function calculateSingleRiskMeanFirstPercentageBoxError(
  chosenStat: string,
  inputValue: string | PossiblyNegativeNumber,
  underlyingMarketParamData: Array<null | ParameterisationByIdResponse>,
): string | undefined {
  if (chosenStat === 'Severity') {
    try {
      const currentValue = new Big(inputValue as any)

      const highestThreshold = getHighestItemFromUnderlyingMarketValues(underlyingMarketParamData, 'Threshold')
      const highestMaxLine = getHighestItemFromUnderlyingMarketValues(underlyingMarketParamData, 'Max')

      const isHigherThanThreshold = currentValue.toNumber() > highestThreshold
      const isLowerThanMax = currentValue.toNumber() < highestMaxLine

      if (!isHigherThanThreshold && !isLowerThanMax) {
        return `Must be between the Threshold (${highestThreshold}) and Max Line ${highestMaxLine}`
      } else if (!isHigherThanThreshold) {
        return `Must be higher than the threshold (${highestThreshold})`
      } else if (!isLowerThanMax) {
        return `Must be lower than the Max (${highestMaxLine})`
      }
    } catch (e) {
      return 'Invalid entry'
    }
  }

  return calculateOverrideInputError(inputValue)
}

function getPercentileValueFromStat(stat: string): number {
  if (stat === 'SD' || stat === 'CoV') {
    return -1
  }
  const numberExtracted = stat.replace(/[^0-9.]/g, '')
  return parseFloat(numberExtracted) / 100
}

function makeValueFetcher(underlyingMarketParamData: ParameterisationByIdResponse[], marketName: string) {
  return (columnName: string): number => {
    const result = calculateHowToGetValueForMarketData(underlyingMarketParamData)(marketName, columnName)
    if (!result && result !== 0) {
      throw new Error('Missing lookup')
    }
    return result
  }
}

function toMatrix(array: DefaultParameterData[]): Matrix {
  const headingList: string[] = Array.from(new Set([...array.map((x) => x.market_1), ...array.map((x) => x.market_2)]))
  const matrix: PartialMatrixLine[] = []
  for (let r = 0; r < headingList.length; r++) {
    const rowHeading = headingList[r]
    for (let c = 0; c < headingList.length; c++) {
      const colHeading = headingList[c]

      // Row Headings
      if (r === 0) {
        const label = headingList[c]
        const row = 1
        const column = c + 2
        matrix.push({ row, column, label, item: undefined, status: 'row-heading' })
      }

      // Column headings
      if (c === 0) {
        const label = headingList[r]
        const row = r + 2
        const column = 1
        matrix.push({ row, column, label, item: undefined, status: 'col-heading' })
      }

      // Hidden "diagonal" items
      if (r === c) {
        // Populate item with stub data
        const item = { ...array[0] }
        // Assign diagonal to what the required value:
        item.dependency = '1'
        item.market_1 = colHeading
        item.market_2 = colHeading

        const row = r + 2
        const column = c + 2

        matrix.push({ row, column, item, status: 'hidden' })
      }

      // Cell items WITH defined values
      const foundIndex = array.findIndex((a) => a.market_1 === rowHeading && a.market_2 === colHeading)
      if (foundIndex >= 0) {
        // Push into new array
        const item = array[foundIndex]
        // console.log(item)
        const row = r + 2
        const column = c + 2
        // Add the first item
        matrix.push({ row, column, item, status: 'disabled' })
        // Add the inverse
        matrix.push({ row: column, column: row, item, status: 'enabled' })

        // This is purely for optimisation. If the formula changes then this may need to be commented out when debugging:
        // Pop from list because we don't need this anymore, and saves additional lookup time
        array.splice(foundIndex, 1)
      }
    }
  }

  const completeMatrixWithKey: Matrix = matrix.map((x, i) => ({ ...x, key: i }))
  return completeMatrixWithKey
}
