import { FieldValues } from 'react-hook-form'

export const sumAllFormValues: <T extends FieldValues>(values: T) => number = (values) => {
  const ownPropNames = Object.getOwnPropertyNames(values)
  if (ownPropNames.length === 1 && Array.isArray(values[ownPropNames[0]])) {
    return sumDynamicFormValues(values[ownPropNames[0]])
  }
  return sumStaticFormValues(values)
}

const sumStaticFormValues: <T extends FieldValues>(values: T) => number = (values) => {
  let totalValue = 0
  for (const field in values) {
    const value = values[field]
    totalValue += Number.isNaN(value) ? 0 : value
  }
  return totalValue
}

const sumDynamicFormValues: <T extends { value: number }>(values: T[]) => number = (values) => {
  return values.reduce((acc, value) => acc + (Number.isNaN(value.value) ? 0 : value.value), 0)
}
