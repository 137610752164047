import React, { useContext, useState } from 'react'

import {
  WaterfallGwpAddedPerAdjustmentConsumer,
  WaterfallGwpAddedPerAdjustmentProvider,
} from './WaterfallGwpAddedPerAdjustmentProvider'
import WaterfallChart from './WaterfallChart'
import { MultiSelectDropdown } from '../../multi-select-dropdown/MultiSelectDropdown'
import { onSelectSingleValueFromMulti } from '../../../pages/scenario-chooser/ScenarioChooser'
import { GraphFilterDataContext } from '../GraphFilterDataProvider'
import { GraphHeader } from '../../graph-header/GraphHeader'
import { toOption } from '../../../utils/lists'

export function AdjutedGwpWaterfalSplitByColumnFilter(props: { columnToDefaultTo?: string }) {
  const [currentGraphColumn, setCurrentGraphColumn] = useState(props.columnToDefaultTo || 'A1 Segment')

  const { possibleStringColumnValues } = useContext(GraphFilterDataContext)

  return (
    <>
      <GraphHeader title="Adjustment Mix Impact" />
      <MultiSelectDropdown
        onSelect={onSelectSingleValueFromMulti(setCurrentGraphColumn)}
        options={possibleStringColumnValues.map(toOption)}
        selected={[toOption(currentGraphColumn)]}
      />
      <WaterfallGwpAddedPerAdjustmentProvider columnToSplitBy={currentGraphColumn}>
        <WaterfallGwpAddedPerAdjustmentConsumer>
          {(value) => (
            <div className="Graph">
              <WaterfallChart data={value.waterfallChartData} />
            </div>
          )}
        </WaterfallGwpAddedPerAdjustmentConsumer>
      </WaterfallGwpAddedPerAdjustmentProvider>
    </>
  )
}
