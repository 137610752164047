import { numericValueIsDefined, PossiblyNegativeNumber } from '../../../utils/numbers'
import { ManyDropdownFilterValues } from '../../../providers/FilterProvider/model'
import { CLIENT_NAME_FILTER_ID, CLIENT_PARENT_FILTER_ID } from '../../../pages/client/filterIds'
import {
  INVALID_PERCENTAGE_RANGE_ERROR,
  LESS_THAN_NEGATIVE_100_ERROR,
  MORE_THAN_100_WARNING,
  MUST_HAVE_A_VALUE_ERROR,
} from './messages'

// Agnostics

const calculateMoreThan100 = (percentageChange: PossiblyNegativeNumber) => {
  if (percentageChange && typeof percentageChange === 'number' && percentageChange > 100) {
    return MORE_THAN_100_WARNING
  }
  return undefined
}

const calculateLessThanNegative100 = (percentageChange: PossiblyNegativeNumber) => {
  if (percentageChange && typeof percentageChange === 'number' && percentageChange < -100) {
    return LESS_THAN_NEGATIVE_100_ERROR
  }

  return undefined
}

const calculateCorrectRange = (percentageChange: PossiblyNegativeNumber) => {
  if (percentageChange && typeof percentageChange === 'number' && (percentageChange < 0 || percentageChange > 100)) {
    return INVALID_PERCENTAGE_RANGE_ERROR
  }

  return undefined
}

// Named

export const calculatePureGrossRateChangeWarning = (currentPureGrossRateChange: PossiblyNegativeNumber) => {
  return calculateMoreThan100(currentPureGrossRateChange)
}

export const calculatePureGrossRateChangeError = (currentPureGrossRateChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentPureGrossRateChange)) return MUST_HAVE_A_VALUE_ERROR
  return calculateLessThanNegative100(currentPureGrossRateChange)
}

export const calculateRiskAdjustedPremiumRateChangeWarning = (
  currentRiskAdjustedPremiumRateChange: PossiblyNegativeNumber,
) => {
  return calculateMoreThan100(currentRiskAdjustedPremiumRateChange)
}

export const calculateAcquisitionCostChangeError = (currentAcquisitionCostChange: PossiblyNegativeNumber) => {
  return calculateCorrectRange(currentAcquisitionCostChange)
}

export const calculateRiskAdjustedPremiumRateChangeError = (
  currentRiskAdjustedPremiumRateChange: PossiblyNegativeNumber,
) => {
  if (!numericValueIsDefined(currentRiskAdjustedPremiumRateChange)) return MUST_HAVE_A_VALUE_ERROR
  return calculateLessThanNegative100(currentRiskAdjustedPremiumRateChange)
}

export const calculateConvexShareChangeWarning = (currentConvexShareChange: PossiblyNegativeNumber) => {
  return calculateMoreThan100(currentConvexShareChange)
}

export const calculateConvexShareChangeErrorRealTime = (currentConvexShareChange: PossiblyNegativeNumber) => {
  return calculateLessThanNegative100(currentConvexShareChange)
}

export const calculateConvexShareChangeError = (currentConvexShareChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentConvexShareChange)) return MUST_HAVE_A_VALUE_ERROR

  return undefined
}

export const calculateRetentionChangeErrorRealTime = (currentRetentionChange: PossiblyNegativeNumber) => {
  return calculateCorrectRange(currentRetentionChange)
}

export const calculateRetentionChangeError = (currentRetentionChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentRetentionChange)) return MUST_HAVE_A_VALUE_ERROR

  return calculateCorrectRange(currentRetentionChange)
}

export const calculateErrorIfIsClientSpecificAndNoClientSelected = (
  adjustmentType: string,
  filterOptions: ManyDropdownFilterValues,
) => {
  if (adjustmentType !== 'CLIENT_SPECIFIC') {
    return undefined
  }

  const clientSpecificFilter = filterOptions[CLIENT_NAME_FILTER_ID]
  const clientParentSpecificFilter = filterOptions[CLIENT_PARENT_FILTER_ID]
  if (!clientSpecificFilter?.chosenValue && !clientParentSpecificFilter?.chosenValue) {
    return 'Please select a client first using the filters'
  }

  return undefined
}
