import React, { MouseEventHandler, SVGProps } from 'react'

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => {
  const onClick: MouseEventHandler<SVGSVGElement> = (event) => {
    if (props.onClick) {
      event.stopPropagation()
      const areYouSure = window.confirm('Are you sure?')
      if (areYouSure) {
        props.onClick(event)
      }
    }
  }

  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        onClick={onClick}
      >
        <path
          fill="#4E566B"
          fillRule="evenodd"
          d="M10.6666667,6 L10.6666667,12.6666667 L5.33333333,12.6666667 L5.33333333,6 L10.6666667,6 M9.66666667,2 L6.33333333,2 L5.66666667,2.66666667 L3.33333333,2.66666667 L3.33333333,4 L12.6666667,4 L12.6666667,2.66666667 L10.3333333,2.66666667 L9.66666667,2 Z M12,4.66666667 L4,4.66666667 L4,12.6666667 C4,13.4 4.6,14 5.33333333,14 L10.6666667,14 C11.4,14 12,13.4 12,12.6666667 L12,4.66666667 Z"
        />
      </svg>
    </div>
  )
}

export default DeleteIcon
