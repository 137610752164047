import React, { SVGProps } from 'react'

const NewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#4E566B"
      fillRule="evenodd"
      d="M10.2666667,1.02666667 L11.5266667,3.14666667 L13.9333333,3.69333333 L13.7066667,6.14666667 L15.3333333,8 L13.7066667,9.85333333 L13.9333333,12.3066667 L11.5266667,12.8533333 L10.2666667,14.9733333 L8,14 L5.73333333,14.98 L4.47333333,12.86 L2.06666667,12.3133333 L2.29333333,9.85333333 L0.666666667,8 L2.29333333,6.14 L2.06666667,3.68666667 L4.47333333,3.14666667 L5.73333333,1.02666667 L8,2 L10.2666667,1.02666667 Z M9.72,2.71333333 L8,3.45333333 L6.28,2.72 L5.32666667,4.32666667 L3.5,4.73333333 L3.67333333,6.58666667 L2.44,8 L3.67333333,9.4 L3.5,11.2666667 L5.32666667,11.68 L6.28,13.2866667 L8,12.5466667 L9.72,13.2866667 L10.6733333,11.68 L12.5,11.2666667 L12.3266667,9.40666667 L13.56,8 L12.3266667,6.59333333 L12.5,4.73333333 L10.6733333,4.32 L9.72,2.71333333 Z M8.6,5 L8.6,7.4 L11,7.4 L11,8.6 L8.6,8.6 L8.6,11 L7.4,11 L7.4,8.6 L5,8.6 L5,7.4 L7.4,7.4 L7.4,5 L8.6,5 Z"
    />
  </svg>
)

export default NewIcon
