import { Component, createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { FilterContext, transformIntoFlatStructure } from './FilterProvider'
import { calculateGrarcKpi, calculateKPIs, filterData } from '../backend/calculate-kpis'
import { FullScenarioDataContext, splitIntoRenewalAndNew } from './FullScenarioData/FullScenarioDataProvider'
import { ScenarioContext } from './ScenarioProvider'
import { RawEntry } from '../backend/rawEntry'
import { StackedScenarioComparisonContext } from './StackedScenarioComparisonProvider'

interface ScenarioComparisonFullDataContainer {
  setDataForScenario: (scenarioId: string, dataForScenario: RawEntry[]) => void
}

export const ScenarioComparisonFullDataContext = createContext<ScenarioComparisonFullDataContainer>({
  setDataForScenario: () => undefined,
})

// TODO this thing should be a hook not a component T_T
export function ScenarioComparisonDataSwallower() {
  const { currentScenario } = useContext(ScenarioContext)
  const { currentStackedScenario } = useContext(StackedScenarioComparisonContext)
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  const { setDataForScenario } = useContext(ScenarioComparisonFullDataContext)

  useEffect(() => {
    if (currentScenario?.id || currentStackedScenario?.id) {
      setDataForScenario((currentScenario?.id || currentStackedScenario?.id)!, fullDataForScenario)
    }
  }, [currentScenario, currentStackedScenario, fullDataForScenario, setDataForScenario])

  return <></>
}

export class ScenarioComparisonFullDataAvailableProvider extends Component<PropsWithChildren> {
  state: Record<string, any[] | undefined> = {}

  updateIndividualScenarioDataItems = (name: string, newState: any[]) => {
    this.setState({ [name]: newState })
  }

  render() {
    return (
      <ScenarioComparisonFullDataContext.Provider
        value={{
          setDataForScenario: this.updateIndividualScenarioDataItems,
        }}
      >
        <ScenarioComparisonFullDataTransformer
          allScenarioData={this.state}
          children={this.props.children}
        />
      </ScenarioComparisonFullDataContext.Provider>
    )
  }
}

function ScenarioComparisonFullDataTransformer(
  props: PropsWithChildren<{ allScenarioData: Partial<Record<string, any[]>> }>,
) {
  const { dropdownFilterOptions } = useContext(FilterContext)
  const fullDataWithActuals: any[] = useMemo(() => {
    return Object.entries(props.allScenarioData).flatMap(
      ([scenarioId, dataItems]) =>
        dataItems?.flatMap((item) => ({
          ...item,
          'WF Scenario Id': scenarioId,
        })),
    )
  }, [props.allScenarioData])
  const filtersToApply = useMemo(() => transformIntoFlatStructure(dropdownFilterOptions), [dropdownFilterOptions])
  const fullRenewalAndNew = useMemo(() => splitIntoRenewalAndNew(fullDataWithActuals), [fullDataWithActuals])
  const fullRenewalKpis = useMemo(() => calculateKPIs(fullRenewalAndNew.renewal), [fullRenewalAndNew])
  const fullNewKpis = useMemo(
    () =>
      calculateKPIs([
        ...fullRenewalAndNew.new,
        ...fullRenewalAndNew.newClient,
        ...fullRenewalAndNew.newCustomPortfolio,
      ]),
    [fullRenewalAndNew.new, fullRenewalAndNew.newClient, fullRenewalAndNew.newCustomPortfolio],
  )

  const filteredDataForScenario = useMemo(
    () => filterData(fullDataWithActuals, filtersToApply),
    [fullDataWithActuals, filtersToApply],
  )
  const filteredRenewalAndNew = useMemo(
    () => splitIntoRenewalAndNew(filteredDataForScenario),
    [filteredDataForScenario],
  )

  const filteredRenewalKpis = useMemo(() => calculateKPIs(filteredRenewalAndNew.renewal), [filteredRenewalAndNew])
  const filteredNewKpis = useMemo(
    () =>
      calculateKPIs([
        ...filteredRenewalAndNew.new,
        ...filteredRenewalAndNew.newClient,
        ...filteredRenewalAndNew.newCustomPortfolio,
      ]),
    [filteredRenewalAndNew.new, filteredRenewalAndNew.newClient, filteredRenewalAndNew.newCustomPortfolio],
  )

  const grarcKpi = useMemo(() => calculateGrarcKpi(fullDataWithActuals), [fullDataWithActuals])

  const newGrarcKpi = useMemo(
    () =>
      calculateGrarcKpi([
        ...fullRenewalAndNew.new,
        ...fullRenewalAndNew.newClient,
        ...fullRenewalAndNew.newCustomPortfolio,
      ]),
    [fullRenewalAndNew.new, fullRenewalAndNew.newClient, fullRenewalAndNew.newCustomPortfolio],
  )

  const renewalGrarcKpi = useMemo(() => calculateGrarcKpi([...fullRenewalAndNew.renewal]), [fullRenewalAndNew.renewal])

  return (
    <FullScenarioDataContext.Provider
      children={props.children}
      value={{
        fullDataForScenario: fullDataWithActuals,
        fullRenewalKpis,
        fullNewKpis,
        fullWeightedAverageKpis: undefined,
        grarcKpi,
        newGrarcKpi,
        renewalGrarcKpi,
        weightedAverageRenewalKpis: undefined,
        filteredDataForScenario,
        filteredRenewalKpis,
        filteredNewKpis,
        filteredNewDataForScenario: filteredRenewalAndNew.new,
        filteredRenewalDataForScenario: filteredRenewalAndNew.renewal,
        filteredNewClientDataForScenario: filteredRenewalAndNew.newClient,
        filteredNewCustomPortfolioDataForScenario: filteredRenewalAndNew.newCustomPortfolio,
        filteredDataToUseInGraph: fullDataWithActuals,
        reloadData: () => undefined,
        setPreviewNewVirtual: () => undefined,
        setPreviewNewClient: () => undefined,
        setPreviewRenewal: () => undefined,
      }}
    />
  )
}
