import React from 'react'
import { css } from '../../utils/css'
import './HeadingWithTitle.scss'

export type TextWithTitleProps = React.PropsWithChildren<{
  title?: string
  value?: string
  className?: string | string[]
  id?: string
}>

export function TextWithTitle(props: TextWithTitleProps) {
  return (
    <div
      className={css('HeadingWithTitle', props.className)}
      title={props.value}
    >
      <div
        className="Heading"
        id={props.id}
      >
        {props.title && <p className="Title">{props.title}</p>}
        {props.value !== undefined ? props.value : '-'}
      </div>
    </div>
  )
}

export default TextWithTitle
