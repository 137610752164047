import { ReactElement, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { WayfinderPaths } from './utils/paths/Paths'
import { setupAmplify } from './utils/jumpcloud-login/AmplifySetup'
import { Redirect, Route } from 'react-router'
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import { AuthContext, AuthProvider, AuthStateContainer } from './providers/AmplifyAuthProvider'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import LandingContainer from './components/landing-container/LandingContainer'
import Landing from './pages/landing/Landing'
import LandingSideBar from './components/landing-side-bar/LandingSideBar'
import TopContainer from './components/top-container/TopContainer'
import { NavigationMenu } from './components/navigation-menu/NavigationMenu'
import { ScenarioProvider } from './providers/ScenarioProvider'
import { ActualsProvider } from './providers/ActualDataChoiceProvider'
import { PortfolioListingProvider } from './providers/PortfolioListingProvider'
import { StackedScenarioProvider } from './providers/StackedScenarioProvider'
import { IsAdminProvider } from './providers/IsAdminProvider'
import { MultipleActualDataChoiceProvider } from './providers/MultipleActualDataChoiceProvider'
import { OutwardsPortfolioListingProvider } from './providers/outwards/OutwardsPortfolioListingProvider'
import OutwardScenarioProvider from './providers/outwards/OutwardScenarioProvider'
import { hotjar } from 'react-hotjar'
import TopNavigationMenu from './components/top-navigation-menu/TopNavigationMenu'
import { ScenarioChooserProvider } from './pages/scenario-chooser/ScenarioChooser'
import { ProgressProvider } from './providers/ProgressProvider'
import { OutwardScenarioChooserProvider } from './pages/outward-scenario-chooser/OutwardScenarioChooser'
import { AppQueryProvider } from './providers/AppQueryProvider'
import { LaunchDarklyProvider } from './providers/LaunchDarklyProvider'
import * as Sentry from '@sentry/browser'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { FullScenarioDataProvider } from './providers/FullScenarioData/FullScenarioDataProvider'
import { FilterProvider } from './providers/FilterProvider'

setupAmplify()

const DecideOnAuth = (component: ReactElement, auth: AuthStateContainer) => {
  if (auth.user) {
    return component
  }

  if (auth.state === 'initial') {
    return <div /> //TODO - Loading content?
  }

  return <Redirect to={'/'} />
}

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

export const BASE_PATH = process.env.PUBLIC_URL || '/'

const HOTJAR_ID = process.env.HOTJAR_ID
const HOTJAR_VERSION = process.env.HOTJAR_VERSION

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // 10%
    replaysOnErrorSampleRate: 1.0, // 100%
    environment: process.env.REACT_APP_ENV,
  })
} catch (err) {
  console.error('App.tsx - Sentry init - ', err)
}

export function App() {
  useEffect(() => {
    if (!HOTJAR_ID) {
      return
    }

    try {
      hotjar.initialize(parseInt(HOTJAR_ID), parseInt(HOTJAR_VERSION || '0'))
    } catch (e) {
      console.error('Error initalizing hotjar', e)
    }
  }, [])

  const theme = extendTheme({
    components: {
      Button: {
        variants: {
          default: {
            color: 'white',
            backgroundColor: '#4D7F71',
            _hover: {
              backgroundColor: '#609F8D',
            },
          },
        },
        defaultProps: {
          variant: 'default',
        },
      },
    },
  })

  return (
    <LaunchDarklyProvider>
      <ChakraProvider theme={theme}>
        <Router basename={BASE_PATH}>
          <AuthProvider>
            <QueryParamProvider
              ReactRouterRoute={Route}
              stringifyOptions={queryStringifyOptions}
            >
              <AppQueryProvider>
                <DndProvider backend={HTML5Backend}>
                  <ProgressProvider>
                    <ScenarioProvider>
                      <StackedScenarioProvider>
                        <OutwardScenarioProvider>
                          <ActualsProvider>
                            <MultipleActualDataChoiceProvider>
                              <PortfolioListingProvider>
                                <OutwardsPortfolioListingProvider>
                                  <ScenarioChooserProvider>
                                    <OutwardScenarioChooserProvider>
                                      <IsAdminProvider>
                                        <FilterProvider>
                                          <FullScenarioDataProvider>
                                            <AllRoutes />
                                          </FullScenarioDataProvider>
                                        </FilterProvider>
                                      </IsAdminProvider>
                                    </OutwardScenarioChooserProvider>
                                  </ScenarioChooserProvider>
                                </OutwardsPortfolioListingProvider>
                              </PortfolioListingProvider>
                            </MultipleActualDataChoiceProvider>
                          </ActualsProvider>
                        </OutwardScenarioProvider>
                      </StackedScenarioProvider>
                    </ScenarioProvider>
                  </ProgressProvider>
                </DndProvider>
              </AppQueryProvider>
            </QueryParamProvider>
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </LaunchDarklyProvider>
  )
}

const AllRoutes = () => {
  const auth = useContext(AuthContext)

  return (
    <Switch>
      {WayfinderPaths.map(({ rightColumnComponent, path }) => (
        <Route
          path={path}
          key={'path-for-component-' + path}
        >
          <TopContainer
            topMenu={<TopNavigationMenu />}
            leftColumm={<NavigationMenu />}
            rightColumn={DecideOnAuth(rightColumnComponent, auth)}
          />
        </Route>
      ))}
      <Route path={'/'}>
        <LandingContainer
          leftColumm={<Landing />}
          rightColumn={<LandingSideBar />}
        />
      </Route>
    </Switch>
  )
}
