import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'

export type OutwardPortofioListingResponse = {
  canWrite: boolean
  listing: OutwardPortfolioListing
}

export type OutwardPortfolioListing = Partial<Record<string, VersionListing>>

export type VersionListing = Partial<Record<string, ProgramListing>>

export type ProgramListing = Partial<Record<string, TreatyListing>>

export type TreatyListing = Partial<Record<string, LayerDetail[]>>

export interface LayerDetail {
  layer: string
  treaty: string
  program: string
  planVersion: string
  year: string
}

type Year = string
type Version = string
type Programme = string

type LayerContainer = Record<Version, LayerDetail[]>

type ProgrammeContainer = Record<Programme, LayerDetail[]>
type VersionContainer = Record<Version, ProgrammeContainer>

type TreatiesForCurrentVersion = Record<Year, LayerContainer>

export type TreatiesForCurrentVersionListing = Partial<TreatiesForCurrentVersion>
export type TreatiesForCurrentProgramListing = Partial<Record<Year, VersionContainer>>

export async function fetchOutwardsPortfolioListing(): Promise<OutwardPortofioListingResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/portfolios/outwards`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject('Invalid request')
  }

  return await response.json()
}
