import React, { SVGProps } from 'react'

const StopPreviewIcon = (props: SVGProps<SVGSVGElement>) => (
  <div>
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g>
          <path d="M0,0 L16,0 L16,16 L0,16 L0,0 Z M0,0 L16,0 L16,16 L0,16 L0,0 Z M0,0 L16,0 L16,16 L0,16 L0,0 Z M0,0 L16,0 L16,16 L0,16 L0,0 Z"></path>
          <path
            d="M8,4 C10.5266667,4 12.78,5.42 13.88,7.66666667 C13.4866667,8.48 12.9333333,9.18 12.2733333,9.74666667 L13.2133333,10.6866667 C14.14,9.86666667 14.8733333,8.84 15.3333333,7.66666667 C14.18,4.74 11.3333333,2.66666667 8,2.66666667 C7.15333333,2.66666667 6.34,2.8 5.57333333,3.04666667 L6.67333333,4.14666667 C7.10666667,4.06 7.54666667,4 8,4 Z M7.28666667,4.76 L8.66666667,6.14 C9.04666667,6.30666667 9.35333333,6.61333333 9.52,6.99333333 L10.9,8.37333333 C10.9533333,8.14666667 10.993358,7.90666667 10.993358,7.66 C11,6.00666667 9.65333333,4.66666667 8,4.66666667 C7.75333333,4.66666667 7.52,4.7 7.28666667,4.76 Z M1.34,2.58 L3.12666667,4.36666667 C2.04,5.22 1.18,6.35333333 0.666666667,7.66666667 C1.82,10.5933333 4.66666667,12.6666667 8,12.6666667 C9.01333333,12.6666667 9.98666667,12.4733333 10.88,12.12 L13.16,14.4 L14.1,13.46 L2.28,1.63333333 L1.34,2.58 Z M6.34,7.58 L8.08,9.32 C8.05333333,9.32666667 8.02666667,9.33333333 8,9.33333333 C7.08,9.33333333 6.33333333,8.58666667 6.33333333,7.66666667 C6.33333333,7.63333333 6.34,7.61333333 6.34,7.58 L6.34,7.58 Z M4.07333333,5.31333333 L5.24,6.48 C5.08666667,6.84666667 5,7.24666667 5,7.66666667 C5,9.32 6.34666667,10.6666667 8,10.6666667 C8.42,10.6666667 8.82,10.58 9.18,10.4266667 L9.83333333,11.08 C9.24666667,11.24 8.63333333,11.3333333 8,11.3333333 C5.47333333,11.3333333 3.22,9.91333333 2.12,7.66666667 C2.58666667,6.71333333 3.26666667,5.92666667 4.07333333,5.31333333 Z"
            id="Shape"
            fill="#4E566B"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  </div>
)

export default StopPreviewIcon
