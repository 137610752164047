import { useContext, useState } from 'react'
import { RULE_PREVIEW_ID } from '../../components/outward-rule-card/OutwardRuleCard'
import { OutwardScenarioRuleContext } from '../../providers/outwards/OutwardScenarioRulesProvider'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioFilterCard } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { OutwardScenarioContext } from '../../providers/outwards/OutwardScenarioProvider'
import { FilterContext } from '../../providers/FilterProvider'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { CreateOutwardRuleCard } from '../../components/create-outward-rule-card/CreateOutwardRuleCard'

function OutwardScenarioCoverageContent() {
  const { triggerReloadSavedRules } = useContext(OutwardScenarioRuleContext)
  const { currentOutwardScenario } = useContext(OutwardScenarioContext)
  const canBeEdited = currentOutwardScenario?.canWrite && currentOutwardScenario?.status !== ScenarioStatus.COMPLETE
  const { onChangeFilters } = useContext(FilterContext)
  const [currentlyEditingRuleId, setRuleIdIsBeingEdited] = useState<string>('')

  const reloadSavedRulesAndResetRulesPage = () => {
    triggerReloadSavedRules()
    setRuleIdIsBeingEdited('')
    onChangeFilters({})
  }

  return (
    <div
      className="OutwardCoverage"
      id="OutwardCoverage"
    >
      <FiltersContainer>
        <ScenarioFilterCard outward />
        {canBeEdited && (!currentlyEditingRuleId || currentlyEditingRuleId === RULE_PREVIEW_ID) && (
          <CreateOutwardRuleCard
            key={'create-rule'}
            ruleBeingEditedId={RULE_PREVIEW_ID}
            reloadSavedRulesAndResetRulesPage={reloadSavedRulesAndResetRulesPage}
          />
        )}
      </FiltersContainer>
    </div>
  )
}

export default OutwardScenarioCoverageContent
