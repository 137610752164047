import React from 'react'
import './MarketV2.scss'
import { MarketV2Wrapper } from './MarketV2Wrapper'

export const MarketV2Path = 'market-editor'

export function MarketV2() {
  return (
    <div className="MarketV2">
      <MarketV2Wrapper />
    </div>
  )
}
