import React from 'react'
import { Card } from '../card/Card'
import MenuIcon from '../icons/MenuIcon'
import './PageHeaderText.scss'
export type PageHeaderTextProps = React.PropsWithChildren<{}>

export function PageHeaderText(props: PageHeaderTextProps) {
  return (
    <div
      className="PageHeaderText"
      {...props}
    >
      <Card>
        <div className="MenuButton">
          <MenuIcon />
        </div>
      </Card>
      <h1>{props.children}</h1>
    </div>
  )
}

export default PageHeaderText
