import { getCurrentConfigSettings } from 'src/utils/config'
import { getAccessToken } from './teams'

export type Currency = {
  id: string
  name: string
  displayName: string
  symbol: string
  createdAt: Date
  updatedAt: Date
}

export type NewCurrency = {
  name: string
  displayName: string
  symbol: string
}

export async function fetchAllCurrencies(): Promise<Currency[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/currencies`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  if (response.status !== 200) {
    return Promise.reject()
  }

  return await response.json()
}

export async function createCurrency(currency: NewCurrency) {
  await fetch(`${getCurrentConfigSettings().apiHost}/currencies`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(currency),
  })
}
