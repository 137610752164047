import { DefaultValues, FieldValues, useForm } from 'react-hook-form'

type Options<T extends FieldValues> = {
  defaultValues: DefaultValues<T>
}

export const useCategoryForm = <T extends FieldValues>(options: Options<T>) => {
  return useForm<T>({
    defaultValues: options.defaultValues,
    mode: 'onBlur',
  })
}
