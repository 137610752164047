import React, { createContext, useContext, useEffect, useState } from 'react'
import { ProgressContext, ProgressState } from '../../../providers/ProgressProvider'
import { convertGroupedDataToGraphFormat, GraphDataContext } from '../GraphDataProvider'
import { RENEWAL_INCEPTION_MONTH_COLUMN_NAME } from '../../../backend/calculate-with-actuals'
import { sortInceptionMonth } from '../gwp-bar-chart/inception-month-graph'
import { groupListOfObjsBy } from '../../../utils/lists'
import { FullScenarioDataContext } from '../../../providers/FullScenarioData/FullScenarioDataProvider'
import { fetchScenario } from '../../../backend/scenarios'
import { fetchStackedScenarioById } from '../../../backend/stacked-scenarios'
import { GwpCumulativeLineChartContainer, GwpCumulativeLineChartProps } from './GwpCumulativeLineChart.types'
import { aggregateCumulativeGwpForEachMonth } from './AggregateCumulativeGwp'
import { convertDataToDataPoint, convertDataToGraphFormat } from './ConvertDataPoints'

const GwpCumulativeLineChartContext = createContext<GwpCumulativeLineChartContainer>({ graphData: [] })

const { Consumer, Provider } = GwpCumulativeLineChartContext

const GwpCumulativeLineChartDataProvider = (props: React.PropsWithChildren<GwpCumulativeLineChartProps>) => {
  const [graphData, setGraphData] = useState<any[]>([])
  const { summarisedData } = useContext(GraphDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  useEffect(() => {
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.LOADING)

    const aggregatedCumulativeGwp = aggregateCumulativeGwpForEachMonth(summarisedData)

    const adjustedGwpDataReadyForGraph = convertDataToDataPoint(aggregatedCumulativeGwp, 'Total Adjusted GWP')
    const gwpDataReadyForGraph = convertDataToDataPoint(aggregatedCumulativeGwp, 'Total GWP')
    const dataReadyForGraph = convertDataToGraphFormat(gwpDataReadyForGraph, adjustedGwpDataReadyForGraph)
    setGraphData(dataReadyForGraph)
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.FINISHED)
  }, [updateIndividualProgressItem, summarisedData])

  return (
    <Provider
      value={{ graphData }}
      children={props.children}
    />
  )
}

const ComparisonGwpCumulativeLineChartDataProvider = (props: React.PropsWithChildren<{}>) => {
  const [graphData, setGraphData] = useState<any[]>([])
  const { filteredDataToUseInGraph } = useContext(FullScenarioDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const reloadData = async () => {
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.LOADING)

    const splitByScenario = groupListOfObjsBy(filteredDataToUseInGraph, 'WF Scenario Id')

    const splitByScenarioAndMonth = Object.entries(splitByScenario).map(([scenarioId, dataForScenario]) => {
      const groupedByScenarioAndMonth = groupListOfObjsBy(dataForScenario, RENEWAL_INCEPTION_MONTH_COLUMN_NAME)

      const graphData = convertGroupedDataToGraphFormat(groupedByScenarioAndMonth, RENEWAL_INCEPTION_MONTH_COLUMN_NAME)

      const sortedGraphData = sortInceptionMonth(graphData)
      const aggregatedCumulativeGwp = aggregateCumulativeGwpForEachMonth(sortedGraphData)

      const adjustedGwpDataReadyForGraph = convertDataToDataPoint(aggregatedCumulativeGwp, 'Total Adjusted GWP')

      return [scenarioId, adjustedGwpDataReadyForGraph]
    })
    setGraphData(
      await Promise.all(
        splitByScenarioAndMonth.map(async ([scenarioId, data]) => {
          let scenarioMatchingId: { name: string } | undefined = await fetchScenario(scenarioId as string)

          if (!scenarioMatchingId) {
            scenarioMatchingId = await fetchStackedScenarioById(scenarioId as string)
          }

          return {
            id: scenarioMatchingId?.name || scenarioId,
            data,
          }
        }),
      ),
    )
    updateIndividualProgressItem('gwpCumulativeLineChartUpdate', ProgressState.FINISHED)
  }

  useEffect(() => {
    reloadData()
    //eslint-disable-next-line
  }, [updateIndividualProgressItem, filteredDataToUseInGraph])

  return (
    <Provider
      value={{ graphData }}
      children={props.children}
    />
  )
}

export {
  Consumer as GwpCumulativeLineChartDataConsumer,
  GwpCumulativeLineChartContext,
  GwpCumulativeLineChartDataProvider,
  ComparisonGwpCumulativeLineChartDataProvider,
}
