import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { RouterProps, withRouter } from 'react-router'

import './ScenarioChooser.scss'

import { DivisionListing } from '../../../../backend/src/modules/portfolios/domain/PortfolioListing.types'
import ContentContainer from '../../components/content-container/ContentContainer'
import { ScenariosList } from './ScenariosList'
import { OptionsType } from '../../utils/lists'
import { PortfolioListingContext } from '../../providers/PortfolioListingProvider'
import { StackedScenarioListProvider } from '../../components/stacked-scenarios-list/StackedScenarioListProvider'
import { StackedScenariosViewContext } from '../../components/stacked-scenarios-view/StackedScenariosViewProvider'
import StackedScenariosView from '../../components/stacked-scenarios-view/StackedScenariosView'
import { StackedScenarioEditingContext } from '../../components/stacked-scenarios-list/StackedScenarioEditingProvider'
import { FinishCreatingStackedScenarioModal } from './FinishCreatingStackedScenarioModal'
import { calculateLocationToSendUserTo } from './calculateDefaultLocation'
import { AddingTagModalProvider } from '../../components/adding-tag-modal/AddingTagModalProvider'
import { FinishCreatingTagModal } from '../../components/tag/FinishCreatingTagModal'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { HideUntaggedButton } from './HideUntaggedButton'
import SectionHeaderContainer from '../../components/section-header-container/SectionHeaderContainer'
import { AppQueryContext } from '../../providers/AppQueryProvider'
import { StackedScenarioContext } from '../../providers/StackedScenarioProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Flag } from '../../pages/exposures/StackedExposures'
import { areEqual } from '../../utils/objects'

export function onSelectSingleValueFromMulti<T>(setState: any) {
  return function (values: OptionsType<T>[]): void {
    const { value } = values[0]
    setState(value)
  }
}

export const onSelectSingleValueFromMultiWithCustomLabel = (setState: any) => (values: OptionsType[]) => {
  setState(values)
}

const PORTFOLIO_LEVELS = ['Organisational', 'Divisional']

export function isArrayEqual<T>(firstArray: Array<T>, secondArray: Array<T>): boolean {
  const firstToCompare = [...firstArray].sort()
  const secondToCompare = [...secondArray].sort()
  return areEqual(firstToCompare, secondToCompare)
}

interface ScenarioChooserContainer {
  availableTeams: string[]
  availableMarkets: string[]
  availableDivisions: string[]
  hideUntagged: boolean
  toggleHideUntagged: () => void
}

export const ScenarioChooserContext = createContext<ScenarioChooserContainer>({
  availableMarkets: [],
  availableTeams: [],
  availableDivisions: [],
  hideUntagged: false,
  toggleHideUntagged: () => {},
})

type ProviderProps = React.PropsWithChildren<{}>

export const ScenarioChooserProvider = (props: ProviderProps): JSX.Element => {
  const { currentStackedScenario } = useContext(StackedScenarioContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { portfolioStatus } = useContext(PortfolioListingContext)
  const { yearQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)

  const [availableTeams, setAvailableTeams] = useState<string[]>([])
  const [availableMarkets, setAvailableMarkets] = useState<string[]>([])
  const [hideUntagged, setHideUntagged] = useState<boolean>(false)

  const [yearFromQuery, setYear] = yearQuery
  const [divisionFromQuery] = divisionQuery
  const [teamFromQuery, setTeam] = teamQuery

  const scenario = currentStackedScenario ?? currentScenario

  const currentDivision = scenario?.division || divisionFromQuery
  const currentTeam = scenario?.team || teamFromQuery

  useEffect(() => {
    let divisionListing: DivisionListing | undefined = undefined

    if (
      portfolioStatus.portfolio?.portfolioListing &&
      Object.keys(portfolioStatus.portfolio.portfolioListing.divisions).includes(currentDivision || '')
    ) {
      divisionListing = portfolioStatus.portfolio.portfolioListing.divisions[currentDivision as string]!

      const teamInDivision = divisionListing.teams.find((team) => team.name === currentTeam)
      if (teamInDivision) {
        setAvailableMarkets(teamInDivision.addressableMarkets)
      }
    }
    const newAvailableTeams = currentDivision && divisionListing ? divisionListing.teams.map((item) => item.name) : []
    if (!isArrayEqual(newAvailableTeams, availableTeams)) {
      setAvailableTeams(newAvailableTeams)
      setAvailableMarkets([])
    }
    if (!currentDivision && currentTeam) {
      setTeam(null)
    }
    if (portfolioStatus.portfolio?.portfolioListing && currentTeam && !newAvailableTeams.includes(currentTeam)) {
      setTeam(null)
    }
  }, [
    setAvailableMarkets,
    portfolioStatus,
    currentDivision,
    currentTeam,
    setTeam,
    availableTeams,
    yearFromQuery,
    setYear,
  ])

  const { customUsLicensing } = useFlags<Flag>()

  const availableDivisions = useMemo(() => {
    if (customUsLicensing) {
      return portfolioStatus.portfolio?.portfolioListing
        ? Object.keys(portfolioStatus.portfolio.portfolioListing.divisions || {})
        : []
    }
    return Object.keys(portfolioStatus.portfolio?.portfolioListing.divisions || {}).filter((division) => {
      if (division === 'CUS') {
        return false
      }
      return true
    })
  }, [portfolioStatus.portfolio?.portfolioListing])

  const toggleHideUntagged = () => {
    setHideUntagged(!hideUntagged)
  }

  return (
    <ScenarioChooserContext.Provider
      value={{
        availableTeams,
        availableMarkets,
        availableDivisions,
        hideUntagged,
        toggleHideUntagged,
      }}
      children={props.children}
    />
  )
}

type ComponentProps = RouterProps

function Component(props: ComponentProps): JSX.Element {
  const { portfolioStatus } = useContext(PortfolioListingContext)
  const { yearQuery, portfoliolevelQuery, divisionQuery, teamQuery } = useContext(AppQueryContext)
  const { currentlyChosenScenarioIds, setCurrentlyChosenScenarioIds, currentlyCreatingStackedScenario } =
    useContext(StackedScenarioEditingContext)
  const { availableMarkets, availableTeams, availableDivisions, hideUntagged, toggleHideUntagged } =
    useContext(ScenarioChooserContext)

  const [currentlyComparedScenarioIds, onCurrentComparisonSelectChanges] = useState<Array<string>>([])
  const [currentlyComparingMarket, setCurrentlyComparing] = useState<string | undefined>(undefined)
  const [currentlySelectedStacksIds, setComparingStacksIds] = useState<Array<string>>([])
  const [, setCurrentlyInComparison] = useState<boolean>(false)
  const [currentAvailableScenarios, setCurrentAvailableScenarios] = useState<
    Record<string, boolean | undefined> | undefined
  >({})
  const [currentAvailableTeamScenarios, setCurrentAvailableTeamScenarios] = useState<
    Record<string, boolean | undefined> | undefined
  >({})
  const [currentlyComparingGroup, setCurrentlyComparingGroup] = useState<string | undefined>(undefined)

  const [yearFromQuery] = yearQuery
  const [portfoliolevelFromQuery, setPortfoliolevel] = portfoliolevelQuery
  const [divisionFromQuery, setDivision] = divisionQuery
  const [teamFromQuery, setTeam] = teamQuery

  useEffect(() => {
    if (portfolioStatus.portfolio?.portfolioListing && divisionFromQuery === undefined && teamFromQuery === undefined) {
      const toRedirectTo = calculateLocationToSendUserTo(portfolioStatus.portfolio.portfolioListing)

      if (toRedirectTo && toRedirectTo.divisionName && toRedirectTo.teamName) {
        setDivision(toRedirectTo.divisionName)
        setTeam(toRedirectTo.teamName)
        setPortfoliolevel('Team')
      } else if (toRedirectTo && toRedirectTo.divisionName) {
        setPortfoliolevel('Divisional')
        setDivision(toRedirectTo.divisionName)
      }
    }
  })

  useEffect(() => {
    if (!portfoliolevelFromQuery) {
      setPortfoliolevel((previousState) => {
        if (!previousState) {
          return PORTFOLIO_LEVELS[0]
        }
        return previousState
      })
    }
  }, [portfoliolevelFromQuery, setPortfoliolevel])

  const updateCurrentlySelectedScenarios = (marketToChange: string, newValueOfScenarioId: string | undefined) => {
    const newValue = { ...currentlyChosenScenarioIds, [marketToChange]: newValueOfScenarioId }
    setCurrentlyChosenScenarioIds(newValue)
  }
  const updateScenariosInGivenAvailableMarket = useMemo(
    () => (currentMarket: string, hasScenarios: boolean | undefined) => {
      const newValue = { ...currentAvailableScenarios, [currentMarket]: hasScenarios }
      setCurrentAvailableScenarios(newValue)
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateCurrentlySelectedStackedScenarios = (teamToChange: string, newValueOfScenarioId: string | undefined) => {
    const newValue = { ...currentlyChosenScenarioIds, [teamToChange]: newValueOfScenarioId }
    setCurrentlyChosenScenarioIds(newValue)
  }
  const updateScenariosInGivenDivision = useMemo(
    () => (currentTeam: string, hasTeamScenarios: boolean | undefined) => {
      const newValue = { ...currentAvailableTeamScenarios, [currentTeam]: hasTeamScenarios }
      setCurrentAvailableTeamScenarios(newValue)
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className="ScenarioChooser">
      <FinishCreatingStackedScenarioModal
        availableDivisions={availableDivisions}
        availableMarkets={availableMarkets}
        availableTeams={availableTeams}
        allowValidationIfUserConfirms
      />
      <FinishCreatingTagModal />
      <ContentContainer>
        <SectionHeaderContainer alignRight>
          <HideUntaggedButton
            hideUntagged={hideUntagged}
            toggleHideUntagged={toggleHideUntagged}
          />
        </SectionHeaderContainer>
        <div className="ScenarioListContainer">
          {portfoliolevelFromQuery === 'Organisational' && !teamFromQuery && !divisionFromQuery && yearFromQuery && (
            <StackedScenariosViewContext.Provider
              value={{
                currentYear: yearFromQuery,
                currentPortfolioLevel: portfoliolevelFromQuery,
                currentDivision: undefined,
                currentTeam: undefined,
              }}
            >
              <StackedScenarioListProvider>
                <StackedScenariosView
                  className={
                    currentlyComparingGroup === undefined
                      ? ''
                      : currentlyComparingGroup === 'Convex'
                      ? ''
                      : 'ScenarioListInactive'
                  }
                  currentStackedScenarioGroupName={'Convex'}
                  currentlyChosenScenarioId={currentlyChosenScenarioIds ? currentlyChosenScenarioIds[''] : undefined}
                  onCurrentCreatingSelectChanges={updateCurrentlySelectedStackedScenarios}
                  possibleToCreateDivisionScenario={updateScenariosInGivenDivision}
                  onStartComparison={() => setCurrentlyInComparison(true)}
                  onEndComparison={() => setCurrentlyInComparison(false)}
                  isComparing={currentlyComparingGroup === 'Convex'}
                  currentlySelectedStackedScenarioIds={currentlySelectedStacksIds}
                  updateCurrentlySelectedStackedScenarioIds={setComparingStacksIds}
                  onClickForComparison={setCurrentlyComparingGroup}
                />
              </StackedScenarioListProvider>
            </StackedScenariosViewContext.Provider>
          )}
          {portfoliolevelFromQuery === 'Divisional' &&
            !teamFromQuery &&
            !divisionFromQuery &&
            yearFromQuery &&
            availableDivisions.map((divisionName) => (
              <StackedScenariosViewContext.Provider
                key={divisionName}
                value={{
                  currentYear: yearFromQuery,
                  currentPortfolioLevel: portfoliolevelFromQuery,
                  currentDivision: divisionName,
                  currentTeam: teamFromQuery,
                }}
              >
                <StackedScenarioListProvider>
                  <StackedScenariosView
                    className={
                      currentlyComparingGroup === undefined
                        ? ''
                        : currentlyComparingGroup === divisionName
                        ? ''
                        : 'ScenarioListInactive'
                    }
                    currentStackedScenarioGroupName={divisionName}
                    currentlyChosenScenarioId={
                      currentlyChosenScenarioIds ? currentlyChosenScenarioIds[divisionName] : undefined
                    }
                    onCurrentCreatingSelectChanges={updateCurrentlySelectedStackedScenarios}
                    possibleToCreateDivisionScenario={updateScenariosInGivenDivision}
                    onStartComparison={() => setCurrentlyInComparison(true)}
                    onEndComparison={() => setCurrentlyInComparison(false)}
                    isComparing={currentlyComparingGroup === divisionName}
                    currentlySelectedStackedScenarioIds={currentlySelectedStacksIds}
                    updateCurrentlySelectedStackedScenarioIds={setComparingStacksIds}
                    onClickForComparison={setCurrentlyComparingGroup}
                  />
                </StackedScenarioListProvider>
              </StackedScenariosViewContext.Provider>
            ))}
          {!teamFromQuery &&
            portfoliolevelFromQuery &&
            yearFromQuery &&
            divisionFromQuery &&
            availableTeams.map((teamName) => (
              <StackedScenariosViewContext.Provider
                key={teamName}
                value={{
                  currentYear: yearFromQuery,
                  currentPortfolioLevel: portfoliolevelFromQuery,
                  currentDivision: divisionFromQuery,
                  currentTeam: teamName,
                }}
              >
                <StackedScenarioListProvider>
                  <StackedScenariosView
                    className={
                      currentlyComparingGroup === undefined
                        ? ''
                        : currentlyComparingGroup === teamName
                        ? ''
                        : 'ScenarioListInactive'
                    }
                    currentStackedScenarioGroupName={teamName}
                    currentlyChosenScenarioId={
                      currentlyChosenScenarioIds ? currentlyChosenScenarioIds[teamName] : undefined
                    }
                    onCurrentCreatingSelectChanges={updateCurrentlySelectedStackedScenarios}
                    possibleToCreateDivisionScenario={updateScenariosInGivenDivision}
                    onStartComparison={() => setCurrentlyInComparison(true)}
                    onEndComparison={() => setCurrentlyInComparison(false)}
                    isComparing={currentlyComparingGroup === teamName}
                    currentlySelectedStackedScenarioIds={currentlySelectedStacksIds}
                    updateCurrentlySelectedStackedScenarioIds={setComparingStacksIds}
                    onClickForComparison={setCurrentlyComparingGroup}
                  />
                </StackedScenarioListProvider>
              </StackedScenariosViewContext.Provider>
            ))}
          {divisionFromQuery &&
            teamFromQuery &&
            yearFromQuery &&
            availableMarkets.map((marketName) => (
              <ScenariosList
                key={`${divisionFromQuery}-${teamFromQuery}-${marketName}`}
                market={marketName}
                division={divisionFromQuery}
                team={teamFromQuery}
                year={yearFromQuery}
                className={
                  currentlyComparingMarket === undefined
                    ? ''
                    : currentlyComparingMarket === marketName
                    ? ''
                    : 'ScenarioListInactive'
                }
                isComparing={currentlyComparingMarket === marketName}
                onStartComparison={() => setCurrentlyComparing(marketName)}
                currentlyComparedScenarioIds={currentlyComparedScenarioIds}
                onCurrentComparisonSelectChanges={onCurrentComparisonSelectChanges}
                onEndComparison={() => setCurrentlyComparing(undefined)}
                possibleToCreateTeamScenario={updateScenariosInGivenAvailableMarket}
                isCreatingTeamScenario={currentlyCreatingStackedScenario}
                currentlyChosenScenarioIds={
                  currentlyChosenScenarioIds ? currentlyChosenScenarioIds[marketName] : undefined
                }
                onCurrentCreatingSelectChanges={updateCurrentlySelectedScenarios}
              />
            ))}
        </div>
      </ContentContainer>
    </div>
  )
}

const ComponentWithRouter = withRouter(Component)

export const MainSelector = () => (
  <AddingTagModalProvider>
    <ComponentWithRouter />
  </AddingTagModalProvider>
)
