import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Big, { BigSource } from 'big.js'

import './ParameterisationRequestContainer.scss'

import {
  handlePercentageAndOutputString,
  numericValueIsDefined,
  ONE_HUNDRED,
  PossiblyNegativeNumber,
  suffixForValue,
  ZERO,
} from '../../utils/numbers'
import { KpiCardSmall } from '../kpi-card-small/KpiCardSmall'
import Divider from '../divider/LineDivider'
import SectionHeader from '../section-header/SectionHeader'
import { TOTAL_ADJUSTED_GWP_COLUMN } from '../../backend/calculate-kpis'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { ParameterisationDefaultMarketDataContext } from '../../providers/parameterisation/ParameterisationDefaultMarketDataProvider'
import { ErrorText } from '../text-area/ErrorText'
import Button from '../button/Button'
import {
  AttritionalDependency,
  createTcsRequest,
  CreateTcsRequestBody,
  DefaultParameterData,
  findMarketInputDependencyLevel,
  MarketInputByMarketInput,
  ParameterInputs,
  ParameterisationByIdResponse,
  TcsStatus,
} from '../../backend/parameterisation'
import { Scenario } from '../../backend/scenarios'
import TextField from '../text-field/WFTextField'
import { MultiSelectDropdown } from '../multi-select-dropdown/MultiSelectDropdown'
import { OptionsType, toOption } from '../../utils/lists'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { LossToggle, ParameterisationDataContext } from '../../providers/parameterisation/ParameterisationDataProvider'
import SegmentedControlSwitch from '../segmented-control/SegmentedControl'
import DropdownWithTextfield from '../dropdown-with-textfield/DropdownWithTextfield'
import { NoteSections } from '../../pages/notes/NotesContent'
import { NotesDataContext } from '../../pages/notes/NotesDataProvider'
import { ProgressContext, ProgressState } from '../../providers/ProgressProvider'

const attritionalDependencyOptions = (['High', 'Medium', 'Low'] as AttritionalDependency[]).map(toOption)

type LossratioOption =
  | { value: string; label: 'Attritional Loss Ratio' }
  | { value: string; label: 'Single-risk Loss Ratio' }
  | { value: string; label: 'Clash Loss Ratio' }
  | { value: string; label: 'ABE GGLR' }

type LossratioLabel = LossratioOption['label']

const LOSS_RATIO_OPTION_LIST: LossratioOption[] = (
  ['Attritional Loss Ratio', 'Single-risk Loss Ratio', 'Clash Loss Ratio'] as const
).map((label) => ({ label, value: '0.0' }))

const CLASH_LOSS_RATIO_HOVER_OVER_TEXT =
  'We do not yet have accurate projected 2023 clash loss ratio distributions, so this is proxied using 2022 distributions, allowing for growth in GWP.'

export function ParameterisationRequestContainer(): JSX.Element {
  const { triggerSaveOfNotes } = useContext(NotesDataContext)
  const { currentScenario: scenario } = useContext(ScenarioContext)
  const { fullRenewalKpis, fullNewKpis } = useContext(FullScenarioDataContext)
  const { defaultMarketData } = useContext(ParameterisationDefaultMarketDataContext)
  const { setNeedToPollForMoreData, setLossToggle, lossToggle, parameterisationData } =
    useContext(ParameterisationDataContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)

  const [maxLine, setMaxLine] = useState<PossiblyNegativeNumber>(undefined)
  const [threshold, setThreshold] = useState<PossiblyNegativeNumber>(undefined)
  const [lossratioOne, setLossratioOne] = useState<LossratioOption>({ label: 'Attritional Loss Ratio', value: '' })
  const [lossratioTwo, setLossratioTwo] = useState<LossratioOption>({ label: 'Single-risk Loss Ratio', value: '' })
  const [lossratioTotal, setLossratioTotal] = useState<LossratioOption>({ label: 'ABE GGLR', value: '' })
  const [expectSingleriskloss, setExpectSingleriskloss] = useState<string>('')
  const [attritionalDependency, setAttritionalDependency] = useState<string>('')
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [isUpToDateWithRemote, setIsUptoDate] = useState<boolean>(true)

  // We stringify these objects so that the useEffect dependency-array can check if values inside it change
  const dependencyDefaultMarketData = JSON.stringify(defaultMarketData)
  const dependencyParameterisationData = JSON.stringify(parameterisationData)

  useEffect(() => {
    if (!defaultMarketData) {
      return
    }
    if (!parameterisationData) {
      return setControlWithDefaults(defaultMarketData)
    }
    return setControlWithParameterisationResponse(parameterisationData, defaultMarketData)
  }, [defaultMarketData, parameterisationData, dependencyDefaultMarketData, dependencyParameterisationData])

  function setControlWithDefaults(defaultMarketData: DefaultParameterData): void {
    const {
      convexAttritionalLossRatio,
      convexSingleRiskLossRatio,
      convexTotalLossRatio,
      convexMax,
      convexThreshold,
      singleRiskFrequencyMean,
      attritionalDependencySelection,
    } = defaultMarketData
    setLossratioOne((option) => ({ ...option, value: prettyNumber(convexAttritionalLossRatio) }))
    setLossratioTwo((option) => ({ ...option, value: prettyNumber(convexSingleRiskLossRatio) }))
    setLossratioTotal((option) => ({ ...option, value: prettyNumber(convexTotalLossRatio) }))
    setMaxLine(convexMax)
    setThreshold(convexThreshold)
    setExpectSingleriskloss(singleRiskFrequencyMean ? new Big(singleRiskFrequencyMean).div(ONE_HUNDRED).toFixed(2) : '')
    setAttritionalDependency(attritionalDependencySelection)
    setIsUptoDate(true)
  }

  function setControlWithParameterisationResponse(
    parameterisationData: ParameterisationByIdResponse,
    defaultMarketData: DefaultParameterData,
  ): void {
    const { marketInputByMarketInput, marketInputDependency } = parameterisationData.input.inputs
    const tcsRequestInputs: ParameterInputs = inputObjTransformer(marketInputByMarketInput)
    const { LR_Attritional, LR_PerilTotal, LR_Total, Max, Threshold, SRFrequency_Mean } = tcsRequestInputs

    // This will switch out the user's input:
    setLossratioOne({ label: 'Attritional Loss Ratio', value: prettyNumber(fromDecimalToPercent(LR_Attritional)) })
    setLossratioTwo({ label: 'Clash Loss Ratio', value: prettyNumber(fromDecimalToPercent(LR_PerilTotal)) })
    setLossratioTotal({ label: 'ABE GGLR', value: prettyNumber(fromDecimalToPercent(LR_Total)) })

    setAttritionalDependency(marketInputDependency[0].value)
    setExpectSingleriskloss(SRFrequency_Mean.toString())
    setIsUptoDate(true)

    //Max Line & Threshold always set from default data as they can't be edited
    const { convexMax, convexThreshold } = defaultMarketData
    setThreshold(convexThreshold)
    setMaxLine(convexMax)

    if (Max !== convexMax || Threshold !== convexThreshold) {
      setIsUptoDate(false)
    }
  }

  function setStateWithSettingNotUpToDate<T>(setState: Dispatch<SetStateAction<T>>): Dispatch<SetStateAction<T>> {
    return (value: SetStateAction<T>): void => {
      setState(value)
      setIsUptoDate(false)
    }
  }

  const balancingItem: LossratioOption = makeBalancingItem(
    LOSS_RATIO_OPTION_LIST,
    lossratioOne,
    lossratioTwo,
    lossratioTotal,
  )

  const convexGwp: Big =
    fullRenewalKpis && fullNewKpis
      ? fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN].plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN])
      : ZERO

  const validateThenCreateParameterisation = async (): Promise<void> => {
    if (!scenario) {
      throw new Error(`Missing currentScenario`)
    }
    setShouldValidate(true)
    if (maybeError(lossratioOne, lossratioTwo, lossratioTotal)) {
      return
    }
    try {
      updateIndividualProgressItem('sendingTcsRequest', ProgressState.LOADING)
      const body: CreateTcsRequestBody = calculateCreateRequest(
        lossratioOne,
        lossratioTwo,
        lossratioTotal,
        balancingItem,
        convexGwp,
        maxLine,
        threshold,
        expectSingleriskloss,
        attritionalDependency,
        scenario,
      )

      await createTcsRequest(scenario.id, body)
      await triggerSaveOfNotes()
      setNeedToPollForMoreData(TcsStatus.INPROGRESS)
      setIsUptoDate(true)
      updateIndividualProgressItem('sendingTcsRequest', ProgressState.FINISHED)
    } catch (err) {
      updateIndividualProgressItem('sendingTcsRequest', ProgressState.ERROR)
      console.log('Something went wrong:', err)
    }
  }

  return (
    <div className="ParameterisationRequestContainer">
      <SectionHeader title="Parameter Control" />

      <Divider />
      <SectionHeader title="Distribution Type" />
      <SegmentedControlSwitch
        options={[
          { name: 'Loss Ratio', value: 'Loss Ratio' },
          { name: 'Loss Value', value: 'Loss Value' },
        ]}
        selectedOption={lossToggle === 'Loss Ratio' ? 0 : 1}
        onChange={(x) => setLossToggle(x as LossToggle)}
        className="ColumnAndValueFilterSegmentedControl"
      />

      <Divider />
      <div className="GwpContainer">
        <KpiCardSmall
          value={convexGwp}
          title="GWP"
          prefix="$"
          suffix={
            fullRenewalKpis && fullNewKpis
              ? suffixForValue(fullNewKpis[TOTAL_ADJUSTED_GWP_COLUMN].plus(fullRenewalKpis[TOTAL_ADJUSTED_GWP_COLUMN]))
              : ''
          }
          className="GwpCard"
        />
        <KpiCardSmall
          value={toBig(maxLine)}
          title="Max Line"
          prefix="$"
          suffix={suffixForValue(toBig(maxLine))}
          className="GwpCard"
        />
        <KpiCardSmall
          value={toBig(threshold)}
          title="Threshold"
          prefix="$"
          suffix={suffixForValue(threshold || 0)}
          className="GwpCard"
        />
      </div>

      <Divider />
      <div className="LossRatioDropdownsContainer">
        {/* First dropdown */}
        <div
          className="LossRatioDropdownWithInput"
          title={lossratioOne?.label === 'Clash Loss Ratio' ? CLASH_LOSS_RATIO_HOVER_OVER_TEXT : ''}
        >
          <DropdownWithTextfield
            onDropdownSelect={handleOnDropdownSelect(setLossratioOne, lossratioTwo, setLossratioTwo)}
            onTextFieldChange={(ev) => {
              const value: string = ev.target.value
              const option: SetStateAction<LossratioOption> = (lossratio) => ({ ...lossratio, value })
              return setStateWithSettingNotUpToDate(setLossratioOne)(option)
            }}
            options={LOSS_RATIO_OPTION_LIST}
            selected={[lossratioOne]}
            textFieldPlaceholder="0.0"
            textFieldType="number"
            textFieldValue={lossratioOne.value}
            textFieldWarning={calculateLossRatioWarning(lossratioOne.value, lossratioTotal.value)}
            textFieldError={shouldValidate ? validateLossratio(lossratioOne, lossratioTwo, lossratioTotal) : ''}
            textFieldIsPercentage
            step="0.1"
          />
        </div>
        {/* Second dropdown */}
        <div
          className="LossRatioDropdownWithInput"
          title={lossratioTwo?.label === 'Clash Loss Ratio' ? CLASH_LOSS_RATIO_HOVER_OVER_TEXT : ''}
        >
          <DropdownWithTextfield
            onDropdownSelect={handleOnDropdownSelect(setLossratioTwo, lossratioOne, setLossratioOne)}
            onTextFieldChange={(ev) => {
              const value: string = ev.target.value
              const option: SetStateAction<LossratioOption> = (lossratio) => ({ ...lossratio, value })
              return setStateWithSettingNotUpToDate(setLossratioTwo)(option)
            }}
            options={makeSecondaryOptions(LOSS_RATIO_OPTION_LIST, lossratioOne)}
            selected={[lossratioTwo]}
            textFieldPlaceholder="0.0"
            textFieldType="number"
            textFieldValue={lossratioTwo.value}
            textFieldWarning={calculateLossRatioWarning(lossratioTwo.value, lossratioTotal.value)}
            textFieldError={shouldValidate ? validateLossratio(lossratioTwo, lossratioOne, lossratioTotal) : ''}
            textFieldIsPercentage
            step="0.1"
          />
        </div>
      </div>
      <div
        className="LossRatioContainer"
        title={balancingItem?.label === 'Clash Loss Ratio' ? CLASH_LOSS_RATIO_HOVER_OVER_TEXT : ''}
      >
        <div>
          <KpiCardSmall
            className="GwpCard"
            title={balancingItem.label}
            tooltip={balancingItem?.label === 'Clash Loss Ratio' ? CLASH_LOSS_RATIO_HOVER_OVER_TEXT : ''}
            value={toBig(balancingItem.value)}
            decimals={2}
            suffix="%"
          />
        </div>
        <div>
          <h3 className="ExpectNumberInput">{lossratioTotal.label}</h3>
          <TextField
            className="TotalLossRatio"
            value={lossratioTotal.value}
            onChange={(ev) => {
              const value: string = ev.target.value
              const option: SetStateAction<LossratioOption> = (lossratio) => ({ ...lossratio, value })
              return setStateWithSettingNotUpToDate(setLossratioTotal)(option)
            }}
            type="number"
            percentage
          />
        </div>
      </div>

      <Divider />
      <h3 className="ExpectNumberInput">Expected Number of Single-risk Losses</h3>
      <TextField
        className="ExpectedNumber"
        type="number"
        value={expectSingleriskloss}
        onChange={(ev) => setStateWithSettingNotUpToDate(setExpectSingleriskloss)(ev.target.value)}
        error={shouldValidate ? calculateExpectedNumberError(expectSingleriskloss) : ''}
        placeholder="0"
        step="0.1"
      />
      <h3 className="AttritionalDependencySelection">Attritional Dependency Selection</h3>
      <MultiSelectDropdown
        onSelect={([option]) => setStateWithSettingNotUpToDate(setAttritionalDependency)(option.value)}
        options={attritionalDependencyOptions}
        selected={[toOption(attritionalDependency)]}
      />
      {shouldValidate && !attritionalDependency && <ErrorText error={'This must have a value'} />}

      <Divider />
      <NoteSections />
      {scenario?.canWriteScenario && (
        <div className="SaveContainer">
          <div className={isUpToDateWithRemote ? 'SaveContainerTitle' : 'UnsaveContainerTitle'}>
            {isUpToDateWithRemote ? 'Saved' : 'Unsaved changes'}
          </div>
          <div className="ButtonsContainer">
            <Button
              title="Save"
              onClick={validateThenCreateParameterisation}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function handleOnDropdownSelect(
  setLossratio: Dispatch<SetStateAction<LossratioOption>>,
  other: LossratioOption,
  setOther: Dispatch<SetStateAction<LossratioOption>>,
) {
  return function ([option]: OptionsType[]): void {
    const next = option as LossratioOption
    return setLossratio((current) => {
      // Selected the same item in the dropdown
      if (current.label === next.label) {
        // Do nothing
        return current
      }
      // Selected the "Single-risk Loss Ratio"
      if (next.label === 'Single-risk Loss Ratio') {
        // Automatically choose the other dropdown item
        setOther({ label: 'Clash Loss Ratio', value: '0.0' })
        const { value } = other
        return { ...next, value }
      }
      // Selected the same item as the other dropdown
      if (next.label === other.label) {
        // Swap the options around
        setOther(current)
        return other
      }
      return next
    })
  }
}

function maybeError(
  lossratioOne: LossratioOption,
  lossratioTwo: LossratioOption,
  lossratioTotal: LossratioOption,
): undefined | string {
  return (
    validateLossratio(lossratioOne, lossratioTwo, lossratioTotal) ||
    validateLossratio(lossratioTwo, lossratioOne, lossratioTotal)
  )
}

function makeSecondaryOptions(options: LossratioOption[], lossratioOne: LossratioOption): LossratioOption[] {
  return lossratioOne.label === 'Attritional Loss Ratio'
    ? options.filter((option) => option.label !== 'Attritional Loss Ratio')
    : options
}

function prettyNumber(n: BigSource): string {
  return toBig(n).toFixed(2)
}

function fromPercentToDecimal(percent: string): Big {
  return toBig(percent).div(ONE_HUNDRED)
}

function fromDecimalToPercent(decimal: number): Big {
  return toBig(decimal).mul(ONE_HUNDRED)
}

function calculateLossRatioWarning(
  value: string | PossiblyNegativeNumber,
  totalValue: string | PossiblyNegativeNumber,
): string | undefined {
  if (!numericValueIsDefined(value) || !numericValueIsDefined(totalValue)) {
    return undefined
  }

  if (Number.parseFloat(String(value)) > Number.parseFloat(String(totalValue))) {
    return 'Should this be greater than total loss ratio?'
  }
  return undefined
}

function calculateExpectedNumberError(inputValue: string | PossiblyNegativeNumber): string | undefined {
  const input = toBig(inputValue)

  if (!numericValueIsDefined(inputValue)) {
    return 'This must have a value'
  }
  if (input.cmp(ZERO) < 0) {
    return 'This must be greater or equal than 0'
  }
  return undefined
}

function validateLossratio(
  lossratioOne: LossratioOption,
  lossratioTwo: LossratioOption,
  lossratioTotal: LossratioOption,
): string | undefined {
  const [one, two, total] = [lossratioOne, lossratioTwo, lossratioTotal].map((x) => x.value).map(toBig)
  if (!numericValueIsDefined(one)) {
    return 'This must have a value'
  }
  if (one.cmp(ZERO) < 0) {
    return 'This must be greater or equal than 0'
  }
  if (one.plus(two).minus(total).cmp(ZERO) > 0) {
    return 'The balancing item can not be negative'
  }
  return undefined
}

function toBig(n: undefined | null | BigSource): Big {
  return new Big(n || 0)
}

function calculateBalancingValue(
  lossratioOne: LossratioOption,
  lossratioTwo: LossratioOption,
  lossratioTotal: LossratioOption,
): string {
  const one = lossratioOne.value
  const two = lossratioTwo.value
  const total = lossratioTotal.value
  const hasValues: boolean = one !== '' && two !== '' && total !== ''
  if (!hasValues) {
    return String(0)
  }
  const sum = toBig(one).plus(toBig(two))
  const difference = toBig(total).minus(sum)

  return String(difference.toNumber())
}

function inputObjTransformer(inputList: MarketInputByMarketInput[]): ParameterInputs {
  const inputs = Object.fromEntries(inputList.map((input) => [input.type, input.value]))
  return {
    GWP: inputs['GWP'],
    Max: inputs['Max'],
    LR_Total: inputs['LR_Total'],
    LR_Attritional: inputs['LR_Attritional'],
    LR_PerilTotal: inputs['LR_PerilTotal'],
    Threshold: inputs['Threshold'],
    SRFrequency_Mean: inputs['SRFrequency_Mean'],
  }
}

function calculateCreateRequest(
  lossratioOne: LossratioOption,
  lossratioTwo: LossratioOption,
  lossratioTotal: LossratioOption,
  balancingItem: LossratioOption,
  convexGwp: Big,
  maxLine: PossiblyNegativeNumber,
  threshold: PossiblyNegativeNumber,
  expectSingleriskloss: string,
  attritionalDependency: string,
  scenario: undefined | Scenario,
): CreateTcsRequestBody {
  if (!scenario) {
    throw new Error('Missing scenario')
  }
  const lookupByLabel = lookupLossratio(lossratioOne, lossratioTwo, balancingItem)
  const { market } = scenario
  return {
    callback_url: null,
    inputs: {
      exportToDB: 'TRUE',
      marketNameSelected: market,
      marketInputDependency: [{ value: findMarketInputDependencyLevel(attritionalDependency) }],
      marketInputByMarketInput: [
        {
          market,
          type: 'GWP',
          value: convexGwp.toNumber(),
        },
        {
          market,
          type: 'Max',
          value: parseInt(handlePercentageAndOutputString(maxLine)),
        },
        {
          market,
          type: 'LR_Total',
          value: fromPercentToDecimal(lossratioTotal.value).toNumber(),
        },
        {
          market,
          type: 'LR_Attritional',
          value: fromPercentToDecimal(lookupByLabel('Attritional Loss Ratio').value).toNumber(),
        },
        {
          market,
          type: 'LR_PerilTotal',
          value: fromPercentToDecimal(lookupByLabel('Clash Loss Ratio').value).toNumber(),
        },
        {
          market,
          type: 'Threshold',
          value: parseInt(handlePercentageAndOutputString(threshold)),
        },
        {
          market,
          type: 'SRFrequency_Mean',
          value: parseFloat(expectSingleriskloss),
        },
      ],
    },
  }
}

function lookupLossratio(lossratioOne: LossratioOption, lossratioTwo: LossratioOption, balancingItem: LossratioOption) {
  return function (lookup: LossratioLabel): LossratioOption {
    const option = [lossratioOne, lossratioTwo, balancingItem].find((option) => option.label === lookup)
    if (!option) {
      throw new Error(`Missing Lossratio lookup: ${lookup}`)
    }
    return option
  }
}

function makeBalancingItem(
  optionList: LossratioOption[],
  lossratioOne: LossratioOption,
  lossratioTwo: LossratioOption,
  lossratioTotal: LossratioOption,
): LossratioOption {
  const label: undefined | LossratioLabel = optionList
    .map((option) => option.label)
    .find((label) => label !== lossratioOne.label && label !== lossratioTwo.label)
  if (!label) {
    throw new Error(`Missing lossratioBalancingItem`)
  }
  const value: string = calculateBalancingValue(lossratioOne, lossratioTwo, lossratioTotal)
  return { label, value }
}
