import { BarGraphData } from '../pages/exposures/components/ExposuresBarGraph'
import { getCurrentConfigSettings } from '../utils/config'
import { getAccessToken } from './teams'
import { ExposureGrowth } from './exposures'

export type graphType = 'bar' | 'exceedance'

export interface exposuresTemplateData {
  id: string
  createdAt: Date
  data: string
}

export type MarketShareMatrix = {
  [peril: string]: {
    ilcBand: string
    marketShare: {
      inforce: number
      scaled: number
      projected?: number
    }
  }[]
}

type Point = {
  x: number
  y: number
}

export type ReturnPeriod = 'OEP' | 'AEP'

export type ExceedanceProbabiltyInforceData = {
  [peril: string]: {
    [returnType in ReturnPeriod]: Point[]
  }
}

export type ExceedanceGraphData = {
  id: string
  color: string
  data: ExceedanceProbabiltyInforceData
}

export type ExceedanceProbabilityAALData = {
  [peril: string]: {
    data: number
  }
}

export type ExceedanceProbabilityAALGraph = {
  id: string
  color: string
  data: ExceedanceProbabilityAALData[]
}

export async function fetchExceedanceProbabilityGraph(
  market: string,
  exposuresCalculationData: ExposureGrowth,
  perils: string[],
): Promise<[ExceedanceGraphData[], ExceedanceProbabilityAALGraph[]]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/graphs/exceedance`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ market, perils, exposureGrowth: exposuresCalculationData }),
  })
  if (response.status >= 400) {
    const message = JSON.stringify(response, null, 2)
    return Promise.reject(`GET Exposures Graph Data responded with a non-200 status: expected 200, got "${message}"`)
  }

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return Promise.reject('Exposures Graph Data returned an empty object')
  }

  return responseObject
}

export async function fetchMarketShareMatrixGraph(
  market: string,
  perils: string[],
  exposuresCalculationData: ExposureGrowth,
): Promise<MarketShareMatrix> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/graphs/market-share-matrix`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify({ market, perils, exposureGrowth: exposuresCalculationData }),
  })
  if (response.status >= 400) {
    const message = JSON.stringify(response, null, 2)
    return Promise.reject(`GET Exposures Graph Data responded with a non-200 status: expected 200, got "${message}"`)
  }

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return Promise.reject('Exposures Graph Data returned an empty object')
  }

  return responseObject
}

export async function fetchExposuresGraphData(graphType: graphType): Promise<BarGraphData> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/graphs/${graphType}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status >= 400) {
    const message = JSON.stringify(response, null, 2)
    return Promise.reject(`GET Exposures Graph Data responded with a non-200 status: expected 200, got "${message}"`)
  }

  const responseObject = await response.json()

  if (Object.keys(responseObject).length === 0) {
    return Promise.reject('Exposures Graph Data returned an empty object')
  }

  return responseObject
}

type ModelledLossesDataPoint = {
  YLT_DVID: number
  PERSPECTIVE: string
  PERIL: string
  OEP: number
  AEP: number
  RETURNPERIOD: number
  NONCAT_RATIO: number | null
  MAXLOSS: number | null
  ASOFDATE: string
}

type GetTemplateByScenarioIdResponse = { id: string; market: string; defaultValues: ModelledLossesDataPoint[] }

export async function fetchExposuresTemplateIdByScenarioId(
  scenarioId: string,
): Promise<GetTemplateByScenarioIdResponse> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/exposures/v2/templates/scenarios/${scenarioId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
  if (response.status < 200 || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Template responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  if (response.status === 204) {
    return {
      id: '',
      market: '',
      defaultValues: [],
    }
  }

  const responseObject = await response.json()

  return responseObject
}

export async function fetchExceedanceProbabilityDataByReportId(
  reportId: string,
): Promise<[ExceedanceGraphData[], ExceedanceProbabilityAALGraph[]]> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${reportId}/exceedance`,
    request,
  )
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Exceedance Probability responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  const responseObject = await response.json()
  return responseObject
}

export async function fetchMarketShareMatrixDataByReportId(reportId: string): Promise<MarketShareMatrix> {
  const request: RequestInit = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  }

  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/exposures/v2/reports/${reportId}/market-share-matrix`,
    request,
  )
  if (!response.ok || response.status >= 400) {
    return Promise.reject(
      `GET Exposures Market share matrix responded with a non-200 status: expected 200, got ${response.status}`,
    )
  }

  return response.json().then(({ marketShareMatrix }) => marketShareMatrix)
}
