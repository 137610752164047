import React, { ReactElement } from 'react'
import './LandingContainer.scss'
export type LandingContainerProps = React.PropsWithChildren<{
  leftColumm: ReactElement
  rightColumn: ReactElement
}>

export function LandingContainer(props: LandingContainerProps) {
  return (
    <div className="LandingContainer">
      {props.children}
      <div className="LandingContainerLeftColumn">{props.leftColumm}</div>
      <div className="LandingContainerRightColumn">{props.rightColumn}</div>
    </div>
  )
}

export default LandingContainer
