import { sumListAsIntegers } from './numbers'

interface IndividualDataItem {
  [someKey: string]: any
}

export function itemsInList1WhichAreNotInListTwo(listOne: any[], listTwo: any[], columnToDeDupeBy: string) {
  const allKeysInListTwo = listTwo.map((item) => item[columnToDeDupeBy])

  return listOne.filter((item) => !allKeysInListTwo.includes(item[columnToDeDupeBy]))
}

export function sortListBy(listOfObjects: any[], keysToSortBy: string[]): any[] {
  const returnList = [...listOfObjects]

  return returnList.sort((dataItemOne, dataItemTwo) => {
    return (
      keysToSortBy
        .map((currentKeyToSortBy) => {
          const firstValue = dataItemOne[currentKeyToSortBy]
          const secondValue = dataItemTwo[currentKeyToSortBy]
          if (typeof firstValue === 'string' && typeof secondValue === 'string') {
            try {
              return Number.parseInt(firstValue) - Number.parseInt(secondValue)
            } catch (e) {
              return firstValue.localeCompare(secondValue)
            }
          } else if (typeof firstValue == 'number' && typeof secondValue == 'number') {
            return firstValue - secondValue
          }

          return secondValue < firstValue ? 1 : -1
        })
        .find((item) => item !== 0) || 0
    )
  })
}

export function groupListOfObjsBy(listOfObjects: any[], keyToGroupBy: string): { [key: string]: any[] } {
  return listOfObjects.reduce((groupingObject, currentItem) => {
    ;(groupingObject[currentItem[keyToGroupBy]] = groupingObject[currentItem[keyToGroupBy]] || []).push(currentItem)
    return groupingObject
  }, {})
}

export function filterListByColumns(data: IndividualDataItem[], columnNames: string[]): IndividualDataItem[] {
  return data.filter((currentDataItem) => {
    const valuesForData = columnNames.map((currentColumnName) => currentDataItem[currentColumnName])

    const oneOrMoreValueIsDefined =
      valuesForData.filter((value) => value !== undefined && value !== null && value !== '').length > 0
    return !oneOrMoreValueIsDefined
  })
}

export function aggregateIntoTopNAndOtherCategory(
  inputValues: Array<any>,
  fieldToUse: string,
  nameFieldOfObject: string,
  numberToKeep: number,
): Array<any> {
  const valuesAsSortedArray = [...inputValues]
  valuesAsSortedArray.sort((first, second) => second[fieldToUse] - first[fieldToUse])

  if (inputValues.length <= numberToKeep) {
    return valuesAsSortedArray
  }

  const topNValues = valuesAsSortedArray.slice(0, numberToKeep)
  const otherValues = valuesAsSortedArray.slice(numberToKeep, valuesAsSortedArray.length)

  const otherTotal = sumListAsIntegers(otherValues, fieldToUse)

  return [...topNValues, { [nameFieldOfObject]: 'Others', [fieldToUse]: otherTotal.toNumber() }]
}

export function mapValues<IN, OUT>(
  objectToBeMapped: { [key: string]: IN },
  valueTransformer: (value: IN) => OUT,
): { [key: string]: OUT } {
  return Object.fromEntries(Object.entries(objectToBeMapped).map(([key, value]) => [key, valueTransformer(value)]))
}

export function groupByObjKeys(listOfObj: Array<Record<string, string[]>>) {
  return listOfObj.reduce((groupingObj, currentObj) => {
    Object.entries(currentObj).forEach(([currentKey, currentValue]) => {
      if (!groupingObj[currentKey]) {
        groupingObj[currentKey] = []
      }
      groupingObj[currentKey] = [...groupingObj[currentKey], ...currentValue]
    })
    return groupingObj
  }, {})
}

export function option<T extends string>(fn = (id: T) => id) {
  return (value: T, _index: number, _array: T[]) => {
    return { value, label: fn(value) }
  }
}

export type OptionsType<T = string> = {
  label: string
  value: T
}

export type DropdownGroup<T = string> = {
  label: string
  options: OptionsType<T>[]
}

export function toOption(value: string): OptionsType {
  return { value, label: value }
}

export type LoadingStatus<K extends keyof any, Loaded> =
  | (Record<'status', 'error'> & Record<K, undefined> & Record<'error', string>)
  | (Record<'status', 'unloaded'> & Record<K, undefined>)
  | (Record<'status', 'loading'> & Record<K, undefined>)
  | (Record<'status', 'loaded'> & Record<K, Loaded>)
