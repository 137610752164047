import React, { HTMLProps } from 'react'
import { css } from '../../utils/css'

type Props = HTMLProps<HTMLAnchorElement>

export function LinkButton(props: Props): JSX.Element {
  const { className } = props
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...props}
      className={css('Button', className)}
    />
  )
}
